import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentInformationQueryKeys } from './contentInformationQueries';
import { uploadToAws } from './uploadToAws';
import { AddContentInformation } from '../../components/ContentInformation/ContentInformationForm';

export default function useAddContentInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async (options: { information: AddContentInformation; projectId: number }) => {
            const { image: file, ...contentInformation } = options.information;
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;

            const { preSingedUrl, id } = await trpc.ContentInformationApi.addContentInformation.mutate({
                information: { ...contentInformation },
                image: image,
                projectId: options.projectId,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentInformation.mutate({
                            id,
                            projectId: options.projectId,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
            },
        },
    );
}

export function useAddContentOrgInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async (options: { information: AddContentInformation }) => {
            const { image: file, ...contentInformation } = options.information;
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;

            const { preSingedUrl, id } = await trpc.ContentInformationApi.addContentOrgInformation.mutate({
                information: { ...contentInformation },
                image: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentOrgInformation.mutate({
                            id,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
            },
        },
    );
}

export function useCreateDraftContentOrgInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async (options: { information: AddContentInformation }) => {
            const { image: file, ...contentInformation } = options.information;
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;

            const { preSingedUrl, id } = await trpc.ContentInformationApi.createDraftContentOrgInformation.mutate({
                information: { ...contentInformation },
                image: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentOrgInformation.mutate({
                            id,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.drafts());
            },
        },
    );
}
