import React, { useMemo, useState } from 'react';
import { getCoreRowModel, useReactTable, SortingState, getSortedRowModel } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import ChosenFilters from '../../components/ChosenFilters/ChosenFilters';
import { ExportToExcel } from '../../components/ExportToExcel';
import TableMenu from '../../components/TableMenuV2';
import Comments from '../../components/Comments';
import ProductsFilters from '../../components/FilterTableData';
import FilterSearchContainer from '../../components/emotion/Filters/FilterSearchContainer';
import Row from '../../components/emotion/Row';
import ContentContainer from '../../components/emotion/ContentContainer';
import PaddedContainer from '../../components/emotion/PaddedContainer';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { Loading } from '../../../../components/Loading/Loading';
import { FiltersProducts } from '../../DataType';
import { L } from '../../../../lib/i18n';
import { InventoryData } from '@app/trpc-api/models/Binders';
import { useGetPropertyProductList, useGetPropertyProductsListFilters } from '../../hooks/productQueries';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import GenericTableVirtualized from '../../components/GenericTableVirtualizedInfinite';
import useUpdateProductPrices from '../../hooks/useUpdateProductPrices';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { margin } from '../../../../components/Shared/Style/margin';
import TopAreaContainer from '../../components/TopAreaContainer';
import { TableMeta } from '../../TableMetaTypes/PropertyProductsTableMeta';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom-v5-compat';
import columns from '../../components/Products/PropertyProductsPageColumns';
import AddProductFromTemplate from '../../components/Products/AddProductFromTemplate';
import Dialog from '../../../../components/Dialog/Dialog';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { useLocalStorage } from 'react-use';

type PropertyFilterProducts = Omit<FiltersProducts, 'property'>;

type NewPrice = { id: number; price: number; addon_price: string };

export type MutatedInventoryData = InventoryData & {
    stagedDeletion: boolean;
    newPrice:
        | false
        | {
              id: number;
              price: number;
              addon_price: number;
              isProcentual: boolean;
          }
        | undefined;
};

const options = [
    { name: 'space', value: 'selectedSpaces', newValue: 'setSelectedSpaces', id: 1 },
    { name: 'product_group', value: 'selectedProductTypes', newValue: 'setSelectedProductTypes', id: 2 },
    { name: 'package', value: 'selectedPackage', newValue: 'setSelectedPackages', id: 3 },
];

const procentualAdd = (priceAdjustment: number, price: number) => {
    if (price) {
        return Math.round(priceAdjustment * price);
    } else {
        //NAN
        return null;
    }
};

const PropertyProductsComponent = ({ projectId, propertyId }: { projectId: string; propertyId: string }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';
    const [open, setOpen] = useState(false);

    const [localStorageValue, setLocalStorageValue] = useLocalStorage<Record<string, boolean>>(
        'propertyProductsImageToggle',
        { image: false },
    );

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else setSearchParams([]);
    };

    const updateFilter = (key: string, values: string[]) => {
        const valuesAsString = values.map((val) => String(val));
        if (values.length === 0) searchParams.delete(key);
        searchParams.delete('page');
        setSearchParams((searchParams) => {
            if (values.length > 0) {
                searchParams.delete(key);
                valuesAsString.forEach((val) => searchParams.append(key, val));
            } else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
    };

    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [previewDeletion, setPreviewDeletion] = useState<number[]>([]);
    const [previewPrices, setPreviewPrices] = useState<
        { id: number; price: number; addon_price: number; isProcentual: boolean }[]
    >([]);
    const [showModal, setShowModal] = useState(false);

    const { data: filter } = useGetPropertyProductsListFilters(Number(propertyId));
    const spaceFilter = filter?.spaces;
    const productTypesFilter = filter?.productGroups;
    const packageFilters = filter?.packages;

    const navigate = useNavigate();

    //Get all filters when initial loads are done
    const filterOptions = useMemo(() => {
        const filteredFilterData: PropertyFilterProducts = {
            space: [],
            product_group: [],
            package: [],
        };
        if (spaceFilter && productTypesFilter && packageFilters) {
            spaceFilter.forEach((data) => {
                if (
                    filteredFilterData?.space &&
                    !filteredFilterData.space.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.space.push({ value: data.name.toString(), label: data.name });
                }
            });

            productTypesFilter.forEach((data) => {
                if (
                    filteredFilterData?.product_group &&
                    !filteredFilterData.product_group.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.product_group.push({ value: data.name.toString(), label: L(data.name) });
                }
            });
            packageFilters.forEach((data) => {
                if (
                    filteredFilterData?.package &&
                    !filteredFilterData.package.find((filter) => filter.value === data.package_name)
                ) {
                    filteredFilterData.package.push({
                        value: data.id.toString(),
                        label: data.package_name ? data.package_name : L('name_missing'),
                    });
                }
            });
        }
        return filteredFilterData;
    }, [spaceFilter, productTypesFilter, packageFilters]);

    const optionMetadata = useMemo(
        () => [
            { name: 'space', initialTexts: [L('space'), L('spaces')], api: 'space' },
            { name: 'product_group', initialTexts: [L('producttype'), L('producttypes')], api: 'productgroup' },
            { name: 'package', initialTexts: [L('package'), L('package')], api: 'count' },
        ],
        [],
    );

    const spaces = searchParams.getAll('space');

    const productGroups = searchParams.getAll('product_group');

    const packageParam = searchParams.getAll('package');

    const setSelectedSpaces = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('space', onlyValues);
    };

    const setSelectedProductTypes = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('product_group', onlyValues);
    };

    const setSelectedPackages = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('package', onlyValues);
    };

    //Get from URL params the selected filters
    const selectedFiltersWithLabel = {
        selectedSpaces: spaces.flatMap((space) => {
            const option = filterOptions.space.find((filter) => filter.value === space);
            return option ?? [];
        }),
        selectedProductTypes: productGroups.flatMap((type) => {
            const option = filterOptions.product_group.find((filter) => filter.value === type);
            return option ?? [];
        }),
        selectedPackages: packageParam.flatMap((type) => {
            const option = filterOptions.package.find((filter) => filter.value === type);
            return option ?? [];
        }),
    };

    const toggleModal = () => {
        setShowModal((oldModal) => !oldModal);
    };

    const clearInputParams = (key: string, value: string, searchParams: URLSearchParams) => {
        const filteredParams = searchParams.getAll(key).filter((param) => param !== value);
        searchParams.delete(key);
        filteredParams.forEach((val) => searchParams.append(key, val));
        return searchParams;
    };

    //When you click X button next to selected filter, this triggers
    const clearInput = (type: keyof FiltersProducts, value: string) => {
        if (type === 'space') {
            setSearchParams((searchParams) => {
                return clearInputParams('space', value, searchParams);
            });
        }

        if (type === 'product_group') {
            setSearchParams((searchParams) => {
                return clearInputParams('product_group', value, searchParams);
            });
        }

        if (type === 'package') {
            setSearchParams((searchParams) => {
                return clearInputParams('package', value, searchParams);
            });
        }
    };

    //Change a single cell
    const setSingleCell = (id: number, price: number, addon_price: string) => {
        const previewPricesCopy = [...previewPrices];
        const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === id);

        if (foundIndex >= 0) {
            previewPricesCopy[foundIndex].price = Math.round(price);
            previewPricesCopy[foundIndex].isProcentual = false;
        } else
            previewPricesCopy.push({
                id,
                price: Math.round(price),
                addon_price: Number(addon_price),
                isProcentual: false,
            });
        setPreviewPrices(previewPricesCopy);
    };

    const { fetchNextPage, hasNextPage, isFetching, data, isLoading } = useGetPropertyProductList({
        filters: selectedFiltersWithLabel,
        sortOptions: sorting,
        propertyId: +propertyId,
        search: searchField,
    });

    // filter data
    const filteredData = useMemo(() => {
        if (!data) return [];
        const allItems = data.pages.map((page) => page.items).flatMap((item) => item);
        return allItems.map((data, index) => {
            return {
                ...data,
                stagedDeletion: previewDeletion.length > 0 && previewDeletion.includes(data.id),
                newPrice: previewPrices.length > 0 && previewPrices.find((price) => price.id === index),
            };
        });
    }, [filterOptions, data, previewDeletion, previewPrices]);

    const tableData = useReactTable({
        data: filteredData,
        columns,
        state: {
            sorting,
            rowSelection,
            columnVisibility: localStorageValue,
        },
        onColumnVisibilityChange: setLocalStorageValue,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        manualSorting: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        getRowId: (originalRow) => originalRow.id.toString(),
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        enableRowSelection: true,
        meta: {
            updateData: ({ id, price, addon_price }: NewPrice) => {
                setSingleCell(id, price, addon_price);
            },
            previewPrices,
            previewDeletion,
        } as TableMeta,
    });

    const totalDBRowCount = Number(data?.pages?.[0]?.totalItems ?? 0);
    const totalFetched = filteredData.length;

    const previewPrice = (price: number, isProcentual: boolean) => {
        const previewPricesCopy = [...previewPrices];
        tableData.getSelectedRowModel().flatRows.forEach((item) => {
            const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === item.original.id);
            if (foundIndex >= 0) {
                previewPricesCopy[foundIndex].price = price;
                previewPricesCopy[foundIndex].isProcentual = isProcentual;
            } else if ((item.original.addon_price || !isProcentual) && price >= 0) {
                previewPricesCopy.push({
                    id: item.original.id,
                    price,
                    addon_price: Number(item.original.addon_price),
                    isProcentual,
                });
            }
        });
        setPreviewPrices([...previewPricesCopy]);
    };

    const cancelPrice = () => {
        setPreviewPrices([]);
    };

    const { mutate: updatePrice } = useUpdateProductPrices();

    const confirmPrice = async () => {
        const stagedItems = previewPrices
            .map((item) => {
                if (item.price) {
                    if (item.isProcentual) {
                        const calculatedPrice = procentualAdd(item.price, Number(item.addon_price));
                        return {
                            id: Number(item.id),
                            price: calculatedPrice && calculatedPrice >= 0 ? calculatedPrice : Number(item.addon_price),
                        };
                    } else {
                        return {
                            id: Number(item.id),
                            price: item.price >= 0 ? item.price : Number(item.addon_price),
                        };
                    }
                }
            })
            .flatMap((item) => (item ? [item] : []));
        if (stagedItems)
            updatePrice(stagedItems, {
                onSuccess: (changedItems) => {
                    setPreviewPrices([]);
                    toast.success(
                        `${L('edited')} ${changedItems} ${L(
                            changedItems === 1 ? 'product' : 'products',
                        ).toLowerCase()}`,
                    );
                },
                onError: () => {
                    toast.error(L('something_went_wrong'));
                },
            });
    };

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Dialog content={{ style: { width: 'auto' } }} open={open} onOpenChange={setOpen}>
                <AddProductFromTemplate />
            </Dialog>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="../members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="../contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="../todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="../documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <Comments showModal={showModal} toggleModal={toggleModal} />
                        <CardTitle>
                            {L('products')} {data ? `(${data?.pages[0].totalItems})` : ''}
                        </CardTitle>
                        <div style={{ display: 'flex', columnGap: margin.s, marginLeft: 'auto' }}>
                            <ExportToExcel
                                fileName={'Översiktsinventarie'}
                                projectId={Number(projectId)}
                                filters={selectedFiltersWithLabel}
                                search={searchField}
                            />
                            <Button onClick={() => navigate('new')}>{L('new_product')}</Button>
                            <Button onClick={() => setOpen(true)}>{L('new_product_from_template')}</Button>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <FilterSearchContainer>
                            <ContentContainer>
                                <ProductsFilters
                                    options={options}
                                    filterOptions={filterOptions}
                                    selected={{
                                        selectedSpaces: selectedFiltersWithLabel.selectedSpaces,
                                        selectedProductTypes: selectedFiltersWithLabel.selectedProductTypes,
                                        selectedPackages: selectedFiltersWithLabel.selectedPackages,
                                    }}
                                    setSelected={{
                                        setSelectedSpaces,
                                        setSelectedProductTypes,
                                        setSelectedPackages,
                                    }}
                                    clearAll={handleClearAll}
                                />
                            </ContentContainer>
                            <ContentContainer>
                                <PaddedContainer
                                    style={{
                                        display: 'flex',
                                        columnGap: '1rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            columnGap: '0.5rem',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {`${L('show_with_image')}:`}
                                        <Checkbox
                                            checked={tableData.getColumn('image')?.getIsVisible()}
                                            onChange={tableData.getColumn('image')?.getToggleVisibilityHandler()}
                                        />
                                    </div>
                                    <Row>
                                        <GlobalFilter
                                            filter={searchField}
                                            setFilter={handleSearch}
                                            placeholder={L('search')}
                                        />
                                    </Row>
                                </PaddedContainer>
                            </ContentContainer>
                        </FilterSearchContainer>
                        {filterOptions && (
                            <ChosenFilters
                                inputs={{
                                    space: selectedFiltersWithLabel.selectedSpaces,
                                    product_group: selectedFiltersWithLabel.selectedProductTypes,
                                    package: selectedFiltersWithLabel.selectedPackages ?? [],
                                }}
                                onRemove={clearInput}
                                optionMetadata={optionMetadata}
                            />
                        )}
                        <TableMenu
                            selectedCount={Object.keys(rowSelection).length}
                            previewDeletion={previewDeletion}
                            changedPrices={previewPrices.length > 0}
                            setPreviewDeletion={setPreviewDeletion}
                            onCancelPrice={cancelPrice}
                            previewPrice={previewPrice}
                            confirmPrice={confirmPrice}
                            tableData={tableData}
                            resetRowSelection={() => {
                                setRowSelection({});
                            }}
                        />
                        <GenericTableVirtualized
                            table={tableData}
                            handleFetchNextPage={() => {
                                fetchNextPage();
                            }}
                            hasNextPage={hasNextPage}
                            isFetching={isFetching}
                            totalDBRowCount={totalDBRowCount}
                            totalFetched={totalFetched}
                            estimateSize={50}
                            rowClickHandler={(row) => {
                                navigate(`${row.original.id}`);
                            }}
                        />
                        {filteredData?.length === 0 &&
                            data &&
                            (!searchField ? (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_on_property_description')}</p>
                                        <p>{L('click_button_below')}</p>
                                        <Button onClick={() => navigate('new')}>{L('new_product')}</Button>
                                    </EmptyState.Description>
                                </EmptyState>
                            ) : (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_found_description')}</p>
                                        <p>{L('please_try_something_else')}</p>
                                    </EmptyState.Description>
                                </EmptyState>
                            ))}
                    </>
                }
            />
        </>
    );
};

const PropertyProductsPage = () => {
    const { id: projectId, propertyId } = useParams<{ id: string; propertyId: string }>();
    return projectId && propertyId ? <PropertyProductsComponent projectId={projectId} propertyId={propertyId} /> : null;
};

export default PropertyProductsPage;
