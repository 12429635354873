import React, { ComponentProps, useMemo, useState } from 'react';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import styled from '@emotion/styled';
import { useGetPropertyDocumentTypes } from '../../binders/hooks/propertyHooks/propertyQueries';
import { useGetDocumentSubtypes } from '../../binders/hooks/documentTypeQueries';
import { L } from '../../../lib/i18n';
import Dialog from '../../../components/Dialog/Dialog';
import { Button } from '@ourliving/ourliving-ui';
import DocumentForm from '../components/DocumentForm';
import { LocalPropertyDocument } from '../../binders/schemas';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Loading } from '../../../components/Loading/Loading';
import GenericTable from '../../binders/components/GenericTable';
import CopyToPropertyiesList from '../../binders/components/CopyToPropertiesList/CopyToPropertiesList';
import propertiesColumns from '../../binders/components/CopyToPropertiesList/docCopyPageColumns';
import DropdownContainer from '../../binders/components/emotion/DropdownContainer';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { useGetProjectDocuments, useGetProjects } from '../../binders/hooks/projectHooks/projectQueries';
import { useLocalStorage } from 'react-use';
import FormatOptionsLabel from '../../binders/components/FormatOptionsLabel';
import useUploadPropertyDocsToMultiple from '../hooks/useUploadPropertyDocsToMultiple';
import toast from 'react-hot-toast';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import useCopyDocumentsListColumns from './CopyDocumentsListColumns';
import H1 from '../../../components/typography/H1';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import useProjectDocumentColumns from './ProjectDocumentColumns';
import useCopyPropertyDocuments from '../hooks/useCopyPropertyDocuments';
import GenericTableVirtualized from '../../binders/components/GenericTableVirtualized';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import DocumentFormMultiple from '../components/DocumentFormMultiple';

const StyledCardWithDivider = styled(CardWithDivider)({
    alignSelf: 'start',
    '& .main': {
        overflowY: 'auto',
        maxHeight: '60vh',
    },
});

const StyledCardWithDividerNoMaxHeight = styled(CardWithDivider)({
    alignSelf: 'start',
    '& .main': {
        overflowY: 'auto',
    },
});

type SubmitParams = Parameters<ComponentProps<typeof DocumentForm>['handleDocSaveMutation']>[0];

export type SubmitMultipleParams = {
    document_type: SubmitParams['document_type'];
    document_subtype?: SubmitParams['document_subtype'];
    files: File[];
};

const Grid = styled.div({
    display: 'grid',
    gap: '1rem',
    alignItems: 'center',
});

const CopyDocumentsPage = () => {
    const { data: documentTypes, status: documentTypesStatus } = useGetPropertyDocumentTypes();
    const { data: documentSubtypes, status: documentSubtypesStatus } = useGetDocumentSubtypes();
    const columns = useCopyDocumentsListColumns();
    const projectDocumentColumns = useProjectDocumentColumns();
    const [numberOfProperties, setNumberOfProperties] = useState<number | null>(null);
    const { mutate: uploadPropertyDocs } = useUploadPropertyDocsToMultiple();
    const { data: projects } = useGetProjects();
    const [open, setOpen] = useState(false);
    const [openUploadMultiple, setOpenUploadMultiple] = useState(false);
    const [propertySearch, setPropertySearch] = useState('');
    const [documentsState, setDocumentsState] = useState<LocalPropertyDocument[]>([]);
    const [localStorageValue, setLocalStorageValue] = useLocalStorage<{
        project: { value: string; label: string } | null;
        selectedProperties: Record<string, boolean>;
    }>('copyDocumentsPageSelection', {
        project: null,
        selectedProperties: {},
    });
    const { mutate: copyPropertyDocs } = useCopyPropertyDocuments();

    const [originProject, setOriginProject] = useState<{ value: string; label: string } | null>(null);

    const { data: documentsData, status: projectDocsQueryStatus } = useGetProjectDocuments({
        projectId: originProject?.value ? +originProject.value : null,
        search: propertySearch,
    });

    const documents = useMemo(() => documentsData ?? [], [documentsData]);

    const setProject = (val: { value: string; label: string } | null) =>
        setLocalStorageValue({
            project: val,
            selectedProperties: {},
        });

    const setSelectedProperties = (val: Record<string, boolean>) => {
        setLocalStorageValue({
            selectedProperties: val,
            project: localStorageValue?.project ?? null,
        });
    };

    const numberOfSelectedProperties = localStorageValue?.selectedProperties
        ? Object.keys(localStorageValue.selectedProperties).length
        : 0;

    const onFormSubmit = (data: SubmitParams) => {
        const newDocumentsState = [...documentsState, { ...data, id: crypto.randomUUID() }];
        setDocumentsState(newDocumentsState);
        setOpen(false);
    };

    const onFormSubmitMultiple = (data: SubmitMultipleParams) => {
        const newDocuments = data.files.map((file) => ({
            id: crypto.randomUUID(),
            name: file.name,
            document_type: data.document_type,
            file: file,
            document_subtype: data.document_subtype,
        }));
        const newDocumentsState = [...documentsState, ...newDocuments];
        setDocumentsState(newDocumentsState);
        setOpenUploadMultiple(false);
    };

    const table = useReactTable({
        data: documentsState,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        meta: {
            removeRow: (id: string) => {
                const filteredDocArr = documentsState.filter((doc) => doc.id !== id);
                setDocumentsState(filteredDocArr);
            },
            clearRows: () => {
                setDocumentsState([]);
            },
            numberOfDocs: documentsState.length,
        },
    });

    const projectDocTable = useReactTable({
        data: documents,
        columns: projectDocumentColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getRowId: (row) => row.id.toString(),
    });

    const numberOfSelectedProjectDocs = Object.keys(projectDocTable.getState().rowSelection).length;

    const projectOption =
        projects?.map((option) => ({
            value: String(option.id),
            label: String(option.name),
            template: option.template,
        })) ?? [];

    return (
        <>
            <H1>{L('document_management')}</H1>
            <BigTabNav>
                <BigNavItem end to=".">
                    {L('document_copying')}
                </BigNavItem>
                <BigNavItem end to="deletion">
                    {L('document_deletion')}
                </BigNavItem>
            </BigTabNav>
            <Dialog open={!!open} onOpenChange={setOpen}>
                {documentTypesStatus === 'loading' || (documentSubtypesStatus === 'loading' && <Loading />)}
                {documentTypes && documentSubtypes && (
                    <DocumentForm
                        documentSubtypes={documentSubtypes}
                        documentTypes={documentTypes}
                        handleDocSaveMutation={onFormSubmit}
                    />
                )}
            </Dialog>
            <Dialog open={!!openUploadMultiple} onOpenChange={setOpenUploadMultiple}>
                {documentTypesStatus === 'loading' || (documentSubtypesStatus === 'loading' && <Loading />)}
                {documentTypes && documentSubtypes && (
                    <DocumentFormMultiple
                        documentSubtypes={documentSubtypes}
                        documentTypes={documentTypes}
                        handleDocSaveMutation={onFormSubmitMultiple}
                    />
                )}
            </Dialog>
            <Grid>
                <StyledCardWithDividerNoMaxHeight
                    style={{
                        height: '100%',
                    }}
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardTitle>
                                {L('property_documents')}{' '}
                                {`(${Object.keys(projectDocTable.getState().rowSelection).length}/${documents.length})`}
                            </CardTitle>
                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '1rem',
                                }}
                            >
                                <DropdownContainer>
                                    <ReactSelect
                                        placeholder={L('project')}
                                        options={projectOption}
                                        value={originProject ?? null}
                                        onChange={(value) => {
                                            setOriginProject(value);
                                        }}
                                        isSearchable={true}
                                        width={'140px'}
                                        formatOptionLabel={FormatOptionsLabel}
                                    />
                                </DropdownContainer>
                                <GlobalFilter
                                    filter={propertySearch}
                                    setFilter={setPropertySearch}
                                    placeholder={L('search')}
                                />
                            </div>
                        </div>
                    }
                    mainArea={
                        <>
                            {projectDocsQueryStatus === 'loading' ? (
                                <>
                                    <div>
                                        <Loading />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <GenericTableVirtualized
                                        table={projectDocTable}
                                        estimateSize={50}
                                        shouldFillWidth={false}
                                    />
                                    {documents.length === 0 && originProject !== null && (
                                        <EmptyState>
                                            <EmptyState.Title>{L('no_documents')}</EmptyState.Title>
                                            <EmptyState.Description>
                                                {L('no_documents_found_description')}
                                            </EmptyState.Description>
                                        </EmptyState>
                                    )}
                                    {documents.length === 0 && originProject === null && (
                                        <EmptyState>
                                            <EmptyState.Title>{L('no_documents')}</EmptyState.Title>
                                            <EmptyState.Description>
                                                {L('no_property_documents_copy_function_help')}
                                            </EmptyState.Description>
                                        </EmptyState>
                                    )}
                                </>
                            )}
                        </>
                    }
                />
                <StyledCardWithDivider
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardTitle>
                                {L('new_documents')} {`(${documentsState?.length ?? 0})`}
                            </CardTitle>
                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <Button onClick={() => setOpen(true)}>{L('new_document')}</Button>
                                <Button onClick={() => setOpenUploadMultiple(true)}>{L('new_documents')}</Button>
                            </div>
                        </div>
                    }
                    mainArea={
                        <>
                            <GenericTable table={table} shouldFillWidth={false} />
                            {documentsState.length === 0 && (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_documents')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        {L('no_documents_copy_function_description')}
                                    </EmptyState.Description>
                                </EmptyState>
                            )}
                        </>
                    }
                />
                <StyledCardWithDivider
                    style={{
                        height: '100%',
                    }}
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardTitle>{`${L('properties')} (${numberOfSelectedProperties}/${
                                numberOfProperties ?? 0
                            })`}</CardTitle>
                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '1rem',
                                }}
                            >
                                <DropdownContainer>
                                    <ReactSelect
                                        placeholder={L('project')}
                                        options={projectOption}
                                        value={localStorageValue?.project ?? null}
                                        onChange={(value) => {
                                            projectDocTable.resetRowSelection();
                                            setProject(value);
                                        }}
                                        isSearchable={true}
                                        width={'140px'}
                                        formatOptionLabel={FormatOptionsLabel}
                                    />
                                </DropdownContainer>
                                <Button
                                    onClick={() => {
                                        if (!localStorageValue) return;
                                        const propertyIds = Object.keys(localStorageValue.selectedProperties).map(
                                            (id) => +id,
                                        );
                                        if (propertyIds.length === 0) return;

                                        if (documentsState.length > 0) {
                                            uploadPropertyDocs(
                                                {
                                                    documents: documentsState,
                                                    propertyIds: propertyIds,
                                                },
                                                {
                                                    onSuccess: (data) => {
                                                        toast.success(
                                                            `${L('number_of_new_documents_created')}: ${data.length} `,
                                                        );
                                                    },
                                                    onError: () => {
                                                        toast.error(L('an_error_occurred'));
                                                    },
                                                },
                                            );
                                        }
                                        if (numberOfSelectedProjectDocs > 0) {
                                            copyPropertyDocs({
                                                propertyIds,
                                                documentIds: Object.keys(projectDocTable.getState().rowSelection).map(
                                                    (id) => +id,
                                                ),
                                            });
                                        }
                                    }}
                                    disabled={
                                        !localStorageValue?.selectedProperties ||
                                        Object.keys(localStorageValue.selectedProperties).length === 0 ||
                                        (numberOfSelectedProjectDocs === 0 && documentsState.length === 0)
                                    }
                                >
                                    {L('copy')}
                                </Button>
                            </div>
                        </div>
                    }
                    mainArea={
                        <>
                            {localStorageValue && (
                                <div>
                                    <CopyToPropertyiesList
                                        search={''}
                                        projectId={
                                            localStorageValue.project?.value ? +localStorageValue.project.value : null
                                        }
                                        handleSetSelectedProperties={(val) => {
                                            setSelectedProperties(val);
                                        }}
                                        handleSetNumberOfProperties={(val: number | null) => setNumberOfProperties(val)}
                                        selectedProperties={localStorageValue.selectedProperties}
                                        columns={propertiesColumns}
                                    />
                                </div>
                            )}
                        </>
                    }
                />
            </Grid>
        </>
    );
};

export default CopyDocumentsPage;
