import React from 'react';
import styled from '@emotion/styled';
import CameraIcon from '../../../public/assets/camera.svg';

const Container = styled.div({
    padding: '5px',
    borderRadius: '4px',
    backgroundColor: 'white',
    marginRight: '1rem',
    marginBottom: '1rem'
});
const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
});
const Text = styled.span({
    cursor: 'pointer',
    marginLeft: '0.5rem',
    fontSize: '0.6rem',
    margin: 'auto 0px auto 5px'
});

const EditHeroImage = () => {
    return (
        <Container>
            <Wrapper>
                <CameraIcon width="20" height="20" />
                <Text>Lägg till typskylt</Text>
            </Wrapper>
        </Container>
    );
};

export default EditHeroImage;
