import React, { useState } from 'react';
import styled from '@emotion/styled';
import useDeleteSettingsListByType from '../hooks/useDeleteSettingsListByType';
import useSaveSettingsListByType from '../hooks/useSaveSettingsListByType';
import { Icon } from '../../../components/Icon/Icon';
import { MaintenanceCategory } from '@app/api/models/Maintenances';
import { L } from '../../../lib/i18n';

const Item = styled.input({
    backgroundColor: 'gray',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid black',
    width: '300px',
    margin: '1rem',
    paddingLeft: '1rem',
    color: 'white',
    '&:focus': {
        outline: 'none'
    }
});
const ItemWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});
const SettingsIcon = styled(Icon.Settings)({
    margin: '0.3rem',
    cursor: 'pointer'
});
const TrashCanIcon = styled(Icon.TrashCan)({
    margin: '0.3rem',
    cursor: 'pointer'
});
const InvisibleButton = styled.button({
    backgroundColor: 'transparent',
    border: 'none'
});
const Save = styled.div({
    backgroundColor: '#e0e0e0',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '4px'
});

type Props = {
    item: MaintenanceCategory;
    settingsType: 'category' | 'status' | 'unit' | 'type';
};

const SettingItem = ({ item, settingsType }: Props) => {
    const [name, setName] = useState(item.name);
    const { mutate: remove } = useDeleteSettingsListByType();
    const [editMode, setEditMode] = useState(false);
    const { mutate: save } = useSaveSettingsListByType(settingsType);

    return (
        <ItemWrapper key={item.id}>
            <Item readOnly={!editMode} onChange={e => setName(e.target.value)} value={name} />
            <InvisibleButton onClick={() => setEditMode(prev => !prev)}>
                {editMode && <Save onClick={() => save({ id: item.id, name: name })}>{L('save')}</Save>}
                <SettingsIcon />
            </InvisibleButton>
            <InvisibleButton onClick={() => remove({ id: item.id, settingstype: settingsType })}>
                <TrashCanIcon />
            </InvisibleButton>
        </ItemWrapper>
    );
};

export default SettingItem;
