import React from 'react';
import { Property } from '@app/api/models/Properties';
import styled from '@emotion/styled';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import RadialProgressBar from '../../../components/ProgressBar/RadialProgressBar';
import useGetPropertiesByProject from '../hooks/useGetPropertiesByProject';

const Card = styled(NavLink)({
    padding: '12px',
    borderBottom: '1px solid #979797',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:nth-of-type(1)': {
        borderTop: '1px solid #979797'
    },
    ['@media screen and (min-width: 1200px)']: {
        '&.active': { background: '#F0F1F7'}
     },
});
const InfoWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 300
});
const PropertyName = styled.span({
    color: '#444444',
    fontSize: '16px',
    fontWeight: 300,
    flex: '1',
    display: 'flex',
    flexDirection: 'column'
});

const Address = styled.span({
    color: '#444444',
    fontSize: '12px',
    fontWeight: 300
});

const RadialContainer = styled.div({
    width: '40px'
});

const PropertyComponent = ({ property, ...rest }: { property: Property }) => {
    const params = useParams<{ spaceId: string; projectId: string }>();
    const { data: propertyData } = useGetPropertiesByProject();

    

    const history = useHistory();

    const propertyItem = propertyData?.find(item => item.name === property.name);

    return (
        <Card
        {...rest}
         to={`/inventory/project/${params.projectId}/property/${property.id}`}
        
        >
            <InfoWrapper>
                <PropertyName>
                    {propertyItem?.name}
                    <Address>
                        {propertyItem?.address?.street}, {propertyItem?.address?.city}
                    </Address>
                </PropertyName>
                <RadialContainer>
                    <RadialProgressBar current={propertyItem?.completed} total={propertyItem?.total} />
                </RadialContainer>
            </InfoWrapper>
        </Card>
    );
};

export default PropertyComponent;
