import styled from '@emotion/styled';

export const TextButton = styled.div({
    fontWeight: 'bolder',
    textAlign: 'right',
    margin: 0,
    [':hover']: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});
