import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { RouterOutput } from '../../../../lib/trpc';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { L } from '../../../../lib/i18n';
import { fontSize } from '../../../../components/Shared/Style/typography';
import { colors } from '@ourliving/ourliving-ui';

type PropertyType = RouterOutput['BindersApi']['getProperties'][0];

const columnHelper = createColumnHelper<PropertyType>();
const columns = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
        enableResizing: false,
    }),
    columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: () => L('name'),
        cell: (info) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {info.row.original?.template ? (
                    <span style={{ fontSize: fontSize.milli, color: colors.all.brandGreen }}>{L('template')}</span>
                ) : (
                    <></>
                )}
                <span>{info.row.original?.name}</span>
            </div>
        ),
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.address?.apartmentNumber, {
        id: 'apartmentNumber',
        header: () => L('member_property_number'),
        cell: (info) => <div>{info.row.original?.address?.apartmentNumber}</div>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.space_count, {
        id: 'spaceCount',
        header: () => L('number_of_spaces'),
        cell: (info) => <div>{info.row.original.space_count}</div>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
];

export default columns;
