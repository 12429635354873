import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import ProductsPage from './pages/ProductsPage';
import LibraryPage from './pages/LibraryPage';
import CopyToPage from './pages/CopyToPage';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { useBasket } from '../binders/hooks/useBasket';
import { colors } from '../../components/Shared/Style/colors';
import { L } from '../../lib/i18n';
import AddProductPage from './pages/AddProductPage';
import EditProductCopyFunctionPage from './pages/EditProductCopyFunctionPage';
import EditInventoryTemplatePage from '../inventory-library/pages/EditInventoryTemplatePage';
import PackageProductsPage from './pages/PackageProductsPageCopyFunction';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import PackageProductsLibraryPage from './pages/PackageProductsLibraryPage';
import AddNewProductToPackage from './pages/AddNewProductToPackage';
import AddNewProductToTemplatePackage from './pages/AddNewProductToTemplatePackage';

const Basket = styled.div({
    marginLeft: 10,
    display: 'flex',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    justifyContent: 'center',
    paddingBottom: '0.25rem',
    alignItems: 'center',
    borderRadius: '20px',
    backgroundColor: colors.primary,
    color: 'white',
    textAlign: 'center',
});

const FlexContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
});

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const BreadcrumbsNav = () => {
    return (
        <Routes>
            <Route path="/copy-function">
                <Route index element={null} />
                <Route path="product-list" element={null} />
                <Route path="library">
                    <Route index element={null} />
                    <Route path=":id">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="..">{L('library')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>{L('product')}</Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                        <Route path="package">
                            <Route
                                index
                                element={
                                    <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                        <Breadcrumbs.Item to="../..">{L('library')}</Breadcrumbs.Item>
                                        <Breadcrumbs.ItemActive>{L('product')}</Breadcrumbs.ItemActive>
                                    </Breadcrumbs>
                                }
                            />
                            <Route path="new">
                                <Route
                                    index
                                    element={
                                        <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                            <Breadcrumbs.Item to="../../..">
                                                {L('existing_properties')}
                                            </Breadcrumbs.Item>
                                            <Breadcrumbs.Item to="..">{L('content_of_packages')}</Breadcrumbs.Item>
                                            <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                                        </Breadcrumbs>
                                    }
                                />
                            </Route>
                            <Route path=":packageItemId">
                                <Route
                                    index
                                    element={
                                        <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                            <Breadcrumbs.Item to="../../..">{L('library')}</Breadcrumbs.Item>
                                            <Breadcrumbs.Item to="..">{L('product')}</Breadcrumbs.Item>
                                            <Breadcrumbs.ItemActive>{L('content_of_packages')}</Breadcrumbs.ItemActive>
                                        </Breadcrumbs>
                                    }
                                />
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route
                    path="new"
                    element={
                        <Breadcrumbs style={{ marginBottom: '1rem' }}>
                            <Breadcrumbs.Item to="..">{L('existing_properties')}</Breadcrumbs.Item>
                            <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                        </Breadcrumbs>
                    }
                />
                <Route path=":productId">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('existing_properties')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{L('product')}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                    <Route path="package">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="../..">{L('existing_properties')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>{L('product')}</Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                        <Route path="new">
                            <Route
                                index
                                element={
                                    <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                        <Breadcrumbs.Item to="../../..">{L('existing_properties')}</Breadcrumbs.Item>
                                        <Breadcrumbs.Item to="..">{L('content_of_packages')}</Breadcrumbs.Item>
                                        <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                                    </Breadcrumbs>
                                }
                            />
                        </Route>
                        <Route path=":packageItemId">
                            <Route
                                index
                                element={
                                    <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                        <Breadcrumbs.Item to="../../..">{L('existing_properties')}</Breadcrumbs.Item>
                                        <Breadcrumbs.Item to="..">{L('product')}</Breadcrumbs.Item>
                                        <Breadcrumbs.ItemActive>{L('content_of_packages')}</Breadcrumbs.ItemActive>
                                    </Breadcrumbs>
                                }
                            />
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

const Tabs = () => {
    const [storedIds] = useBasket();
    return (
        <Routes>
            <Route path="/copy-function">
                <Route
                    index
                    element={
                        <Menu>
                            <BigTabNav>
                                <BigNavItem end to={'.'}>
                                    {L('existing_properties')}
                                </BigNavItem>
                                <BigNavItem end to={`library`}>
                                    {L('library')}
                                </BigNavItem>
                                <FlexContainer>
                                    <BigNavItem end to={`product-list`}>
                                        {L('product_basket')}
                                    </BigNavItem>
                                    <Basket>{storedIds.length > 999 ? '999+' : storedIds.length}</Basket>
                                </FlexContainer>
                            </BigTabNav>
                        </Menu>
                    }
                />
                <Route path="library">
                    <Route
                        index
                        element={
                            <Menu>
                                <BigTabNav>
                                    <BigNavItem end to={'..'}>
                                        {L('existing_properties')}
                                    </BigNavItem>
                                    <BigNavItem end to={`.`}>
                                        {L('library')}
                                    </BigNavItem>
                                    <FlexContainer>
                                        <BigNavItem end to={`../product-list`}>
                                            {L('product_basket')}
                                        </BigNavItem>
                                        <Basket>{storedIds.length > 999 ? '999+' : storedIds.length}</Basket>
                                    </FlexContainer>
                                </BigTabNav>
                            </Menu>
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <Menu>
                                <BigTabNav>
                                    <BigNavItem end to=".">
                                        {L('edit_product')}
                                    </BigNavItem>
                                    <BigNavItem end to="package">
                                        {L('content_of_packages')}
                                    </BigNavItem>
                                </BigTabNav>
                            </Menu>
                        }
                    >
                        <Route
                            path="package"
                            element={
                                <Menu>
                                    <BigTabNav>
                                        <BigNavItem end to="..">
                                            {L('edit_product')}
                                        </BigNavItem>
                                        <BigNavItem end to=".">
                                            {L('content_of_packages')}
                                        </BigNavItem>
                                    </BigTabNav>
                                </Menu>
                            }
                        />
                    </Route>
                </Route>
                <Route path="product-list">
                    <Route
                        index
                        element={
                            <Menu>
                                <BigTabNav>
                                    <BigNavItem end to={'..'}>
                                        {L('existing_properties')}
                                    </BigNavItem>
                                    <BigNavItem end to={`../library`}>
                                        {L('library')}
                                    </BigNavItem>
                                    <FlexContainer>
                                        <BigNavItem end to={`.`}>
                                            {L('product_basket')}
                                        </BigNavItem>
                                        <Basket>{storedIds.length > 999 ? '999+' : storedIds.length}</Basket>
                                    </FlexContainer>
                                </BigTabNav>
                            </Menu>
                        }
                    />
                </Route>
                <Route
                    path=":productId"
                    element={
                        <Menu>
                            <BigTabNav>
                                <BigNavItem end to=".">
                                    {L('edit_product')}
                                </BigNavItem>
                                <BigNavItem end to="package">
                                    {L('content_of_packages')}
                                </BigNavItem>
                            </BigTabNav>
                        </Menu>
                    }
                >
                    <Route
                        path="package"
                        element={
                            <Menu>
                                <BigTabNav>
                                    <BigNavItem end to="..">
                                        {L('edit_product')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('content_of_packages')}
                                    </BigNavItem>
                                </BigTabNav>
                            </Menu>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
};

export const CopyFunctionPage = () => {
    return (
        <>
            <BreadcrumbsNav />
            <Tabs />
            <Routes>
                <Route path="/copy-function">
                    <Route index element={<ProductsPage />} />
                    <Route path=":productId">
                        <Route index element={<EditProductCopyFunctionPage />} />
                        <Route path="package">
                            <Route index element={<PackageProductsPage />} />
                            <Route path="new">
                                <Route index element={<AddNewProductToPackage />} />
                            </Route>
                            <Route path=":packageItemId">
                                <Route index element={<EditProductCopyFunctionPage />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route path="library">
                        <Route index element={<LibraryPage />} />
                        <Route path="new">
                            <Route index element={<AddNewProductToTemplatePackage />} />
                        </Route>
                        <Route path=":id">
                            <Route index element={<EditInventoryTemplatePage />} />
                            <Route path="package">
                                <Route index element={<PackageProductsLibraryPage />} />
                                <Route path="new">
                                    <Route index element={<AddNewProductToTemplatePackage />} />
                                </Route>
                                <Route path=":packageItemId">
                                    <Route index element={<EditInventoryTemplatePage />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                    <Route path="product-list" element={<CopyToPage />} />
                    <Route path="new" element={<AddProductPage />} />
                </Route>
            </Routes>
        </>
    );
};
