import React from 'react';
import styled from '@emotion/styled';
import { style } from '../../components/Shared/Style';

const Page = styled.div({
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    fontFamily: 'Lato',
    lineHeight: '14px',
    flexDirection: 'column',
    fontWeight: style.fontWeight.thin,
    backgroundColor: style.colors.white
});

const Splash = styled.div({
    height: '60px',
    width: '60px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: `url('../favicon.ico')`,
});

export const SplashScreenPage = () => {
    return (
        <Page>
            <Splash />
        </Page>
    );
};
