import { useQuery } from 'react-query';
import ProjectsApi from '@app/api/public/ProjectsApi';

export const getAlertProjectsQueryKey = 'getAlertProjects';

const getProjects = async () => {
    return await ProjectsApi.list();
};

const useGetProjects = () => {
    return useQuery([getAlertProjectsQueryKey], () => getProjects());
};

export default useGetProjects;
