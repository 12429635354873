export const fontSize = {
    giga: 72,
    mega: 54,
    kilo: 36,
    alpha: 32,
    beta: 28,
    gamma: 24,
    delta: 18,
    epsilon: 16,
    seta: 14,
    centi: 13,
    milli: 12,
    micro: 10
};

export const fontWeight = {
    thin: 300,
    normal: 400,
    bold: 700
};

export const lineHeight = {
    normal: 1.5,
    tight: 1.3
};
