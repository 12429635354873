import styled from '@emotion/styled';
import React from 'react';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import LabelRequired from '../../../../components/Forms/LabelRequired';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddMemberSchema, AddMember } from '../../schemas';
import { style } from '../../../../components/Shared/Style';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '2rem'
});

type Props = {
    onSubmit: SubmitHandler<AddMember>;
    defaultValues?: Partial<AddMember>;
    formStyling?: React.CSSProperties;
};

const MemberForm = ({ onSubmit, defaultValues, formStyling }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<AddMember>({
        resolver: zodResolver(AddMemberSchema),
        defaultValues
    });

    return (
        <Form style={formStyling} onSubmit={handleSubmit(onSubmit)}>
            <ErrorAndInputWrapper errorMsg={L(errors.email?.message ?? '')}>
                <LabelRequired title={L('email')}>
                    <InputWithError isError={!!errors?.email} {...register('email')} />
                </LabelRequired>
            </ErrorAndInputWrapper>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={{ marginTop: '1rem' }} type="submit">
                    {L('save')}
                </Button>
            </div>
        </Form>
    );
};

export default MemberForm;
