Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getbyid"] = b.createRpcProxy("MembersApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("MembersApi", "remove", {
  "urlPrefix": "/rpc"
});
o["importMembers"] = b.createRpcProxy("MembersApi", "importMembers", {
  "urlPrefix": "/rpc"
});
o["addMember"] = b.createRpcProxy("MembersApi", "addMember", {
  "urlPrefix": "/rpc"
});
o["listByProperty"] = b.createRpcProxy("MembersApi", "listByProperty", {
  "urlPrefix": "/rpc"
});