import React from 'react';
import { Route } from 'react-router-dom';
import { TemplateSettingsPage } from './Routes';

export const routes = [<Route key={0} path="/template-settings" component={TemplateSettingsPage} />];

export const navigation = [
    {
        name: 'templates',
        link: '/template-settings',
        match: '/template-settings',
        icon: 'templates',
    },
];
