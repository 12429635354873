import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { getSettingsListByTypeKey } from './useGetSettingsListByType';
import {
    MaintenanceCategory,
    MaintenanceStatus,
    MaintenanceType,
    MaintenanceUnit,
    SettingsType
} from '@app/api/models/Maintenances';
type Variables = {
    id?: number;
    name: string;
    color_code?: string;
};
const saveSetting = async (settingstype: SettingsType, variables: Variables) => {
    let response: Promise<MaintenanceStatus | MaintenanceCategory | MaintenanceType | MaintenanceUnit | undefined>;
    switch (settingstype) {
        case 'category':
            response = MaintenancesApi.saveCategory(variables);
            break;
        case 'status':
            response = MaintenancesApi.saveStatus(variables);
            break;
        case 'type':
            response = MaintenancesApi.saveType(variables);
            break;
        case 'unit':
            response = MaintenancesApi.saveUnit(variables);
            break;
    }
    return response;
};

const useSaveSettingsListByType = (settingstype: SettingsType) => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveSetting(settingstype, variables), {
        onSuccess: () => {
            queryClient.invalidateQueries([getSettingsListByTypeKey, { settingstype }]);
        }
    });
};

export default useSaveSettingsListByType;
