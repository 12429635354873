import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { HTMLAttributes } from 'react';
import DocumentItem from '../../../components/document/DocumentItem';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { p } from '../../../components/Shared/Style/typography';
import { useGetTicketNotes } from '../hooks/ticketQueries';
import { Icon } from '../../../components/Icon/Icon';
import { useParams } from 'react-router';
import useRemoveTicketNote from '../hooks/useRemoveTicketNote';
import { useDocumentDetails } from '../../../lib/hooks/useGetDocumentDetail';

const StyledNote = styled.div({
    ...p,
    display: 'grid',
    gap: '0.5rem',
    width: '100%'
});

const NoteHeader = styled.div({
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: '1ch'
});

const StyledDocument = styled(DocumentItem)({
    backgroundColor: colors.myBubble,
    borderRadius: style.roundCornerSize.small,
    width: 'max-content',
    padding: '0.5rem',
    '& p': {
        margin: '0'
    }
});

const StyledNoteWrapper = styled.div({
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: '1fr max-content',
    minWidth: '20ch',
    padding: '0.75rem',
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    alignSelf: 'start',
    width: '100%'
});

type NoteProps = {
    note: NonNullable<ReturnType<typeof useGetTicketNotes>['data']>[0];
    readOnly?: boolean;
} & HTMLAttributes<HTMLDivElement>;
const Note = ({
    note: { text, created_name, updated_at, attachment_ids, id: noteId },
    readOnly,
    ...rest
}: NoteProps) => {
    const documentDetalQueries = useDocumentDetails(attachment_ids);
    const { id } = useParams<{ id: string }>();
    const Documents = documentDetalQueries?.map(query => {
        const data = query.data;
        if (!data) return null;
        return <StyledDocument key={data.id} document={data} />;
    });
    const { mutate } = useRemoveTicketNote();
    const date = () => {
        if (dayjs(updated_at).isSame(dayjs(), 'day')) {
            return dayjs(updated_at).format('HH:mm');
        }
        if (dayjs(updated_at).isSame(dayjs(), 'year')) {
            return dayjs(updated_at).format('MM-DD HH:mm');
        }
        return dayjs(updated_at).format('YYYY-MM-DD HH:mm');
    };

    const handleRemove = () => {
        mutate({ ticketId: +id, noteId });
    };
    return (
        <StyledNoteWrapper>
            <StyledNote {...rest}>
                <NoteHeader>
                    <span>{created_name}</span>
                    <span>{date()}</span>
                </NoteHeader>
                <pre>{text}</pre>
                {Documents}
            </StyledNote>
            {!readOnly && <Icon.TrashCan onClick={() => handleRemove()} style={{ alignSelf: 'center' }} />}
        </StyledNoteWrapper>
    );
};

export default Note;
