import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';

type ArchiveTicket = { ticketId: number };
const useArchiveTicket = () => {
    const client = useQueryClient();
    return useMutation((variables: ArchiveTicket) => TicketsApi.archiveTicket(variables.ticketId), {
        onSettled: (response, error, varables) => {
            if (varables.ticketId) {
                client.invalidateQueries(ticketQueryKeys.id(varables.ticketId.toString()));
            }
            client.invalidateQueries(ticketQueryKeys.list());
        }
    });
};

export default useArchiveTicket;
