import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getAdminKeys } from './useGetProjectAdmins';

const useAddProjectAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (options: { email: string; role: string; name: string; projectId: number }) => {
            return trpc.MembersApi.addAdminToProject.query(options);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getAdminKeys.lists());
            },
        },
    );
};

export default useAddProjectAdmin;
