import { z } from 'zod';

export const AddProjectSchema = z
    .object({
        name: z.string().min(1, { message: 'error_required' }),
        address: z.string().optional(),
        zip: z.string().optional(),
        city: z.string().optional(),
        commune: z.string().optional(),
        access_date: z
            .string()
            .optional()
            .transform((val) => {
                if (val === '') {
                    return null;
                }
                return val;
            }),
        addon_preview_date: z
            .string()
            .optional()
            .transform((val) => {
                if (val === '') {
                    return null;
                }
                return val;
            }),
        addon_start_date: z
            .string()
            .optional()
            .transform((val) => {
                if (val === '') {
                    return null;
                }
                return val;
            }),
        addon_end_date: z
            .string()
            .optional()
            .transform((val) => {
                if (val === '') {
                    return null;
                }
                return val;
            }),
        project_type: z
            .string()
            .optional()
            .transform((val) => {
                if (val) {
                    const valueAsNumber = +val;
                    if (valueAsNumber < 0) return undefined;
                    return valueAsNumber;
                }
                return undefined;
            }),
        created_at: z.string().optional(),
        updated_at: z.string().optional(),
        property_count: z
            .string()
            .transform((val) => {
                if (!val) return null;
                const valueAsNumber = +val;
                if (valueAsNumber <= 0) return undefined;
                return valueAsNumber;
            })
            .optional(),
    })
    .superRefine((val, ctx) => {
        if (!val.addon_start_date || !val.addon_end_date) return null;
        if (new Date(val.addon_start_date).getTime() > new Date(val.addon_end_date).getTime()) {
            ctx.addIssue({
                path: ['addon_start_date'],
                code: z.ZodIssueCode.invalid_date,
                message: 'project_addon_date_error',
            });
        }
    });

export type AddProject = z.infer<typeof AddProjectSchema>;

export const AddPropertySchema = z.object({
    name: z.string().min(1, { message: 'error_required' }),
    property_type: z.number().or(
        z.string({ required_error: 'error_required' }).transform((val, ctx) => {
            const parsed = parseInt(val);
            if (isNaN(parsed)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'error_required',
                });
                return z.NEVER;
            }
            return parsed;
        }),
    ),
    city: z.string().nullish(),
    floor: z.string().nullish(),
    house: z.string().nullish(),
    rooms: z.string().nullish(),
    floors: z.string().nullish(),
    parish: z.string().nullish(),
    street: z.string().nullish(),
    commune: z.string().nullish(),
    country: z.string().nullish(),
    heating: z.string().nullish(),
    bathrooms: z.string().nullish(),
    plot_area: z
        .number()
        .min(1, { message: 'empty_field_or_larger_than_zero' })
        .nullish()
        .or(
            z
                .nan()
                .transform(() => undefined)
                .optional(),
        ),
    stairwell: z.string().nullish(),
    built_year: z.string().nullish(),
    postalcode: z.string().nullish(),
    access_date: z.string().nullish(),
    description: z.string().nullish(),
    monthly_fee: z.string().nullish(),
    assess_value: z.string().nullish(),
    property_area: z
        .number()
        .min(1, { message: 'empty_field_or_larger_than_zero' })
        .nullish()
        .or(
            z
                .nan()
                .transform(() => undefined)
                .optional(),
        ),
    property_term: z.string().nullish(),
    property_number: z.string().nullish(),
    apartment_number: z.string().nullish(),
    percentage_of_community: z.string().nullish(),
});

export type AddProperty = z.infer<typeof AddPropertySchema>;

export const AddSpaceSchema = z.object({
    name: z.string().min(1, { message: 'error_required' }),
    space_type: z.coerce.number({ required_error: 'error_required', invalid_type_error: 'error_required' }),
    description: z.string().optional(),
    area: z
        .string()
        .optional()
        .refine((val) => (val === '' ? true : Number(val)), { message: 'String does not parse to number' }),
    width: z
        .string()
        .optional()
        .refine((val) => (val === '' ? true : Number(val)), { message: 'String does not parse to number' }),
    length: z
        .string()
        .optional()
        .refine((val) => (val === '' ? true : Number(val)), { message: 'String does not parse to number' }),
});

export type AddSpace = z.infer<typeof AddSpaceSchema>;

export const AddPropertyDocumentSchema = z
    .object({
        name: z.string().min(1, { message: 'error_required' }),
        document_type: z.number({ required_error: 'error_required', invalid_type_error: 'error_required' }),
        document_subtype: z
            .number({ required_error: 'error_required', invalid_type_error: 'error_required' })
            .optional(),
        file: z.instanceof(File, { message: 'please_select_a_file' }),
    })
    .superRefine((val, ctx) => {
        if ((val.document_type === 5 || val.document_type === 10) && val.document_subtype === undefined) {
            return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'error_required',
                path: ['document_subtype'],
            });
        }
    });

export type AddPropertyDocument = z.infer<typeof AddPropertyDocumentSchema>;
export type LocalPropertyDocument = z.infer<typeof AddPropertyDocumentSchema> & { id: string };

export const AddMultiplePropertyDocumentSchema = z
    .object({
        document_type: z.number({ required_error: 'error_required', invalid_type_error: 'error_required' }),
        document_subtype: z
            .number({ required_error: 'error_required', invalid_type_error: 'error_required' })
            .optional(),
        files: z.array(z.instanceof(File), { required_error: 'please_select_a_file' }),
    })
    .superRefine((val, ctx) => {
        if ((val.document_type === 5 || val.document_type === 10) && val.document_subtype === undefined) {
            return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'error_required',
                path: ['document_subtype'],
            });
        }
    });

export type AddMultiplePropertyDocument = z.infer<typeof AddMultiplePropertyDocumentSchema>;

export const EditPropertyDocTypeSchema = z
    .object({
        document_type: z.number({ required_error: 'error_required', invalid_type_error: 'error_required' }),
        document_subtype: z
            .number({ required_error: 'error_required', invalid_type_error: 'error_required' })
            .optional(),
    })
    .superRefine((val, ctx) => {
        if ((val.document_type === 5 || val.document_type === 10) && val.document_subtype === undefined) {
            return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'error_required',
                path: ['document_subtype'],
            });
        }
    });

export type EditPropertyDocType = z.infer<typeof EditPropertyDocTypeSchema>;

export const AddProductSchema = z.object({
    project_id: z.number({ required_error: 'error_required' }),
    property_id: z.number({ required_error: 'error_required' }),
    name: z.string().min(1, { message: 'error_required' }),
    brand_id: z.number().nullish(),
    parent_id: z.number({ required_error: 'error_required' }),
    package_type: z.string().optional(),
    supplier_id: z.number().nullish(),
    profession_id: z.number().nullish(),
    attr: z.object({
        product: z.string().min(1, { message: 'error_required' }),
        description: z.string().optional(),
        url: z.string().optional(),
        color: z.string().optional(),
        model: z.string().optional(),
        warranty: z.boolean().optional(),
        is_private: z.boolean().optional(),
        manufacturer: z.string().optional(),
        purchase_date: z.string().optional(),
        serial_number: z.string().optional(),
        purchase_price: z.string().optional(),
        purchase_store: z.string().optional(),
        service_advice: z.string().optional(),
        warranty_end_date: z.string().optional(),
        extra_information: z.string().optional(),
        choice: z.string().optional(),
        info: z.string().optional(),
        belonging: z.string().optional(),
        space: z.string().optional(),
        article_no: z.string().optional(),
        rsk_no: z.string().optional(),
        e_no: z.string().optional(),
        gtin_ean: z.string().optional(),
        brand: z.string().optional(),
        pnc_number: z.string().optional(),
        order_number: z.string().optional(),
        order_no: z.string().optional(),
    }),
    entity_type: z.number().optional(),
    entity_subtype: z.number({ required_error: 'error_required' }),
});

export type AddProduct = z.infer<typeof AddProductSchema>;

export const EditProductSchema = AddProductSchema.omit({ project_id: true });

export type EditProduct = z.infer<typeof EditProductSchema>;

export const AddPackageProductSchema = z.object({
    name: z.string().min(1, { message: 'error_required' }),
    brand_id: z.number().nullish(),
    package_type: z.string().optional(),
    supplier_id: z.number().nullish(),
    profession_id: z.number().nullish(),
    attr: z.object({
        product: z.string().min(1, { message: 'error_required' }),
        description: z.string().optional(),
        url: z.string().optional(),
        color: z.string().optional(),
        model: z.string().optional(),
        warranty: z.boolean().optional(),
        is_private: z.boolean().optional(),
        manufacturer: z.string().optional(),
        purchase_date: z.string().optional(),
        serial_number: z.string().optional(),
        purchase_price: z.string().optional(),
        purchase_store: z.string().optional(),
        service_advice: z.string().optional(),
        warranty_end_date: z.string().optional(),
        extra_information: z.string().optional(),
        choice: z.string().optional(),
        info: z.string().optional(),
        belonging: z.string().optional(),
        space: z.string().optional(),
        article_no: z.string().optional(),
        rsk_no: z.string().optional(),
        e_no: z.string().optional(),
        gtin_ean: z.string().optional(),
        brand: z.string().optional(),
        pnc_number: z.string().optional(),
        order_number: z.string().optional(),
        order_no: z.string().optional(),
    }),
    entity_type: z.number().optional(),
    entity_subtype: z.number({ required_error: 'error_required' }),
});

export type AddPackageProduct = z.infer<typeof AddPackageProductSchema>;

export const EditPackageProductSchema = AddProductSchema.omit({ project_id: true });

export type EditPackageProduct = z.infer<typeof EditProductSchema>;

export const AddAccountProductSchema = z.object({
    name: z.string().min(1, { message: 'error_required' }),
    brand_id: z.number().nullish(),
    parent_id: z.number().optional().nullish(),
    package_type: z.string().optional().nullish(),
    supplier_id: z.number().nullish(),
    profession_id: z.number().nullish(),
    attr: z.object({
        product: z.string().min(1, { message: 'error_required' }),
        description: z.string().optional(),
        url: z.string().optional(),
        color: z.string().optional(),
        model: z.string().optional(),
        warranty: z.boolean().optional(),
        is_private: z.boolean().optional(),
        manufacturer: z.string().optional(),
        purchase_date: z.string().optional(),
        serial_number: z.string().optional(),
        purchase_price: z.string().optional(),
        purchase_store: z.string().optional(),
        service_advice: z.string().optional(),
        warranty_end_date: z.string().optional(),
        extra_information: z.string().optional(),
        choice: z.string().optional(),
        info: z.string().optional(),
        belonging: z.string().optional(),
        space: z.string().optional(),
        article_no: z.string().optional(),
        rsk_no: z.string().optional(),
        e_no: z.string().optional(),
        gtin_ean: z.string().optional(),
        brand: z.string().optional(),
        pnc_number: z.string().optional(),
        order_number: z.string().optional(),
        order_no: z.string().optional(),
    }),
    entity_type: z.number().optional(),
    entity_subtype: z.number({ required_error: 'error_required' }),
});

export type AddAccountProduct = z.infer<typeof AddAccountProductSchema>;

export const AddMemberSchema = z.object({
    email: z.string().email(),
});

export type AddMember = z.infer<typeof AddMemberSchema>;

export const DocumentTypeSchema = z
    .literal(0)
    .or(z.literal(1))
    .or(z.literal(2))
    .or(z.literal(3))
    .or(z.literal(4))
    .or(z.literal(5))
    .or(z.literal(6))
    .or(z.literal(7))
    .or(z.literal(8))
    .or(z.literal(9))
    .or(z.literal(10))
    .or(z.literal(11))
    .or(z.literal(12))
    .or(z.literal(13))
    .or(z.literal(14))
    .or(z.literal(15));

export const DocumentSubtypeSchema = z
    .literal(0)
    .or(z.literal(1))
    .or(z.literal(2))
    .or(z.literal(3))
    .or(z.literal(4))
    .or(z.literal(5))
    .or(z.literal(6))
    .or(z.literal(7))
    .or(z.literal(8));
