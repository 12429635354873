import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import NewModal from '../../../components/Modal/NewModal';
import { useGetTicket } from '../hooks/ticketQueries';
import ForwardTicketMail from '../forwardTicketMail/ForwardTicketMail';
import EditTicket from './EditTicket';

const HandleInitialTicketData = ({ readOnly = false }: { readOnly?: boolean }) => {
    const { id } = useParams<{ id: string }>();
    const { data } = useGetTicket(id);
    return (
        <>
            <EditTicket readOnly={readOnly} data={data} />
            <NewModal>
                <ForwardTicketMail ticket_id={data?.id} />
            </NewModal>
        </>
    );
};

export default HandleInitialTicketData;
