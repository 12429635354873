import React, { ReactNode, useState, useRef } from 'react';
import { style } from '../Shared/Style';
import { useSpring, animated } from 'react-spring';
import Close from '../SliderMenu/Close/Close';
import styled from '@emotion/styled';
import useHandleClickOutside from '../../lib/hooks/useHandleClickOutside';

const MenuWrapper = styled.nav({
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 99,
    backgroundColor: '#F7F8FC',
    boxShadow: '0 0 35px gray',
    height: '100vh',
    '& a': {
        color: style.colors.black,
        textDecoration: 'none',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        width: '90%',
        marginTop: style.margin.m,
        fontSize: style.fontSize.gamma,
        fontWeight: style.fontWeight.thin,
        'a:first-of-type': {
            padding: `71px 0px 0px 21px`,
        },
    },
});

const OpenMenuButton = styled.button({
    background: 'none',
    border: 'none',
    display: 'flex',
    padding: '0px',
    margin: 0,
});

export const MoreHorizontalLinkStyle = {
    color: style.colors.black,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '90%',
    marginTop: style.margin.m,
    fontSize: style.fontSize.gamma,
    padding: `12px 0px 0px 21px`,
    fontWeight: style.fontWeight.thin,
    '&:first-of-type': {
        padding: `71px 0px 0px 21px`,
    },
};

const AnimatedMenu = animated(MenuWrapper);

export const AvatarMenu = ({ avatar, children }: { avatar: any; children: ReactNode }) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const ref = useRef<HTMLHeadingElement | null>(null);

    const expand = useSpring({
        config: { friction: 20, clamp: !isSettingsOpen },
        width: isSettingsOpen ? '320px' : '0px',
    });

    const handleClickOutside = () => setIsSettingsOpen(false);
    useHandleClickOutside<HTMLHeadingElement>({ ref, clickOutsideFunc: handleClickOutside });

    if (!children) return null;
    return (
        <>
            <OpenMenuButton onClick={() => setIsSettingsOpen((prev) => !prev)}>{avatar}</OpenMenuButton>
            <AnimatedMenu ref={ref} style={expand}>
                <Close
                    inverted
                    left={10}
                    top={10}
                    onClose={() => {
                        setIsSettingsOpen(false);
                    }}
                />
                <span onClick={() => setIsSettingsOpen((prev) => !prev)}>{children}</span>
            </AnimatedMenu>
        </>
    );
};
