import React, { TouchEvent } from 'react';
import { DndContext, closestCenter, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import type { MouseEvent, KeyboardEvent } from 'react';
import {
    MouseSensor as LibMouseSensor,
    KeyboardSensor as LibKeyboardSensor,
    TouchSensor as LibTouchSensor,
} from '@dnd-kit/core';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

export const GridContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill,minmax(150px,1fr))`,
    gap: 10,
    borderRadius: '4px',
});

function shouldHandleEvent(element: HTMLElement | null) {
    let cur = element;

    while (cur) {
        if (cur.dataset && cur.dataset.noDnd) {
            return false;
        }
        cur = cur.parentElement;
    }

    return true;
}

const pulse = keyframes`
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
`;
export const Skeleton = styled.div(() => ({
    transformOrigin: '0 0',
    borderRadius: '8px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    cursor: 'pointer',
    paddingBottom: '100%',
    position: 'relative',
    '&:focus-visible': {
        outline: 'none',
    },

    animation: `${pulse} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite`,
}));

export class MouseSensor extends LibMouseSensor {
    static activators = [
        {
            eventName: 'onMouseDown' as const,
            handler: ({ nativeEvent: event }: MouseEvent) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

export class TouchSensor extends LibTouchSensor {
    static activators = [
        {
            eventName: 'onTouchStart' as const,
            handler: ({ nativeEvent: event }: TouchEvent) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

export class KeyboardSensor extends LibKeyboardSensor {
    static activators = [
        {
            eventName: 'onKeyDown' as const,
            handler: ({ nativeEvent: event }: KeyboardEvent<Element>) => {
                return shouldHandleEvent(event.target as HTMLElement);
            },
        },
    ];
}

type Props<T> = {
    items: T;
    onDragEnd: (event: DragEndEvent) => void;
    isUploading?: boolean;
    disableSort: boolean;
    children: React.ReactNode;
};

const DraggableSortGrid = <T extends { id: number }[]>({
    items,
    onDragEnd,
    disableSort,
    isUploading,
    children,
}: Props<T>) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd} css>
            <SortableContext
                disabled={disableSort || isUploading}
                items={items.map((r) => r.id)}
                strategy={rectSortingStrategy}
            >
                {children}
            </SortableContext>
        </DndContext>
    );
};

export default DraggableSortGrid;
