import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    Row as RowType,
} from '@tanstack/react-table';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useNavigate } from 'react-router-dom-v5-compat';
import { useGetInfinteProfessions } from '../hooks/professionQueries';
import dayjs from 'dayjs';
import { Profession } from '@app/trpc-api/models/Professions';
import TopAreaContainer from '../../../binders/components/TopAreaContainer';
import GenericTableVirtualized from '../../../binders/components/GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../../../binders/components/emotion/Filters/FilterSearchContainer';
import PaddedContainer from '../../../binders/components/emotion/PaddedContainer';
import Row from '../../../binders/components/emotion/Row';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import toast from 'react-hot-toast';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import { margin } from '../../../../components/Shared/Style/margin';
import useDeleteProfessions from '../hooks/useDeleteProfessions';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<Profession>();

const ProfessionList = ({ handleSetOpen }: { handleSetOpen: () => void }) => {
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name')}</Flex>,
                meta: {
                    styles: {
                        flex: '4 0 auto',
                        width: 150,
                    },
                },
            }),
            columnHelper.accessor((row) => row.created_at, {
                id: 'created_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>{dayjs(info.row.original.created_at).format('YYYY-MM-DD HH:mm')}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('created_at')}</Flex>,
                meta: {
                    styles: {
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.updated_at, {
                id: 'updated_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>{dayjs(info.row.original.updated_at).format('YYYY-MM-DD HH:mm')}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('updated_at')}</Flex>,
                meta: {
                    styles: {
                        width: 120,
                        marginLeft: 'auto',
                    },
                },
            }),
        ],
        [],
    );

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

    const navigate = useNavigate();

    const handleClickRow = useCallback((row: RowType<Profession>) => {
        navigate(`${location.pathname}/${row.original.id}`);
    }, []);

    const searchParams = new URLSearchParams(location.search);

    const searchField = useMemo(() => searchParams.get('searchField') ?? '', [searchParams]);

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };

    const [sorting, setSorting] = useState<SortingState>([]);

    const { fetchNextPage, hasNextPage, isFetching, data } = useGetInfinteProfessions({
        keepPreviousData: true,
        search: searchField,
        sorting,
    });

    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);

    const totalDBRowCount = useMemo(() => Number(data?.pages?.[0]?.totalItems) ?? 0, [data]);
    const totalFetched = useMemo(() => flatData.length, [flatData]);

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
            rowSelection,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableColumnResizing: false,
        onRowSelectionChange: setRowSelection,
    });

    const { mutate: deleteProfessions } = useDeleteProfessions();

    const handleRemoveProfession = (suppliers: Profession[]) => {
        const ids = suppliers.map((supplier) => supplier.id);
        deleteProfessions(ids, {
            onSuccess: () => {
                setRowSelection({});
                if (ids.length > 0) toast.success(L('removed_profession'));
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    useEffect(() => {
        setRowSelection({});
    }, [data]);

    const selectedProfessions = table.getSelectedRowModel().flatRows.map((data) => data.original);

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>{L('profession')}</CardTitle>
                    <div style={{ display: 'flex', columnGap: margin.s, marginLeft: 'auto' }}>
                        <ConfirmationButton
                            props={{
                                onConfirm: () => {
                                    handleRemoveProfession(selectedProfessions);
                                },
                                confirmVariant: 'danger',
                            }}
                            disabled={!Boolean(selectedProfessions.length > 0)}
                        >
                            {L('remove')}
                        </ConfirmationButton>
                        <Button style={{ marginLeft: 'auto' }} onClick={() => handleSetOpen()}>
                            {L('new_profession')}
                        </Button>
                    </div>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <FilterSearchContainer style={{ justifyContent: 'end' }}>
                        <PaddedContainer>
                            <Row>
                                <GlobalFilter
                                    filter={searchField ?? ''}
                                    setFilter={handleSearch}
                                    placeholder={L('search')}
                                />
                            </Row>
                        </PaddedContainer>
                    </FilterSearchContainer>

                    <GenericTableVirtualized
                        table={table}
                        handleFetchNextPage={() => fetchNextPage()}
                        hasNextPage={hasNextPage}
                        isFetching={isFetching}
                        totalDBRowCount={totalDBRowCount}
                        totalFetched={totalFetched}
                        estimateSize={50}
                        rowClickHandler={handleClickRow}
                    />
                    {flatData?.length === 0 && data && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_professions')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('no_professions_description')}</p>
                                <p>{L('click_button_below')}</p>
                                <Button onClick={() => handleSetOpen()}> {L('new_profession')}</Button>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                </>
            }
        />
    );
};

export default ProfessionList;
