import { createValidator, required } from '../../lib/validation';
import { observable } from 'mobx';
import {
    ContentInformationItem,
    ContentInformationDocumentDetail,
    DocumentGroup
} from '@app/api/models/ProjectContentInformation';
import ContentInformationApi from '@app/api/public/ProjectContentInformationApi';
import { redirect } from '../../lib/routing';
import DocumentsApi from '@app/api/public/DocumentsApi';

const rules = createValidator({
    title: [required()],
    description: [required()]
});

class ContentInformationStoreClass {
    posted = false;
    @observable errors: any = {};
    @observable model: Partial<ContentInformationItem> = {};

    @observable imageUrl: string;
    @observable imagePreview: string;

    @observable documents: ContentInformationDocumentDetail[] = [];
    @observable document: ContentInformationDocumentDetail;

    @observable documentGroups: DocumentGroup[] = [];

    @observable searchFilter: string[] = [];

    async load(id: string | undefined) {
        this.posted = false;
        if (!id) {
            this.model = {};
            this.imageUrl = '';
        } else {
            this.model = (await ContentInformationApi.get(id)) || {};
            this.listDocuments(id);
        }
        if (this.model.cover_image_data) {
            this.imageUrl = await DocumentsApi.getSignedUrl(this.model.cover_image_data.id);
            console.log(this.imageUrl);
        }
    }

    async listDocuments(document_id: string) {
        this.documents = await ContentInformationApi.listDocuments(document_id);
    }

    saveDocument = async () => {
        await ContentInformationApi.saveDocument(this.document);
    };

    async listDocumentGroups() {
        this.documentGroups = await ContentInformationApi.listDocumentGroups();
    }

    setValue = async (value: any, name: string) => {
        this.model[name] = value;
        if (this.posted) {
            const validation = rules.test(this.model);
            this.errors = validation.errors;
        }
    };

    remove = async (id: string) => {
        await ContentInformationApi.remove(id);
        this.load(id);
        redirect(`/information/`);
    };

    save = async () => {
        this.posted = true;
        const validation = rules.test(this.model);
        this.errors = validation.errors;
        if (!validation.ok) return;

        const result = await ContentInformationApi.save(this.model);

        if (result && result.id !== this.model.id) {
            return redirect(`/information/${result.id}`);
        }

        this.model = result as Partial<ContentInformationItem>;
    };
}
export const ContentInformationStore = new ContentInformationStoreClass();
