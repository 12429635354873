import styled from '@emotion/styled';
import React from 'react';
import { style } from '@app/shared/lib/Style';
import { L } from '../../../../lib/i18n';

const Column = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
});

const Categories = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: `${style.margin.xs}px ${style.margin.s}px`
});

const Category = styled.div({
    fontSize: style.fontSize.milli,
    lineHeight: '14px',
    fontFamily: 'Lato',
    fontWeight: style.fontWeight.bold,
    color: style.colors.black
});

export const ReportProductsCategories = ({ fields }) => {
    return (
        <Column>
            <Categories>
                {fields.includes('function') ? (
                    <Category style={{ width: '12%' }}>{L('function')}</Category>
                ) : (
                    fields.includes('image') && <Category style={{ marginRight: '63px' }} />
                )}
                {fields.includes('product') && <Category style={{ width: '23%' }}>{L('product_group')}</Category>}
                {fields.includes('product') && <Category style={{ width: '25%' }}>{L('product')}</Category>}
                {fields.includes('brand') && <Category style={{ width: '10%' }}>{L('brand')}</Category>}
                {fields.includes('supplier') && <Category style={{ width: '10%' }}>{L('supplier')}</Category>}
                {fields.includes('profession') && <Category style={{ width: '10%' }}>{L('profession')}</Category>}
                {fields.includes('price') && <Category style={{ width: '10%' }}>{L('price')}</Category>}
            </Categories>
        </Column>
    );
};
