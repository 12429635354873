import styled from '@emotion/styled';
import React from 'react';

const StyledDiv = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const BigTabMenu = (props: React.ComponentProps<'div'>) => {
    return <StyledDiv {...props} />;
};

export default BigTabMenu;
