import { trpc } from '../../../../lib/trpc';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyContactsKeys } from './propertyContactsQueries';

const useDeletePropertyContact = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ id, property_id }: { id: number; property_id: number }) => {
            return trpc.PropertyContactsApi.deletePropertyContact.mutate({
                property_id: property_id,
                contactId: id,
            });
        },
        {
            onSettled: () => {
                client.invalidateQueries(getPropertyContactsKeys.lists());
            },
        },
    );
};

export default useDeletePropertyContact;
