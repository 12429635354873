import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentInformationQueryKeys } from './contentInformationQueries';

const useUpdateContentInfoSort = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (params: { sort: { id: number }[]; projectId: number }) => {
            return trpc.ContentInformationApi.updateContentInfoSort.mutate({
                sort: params.sort,
                projectId: params.projectId,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
            },
        },
    );
};

export const useUpdateContentOrgInfoSort = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (params: { sort: { id: number }[] }) => {
            return trpc.ContentInformationApi.updateContentOrgInfoSort.mutate({
                sort: params.sort,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
            },
        },
    );
};

export default useUpdateContentInfoSort;
