import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { colors } from '../Shared/Style/colors';

const StyledNoProject = styled.div({
    padding: '50px',
    textAlign: 'center',
    minHeight: '436px',
    display: 'grid',
    alignContent: 'center',
    gridTemplateRows: '4rem 4rem'
});

const NoProjectTitle = styled.span({
    fontSize: style.fontSize.gamma
});

const NoProjectDescription = styled.span({
    fontSize: style.fontSize.epsilon,
    color: colors.secondary
});


// const Container = styled.div
//     border-radius: ${style.roundCornerSize.small}px;
//     padding: ${style.margin.l}px;
//     background: ${props => props.background};
// `;

interface Props {
    title?: string;
    description?: any;
}

export const EmptyState = (props: Props) => {
    return (
        <StyledNoProject>
                    <NoProjectTitle>{props.title}</NoProjectTitle>
                    <NoProjectDescription>
                        {props.description}
                    </NoProjectDescription>
                </StyledNoProject>
    );
};
