import styled from '@emotion/styled';
import React from 'react';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import LabelRequired from '../../../../components/Forms/LabelRequired';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import { style } from '../../../../components/Shared/Style';
import H2 from '../../../../components/typography/H2';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '2rem',
});

type DocumentGroup = { name: string };

type Props = {
    onSubmit: SubmitHandler<DocumentGroup>;
    defaultValues?: DocumentGroup;
    formStyling?: React.CSSProperties;
    heading: string;
};

const DocumentGroupForm = ({ onSubmit, defaultValues, formStyling, heading }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DocumentGroup>({
        defaultValues,
    });

    return (
        <Form style={formStyling} onSubmit={handleSubmit(onSubmit)}>
            <H2>{heading}</H2>
            <ErrorAndInputWrapper errorMsg={L(errors.name?.message ?? '')}>
                <LabelRequired title={L('name')}>
                    <InputWithError isError={!!errors?.name} {...register('name')} />
                </LabelRequired>
            </ErrorAndInputWrapper>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Button style={{ marginTop: '1rem' }} type="submit">
                    {L('save')}
                </Button>
            </div>
        </Form>
    );
};

export default DocumentGroupForm;
