import { work, se } from './firebaseconfig';

const parseUrl = () => {
    const regex = new RegExp(/(\.\w+)$/i);

    const toplevelUrl = window.location.origin.match(regex)?.map((parsedString) => parsedString.replace('.', ''));

    if (toplevelUrl?.length) {
        return toplevelUrl[0];
    }
    return null;
};

const getFirebaseConfig = () => {
    const toplevelUrl = parseUrl();
    if (!toplevelUrl) return null;
    let firebaseConfig: null | typeof work = null;
    switch (toplevelUrl) {
        case 'work':
            firebaseConfig = work;
            break;
        case 'se':
            firebaseConfig = se;
            break;
        default:
            firebaseConfig;
    }

    return firebaseConfig;
};

export default getFirebaseConfig;
