import { Property } from '@app/trpc-api/models/Binders';
import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';

const deleteProperties = async (properties: Property[]) => {
    const removeProperty = properties.map(async (property) => {
        return await trpc.BindersApi.deleteFullProperty.query({ propertyId: property.id });
    });

    const deleteProperty = await Promise.all(removeProperty);

    return deleteProperty;
};

type Variables = {
    properties: Property[];
    projectId: number;
};

const useDeleteProperties = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: Variables) => {
            await deleteProperties(variables.properties);
        },
        {
            onSettled(_data, _error, vars) {
                queryClient.invalidateQueries(getPropertiesKeys.list({ projectId: vars.projectId }));
            },
        },
    );
};

export default useDeleteProperties;
