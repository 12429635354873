import React, { ReactElement, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useObserver } from 'mobx-react';
import styled from '@emotion/styled';
import { Header } from '../../../../components/Layout/Header';
import { Loading } from '../../../../components/Loading/Loading';
import { style } from '../../../../components/Shared/Style';
import { Table } from '../../../../components/Table/Table';
import { usePromise } from '../../../../lib/react';
import { L } from '../../../../lib/i18n';
import { AddonReportStore } from '../AddonReportStore';
import { Card } from '../../../../components/Layout/Card';
import { EntitySupplierStore } from '../EntitySupplier/EntitySupplierStore';
import { formatCurrency } from '@app/shared/lib/formatting';
import { ListActiveFilter } from './Components/ListActiveFilter';
import * as Icons from 'react-feather';
import RaportHandler from './Components/RaportHandler';
import { useAddonReport } from './context/AddonReportContext';
import SupplierCreatePdf from '../../../client-pdf/Supplier/SupplierCreatePdf';

const Description = styled.div({
    textDecoration: 'none',
    fontWeight: style.fontWeight.normal,
    fontSize: style.fontSize.milli,
    lineHeight: '20px'
});

const ActiveFilters = styled.div({
    padding: `0px ${style.margin.l}px`,
    width: '100%'
});

const ShowFilter = styled.span({
    cursor: 'pointer',
    color: style.colors.secondary,
    fontSize: style.fontSize.milli,
    textTransform: 'uppercase',
    fontWeight: style.fontWeight.bold
});

type CssPriorityProps = {
    type?: string;
};

export const AddonReportListPage = () => {
    const { projectId } = useParams<{ projectId: string }>();

    const { filter } = useAddonReport();

    const [loadPrint, setLoadPrint] = useState(false);

    const [ready] = usePromise(async () => {
        // setFilter(JSON.parse(localStorage.getItem('filter') as string));
        await AddonReportStore.loadAddonSuppliers(+projectId, filter);
        await EntitySupplierStore.load();
        await AddonReportStore.startPrintToPdf();
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        const onClick = () => {
            setLoadPrint(true);
            AddonReportStore.startPrintToPdf();
        };

        const reportProducts = AddonReportStore.supplier.filter(r => r.entity_type != 0);
        const sortedReportProducts = reportProducts.sort((a, b) =>
            a.property_name.localeCompare(b.property_name, undefined, { numeric: true })
        );

        const rows: {
            property: ReactElement;
            function: ReactElement;
            product: ReactElement;
            brand: ReactElement;
            supplier: ReactElement;
            profession: ReactElement;
            space: ReactElement;
            price: ReactElement;
        }[] = sortedReportProducts.map(p => ({
            property: (
                <>
                    <Description>
                        <div>{L(p.property_name)}</div>
                    </Description>
                </>
            ),
            space: (
                <>
                    <Description>
                        <div>{p.parent_names && p.parent_names[0]}</div>
                    </Description>
                </>
            ),
            function: (
                <>
                    <Description>
                        <div>{L(p.product_type_name)}</div>
                    </Description>
                </>
            ),
            product_group: (
                <>
                    <Description>
                        <div>{p.product_group_name && `${p.product_group_name}`}</div>
                    </Description>
                </>
            ),
            product: (
                <>
                    <Description>
                        <div>
                            {p.product_name && <span style={{ color: style.colors.black90 }}> {p.product_name}</span>}
                            {p.addon_selected_info && (
                                <span style={{ fontStyle: 'italic', marginLeft: style.margin.xs }}>
                                    {`${p.is_addon_color_picker ? 'Färgkod: NCS ' : ''}`}
                                    {p.addon_selected_info.custom_request}
                                </span>
                            )}
                        </div>
                    </Description>
                </>
            ),
            brand: (
                <>
                    <Description>
                        <div>{p.brand_name}</div>
                    </Description>
                </>
            ),
            supplier: (
                <>
                    <Description>
                        <div>{p.supplier_name}</div>
                    </Description>
                </>
            ),
            profession: (
                <>
                    <Description>
                        <div>{p.profession_name}</div>
                    </Description>
                </>
            ),

            price: (
                <>
                    <Description>
                        {p.addon_selected_info.price
                            ? formatCurrency(+p.addon_selected_info.price, false)
                            : L('price_missing')}
                    </Description>
                </>
            )
        }));

        const filterPanel = [
            {
                filterName: 'property',
                filterListValue: filter?.property?.map(value => value),
                isID: true,
                list: AddonReportStore.properties
            },
            {
                filterName: 'space',
                filterListValue: filter?.space?.map(value => value),
                isID: false,
                list: AddonReportStore.spaces
            },
            {
                filterName: 'product_type_id',
                filterListValue: filter?.product_type_id?.map(value => value),
                isID: true,
                list: EntitySupplierStore.productType
            },
            {
                filterName: 'product_group',
                filterListValue: filter?.product_group?.map(value => value),
                isID: false,
                list: AddonReportStore.productGroups
            },
            {
                filterName: 'product',
                filterListValue: filter?.product?.map(value => value),
                isID: false,
                list: AddonReportStore.products
            },
            {
                filterName: 'supplier',
                filterListValue: filter?.supplier?.map(value => value),
                isID: true,
                list: EntitySupplierStore.suppliers
            },
            {
                filterName: 'profession',
                filterListValue: filter?.profession?.map(value => value),
                isID: true,
                list: EntitySupplierStore.professions
            }
        ];

        return (
            <>
                <Header
                    to={'./'}
                    text={`<-` + L('backtoproperties')}
                    title={`${L('report')}  ${AddonReportStore.project_name}`}
                />
                <RaportHandler handlePrint={() => onClick()} projectId={+projectId} loadPrint={loadPrint} />
                <ActiveFilters>
                    <ShowFilter
                        onClick={() => (AddonReportStore.isShowingFilters = !AddonReportStore.isShowingFilters)}
                    >
                        {AddonReportStore.isShowingFilters ? L('hide_filter') : L('show_filter')}
                        <Icons.ChevronDown
                            size={16}
                            style={{
                                transform: AddonReportStore.isShowingFilters ? 'rotateX(180deg)' : 'none',
                                verticalAlign: 'middle'
                            }}
                        />
                    </ShowFilter>
                    {AddonReportStore.isShowingFilters && <ListActiveFilter filterPanel={filterPanel} />}
                </ActiveFilters>
                <Card>
                    {AddonReportStore.isWaitingFilters ? (
                        <Loading />
                    ) : (
                        <Table
                            columns={[
                                { key: 'property', header: L('property'), width: '1fr' },
                                { key: 'space', header: L('space'), width: '0.6fr' },
                                { key: 'function', header: L('function'), width: '0.8fr' },
                                { key: 'product_group', header: L('product_group'), width: '1.5fr' },
                                { key: 'product', header: L('product'), width: '1.5fr' },
                                { key: 'brand', header: L('brand'), width: '0.7fr' },
                                { key: 'supplier', header: L('supplier'), width: '0.7fr' },
                                { key: 'profession', header: L('profession'), width: '0.7fr' },
                                { key: 'price', header: L('price'), width: '0.7fr' }
                            ]}
                            data={rows}
                        />
                    )}
                </Card>
                {loadPrint && (
                    <SupplierCreatePdf
                        setLoadPrint={setLoadPrint}
                        projectId={projectId}
                        property_ids={AddonReportStore.propertyIds}
                        filter={filter}
                        fields={[...AddonReportStore.fields]}
                    />
                )}
            </>
        );
    });
};
