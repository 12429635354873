import { useQueryClient, useMutation } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useParams } from 'react-router-dom';
import { getMaintenanceDocumentsQueryKey } from './useGetMaintenanceDocuments';

type Variables = {
    itemId: string;
    name: string;
    file: File;
};

export const saveMaintenanceDocuments = async (projectId: string, variables: Variables) => {
    return MaintenancesApi.saveDocument(+projectId, +variables.itemId, variables.name, variables.file);
};

const useSaveMaintenanceDocuments = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ projectId: string }>();
    return useMutation((variables: Variables) => saveMaintenanceDocuments(projectId, variables), {
        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries([getMaintenanceDocumentsQueryKey]);
        }
    });
};

// export const saveEditedDocuments = async (document) => {
//     return await MaintenancesApi.saveEditDocument(document);
// };

export default useSaveMaintenanceDocuments;
