import React from 'react';
import { Header } from '../../../components/Layout/Header';
import { Content } from '../../../components/Layout/Content';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { InformationDetailEditor } from './InformationDetailEditor';
import { RouteComponentProps } from 'react-router';

interface Props {
    id?: string;
}

const Container = styled.div({});

export function InformationDetailPage(props: RouteComponentProps<{ id: string }>) {
    const id = props.match.params.id;
    return (
        <>
            <Header title={L('information')} to={'./'} text={`<-` + L('back_to_info')} />
            <Content>
                <div>
                    <Container>
                        {id === 'new' ? <InformationDetailEditor id={''} /> : <InformationDetailEditor id={id} />}
                    </Container>
                </div>
            </Content>
        </>
    );
}
