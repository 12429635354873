import { useQueryClient, useMutation } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { ocrImageQueryKey } from './ocrQuery';
import { productsQueryKey } from './useGetProductsByProperty';

type Variables = { productId: string; file: File };

const saveProductImage = async (variables: Variables) => {
    return InventoryApi.uploadTypePlate(variables.productId, variables.file);
};

const useSaveProductImage = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveProductImage(variables), {
        onSettled: () => {
            queryClient.invalidateQueries([ocrImageQueryKey]);
            queryClient.invalidateQueries([productsQueryKey]);
        }
    });
};

export default useSaveProductImage;
