import { useQuery } from 'react-query';
import AlertsApi, { Alert, AlertFilter } from '@app/api/public/AlertsApi';

export const alertQuqeryKeys = {
    all: ['alerts'],
    id: (id?: Alert['id']) => [...alertQuqeryKeys.all, { id }],
    list: (filter?: AlertFilter) => [...alertQuqeryKeys.all, 'list', { filter }]
};

const getAlert = async (id?: Alert['id']) => {
    if (!id) return;
    return await AlertsApi.id(id);
};

const useGetAlert = (id?: Alert['id']) => {
    return useQuery(alertQuqeryKeys.id(id), () => getAlert(id), { enabled: !!id });
};

const getAlerts = async (filter: AlertFilter) => {
    const r = await AlertsApi.list(filter);
    return r;
};

const useGetAlerts = (filter: AlertFilter) => {
    return useQuery(alertQuqeryKeys.list(filter), () => getAlerts(filter));
};

export { useGetAlerts, useGetAlert };
