import { useMutation, useQueryClient } from 'react-query';
import { getSupplierKeys } from './supplierQueries';
import { trpc } from '../../../../lib/trpc';

const useAddSupplier = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ name }: { name: string }) => {
            return trpc.SupplierApi.createSupplier.query({ name });
        },
        {
            onSettled: () => {
                client.invalidateQueries(getSupplierKeys.lists());
            }
        }
    );
};

export default useAddSupplier;
