import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';
import { AddProduct } from '../../binders/schemas';
import PropertyProductTemplateForm from '../PropertyTemplates/PropertyProductTemplateForm';
import { useGetPropSpaces } from '../hooks/propertyTemplateQueries';
import useAddProductToTemplate from '../hooks/useAddProductToTemplate';

const Component = ({ propertyId }: { propertyId: string }) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const { data: spaces } = useGetPropSpaces(+propertyId);
    const navigate = useNavigate();

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name ?? '') })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProductToTemplate();

    const onSubmit: SubmitHandler<AddProduct> = (data) => {
        saveInventoryMaterial(
            { inventoryMaterial: data },
            {
                onSuccess: (data) => {
                    toast.success(L('product_added'));
                    navigate(`../${data.id}`);
                },
                onError: () => {
                    toast.error(L('product_not_added'));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('new_product')}</CardTitle>}
            mainArea={
                <PropertyProductTemplateForm
                    propertyId={+propertyId}
                    onSubmit={onSubmit}
                    options={options}
                    defaultValues={{ property_id: +propertyId }}
                />
            }
        />
    );
};

export default function AddPropertyProductTemplatePage() {
    const { id: propertyId } = useParams<{ id: string }>();
    return propertyId ? <Component propertyId={propertyId} /> : null;
}
