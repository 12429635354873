Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getProductDetail"] = b.createRpcProxy("BindersApi", "getProductDetail", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveInventoryMaterial"] = b.createRpcProxy("BindersApi", "saveInventoryMaterial", {
  "urlPrefix": "/rpc"
});
o["listBrands"] = b.createRpcProxy("BindersApi", "listBrands", {
  "urlPrefix": "/rpc"
});
o["listSuppliers"] = b.createRpcProxy("BindersApi", "listSuppliers", {
  "urlPrefix": "/rpc"
});
o["listProffesions"] = b.createRpcProxy("BindersApi", "listProffesions", {
  "urlPrefix": "/rpc"
});
o["listProductTypes"] = b.createRpcProxy("BindersApi", "listProductTypes", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = b.createRpcProxy("BindersApi", "listProperties", {
  "urlPrefix": "/rpc"
});
o["listSpaces"] = b.createRpcProxy("BindersApi", "listSpaces", {
  "urlPrefix": "/rpc"
});
o["listPropertiesByProject"] = b.createRpcProxy("BindersApi", "listPropertiesByProject", {
  "urlPrefix": "/rpc"
});
o["listDistinctSpaceNames"] = b.createRpcProxy("BindersApi", "listDistinctSpaceNames", {
  "urlPrefix": "/rpc"
});