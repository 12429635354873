Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getNotificationCount"] = b.createRpcProxy("NotificationsApi", "getNotificationCount", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["list"] = b.createRpcProxy("NotificationsApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getNotificationTypes"] = b.createRpcProxy("NotificationsApi", "getNotificationTypes", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});