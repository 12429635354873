Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["uploadProjectMailTemplate"] = b.createRpcProxy("ProjectSettingsApi", "uploadProjectMailTemplate", {
  "urlPrefix": "/rpc"
});
o["getProjectMailTemplatesList"] = b.createRpcProxy("ProjectSettingsApi", "getProjectMailTemplatesList", {
  "urlPrefix": "/rpc"
});
o["getProjectMailTemplate"] = b.createRpcProxy("ProjectSettingsApi", "getProjectMailTemplate", {
  "urlPrefix": "/rpc"
});