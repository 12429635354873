import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useDeletePropertyTemplateDoc = () => {
    return useMutation(async ({ documentId, propertyId }: { documentId: number; propertyId: number }) => {
        return await trpc.TemplatesApi.removePropertyTemplateDocument.mutate({ id: documentId, propertyId });
    });
};

export default useDeletePropertyTemplateDoc;
