import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    RowSelectionState,
} from '@tanstack/react-table';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useNavigate } from 'react-router-dom-v5-compat';
import dayjs from 'dayjs';
import { ListAdminsByAccountId } from '@app/trpc-api/models/Members';
import GenericTableVirtualized from '../../components/GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../../../binders/components/emotion/Filters/FilterSearchContainer';
import PaddedContainer from '../../../binders/components/emotion/PaddedContainer';
import Row from '../../../binders/components/emotion/Row';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { useGetProjectAdmins } from '../../hooks/adminHooks/useGetProjectAdmins';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<ListAdminsByAccountId>();

const columns = [
    {
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <Checkbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
            />
        ),
        size: 30,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
    },
    columnHelper.accessor((row) => row.email, {
        id: 'email',
        cell: (info) => (
            <CellContainer>
                <Data>{info.getValue()}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('email_account')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 150,
            },
        },
    }),
    columnHelper.accessor((row) => row.role, {
        id: 'role',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original.role)}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('role')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 50,
            },
        },
    }),
    columnHelper.accessor((row) => row.sub_role, {
        id: 'sub_role',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original.sub_role)}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('sub_role')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 50,
            },
        },
    }),

    columnHelper.accessor((row) => row.name, {
        id: 'name',
        cell: (info) => (
            <CellContainer>
                <Data>{info.getValue()}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('name')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => row.invited_at, {
        id: 'invited_at',
        cell: (info) => (
            <CellContainer>
                <Data>
                    {info.row.original.invited_at ? dayjs(info.row.original.invited_at).format('YYYY-MM-DD HH:mm') : ''}
                </Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('invited')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => row.confirmed_at, {
        id: 'confirmed_at',
        cell: (info) => (
            <CellContainer>
                <Data>
                    {info.row.original.confirmed_at
                        ? dayjs(info.row.original.confirmed_at).format('YYYY-MM-DD HH:mm')
                        : ''}
                </Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('account_confirmed')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
];

const AdminListPage = ({
    handleSetSelectedAdmins,
    projectId,
}: {
    handleSetSelectedAdmins: (param: ListAdminsByAccountId[]) => void;
    projectId: number;
}) => {
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);

    const searchField = searchParams.get('searchField') ?? '';

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    const [sorting, setSorting] = useState<SortingState>([]);

    const { fetchNextPage, hasNextPage, isFetching, data } = useGetProjectAdmins({
        sorting,
        search: searchField,
        projectId,
    });

    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);
    const totalDBRowCount = Number(data?.pages?.[0]?.totalItems ?? 0);
    const totalFetched = flatData.length;

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
            rowSelection,
        },
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
    });

    useEffect(() => {
        setRowSelection({});
    }, [data]);

    useEffect(() => {
        const selectedData = table.getSelectedRowModel().flatRows.map((data) => data.original);
        handleSetSelectedAdmins(selectedData);
    }, [rowSelection]);

    return (
        <>
            <FilterSearchContainer>
                <PaddedContainer style={{ marginLeft: 'auto' }}>
                    <Row>
                        <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                    </Row>
                </PaddedContainer>
            </FilterSearchContainer>
            <GenericTableVirtualized
                table={table}
                handleFetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetching={isFetching}
                totalDBRowCount={totalDBRowCount}
                totalFetched={totalFetched}
                estimateSize={50}
            />
            {flatData && flatData.length === 0 && !searchField && (
                <EmptyState>
                    <EmptyState.Title>{L('no_admins')}</EmptyState.Title>
                    <EmptyState.Description>
                        <p>{L('no_admins_description')}</p>
                    </EmptyState.Description>
                </EmptyState>
            )}
            {flatData && flatData.length === 0 && searchField && (
                <EmptyState>
                    <EmptyState.Title>{L('no_admins_found')}</EmptyState.Title>
                    <EmptyState.Description>{L('no_admins_found_description')}</EmptyState.Description>
                </EmptyState>
            )}
        </>
    );
};

export default AdminListPage;
