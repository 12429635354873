import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { style } from '../../../../components/Shared/Style';
import { useGetProjectTemplateById } from '../../hooks/ProjectTemplates/projectTemplateQueries';
import ProjectTemplateForm from '../../components/ProjectTemplates/ProjectTemplateForm';
import DocumentGroupList from '../../components/DocumentGroups/DocumentGroupList';
import ProjectTemplateImages from '../../components/ProjectTemplates/ProjectTemplateImages';
import styled from '@emotion/styled';

const ProjectTemplateDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    return id ? <ProjectTemplateDetail id={+id} /> : null;
};

const Grid = styled.div({
    display: 'grid',
    marginBottom: '1rem',
    gap: '1rem',
    [`@media screen and (min-width: 1380px)`]: {
        gridTemplateColumns: '1fr 1fr',
        alignItems: 'start',
    },
});
const ProjectTemplateDetail = ({ id }: { id: number }) => {
    const { data: template, status } = useGetProjectTemplateById(id);

    return (
        <Grid>
            <CardWithDivider
                topArea={<CardTitle>{L('project_template')}</CardTitle>}
                mainArea={<ProjectTemplateForm id={id} />}
            />

            <CardWithDivider
                topArea={
                    <CardTitle>
                        {L('pictures')} & {L('document_groups')}
                    </CardTitle>
                }
                mainArea={
                    status !== 'loading' && (
                        <div
                            style={{
                                gridArea: 'sideSection',
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: style.margin.m,
                            }}
                        >
                            <ProjectTemplateImages projectId={id} />
                            <DocumentGroupList projectId={id} projectName={template?.name || ''} />
                        </div>
                    )
                }
            />
        </Grid>
    );
};

export default ProjectTemplateDetailPage;
