import { TicketFilter } from '@app/api/models/Tickets';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { Filters } from '../../../components/Filters/Filters';
import useGetProjects from '../../../lib/hooks/useGetProjects';
import { useGetProjectProperties } from '../../../lib/hooks/useGetProperties';
import { L } from '../../../lib/i18n';
import { useGetAssignees, useGetSettingsByType, useGetTicketArchive } from '../hooks/ticketQueries';
import Cross from '../../../components/Icon/e-remove-outline-12.svg';
import { style } from '../../../components/Shared/Style';

const StyledCross = styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    color: '#4B506D66',
    marginRight: '5px'
});

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase'
});

const StyledFilters = styled(Filters)({
    margin: '0px',
    marginBottom: '1rem',
    gap: '0.5rem',
    '& .label': {
        margin: '0px',
        padding: '0px'
    }
});

type Props = {
    filter: TicketFilter;
    handleFilter: <T extends keyof TicketFilter>(key: T, value: TicketFilter[T]) => void;
    handleClearAll: () => void;
} & HTMLAttributes<HTMLDivElement>;

const TicketFilters = ({ handleFilter, filter, handleClearAll, ...rest }: Props) => {
    const { data: noFiltertickets } = useGetTicketArchive({});
    const { data: projects } = useGetProjects('ticket');
    const { data: properies } = useGetProjectProperties(filter.project_id?.toString());
    const { data: assignees } = useGetAssignees();
    const { data: statuses } = useGetSettingsByType('status');
    const { data: priorities } = useGetSettingsByType('priority');
    const projectOptions =
        projects
            ?.filter(project => noFiltertickets?.find(ticket => ticket.project_id === project.id))
            .map(property => ({ label: property.name, value: property.id })) || [];

    const propertyOptions =
        properies
            ?.filter(property => noFiltertickets?.find(ticket => ticket.property_id === property.id))
            .map(property => ({ label: property.name, value: property.id })) || [];

    const assigneeOptions =
        assignees
            ?.filter(assignee => noFiltertickets?.find(ticket => ticket.assigned_id === assignee.id))
            ?.map(assignee => ({ label: assignee.name, value: assignee.id })) || [];
    const priorityOptions =
        priorities
            ?.filter(priority => noFiltertickets?.find(ticket => ticket.ticket_priority_id === priority.id))
            .map(priority => ({ label: priority.name, value: priority.id })) || [];

    const statusOptions =
        statuses
            ?.filter(status => noFiltertickets?.find(ticket => ticket.ticket_status_id === status.id))
            .map(status => ({ label: status.name, value: status.id })) || [];

    return (
        <StyledFilters {...rest}>
            <ReactSelect
                placeholder={L('project')}
                isClearable
                value={projectOptions?.find(projectOption => projectOption.value === filter.project_id) ?? null}
                onChange={option => {
                    handleFilter('property', undefined);
                    handleFilter('project_id', option?.value);
                }}
                options={projectOptions}
            />
            <ReactSelect
                placeholder={L('properties')}
                isClearable
                value={propertyOptions?.find(propertyOption => propertyOption.value === filter?.property) ?? null}
                onChange={option => handleFilter('property', option?.value)}
                options={propertyOptions}
                noOptionsMessage={() => L('no_project_selected')}
            />
            <ReactSelect
                placeholder={L('assignee')}
                isClearable
                value={assigneeOptions?.find(assigneOption => assigneOption.value === filter?.assignee) ?? null}
                options={assigneeOptions}
                onChange={option => handleFilter('assignee', option?.value)}
            />
            <ReactSelect
                placeholder={L('status')}
                isClearable
                value={statusOptions?.find(statusOption => statusOption.value === filter?.status_id) ?? null}
                onChange={option => handleFilter('status_id', option?.value)}
                options={statusOptions}
            />
            <ReactSelect
                placeholder={L('priority')}
                isClearable
                value={priorityOptions?.find(priorityOption => priorityOption.value === filter?.priority_id) ?? null}
                onChange={option => handleFilter('priority_id', option?.value)}
                options={priorityOptions}
            />
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleClearAll()}>
                <StyledCross />
                <Label>{L('clear_filter')}</Label>
            </div>
        </StyledFilters>
    );
};

export default TicketFilters;
