Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["list"] = b.createRpcProxy("ContentInformationApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = b.createRpcProxy("ContentInformationApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = b.createRpcProxy("ContentInformationApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("ContentInformationApi", "remove", {
  "urlPrefix": "/rpc"
});
o["removeDocumentgroup"] = b.createRpcProxy("ContentInformationApi", "removeDocumentgroup", {
  "urlPrefix": "/rpc"
});
o["saveDocuementGroup"] = b.createRpcProxy("ContentInformationApi", "saveDocuementGroup", {
  "urlPrefix": "/rpc"
});
o["listDocumentGroups"] = b.createRpcProxy("ContentInformationApi", "listDocumentGroups", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = b.createRpcProxy("ContentInformationApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = b.createRpcProxy("ContentInformationApi", "listDocuments", {
  "urlPrefix": "/rpc"
});