import { trpc } from '../../../lib/trpc';
import { useMutation, useQueryClient } from 'react-query';
import { getProductsKeys } from './productQueries';
import { getPropertyProductsTemplatesKeys } from '../../project-templates/hooks/PropertyTemplates/propertyProductsTemplateQueries';

const useUpdateProductPrices = () => {
    const client = useQueryClient();
    return useMutation(
        async (products: { id: number; price: number }[]) => {
            return trpc.ProductsApi.updatePrice.mutate({ params: products });
        },
        {
            onSettled: () => {
                client.invalidateQueries(getProductsKeys.all);
                client.invalidateQueries(getPropertyProductsTemplatesKeys.all);
            },
        },
    );
};

export default useUpdateProductPrices;
