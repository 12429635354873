import I18nApi from '@app/api/public/I18nApi';
let stringData: { [key: string]: string } = {};

export async function prepareI18n() {
    stringData = await I18nApi.strings();
}

export const L = (key?: string | null) => {
    if (!key) return '';
    return stringData[key] || key;
};
