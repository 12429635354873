import React from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import ProjectTemplateForm from '../../components/ProjectTemplates/ProjectTemplateForm';
import { L } from '../../../../lib/i18n';

const AddProjectTemplatePage = () => {
    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_project_template')}</CardTitle>}
            mainArea={<ProjectTemplateForm />}
        />
    );
};

export default AddProjectTemplatePage;
