import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import { animated, useSpring } from '@react-spring/web';
import ArrowEnlarger from '../../../components/Icon/arrows-maximize-2-glyph-16.svg';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import StyledCross from '../../binders/components/emotion/StyledCross';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';

const StyledConfirm = styled.button({
    fontWeight: 80,
    fontSize: '1rem',
    border: 'none',
    textAlign: 'center',
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    padding: '5px 6px',
    cursor: 'pointer',
    color: 'white',
    zIndex: 9999,
});

const PhotoComponent = styled.div(({ url }: { url: string }) => ({
    transformOrigin: '0 0',
    borderRadius: '8px',
    backgroundImage: `url("${url}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: 'grey',
    cursor: 'pointer',
    paddingBottom: '100%',
    position: 'relative',
    '&:focus-visible': {
        outline: 'none',
    },
}));

const StyledContainer = animated(
    styled.div({
        position: 'absolute',
        right: 5,
        top: 5,
        boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(0, 0, 0, 0.25)',
        borderRadius: '25px',
    }),
);

const StyledEnlarger = styled.div({
    position: 'absolute',
    right: 5,
    bottom: 5,
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    borderRadius: '25px',
    width: '32px',
    height: '32px',
    display: 'grid',
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const StyledDialogContainer = styled.div({
    height: '100%',
    display: 'grid',
    maxHeight: 'inherit',
});

const StyledImage = styled.img({
    placeSelf: 'center',
    maxWidth: '100%',
    maxHeight: 'inherit',
});

const StyledEnlargerArrow = styled(ArrowEnlarger)({
    placeSelf: 'center',
});

const CloseCross = styled(StyledCross)({
    width: '16px',
    height: '16px',
    '& path': {
        stroke: colors.white,
    },
});

const CrossContainer = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
});

type Props = {
    url: string;
    handleRemove: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const LocalImage = ({ handleRemove, url }: Props) => {
    const [open, setOpen] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const props = useSpring({ width: confirmDelete ? 75 : 32 });
    return (
        <PhotoComponent title="Image" url={url}>
            <StyledContainer style={props}>
                {confirmDelete ? (
                    <StyledConfirm
                        data-no-dnd="true"
                        type="button"
                        onClick={(e) => {
                            handleRemove(e);
                        }}
                    >
                        {L('confirm')}
                    </StyledConfirm>
                ) : (
                    <CrossContainer
                        data-no-dnd="true"
                        onClick={() => {
                            setConfirmDelete(true);
                        }}
                    >
                        <CloseCross />
                    </CrossContainer>
                )}
            </StyledContainer>

            <StyledEnlarger data-no-dnd="true" onClick={() => setOpen(true)}>
                <StyledEnlargerArrow />
            </StyledEnlarger>

            <Dialog.Root open={!!open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <StyledDialogContainer>
                                <StyledImage src={url} />
                            </StyledDialogContainer>
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
        </PhotoComponent>
    );
};

type PhotoProps = {
    url: string;
};
const Photo = ({ url }: PhotoProps) => {
    const [open, setOpen] = React.useState(false);

    return (
        <PhotoComponent title="Image" url={url}>
            <StyledEnlarger data-no-dnd="true" onClick={() => setOpen(true)}>
                <StyledEnlargerArrow />
            </StyledEnlarger>

            <Dialog.Root open={!!open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <StyledDialogContainer>
                                <StyledImage src={url} />
                            </StyledDialogContainer>
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
        </PhotoComponent>
    );
};

export { Photo, LocalImage };
