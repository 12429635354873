import React from 'react';
import styled from '@emotion/styled';
import { Switch, Route, NavLink, useParams } from 'react-router-dom';
import { Header } from '../../components/Layout/Header';
import { L } from '../../lib/i18n';
import EditMaintenancePage from './page/EditMaintenancePage';
import AddMaintenancePage from './page/AddMaintenancePage';
import SettingsPage from './page/SettingsPage';
import Settings from './components/Settings';
import StatusSettings from './components/StatusSettings';
import SliderMenuMaintenanceLinks from './components/SliderMenuMaintenanceLinks';
import SliderMenuProvider from '../../components/SliderMenu/context/SliderMenuContext';
import AnalysPage from './page/AnalysPage';
import ServicePartnerPage from './page/ServicePartnerPage';
import CategorySettings from './components/CategorySettings';
import charBar from '../../components/Icon/chart-bar.svg';
import bulletList from '../../components/Icon/bullet-list.svg';
import { colors } from '../../components/Shared/Style/colors';
import useGetProjects from '../../lib/hooks/useGetProjects';
import MaintenanceTablePage from './page/MaintenanceTablePage';

const Container = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
});

const ChartIcon = styled(charBar)({
    height: '20px',
    width: '30px'
});
const ListIcon = styled(bulletList)({
    height: '20px',
    width: '20px'
});
const LinkWrapper = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '20px',
    paddingRight: '20px',
    '& .active': {
        svg: {
            g: {
                stroke: colors.primary
            }
        }
    }
});
const LinkIcon = styled(NavLink)({});

export const MaintenancePage = () => {
    useGetProjects('maintenance');
    const { projectId } = useParams<{ projectId: string }>();
    return (
        <SliderMenuProvider>
            <>
                <Switch>
                    <Route path={L(`/maintenance/${projectId}/service_partner`)}>
                        <Header Links={<SliderMenuMaintenanceLinks />} sliderMenu={true} title={L('service_partner')}>
                            <LinkWrapper>
                                <LinkIcon to={`/maintenance/${projectId}/analys`}>
                                    <ChartIcon />
                                </LinkIcon>
                                <LinkIcon exact to={`/maintenance/${projectId}`}>
                                    <ListIcon />
                                </LinkIcon>
                            </LinkWrapper>
                        </Header>
                    </Route>
                    <Route path={L(`/maintenance/${projectId}`)}>
                        <Header Links={<SliderMenuMaintenanceLinks />} sliderMenu={true} title={L('maintenance')}>
                            <LinkWrapper>
                                <LinkIcon to={`/maintenance/${projectId}/analys`}>
                                    <ChartIcon />
                                </LinkIcon>
                                <LinkIcon exact to={`/maintenance/${projectId}`}>
                                    <ListIcon />
                                </LinkIcon>
                            </LinkWrapper>
                        </Header>
                    </Route>
                </Switch>
                <Container>
                    <Switch>
                        <Route exact path="/maintenance/:projectId">
                            <MaintenanceTablePage />
                        </Route>
                        <Route path="/maintenance/:projectId/service_partner">
                            <ServicePartnerPage />
                        </Route>

                        <Route exact path="/maintenance/:projectId/add">
                            <AddMaintenancePage />
                        </Route>
                        <Route exact path="/maintenance/:projectId/analys">
                            <AnalysPage />
                        </Route>
                        <Route exact path="/maintenance/:projectId/category">
                            <SettingsPage>
                                <CategorySettings settingsType={'category'} />
                            </SettingsPage>
                        </Route>
                        <Route exact path="/maintenance/:projectId/status">
                            <SettingsPage>
                                <StatusSettings settingsType={'status'} />
                            </SettingsPage>
                        </Route>
                        <Route exact path="/maintenance/:projectId/unit">
                            <SettingsPage>
                                <Settings settingsType={'unit'} />
                            </SettingsPage>
                        </Route>
                        <Route exact path="/maintenance/:projectId/type">
                            <SettingsPage>
                                <Settings settingsType={'type'} />
                            </SettingsPage>
                        </Route>
                        <Route path="/maintenance/:projectId/:id">
                            <EditMaintenancePage />
                        </Route>
                    </Switch>
                </Container>
            </>
        </SliderMenuProvider>
    );
};
