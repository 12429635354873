import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    Row as RowType,
} from '@tanstack/react-table';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';

import { useNavigate } from 'react-router-dom-v5-compat';
import { colors } from '../../../../components/Shared/Style/colors';
import { ListMembersByPropertyId } from '@app/trpc-api/models/Members';
import { useListPropertyMember } from '../../hooks/memberHooks/propertyMemberQueries';
import GenericTable from '../GenericTable';
import dayjs from 'dayjs';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../../components/Loading/Loading';

export type FiltersSpaces = {
    space_type: { value: string; label: string }[];
};

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const StyledNameDiv = styled.div({
    textDecoration: 'none',
    color: 'inherit',
});

const StyledP = styled.p({
    color: colors.black80,
    margin: 0,
    fontSize: '12px',
});

const columnHelper = createColumnHelper<ListMembersByPropertyId>();

type Props = {
    propertyId: number;
    handleSetSelectedMembers: (selectedMembers: ListMembersByPropertyId[]) => void;
    handleOpenModal: () => void;
    handleSetNumberOfMembers: (val: number | null) => void;
};

export default function MembersList({
    propertyId,
    handleSetSelectedMembers,
    handleOpenModal,
    handleSetNumberOfMembers,
}: Props) {
    const columns = useMemo(
        () => [
            {
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                enableResizing: false,
            },
            columnHelper.accessor((row) => [row.name, row.email], {
                id: 'name_and_mail',
                cell: (info) => (
                    <CellContainer>
                        <StyledNameDiv>
                            <Span>{info.row.original.name}</Span>
                            <StyledP>{info.row.original.email}</StyledP>
                        </StyledNameDiv>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name_and_email')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.confirmed_at, {
                id: 'confirmed_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>
                            {info.row.original.confirmed_at
                                ? dayjs(info.row.original.confirmed_at).format('YYYY-MM-DD HH:mm')
                                : ''}
                        </Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('account_confirmed')}</Flex>,
                minSize: 110,
                size: 150,
                sortingFn: 'alphanumeric',
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.invited_at, {
                id: 'invited_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>
                            {info.row.original.invited_at
                                ? dayjs(info.row.original.invited_at).format('YYYY-MM-DD HH:mm')
                                : ''}
                        </Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('invited')}</Flex>,
                minSize: 110,
                size: 150,
                sortingFn: 'alphanumeric',
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.phone_number, {
                id: 'phone_number',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.phone_number}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('phone_number')}</Flex>,
                minSize: 110,
                size: 150,
                sortingFn: 'alphanumeric',
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [],
    );

    const navigate = useNavigate();

    const handleClickRow = useCallback((row: RowType<ListMembersByPropertyId>) => {
        navigate(`${row.original.id}`);
    }, []);

    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});

    const { data: members, status } = useListPropertyMember(propertyId);

    const table = useReactTable({
        data: members ?? [],
        columns,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
    });

    useEffect(() => {
        setRowSelection({});
        handleSetNumberOfMembers(members ? members.length : null);
    }, [members]);

    useEffect(() => {
        const selectedData = table.getSelectedRowModel().flatRows.map((data) => data.original);
        handleSetSelectedMembers(selectedData);
    }, [rowSelection]);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <GenericTable table={table} rowClickHandler={handleClickRow} rowProps={{ style: { minHeight: '65px' } }} />
            {members?.length === 0 && members && (
                <EmptyState>
                    <EmptyState.Title>{L('no_property_members')}</EmptyState.Title>
                    <EmptyState.Description>
                        <p>{L('no_property_members_description')}</p>
                        <p>{L('click_button_below')}</p>
                        <Button onClick={handleOpenModal}>{L('new_member')}</Button>
                    </EmptyState.Description>
                </EmptyState>
            )}
        </>
    );
}
