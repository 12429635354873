import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import NoticeTitle from '../NoticeTitle';
import NoticeLabel from '../NoticeLabel';
import NoticeDescription from '../NoticeDescription';

import { Icon } from '../../../../components/Icon/Icon';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import NoticeDate from '../NoticeDate';
import dayjs from 'dayjs';
import { colors } from '@app/shared/lib/Style/colors';
import { NotificationType } from '@app/api/models/NotificationModel';

const Border = styled.div(({ theme }) => {
    return {
        backgroundColor: colors.alertBackground,
        padding: '16px',
        width: '100%',
        borderRadius: '8px',
        display: 'grid',
        whiteSpace: 'pre-line',
        gridTemplateColumns: 'min-content auto min-content',
        gridTemplateAreas: `
    "icon title label"
    "icon description label"
    "icon date label"
    `,
        columnGap: '1rem',
        rowGap: '0.5 rem'
    };
});

const IconDiv = styled.div(({ theme }) => {
    return {
        cursor: 'pointer',
        gridArea: 'icon',
        placeSelf: 'center',
        '& span': {
            color: colors?.textColor2
        },
        svg: {
            height: '24px',
            width: '24px'
        }
    };
});

const TicketNotice: FC<NotificationType & { isCollapsed: boolean }> = ({
    title,
    body,
    isCollapsed,
    link,
    read,
    created_at
}) => {
    const notificationDate = dayjs(created_at).isValid() ? dayjs(Date.now()).to(created_at) : '';
    return (
        <Link to={link ?? `/`} style={{ color: colors.black, textDecoration: 'none' }}>
            <Border style={{ backgroundColor: read ? style.colors.background : style.colors.alertBackground }}>
                <IconDiv>
                    <Icon.Tickets />
                </IconDiv>

                <NoticeTitle isCollapsed={isCollapsed} description={title} />
                <NoticeDescription description={body} isCollapsed={isCollapsed} />
                <NoticeLabel type={'show_ticket'} backgroundColor={style.colors.primary} />
                <NoticeDate when={notificationDate} />
            </Border>
        </Link>
    );
};

export default TicketNotice;
