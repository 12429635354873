import { useMutation, useQueryClient } from 'react-query';
import { AddPackageProduct } from '../../binders/schemas';
import { trpc } from '../../../lib/trpc';
import { getProductsKeys } from '../../binders/hooks/productQueries';
import { TRPCClientError } from '@trpc/client';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';

export default function useAddProductToTemplatePackage() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddPackageProduct; parentId: number }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.BindersApi.saveInventoryMaterialOnAccountProduct.query({
                product: dataToSave,
                toProductId: options.parentId,
            });
        },
        {
            onSettled(_data, _error, variables) {
                queryClient.invalidateQueries(getProductsKeys.productsInPackage({ entityId: variables.parentId }));
            },
            onError(err) {
                if (err instanceof TRPCClientError) {
                    if (err.message.includes('product is a package and cannot be added to another product'))
                        toast.error(L('add_package_to_package_err'));
                    if (err.message.includes(`a product can't be added to another product in a package`))
                        toast.error(L('add_product_to_package_product_err'));
                } else toast.error(L('an_error_occurred'));
            },
        },
    );
}
