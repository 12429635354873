import { useInfiniteQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';

export const getAdminKeys = {
    all: ['admins'] as const,
    lists: () => [...getAdminKeys.all, 'list'] as const,
    infiniteList: (params: { sorting: SortingState; search: string }) => [...getAdminKeys.lists(), 'infinite', params]
};

export function useGetInfiniteAdmins({ sorting, search }: { sorting: SortingState; search: string }) {
    return useInfiniteQuery(
        [...getAdminKeys.infiniteList({ sorting, search })],
        async ({ pageParam = 1 }) => trpc.MembersApi.listAdminsByAccount.query({ sorting, search, page: pageParam }),
        {
            keepPreviousData: true,
            getNextPageParam: lastPage => {
                return lastPage.nextCursor;
            }
        }
    );
}
