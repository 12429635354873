import { SupplierFilter } from '@app/api/models/Addons';
import * as React from 'react';
import { AddonReportStore } from '../../AddonReportStore';
import { FilterFunction } from './ReportFilterFunction';
import { FilteredProduct } from './FilterProduct';
import { FilterProperty } from './ReportFilterProperty';
import { Filters } from '../../../../../components/Filters/Filters';
import { FilterSpace } from './ReportFilterSpace';
import { FilterProfession } from './FilterProfession';
import { FilterSupplier } from './FilterSupplier';
import { EntitySupplierStore } from '../../EntitySupplier/EntitySupplierStore';
import { FilterAddonType } from './FilterAddonType';
import { FilterHasPrice } from './FilterHasPrice';
import { FilterIncludeImage } from './FilterIncludeImage';
import { FilterProductGroup } from './FilterProductGroup';

interface Props {
    setFilter: (f: SupplierFilter) => void;
    filter: SupplierFilter;
}

export const AddonReportFilterPanel = (props: Props) => {
    return (
        <div>
            <Filters showLabel marginBottom={'12px'}>
                <FilterProperty setFilter={props.setFilter} filter={props.filter} store={AddonReportStore} />
                <FilterSpace setFilter={props.setFilter} filter={props.filter} store={AddonReportStore} />
                <FilterFunction setFilter={props.setFilter} filter={props.filter} store={EntitySupplierStore} />
                <FilterProductGroup setFilter={props.setFilter} filter={props.filter} store={AddonReportStore} />
                <FilteredProduct setFilter={props.setFilter} filter={props.filter} store={AddonReportStore} />
            </Filters>
            <Filters showLabel={false}>
                <FilterSupplier setFilter={props.setFilter} filter={props.filter} store={EntitySupplierStore} />
                <FilterProfession setFilter={props.setFilter} filter={props.filter} store={EntitySupplierStore} />
                <FilterAddonType setFilter={props.setFilter} filter={props.filter} />
                <FilterHasPrice setFilter={props.setFilter} filter={props.filter} />
                <FilterIncludeImage store={AddonReportStore} />
            </Filters>
        </div>
    );
};
