import styled from '@emotion/styled';

export default styled.div({
    gap: '0.5rem',
    paddingBottom: '0.5rem',
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    width: '100%'
});
