import React from 'react';
import styled from '@emotion/styled';

const Logo = styled.img({
    maxWidth: '100px',
    maxHeight: '50px'
});

export const ReportHeaderLogo = ({ logo }) => {
    return logo ? <Logo src={logo} /> : <React.Fragment />;
};
