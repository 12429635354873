import React from 'react';
import useImportTemplateProduct from '../../binders/hooks/CopyFunctionHooks/useImportTemplateProduct';
import { useGetLibraries } from '../../binders/hooks/libraryQueries';
import { useParams } from 'react-router-dom-v5-compat';
import AddProductFromTemplateComponent from '../../binders/components/Products/AddProductFromTemplateComponent';
import { useGetPropSpaces } from '../hooks/propertyTemplateQueries';

const AddInventoryFromTemplate = () => {
    const { id: propertyId } = useParams<{ id: string }>();
    const { data: libraries } = useGetLibraries();
    const { mutate: importTemplateProduct } = useImportTemplateProduct();
    const { data: spaces } = useGetPropSpaces(+propertyId);
    const handleImportTemplateProduct = ({
        products,
        propertyId,
        destinationId,
    }: {
        propertyId: number;
        products: {
            id: number;
            package_type: string | null;
        }[];
        destinationId: number;
    }) => {
        importTemplateProduct({
            propertyId: +propertyId,
            products,
            spaceId: +destinationId,
        });
    };
    return propertyId && libraries ? (
        <AddProductFromTemplateComponent
            libraries={libraries}
            propertyId={+propertyId}
            handleImportToPropertyProduct={handleImportTemplateProduct}
            spaces={spaces}
        />
    ) : null;
};

export default AddInventoryFromTemplate;
