import styled from '@emotion/styled';
import React, { FormEvent } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router';
import { Button } from '@ourliving/ourliving-ui';
import Label from '../../../components/Forms/Label';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useGetTicket, useGetTicketNotes } from '../hooks/ticketQueries';
import useSaveTicketNote from '../hooks/useSaveTicketNote';
import Note from './Note';
import AttachmentIcon from '../../../components/Icon/attachment.svg';
import DocumentClick from '../../../components/document/DocumentClick/DocumentClick';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { TextArea } from '@ourliving/ourliving-ui';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';

const NotesWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column-reverse',
    gap: '1rem',
    marginTop: '1rem',
});

const Form = styled.form({
    display: 'grid',
    gap: '1rem',
});

const StyledAttachmentIcon = styled(AttachmentIcon)({
    height: '24px',
    width: '24px',
});

const FakeInput = styled.div(() => ({
    background: colors.inputBackground,
    border: `1px solid ${colors.inputBorder}`,
    width: '100%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    resize: 'vertical',
    '&:read-only': {
        color: colors.inputPlaceholderColor,
        cursor: 'default',
    },
}));

const InvisibleTextArea = styled(TextArea)({
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
});

const Notes = ({ readOnly }: { readOnly?: boolean }) => {
    const { id, projectId } = useParams<{ id: string; projectId: string }>();
    const { data } = useGetTicket(id);
    const { data: notes } = useGetTicketNotes(id);
    const { mutate, status } = useSaveTicketNote();
    const [noteText, setNoteText] = React.useState('');
    const [files, setFiles] = React.useState<File[]>([]);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        mutate(
            {
                note: {
                    text: noteText,
                    ticketId: +id,
                },
                files,
            },
            {
                onSuccess: () => {
                    toast.success(L('ticket_note_saved'));
                    setNoteText('');
                    setFiles([]);
                },
            },
        );
    };

    const handleAddAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const eventFiles = Array.from(e.target.files);
            const newFiles = files.filter((file) => !eventFiles.some((item) => item.name === file.name));
            setFiles([...newFiles, ...eventFiles]);
        }
    };

    const Documents = files.map((file) => {
        const url = URL.createObjectURL(file);
        const handleDelete = () => {
            setFiles(files.filter((item) => item.name !== file.name));
        };
        return (
            <DocumentClick
                key={file.name}
                fileType={file.type}
                url={url}
                name={file.name}
                deleteDocument={handleDelete}
            />
        );
    });
    return (
        <CardWithDivider
            topArea={
                <CardTitle>
                    #{data?.property_id} - {data?.ticket_no} {L('notes')}
                </CardTitle>
            }
            mainArea={
                <div>
                    {!readOnly && (
                        <Form onSubmit={handleSubmit}>
                            <Label title={L('notes')}>
                                <FakeInput>
                                    <InvisibleTextArea
                                        value={noteText}
                                        onChange={(e) => setNoteText(e.target.value)}
                                        placeholder={L('chat_placeholder')}
                                    />
                                    {Documents}
                                </FakeInput>
                            </Label>

                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <label style={{ cursor: 'pointer' }}>
                                    <input type={'file'} style={{ display: 'none' }} onChange={handleAddAttachments} />
                                    <StyledAttachmentIcon />
                                </label>
                                <Button
                                    disabled={!noteText && !files.length}
                                    isLoading={status === 'loading'}
                                    style={{ placeSelf: 'end' }}
                                >
                                    {L('save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                    <NotesWrapper>
                        {notes?.map((note) => <Note key={note.id} note={note} readOnly={readOnly} />)}
                        {notes?.length === 0 && (
                            <EmptyState>
                                <EmptyState.Title>{L('no_notes')}</EmptyState.Title>
                                {!readOnly && (
                                    <EmptyState.Description>
                                        <p>{L('no_notes_description')}</p>
                                    </EmptyState.Description>
                                )}
                            </EmptyState>
                        )}
                    </NotesWrapper>
                </div>
            }
        />
    );
};

export default Notes;
