import React from 'react';
import { L } from '../../../lib/i18n';
import DropdownContainer from './emotion/DropdownContainer';
import FlexGapContainer from './emotion/FlexGapContainer';
import Row from './emotion/Row';
import StyledSpan from './emotion/StyledSpan';
import ReactSelect from '../../../components/Dropdown/ReactSelect';

export interface Option {
    value: number;
    label: string;
}

export default function RowsNav({ selectedType, setSelectedType }) {
    const options = [
        { value: 10, label: '10 ' + L('rows') },
        { value: 20, label: '20 ' + L('rows') },
        { value: 50, label: '50 ' + L('rows') },
        { value: 100, label: '100 ' + L('rows') }
    ];
    const changeType = (newOption: Option) => {
        if (newOption) {
            setSelectedType(newOption.value);
        }
    };

    return (
        <Row>
            <FlexGapContainer>
                <StyledSpan>{L('show')}:</StyledSpan>
                <DropdownContainer>
                    <ReactSelect
                        options={options}
                        value={selectedType}
                        onChange={value => changeType(value)}
                        isSearchable={false}
                    />
                </DropdownContainer>
            </FlexGapContainer>
        </Row>
    );
}
