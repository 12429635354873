import { TicketFilter } from '@app/api/public/TicketsApi';
import { TicketMessageThread } from '@app/api/models/Tickets';
import { SortingState } from '@tanstack/react-table';

export type MessageType = TicketMessageThread['ticket_message_thread_type'];
export const ticketQueryKeys = {
    all: ['tickets'],
    list: (filter?: TicketFilter, searchQuery?: string) =>
        filter || searchQuery
            ? [...ticketQueryKeys.all, 'list', filter, searchQuery]
            : [...ticketQueryKeys.all, 'list'],
    listInfinite: (sorting?: SortingState, filter?: TicketFilter, searchQuery?: string) =>
        filter || searchQuery || sorting
            ? [...ticketQueryKeys.all, 'listInfinite', filter, searchQuery, sorting]
            : [...ticketQueryKeys.all, 'listInfinite'],
    id: (id?: string) => [...ticketQueryKeys.all, id],
    spaces: (id?: string) => [...ticketQueryKeys.all, id],
    archive: (filter?: TicketFilter) =>
        filter ? [...ticketQueryKeys.all, 'archive', filter] : [...ticketQueryKeys.all, 'archive'],
    listArchiveInfinite: (sorting?: SortingState, filter?: TicketFilter, searchQuery?: string) =>
        filter || searchQuery || sorting
            ? [...ticketQueryKeys.all, 'listArchiveInfinite', filter, searchQuery, sorting]
            : [...ticketQueryKeys.all, 'listArchiveInfinite'],
    inventories: (id?: string) => [...ticketQueryKeys.all, id],
    messages: (messageType: MessageType, ticketId?: string) => [...ticketQueryKeys.all, messageType, ticketId],
    notes: (ticketId?: string) => [...ticketQueryKeys.all, 'notes', ticketId],
    log: (ticketId?: string) => [...ticketQueryKeys.all, 'log', ticketId],
    status: () => [...ticketQueryKeys.all, 'status'],
    priority: () => [...ticketQueryKeys.all, 'priority'],
    assignees: () => [...ticketQueryKeys.all, 'assignees'],
    type: () => [...ticketQueryKeys.all, 'type'],
    responseText: () => [...ticketQueryKeys.all, 'responseText'],
    serviceText: () => [...ticketQueryKeys.all, 'serviceText'],
    dashboard: () => [...ticketQueryKeys.all, 'dashboard'],
    filters: () => [...ticketQueryKeys.all, 'filters'],
};
