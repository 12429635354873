import React, { useEffect, useMemo } from 'react';
import { L } from '../../../../lib/i18n';
import { ColumnDef, RowSelectionState, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useGetProperties } from '../../hooks/propertyHooks/propertyQueries';
import GenericTable from '../GenericTable';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { RouterOutput } from '../../../../lib/trpc';

type PropertyType = RouterOutput['BindersApi']['getProperties'][0];

function CopyToPropertiesListComponent({
    projectId,
    handleSetSelectedProperties,
    handleSetNumberOfProperties,
    selectedProperties,
    columns,
    search,
}: {
    projectId: number;
    handleSetSelectedProperties: (val: RowSelectionState) => void;
    handleSetNumberOfProperties: (val: number | null) => void;
    selectedProperties: Record<string, boolean> | null;
    columns: ColumnDef<PropertyType>[];
    search: string;
}) {
    const { data } = useGetProperties({ projectId, shouldFetch: 'all', search });

    useEffect(() => {
        handleSetNumberOfProperties(data ? data.length : null);
    }, [data]);

    const dataMemo = useMemo(() => {
        return data || [];
    }, [data]);

    const tableData = useReactTable({
        data: dataMemo,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection: selectedProperties ?? {} },
        onRowSelectionChange: (updater) => {
            if (typeof updater === 'function') {
                const val = updater(selectedProperties ?? {});
                handleSetSelectedProperties(val);
            }
        },
        getRowId: (originalRow) => String(originalRow.id),
    });

    return data && data.length > 0 ? (
        <GenericTable shouldFillWidth={false} table={tableData} />
    ) : (
        <EmptyState>
            <EmptyState.Title>{L('no_properties_empty_state')}</EmptyState.Title>
            <EmptyState.Description>{L('no_properties_description')}</EmptyState.Description>
        </EmptyState>
    );
}

const CopyToPropertyiesList = ({
    projectId,
    handleSetSelectedProperties,
    handleSetNumberOfProperties,
    selectedProperties,
    columns,
    search,
}: {
    projectId: number | null;
    handleSetSelectedProperties: (val: RowSelectionState) => void;
    handleSetNumberOfProperties: (val: number | null) => void;
    selectedProperties: Record<string, boolean> | null;
    columns: ColumnDef<PropertyType>[];
    search: string;
}) => {
    return projectId ? (
        <CopyToPropertiesListComponent
            projectId={projectId}
            handleSetSelectedProperties={handleSetSelectedProperties}
            handleSetNumberOfProperties={handleSetNumberOfProperties}
            selectedProperties={selectedProperties}
            columns={columns}
            search={search}
        />
    ) : (
        <EmptyState>
            <EmptyState.Title>{L('no_project_chosen_title')}</EmptyState.Title>
            <EmptyState.Description>{L('no_project_chosen_description')}</EmptyState.Description>
        </EmptyState>
    );
};

export default CopyToPropertyiesList;
