import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoDetail from './TodoDetail';

const TodoDetailPage = () => {
    const { todoId } = useParams<{ todoId: string }>();
    return todoId ? <TodoDetail todoId={+todoId} /> : null;
};

export default TodoDetailPage;
