import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyTemplatesKeys } from '../../../property-templates/hooks/propertyTemplateQueries';
import { AddPropertyTemplate } from '../../../property-templates/PropertyTemplates/PropertyTemplateForm';

export default function useAddPropertyTemplate() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ property }: { property: AddPropertyTemplate }) => {
            return trpc.TemplatesApi.addPropertyTemplate.query({ property });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(getPropertyTemplatesKeys.lists());
            },
        },
    );
}
