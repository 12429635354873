import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoList from './TodoList';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import H1 from '../../../../components/typography/H1';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';

const TodoListPage = () => {
    const { propertyId, id } = useParams<{ propertyId: string; id: string }>();

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(id);
    const propertyName = useGetPropertyName(propertyId);

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../..'}>{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="../products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="../members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="../contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="../documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            {propertyId && <TodoList propertyId={+propertyId} />}
        </>
    );
};

export default TodoListPage;
