import styled from '@emotion/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../folder-outline-16.svg';

const FolderIcon = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& path': {
            stroke: colors.icons,
        },
    };
});
const Folder: FC = ({ ...rest }) => {
    return <FolderIcon {...rest} />;
};

export default Folder;
