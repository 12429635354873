import React from 'react';
import styled from '@emotion/styled';
import { Icon } from '../../../components/Icon/Icon';
import { ServicePartnerCategory } from '@app/api/models/ServicePartners';
import useDeleteServicePartnerCategory from '../hooks/useDeleteServicePartnerCategory';
import BoxColorPicker from './BoxColorPicker';

const Item = styled.div({
    backgroundColor: 'gray',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid black',
    width: '300px',
    margin: '1rem 0px 1rem 1rem',
    paddingLeft: '1rem',
    color: 'white',
    '&:focus': {
        outline: 'none',
    },
});

const ItemWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const TrashCanIcon = styled(Icon.TrashCan)({
    margin: '0.3rem',
    cursor: 'pointer',
});

const InvisibleButton = styled.button({
    backgroundColor: 'transparent',
    border: 'none',
});

type Props = {
    item: ServicePartnerCategory;
};

const ServicePartnerCategoryItem = ({ item }: Props) => {
    const { mutate: remove } = useDeleteServicePartnerCategory();

    return (
        <ItemWrapper key={item.category_id}>
            <BoxColorPicker initialColor={item.color_code || ''} getValue={(value) => value} />

            <Item>{item.category_name}</Item>

            <InvisibleButton
                onClick={() => remove({ servicePartnerId: item.service_partner_id, categoryId: item.category_id })}
            >
                <TrashCanIcon />
            </InvisibleButton>
        </ItemWrapper>
    );
};

export default ServicePartnerCategoryItem;
