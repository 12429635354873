import styled from '@emotion/styled';
import React, { FC } from 'react';
import { style } from '../Shared/Style';

const StyledLogo = styled.img((props: { height?: string; isTopRight: boolean }) => ({
    maxHeight: `${props.height}px` || '100px',
    borderRadius: style.margin.xs,
    padding: '8px',
    position: props.isTopRight ? 'absolute' : 'static',
    right: props.isTopRight ? 12 : 'auto',
    top: props.isTopRight ? 12 : 'auto'
}));

type styledLogo = {
    classname?: string;
    height?: string;
    isTopRight?: boolean;
};

const Logo: FC<styledLogo> = ({ height, isTopRight, ...rest }) => {
    const matchedUrlName = window.location.origin.match(/\/{2}(\w+)\./i);

    const handleImgError = e => {
        e.currentTarget.src = 'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving.svg';
    };

    return matchedUrlName && matchedUrlName?.length > 1 && matchedUrlName[1] !== 'residence' ? (
        <StyledLogo
            isTopRight={isTopRight || false}
            alt="logo"
            height={height}
            {...rest}
            onError={handleImgError}
            src={`https://s3-eu-west-1.amazonaws.com/public.ourliving.se/ourliving/${matchedUrlName[1]}.svg`}
        />
    ) : (
        <StyledLogo
            isTopRight={isTopRight || false}
            alt="logo"
            height={height}
            {...rest}
            src={`https://s3-eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving.svg`}
        />
    );
};

export default Logo;
