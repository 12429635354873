import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { useObserver } from 'mobx-react';
import { ProductGroup } from '@app/api/models/Products';
import { MultiSelect } from '../../../../../components/Dropdown/MultiSelect';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import DropdownFlexContainer from './DropdownFlexContainer';

const Container = styled.div({ paddingRight: '10px', width: '160px' });

interface Props {
    filter: { product_type_id?: number[] };
    setFilter: (filter: any) => void;
    store: { productType: ProductGroup[]; load(projectId?: string): Promise<void> };
}

export const FilterFunction = (props: Props) => {
    const name = 'product_type_id';

    const handleOnChange = value => {
        event?.preventDefault();
        props.setFilter({
            ...props.filter,
            [name]: [...new Set<string>(value?.flatMap(id => id.value))]
        });
    };

    const options = props.store.productType
        .slice()
        .sort((a, b) => L(a.name).localeCompare(L(b.name), 'sv'))
        .map(v => ({
            value: v.id,
            label: (
                <DropdownFlexContainer>
                    <Checkbox onChange={() => handleOnChange} checked={props.filter[name]?.includes(v.id) || false} />
                    <span>{L(v.name)}</span>
                </DropdownFlexContainer>
            )
        }));

    const selectAll = [
        {
            value: props.store.productType?.map(prodType => prodType.id),
            label: ' Välj alla'
        },
        ...options
    ];

    return useObserver(() => {
        return (
            <Container>
                <MultiSelect
                    isMulti={true}
                    isClearable
                    value={props.filter[name]?.map(id => ({
                        value: id,
                        label: props.store.productType.find(({ id: prduct_type_id }) => prduct_type_id === +id)?.name
                    }))}
                    onChange={handleOnChange}
                    name={name}
                    label={L(name)}
                    options={selectAll}
                />
            </Container>
        );
    });
};
