import { Property } from '@app/api/models/Properties';
import AddonsApi from '@app/api/public/AddonsApi';
import { useQuery } from 'react-query';

export const getOrderUserKeys = {
    all: ['orderUser'] as const,
    list: (propertyId?: Property['id']) => [...getOrderUserKeys.all, 'list', { propertyId }] as const
};

const getUsersOnOrder = async (propertyId?: Property['id']) => {
    if (!propertyId) throw new Error('no propertyId');
    return await AddonsApi.getUsersOnOrder(+propertyId);
};
const useGetUsersOnOrder = (propertyId?: Property['id']) => {
    return useQuery(getOrderUserKeys.list(propertyId), () => getUsersOnOrder(propertyId), {
        enabled: !!propertyId
    });
};

export default useGetUsersOnOrder;
