import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

const useGetSingedUrlByIdQueryKey = 'getSingedUrlById';

const getSingedUrl = (id?: string) => {
    if (!id) return Promise.reject('no id');

    return DocumentsApi.getSignedUrl(id);
};

const useGetSingedUrlById = (id?: string) => {
    return useQuery([useGetSingedUrlByIdQueryKey, id], () => getSingedUrl(id), {
        enabled: !!id,
    });
};

export default useGetSingedUrlById;
