import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import PackageProductOnTemplateComponent from '../PackageProductOnTemplateComponent';

const PackageProductsTemplatePage = () => {
    const { productId, id: propertyId } = useParams<{ productId?: string; id: string }>();
    if (productId && propertyId)
        return <PackageProductOnTemplateComponent propertyId={propertyId} productId={productId} />;
    return null;
};

export default PackageProductsTemplatePage;
