import { useMutation } from 'react-query';
import { trpc } from '../../../lib/trpc';

const useUpdatePropertyDocSort = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.BindersApi.updatePropertyDocSort.mutate({ docs: params.docs, propertyId: params.documentable_id });
    });
};

export default useUpdatePropertyDocSort;
