import React from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import { variant } from 'styled-system';

interface Props {
    title: string;
    children?: any;
    textColor?: any;
    textAlign?: any;
}

interface ColorProps {
    textColor?: any;
    textAlign?: any;
}

const Container = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: style.margin.s,
    flexDirection: 'column'
});

const Title = styled.div<ColorProps>(
    {
        textAlign: 'left',
        width: '90%',
        fontWeight: style.fontWeight.bold,
        textTransform: 'uppercase',
        fontSize: style.fontSize.milli,
        lineHeight: '15px',
        letterSpacing: '0.3px',
        marginBottom: style.margin.s
    },
    variant({
        prop: 'textColor',
        variants: {
            1: {
                color: 'black'
            },
            0: {
                color: style.colors.black80
            }
        }
    }),
    variant({
        prop: 'textAlign',
        variants: {
            center: {
                textAlign: 'center'
            },
            left: {
                textAlign: 'left'
            },
            right: {
                textAlign: 'right'
            }
        }
    })
);

const Description = styled.div({
    width: '100%',
    border: `1px solid ${style.colors.inputBorder}`,
    borderRadius: style.roundCornerSize.small,
    backgroundColor: style.colors.inputBackground,
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    marginTop: style.margin.xs,
    whiteSpace: 'pre',
    overflow: 'auto'
});

export function DisplayField(p: Props) {
    return (
        <Container>
            <Title textColor={p.textColor} textAlign={p.textAlign}>
                {p.title}
            </Title>
            <Description>{p.children}</Description>
        </Container>
    );
}
