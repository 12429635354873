import styled from '@emotion/styled';
import React, { FC } from 'react';
import useGetServicePartners from '../hooks/useGetServicePartners';
import ServicePartnerListItem from './ServicePartnerListItem';

type Props = {
    searchValue: string;
};

const Ul = styled.ul({
    padding: 0,
    margin: 0,
    listStyle: 'none',
});

const ServicePartnerListHandler: FC<Props> = ({ searchValue, ...rest }) => {
    const servicePartners = useGetServicePartners();

    const searchRegexp = new RegExp(searchValue, 'i');

    return (
        <Ul {...rest}>
            {servicePartners.data
                ?.filter(
                    (servicePartner) =>
                        servicePartner.name.match(searchRegexp) ||
                        servicePartner?.contact.email.match(searchRegexp) ||
                        servicePartner?.contact.name.match(searchRegexp) ||
                        servicePartner?.contact.phone_number.match(searchRegexp)
                )
                .map((servicePartner) => {
                    return (
                        <ServicePartnerListItem
                            key={servicePartner.id}
                            id={servicePartner.id}
                            name={servicePartner.name}
                            city={servicePartner?.address?.city || ''}
                        />
                    );
                })}
        </Ul>
    );
};

export default ServicePartnerListHandler;
