import React, { useState } from 'react';
import styled from '@emotion/styled';
import Input from '../../../components/Forms/Input';
import * as Icons from 'react-feather';

const Container = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%'
});

const InputWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
});

const StyledInput = styled(Input)((props: { showModal?: boolean }) => ({
    width: '100%',
    height: '30px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    borderBottomLeftRadius: `${props.showModal ? '0px' : '4px'}`
}));

const ArrowBox = styled.div((props: { showModal?: boolean }) => ({
    width: '30px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FCFDFE',
    borderTop: '1px solid #F0F1F7',
    borderRight: '1px solid #F0F1F7',
    borderBottom: '1px solid #F0F1F7',
    borderTopRightRadius: '4px',
    borderBottomRightRadius: `${props.showModal ? '0px' : '4px'}`,
    outline: 'none',
    padding: '8px',
    fontSize: '14px',
    cursor: 'pointer'
}));

const Modal = styled.div({
    width: '100%',
    maxHeight: '30vh',
    overflowY: 'scroll',
    background: '#FCFDFE',
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #F0F1F7',
    borderLeft: '1px solid #F0F1F7',
    borderBottom: '1px solid #F0F1F7',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px'
});

const Option = styled.div({
    fontSize: '14px',
    padding: '8px',
    '&:hover': {
        backgroundColor: '#f2f2f2'
    }
});

type Props = {
    imageWords?: string[];
    setState: (value: string) => void;
    value: string;
};

const EditableDropdown = ({ imageWords, value, setState }: Props) => {
    const [showModal, setShowModal] = useState(false);
    const Options = imageWords
        ?.filter(words => {
            if (
                words
                    ?.toString()
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase())
            ) {
                return words;
            }
        })
        .map((word, i) => {
            return (
                <Option
                    key={i}
                    onClick={() => {
                        setState(word);
                        setShowModal(false);
                    }}
                >
                    {word}
                </Option>
            );
        });

    return (
        <Container>
            <InputWrapper>
                <StyledInput
                    showModal={showModal}
                    onClick={() => setShowModal(true)}
                    value={value}
                    onChange={e => setState(e.target.value)}
                />
                <ArrowBox showModal={showModal} onClick={() => setShowModal(!showModal)}>
                    <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />
                </ArrowBox>
            </InputWrapper>
            {showModal && <Modal>{Options}</Modal>}
        </Container>
    );
};

export default EditableDropdown;
