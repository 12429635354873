import { useMutation, useQueryClient } from 'react-query';
import { AddProduct } from '../../schemas';
import { trpc } from '../../../../lib/trpc';
import { getProductFilterKeys, getProductsKeys } from '../productQueries';

export default function useAddProduct() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddProduct }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.BindersApi.saveInventoryMaterial.query({
                product: dataToSave,
                propertyId: dataToSave.property_id,
            });
        },
        {
            onSettled: async (_res, _err, variables) => {
                queryClient.invalidateQueries(getProductsKeys.all);
                queryClient.invalidateQueries(
                    getProductFilterKeys.list({ propertyId: variables.inventoryMaterial.property_id }),
                );
            },
        },
    );
}
