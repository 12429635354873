import React from 'react';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import EditPen from './emotion/EditPen';
import FlexContainer from './emotion/FlexContainer';

const CommentContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem  1rem',
    flexDirection: 'column'
});

const CommentHeader = styled.div({
    display: 'flex',
    width: '100%',
    padding: '0.5rem  0',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const CommentBody = styled.div({
    display: 'flex',
    width: '100%',
    padding: '1rem',
    backgroundColor: style.colors.lightgray,
    borderRadius: 8
});

const Circle = styled.div({
    width: '30px',
    height: '30px',
    backgroundColor: 'green',
    borderRadius: '50%'
});

const Author = styled.div({
    padding: '0 1rem'
});

//Currently unused, but will be implemented in the next release
export default function Comment() {
    return (
        <CommentContainer>
            <CommentHeader>
                <FlexContainer>
                    <Circle />
                    <Author>Linus</Author>
                </FlexContainer>
                <EditPen />
            </CommentHeader>
            <CommentBody>This is such a good product</CommentBody>
        </CommentContainer>
    );
}
