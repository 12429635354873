import React from 'react';
import { colors } from '../../Shared/Style/colors';
import styled from '@emotion/styled';

const StyledButton = styled.button(() => {
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        letterSpacing: '0.41px',
        color: colors.tabInactive,
        boxSizing: 'border-box',
        display: 'inline-block',
        marginRight: '16px',
        textDecoration: 'none',
        border: 'none',
        backgroundColor: 'inherit',
        '&:hover': {
            color: colors.primary,
            cursor: 'pointer'
        },
        '&.active': {
            color: colors.primary,
            textDecoration: 'none'
        }
    };
});

const BigTab: React.FC<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = ({
    children,
    ...rest
}) => {
    return <StyledButton {...rest}>{children}</StyledButton>;
};

export default BigTab;
