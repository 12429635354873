import React from 'react';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Alert } from '@app/api/public/AlertsApi';
import { NavLink } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';
import useGetProjects from '../hooks/useGetProjects';
import dayjs from 'dayjs';
import { L } from '../../../lib/i18n';
import { fontSize } from '../../../components/Shared/Style/typography';
interface AlertCardProps {
    alert?: Alert;
}

const cssActive = {
    borderColor: '#2EB67D',
    border: '2px solid #2EB67D'
};

const Text = styled.div({
    fontSize: style.fontSize.seta,
    fontWeight: 'inherit'
});

const Message = styled(NavLink)({
    display: 'block',
    width: '100%',
    textDecoration: 'none',
    color: colors.black,
    borderRadius: style.roundCornerSize.small,
    border: '2px solid transparent',
    cursor: 'pointer',
    ':hover': { borderColor: '#2EB67D', border: '2px solid #2EB67D' },
    '&.active': { ...cssActive }
});

const Body = styled.div((p: { prio: string | undefined }) => ({
    borderRadius: '4px',
    overflow: 'hidden',
    borderLeft: `6px solid ${p.prio}`,
    background: 'white',
    gridAutoRows: 'min-content 1fr',
    gridAutoColumns: 'auto auto',
    padding: style.margin.m,
    paddingBottom: '42px',
    display: 'grid',
    gridTemplate: `
    'project   endDate'
    'title   title'
    `,
    rowGap: '4px'
}));

const getColor = (prio: string) => {
    if (prio === 'low') return '#29CC97';
    if (prio === 'medium') return '#FEC400';
    if (prio === 'high') return '#F7685B';
};

export function AlertCard({ alert }: AlertCardProps) {
    const { data: projects } = useGetProjects();
    const project = projects?.find(project => project.id === alert?.project_id);
    return (
        <Message to={`/alerts/${alert?.id}`}>
            <Body prio={getColor(alert?.priority || '')}>
                <Text style={{ gridArea: 'project', color: colors.black80, fontSize: fontSize.milli }}>
                    {project?.name || ''}
                </Text>
                <Text style={{ gridArea: 'title' }}>{alert?.title || ''}</Text>
                <Text
                    style={{ gridArea: 'endDate', justifySelf: 'end', color: colors.black80, fontSize: fontSize.milli }}
                >
                    {L('end_date') + ' ' + dayjs(alert?.publish_to_date).format('YYYY-MM-DD') || ''}
                </Text>
            </Body>
        </Message>
    );
}
