import React from 'react';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../types/ColumnType';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { ProjectDetail } from '@app/api/models/Projects';

dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px'
});

export const COLUMNSPROJECTS: StyleColumn<ProjectDetail>[] = [
    {
        Header: 'Namn',
        accessor: value => value.name,
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center'
        },
        Cell: ({ row }) => {
            const item = row.original;
            return <Span>{item.name}</Span>;
        }
    },
    {
        Header: 'Skapad',
        accessor: value => value.created_at,
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center'
        },
        Cell: ({ row }) => {
            const item = row.original;
            return <Span>{dayjs(item.created_at).format('YYYY-MM-DD')}</Span>;
        }
    }
];

export const COLUMNSMOBILE: StyleColumn<ProjectDetail>[] = [
    {
        Header: 'Namn',
        accessor: project => project.name,
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center'
        },
        Cell: ({ row }) => {
            const item = row.original;
            return <Span>{item.name}</Span>;
        }
    }
];
