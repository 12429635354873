import React from 'react';
import styled from '@emotion/styled';
import { Loading } from '../../../components/Loading/Loading';
import useGetContacts from '../hooks/useGetContacts';
import useTableCheckbox from './TableProvider';
import { COLUMNSCONTACTS } from '../components/ColumnsContact';
import { COLUMNSPROJECTS } from '../components/ColumnsProject';
import TableRowSelectionContacts from '../components/TableRowSelectionContacts';
import TableRowSelectionProjects from './TableRowSelectionProjects';
import Exchange from '../../../components/Icon/themed/Exchange';
import useGetProjects from '../hooks/useGetProjects';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Button } from '@ourliving/ourliving-ui';
import useCopyContactsToProjects from '../hooks/useCopyContactsToProjects';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import { L } from '../../../lib/i18n';

//#region CSS

const GridCopyContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(250px, 1fr) )',
    gap: '1rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        gridTemplateColumns: '1fr 3rem 1fr',
        gap: '2rem'
    },
    alignItems: 'center'
});

const StyledExchange = styled(Exchange)({
    justifySelf: 'center'
});

const StyledCardWithDivider = styled(CardWithDivider)({
    alignSelf: 'start'
});

const ButtonContainer = styled.div({
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1rem'
});

//#endregion

export const ProjectsContactsListPage = () => {
    const { data: contacts, status: contactStatus } = useGetContacts();
    const { data: projects, status: projectStatus } = useGetProjects();
    const { mutate: copyContactsToProjects } = useCopyContactsToProjects();
    const projectsMemo = React.useMemo(() => (projects ? projects : []), [projects]);
    const contactsMemo = React.useMemo(() => (contacts?.items ? contacts.items : []), [contacts]);
    const useTableContacts = useTableCheckbox({ data: contactsMemo, columns: COLUMNSCONTACTS });
    const useTableProjects = useTableCheckbox({ data: projectsMemo, columns: COLUMNSPROJECTS });

    const selectedDataContacts = useTableContacts.selectedFlatRows.map(data => {
        return data.original.id;
    });
    const selectedDataProjects = useTableProjects.selectedFlatRows.map(data => {
        return data.original;
    });

    const copyContactsToProject = () => {
        copyContactsToProjects({ contacts: selectedDataContacts, projects: selectedDataProjects });
        useTableContacts.toggleAllRowsSelected(false);
        useTableProjects.toggleAllRowsSelected(false);
    };

    if (contactStatus === 'success' && projectStatus === 'success') {
        return (
            <>
                <ButtonContainer>
                    {selectedDataContacts.length > 0 && selectedDataProjects.length > 0 ? (
                        <Button onClick={() => copyContactsToProject()}>{L('copy_contacts')}</Button>
                    ) : (
                        <Button disabled={true}>{L('copy_contacts')}</Button>
                    )}
                </ButtonContainer>

                <GridCopyContainer>
                    <StyledCardWithDivider
                        mainArea={<TableRowSelectionContacts useTable={useTableContacts} contacts={contacts.items} />}
                    />
                    <StyledExchange />

                    <StyledCardWithDivider
                        mainArea={<TableRowSelectionProjects useTable={useTableProjects} projects={projects} />}
                    />
                </GridCopyContainer>
            </>
        );
    }

    if (contactStatus === 'loading' || projectStatus === 'loading') {
        return <Loading />;
    }

    return <div>Error</div>;
};
