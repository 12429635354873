import * as React from 'react';
import { Outlet, Route, Routes, matchPath, useLocation, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import PropertyTemplatesPage from './pages/PropertyTemplatesPage';
import AddPropertyTemplatePage from './pages/AddPropertyTemplatePage';
import PropertyTemplateDetailPage from './pages/PropertyTemplateDetailPage';
import PropertyProductsTemplatePage from './pages/PropertyProductsTemplatePage';
import PropertySpacesTemplatePage from './pages/PropertySpacesTemplatePage';
import PropertyDocumentsTemplatePage from './pages/PropertyDocumentsTemplatePage';
import AddPropertyProductTemplatePage from './pages/AddPropertyProductTemplatePage';
import AddPropertyTemplateSpace from './pages/AddPropertyTemplateSpace';
import PropertySpaceTemplateDetailPage from './pages/PropertySpaceTemplateDetailPage';
import PropertyContactsTemplatePage from './pages/PropertyContactsTemplatePage';
import PropertyTemplateContactDetailPage from './pages/PropertyTemplateContactDetailPage';
import EditPropertyProductTemplatePage from './pages/EditPropertyProductTemplatePage';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { useGetPropertyTemplateById } from './hooks/propertyTemplateQueries';
import { Header } from '../../components/Layout/Header';
import PackageProductsTemplatePage from './pages/PackageProductsTemplatePage';
import TodoTemplateListPage from './pages/TodoPages/TodoTemplateListPage';
import TodoTemplateNewPage from './pages/TodoPages/TodoTemplateNewPage';
import TodoTemplateDetailPage from './pages/TodoPages/TodoTemplateDetailPage';
import AddNewProductToTemplatePackage from './pages/AddNewProductToPackage';
import BigTabMenu from '../../components/BigTabItem/BigTabMenu';

const PropertyMenu = () => {
    const location = useLocation();
    const match = matchPath({ path: '/property-templates/:id/*' }, location.pathname);

    if (match?.params['*']?.includes('/'))
        return (
            <>
                <Outlet />
            </>
        );
    return (
        <BigTabMenu>
            <BigTabNav>
                <BigNavItem end to="">
                    {L('information')}
                </BigNavItem>
                <BigNavItem to={`spaces`}>{L('spaces')}</BigNavItem>
                <BigNavItem to={`products`}>{L('products')}</BigNavItem>
                <BigNavItem to={`document`}>{L('document')}</BigNavItem>
                <BigNavItem to={`todos`}>{L('todos')}</BigNavItem>
                <BigNavItem to={`contacts`}>{L('contacts')}</BigNavItem>
            </BigTabNav>
        </BigTabMenu>
    );
};

const Title = () => {
    const PropertyTitle = ({ propertyId }: { propertyId: string }) => {
        const { data, isLoading } = useGetPropertyTemplateById(+propertyId);
        if (isLoading) return <></>;
        return data?.name ? <Header title={data.name} /> : <Header title={L('property_template')} />;
    };
    const { id } = useParams<{
        id: string;
    }>();
    if (id)
        return (
            <>
                <Outlet />
                <PropertyTitle propertyId={id} />
            </>
        );
    return (
        <>
            <Outlet />
            <Header title={L('property_templates')} />
        </>
    );
};

const PropertyTemplateName = () => {
    const { id } = useParams<{ id: string }>();
    const Name = ({ id }: { id: number }) => {
        const { data: property, isLoading } = useGetPropertyTemplateById(id);
        if (isLoading) return <></>;
        return property?.name ? <>{property.name}</> : <>{L('property')}</>;
    };

    return id ? <Name id={+id} /> : <>{L('property')}</>;
};

const ChosenPropertyBreadcrumbs = () => {
    return (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item key={1} to={'../..'}>
                {L('property_templates')}
            </Breadcrumbs.Item>
            <Breadcrumbs.ItemActive key={2}>
                <PropertyTemplateName />
            </Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );
};

const PropertiesBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
    return (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item key={1} to="../../..">
                {L('property_templates')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item key={2} to="..">
                <PropertyTemplateName />
            </Breadcrumbs.Item>
            {children}
        </Breadcrumbs>
    );
};

const BreadcrumbsNavigation = () => {
    return (
        <Routes>
            <Route path="property-templates" element={<Title />}>
                <Route index element={null} />
                <Route path="new">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('property_template')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{L('new_property_template')}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                </Route>
                <Route path=":id">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('property_templates')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{<PropertyTemplateName />}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                    <Route path="spaces">
                        <Route index element={<ChosenPropertyBreadcrumbs />} />
                        <Route
                            path="*"
                            element={
                                <PropertiesBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{L('spaces')}</Breadcrumbs.ItemActive>
                                </PropertiesBreadcrumbs>
                            }
                        />
                    </Route>
                    <Route path="products">
                        <Route index element={<ChosenPropertyBreadcrumbs />} />
                        <Route path=":productId">
                            <Route
                                index
                                element={
                                    <PropertiesBreadcrumbs>
                                        <Breadcrumbs.ItemActive>{L('products')}</Breadcrumbs.ItemActive>
                                    </PropertiesBreadcrumbs>
                                }
                            />
                        </Route>
                    </Route>
                    <Route path="contacts">
                        <Route index element={<ChosenPropertyBreadcrumbs />} />
                        <Route
                            path="*"
                            element={
                                <PropertiesBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{L('contacts')}</Breadcrumbs.ItemActive>
                                </PropertiesBreadcrumbs>
                            }
                        />
                    </Route>
                    <Route path="document">
                        <Route index element={<ChosenPropertyBreadcrumbs />} />
                        <Route
                            path="*"
                            element={
                                <PropertiesBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{L('documents')}</Breadcrumbs.ItemActive>
                                </PropertiesBreadcrumbs>
                            }
                        />
                    </Route>
                    <Route path="todos">
                        <Route index element={<ChosenPropertyBreadcrumbs />} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

const Navigation = () => {
    return (
        <>
            <Routes>
                <Route path="property-templates">
                    <Route index element={null} />
                    <Route path="new" element={null} />
                    <Route path=":id/*" element={<PropertyMenu />}>
                        <Route path="products">
                            <Route index element={null} />
                            <Route path="new" element={null} />
                            <Route path=":productId">
                                <Route
                                    index
                                    element={
                                        <BigTabMenu>
                                            <BigTabNav>
                                                <BigNavItem end to=".">
                                                    {L('edit_product')}
                                                </BigNavItem>
                                                <BigNavItem end to="package">
                                                    {L('content_of_packages')}
                                                </BigNavItem>
                                            </BigTabNav>
                                        </BigTabMenu>
                                    }
                                />
                                <Route path="package">
                                    <Route index element={null} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="spaces">
                            <Route index element={null} />
                            <Route path="new" element={null} />
                            <Route path=":spaceId" element={null} />
                        </Route>
                        <Route path="contacts">
                            <Route index element={null} />
                            <Route path=":contactId" element={null} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};

export const TemplatesPage = () => {
    return (
        <>
            <BreadcrumbsNavigation />
            <Navigation />
            <Routes>
                <Route path="property-templates">
                    <Route index element={<PropertyTemplatesPage />} />
                    <Route path="new" element={<AddPropertyTemplatePage />} />
                    <Route path=":id">
                        <Route index element={<PropertyTemplateDetailPage />} />
                        <Route path="products">
                            <Route index element={<PropertyProductsTemplatePage />} />
                            <Route path="new" element={<AddPropertyProductTemplatePage />} />
                            <Route path=":productId">
                                <Route index element={<EditPropertyProductTemplatePage />} />
                                <Route path="package">
                                    <Route index element={<PackageProductsTemplatePage />} />
                                    <Route path="new">
                                        <Route index element={<AddNewProductToTemplatePackage />} />
                                    </Route>
                                    <Route path=":packageItemId">
                                        <Route index element={<EditPropertyProductTemplatePage />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="spaces">
                            <Route index element={<PropertySpacesTemplatePage />} />
                            <Route path="new" element={<AddPropertyTemplateSpace />} />
                            <Route path=":spaceId" element={<PropertySpaceTemplateDetailPage />} />
                        </Route>
                        <Route path="document">
                            <Route index element={<PropertyDocumentsTemplatePage />} />
                        </Route>
                        <Route path="contacts">
                            <Route index element={<PropertyContactsTemplatePage />} />
                            <Route path=":contactId" element={<PropertyTemplateContactDetailPage />} />
                        </Route>
                        <Route path="todos">
                            <Route index element={<TodoTemplateListPage />} />
                            <Route path="new" element={<TodoTemplateNewPage />} />
                            <Route path=":todoId" element={<TodoTemplateDetailPage />} />
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
