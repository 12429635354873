import styled from '@emotion/styled';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = {
    breadcrumbs: {
        name?: string;
        to: NavLinkProps['to'];
    }[];
};

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    fontSize: '16px',
    color: '#9DA3B4',
    fontWeight: 400,
    cursor: 'pointer'
});

const Breadcrumb = ({ breadcrumbs }: Props) => {
    const NavLinks = breadcrumbs.map((breadcrumb, i) => (
        <StyledNavLink key={i} to={breadcrumb.to}>
            {breadcrumb.name}
        </StyledNavLink>
    ));
    return <div>{NavLinks}</div>;
};

export default Breadcrumb;
