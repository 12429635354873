import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoDetail from '../../../binders/pages/TodoPages/TodoDetail';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetPropertyName from '../../../binders/useGetPropertyName';
import { L } from '../../../../lib/i18n';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';
import { useGetTodoDetail } from '../../../binders/pages/TodoPages/hooks/todoQueries';

const TodoTemplateDetailPage = () => {
    const { todoId, id: propertyId } = useParams<{ todoId: string; id: string }>();

    return todoId && propertyId ? <Page todoId={+todoId} propertyId={propertyId} /> : null;
};

const Page = ({ propertyId, todoId }: { todoId: number; propertyId: string }) => {
    const todoQuery = useGetTodoDetail({ todoId, propertyId: +propertyId });
    const propertyName = useGetPropertyName(propertyId);

    if (todoQuery.status === 'loading') {
        return <Loading />;
    }
    return todoId && propertyId ? (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../..'}>{L(L('property_templates'))}</Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{todoQuery?.data?.name ?? L('edit_todo')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{todoQuery.data?.name ?? L('todo')}</H1>
            <TodoDetail todoId={+todoId} propertyId={+propertyId} />
        </>
    ) : null;
};

export default TodoTemplateDetailPage;
