import { observable } from 'mobx';
import AddonsApi, { Supplier, SupplierFilter } from '@app/api/public/AddonsApi';
import { AddonType, Property } from '@app/api/models/Addons';
import { AddonFilterProduct, AddonFilterProductGroup, RptProduct } from '@app/api/models/Orders';
import PropertiesApi, { Space } from '@app/api/public/PropertiesApi';
import ProjectsApi from '@app/api/public/ProjectsApi';
class AddonReportStoreClass {
    @observable filter: SupplierFilter = {};
    @observable projectId?: number;
    @observable propertyIds: number[];
    @observable properties: Property[];
    @observable supplier: RptProduct[];
    @observable html: any;
    @observable configure: Supplier[];
    @observable spaces: Space[];
    @observable products: AddonFilterProduct[];
    @observable productGroups: AddonFilterProductGroup[];
    @observable isWaitingCost: boolean;
    @observable isWaitingSupplier: boolean;
    @observable isShowingFilters: boolean;
    @observable isWaitingFilters: boolean;
    @observable isIncludingImage: boolean;
    @observable fields: Set<string> = new Set();
    @observable addon_type: AddonType[] = ['standard', 'optional'];
    @observable project_name?: string;
    @observable tab = 'supplier';

    async loadAddonSuppliers(projectId: number, filter: SupplierFilter) {
        this.projectId = projectId;
        this.getProjectById();
        this.properties = await AddonsApi.getPropertyList(projectId);
        this.propertyIds = this.properties.map(r => r.id);
        this.supplier = await AddonsApi.rptProducts(this.propertyIds, filter);
        if (filter?.property?.length) {
            this.spaces = await PropertiesApi.listSpaceNames(filter?.property);
            this.products = await AddonsApi.getProductList(filter?.property);
            this.productGroups = await AddonsApi.getProductGroupList(filter?.property);
        } else {
            this.spaces = await PropertiesApi.listSpaceNames([...this.propertyIds]);
            this.products = await AddonsApi.getProductList([...this.propertyIds]);
            this.productGroups = await AddonsApi.getProductGroupList([...this.propertyIds]);
        }
    }

    async trySupplierPDF(projectId: string, filter: SupplierFilter) {
        this.isWaitingSupplier = true;
        this.fields.clear();
        this.fields
            .add('product')
            .add('brand')
            .add('supplier')
            .add('profession');
        this.isIncludingImage ? this.fields.add('image') : this.fields.add('function');
        try {
            return await AddonsApi.renderSupplierReportPdf(
                filter?.property ? [...filter?.property] : [...this.propertyIds],
                projectId,
                [...this.fields],
                filter
            );
        } catch (e) {
            console.log(e);
        } finally {
            this.isWaitingSupplier = false;
        }
    }

    async startPrintToPdf() {
        this.fields.clear();
        this.isIncludingImage ? this.fields.add('image') : this.fields.add('function');
        if (this.tab === 'supplier') {
            this.fields
                .add('product')
                .add('brand')
                .add('supplier')
                .add('profession');
        }
        if (this.tab === 'prices') {
            this.fields.add('product').add('price');
        }
    }

    async getProjectById() {
        if (!this.projectId) return;
        const r = await ProjectsApi.getById(this.projectId);
        this.project_name = r?.name;
    }

    async tryCostPDF(projectId: string, filter: SupplierFilter) {
        this.isWaitingCost = true;
        this.fields.clear();
        this.fields.add('product').add('price');
        this.isIncludingImage ? this.fields.add('image') : this.fields.add('function');
        try {
            return await AddonsApi.renderSupplierReportPdf(
                filter?.property ? filter?.property : [...this.propertyIds],
                projectId,
                [...this.fields],
                filter
            );
        } catch (e) {
            console.log(e);
        } finally {
            this.isWaitingCost = false;
        }
    }

    async getPdf() {
        return null;
    }
}

export const AddonReportStore = new AddonReportStoreClass();
