import React from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import { useGetTodoDetail } from '../hooks/accountTodoQueries';
import { useUpdateTodo } from '../hooks/accountTodoMutations';
import toast from 'react-hot-toast';
import TodoForm from './TodoForm';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { Header } from '../../../components/Layout/Header';

const TodoDetail = ({ todoId }: { todoId: number }) => {
    const todoQuery = useGetTodoDetail({ todoId });
    const { mutate: updateTodo } = useUpdateTodo();

    if (todoQuery.data) {
        const data = todoQuery.data;
        return (
            <>
                <Breadcrumbs style={{ paddingBottom: '1rem' }}>
                    <Breadcrumbs.Item to={'..'}>{L('todos')}</Breadcrumbs.Item>
                    <Breadcrumbs.ItemActive>{data.name}</Breadcrumbs.ItemActive>
                </Breadcrumbs>
                <Header title={L('todos')} />
                <CardWithDivider
                    style={{ maxWidth: '800px' }}
                    topArea={<CardTitle>{L('edit_todo')}</CardTitle>}
                    mainArea={
                        <TodoForm
                            formValues={{
                                name: data.name,
                                description: data.description ?? undefined,
                                tips: data.tips ?? undefined,
                                recurrency: data.recurrency ?? undefined,
                                template_for_type: data.template_for_type ?? undefined,
                                template_for_subtype: data.template_for_subtype ?? undefined,
                                due_at: data.due_at || '',
                            }}
                            onSubmit={(formValues) =>
                                updateTodo(
                                    {
                                        ...formValues,
                                        todoId,
                                    },
                                    {
                                        onSuccess: (data) => {
                                            toast.success(`${data.name} ${L('updated')}`);
                                        },
                                    },
                                )
                            }
                        />
                    }
                />
            </>
        );
    }

    return (
        <>
            <Breadcrumbs style={{ paddingBottom: '1rem' }}>
                <Breadcrumbs.Item to={'..'}>{L('todos')}</Breadcrumbs.Item>
            </Breadcrumbs>
            {todoQuery.status === 'error' && <div>{L('error')}</div>}
            {todoQuery.status === 'loading' && <Loading />}
        </>
    );
};

export default TodoDetail;
