import React from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import { style } from '../../../components/Shared/Style';
import { Card } from '../../../components/Layout/Card';
import { L } from '../../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../components/Loading/Loading';
import ProfessionForm from './components/ProfessionForm';
import useEditProfession from './hooks/useEditProfession';
import useDeleteProfession from './hooks/useDeleteProfession';
import { useGetProfessionById } from './hooks/professionQueries';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';

const Content = styled.div({
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.l,
    paddingBottom: style.margin.xs,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});

const ButtonContainer = styled.div({
    width: 'auto',
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'flex-end',
});

const RestyledCard = styled(Card)({
    [`@media screen and (min-width: 1200px)`]: {
        width: '50%',
    },
    width: '100%',
});

export const ProfessionDetailPage = () => {
    const { id } = useParams<{
        id: string;
    }>();

    return id ? <ProfessionDetail id={+id} /> : null;
};

const ProfessionDetail = ({ id }: { id: number }) => {
    const { data: profession, isLoading } = useGetProfessionById({ id: id, keepPreviousData: true });
    const navigate = useNavigate();

    const { mutate } = useEditProfession();
    const onSubmit = (profession: { name: string }) =>
        mutate(
            { profession: { ...profession, id: id } },
            {
                onSuccess: () => {
                    toast.success(`${L('profession')} ${profession.name} ${L('updated').toLocaleLowerCase()}`);
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) {
                        if (err.message.split(' ')[0] === '23505') {
                            toast.error(L('unique_name_error'));
                            return;
                        }
                        toast.error(L('pg_unkown'));
                    }
                },
            },
        );

    const { mutate: remove } = useDeleteProfession();

    if (isLoading) return <Loading />;

    return (
        <>
            <Content>
                <Card padding="0" width="49%">
                    {L('contact')}
                </Card>
            </Content>
            <Content>
                <RestyledCard background={style.colors.white}>
                    <ProfessionForm profession={profession} onSubmit={onSubmit}>
                        <ButtonContainer>
                            <ConfirmationButton
                                style={{ marginRight: style.margin.s }}
                                props={{
                                    shouldPreventDefault: true,
                                    onConfirm: () => {
                                        if (!profession?.id) {
                                            return;
                                        }
                                        remove(
                                            {
                                                id: +profession.id,
                                            },
                                            {
                                                onSuccess: () => {
                                                    toast.success(`${profession.name} ${L('removed').toLowerCase()}`);
                                                    navigate('..');
                                                },
                                                onError: (err) => {
                                                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                                                },
                                            },
                                        );
                                    },
                                }}
                            />

                            <Button type="submit" style={{ marginRight: '0px' }}>
                                {L('save')}
                            </Button>
                        </ButtonContainer>
                    </ProfessionForm>
                </RestyledCard>
            </Content>
        </>
    );
};
