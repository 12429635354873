import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentNewsQueryKeys } from './contentNewsQueries';
import { uploadToAws } from '../ContentInformation/uploadToAws';

type News = {
    title: string;
    description: string;
};

export default function useAddContentNews() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ file, news, projectId }: { news: News; file?: File | null; projectId: number }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;

            const { preSingedUrl, id } = await trpc.ContentNewsApi.addContentNews.mutate({
                news: news,
                image: image,
                projectId: projectId,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentNewsApi.updateContentNews.mutate({
                            id,
                            projectId: projectId,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentNewsQueryKeys.lists());
            },
        },
    );
}
