import styled from '@emotion/styled';
import { Root } from '@radix-ui/react-separator';
import { colors } from '../Shared/Style/colors';

const StyledSeparator = styled(Root)(({ orientation = 'horizontal' }: { orientation: 'vertical' | 'horizontal' }) => ({
    height: orientation === 'horizontal' ? '1px' : undefined,
    width: orientation === 'horizontal' ? '100%' : '1px',
    backgroundColor: colors.secondary,
}));

// Exports
export const Separator = StyledSeparator;
