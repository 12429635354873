import { Property } from '@app/trpc-api/models/Binders';
import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';

const deleteProperties = async (properties: Property[]) => {
    const removeProperty = properties.map(async (property) => {
        return await trpc.BindersApi.deletePropertyTemplateOnProject.query({ propertyId: property.id });
    });

    const deleteProperty = await Promise.all(removeProperty);

    return deleteProperty;
};

type Variables = {
    properties: Property[];
    projectId: number;
};

const useDeleteTemplateProperties = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (variables: Variables) => {
            await deleteProperties(variables.properties);
        },
        {
            onSettled: (_err, _data, vars) => {
                queryClient.invalidateQueries(getPropertiesKeys.list({ projectId: vars.projectId }));
            },
        },
    );
};

export default useDeleteTemplateProperties;
