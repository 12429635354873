import { useMutation, useQueryClient } from 'react-query';
import { getTemplatePropSpaceKeys } from './propertyTemplateQueries';
import { trpc } from '../../../lib/trpc';
import { AddSpace } from '../../binders/schemas';

const useSaveSpaceOnPropertyTemplate = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ data, propertyId }: { data: AddSpace; propertyId: number }) => {
            const { name, space_type, ...attr } = data;
            const spaceData = { name, space_type, attr };
            return trpc.TemplatesApi.saveSpace.query({ space: spaceData, propertyId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getTemplatePropSpaceKeys.lists());
            },
        },
    );
};

export default useSaveSpaceOnPropertyTemplate;
