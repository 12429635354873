import { AnalyticCostByYear } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';
import React from 'react';
import useGetAnaltyicList from '../hooks/useGetAnalyticList';
import PieGraph from './PieGraph';

const PieGraphCostYear = () => {
    const { data: analtyicList } = useGetAnaltyicList();

    if (!analtyicList) return null;
    if (!analtyicList.length) {
        return (
            <PieGraph
                headerPrice={'Kostnad per år'}
                headerPie={'Kostnad per kategori'}
                price={'Inga planerade underhåll'}
            />
        );
    }

    const uniqueYears = [...new Set(analtyicList.map(item => item.year)).values()];
    const maxYear = Math.max(...uniqueYears);
    const yearSpan = maxYear - +dayjs().format('YYYY');

    const uniqueCategories = [...new Set(analtyicList.map(item => item.maintenance_category.name)).values()];
    const avrageCost =
        analtyicList
            .map(analyticCost =>
                analyticCost.completed_amount && +analyticCost.completed_amount
                    ? +analyticCost.completed_amount
                    : +analyticCost.planned_amount
            )
            .reduce((sum, current) => sum + current) / yearSpan;

    const Category = class {
        constructor(categories: AnalyticCostByYear[], years: number[]) {
            this.costs = categories.map(catagory => {
                return catagory.completed_amount && +catagory.completed_amount
                    ? +catagory.completed_amount
                    : +catagory.planned_amount;
            });
            this.name = categories[0].maintenance_category.name;
            this.color = categories[0].maintenance_category.color;
            this.years = years;
        }
        costs: number[];
        name: string;
        color: string;
        years: number[];

        avrageCost() {
            const maxYear = Math.max(...this.years);
            const yearSpan = maxYear - +dayjs().format('YYYY');
            return Math.round(this.costs.reduce((sum, current) => sum + current) / yearSpan);
        }
    };

    const categories = uniqueCategories.map(categoryName => {
        const CategoryClass = new Category(
            analtyicList.filter(item => item.maintenance_category.name === categoryName),
            uniqueYears
        );
        return CategoryClass;
    });

    const data = {
        labels: categories.map(category => category.name),
        datasets: [
            {
                label: 'kostnad per år',
                data: categories.map(category => category.avrageCost()),
                backgroundColor: categories.map(category => category.color)
            }
        ]
    };

    return (
        <PieGraph
            data={data}
            headerPrice={'Kostnad per år'}
            headerPie={'Kostnad per kategori'}
            price={new Intl.NumberFormat('sv-SE', {
                style: 'currency',
                currency: 'SEK',
                maximumFractionDigits: 0
            }).format(avrageCost)}
        />
    );
};

export default PieGraphCostYear;
