import React, { useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { Button } from '@ourliving/ourliving-ui';
import CardTitle from '../../../../components/typography/CardTitle';
import ContentNewsList from '../../components/ContentNews/ContentNewsList';
import { NavLink } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';

const ContentNewsPage = () => {
    const [numberOfItems, setNumberOfItems] = useState<number | null>(null);

    return (
        <CardWithDivider
            topArea={
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <CardTitle>
                        {L('news')} {numberOfItems !== null ? `(${numberOfItems})` : ''}
                    </CardTitle>
                    <NavLink to={'new'}>
                        <Button>{L('new_content_news')}</Button>
                    </NavLink>
                </div>
            }
            mainArea={<ContentNewsList handleSetNumberOfItems={(val) => setNumberOfItems(val)} />}
        />
    );
};

export default ContentNewsPage;
