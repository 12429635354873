import TicketsApi, { TicketFilter } from '@app/api/public/TicketsApi';
import { SortingState } from '@tanstack/react-table';
import { useMutation } from 'react-query';

const renderCsv = async ({ sorting, searchQuery, filter }: Variables) => {
    const r = await TicketsApi.getTicketListCsv(searchQuery, filter, sorting);
    return r;
};

type Variables = {
    sorting: SortingState;
    searchQuery: string;
    filter: TicketFilter;
};

const useRenderCsv = () => {
    return useMutation(renderCsv);
};

export default useRenderCsv;
