import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { useGetLibraries } from '../../hooks/libraryQueries';
import AddProductFromTemplateComponent from './AddProductFromTemplateComponent';
import useImportTemplateProduct from '../../hooks/CopyFunctionHooks/useImportTemplateProduct';
import { useGetPropSpaces } from '../../hooks/propertyHooks/propertyQueries';

const AddProductFromTemplate = () => {
    const { id: projectId, propertyId, spaceId } = useParams<{ id: string; propertyId: string; spaceId?: string }>();
    const { data: libraries } = useGetLibraries();
    const { mutate: importTemplateProduct } = useImportTemplateProduct();
    const { data: spaces } = useGetPropSpaces(+propertyId);
    const handleImportTemplateProduct = ({
        products,
        propertyId,
        destinationId,
    }: {
        propertyId: number;
        products: {
            id: number;
            package_type: string | null;
        }[];
        destinationId: number;
    }) => {
        importTemplateProduct({
            propertyId: +propertyId,
            products: products,
            spaceId: +destinationId,
        });
    };
    return projectId && propertyId && libraries ? (
        <AddProductFromTemplateComponent
            propertyId={+propertyId}
            spaceId={spaceId}
            libraries={libraries}
            handleImportToPropertyProduct={handleImportTemplateProduct}
            spaces={spaces}
        />
    ) : null;
};

export default AddProductFromTemplate;
