import { useMutation, useQueryClient } from 'react-query';
import { todoQueryKeys } from './todoQueries';
import { trpc } from '../../../../../lib/trpc';
import toast from 'react-hot-toast';
import { L } from '../../../../../lib/i18n';

type Input = {
    description?: string;
    name: string;
    due_at?: string;
    propertyId: number;
    template_for_type?: string;
    template_for_subtype?: string;
    template?: boolean;
    tips?: string;
    recurrency?: number;
};
const useCreateTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ ...input }: Input & { propertyId: number }) => {
            return trpc.TodoApi.createNewTodo.mutate({
                ...input,
            });
        },

        onSettled: () => {
            queryClient.invalidateQueries(todoQueryKeys.lists());
        },
    });
};

const useUpdateTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ todoId, ...input }: Input & { propertyId: number; todoId: number }) => {
            return trpc.TodoApi.updateTodo.mutate({
                ...input,
                id: todoId,
            });
        },
        onSettled: (_data, _error, input) => {
            queryClient.invalidateQueries(todoQueryKeys.lists());
            queryClient.invalidateQueries(todoQueryKeys.detail({ propertyId: input.propertyId, id: input.todoId }));
        },
    });
};

const useRemoveTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ todoId, propertyId }: { propertyId: number; todoId: number }) => {
            return trpc.TodoApi.removeTodo.mutate({
                id: todoId,
                propertyId,
            });
        },
        onSuccess: () => {
            toast.success(L('todo_removed'));
        },
        onError: () => {
            toast.success(L('todo_remove_error'));
        },
        onSettled: (_data, _error, input) => {
            queryClient.invalidateQueries(todoQueryKeys.lists());
            queryClient.invalidateQueries(todoQueryKeys.detail({ propertyId: input.propertyId, id: input.todoId }));
        },
    });
};

export { useCreateTodo, useUpdateTodo, useRemoveTodo };
