import { TicketSettingType } from '@app/api/models/Tickets';
import TicketsApi, { TicketFilter } from '@app/api/public/TicketsApi';
import { ticketQueryKeys, MessageType } from '@app/shared/queryKeys/tickets';
import { useInfiniteQuery, useQuery, UseQueryOptions } from 'react-query';
import { SortingState } from '@tanstack/react-table';

const getById = (id?: string) => {
    if (!id) return;
    return TicketsApi.getbyid(id);
};

const useGetTicket = (id?: string) => {
    return useQuery(ticketQueryKeys.id(id), () => getById(id), {
        enabled: !!id,
    });
};

const getTicketArchive = (filter?: TicketFilter) => {
    return TicketsApi.getArchive(filter);
};

const getTicketArchivePaginated = ({
    sorting,
    currentPage,
    filter,
    search,
}: {
    sorting: SortingState;
    currentPage: number;
    filter?: TicketFilter;
    search?: string;
}) => {
    return TicketsApi.getArchivePaginated({ sorting, currentPage, filter, search });
};

const useGetTicketArchive = (filter?: TicketFilter) => {
    return useQuery(ticketQueryKeys.archive(filter), () => getTicketArchive(filter));
};

const useGetInfiniteTicketsArchive = ({
    keepPreviousData,
    filter,
    searchQuery,
    sorting,
}: {
    keepPreviousData?: UseQueryOptions['keepPreviousData'];
    filter?: TicketFilter;
    searchQuery: string;
    sorting: SortingState;
}) => {
    return useInfiniteQuery(
        ticketQueryKeys.listArchiveInfinite(sorting, filter, searchQuery),
        ({ pageParam = 1 }) =>
            getTicketArchivePaginated({ currentPage: pageParam, filter, search: searchQuery, sorting }),
        {
            keepPreviousData,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
};

const useGetInfiniteTickets = ({
    filter,
    keepPreviousData,
    searchQuery,
    sorting,
}: {
    filter?: TicketFilter;
    keepPreviousData?: UseQueryOptions['keepPreviousData'];
    searchQuery?: string;
    sorting: SortingState;
}) => {
    return useInfiniteQuery(
        ticketQueryKeys.listInfinite(sorting, filter, searchQuery),
        ({ pageParam = 1 }) => TicketsApi.getInfiniteList({ currentPage: pageParam, searchQuery, filter, sorting }),
        {
            keepPreviousData,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
};

const useGetTicketCsv = ({
    filter,
    searchQuery,
    sorting,
}: {
    filter?: TicketFilter;
    searchQuery?: string;
    sorting: SortingState;
}) => {
    return TicketsApi.getTicketListCsv({ sorting, searchQuery, filter });
};

const getSpacesForProperty = async (id?: string) => {
    if (!id) return;
    return TicketsApi.getSpacesForProperty(+id);
};

const useGetSpacesForProperty = (id?: string) => {
    return useQuery(ticketQueryKeys.spaces(id), () => getSpacesForProperty(id), {
        enabled: !!id,
    });
};

const getInventoriesForProperty = async (id?: string) => {
    if (!id) return;
    return TicketsApi.getInventories(id);
};

const useGetInventoriesForSpace = (id?: string) => {
    return useQuery(ticketQueryKeys.inventories(id), () => getInventoriesForProperty(id), {
        enabled: !!id,
    });
};

const getNotes = async (ticketId?: string) => {
    if (!ticketId) return;
    return TicketsApi.getNotes(ticketId);
};

const useGetTicketNotes = (ticketId?: string) => {
    return useQuery(ticketQueryKeys.notes(ticketId), () => getNotes(ticketId), {
        enabled: !!ticketId,
    });
};
const useGetTicketMessages = (messageType: MessageType = 'residence', ticketId?: string) => {
    return useQuery(
        ticketQueryKeys.messages(messageType, ticketId),
        () => {
            if (!ticketId) return;
            return TicketsApi.getMessages(+ticketId, messageType);
        },
        {
            enabled: !!ticketId,
        },
    );
};

const useGetTicketDashboard = () => {
    return useQuery(ticketQueryKeys.dashboard(), () => TicketsApi.getDashboardInfo());
};

const useGetTicketLog = (ticketId?: string) => {
    return useQuery(
        ticketQueryKeys.log(ticketId),
        () => {
            if (!ticketId) return;
            return TicketsApi.getEvents(ticketId);
        },
        {
            enabled: !!ticketId,
        },
    );
};

const getSettingsByType = (type: TicketSettingType) => {
    return TicketsApi.getSettingsByType(type);
};

const useGetSettingsByType = (type: TicketSettingType) => {
    return useQuery(ticketQueryKeys[type](), () => getSettingsByType(type), {
        enabled: !!type,
    });
};

const useGetAssignees = () => {
    return useQuery(ticketQueryKeys.assignees(), () => TicketsApi.getAssignees());
};

const useGetTicketFilters = () => {
    return useQuery(ticketQueryKeys.filters(), () => TicketsApi.listTicketFilters());
};

export {
    useGetInfiniteTickets,
    useGetInfiniteTicketsArchive,
    useGetTicket,
    useGetSpacesForProperty,
    useGetInventoriesForSpace,
    useGetTicketArchive,
    useGetTicketMessages,
    useGetTicketNotes,
    useGetTicketLog,
    useGetSettingsByType,
    useGetAssignees,
    useGetTicketDashboard,
    useGetTicketFilters,
    useGetTicketCsv,
};
