import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdatePropertyTemplateDocName = () => {
    return useMutation(async (params: { docId: number; name: string; propertyId: number }) => {
        return trpc.TemplatesApi.updatePropertyTemplateDocumentName.mutate(params);
    });
};

export default useUpdatePropertyTemplateDocName;
