import { SortingState } from '@tanstack/react-table';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProductsKeys } from '../../../binders/hooks/productQueries';
import { AddAccountProduct } from '../../../binders/schemas';

export const getPropertyTemplatesKeys = {
    all: ['templates-inventory'] as const,
    lists: () => [...getPropertyTemplatesKeys.all, 'list'] as const,
    listInfinite: (params: { sorting: SortingState; search: string }) =>
        [...getPropertyTemplatesKeys.lists(), params] as const,
};

export function useGetInfiniteInventoryTemplates({ sorting, search }: { sorting: SortingState; search: string }) {
    return useInfiniteQuery(
        [...getPropertyTemplatesKeys.listInfinite({ sorting, search })],
        async ({ pageParam = 1 }) =>
            trpc.TemplatesApi.getInventoryTemplates.query({ page: pageParam, sorting, search }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useAddProduct() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddAccountProduct }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.BindersApi.saveInventoryMaterialOnAccount.query(dataToSave);
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(getPropertyTemplatesKeys.lists());
            },
        },
    );
}

export default function useUpdateProduct() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddAccountProduct; productId: number }) => {
            const dataToSave = {
                ...options.inventoryMaterial,
                entity_type: 1,
                parent_id:
                    options.inventoryMaterial.parent_id === null ? undefined : options.inventoryMaterial.parent_id,
            };
            return trpc.BindersApi.updateInventoryMaterialOnAccount.query({
                data: dataToSave,
                entityId: options.productId,
            });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(getProductsKeys.all);
            },
        },
    );
}

export function useGetProduct(productId: number) {
    return useQuery(getProductsKeys.detail({ productId }), () =>
        trpc.TemplatesApi.getProduct.query({ entityId: productId }),
    );
}
