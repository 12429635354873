import styled from '@emotion/styled';
import React from 'react';
import { fontSize } from '../Shared/Style/typography';
import Input from './Input';
import { TextArea } from '@ourliving/ourliving-ui';

const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '0.2rem',
    flexGrow: 1,
});

const Error = styled.span({
    color: 'red',
    fontSize: fontSize.milli,
});

export const InputWithError = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

export const TextAreaWithError = styled(TextArea)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

type Props = {
    children: React.ReactNode;
    errorMsg?: string;
    isMinHeight?: boolean;
    style?: React.CSSProperties;
};

const ErrorAndInputWrapper = ({ children, errorMsg = '', isMinHeight = true, style }: Props) => {
    return (
        <Wrapper style={{ minHeight: isMinHeight ? '52px' : '', ...style }}>
            {children}
            <Error>{errorMsg}</Error>
        </Wrapper>
    );
};

export default ErrorAndInputWrapper;
