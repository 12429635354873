import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../../../project-templates/hooks/ContentInformation/uploadToAws';
import { RouterInput } from '../../../../lib/trpc';

export default function useUploadPropertyDocument() {
    return useMutation(
        async ({
            file,
            propertyId,
            documentType,
            documentSubtype,
            name,
        }: {
            file: File;
            propertyId: number;
            documentType: Required<RouterInput['BindersApi']['uploadPropertyDocument']['documentType']>;
            documentSubtype: RouterInput['BindersApi']['uploadPropertyDocument']['documentSubtype'];
            name: string;
        }) => {
            const fileToSave = {
                name: name ?? file.name,
                size: file.size,
                type: file.type,
            };

            const response = await trpc.BindersApi.uploadPropertyDocument.mutate({
                file: fileToSave,
                propertyId,
                documentType,
                documentSubtype,
            });

            if (response) {
                await uploadToAws({
                    preSingedUrl: response.preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.BindersApi.removePropertyDocument.mutate({
                            id: response.id,
                            propertyId,
                        });
                    },
                });
            }
            return response;
        },
    );
}
