import React from 'react';
import toast from 'react-hot-toast';
import { L } from '../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';

type Args = {
    continueFn: () => void;
    continueButtonText: string;
    abortButtonText?: string;
    title: string;
    id?: string;
};
const triggerDirtyFieldsToast = ({ continueFn, continueButtonText, title, id, abortButtonText }: Args) =>
    toast(
        (t) => (
            <div>
                <p>{L(title)}</p>
                <div style={{ display: 'flex', gap: '1rem', justifyContent: 'space-between' }}>
                    <Button
                        onClick={() => {
                            continueFn();
                            toast.dismiss(t.id);
                        }}
                    >
                        {continueButtonText}
                    </Button>
                    <Button onClick={() => toast.dismiss(t.id)}>{abortButtonText ? abortButtonText : L('no')}</Button>
                </div>
            </div>
        ),
        { duration: Infinity, id },
    );

export default triggerDirtyFieldsToast;
