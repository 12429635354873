import { useQueryClient, useMutation } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { InventoryProduct } from '@app/api/models/Inventory';

const saveProduct = async (product: Partial<InventoryProduct>) => {
    return InventoryApi.saveInventory(product);
};

const useSaveProduct = () => {
    const queryClient = useQueryClient();

    return useMutation((product: Partial<InventoryProduct>) => saveProduct(product), {
        onSettled: () => {
            queryClient.invalidateQueries('getProductsByProperty');
        }
    });
};

export default useSaveProduct;
