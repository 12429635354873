import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateProductDocSort = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.ProductsApi.updateSortBulk.mutate({ docs: params.docs, entityId: params.documentable_id });
    });
};

export default useUpdateProductDocSort;
