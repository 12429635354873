import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProjectTemplatesKeys } from './projectTemplateQueries';

export default function useSortTemplateImages() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ ids, projectId }: { ids: number[]; projectId: number }) => {
            return trpc.TemplatesApi.sortTemplateImages.mutate({
                ids,
                projectId,
            });
        },
        onSettled: (_data, _err, params) => {
            queryClient.invalidateQueries(getProjectTemplatesKeys.imagesByTemplateId(params.projectId));
        },
    });
}
