import styled from '@emotion/styled';
import React from 'react';
import { Row, flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '../../../components/Icon/Icon';

const TRow = styled.div({
    display: 'flex',
    flex: '1 0 auto',
    minWidth: '0px',
});

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
    cursor: 'grab',
});

const DraggableTableRow = <
    T extends {
        id: number;
    },
>({
    row,
    rowClickHandler,
}: {
    row: Row<T>;
    rowClickHandler?: (row: Row<T>) => void;
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: row.original.id,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <TRow
            onClick={() => {
                if (!rowClickHandler) return;
                rowClickHandler(row);
            }}
            style={rowClickHandler ? { cursor: 'pointer', ...style } : { ...style }}
            ref={setNodeRef}
            role="row"
        >
            <div
                role="cell"
                className="td"
                style={{
                    boxSizing: 'border-box',
                    minWidth: '0px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                <div
                    style={{
                        boxSizing: 'border-box',
                        minWidth: '0px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        display: 'flex',
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    {...attributes}
                    {...listeners}
                >
                    <IconWrapper {...attributes} {...listeners}>
                        <Icon.VerticalMoreIcon
                            size="small"
                            style={{
                                justifySelf: 'left',
                                visibility: 'visible',
                            }}
                        />
                        <Icon.VerticalMoreIcon
                            size="small"
                            style={{
                                justifySelf: 'left',
                                visibility: 'visible',
                                marginLeft: '-6px',
                            }}
                        />
                    </IconWrapper>
                </div>
            </div>
            {row.getVisibleCells().map((cell) => {
                return (
                    <div
                        key={cell.id}
                        role="cell"
                        className="td"
                        style={{
                            boxSizing: 'border-box',
                            minWidth: '0px',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            display: 'flex',
                            width: cell.column.getSize(),
                            ...cell.column.columnDef?.meta?.styles,
                        }}
                    >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                );
            })}
        </TRow>
    );
};

export default DraggableTableRow;
