import * as React from 'react';
import { L } from '../../lib/i18n';
import { Route, Routes, useParams } from 'react-router-dom-v5-compat';
import { ContactListPage } from './ListPage/ContactListPage';
import { PropertiesContactsListPage } from './PropertiesContactsCopy/PropertiesContactsListPage';
import { ProjectsContactsListPage } from './ProjectsContactsCopy/ProjectsContactsListPage';
import { ContactDetailPage } from './DetailPage/ContactDetailPage';
import ModalProvider from '../../components/Modal/Context/ModalContext';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { useGetContactById } from './hooks/useGetContacts';
import PageTitle from '../../components/typography/PageTitle';

const Tabs = () => {
    return (
        <>
            <BigNavItem end to={'/contacts'}>
                {L('contacts')}
            </BigNavItem>
            <BigNavItem end to={'/contacts/projects'}>
                {L('copy_to_projects')}
            </BigNavItem>
            <BigNavItem end to={'/contacts/properties'}>
                {L('copy_to_properties')}
            </BigNavItem>
        </>
    );
};

const Name = () => {
    const ContactName = ({ id }: { id: string }) => {
        const { data, isLoading } = useGetContactById(+id);

        if (isLoading) return <></>;
        return data?.name ? <>{data.name}</> : <>{L('property_template')}</>;
    };
    const { id } = useParams<{
        id: string;
    }>();
    if (id)
        return (
            <>
                <ContactName id={id} />
            </>
        );
    return <>{L('property_template')}</>;
};

export const ContactsPage = () => {
    return (
        <>
            <PageTitle>{L('contacts')}</PageTitle>
            <>
                <BigTabNav>
                    <Routes>
                        <Route path="/contacts">
                            <Route index element={<Tabs />} />
                            <Route path="projects" element={<Tabs />} />
                            <Route path="properties" element={<Tabs />} />
                            <Route
                                path=":id"
                                element={
                                    <>
                                        <BigNavItem end to="..">
                                            {L('contacts')}
                                        </BigNavItem>
                                        <div style={{ borderRight: 'solid 1px gray' }} />
                                        <BigNavItem end to=".">
                                            <Name />
                                        </BigNavItem>
                                    </>
                                }
                            />
                        </Route>
                    </Routes>
                </BigTabNav>
                <Routes>
                    <Route path="/contacts">
                        <Route
                            index
                            element={
                                <ModalProvider>
                                    <ContactListPage />
                                </ModalProvider>
                            }
                        />
                        <Route path="projects" element={<ProjectsContactsListPage />} />
                        <Route path="properties" element={<PropertiesContactsListPage />} />
                        <Route path=":id" element={<ContactDetailPage />} />
                    </Route>
                </Routes>
            </>
        </>
    );
};
