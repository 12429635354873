import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { RouterInputs } from '@app/trpc-api';

type UsedProjectTypesParams = RouterInputs['BindersApi']['listUsedProjectTypes'];

export const getProjectTypesKeys = {
    all: ['binders-project-types'] as const,
    lists: () => [...getProjectTypesKeys.all, 'list'] as const,
    listUsed: (params: UsedProjectTypesParams) =>
        [...getProjectTypesKeys.lists(), 'listUsedInAccount', params] as const,
};

export function useGetProjectTypes() {
    return useQuery(getProjectTypesKeys.lists(), () => trpc.BindersApi.listProjectTypes.query());
}

export function useGetUsedProjectTypes(params: UsedProjectTypesParams) {
    return useQuery(getProjectTypesKeys.listUsed(params), () => trpc.BindersApi.listUsedProjectTypes.query(params));
}
