import React from 'react';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { Modal } from '../../../components/Modal/Modal';
import Comment from './Comment';
import NewComment from './NewComment';
import Cross from '../../../components/Icon/e-remove-outline-12.svg';
const ModalHeader = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0.5rem  1rem'
});

const StyledCross = styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    color: '#4B506D66',
    marginRight: '10px'
});

//Currently unused, but will be implemented in the next release
export default function Comments({ showModal, toggleModal }: { showModal: boolean; toggleModal: () => void }) {
    return (
        <Modal isActive={showModal} onClose={toggleModal} width={'80%'}>
            <ModalHeader>
                <h2>{L('comments')}</h2>
                <StyledCross onClick={toggleModal} />
            </ModalHeader>
            <Comment />
            <NewComment />
        </Modal>
    );
}
