Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["id"] = b.createRpcProxy("ProfilesApi", "id", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = b.createRpcProxy("ProfilesApi", "save", {
  "urlPrefix": "/rpc"
});
o["saveProfileImage"] = b.createRpcProxy("ProfilesApi", "saveProfileImage", {
  "urlPrefix": "/rpc"
});