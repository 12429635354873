import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getSpaceKeys, getSpacesFilterKeys } from './spaceQueries';
import { AddSpace } from '../../schemas';

const useUpdateSpace = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ data, spaceId, propertyId }: { data: AddSpace; spaceId: number; propertyId: number }) => {
            const { name, space_type, ...attr } = data;
            const spaceData = { name, space_type, attr };
            return trpc.BindersApi.updateSpace.query({ space: spaceData, spaceId, propertyId });
        },
        {
            onSettled: (variables) => {
                queryClient.invalidateQueries(getSpaceKeys.lists());
                if (variables?.propertyId)
                    queryClient.invalidateQueries(getSpacesFilterKeys.list({ propertyId: variables.propertyId }));
                if (variables?.id) queryClient.invalidateQueries(getSpaceKeys.detail(variables.id));
            },
        },
    );
};

export default useUpdateSpace;
