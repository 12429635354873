import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdatePropertyTemplateDocSort = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.TemplatesApi.updatePropertyTemplateDocSort.mutate({
            docs: params.docs,
            propertyId: params.documentable_id,
        });
    });
};

export default useUpdatePropertyTemplateDocSort;
