import React from 'react';

import NotificationList from '../components/NotificationList';
import styled from '@emotion/styled';
import { NotificationType } from '@app/api/models/NotificationModel';

const StyledNotificationList = styled(NotificationList)({
    '& li': { marginBottom: '21px', listStyle: 'none' }
});

const NotificationHandler = ({ items }: { items: NotificationType[] }) => {
    return <StyledNotificationList isCollapsed={false} notifications={items || []} />;
};

export default NotificationHandler;
