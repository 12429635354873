import React, { useReducer, useEffect } from 'react';
import { DateSingleInput } from '@datepicker-react/styled';
import { style } from '../Shared/Style';
import { ThemeProvider } from '@emotion/react';

interface Props {
    dateChange: (date: any) => void;
    date?: Date;
    name: string;
    id?: string;
    error?: string;
}

function reducer(state, action) {
    switch (action.type) {
        case 'focusChange':
            return { ...state, showDatepicker: action.payload };
        case 'dateChange':
            return action.payload;
        default:
            throw new Error();
    }
}

export function DatePick(p: Props) {
    const initialState = {
        date: p.date || undefined,
        showDatepicker: false,
    };
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        p.dateChange(state.date);
    }, [state.date]);

    return (
        <ThemeProvider
            theme={{
                reactDatepicker: {
                    fontWeight: style.fontWeight.bold,
                    colors: {
                        primaryColor: style.colors.primary,
                        selectedDay: style.colors.primary,
                        accessibility: style.colors.primary,
                        normalDayHover: style.colors.primary50,
                    },
                },
            }}
        >
            {p.error && <span>{p.error}</span>}
            <DateSingleInput
                inputId={p.id}
                onDateChange={(data) => dispatch({ type: 'dateChange', payload: data })}
                onFocusChange={(focusedInput) => dispatch({ type: 'focusChange', payload: focusedInput })}
                date={state.date}
                displayFormat={'yyyy-MM-dd'}
                showDatepicker={state.showDatepicker}
                placement={'bottom'}
                showResetDate={false}
                showClose={false}
            />
        </ThemeProvider>
    );
}
