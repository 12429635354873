import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';
import styled from '@emotion/styled';
import { FormField } from '../../../../../components/Forms/FormField';
import { AddonProjectStore } from '../../AddonProjectStore';
import { TextArea } from '@ourliving/ourliving-ui';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '200px',
    alignSelf: 'flex-end',
});

const TextInput = styled.input({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    width: '100%',
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
});

export function AddonProjectInfo({ forwardRef, isActive, setIsActive }) {
    const store = AddonProjectStore;

    const onClick = () => {
        store.saveAddonInfo();
        setIsActive(false);
    };

    const onChange = (evt: any) => {
        store.setAddonInfo(evt.target.value, evt.target.name);
    };

    return (
        <Modal height={'500px'} width={'400px'} forwardRef={forwardRef} isActive={isActive}>
            <SubTitle>{L('addons_info')} </SubTitle>
            <FormField title={L('title')} error={''}>
                <TextInput defaultValue={store.addon_info.title} name="title" onChange={onChange} />
            </FormField>
            <FormField title={L('description')} error={''}>
                <TextArea
                    maxWidth={20}
                    defaultValue={store.addon_info.description}
                    name="description"
                    onChange={onChange}
                />
            </FormField>
            <ButtonWrapper>
                <Button
                    onClick={onClick}
                    style={{
                        marginTop: style.margin.l,
                        marginRight: '0px',
                    }}
                >
                    {L('save')}
                </Button>
                <Button
                    onClick={() => setIsActive(false)}
                    variant="danger"
                    style={{
                        marginTop: style.margin.l,
                        marginRight: '0px',
                    }}
                >
                    {L('cancel')}
                </Button>
            </ButtonWrapper>
        </Modal>
    );
}
