import styled from '@emotion/styled';
import RemovedMark from '../../../../components/Icon/e-delete.svg';

export const RemoveIcon = styled(RemovedMark)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '12px',
    width: '12px',
    fill: 'black',
    stroke: 'black'
});
