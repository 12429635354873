import { Order } from '@app/api/models/Orders';
import { Property } from '@app/api/models/Properties';
import AddonsApi from '@app/api/public/AddonsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';

const setEndDate = async ({ propertyIds, endDate }: Variables) => {
    const r = await AddonsApi.saveAddonEndDate(propertyIds, endDate);
    return r;
};

type Variables = {
    propertyIds: Property['id'][];
    endDate: string;
};

const useSetEndDate = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => setEndDate(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getPropertyKeys.all);
        }
    });
};

export default useSetEndDate;
