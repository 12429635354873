import { useQueryClient, useMutation } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useParams } from 'react-router';
import { getMaintenanceDocumentsQueryKey } from './useGetMaintenanceDocuments';

type Variables = { documentId: string; queryId: string };

export const deleteMaintenanceDocument = async (projectId: string, documentId: number, maintenanceId: number) => {
    return await MaintenancesApi.deleteDocumentFromMaintenance(+projectId, documentId, maintenanceId);
};

const useDeleteMaintenanceDocument = () => {
    const queryClient = useQueryClient();
    const { id, projectId } = useParams<{ id: string; projectId: string }>();
    return useMutation((variables: Variables) => deleteMaintenanceDocument(projectId, +variables.documentId, +id), {
        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries([getMaintenanceDocumentsQueryKey]);
        }
    });
};

export default useDeleteMaintenanceDocument;
