import { SpaceFilter } from '@app/trpc-api/models/Binders';
import { SortingState } from '@tanstack/react-table';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const getSpaceKeys = {
    all: ['templates-property-spaces'] as const,
    lists: () => [...getSpaceKeys.all, 'list'] as const,
    list: ({
        propertyId,
        sorting,
        search,
        filter,
    }: {
        propertyId: number;
        sorting: SortingState;
        search: string;
        filter: SpaceFilter;
    }) => [...getSpaceKeys.lists(), { propertyId, sorting, search, filter }] as const,
};

export function useGetSpaces({
    propertyId,
    sorting,
    search,
    filter,
}: {
    propertyId: number;
    sorting: SortingState;
    search: string;
    filter: SpaceFilter;
}) {
    return useQuery(
        [...getSpaceKeys.list({ propertyId, sorting, search, filter })],
        async () =>
            trpc.TemplatesApi.getPropertySpaces.query({
                propertyId,
                sorting,
                search,
                filter,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export const useDeleteSpace = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ propertyId, spaceId }: { spaceId: number; propertyId: number }) => {
            return trpc.TemplatesApi.deleteSpace.query({ spaceId, propertyId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getSpaceKeys.lists());
            },
        },
    );
};
