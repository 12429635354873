import { Alert, AlterPriorityType } from '@app/api/public/AlertsApi';
import React from 'react';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import useGetProjects from '../../hooks/useGetProjects';

type Props = {
    statusFields?: Partial<
        Pick<Alert, 'project_id' | 'publish_from_date' | 'publish_to_date' | 'priority' | 'title' | 'description'>
    >;
    setStatusFields: React.Dispatch<
        React.SetStateAction<
            Partial<
                Pick<
                    Alert,
                    'project_id' | 'publish_from_date' | 'publish_to_date' | 'priority' | 'title' | 'description'
                >
            >
        >
    >;
    isError: boolean;
};

const AlertDetailPageStatus = ({ statusFields, setStatusFields, isError }: Props) => {
    const { data: projects } = useGetProjects();

    const priorityTypes: Array<AlterPriorityType> = ['low', 'medium', 'high'];

    return (
        <div style={{ minWidth: '220px', marginLeft: '12px' }}>
            <Label title={L('project')}>
                <Dropdown
                    isClearable
                    value={statusFields?.project_id}
                    onChange={value => {
                        setStatusFields({ ...statusFields, project_id: value } as any);
                    }}
                    name="project_id"
                    placeholder={L('project')}
                    label="Fastighet"
                    options={projects?.map(p => ({ label: p.name, value: p.id })) || []}
                    error={isError && !statusFields?.project_id}
                />
            </Label>
            <Label title={L('priority')}>
                <Dropdown
                    isClearable
                    value={statusFields?.priority}
                    onChange={value => {
                        setStatusFields({ ...statusFields, priority: value } as any);
                    }}
                    name="priority"
                    placeholder={L('priority')}
                    label="Prioritet"
                    options={priorityTypes?.map(p => ({ label: L('ticket_priority_' + p), value: p })) || []}
                    error={isError && !statusFields?.priority}
                />
            </Label>
            <Label error={isError && !statusFields?.publish_from_date ? 'Välj datum' : ''} title={L('start_date')}>
                <Input
                    onChange={e => setStatusFields({ ...statusFields, publish_from_date: e.target.value } as any)}
                    type="date"
                    value={statusFields?.publish_from_date || ''}
                    name="priority"
                />
            </Label>
            <Label error={isError && !statusFields?.publish_to_date ? 'Välj datum' : ''} title={L('end_date')}>
                <Input
                    onChange={e => setStatusFields({ ...statusFields, publish_to_date: e.target.value } as any)}
                    type="date"
                    value={statusFields?.publish_to_date || ''}
                    name="priority"
                />
            </Label>
        </div>
    );
};

export default AlertDetailPageStatus;
