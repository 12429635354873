import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../lib/trpc';
import { getProjectKeys } from '../../binders/hooks/projectHooks/projectQueries';
import { L } from '../../../lib/i18n';
import toast from 'react-hot-toast';

const useCopyPropertyDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ documentIds, propertyIds }: { documentIds: number[]; propertyIds: number[] }) =>
            await trpc.BindersApi.copyPropertyDocument.mutate({ documentIds, propertyIds }),
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.documents());
            },
            onSuccess: (data) => {
                toast.success(`${L('number_of_documents_copied')}: ${data.length}`);
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        },
    );
};

export default useCopyPropertyDocuments;
