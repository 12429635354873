import 'regenerator-runtime/runtime';
import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { Input } from '@ourliving/ourliving-ui';

type Props = {
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const GlobalFilter = ({ filter, setFilter, ...rest }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
    const [value, setValue] = useState<string>(filter || '');
    const onChange = useAsyncDebounce((value: string) => {
        setFilter(value);
    }, 300);

    return (
        <>
            <Input.Search
                icon={{ position: 'left', variant: { builtInIcon: 'search' } }}
                {...rest}
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </>
    );
};
export default GlobalFilter;
