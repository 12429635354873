import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateProductDocName = () => {
    return useMutation(async (params: { docId: number; name: string; entityId: number }) => {
        return trpc.ProductsApi.updateProductDocumentName.mutate(params);
    });
};

export default useUpdateProductDocName;
