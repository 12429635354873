import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getAdminKeys } from './adminQueries';

const useRemoveAdmins = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (membershipIds: number[]) => {
            return await Promise.all(
                membershipIds.map(async (id) => {
                    return trpc.MembersApi.removeAdmin.mutate(id);
                }),
            );
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getAdminKeys.lists());
            },
        },
    );
};

export default useRemoveAdmins;
