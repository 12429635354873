import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import PackageProductForm from '../../binders/components/Products/PackageProductForm';
import { AddPackageProduct } from '../../binders/schemas';
import useAddProductToPackage from '../../binders/hooks/productHooks/useAddProductToPackage';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';

const AddProductComponent = ({ packageId }: { packageId: string }) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const navigate = useNavigate();

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProductToPackage();

    const onSubmit: SubmitHandler<AddPackageProduct> = (data) =>
        saveInventoryMaterial(
            { inventoryMaterial: data, parentId: +packageId },
            {
                onSuccess: () => {
                    toast.success(L('product_added'));
                    navigate('..');
                },
            },
        );

    return (
        <>
            <CardWithDivider
                topArea={<CardTitle>{L('add_product')}</CardTitle>}
                mainArea={<PackageProductForm onSubmit={onSubmit} options={options} />}
            />
        </>
    );
};

export default function AddNewProductToPackage() {
    const { productId: packageId } = useParams<{ productId: string }>();
    return packageId ? <AddProductComponent packageId={packageId} /> : null;
}
