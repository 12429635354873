import { ProductListingV2 } from '@app/api/models/Addons';
import _ from 'lodash';
import React from 'react';
import { ProductConfirm } from './ProductConfirm';

type Props = { addonList: ProductListingV2[]; space_id: number };

export const ProductListConfirmPage = (props: Props) => {
    const products = props.addonList.filter(p => p.parent_ids.includes(props.space_id));

    const selectedProducts = products.filter(p => p.selected);
    const productGroups = _(selectedProducts)
        .groupBy('name')
        .map((value, key) => ({ name: key, products: value }))
        .value();

    const rs = productGroups.flatMap(pg =>
        pg.products.map(p => {
            const r = products.find(f => f.id === p.parent_id);

            let productGroupName;

            if (r?.addon_type === 'sublist' || r?.addon_type === 'sublist_optional') {
                productGroupName = r.name;
            }

            return <ProductConfirm key={p.id} product={p} productGroupName={productGroupName} />;
        })
    );
    return <>{rs}</>;
};
