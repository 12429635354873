import { useQuery } from 'react-query';
import PropertiesApi from '@app/api/public/PropertiesApi';

export const propertiesQueryKey = {
    all: ['property'],
    getProperties: () => [...propertiesQueryKey.all, 'list'] as const
};

const getProperties = async () => {
    return await PropertiesApi.listPropertiesByUser();
};

const useGetProperties = () => {
    return useQuery(propertiesQueryKey.getProperties(), () => getProperties());
};

export default useGetProperties;
