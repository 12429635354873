import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import z from 'zod';
import { AccountContact } from '@app/api/models/AccountContacts';
import AccountContactsApi from '@app/api/public/AccountContactsApi';
import { contactsQueryKey } from './useGetContacts';

const errorSchema = z.object({ email: z.string(), status: z.string() }).array();

const removeContact = async ({ contactArray }: { contactArray: AccountContact[] }) => {
    const response = await Promise.all(
        contactArray.map(async contact => {
            try {
                const response = await AccountContactsApi.remove(contact.id);
                if (response.status === 'error') return { email: contact.name, status: response.status };
                return { email: contact.name, status: 'success' };
            } catch (error) {
                return { email: contact.name, status: 'unknown' };
            }
        })
    );
    const errors = response.find(item => item.status === 'error' || item.status === 'unknown');
    if (errors) throw response;
};
const useRemoveCheckedContacts = () => {
    const queryClient = useQueryClient();
    return useMutation(removeContact, {
        onSuccess: () => {
            toast.success(`${L('contacts_removed')}`);
        },
        onError: err => {
            console.log(err);

            const validatedError = errorSchema.safeParse(err);
            /* Shows error message  */
            if (validatedError.success) {
                const errors = validatedError.data.filter(item => item.status === 'error' || item.status === 'unknown');
                errors.map(item => {
                    toast.error(`Inbjudan gick inte att skicka till ${item.email}`);
                });
                const success = validatedError.data.filter(item => item.status === 'success');
                success.length && toast.success(`${success.length} medlemmar inbjudna`);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(contactsQueryKey.getContactList());
        }
    });
};

export default useRemoveCheckedContacts;
