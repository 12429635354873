import React from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import DocumentAreaAndDropZone from '../../../components/DocumentCommunity/DocumentAreaAndDropZone';
import dayjs from 'dayjs';
import ErrorAndInputWrapper, { InputWithError } from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Separator } from '../../../components/Separator/Separator';
import { Loading } from '../../../components/Loading/Loading';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListSpaces,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';
import {
    getProductsKeys,
    useGetProduct,
    useGetProductsDocumentsTemplateProperty,
    useGetProductsImagesTemplateProperty,
} from '../../binders/hooks/productQueries';
import { AddProduct } from '../../binders/schemas';
import PropertyProductTemplateForm from '../PropertyTemplates/PropertyProductTemplateForm';
import useDragEndDnd from '../../../hooks/useDragEndDndNew';
import useUpdateProductDocSortTemplateProperty from '../../project-templates/hooks/Products/useUpdateProductDocSortTemplateProperty';
import useRemoveProductDocumentPropertyTemplate from '../../project-templates/hooks/Products/useRemoveProductDocumentPropertyTemplate';
import useUpdateProductDocTypeTemplateProperty from '../../project-templates/hooks/Products/useUpdateProductDocType';
import useUpdateProductDocNameTemplateProperty from '../../project-templates/hooks/Products/useUpdateProductDocName';
import useUploadProductDocumentsPropertyTemplate from '../../project-templates/hooks/Products/useUploadProductDocumentsPropertyTemplate';
import useUpdateProductOnPropertyTemplate from '../hooks/useUpdateProductOnPropertyTemplate';
import Images from '../../binders/components/Draggables/Images';
import ImageDropZoneNoText from '../../../components/ImageDropzone/ImageDropZoneNoText';

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const Divider = styled(Separator)({
    backgroundColor: style.colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

function EditPropertyProductTemplate({ propertyId, productId }: { propertyId: string; productId: string }) {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();

    const { data: spaces } = useListSpaces(+propertyId);

    const { data: product, isLoading } = useGetProduct(+productId);

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: updateInventoryMaterial } = useUpdateProductOnPropertyTemplate();

    const onSubmit: SubmitHandler<AddProduct> = (data) =>
        updateInventoryMaterial(
            { inventoryMaterial: data, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('product_updated'));
                },
                onError: () => {
                    toast.error(L('product_not_updated'));
                },
            },
        );

    const { data: documents } = useGetProductsDocumentsTemplateProperty(+productId);
    const { data: images } = useGetProductsImagesTemplateProperty(+productId);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateProductDocSortTemplateProperty();
    const { mutate: saveMultipleDocs } = useUploadProductDocumentsPropertyTemplate();
    const { mutate: deleteDocument } = useRemoveProductDocumentPropertyTemplate();
    const { mutate: renameDocument } = useUpdateProductDocNameTemplateProperty();
    const { mutate: updateDocType } = useUpdateProductDocTypeTemplateProperty();

    const queryClient = useQueryClient();
    const handleSaveMultipleDocs = (files: File[]) => {
        saveMultipleDocs(
            { files, productId: +productId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('documents_uploaded'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
            },
        );
    };

    const onImageUpload = (files: File[]) => {
        if (!product?.property_id) return;
        saveMultipleDocs(
            { files, productId: +productId, documentType: 0, propertyId: +product.property_id },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRemoveDocument = (documentId: number) => {
        deleteDocument(
            { documentId, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRenameDocument = (documentId: number, name: string) => {
        renameDocument(
            { docId: documentId, name, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('renamed_document')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleUpdateDocType = ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => {
        updateDocType(
            { docId: documentId, document_type, document_subtype, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('updated_document_types'));
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDragEndDocs = useDragEndDnd({
        queryKey: getProductsKeys.documentsByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const handleDragEndImages = useDragEndDnd({
        queryKey: getProductsKeys.imagesByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const imagesData =
        images?.map((document) => {
            return { id: document.id, url: document.signed_url };
        }) ?? [];

    if (isLoading) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('information')}</CardTitle>}
            mainArea={
                <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '1rem' }}>
                    {product && (
                        <PropertyProductTemplateForm
                            propertyId={+propertyId}
                            onSubmit={onSubmit}
                            options={options}
                            defaultValues={product}
                            oneFieldPerRow
                        />
                    )}
                    <DividerContainer>
                        <Divider decorative orientation="vertical" />
                    </DividerContainer>
                    <div>
                        <div>
                            <div>
                                <ErrorAndInputWrapper>
                                    <Label title={L('created_at')}>
                                        <InputWithError
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(product?.created_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </Label>
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <Label title={L('updated_at')}>
                                        <InputWithError
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(product?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </Label>
                                </ErrorAndInputWrapper>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Images
                                    imageData={imagesData}
                                    handleRemoveImage={handleRemoveDocument}
                                    handleDragEnd={handleDragEndImages}
                                    isUpdatingSort={isUpdatingSort}
                                >
                                    <ImageDropZoneNoText onDrop={onImageUpload} />
                                </Images>
                            </div>
                            <StyledH3>{L('documents')}</StyledH3>
                            <DocumentAreaAndDropZone
                                documents={documents}
                                removeDocument={handleRemoveDocument}
                                saveMultipleDocs={handleSaveMultipleDocs}
                                renameDocument={handleRenameDocument}
                                updateDocType={handleUpdateDocType}
                                handleDragEnd={handleDragEndDocs}
                                isUpdatingSort={isUpdatingSort}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
}

export default function EditPropertyProductTemplatePage() {
    const {
        id: propertyId,
        productId,
        packageItemId,
    } = useParams<{ id: string; productId: string; packageItemId?: string }>();

    if (propertyId && packageItemId) {
        return <EditPropertyProductTemplate productId={packageItemId} propertyId={propertyId} />;
    }
    if (propertyId && productId) {
        return <EditPropertyProductTemplate productId={productId} propertyId={propertyId} />;
    }
    return null;
}
