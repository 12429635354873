import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { SettingsProjectFeatures } from './Projects/SettingsProjectFeatures';
import AdminSettingsPage from './Admins/pages/AdminSettingsPage';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const Navigation = () => {
    return (
        <Menu>
            <BigTabNav>
                <Routes>
                    <Route path="administration-settings">
                        <Route
                            index
                            element={
                                <>
                                    <BigNavItem end to=".">
                                        {L('features')}
                                    </BigNavItem>
                                    <BigNavItem end to="./admins">
                                        {L('admins')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="admins"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('features')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('admins')}
                                    </BigNavItem>
                                </>
                            }
                        />
                    </Route>
                </Routes>
            </BigTabNav>
        </Menu>
    );
};

export const AdministrationSettingsPage = () => {
    return (
        <>
            <Navigation />
            <Routes>
                <Route path="administration-settings">
                    <Route index element={<SettingsProjectFeatures />} />

                    <Route path="admins" element={<AdminSettingsPage />} />
                </Route>
            </Routes>
        </>
    );
};
