const parseUrl = () => {
    const regex = new RegExp(/(\.\w+)$/i);

    const toplevelUrl = window.location.origin.match(regex)?.map((parsedString) => parsedString.replace('.', ''));

    if (toplevelUrl?.length) {
        return toplevelUrl[0];
    }
    return null;
};

const sentryConfig = () => {
    const config = {
        SENTRY_DSN: 'https://f4fa7bb3ad376a703ff8b8214633f932@o4506540738281472.ingest.sentry.io/4506547745980416',
        SENTRY_ENVIRONMENT: 'local',
    };
    const toplevelUrl = parseUrl();
    if (!toplevelUrl)
        return {
            ...config,
            SENTRY_ENVIRONMENT: 'no top level url',
        };
    switch (toplevelUrl) {
        case 'work':
            return {
                ...config,
                SENTRY_ENVIRONMENT: 'staging',
            };

        case 'se':
            return {
                ...config,
                SENTRY_ENVIRONMENT: 'production',
            };

        default:
            return config;
    }
};

export default sentryConfig;
