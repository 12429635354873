import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Button } from '@ourliving/ourliving-ui';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import { L } from '../../../lib/i18n';
import useSaveSettingsListByType from '../hooks/useSaveSettingsListByType';
import StatusSettingItem from './StatusSettingItem';
import ColorPicker from './ColorPicker';

const Container = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    flexDirection: 'column'
});
const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});
const H1 = styled.h1({
    fontSize: '2rem',
    fontWeight: 100
});
const Input = styled.input({
    border: '1px solid gray',
    borderRadius: '4px',
    width: '300px',
    height: '40px',
    margin: '1rem',
    paddingLeft: '1rem',
    '&:focus': {
        outline: 'none'
    }
});
const AddButton = styled(Button)({
    marginTop: '0.5rem'
});
const ItemContainer = styled.div({
    marginTop: '3rem'
});

type Props = {
    settingsType: 'category' | 'status' | 'unit' | 'type';
};

const StatusSettings = ({ settingsType }: Props) => {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const { data: settingsList } = useGetSettingsListByType('status');
    const { mutate: save } = useSaveSettingsListByType('status');

    return (
        <Container>
            <H1>{`Inställningar för ${L(settingsType)}`}</H1>
            <Form
                onSubmit={e => {
                    setName('');
                    e.preventDefault();
                    save({ name, color_code: color });
                }}
            >
                <Input
                    required={true}
                    onChange={e => setName(e.target.value)}
                    value={name}
                    placeholder="Skriv ditt alternativ"
                />
                <ColorPicker getValue={value => setColor(value)} />
                <AddButton type="submit">{L('add')}</AddButton>
            </Form>
            <ItemContainer>
                {settingsList?.map(item => {
                    return <StatusSettingItem key={item.id} item={item} settingsType={'status'} />;
                })}
            </ItemContainer>
        </Container>
    );
};

export default StatusSettings;
