import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import EditProduct from '../../components/Products/EditProduct';

export default function EditProductPage() {
    const {
        id: projectId,
        propertyId,
        productId,
        packageItemId,
    } = useParams<{ id: string; propertyId: string; productId: string; packageItemId: string }>();

    if (packageItemId && projectId && propertyId) {
        return <EditProduct productId={packageItemId} projectId={projectId} propertyId={propertyId} />;
    }
    if (productId && projectId && propertyId) {
        return <EditProduct productId={productId} projectId={projectId} propertyId={propertyId} />;
    }
    return null;
}
