import styled from '@emotion/styled';
import React from 'react';
import { style } from '@app/shared/lib/Style';

import { formatCurrency } from '@app/shared/lib/formatting';
import { L } from '../../../../lib/i18n';
import { productFallbackImage } from '../../../../lib/productFallbackImage';
import { GetListData } from '../hooks/useGetList';

const Product = styled.div({
    display: 'flex',
    width: '100%',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    justifyContent: 'space-between',
    padding: `${style.margin.xs}px ${style.margin.s}px `,
    flexDirection: 'row'
});

const ProductInfo = styled.div({
    display: 'flex',
    height: '30px',
    fontSize: style.fontSize.micro,
    lineHeight: '14px',
    fontFamily: 'Lato',
    fontWeight: style.fontWeight.bold,
    color: style.colors.black,
    alignItems: 'flex-start'
});

const Image = styled.img({
    width: '63px',
    height: '54px',
    borderRadius: '4px',
    boxShadow: '3px 6px 6px rgba(0, 0, 0, 0.15)',
    objectFit: 'cover'
});

export const ProductRow = ({
    product,
    fields,
    ...rest
}: {
    product: GetListData[0];
    fields: string[];
} & React.HTMLAttributes<HTMLDivElement>) => {
    const p = product;
    if (!p) return null;
    return (
        <Product {...rest}>
            {fields.includes('image') ? (
                <Image
                    src={p.signedUrl || productFallbackImage}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = productFallbackImage;
                    }}
                />
            ) : (
                fields.includes('function') && (
                    <ProductInfo style={{ width: '12%' }}>{L(p.product_type_name)}</ProductInfo>
                )
            )}

            {fields.includes('product') && (
                <>
                    <ProductInfo style={{ width: '23%' }}>{p.path_name && p.path_name}</ProductInfo>
                    <ProductInfo style={{ width: '25%' }}>
                        {p.product_name}
                        {p.addon_selected_info?.custom_request && ':'}
                        <span style={{ fontStyle: 'italic', marginLeft: style.margin.xs }}>
                            {p.addon_selected_info?.custom_request &&
                                `:${p.is_addon_color_picker ? 'Färgkod: NCS ' : ''}${
                                    p.addon_selected_info?.custom_request
                                }`}
                        </span>
                    </ProductInfo>
                </>
            )}
            {fields.includes('brand') && <ProductInfo style={{ width: '10%' }}>{p.brand_name}</ProductInfo>}
            {fields.includes('supplier') && <ProductInfo style={{ width: '10%' }}>{p.supplier_name}</ProductInfo>}
            {fields.includes('profession') && <ProductInfo style={{ width: '10%' }}>{p.profession_name}</ProductInfo>}
            {fields.includes('price') && (
                <ProductInfo style={{ width: '10%', textAlign: 'right' }}>
                    {p.addon_info.price ? formatCurrency(p.addon_info.price, false) : L('price_missing')}
                </ProductInfo>
            )}
        </Product>
    );
};
