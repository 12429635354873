import { AccountContact } from '@app/api/models/AccountContacts';
import { Property } from '@app/api/models/Properties';
import AccountContactsApi from '@app/api/public/AccountContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { copyContactsQueryKeys } from './useCopyContactsToProjects';

const copyContactsToProperties = ({
    contacts,
    properties
}: {
    contacts: AccountContact['id'][];
    properties: Property['id'][];
}) => {
    return AccountContactsApi.copyContactsToProperties(contacts, properties);
};

const useCopyContactsToProperties = () => {
    const queryClient = useQueryClient();
    return useMutation(copyContactsToProperties, {
        onSuccess: () => {
            toast.success('Kopiering genomförd');
        },
        onError: err => {
            console.log(err);
            toast.error(`Det gick inte att kopiera kontakter`);
        },
        onSettled: () => {
            queryClient.invalidateQueries(copyContactsQueryKeys.all);
        }
    });
};

export { useCopyContactsToProperties as default };
