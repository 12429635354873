import React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

interface Props {
    name?: string;
    placeholder?: string;
    onChange: (e) => void;
}

const SearchBar = styled.input({
    background: style.colors.white,
    border: '1px solid #F0F1F7',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    outline: 'none',
    lineHeight: '1.5em',
    marginRight: style.margin.m,
    fontSize: style.fontSize.seta,
    padding: style.margin.xs
});

const Menu = styled.div({
    display: 'flex'
});

export function SearchField(p: Props) {
    return (
        <Menu>
            <SearchBar onChange={p.onChange} name={p.name} placeholder={p.placeholder} />
        </Menu>
    );
}
