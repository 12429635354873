import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { MultiSelect } from '../../../../../components/Dropdown/MultiSelect';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import { AddonFilterProductGroup } from '../../../../../../api/models/Orders';
import DropdownFlexContainer from './DropdownFlexContainer';

const Container = styled.div({ paddingRight: '10px', width: 'max-content' });

interface Props {
    filter: { product_group?: string[] };
    setFilter: (filter: any) => void;
    store: { productGroups: AddonFilterProductGroup[]; loadAddonSuppliers(projectId?: string): Promise<void> };
}

export const FilterProductGroup = (props: Props) => {
    const name = 'product_group';

    const handleOnChange = value => {
        event?.preventDefault();
        props.setFilter({
            ...props.filter,
            [name]: [...new Set<string>(value?.flatMap(id => id.value))]
        });
    };

    const options = props.store.productGroups.map(v => ({
        value: v.name,
        label: (
            <DropdownFlexContainer>
                <Checkbox onChange={() => handleOnChange} checked={props.filter[name]?.includes(v.name) || false} />
                <span>{v.name}</span>
            </DropdownFlexContainer>
        )
    }));

    const selectAll = [
        {
            value: props.store.productGroups?.map(p => p.name),
            label: ' Välj alla'
        },
        ...options
    ];

    return (
        <Container>
            <MultiSelect
                isMulti={true}
                isClearable
                value={props.filter[name]?.map(id => ({
                    value: id,
                    label: props.store.productGroups.find(({ name: name }) => name === id)?.name
                }))}
                onChange={handleOnChange}
                name={name}
                label={L(name)}
                options={selectAll}
            />
        </Container>
    );
};
