import { useObserver } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Card } from '../../../../components/Layout/Card';
import { Header } from '../../../../components/Layout/Header';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import { usePromise } from '../../../../lib/react';
import { AddonProductStore } from '../AddonProductStore';

export function AddonProductDetailPage(props: RouteComponentProps<{ productId: string }>) {
    const id = props.match.params.productId;
    const [ready] = usePromise(async () => {
        await AddonProductStore.load(id);
    }, []);

    // const onChange = (evt: any) => {
    //     PropertyStore.setProjectField(evt.target.value, evt.target.name);
    // };

    // const save = () => {
    //     PropertyStore.save();
    // };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }
        const p = AddonProductStore.product;

        return (
            <>
                <Header title={L('product')} />
                <Card>
                    <div>{p.name}</div>
                </Card>
            </>
        );
    });
}
