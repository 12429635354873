import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const useHandleSearch = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const searchField = useMemo(() => searchParams.get('searchField') ?? '', [searchParams]);

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };

    return { searchField, handleSearch };
};

export default useHandleSearch;
