import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../Shared/Style/colors';
import * as Switch from '@radix-ui/react-switch';

const SwitchRoot = styled(Switch.Root)(() => {
    return {
        border: '1px solid',
        padding: '0',
        width: '42px',
        height: '25px',
        borderColor: colors.black80,
        borderRadius: '9999px',
        position: 'relative',
        cursor: 'pointer',
        '&[aria-checked=true]': {
            backgroundColor: colors.primary
        },
        ':focus': {
            boxShadow: 'none'
        }
    };
});

const SwitchThumb = styled(Switch.Thumb)(() => {
    return {
        display: 'block',
        width: '21px',
        height: '21px',
        backgroundColor: 'white',
        borderRadius: '9999px',
        transition: 'transform 100ms',
        transform: 'translateX(2px)',
        willChange: 'transform',
        '&[data-state=checked]': {
            transform: 'translateX(19px)'
        }
    };
});

type SwitchProps = React.ComponentProps<typeof Switch.Root>;
export const StyledRadixSwitch = ({ ...rest }: SwitchProps) => {
    return (
        <SwitchRoot {...rest}>
            <SwitchThumb />
        </SwitchRoot>
    );
};

export default StyledRadixSwitch;
