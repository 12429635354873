import React from 'react';
import { Route } from 'react-router-dom';
import { InformationListPage } from './ListPage/InformationListPage';
import { InformationDetailPage } from './DetailPage/InformationDetailPage';
import { InformationNewDocument } from './DetailPage/InformationNewDocument';

export const routes = [
    <Route key="0" exact path="/information" component={InformationListPage} />,
    <Route key="1" exact path="/information/:id" component={InformationDetailPage} />,
    <Route key="2" exact path="/information/:id/document/new" component={InformationNewDocument} />
];

export const navigation = [{ name: 'Information', link: '/information', icon: 'Information' }];
