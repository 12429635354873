import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi, { Maintenance } from '@app/api/public/MaintenancesApi';
import { useHistory, useParams } from 'react-router-dom';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { createValidator, required } from '../../../lib/validation';

type Variables = Partial<Maintenance>;

const rules = createValidator({
    completed_date: [required()],
    completed_cost: [required()]
});

const completeMaintenance = async (projectId: string, variables: Variables) => {
    const { errors, ok } = rules.test(variables);

    if (ok) {
        return await MaintenancesApi.save(+projectId, variables);
    }
    return Promise.reject(errors);
};

const useCompleteMaintenance = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ projectId: string }>();

    return useMutation((variables: Variables) => completeMaintenance(projectId, variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
        }
    });
};

export default useCompleteMaintenance;
