import styled from '@emotion/styled';
import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import useGetProfile from '../../../lib/hooks/useGetProfile';
import useGetProjectInfo from '../../../lib/hooks/useGetProjectInfo';
import { L } from '../../../lib/i18n';
import { useGetTicketLog, useGetSettingsByType, useGetTicket, useGetAssignees } from '../hooks/ticketQueries';
import whoisCreator from '../lib/whoIsCreator';
import LogItem from './LogItem';
import Message from './Message';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';

const LogWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
});

const Log = () => {
    const { id } = useParams<{ id: string }>();
    const { data: log } = useGetTicketLog(id);
    const { data } = useGetProjectInfo();
    const { data: profile } = useGetProfile();
    const { data: assignees } = useGetAssignees();
    const { data: priority } = useGetSettingsByType('priority');
    const { data: status } = useGetSettingsByType('status');
    const { data: ticket } = useGetTicket(id);
    const assigneesIds = assignees?.map(member => member.id) ?? [];
    const LogItems = log?.map(logItem => {
        if (logItem.type === 'event') {
            const eventItem = logItem.data;
            return (
                <LogItem
                    key={eventItem.id + ' event'}
                    logItem={eventItem}
                    projectName={data?.name || ''}
                    statuses={status || []}
                    priorities={priority || []}
                    logCreator={whoisCreator(
                        eventItem.created_by,
                        profile ? Number(profile.id) : undefined,
                        assigneesIds
                    )}
                />
            );
        }
        if (logItem.type === 'message') {
            const message = logItem.data;
            return (
                <Message
                    key={message.id + ' message'}
                    message={message}
                    messageSender={whoisCreator(
                        message.created_by,
                        profile ? Number(profile.id) : undefined,
                        assigneesIds
                    )}
                />
            );
        }
    });

    return (
        <CardWithDivider
            topArea={
                <CardTitle>
                    #{ticket?.project_id} - {ticket?.ticket_no} {L('ticket_log')}
                </CardTitle>
            }
            mainArea={
                <>
                    <LogWrapper>{LogItems}</LogWrapper>
                    {log?.length === 0 && (
                        <EmptyState>
                            <EmptyState.Title>{L('empty_log')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('empty_log_description')}</p>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                </>
            }
        />
    );
};

export default Log;
