import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useDeleteProjectDocument = () => {
    return useMutation(async ({ documentId, projectId }: { documentId: number; projectId: number }) => {
        return await trpc.BindersApi.removeProjectDocument.mutate({ id: documentId, projectId });
    });
};

export default useDeleteProjectDocument;
