import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import NumericCell from '../NumericCell';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { MutatedInventoryData } from '../../pages/PropertyPages/PropertyProductsPage';
import { TableMeta } from '../../TableMetaTypes/PropertyProductsTableMeta';
import TableImageWithFallback from '../TableImageWithFallback';

const columnHelper = createColumnHelper<MutatedInventoryData>();

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const ItalicSpan = styled.span({
    fontStyle: 'italic',
});

const columns = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
        enableResizing: false,
    }),
    columnHelper.display({
        id: 'image',
        cell: ({ row }) => (
            <div style={{ width: '100px', aspectRatio: '4/3' }}>
                <TableImageWithFallback id={row.original?.product_image_data?.original?.id} />
            </div>
        ),
        enableResizing: false,
        size: 75,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
    }),
    columnHelper.accessor('name', {
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.getValue())}</Data>
            </CellContainer>
        ),
        minSize: 135,
        size: 150,
        header: () => <Flex>{L('product_group')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('function', {
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.function?.length > 0 ? info.row.original.function : '')}</Data>
            </CellContainer>
        ),
        minSize: 90,
        size: 150,
        header: () => <Flex>{L('function')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('product_name', {
        cell: ({ row }) =>
            row.original?.count ? (
                <CellContainer>
                    <Data>
                        {row.original?.product_name ? (
                            row.original.product_name
                        ) : (
                            <ItalicSpan>{L('name_missing')}</ItalicSpan>
                        )}{' '}
                        <span
                            style={{
                                fontWeight: 'bolder',
                            }}
                        >
                            ({row.original?.count}C)
                        </span>
                    </Data>
                </CellContainer>
            ) : (
                <CellContainer>
                    <Data>{row.original?.product_name}</Data>
                </CellContainer>
            ),
        minSize: 80,
        size: 150,
        header: () => <Flex>{L('product_title')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('space_name', {
        cell: (info) => (
            <CellContainer>
                <Data>{info.row.original?.space_name}</Data>
            </CellContainer>
        ),
        minSize: 90,
        size: 150,
        header: () => <Flex>{L('space')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('color', {
        cell: (info) => <CellContainer>{L(info.row.original?.color)}</CellContainer>,
        minSize: 60,
        size: 150,
        header: () => <Flex>{L('product_color')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('addon_price', {
        cell: ({ table, row }) => {
            const tableMeta = table.options.meta as TableMeta;
            return (
                <CellContainer style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
                    <NumericCell
                        addon_price={row.original.addon_price}
                        id={row.original.id}
                        previewDeletion={tableMeta.previewDeletion}
                        previewPrices={tableMeta.previewPrices}
                        updateData={tableMeta.updateData}
                    />
                </CellContainer>
            );
        },
        minSize: 60,
        size: 150,
        header: () => <Flex>{L('product_purchased_price')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('brand', {
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.brand?.length > 0 ? info.row.original.brand : '')}</Data>
            </CellContainer>
        ),
        minSize: 115,
        size: 150,
        header: () => <Flex>{L('brand')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('package_type', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{L(row.original.package_type)}</Data>
            </CellContainer>
        ),
        minSize: 115,
        size: 150,
        header: () => <Flex>{L('choice')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
];

export default columns;
