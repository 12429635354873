import { Maintenance } from '@app/api/models/Maintenances';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import CompleteMaintenanceForm from '../components/CompleteMaintenanceForm';
import EditMaintenanceForm from '../components/EditMaintenanceForm';
import MaintenanceStatusArea from '../components/MaintenanceStatusArea';
import useDeleteMaintenance from '../hooks/useDeleteMaintenance';
import useDeleteMaintenanceDocument from '../hooks/useDeleteMaintenanceDocument';
import useGetMaintenance from '../hooks/useGetMaintenance';
import useGetMaintenanceDocuments from '../hooks/useGetMaintenanceDocuments';
import useGetServicePartnersByCategory from '../hooks/useGetServicePartnersByCategory';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import useSaveMaintenance from '../hooks/useSaveMaintenance';
import useSaveMaintenanceDocument from '../hooks/useSaveMaintenanceDocument';
import { Route, Switch } from 'react-router-dom';
import { L } from '../../../lib/i18n';

const Container = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    width: '100%',
    alignSelf: 'start'
});

const EditMaintenanceContainer = styled.div({
    display: 'grid',
    gap: '2rem',
    gridTemplateColumns: '3fr 1fr',
    width: '100%'
});

export type View = 'edit' | 'complete';

const EditMaintenancePage = () => {
    const { data: maintenanceData, status: maintenanceDataStatus } = useGetMaintenance();
    const { data: categoryList, status: categoryStatus } = useGetSettingsListByType('category');
    const { data: statusList, status: statusListStatus } = useGetSettingsListByType('status');
    const { data: typeList, status: typeStatus } = useGetSettingsListByType('type');

    const [view, setView] = useState<View>('edit');
    const [maintenanceItem, setMaintenanceItem] = useState<Partial<Maintenance>>();

    const { mutate: remove } = useDeleteMaintenance();
    const { mutate: save, status } = useSaveMaintenance();
    const { data: documentItem } = useGetMaintenanceDocuments();
    const { mutate: saveDoc } = useSaveMaintenanceDocument();
    const { mutate: deleteDoc } = useDeleteMaintenanceDocument();

    useEffect(() => {
        if (!maintenanceData) return;
        setMaintenanceItem(maintenanceData);
    }, [maintenanceData]);

    const { data: servicePartners } = useGetServicePartnersByCategory(maintenanceItem?.maintenance_category?.id);

    if (maintenanceDataStatus === 'loading') return <Loading />;
    if (statusListStatus === 'loading') return <Loading />;
    if (categoryStatus === 'loading') return <Loading />;
    if (typeStatus === 'loading') return <Loading />;
    if (!maintenanceData) return <div>{L('error_data_fetch')}</div>;

    const handleChange = (value: Partial<Maintenance>) => {
        setMaintenanceItem(prev => {
            return { ...prev, ...value };
        });
    };

    const handleSave = () => {
        save({
            ...maintenanceItem,
            planned_date: maintenanceItem?.planned_date ? maintenanceItem.planned_date : undefined,
            completed_date: maintenanceItem?.completed_date ? maintenanceItem.completed_date : undefined
        });
    };

    const handleRemove = () => {
        if (!maintenanceData.id) return;
        remove({ id: maintenanceData.id });
    };

    const formProps = {
        categoryList,
        deleteDoc,
        documentItem,
        handleChange,
        handleRemove,
        handleSave,
        maintenanceItem,
        saveDoc,
        statusList,
        typeList,
        status,
        servicePartners
    };
    const CompleteProps = {
        handleChange,
        maintenanceItem
    };
    return (
        <EditMaintenanceContainer>
            <Container>
                <Switch>
                    <Route path={'/maintenance/:projectId/:id/edit'}>
                        <EditMaintenanceForm {...formProps} />
                    </Route>
                    <Route path={'/maintenance/:projectId/:id/complete'}>
                        <CompleteMaintenanceForm {...CompleteProps} />
                    </Route>
                </Switch>
            </Container>
            <MaintenanceStatusArea maintenanceItem={maintenanceItem} />
        </EditMaintenanceContainer>
    );
};

export default EditMaintenancePage;
