import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import RadialProgressBar from '../../../components/ProgressBar/RadialProgressBar';
import { Project } from '@app/api/models/Projects';
import useGetProjects from '../hooks/useGetProjects';

const Card = styled(NavLink)({
    padding: '12px',
    borderBottom: '1px solid #979797',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:nth-of-type(1)': {
        borderTop: '1px solid #979797'
    },
    ['@media screen and (min-width: 1200px)']: {
       '&.active': { background: '#F0F1F7'}
    },
})
    
const InfoWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});
const PropertyName = styled.span({
    color: '#444444',
    fontSize: '16px',
    fontWeight: 300,
    width: '85%',
    wordBreak: 'break-all',
    display: 'flex',
    flexDirection: 'column'
});

const RadialContainer = styled.div({
    width: '40px'
});

const Address = styled.span({
    color: '#444444',
    fontSize: '12px',
    fontWeight: 300
});

const ProjectComponent = ({ project, ...rest }: { project: Project }) => {
    const { data: projects } = useGetProjects();
    if (!projects) return null;



    const projectItem = projects?.find(item => item.name === project.name);

    return (
        <Card {...rest} to={`/inventory/project/${project.id}`}>
            <InfoWrapper>
                <PropertyName>
                    {project.name}
                    <Address>
                        {project.address ? project.address + ', ' : ''} {project?.city}
                    </Address>
                </PropertyName>
                <RadialContainer>
                    <RadialProgressBar current={projectItem?.completed} total={projectItem?.total} />
                </RadialContainer>
            </InfoWrapper>
        </Card>
    );
};

export default ProjectComponent;
