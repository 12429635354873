import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { Column, useTable, useSortBy, useFlexLayout, useGlobalFilter } from 'react-table';
import GlobalFilter from '../filterComponents/GlobalFilter';
import { style } from '../Shared/Style';
import SortArrows from './sortArrows/SortArrows';

const Styles = styled.div({
    /* These styles are suggested for the table fill all available space in its containing element */
    // display: 'block',
    /* These styles are required for a horizontaly scrollable table overflow */

    '& .table': {
        borderSpacing: 0,
        '.th': {
            color: style.colors.secondary,
            padding: `0px 16px ${style.margin.m}px 16px`,
            fontSize: style.fontSize.epsilon,

            /* These styles are required for a scrollable body to align with the header properly */
            overflowX: 'hidden'
        },

        '.td': {
            borderTop: `1px solid ${style.colors.black50}`,
            fontSize: style.fontSize.centi,
            padding: '16px 16px',
            overflowX: 'hidden'
        },

        '.tbody': {
            /* These styles are required for a scrollable table body */
            overflowX: 'hidden'
        },

        '.th, .td': {
            margin: 0,
            /* In this example we use an absolutely position resizer,
       so this is required. */

            position: 'relative',
            ':last-child': {
                paddingRight: '0px'
            }
        },
        '.td .tr div:first-of-type': {
            paddingLeft: '0px'
        }
    }
});

const getStyles = (
    props,
    style: {
        justifyContent?: string;
        alignItems?: string;
        display?: string;
    }
) => [
    props,
    {
        style: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            display: 'flex',
            ...style
        }
    }
];

const headerProps = (props, { column }) => getStyles(props, column.align);

const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

export type Props = {
    columns: Column<object>[];
    data?: Record<string, unknown>[];
    filterArea?: ReactNode;
};

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center'
});

const ReactTable = ({ columns, data, filterArea, ...rest }: Props & React.TableHTMLAttributes<HTMLTableElement>) => {
    const dataMemo = React.useMemo(() => (data ? data : []), [data]);
    const columnsMemo = React.useMemo<readonly Column<object>[]>(() => [...columns], [data, columns]);

    const defaultColumn = React.useMemo(
        () => ({
            // width: 150, // width is used for both the flex-basis and flex-grow
        }),
        []
    );

    const { getTableProps, headerGroups, rows, prepareRow, state, setGlobalFilter } = useTable(
        {
            columns: columnsMemo,
            data: dataMemo,
            defaultColumn
        },
        useGlobalFilter,
        useSortBy,
        useFlexLayout
    );

    return (
        <Styles>
            <div
                style={{
                    justifyContent: filterArea ? 'space-between' : 'end',
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    paddingBottom: '2rem'
                }}
            >
                {filterArea}
                <div>
                    <GlobalFilter placeholder="Sök" filter={state.globalFilter} setFilter={setGlobalFilter} />
                </div>
            </div>
            <div {...getTableProps()} className="table">
                <div>
                    {headerGroups.map(headerGroup => (
                        <div
                            {...headerGroup.getHeaderGroupProps({
                                // style: { paddingRight: '15px' },
                            })}
                            className="tr"
                            key={headerGroup.getFooterGroupProps().key}
                        >
                            {headerGroup.headers.map(column => (
                                <div
                                    {...column.getHeaderProps(column.getSortByToggleProps(headerProps))}
                                    className="th"
                                    key={column.getHeaderProps().key}
                                >
                                    <FlexContainer>
                                        {column.render('Header')}
                                        {column.canSort && (
                                            <SortArrows
                                                activeSort={
                                                    column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : undefined
                                                }
                                            />
                                        )}
                                    </FlexContainer>
                                    {/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="tbody">
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr" key={row.getRowProps().key}>
                                {row.cells.map(cell => {
                                    return (
                                        <div
                                            {...cell.getCellProps(cellProps)}
                                            className="td"
                                            key={cell.getCellProps().key}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Styles>
    );
};

export default ReactTable;
