import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import { useBasket } from '../../binders/hooks/useBasket';
import { useGetProjects } from '../../binders/hooks/projectHooks/projectQueries';
import GenericTable from '../../binders/components/GenericTable';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import columns from '../../binders/components/Products/CopyToColumns';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useGetProductsByIds } from '../../binders/hooks/productQueries';
import useCopyProduct from '../../binders/hooks/CopyFunctionHooks/useCopyProduct';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import styled from '@emotion/styled';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import DropdownContainer from '../../binders/components/emotion/DropdownContainer';
import CopyToPropertiesList from '../../binders/components/CopyToPropertiesList/CopyToPropertiesList';
import copyToPropertiesColumn from '../../binders/components/CopyToPropertiesList/productColumns';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Exchange from '../../../components/Icon/themed/Exchange';
import FormatOptionsLabel from '../../binders/components/FormatOptionsLabel';
import CopyToSpacesList from '../../binders/components/CopyToSpacesList';
import { Button } from '@ourliving/ourliving-ui';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import Row from '../../binders/components/emotion/Row';
import { useLocalStorage } from 'react-use';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import PaddedContainer from '../../binders/components/emotion/PaddedContainer';

const GridCopyContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(250px, 1fr) )',
    gap: '1rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        gridTemplateColumns: '1fr 3rem 1fr',
        gap: '2rem',
    },
    alignItems: 'center',
});

const StyledCardWithDivider = styled(CardWithDivider)({
    alignSelf: 'start',
});

const StyledExchange = styled(Exchange)({
    justifySelf: 'center',
});

const CopyToPage = () => {
    const [storedIds, setStoredIds] = useBasket();
    const { data: projects } = useGetProjects();
    const { data, isLoading } = useGetProductsByIds(storedIds);
    const [spaceSearch, setSpaceSearch] = useState('');
    const [propertiesSearch, setPropertiesSearch] = useState('');

    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.filter((data) => storedIds.includes(data.id));
    }, [storedIds, data]);
    const clearRows = () => {
        setStoredIds([]);
    };

    const [localStorageValue, setLocalStorageValue] = useLocalStorage<{
        selectedProperties: Record<string, boolean>;
        selectedSpaces: Record<string, boolean>;
        project: { value: string; label: string } | null;
    }>('copyToPageSelections', {
        selectedProperties: {},
        selectedSpaces: {},
        project: null,
    });

    const [columnVisibilityState, setColumnVisibilityState] = useLocalStorage<Record<string, boolean>>(
        'copyToPageColumnVisibilityState',
        { image: false },
    );

    const setSelectedProperties = (val: Record<string, boolean>) => {
        setLocalStorageValue({
            selectedProperties: val,
            selectedSpaces: localStorageValue?.selectedSpaces ?? {},
            project: localStorageValue?.project ?? null,
        });
    };

    const setSelectedSpaces = (val: Record<string, boolean>) =>
        setLocalStorageValue({
            selectedProperties: localStorageValue?.selectedProperties ?? {},
            selectedSpaces: val,
            project: localStorageValue?.project ?? null,
        });

    const setProject = (val: { value: string; label: string } | null) =>
        setLocalStorageValue({
            selectedProperties: {},
            selectedSpaces: {},
            project: val,
        });

    const updateProducts = useCopyProduct();

    const removeRow = (id: number) => {
        const newIds = storedIds.filter((storedId) => storedId !== id);
        setStoredIds(newIds);
    };

    const [numberOfProperties, setNumberOfProperties] = useState<number | null>(null);
    const [numberOfSpaces, setNumberOfSpaces] = useState<number | null>(null);

    const table = useReactTable({
        data: filteredData,
        columns,
        state: { columnVisibility: columnVisibilityState },
        onColumnVisibilityChange: setColumnVisibilityState,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
        manualSorting: true,
        sortDescFirst: false,
        enableSortingRemoval: true,
        meta: {
            removeRow,
            clearRows,
        },
    });

    const selectedProducts = table.getSelectedRowModel().flatRows.map((item) => item.original.id);

    const beginMove = () => {
        if (
            !localStorageValue?.selectedProperties ||
            (localStorageValue?.selectedProperties && Object.keys(localStorageValue.selectedProperties).length < 0) ||
            !localStorageValue?.selectedSpaces ||
            !selectedProducts ||
            selectedProducts.length < 1
        )
            return toast.error(L('no_items_to_copy'));
        const spaceIds = Object.keys(localStorageValue.selectedSpaces).map((space) => +space);
        updateProducts.mutate({ productIds: selectedProducts, spaceIds });
    };

    const projectOption =
        projects?.map((option) => ({
            value: String(option.id),
            label: String(option.name),
            template: option.template,
        })) ?? [];

    if (isLoading) return <Loading />;

    return (
        <div style={{ display: 'grid', rowGap: '1.5rem' }}>
            <StyledCardWithDivider
                topArea={
                    <TopAreaContainer style={{ justifyContent: 'space-between' }}>
                        <CardTitle>
                            {L('product_basket')} {data ? `(${data.length})` : ''}
                        </CardTitle>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <PaddedContainer
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    columnGap: '0.5rem',
                                    alignItems: 'center',
                                }}
                            >
                                {`${L('show_with_image')}:`}
                                <Checkbox
                                    checked={table.getColumn('image')?.getIsVisible()}
                                    onChange={table.getColumn('image')?.getToggleVisibilityHandler()}
                                />
                            </div>
                        </PaddedContainer>
                        <GenericTable table={table} />

                        {filteredData?.length === 0 && data && (
                            <EmptyState>
                                <EmptyState.Title>{L('no_products_chosen')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_products_chosen_description')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        )}
                    </>
                }
            />
            <GridCopyContainer>
                <StyledCardWithDivider
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CardTitle>
                                {L('properties')} {numberOfProperties !== null ? `(${numberOfProperties})` : ''}
                            </CardTitle>

                            <FilterSearchContainer>
                                <Row>
                                    <GlobalFilter
                                        filter={propertiesSearch}
                                        setFilter={setPropertiesSearch}
                                        placeholder={L('search')}
                                    />
                                </Row>
                            </FilterSearchContainer>

                            <DropdownContainer>
                                <ReactSelect
                                    placeholder={L('project')}
                                    options={projectOption}
                                    value={localStorageValue?.project ?? null}
                                    onChange={(value) => {
                                        setProject(value);
                                    }}
                                    isSearchable={true}
                                    width={'140px'}
                                    formatOptionLabel={FormatOptionsLabel}
                                />
                            </DropdownContainer>
                        </div>
                    }
                    mainArea={
                        <>
                            <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                {localStorageValue && (
                                    <CopyToPropertiesList
                                        projectId={
                                            localStorageValue.project?.value ? +localStorageValue.project.value : null
                                        }
                                        handleSetSelectedProperties={(val) => {
                                            setSelectedSpaces({});
                                            setSelectedProperties(val);
                                        }}
                                        handleSetNumberOfProperties={(val: number | null) => setNumberOfProperties(val)}
                                        selectedProperties={localStorageValue.selectedProperties}
                                        columns={copyToPropertiesColumn}
                                        search={propertiesSearch}
                                    />
                                )}
                            </div>
                        </>
                    }
                />
                <StyledExchange />
                <StyledCardWithDivider
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardTitle>
                                {L('spaces')} {numberOfSpaces !== null ? `(${numberOfSpaces})` : ''}
                            </CardTitle>

                            <FilterSearchContainer>
                                <Row>
                                    <GlobalFilter
                                        filter={spaceSearch}
                                        setFilter={setSpaceSearch}
                                        placeholder={L('search')}
                                    />
                                </Row>
                            </FilterSearchContainer>

                            <Button
                                onClick={() =>
                                    localStorageValue?.selectedProperties &&
                                    Object.keys(localStorageValue.selectedProperties).length > 0
                                        ? beginMove()
                                        : null
                                }
                                disabled={
                                    !localStorageValue?.selectedProperties ||
                                    Object.keys(localStorageValue.selectedProperties).length === 0 ||
                                    !localStorageValue?.selectedSpaces ||
                                    selectedProducts.length === 0 ||
                                    Object.keys(localStorageValue?.selectedSpaces).length === 0
                                }
                            >
                                {L('copy')}
                            </Button>
                        </div>
                    }
                    mainArea={
                        <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                            {localStorageValue && (
                                <CopyToSpacesList
                                    handleSetSelectedSpaces={(val) => {
                                        setSelectedSpaces(val);
                                    }}
                                    propertyIds={
                                        localStorageValue.selectedProperties
                                            ? Object.keys(localStorageValue.selectedProperties).map((item) => +item)
                                            : []
                                    }
                                    handleSetNumberOfSpaces={(val: number | null) => setNumberOfSpaces(val)}
                                    spaceSearch={spaceSearch}
                                    selectedSpaces={localStorageValue.selectedSpaces}
                                />
                            )}
                        </div>
                    }
                />
            </GridCopyContainer>
        </div>
    );
};

export default CopyToPage;
