import React, { useMemo } from 'react';
import { L } from '../../../../lib/i18n';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardTitle from '../../../../components/typography/CardTitle';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import PropertyForm from '../../components/Properties/PropertyForm';
import { AddProperty } from '../../schemas';
import { useGetPropertyTypes } from '../../hooks/propertyHooks/propertyQueries';
import { SubmitHandler } from 'react-hook-form';
import useSaveProperty from '../../hooks/propertyHooks/useSaveProperty';
import toast from 'react-hot-toast';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import H1 from '../../../../components/typography/H1';

export type PropertyTypesOptions = {
    value: string;
    label: string;
}[];

const AddPropertyComponent = ({ id, template }: { id: string; template: boolean }) => {
    const navigate = useNavigate();

    const { data: propertyTypes } = useGetPropertyTypes();

    const { mutate: saveProperty } = useSaveProperty();

    const propertyTypesOptions = useMemo<PropertyTypesOptions>(() => {
        if (!propertyTypes) return [];
        return propertyTypes?.map((item) => ({ value: item.id, label: L(item.name) }));
    }, [propertyTypes]);

    const onSubmit: SubmitHandler<AddProperty> = (data) => {
        const dataToSave = { ...data, template };
        saveProperty(
            { property: dataToSave, projectId: +id },
            {
                onSuccess: () => {
                    template ? toast.success(L('added_template')) : toast.success(L('added_property'));
                    navigate('..');
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const project = useGetProjectName(projectId);

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{project ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('new_property_template')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project ?? L('project_literally')}</H1>
            <CardWithDivider
                topArea={<CardTitle>{template ? L('add_property_template') : L('add_property')}</CardTitle>}
                mainArea={<PropertyForm onSubmit={onSubmit} propertyTypesOptions={propertyTypesOptions} />}
            />
        </div>
    );
};

const AddPropertyPage = ({ template = false }: { template?: boolean }) => {
    const { id } = useParams<{ id: string }>();
    return id ? <AddPropertyComponent id={id} template={template} /> : null;
};

export default AddPropertyPage;
