import { useMutation, useQueryClient } from 'react-query';
import { getSupplierKeys } from './supplierQueries';
import { trpc } from '../../../../lib/trpc';

const useDeleteSuppliers = () => {
    const client = useQueryClient();
    return useMutation(
        async (userIds: number[]) => {
            return await Promise.all(
                userIds.map(async (id) => {
                    return trpc.SupplierApi.deleteSupplier.query(id);
                }),
            );
        },
        {
            onSettled: () => {
                client.invalidateQueries(getSupplierKeys.lists());
            },
        },
    );
};

export default useDeleteSuppliers;
