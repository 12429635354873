import React from 'react';
import styled from '@emotion/styled';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { Filters } from '../../../components/Filters/Filters';
import { L } from '../../../lib/i18n';
import { SelectOption } from '../DataType';

const DropdownContainer = styled.div({
    display: 'flex',
    columnGap: '0.625rem',
});

type Props<T extends Record<string, { value: string; label: string }[]>> = {
    options: { name: string; value: string; newValue: string; id: number }[];
    filterOptions: T;
    selected: {
        [key: string]: SelectOption[];
    };
    setSelected: {
        [key: string]: (value: SelectOption[]) => void;
    };
};

const TableFilter = <T extends Record<string, { value: string; label: string }[]>>({
    options,
    filterOptions,
    selected,
    setSelected,
}: Props<T>) => {
    return (
        <Filters marginBottom={'0'} marginRight={'0'}>
            <DropdownContainer>
                {options.map((option) => (
                    <ReactSelect
                        key={option.id}
                        closeMenuOnSelect={false}
                        placeholder={L(option.name)}
                        controlShouldRenderValue={false}
                        isMulti
                        hideSelectedOptions={false}
                        options={filterOptions[option.value]}
                        value={selected[option.value]}
                        onChange={(multiValues) => setSelected[option.newValue]([...multiValues])}
                        isSearchable={true}
                    />
                ))}
            </DropdownContainer>
        </Filters>
    );
};

export default TableFilter;
