import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi, { Maintenance } from '@app/api/public/MaintenancesApi';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { SaveMaintenance } from '@app/api/models/Maintenances';
import { useParams } from 'react-router-dom';

type Variables = Partial<SaveMaintenance>;

const saveMaintenance = async (projectId: string, variables: Variables) => {
    return await MaintenancesApi.save(+projectId, variables);
};

const useSaveMaintenance = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ projectId: string }>();

    return useMutation((variables: Variables) => saveMaintenance(projectId, variables), {
        onSuccess: response => {
            const maintenanceArr = queryClient.getQueryData<Maintenance[]>(getMaintenanceQueryKey);
            if (maintenanceArr) {
                queryClient.setQueryData(getMaintenanceQueryKey, [...maintenanceArr, response]);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
        }
    });
};

export default useSaveMaintenance;
