import { useQuery } from 'react-query';
import { trpc } from '../../../lib/trpc';

export const getDocumentTypes = {
    all: ['document_types'] as const,
    lists: () => [...getDocumentTypes.all, 'list'] as const,
    list: (type: 'document_types' | 'document_subtypes') => [...getDocumentTypes.lists(), type] as const
};

export function useGetDocumentTypes() {
    return useQuery(
        [...getDocumentTypes.list('document_types')],
        async () => trpc.DocumentsApi.listDocumentTypes.query(),
        {
            keepPreviousData: true
        }
    );
}

export function useGetDocumentSubtypes() {
    return useQuery(
        [...getDocumentTypes.list('document_subtypes')],
        async () => trpc.DocumentsApi.listDocumentSubTypes.query(),
        {
            keepPreviousData: true
        }
    );
}
