import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyTemplatesKeys } from '../../../property-templates/hooks/propertyTemplateQueries';
import { AddPropertyTemplate } from '../../../property-templates/PropertyTemplates/PropertyTemplateForm';

export default function useUpdatePropertyTemplate() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ data, templateId }: { data: AddPropertyTemplate; templateId: number }) => {
            return trpc.TemplatesApi.updatePropertyTemplate.mutate({ property: data, propertyId: templateId });
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(getPropertyTemplatesKeys.lists());
                queryClient.invalidateQueries(getPropertyTemplatesKeys.detail(data.templateId));
            },
        },
    );
}
