import React, { ReactNode, useEffect, useState } from 'react';
import DocumentItem from './DocumentItem';
import { style } from '../../Shared/Style';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { colors } from '../../Shared/Style/colors';
import TrashCan from '../../Icon/themed/TrashCan';
import Edit from '../../Icon/themed/Edit';
import Download from '../../Icon/themed/Download';
import Copy from '../../Icon/themed/Copy';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import Label from '../../Forms/Label';
import Input from '../../Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import { createPortal } from 'react-dom';
import styled from '@emotion/styled';
import Dialog from '../../Dialog/Dialog';
import { Close } from '@radix-ui/react-dialog';
import { Controller, useForm } from 'react-hook-form';
import RadixSelect from '../../RadixSelect/RadixSelect';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Folder from '../../Icon/themed/Folder';
import ErrorAndInputWrapper from '../../Forms/ErrorAndInputWrapper';

const DropDownContent = styled(DropdownMenu.Content)({
    padding: '0.5rem',
    backgroundColor: colors.white,
    color: colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});

const FolderIcon = styled(Folder)({
    height: '1.3rem',
    width: '1.3rem',
});

const DeleteIcon = styled(TrashCan)({
    height: '1.3rem',
    width: '1.3rem',
});
const EditIcon = styled(Edit)({
    height: '1.3rem',
    width: '1.3rem',
});

const DownloadIcon = styled(Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const CopyIcon = styled(Copy)({
    height: '1.3rem',
    width: '1.3rem',
});

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const StyledItem = styled(DropdownMenu.Item)(() => ({
    '&:focus-visible': {
        outline: `${colors.primary50} auto 1px`,
    },
}));

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2rem',
    gap: '0.5rem',
});

const StyledTrigger = styled(DropdownMenu.Trigger)(() => ({
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0px',
    margin: '0px',
    display: 'block',
    cursor: 'pointer',
    '&:focus-visible': {
        outline: `${colors.primary50} auto 1px`,
    },
    '&:focus, &:hover': {
        outline: `${colors.primary50} auto 1px`,
    },
}));

type Props = {
    deleteDocument?: () => void;
    editDocumentName?: (name: string) => void;
    documentGroup?: {
        documentGroups: { name: string; id: number }[];
        switchGroup: (groupId: number) => unknown;
    };
    dropdownItems?: ReactNode[];
    url?: string;
    name: string;
    fileType: string;
};

const groupSchema = z.object({
    group: z.number({ required_error: 'error_required' }),
});

const DocumentDropdown = ({ url, name, fileType, deleteDocument, editDocumentName, documentGroup, ...rest }: Props) => {
    const handleCopy = async () => {
        if (!url) return;
        await navigator?.clipboard?.writeText(url);
        toast(`url ${L('copied_to_clipboard')}`);
    };
    const handleDownload = async () => {
        if (!url) return;

        window.open(url);
    };

    const [editName, setEditName] = useState(name);

    const [nameOpen, setNameOpen] = useState(false);
    const [groupOpen, setGroupOpen] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<z.infer<typeof groupSchema>>({
        resolver: zodResolver(groupSchema),
        defaultValues: {
            group: undefined,
        },
    });

    return (
        <>
            {editDocumentName && (
                <Dialog open={nameOpen} onOpenChange={(state) => setNameOpen(state)}>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            setNameOpen(false);
                            editDocumentName(editName);
                        }}
                    >
                        <Label title={L('edit_name')}>
                            <Input value={editName} onChange={(e) => setEditName(e.target.value)} />
                        </Label>
                        <ButtonWrapper>
                            <Close asChild>
                                <Button type="button">{L('cancel')}</Button>
                            </Close>
                            <Button>{L('save')}</Button>
                        </ButtonWrapper>
                    </form>
                </Dialog>
            )}

            {documentGroup && (
                <Dialog open={groupOpen} onOpenChange={(state) => setGroupOpen(state)}>
                    <form
                        onSubmit={handleSubmit(({ group }) => {
                            documentGroup.switchGroup(group);
                        })}
                    >
                        <ErrorAndInputWrapper errorMsg={L(errors.group?.message || '')}>
                            <Label title={L('select_document_group')}>
                                <Controller
                                    name="group"
                                    control={control}
                                    render={({ field }) => {
                                        return (
                                            <RadixSelect
                                                options={documentGroup.documentGroups.map((group) => ({
                                                    label: group.name,
                                                    value: group.id,
                                                }))}
                                                onValueChange={(value) => field.onChange(+value)}
                                                value={field.value}
                                                defaultValue={L('document_group_input')}
                                            />
                                        );
                                    }}
                                />
                            </Label>
                        </ErrorAndInputWrapper>
                        <ButtonWrapper>
                            <Close asChild>
                                <Button type="button">{L('cancel')}</Button>
                            </Close>
                            <Button>{L('save')}</Button>
                        </ButtonWrapper>
                    </form>
                </Dialog>
            )}

            <DropdownMenu.Root modal={false}>
                <StyledTrigger {...rest}>
                    <DocumentItem name={name} fileType={fileType} className={'document'} />
                </StyledTrigger>

                <DropdownMenu.Portal>
                    <DropDownContent sideOffset={1}>
                        {editDocumentName && (
                            <>
                                <StyledItem onClick={() => setNameOpen(true)}>
                                    <ContextItem>
                                        <EditIcon />

                                        <span>{L('edit_name')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {documentGroup && (
                            <StyledItem onClick={() => setGroupOpen(true)}>
                                <ContextItem>
                                    <FolderIcon />
                                    <span>{L('switch_document_group')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}
                        {url && navigator?.clipboard && (
                            <>
                                <StyledItem onClick={() => handleCopy()}>
                                    <ContextItem>
                                        <CopyIcon />
                                        <span>{L('copy')}</span>
                                    </ContextItem>
                                </StyledItem>
                                <StyledItem onClick={handleDownload}>
                                    <ContextItem>
                                        <DownloadIcon />
                                        <span>{L('download')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {deleteDocument && (
                            <StyledItem onClick={() => deleteDocument()}>
                                <ContextItem>
                                    <DeleteIcon />
                                    <span>{L('remove')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}

                        <StyledArrow />
                    </DropDownContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </>
    );
};

type DropdownItemProps = {
    Icon: ReactNode;
    Name: ReactNode;
    onClick: () => unknown;
};

const DropdownItem = ({ Icon, Name, onClick }: DropdownItemProps) => {
    return (
        <StyledItem onClick={onClick}>
            <ContextItem>
                {Icon}
                {Name}
            </ContextItem>
        </StyledItem>
    );
};

export { DocumentDropdown as default, DropdownItem };
