import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Container = styled.div(
    (props: {
        background: string;
        width: string;
        margin: string;
        padding: any;
        justifyContent: string;
        alignItems: string;
    }) => ({
        borderRadius: style.roundCornerSize.small,
        padding: props.padding,
        background: props.background,
        width: props.width,
        display: 'flex',
        flexDirection: 'column',
        margin: props.margin,
        alignItems: props.alignItems,
        justifyContent: props.justifyContent,
        ['@media screen and (max-width: 1024px)']: {
            padding: '0px',
        }
    })
);

interface Props extends React.ComponentProps<'div'> {
    background?: string;
    children?: any;
    width?: string;
    margin?: string;
    padding?: any;
    alignItems?: string;
    justifyContent?: string;
} 

export const Card = (props: Props) => {
    return (
        <Container
            background={props.background || ''}
            width={props.width || ''}
            margin={props.margin || ''}
            padding={props.padding || style.margin.l}
            alignItems={props.alignItems || ''}
            justifyContent={props.justifyContent || ''}
            {...props}
        >
            {props.children}
        </Container>
    );
};
