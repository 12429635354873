import * as React from 'react';
import { Route, Routes, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { useGetContentOrgInformationItem } from '../project-templates/hooks/ContentInformation/contentInformationQueries';
import ContentInformationPage from './pages/ContentInformationPage';
import AddContentInformationPage from './pages/AddContentInformationPage';
import ContentInformationDetailPage from './pages/ContentInformationDetailPage';
import ContentDraftInformationPage from './pages/ContentDraftInformationPage';

const ContentInfoName = () => {
    const { id } = useParams<{ id: string }>();
    const Name = ({ contentInfoId }: { contentInfoId: string }) => {
        const { data: contentInformation, isLoading } = useGetContentOrgInformationItem({
            contentInfoId: +contentInfoId,
        });
        if (isLoading) return <></>;
        return contentInformation?.title ? <>{contentInformation.title}</> : <>{L('content_information')}</>;
    };

    return id ? <Name contentInfoId={id} /> : <>{L('content_information')}</>;
};

const BreadcrumbsNavigation = () => {
    return (
        <Routes>
            <Route path="information-settings">
                <Route index element={null} />
                <Route path="new">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('content_information_plural')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{L('new')}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                </Route>
                <Route path="draft">
                    <Route path=":id">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="..">{L('content_information_plural')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>
                                        <ContentInfoName />
                                    </Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                    </Route>
                </Route>
                <Route path=":id">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('content_information_plural')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>
                                    <ContentInfoName />
                                </Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
};

export const InformationSettings = () => {
    return (
        <>
            <BreadcrumbsNavigation />
            <Routes>
                <Route path="information-settings">
                    <Route index element={<ContentInformationPage />} />
                    <Route path="draft" element={<ContentDraftInformationPage />} />
                    <Route path="new" element={<AddContentInformationPage />} />

                    <Route path=":id" element={<ContentInformationDetailPage />} />
                    <Route path="draft/:id" element={<ContentInformationDetailPage />} />
                </Route>
            </Routes>
        </>
    );
};
