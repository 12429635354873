import { Dimension } from '@app/api/lib/resize';
import DocumentsApi, { DocumentDetail } from '@app/api/public/DocumentsApi';
import { useQueries, useQuery } from 'react-query';
import { trpc } from '../trpc';

const useGetSignedUrlByIdQueryKey = 'getSignedUrlById';

export const signedUrlByIdQueryKeys = {
    all: ['getSignedUrlById'],
    byId: (id?: string, dimension?: Dimension) => ['getSignedUrlById', { id, dimension }],
};

const getSignedUrl = (id?: string, dimension?: Dimension) => {
    if (!id) return Promise.reject('no id');

    return DocumentsApi.getSignedUrl(`${dimension ? dimension + '/' : ''}${id}`);
};

const useGetSignedUrlById = (id?: string, dimension?: Dimension) => {
    return useQuery(
        [useGetSignedUrlByIdQueryKey, `${dimension ? dimension + '/' : ''}${id}`],
        () => {
            if (!id) return Promise.reject('no id');
            return trpc.DocumentsApi.getSignedUrlStore.query({ key: id, dimensions: dimension });
        },
        {
            enabled: !!id,
            keepPreviousData: true,
        },
    );
};

const getSignedUrlsStore = async (documents?: DocumentDetail[], dimension?: Dimension) => {
    if (!documents?.length) return;
    const responses = await Promise.all(
        documents.map(async (document) => {
            return await DocumentsApi.getSignedUrlStore(
                `${dimension ? dimension + '/' : ''}${document?.document_data?.original?.id}`,
            );
        }),
    );

    return responses.filter((id) => !!id);
};

const useGetSignedUrls = (documents?: DocumentDetail[], dimension?: Dimension) => {
    return useQuery(
        [useGetSignedUrlByIdQueryKey, `${dimension ? dimension + '/' : ''}`, { documents }],
        () => {
            return getSignedUrlsStore(documents, dimension);
        },
        {
            enabled: !!documents?.length,
        },
    );
};

const getSignedUrlsStoreWithDocId = async (documents?: DocumentDetail[], dimension?: Dimension) => {
    if (!documents?.length) return;
    const responses = await Promise.all(
        documents.map(async (document) => {
            const signedUrl = await DocumentsApi.getSignedUrlStore(
                `${dimension ? dimension + '/' : ''}${document?.document_data?.original?.id}`,
            );
            return { id: document.id, signedUrl: signedUrl };
        }),
    );

    return responses.filter((id) => !!id);
};

const useGetSignedUrlsWithDocId = (documents?: DocumentDetail[], dimension?: Dimension) => {
    return useQuery(
        [useGetSignedUrlByIdQueryKey, `${dimension ? dimension + '/' : ''}`, { documents }],
        () => {
            return getSignedUrlsStoreWithDocId(documents, dimension);
        },
        {
            enabled: !!documents?.length,
        },
    );
};

export { useGetSignedUrlById, useGetSignedUrls, useGetSignedUrlsWithDocId };
const useGetSignedUrlByIds = (ids?: string[], dimension?: Dimension) => {
    return useQueries(
        ids?.map((id) => {
            return {
                queryKey: signedUrlByIdQueryKeys.byId(id, dimension),
                queryFn: () => getSignedUrl(id, dimension),
            };
        }) || [],
    );
};

export { useGetSignedUrlByIds, useGetSignedUrlById as default };
