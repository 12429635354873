import { TicketArchiveItem, TicketFilter } from '@app/api/models/Tickets';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { colors } from '../../../components/Shared/Style/colors';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useGetInfiniteTicketsArchive } from '../hooks/ticketQueries';
import TicketFilters from './TicketArchiveFilters';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import {
    SortingState,
    createColumnHelper,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    Row as RowType,
} from '@tanstack/react-table';
import { useLocalStorage } from 'react-use';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import GenericTableVirtualized from '../../binders/components/GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import ContentContainer from '../../binders/components/emotion/ContentContainer';
import PaddedContainer from '../../binders/components/emotion/PaddedContainer';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import Row from '../../binders/components/emotion/Row';

const StyledTicketFilters = styled(TicketFilters)({
    margin: '0px',
});

const TopAreaContainer = styled.div({
    display: 'flex',
    minHeight: '35px',
    alignItems: 'center',
});

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const ColorContainer = styled(CellContainer)(({ boxcolor }: { boxcolor: string }) => {
    return {
        borderLeft: `6px solid ${boxcolor}`,
        paddingLeft: '1rem',
    };
});

const columnHelper = createColumnHelper<TicketArchiveItem>();

const columns = [
    columnHelper.accessor((row) => row.title, {
        id: 'title',
        header: () => {
            return `${L('title')} & ${L('description')}`;
        },
        cell: ({ row }) => {
            return (
                <ColorContainer boxcolor={colors.alert_medium}>
                    <Data>{row.original.title}</Data>
                    <Data style={{ fontSize: '0.8em' }}>{row.original.description.plain_text}</Data>
                </ColorContainer>
            );
        },
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => row.property_name, {
        header: () => {
            return L('property');
        },
        id: 'property_name',
        cell: (info) => {
            return (
                <CellContainer>
                    <Data style={{ fontSize: '0.8em' }}>{info.row.original.project_name}</Data>
                    <Data>{info.getValue()}</Data>
                </CellContainer>
            );
        },
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => row.assigned_name, {
        header: () => {
            return L('assignee');
        },
        id: 'assigned_name',
        cell: ({ row }) => {
            const ticket = row.original;
            return (
                <CellContainer>
                    <Data>{ticket.assigned_name}</Data>
                </CellContainer>
            );
        },
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => dayjs(row.archived_at).valueOf(), {
        header: () => {
            return L('archived');
        },
        id: 'archived_at',
        cell: ({ row }) => {
            const ticket = row.original;
            return (
                <CellContainer>
                    <Data>{dayjs(ticket.archived_at).format('ddd DD MMM YYYY')}</Data>
                </CellContainer>
            );
        },
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
];

const TicketsArchive = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const [localStorageValue, setLocalStorageValue] = useLocalStorage(
        'ticketsArchiveListFilters',
        searchParams.toString() ?? '',
    );

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else setSearchParams([]);
        setLocalStorageValue(() => '');
    };

    const updateFilter = <T extends keyof TicketFilter>(key: T, value: number) => {
        setSearchParams((searchParams) => {
            if (value) searchParams.set(key, String(value));
            else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
        setLocalStorageValue(() => newUrlParams.toString());
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (!localStorageValue || !isInitialRender) return;
        if (window.location.search) {
            setIsInitialRender(false);
            return;
        }
        setSearchParams(localStorageValue);
        setIsInitialRender(false);
    }, [localStorageValue, searchParams]);

    const [sorting, setSorting] = useState<SortingState>([]);

    // Get relevant params from URL
    const project_id = searchParams.get('project_id');
    const property = searchParams.get('property');
    const assignee = searchParams.get('assignee');
    const status_id = searchParams.get('status_id');
    const priority_id = searchParams.get('priority_id');

    const filter = {
        project_id: project_id ? Number(project_id) : undefined,
        property: property ? Number(property) : undefined,
        assignee: assignee ? Number(assignee) : undefined,
        status_id: status_id ? Number(status_id) : undefined,
        priority_id: priority_id ? Number(priority_id) : undefined,
    };

    const isFiltering = Object.values(filter).some((value) => !!value === true);

    const { fetchNextPage, hasNextPage, isFetching, data } = useGetInfiniteTicketsArchive({
        keepPreviousData: true,
        filter,
        searchQuery: searchField,
        sorting,
    });
    console.log(data);
    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);

    const totalDBRowCount = useMemo(() => Number(data?.pages?.[0]?.totalItems) ?? 0, [data]);
    const totalFetched = useMemo(() => flatData.length, [flatData]);

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableColumnResizing: false,
    });

    const handleClickRow = useCallback((row: RowType<TicketArchiveItem>) => {
        navigate(`${row.original.id}`);
    }, []);

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>{L('all_archived_tickets')}</CardTitle>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <FilterSearchContainer>
                        <ContentContainer>
                            <StyledTicketFilters
                                filter={filter}
                                handleFilter={updateFilter}
                                handleClearAll={handleClearAll}
                            />
                        </ContentContainer>
                        <PaddedContainer>
                            <Row>
                                <GlobalFilter
                                    filter={searchField ?? ''}
                                    setFilter={handleSearch}
                                    placeholder={L('search')}
                                />
                            </Row>
                        </PaddedContainer>
                    </FilterSearchContainer>

                    <GenericTableVirtualized
                        table={table}
                        handleFetchNextPage={() => fetchNextPage()}
                        hasNextPage={hasNextPage}
                        isFetching={isFetching}
                        totalDBRowCount={totalDBRowCount}
                        totalFetched={totalFetched}
                        estimateSize={50}
                        rowClickHandler={handleClickRow}
                    />
                    {flatData?.length === 0 && data && filter && !isFiltering && searchField === '' && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets_archived')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('no_tickets_archived_description')}</p>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                    {flatData?.length === 0 && data && filter && searchField && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets')}</EmptyState.Title>
                            <EmptyState.Description>
                                <span>{L('no_tickets_matched')}</span>{' '}
                                <span style={{ fontStyle: 'italic' }}>{searchField}</span>.
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                    {flatData?.length === 0 && data && filter && !searchField && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets')}</EmptyState.Title>
                            <EmptyState.Description>
                                <span>{L('no_tickets_matched_filters')}</span>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                </>
            }
        />
    );
};

export default TicketsArchive;
