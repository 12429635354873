import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getSpaceKeys } from './spaceQueries';

const useDeleteSpace = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ propertyId, spaceId }: { spaceId: number; propertyId: number }) => {
            return trpc.BindersApi.deleteSpace.query({ propertyId, spaceId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getSpaceKeys.lists());
            },
        },
    );
};

export default useDeleteSpace;
