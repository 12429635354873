import React, { useEffect, useState } from 'react';
import { Icon } from '../../../components/Icon/Icon';
import useGetServicePartnerCategories from '../hooks/useGetServicePartnerCategories';
import useSaveServicePartnerCategory from '../hooks/useSaveServicePartnerCategory';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Button } from '@ourliving/ourliving-ui';
import useGetMaintenanceCategories from '../hooks/useGetMaintenanceCategories';
import ServicePartnerCategoryItem from './ServicePartnerCategoryItem';
import { useParams } from 'react-router-dom';
import { L } from '../../../lib/i18n';
import H2 from '../../../components/typography/H2';
import styled from '@emotion/styled';

const Container = styled.div({
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    margin: 'auto',
    flexDirection: 'column',
    marginTop: '3rem'
});
const Form = styled.form({
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    placeItems: 'center',
    gap: '1rem'
});

const AddButton = styled(Button)({
    margin: '0px'
});

const ItemContainer = styled.div({
    marginTop: '1rem',
    placeSelf: 'center',
    gridTemplateColumns: '1fr 1fr',
    display: 'grid'
});

const ServicePartnerCagtegory = () => {
    const { id } = useParams<{ id: string }>();

    const [category, setCategory] = useState<number>();
    const { mutate: save } = useSaveServicePartnerCategory();
    const { data: categories } = useGetServicePartnerCategories(id);
    const { data: maintenanceCategories } = useGetMaintenanceCategories();

    const saveCategory = () => {
        if (category) {
            save({ servicePartnerId: +id, categoryId: category });
            setCategory(undefined);
        }
    };

    return (
        <Container>
            <H2>{L('category')}</H2>

            <Form
                onSubmit={e => {
                    e.preventDefault();
                    saveCategory();
                }}
            >
                <Dropdown
                    value={category}
                    label={'Kategori'}
                    options={
                        maintenanceCategories
                            ?.filter(
                                maintenanceC => !categories?.find(category => category.category_id === maintenanceC.id)
                            )
                            ?.map(c => {
                                return { value: c.id, label: c.name };
                            }) || []
                    }
                    onChange={value => setCategory(value)}
                />

                <AddButton variant={'primary'} disabled={!category} type="submit">
                    {L('add')}
                </AddButton>
            </Form>
            <ItemContainer>
                {categories?.map(item => {
                    return <ServicePartnerCategoryItem key={item.category_id} item={item} />;
                })}
            </ItemContainer>
        </Container>
    );
};

export default ServicePartnerCagtegory;
