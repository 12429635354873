import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { AddContentInformation } from '../../schemas';
import { ContentInformationQueryKeys } from './contentInformationQueries';
import { uploadToAws } from './uploadToAws';

export default function useUpdateContentInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            contentInfoId,
            information,
            projectId,
            file,
        }: {
            information: AddContentInformation;
            projectId: number;
            contentInfoId: number;
            file?: File;
        }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;
            const { id, preSingedUrl } = await trpc.ContentInformationApi.updateContentInformation.mutate({
                id: contentInfoId,
                projectId,
                title: information.title,
                description: information.description,
                file: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentInformation.mutate({
                            id,
                            projectId: projectId,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
                queryClient.invalidateQueries(
                    ContentInformationQueryKeys.detail({
                        contentInfoId: data.contentInfoId,
                        templateId: data.projectId,
                    }),
                );
            },
        },
    );
}

export function useUpdateContentOrgInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            contentInfoId,
            information,
            file,
        }: {
            information: AddContentInformation;
            contentInfoId: number;
            file?: File;
        }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;
            const { id, preSingedUrl } = await trpc.ContentInformationApi.updateContentOrgInformation.mutate({
                id: contentInfoId,
                title: information.title,
                description: information.description,
                file: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentOrgInformation.mutate({
                            id,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
                queryClient.invalidateQueries(ContentInformationQueryKeys.drafts());
                queryClient.invalidateQueries(ContentInformationQueryKeys.details());
            },
        },
    );
}

export function useUpdateDraftContentOrgInformation() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            contentInfoId,
            information,
            file,
        }: {
            information: AddContentInformation;
            contentInfoId: number;
            file?: File;
        }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;
            const { id, preSingedUrl } = await trpc.ContentInformationApi.updateDraftContentOrgInformation.mutate({
                id: contentInfoId,
                title: information.title,
                description: information.description,
                file: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentInformationApi.updateContentOrgInformation.mutate({
                            id,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.drafts());
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
                queryClient.invalidateQueries(ContentInformationQueryKeys.details());
            },
        },
    );
}
