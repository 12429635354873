import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';
import { matchPath, useLocation, useParams } from 'react-router-dom';

const projectInfoQueryKey = 'projectInfo';

const getProjectById = (projectId?: string) => {
    if (!projectId) throw new Error('No project id');
    return ProjectsApi.getById(+projectId);
};

const useGetProjectInfo = () => {
    const { projectId } = useParams<{ projectId: string }>();

    const path = useLocation();
    const match = matchPath<{ projectId?: string }>(path.pathname, {
        path: '/maintenance/:projectId'
    });

    const params = match?.params;

    return useQuery(
        [projectInfoQueryKey, { projectId: params?.projectId || projectId }],
        () => getProjectById(params?.projectId || projectId),
        {
            enabled: !!params?.projectId || !!projectId
        }
    );
};

export default useGetProjectInfo;
