import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../ContentInformation/uploadToAws';

export default function useUploadSpaceImagesTemplateProperty() {
    return useMutation(
        async ({ files, propertyId, spaceId }: { files: File[]; propertyId: number; spaceId: number }) => {
            const responses = files.map(async (file) => {
                const fileToSave = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                };

                const response = await trpc.TemplatesApi.uploadSpaceImagePropertyTemplate.mutate({
                    file: fileToSave,
                    propertyId,
                    spaceId,
                });

                if (response) {
                    await uploadToAws({
                        preSingedUrl: response.preSingedUrl,
                        file,
                        cleanup: async () => {
                            await trpc.TemplatesApi.removeSpaceImagePropertyTemplate.mutate({
                                id: response.id,
                                entityId: +spaceId,
                            });
                        },
                    });
                }
            });
            return await Promise.all(responses);
        },
    );
}
