import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { desktop } from '../MediaQuery/breakpoints';

type Props = {
    children?: any;
    isActive?: boolean;
    forwardRef?: any;
    width?: string;
    height?: string;
    onClose?: () => void;
} & HTMLAttributes<HTMLDivElement>;

const ModalContainer = styled.div({
    // width: 'calc(100% - 220px)',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    overflowY: 'auto',
    alignItems: 'center',
    [`@media screen and (max-width: ${desktop}px)`]: {
        width: '100%',
        left: 0
    }
});

const ModalContent = styled.div((props: { width?: string; height?: string }) => ({
    width: props.width ? props.width : undefined,
    height: props.height ? props.height : undefined,
    backgroundColor: 'white',
    borderRadius: '4px',
    textAlign: 'left',
    opacity: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'left',
    position: 'fixed',
    padding: style.margin.l,
    overflowY: 'auto'
}));

export const Modal = (p: Props) => {
    const preventPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    return (
        <>
            {p.isActive ? (
                <ModalContainer onClick={p.onClose}>
                    <ModalContent
                        onClick={preventPropagation}
                        width={p.width}
                        height={p.height}
                        ref={p.forwardRef}
                        {...p}
                    >
                        {p.children}
                    </ModalContent>
                </ModalContainer>
            ) : null}
        </>
    );
};
