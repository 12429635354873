import { useMutation } from 'react-query';
import { RouterInput, trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../../../project-templates/hooks/ContentInformation/uploadToAws';

export default function useUploadPropertyDocuments() {
    return useMutation(
        async ({
            files,
            propertyId,
            documentType,
        }: {
            files: File[];
            propertyId: number;
            documentType?: RouterInput['BindersApi']['uploadPropertyDocument']['documentType'];
        }) => {
            const responses = files.map(async (file) => {
                const fileToSave = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                };

                const response = await trpc.BindersApi.uploadPropertyDocument.mutate({
                    file: fileToSave,
                    propertyId,
                    documentType: documentType,
                });

                if (response) {
                    await uploadToAws({
                        preSingedUrl: response.preSingedUrl,
                        file,
                        cleanup: async () => {
                            await trpc.BindersApi.removePropertyDocument.mutate({
                                id: response.id,
                                propertyId,
                            });
                        },
                    });
                }
            });
            return await Promise.all(responses);
        },
    );
}
