import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Input from '../../../components/Forms/Input';
import { TextArea } from '@ourliving/ourliving-ui';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import {
    useGetAssignees,
    useGetInventoriesForSpace,
    useGetSettingsByType,
    useGetSpacesForProperty,
    useGetTicket,
} from '../hooks/ticketQueries';
import { SaveTicket } from '@app/api/models/Tickets';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import toast from 'react-hot-toast';
import useSaveTicket from '../hooks/useSaveTicket';
import InputSplit from '../../../components/Forms/InputSplit';
import { Button } from '@ourliving/ourliving-ui';
import formatOptionLabelColorBox from './formatOptionsLabel';
import useGetProjects from '../../../lib/hooks/useGetProjects';
import { colors } from '../../../components/Shared/Style/colors';
import { style } from '../../../components/Shared/Style';
import { useQueryClient } from 'react-query';
import H2 from '../../../components/typography/H2';
import { fontSize } from '@app/shared/lib/Style/typography';
import H1 from '../../../components/typography/H1';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';
import ProductPage from '../ProductPage';
import { Product } from '@app/api/models/Products';
import { Property } from '@app/api/models/Properties';
import * as Dialog from '@radix-ui/react-dialog';
import { SpringValue, animated } from 'react-spring';

type Ticket = Partial<SaveTicket>;

const SettingsWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    maxWidth: '100%',
    flexDirection: 'column',
    padding: '0.4rem 0.8rem',
    backgroundColor: colors.inputBackground,
    borderRadius: style.roundCornerSize.small,
});

const StyledTextArea = styled(TextArea)({
    color: colors.inputColor,
    fontSize: '80%',
});

const Wrapper = animated(
    styled.aside({
        padding: '0 0.5rem',
        width: '300px',
        height: '100%',
        background: 'rgba(0 0 0 / 0.5)',
        position: 'fixed',
        top: 0,
        right: 0,
        overflowY: 'auto',
        backgroundColor: '#F7F8FC',
        boxShadow: '0 0 35px gray',
        color: 'black',
        zIndex: 100,
    }),
);

const Form = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    height: '100%',
});

const ButtonWrapper = styled.div({
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'end',
    padding: style.margin.s,
    marginTop: 'auto',
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const padding = 9;

const StyledDialogClose = styled(Dialog.Close)(() => ({
    position: 'relative',
    color: colors.primary,
    background: 'inherit',
    border: 'none',
    cursor: 'pointer',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold,
    borderRadius: style.roundCornerSize.small,
    textDecoration: 'none',
    boxSizing: 'border-box',
    outline: 'none',
    padding: `${padding}px`,
    width: 'max-content',
    minWidth: '94px',

    '&.primary': {
        color: colors.primary,
        padding: `${padding - 1}px `,
        border: `1px solid ${colors.primary}`,
        '&:hover': {
            padding: `${padding - 2}px `,
            border: `2px solid ${colors.primary}`,
        },
    },
    '&.secondary': {
        color: colors.secondary,
        padding: `${padding - 1}px `,
        border: `1px solid ${colors.secondary}`,

        '&:hover': {
            padding: `${padding - 2}px `,
            border: `2px solid ${colors.secondary}`,
        },
    },
    '&.menu': {
        color: colors.primary,
    },
    '&.filledPrimary': {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover, &:active': { backgroundColor: colors.primary50 },
    },
    '&.selectedMenu': {
        backgroundColor: colors.primary,
        color: colors.white,
        '&:hover, &:active': { backgroundColor: colors.primary50 },
    },
    '&.remove': {
        backgroundColor: colors.black80,
        color: colors.black,
        '&:hover, &:active': { backgroundColor: colors.black50 },
    },
    '&.delete': {
        backgroundColor: colors.deleteButton,
        color: 'white',
        '&:hover, &:active': { backgroundColor: colors.deleteButtonHover },
    },
    '&.removeConfirm': {
        backgroundColor: colors.black80,
        color: 'white',
        '&:hover, &:active': { backgroundColor: colors.black50 },
    },

    '&:disabled, &:disabled:hover': {
        color: colors.black80,
        border: '1px solid',
        backgroundColor: 'inherit',
        borderColor: colors.black80,
        cursor: 'default',
        padding: `${padding - 1}px`,
    },
}));

type Props = {
    readOnly?: boolean;
    handleClose: () => void;
    cleanupFunc?: () => void;
    ticketId: string;
    slideIn: {
        transform: SpringValue<string>;
    };
};

const handleError = (isError?: boolean) => {
    return isError ? L('error_required') : undefined;
};

const TicketSideMenu = ({ readOnly = false, handleClose, cleanupFunc, ticketId, slideIn }: Props) => {
    const queryClient = useQueryClient();

    const { data } = useGetTicket(ticketId);

    useEffect(() => {
        return () => {
            if (cleanupFunc) {
                cleanupFunc();
            }
        };
    }, []);

    const [ticket, setTicket] = React.useState<Partial<SaveTicket>>({ ...data });
    const [errors, setErrors] = useState<Partial<Record<keyof SaveTicket, boolean>>>({});

    const handleChange = <T extends keyof Ticket>(key: T, value: Ticket[T]) => {
        if (readOnly) return;
        setErrors({});
        setTicket((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (!data) return;
        setTicket({ ...data });
    }, [data]);

    // Handle assignee
    const { data: assignee } = useGetAssignees();
    // Handle Projects
    const { data: projects } = useGetProjects('ticket');
    const projectsOptions = projects?.map((project) => ({ label: project.name, value: project.id })) || [];
    // Handle Priority
    const { data: priorities } = useGetSettingsByType('priority');
    const priorityOptions = priorities?.map((priority) => ({ label: priority.name, value: priority })) || [];
    // Handle status
    const { data: statuses } = useGetSettingsByType('status');
    const statusOptions = statuses?.map((status) => ({ label: status.name, value: status })) || [];
    // Handle Spaces
    const { data: spaces } = useGetSpacesForProperty(ticket.property_id?.toString());
    const [selectedSpace, setSeletedSpace] = React.useState<number | null>();
    // Handle inventory
    const { data: inventories } = useGetInventoriesForSpace(selectedSpace?.toString());

    const costumerCostOptions = [
        { label: L('residence'), value: true },
        { label: projectsOptions.find((project) => project.value === ticket.project_id)?.label || '', value: false },
    ];

    useEffect(() => {
        if (!data) return;
        setSeletedSpace(data.location_id || null);
    }, [data]);

    const [openProduct, setOpenProduct] = useState<React.ReactNode | undefined>();

    const handleClickProduct = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        productId: Product['id'],
        propertyId: Property['id'],
    ) => {
        e.stopPropagation();
        setOpenProduct(<ProductPage productId={productId} propertyId={propertyId} />);
    };
    const assigneeOptions = assignee?.map((p) => ({ label: p.name, value: p.id })) || [];
    const spacesOptions = spaces?.map((p) => ({ label: p.name, value: p.id })) || [];
    const inventoriesOptions =
        inventories?.map((inventory) => {
            const propertyId = ticket.property_id;
            return {
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{inventory.name}</span>

                        {propertyId && (
                            <span onClick={(e) => handleClickProduct(e, inventory.id, propertyId)}>Länk</span>
                        )}
                    </div>
                ),
                value: inventory.id,
            };
        }) || [];

    const { mutate: saveTicket } = useSaveTicket();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!ticket.title || !ticket.project_id || !ticket.description || !ticket.property_id) {
            setErrors({
                title: !ticket.title,
                description: !ticket.description,
                property_id: !ticket.property_id,
                project_id: !ticket.project_id,
            });
            return;
        }

        saveTicket(
            {
                ticket: {
                    ...ticket,
                    title: ticket.title,
                    description: ticket.description,
                    property_id: ticket.property_id,
                    project_id: ticket.project_id,
                },
            },
            {
                onSuccess: () => {
                    toast.success(`${L('ticket_saved')}`);
                    queryClient.invalidateQueries(ticketQueryKeys.listInfinite());
                    handleClose();
                },
                onError: (error) => {
                    if (error instanceof Error) {
                        toast.error(`${L(error.message)}`);
                    }
                },
            },
        );
    };

    const projectSelect = data ? (
        <ReactSelect isDisabled placeholder={data.project_name} />
    ) : (
        <ReactSelect
            placeholder={L('select_project')}
            isClearable
            value={projectsOptions.find((p) => p.value === ticket.project_id) ?? null}
            onChange={(e) => {
                handleChange('property_id', undefined);
                handleChange('project_id', e?.value);
            }}
            options={projectsOptions}
        />
    );

    return (
        <>
            <Dialog.Root
                open={!!openProduct}
                onOpenChange={(e) => {
                    if (!e) setOpenProduct(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{openProduct}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <Wrapper style={slideIn}>
                <Form onSubmit={handleSubmit}>
                    <div style={{ padding: '0 0.4rem' }}>
                        <H1 style={{ fontSize: fontSize.epsilon }}>{data?.title}</H1>
                        <H2 style={{ fontSize: fontSize.seta }}>{`#${data?.project_id} - ${data?.ticket_no}`}</H2>
                    </div>
                    <SettingsWrapper>
                        <div>
                            <Label title={L('title')} error={handleError(errors.title)}>
                                <Input
                                    readOnly={readOnly}
                                    value={ticket.title || ''}
                                    onChange={(e) => handleChange('title', e.target.value)}
                                    required
                                />
                            </Label>
                            <Label title={L('project')} error={handleError(errors.project_id)}>
                                {projectSelect}
                            </Label>
                            <Label title={L('space')}>
                                <ReactSelect
                                    isDisabled={readOnly}
                                    value={spacesOptions.find((p) => p.value === selectedSpace) ?? null}
                                    isClearable
                                    noOptionsMessage={() =>
                                        spacesOptions.find((p) => p.value === selectedSpace)
                                            ? `${L('no_spaces')}`
                                            : `${L('no_property_selected')}`
                                    }
                                    onChange={(e) => {
                                        handleChange('inventory_id', undefined);
                                        setSeletedSpace(e?.value);
                                    }}
                                    options={spacesOptions}
                                    placeholder={L('select_space')}
                                />
                            </Label>
                            <Label title={L('item')}>
                                <ReactSelect
                                    isDisabled={readOnly}
                                    placeholder={L('select_item')}
                                    noOptionsMessage={() =>
                                        spacesOptions.find((p) => p.value === selectedSpace)
                                            ? `${L('no_items')}`
                                            : `${L('no_space_selected')}`
                                    }
                                    isClearable
                                    value={inventoriesOptions.find((p) => p.value === ticket.inventory_id) ?? null}
                                    onChange={(e) => handleChange('inventory_id', e?.value)}
                                    options={inventoriesOptions}
                                />
                                <Label title={L('assignee')} error={handleError(errors.assigned_id)}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        placeholder={L('select_assignee')}
                                        value={assigneeOptions.find((p) => p.value === ticket.assigned_id) ?? null}
                                        isClearable
                                        onChange={(e) => handleChange('assigned_id', e?.value)}
                                        options={assigneeOptions}
                                    />
                                </Label>
                            </Label>
                            <Label title={L('priority')} error={handleError(errors.ticket_priority_id)}>
                                <ReactSelect
                                    placeholder={L('select_priority')}
                                    isDisabled={readOnly}
                                    isClearable
                                    value={
                                        priorityOptions.find(
                                            (option) => option.value.id === ticket.ticket_priority_id,
                                        ) ?? null
                                    }
                                    options={priorityOptions}
                                    onChange={(e) => handleChange('ticket_priority_id', e?.value.id)}
                                    formatOptionLabel={formatOptionLabelColorBox}
                                />
                            </Label>
                            <Label title={L('status')} error={handleError(errors.ticket_status_id)}>
                                <ReactSelect
                                    placeholder={L('select_status')}
                                    isClearable
                                    isDisabled={readOnly}
                                    value={
                                        statusOptions.find((option) => option.value.id === ticket.ticket_status_id) ??
                                        null
                                    }
                                    options={statusOptions}
                                    onChange={(e) => handleChange('ticket_status_id', e?.value.id)}
                                    formatOptionLabel={formatOptionLabelColorBox}
                                    menuShouldScrollIntoView={true}
                                    captureMenuScroll={false}
                                />
                            </Label>
                            <Label title={L('cost')}>
                                <InputSplit
                                    readOnly={readOnly}
                                    value={ticket.ticket_cost || ''}
                                    onChange={(e) => handleChange('ticket_cost', Number(e.target.value))}
                                    splitInput={'kr'}
                                    type={'number'}
                                    min={0}
                                />
                            </Label>
                            {!!ticket.ticket_cost && (
                                <Label title={L('who_pays')}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        value={costumerCostOptions.find(
                                            (p) => !!p?.value === !!ticket?.is_ticket_customer_cost,
                                        )}
                                        onChange={(e) => handleChange('is_ticket_customer_cost', e?.value)}
                                        options={costumerCostOptions}
                                        menuPortalTarget={document.parentElement}
                                        menuShouldBlockScroll={false}
                                        menuShouldScrollIntoView={true}
                                    />
                                </Label>
                            )}
                            <Label title={L('description')} error={handleError(errors.description)}>
                                <StyledTextArea
                                    rows={8}
                                    readOnly={readOnly}
                                    value={ticket.description?.plain_text || ''}
                                    onChange={(e) => handleChange('description', { plain_text: e.target.value })}
                                    required
                                />
                            </Label>
                        </div>
                    </SettingsWrapper>
                    {!readOnly && (
                        <ButtonWrapper>
                            <StyledDialogClose className={'secondary'}>{L('cancel')}</StyledDialogClose>

                            <Button>{L('save')}</Button>
                        </ButtonWrapper>
                    )}
                </Form>
            </Wrapper>
        </>
    );
};

export default TicketSideMenu;
