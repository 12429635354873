import AddonsApi from '@app/api/public/AddonsApi';
import { useQuery } from 'react-query';
import { HeaderProps } from '../SupplierCreatePdf';

const getHeaderInfo = async (data: HeaderProps, filter?: number[]) => {
    return await AddonsApi.rptHeaderInfo(data.projectId, filter || data.property_ids);
};

const useGetHeaderInfo = (data: HeaderProps, filter?: number[]) => {
    return useQuery(['supplierHeaderInfo', { filter, data }], () => getHeaderInfo(data, filter));
};

export default useGetHeaderInfo;
