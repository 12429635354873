import React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Container = styled.div({
    display: 'inline-flex',
    width: '100%',
    justifyContent: 'end',
    alignItems: 'center',
    paddingRight: '1rem',
    color: style.colors.newcount,
    fontSize: style.fontSize.centi,
});

interface Props {
    count: any;
    text?: string;
}

export function NewCount(props: Props) {
    if (!props.count) return null;
    return <Container>{props.count + `${props.text ? ' ' + props.text : ''}`} </Container>;
}
