import { useQuery } from 'react-query';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';
import { useParams } from 'react-router-dom';

export const servicePartnersByCategoryQueryKey = 'getServicePartnersByCategory';

const getServicePartnersByCategory = async (projectId?: string, categoryId?: string | number) => {
    if (!categoryId || !projectId) throw new Error('no category Id');
    return await ServicePartnersApi.listServiceByCategoryId(+projectId, +categoryId);
};

const useGetServicePartnersByCategory = (categoryId?: string | number) => {
    const { projectId } = useParams<{ projectId?: string }>();
    return useQuery(
        [servicePartnersByCategoryQueryKey, { projectId, categoryId }],
        () => getServicePartnersByCategory(projectId, categoryId),
        {
            enabled: !!categoryId
        }
    );
};

export default useGetServicePartnersByCategory;
