import { useQuery } from 'react-query';
import { RouterInput, trpc } from '../../../../lib/trpc';

type GetPropertiesInput = RouterInput['BindersApi']['getProperties'];

export const getPropertiesKeys = {
    all: ['binder-properties'] as const,
    lists: () => [...getPropertiesKeys.all, 'list'] as const,
    list: (params: GetPropertiesInput) => [...getPropertiesKeys.lists(), params] as const,
    listLean: (params: GetPropertiesInput) => [...getPropertiesKeys.lists(), 'lean', params] as const,
    listsByUser: () => [...getPropertiesKeys.all, 'listByUser'] as const,
    listByUser: (userId: number, shouldFetchTemplates = false) =>
        [...getPropertiesKeys.listsByUser(), { userId, shouldFetchTemplates }] as const,
    details: () => [...getPropertiesKeys.all, 'detail'] as const,
    detail: (propertyId: number | null) => [...getPropertiesKeys.details(), { propertyId }] as const,
    documents: () => [...getPropertiesKeys.all, 'documents'] as const,
    documentsByPropertyId: (propertyId: number) => [...getPropertiesKeys.documents(), propertyId] as const,
    images: () => [...getPropertiesKeys.all, 'images'] as const,
    imagesByPropertyId: (propertyId: number) => [...getPropertiesKeys.images(), propertyId, 'images'] as const,
};

export const getPropSpacesKeys = {
    all: ['binders-prop_spaces'] as const,
    lists: () => [...getPropSpacesKeys.all, 'list'] as const,
    list: (property_id: number) => [...getPropSpacesKeys.lists(), property_id] as const,
};

export function useGetProperty(propertyId: number | null) {
    return useQuery(
        [...getPropertiesKeys.detail(propertyId)],
        () => {
            if (!propertyId) {
                return Promise.reject(new Error('no property id provided'));
            }
            return trpc.BindersApi.getProperty.query({ propertyId });
        },
        {
            enabled: propertyId !== null,
        },
    );
}

export function useGetPropSpaces(property_id: number, template = true) {
    return useQuery(
        getPropSpacesKeys.list(property_id),
        () => trpc.BindersApi.listPropertySpaces.query({ propertyId: property_id, template }),
        {
            enabled: !!property_id,
            keepPreviousData: true,
        },
    );
}

export function useGetProperties(params: GetPropertiesInput) {
    return useQuery([...getPropertiesKeys.list(params)], () => trpc.BindersApi.getProperties.query(params), {
        enabled: !!params.projectId,
    });
}

export function useGetPropertiesLean(params: GetPropertiesInput) {
    return useQuery([...getPropertiesKeys.listLean(params)], () => trpc.BindersApi.getPropertiesLean.query(params), {
        enabled: !!params.projectId,
    });
}

export function useGetOrgProperties(isTemplate = false) {
    return useQuery([...getPropertiesKeys.lists()], () => trpc.BindersApi.getOrgProperties.query({ isTemplate }));
}

export function useGetPropertiesByUser({
    userId,
    shouldFetchTemplates = false,
}: {
    userId: number;
    shouldFetchTemplates?: boolean;
}) {
    return useQuery([...getPropertiesKeys.listByUser(userId, shouldFetchTemplates)], () =>
        trpc.BindersApi.getPropertiesByUser.query({ userId, shouldFetchTemplates }),
    );
}

export function useGetPropertyTypes() {
    return useQuery(['property_types'], () => trpc.BindersApi.getPropertyTypes.query());
}

export function useGetPropertyDocumentTypes() {
    return useQuery(['property_document_types'], () => trpc.BindersApi.listPropertyDocumentTypes.query());
}

export function useGetPropertyDocuments(propertyId: number) {
    return useQuery(
        [...getPropertiesKeys.documentsByPropertyId(propertyId)],
        async () =>
            trpc.BindersApi.listPropertyDocuments.query({
                propertyId,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropertyImages(propertyId: number) {
    return useQuery(
        [...getPropertiesKeys.imagesByPropertyId(propertyId)],
        async () =>
            trpc.BindersApi.listPropertyImages.query({
                propertyId,
            }),
        {
            keepPreviousData: true,
        },
    );
}
