import 'regenerator-runtime/runtime';
import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useAsyncDebounce } from 'react-table';

const Container = styled.div({
    borderLeft: '50px solid gray',
    backgroundColor: 'white',
    height: '40px',
    border: '1px solid #a6a6a6',
    borderRadius: '4px',
    display: 'flex',
});
const Input = styled.input({
    width: '400px',
    textAlign: 'left',
    fontSize: '1rem',
    border: 'none',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    '&:focus': {
        outline: 'none',
    },
});
const SearchIcon = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginRight: '0.5rem',
    height: '100%',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#ebebeb',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
});

type Props = {
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const GlobalFilter = ({ filter, setFilter }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
    const [value, setValue] = useState<string>(filter || '');

    const onChange = useAsyncDebounce((value: string) => {
        setFilter(value);
    }, 300);
    return (
        <Container>
            <SearchIcon>🔍</SearchIcon>
            <Input
                placeholder="Sök bland åtgärder i listan"
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </Container>
    );
};
export default GlobalFilter;
