import React from 'react';
import { useGetLibraries } from '../../binders/hooks/libraryQueries';
import AddProductFromTemplateComponent from '../../binders/components/Products/AddProductFromTemplateComponent';
import { useParams } from 'react-router-dom-v5-compat';
import useImportTemplateProductToTemplate from '../hooks/useImportTemplateProductToTemplatePackage';

const AddProductFromTemplateToTemplatePackage = () => {
    const { productId, id: propertyId } = useParams<{ id: string; productId: string }>();
    const { data: libraries } = useGetLibraries();

    const { mutate: importTemplateProduct } = useImportTemplateProductToTemplate();

    const handleImportTemplateProduct = ({
        products,
        destinationId,
        propertyId,
    }: {
        destinationId: number;
        products: {
            id: number;
            package_type: string | null;
        }[];
        propertyId: number;
    }) => {
        importTemplateProduct({ products, productId: +destinationId, toPropertyId: propertyId });
    };

    return productId && propertyId && libraries ? (
        <AddProductFromTemplateComponent
            productId={+productId}
            propertyId={+propertyId}
            libraries={libraries}
            handleImportToPropertyProduct={handleImportTemplateProduct}
        />
    ) : null;
};

export default AddProductFromTemplateToTemplatePackage;
