import { AccountContact } from '@app/api/models/AccountContacts';
import styled from '@emotion/styled';
import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { style } from '../../../components/Shared/Style';
import { margin } from '../../../components/Shared/Style/margin';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import useRemoveCheckedContacts from '../hooks/useRemoveCheckedMembers';

const Wrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const ButtonMenu = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: style.margin.s
});

const TopArea = ({
    toggleIsModalOpen,
    selectedContacts,
    onSubmit
}: {
    toggleIsModalOpen: () => void;
    selectedContacts: AccountContact[];
    onSubmit: (value: boolean) => void;
}) => {
    const { mutate: removeAllCheckedMembers } = useRemoveCheckedContacts();

    const removeChecked = () => {
        removeAllCheckedMembers({ contactArray: selectedContacts });
        onSubmit(false);
    };
    return (
        <Wrapper>
            <CardTitle>{L('contacts_description')}</CardTitle>
            <div style={{ display: 'flex' }}>
                <ButtonMenu>
                    {selectedContacts.length === 0 ? (
                        <Button style={{ marginRight: margin.s }} disabled variant="secondary">
                            {L('remove_all_checked')}
                        </Button>
                    ) : (
                        <Button style={{ marginRight: margin.s }} variant="primary" onClick={() => removeChecked()}>
                            {L('remove_all_checked')}
                        </Button>
                    )}
                    <Button onClick={toggleIsModalOpen}>
                        {L('new')} {L('contact')}
                    </Button>
                </ButtonMenu>
            </div>
        </Wrapper>
    );
};

export default TopArea;
