import styled from '@emotion/styled';
import React, { FC } from 'react';

type Props = {
    start?: string;
    end?: string;
    when?: string;
    date?: string | number;
    className?: string;
};

const Time = styled.span({
    gridArea: 'time',
    placeSelf: 'start'
});

const Start = styled.span({
    gridArea: 'start',
    placeSelf: 'start'
});

const End = styled.span({
    gridArea: 'end',
    placeSelf: 'start'
});

const When = styled.span({
    gridArea: 'when',
    placeSelf: 'start'
});

const DateArea = styled.h3({
    fontSize: '12px',
    margin: '0px',
    fontWeight: 400,
    gridArea: 'date',
    placeSelf: 'start',
    display: 'grid',
    gridTemplateAreas: `
    "when start end"
    `
});

const NoticeDate: FC<Props> = ({ start, end, when, ...rest }) => {
    return (
        <DateArea>
            <Time>
                <When>{when}</When>
                {start || (end && `: `)}
                <Start>{start && start}</Start>{' '}
                <End>
                    {start && end && '-'} {end && end}
                </End>
            </Time>
        </DateArea>
    );
};

export default NoticeDate;
