import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentInformationQueryKeys } from './contentInformationQueries';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';

export default function useRemoveContentInfo() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ projectId, informationId }: { projectId: number; informationId: number }) => {
            return trpc.ContentInformationApi.removeContentInformation.mutate({
                projectId: projectId,
                contentInformationId: informationId,
            });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
            },
        },
    );
}

export function useRemoveContentOrgInfo() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ informationId }: { informationId: number }) => {
            return trpc.ContentInformationApi.removeContentOrgInformation.mutate({
                contentInformationId: informationId,
            });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentInformationQueryKeys.lists());
                queryClient.invalidateQueries(ContentInformationQueryKeys.drafts());
            },
            onSuccess: async () => {
                toast.success(L('information_page_delete_success'));
            },
        },
    );
}
