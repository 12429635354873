import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';

type SendTicketVariables = { ticket_id: number; emails: string[] };
const useForwardTicketMail = () => {
    const client = useQueryClient();
    return useMutation(
        (variables: SendTicketVariables) => {
            if (variables.ticket_id) {
                return TicketsApi.sendTicketAsMail(variables.ticket_id, variables.emails);
            } else {
                throw new Error('ticket id missing');
            }
        },
        {
            onSettled: (response, error, varables) => {
                if (varables.ticket_id) {
                    client.invalidateQueries(ticketQueryKeys.id(varables.ticket_id.toString()));
                }
                client.invalidateQueries(ticketQueryKeys.list());
            }
        }
    );
};

export default useForwardTicketMail;
