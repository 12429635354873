import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import ContactsList from '../../components/Contacts/ContactsList';
import * as Dialog from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import NewContactModal from '../../components/Contacts/NewContactModal';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import { L } from '../../../../lib/i18n';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import H1 from '../../../../components/typography/H1';

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const PropertyContactsComponent = ({ propertyId }: { propertyId: string }) => {
    const [open, setOpen] = React.useState(false);

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="../products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="../members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="../todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="../documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <Dialog.Root open={!!open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <NewContactModal property_id={+propertyId} handleToggleModal={() => setOpen(!open)} />
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <ContactsList handleToggleModal={() => setOpen(!open)} propertyId={+propertyId} />
        </>
    );
};

const PropertyContactsPage = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return propertyId ? <PropertyContactsComponent propertyId={propertyId} /> : null;
};

export default PropertyContactsPage;
