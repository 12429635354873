import React from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { style } from '../../../components/Shared/Style';
import { Card } from '../../../components/Layout/Card';
import { Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import dayjs from 'dayjs';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';
import { useGetContact } from '../../binders/hooks/propertyHooks/propertyContactsQueries';
import useEditPropertytContacts from '../../binders/hooks/propertyHooks/useEditPropertyContact';
import useDeletePropertyContact from '../../binders/hooks/propertyHooks/useDeletePropertyContact';
import ContactForm, { EditContactForm } from '../../binders/components/Contacts/EditContactForm';

const Content = styled.div({
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.l,
    paddingBottom: style.margin.xs,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const PropertyTemplateContactDetailPage = () => {
    const { id: propertyId, contactId } = useParams<{
        id: string;
        contactId: string;
    }>();
    return propertyId && contactId ? <Page propertyId={propertyId} contactId={contactId} /> : null;
};
const Page = ({ propertyId, contactId }: { propertyId: string; contactId: string }) => {
    const { data: contact, isLoading } = useGetContact(+contactId);
    const navigate = useNavigate();

    const { mutate } = useEditPropertytContacts();
    const onSubmit = (contact: EditContactForm, file?: File) =>
        mutate(
            { contact, id: +contactId, property_id: +propertyId, file },
            {
                onSuccess: () => {
                    toast.success(`${L('contact')} ${contact.name} ${L('saved').toLocaleLowerCase()}`);
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );

    const { mutate: remove } = useDeletePropertyContact();

    if (isLoading) return <Loading />;

    return (
        <>
            <Content>
                <Card padding="0" width="49%">
                    {L('contact')}
                </Card>
            </Content>
            <Content>
                <Card width="49%" background={style.colors.white}>
                    <ContactForm contact={contact} onSubmit={onSubmit}>
                        <ButtonWrapper>
                            <ConfirmationButton
                                type="button"
                                props={{
                                    onConfirm: () => {
                                        if (!contact?.id || !contact?.property_id) {
                                            return;
                                        }
                                        remove(
                                            {
                                                id: +contact.id,
                                                property_id: +contact.property_id,
                                            },
                                            {
                                                onSuccess: () => {
                                                    toast.success(`${contact.name} ${L('removed').toLowerCase()}`);
                                                    navigate(`..`);
                                                },
                                                onError: (err) => {
                                                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                                                },
                                            },
                                        );
                                    },
                                }}
                            />
                            <Button type="submit" style={{ marginRight: '0px' }}>
                                {L('save')}
                            </Button>
                        </ButtonWrapper>
                    </ContactForm>
                </Card>
                <Card width="49%" background={style.colors.white}>
                    <Label title={L('created_at')}>
                        <Input
                            type="datetime-local"
                            readOnly
                            value={dayjs(contact?.created_at).format('YYYY-MM-DDTHH:mm')}
                        />
                    </Label>
                    <Label title={L('updated_at')}>
                        <Input
                            type="datetime-local"
                            readOnly
                            value={dayjs(contact?.updated_at).format('YYYY-MM-DDTHH:mm')}
                        />
                    </Label>
                </Card>
            </Content>
        </>
    );
};

export default PropertyTemplateContactDetailPage;
