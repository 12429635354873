import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { AccountMailTemplatePage } from './AccountMailtemplates/AccountMailTemplatePage';
import { ProjectMailTemplatePage } from './ProjectMailTemplates/ProjectMailTemplatePage';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const Navigation = () => {
    return (
        <Menu>
            <BigTabNav>
                <Routes>
                    <Route path="template-settings">
                        <Route
                            index
                            element={
                                <>
                                    <BigNavItem end to=".">
                                        {L('settings_account_mail_templates')}
                                    </BigNavItem>
                                    <BigNavItem end to="./projectMailTemplates">
                                        {L('settings_project_mail_templates')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="projectMailTemplates"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('settings_account_mail_templates')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('settings_project_mail_templates')}
                                    </BigNavItem>
                                </>
                            }
                        />
                    </Route>
                </Routes>
            </BigTabNav>
        </Menu>
    );
};

export const TemplateSettingsPage = () => {
    return (
        <>
            <Navigation />
            <Routes>
                <Route path="template-settings">
                    <Route index element={<AccountMailTemplatePage />} />

                    <Route path="projectMailTemplates" element={<ProjectMailTemplatePage />} />
                </Route>
            </Routes>
        </>
    );
};
