import styled from '@emotion/styled';

export default styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: '0.75rem',
    ['@media screen and (min-width: 992px)']: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
