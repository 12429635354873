import styled from '@emotion/styled';
import React, { FC } from 'react';
import { colors } from '../Shared/Style/colors';

const StyledCardTitle = styled.h2({
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '0.9rem',
    lineHeight: '100%',
    margin: '0px 0px 0px 0px',
    color: colors.textColor1,
    display: 'flex',
    alignItems: 'center',
});
const CardTitle: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
    children,
    ...rest
}) => {
    return <StyledCardTitle {...rest}>{children}</StyledCardTitle>;
};

export default CardTitle;
