import React from 'react';
import { Icon } from '../../../../components/Icon/Icon';
import LabelText from '../emotion/LabelText';
import Option from '../emotion/Filters/Option';
import HighlightedText from '../emotion/HighlightedText';
import ClickableIcon from '../emotion/ClickableIcon';
export default function ChosenFilter({
    initialTexts,
    values,
    onRemove,
}: {
    initialTexts: string[];
    values: { value: string; label: string }[];
    onRemove: (value: string) => void;
}) {
    const RemoveOutline = Icon['RemoveOutline'];

    return (
        <>
            <LabelText>{initialTexts[values.length > 1 ? 1 : 0]}:</LabelText>
            {values.map((item, index) => (
                <Option key={index}>
                    <HighlightedText key={index}>{item.label}</HighlightedText>
                    <ClickableIcon onClick={() => onRemove(item.value)}>
                        <RemoveOutline size={'small'} />
                    </ClickableIcon>
                </Option>
            ))}
        </>
    );
}
