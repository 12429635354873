import { ProductFilter, PropertyProductFilter, SortOptions } from '@app/trpc-api/models/Binders';
import { useInfiniteQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const getPropertyProductsTemplatesKeys = {
    all: ['templates-properties-products'] as const,
    lists: () => [...getPropertyProductsTemplatesKeys.all, 'list'] as const,
    infiniteLists: () => [...getPropertyProductsTemplatesKeys.lists(), 'infinite'] as const,
    infiniteList: ({
        filters,
        sortOptions,
        page,
        rowCount,
        search,
        propertyId,
    }: {
        search: string;
        propertyId: number;
        filters?: ProductFilter;
        sortOptions?: SortOptions;
        page?: number;
        rowCount?: number;
    }) =>
        [
            ...getPropertyProductsTemplatesKeys.infiniteLists(),
            'templates',
            { filters, sortOptions, page, rowCount, search, propertyId },
        ] as const,
};

export function useGetPropertyProductList({
    filters,
    sortOptions,
    search,
    propertyId,
}: {
    filters: PropertyProductFilter;
    sortOptions: SortOptions;
    search: string;
    propertyId: number;
}) {
    return useInfiniteQuery(
        getPropertyProductsTemplatesKeys.infiniteList({ filters, sortOptions, search, propertyId }),
        async ({ pageParam = 1 }) =>
            trpc.TemplatesApi.getProductsList.query({
                filter: filters,
                sortType: sortOptions,
                page: pageParam,
                search,
                propertyId,
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}
