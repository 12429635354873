import React, { useRef } from 'react';
import { ActionMeta, default as ReactSelect, OptionsType } from 'react-select';
import { useObserver } from 'mobx-react';
import { ChangeFunction } from '../../lib/react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import * as Icons from 'react-feather';
import { L } from '../../lib/i18n';

interface Option {
    value: any;
    label: any;
}

interface Props {
    placeholder?: string;
    label?: string;
    options: Array<Option>;
    name?: string;
    onChange:
        | (((value: OptionsType<any>, actionMeta: ActionMeta<any>) => void) &
              ((value: OptionsType<any>, action: ActionMeta<any>) => void))
        | undefined;
    value?: any;
    isClearable?: boolean;
    disabled?: boolean;
    error?: boolean;
    isMulti?: boolean;
    hideSelectedOptions?: boolean;
}

const Option = styled.span({ color: style.colors.primary });

const styles = {
    container: st => ({ ...st, fontSize: '80%', height: 32 }),
    control: st => ({
        ...st,
        backgroundColor: 'transparent',
        borderRadius: 4,
        height: '32px',
        minHeight: '32px'
    }),
    indicatorsContainer: st => ({ ...st, padding: 0 }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: st => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        cursor: 'pointer'
    }),
    singleValue: st => ({
        ...st,
        position: 'relative',
        transform: 'inherit'
    }),
    option: st => ({
        ...st,
        cursor: 'pointer'
    }),
    menu: st => ({ ...st, minWidth: '12em' }),
    input: st => ({ ...st, caretColor: 'transparent', maxWidth: 0 })
};

const components = {
    DropdownIndicator() {
        return <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
    ClearIndicator(props) {
        const click = evt => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icons.X onMouseDown={click} onClick={click} color="#999" style={{ paddingRight: 3 }} size={16} />;
    }
};

export function MultiSelect(props: Props) {
    return useObserver(() => (
        <ReactSelect
            isMulti={true}
            closeMenuOnSelect={false}
            // onMenuClose={props.onChange}
            controlShouldRenderValue={false}
            isClearable
            components={components}
            hideSelectedOptions={false}
            value={props.value}
            onChange={props.onChange}
            placeholder={props.label}
            name={props.name}
            label={props.name}
            options={props.options}
            styles={styles}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary50: style.colors.primary50,
                    primary25: style.colors.primary25,
                    primary: style.colors.primary
                }
            })}
        />
    ));
}
