import { useMutation, useQueryClient } from 'react-query';
import { getTemplatePropSpaceKeys } from './propertyTemplateQueries';
import { trpc } from '../../../lib/trpc';
import { AddSpace } from '../../binders/schemas';

const useUpdateSpaceOnPropertyTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ data, spaceId, propertyId }: { data: AddSpace; spaceId: number; propertyId: number }) => {
            const { name, space_type, ...attr } = data;
            const spaceData = { name, space_type, attr };
            return trpc.TemplatesApi.updateSpace.query({ space: spaceData, spaceId, propertyId });
        },
        {
            onSettled: (data) => {
                queryClient.invalidateQueries(getTemplatePropSpaceKeys.lists());
                if (data?.id) queryClient.invalidateQueries(getTemplatePropSpaceKeys.detail(data.id));
            },
        },
    );
};

export default useUpdateSpaceOnPropertyTemplate;
