import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';

export const projectsQueryKey = {
    all: ['project'],
    getProjectById: (resourceId: number) => [...projectsQueryKey.all, 'detail', { resourceId }] as const,
    getProjectList: () => [...projectsQueryKey.all, 'list'] as const
};

const getProjectList = () => {
    return ProjectsApi.list();
};

const useGetProjectList = () => {
    return useQuery(projectsQueryKey.getProjectList(), () => getProjectList());
};

export { useGetProjectList as default };
