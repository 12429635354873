import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProfessionKeys } from './professionQueries';

const useEditProfession = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ profession }: { profession: { name: string; id: number } }) => {
            return trpc.ProfessionApi.updateProfession.query(profession);
        },
        {
            onSettled: (_data, _error, variables) => {
                if (!variables.profession.id) return;
                client.invalidateQueries(getProfessionKeys.detail(variables.profession.id));
            }
        }
    );
};

export default useEditProfession;
