import { ExcelMemberImport, ExcelPropertyImport, Property } from '@app/api/models/Properties';
import Excel from 'exceljs';
import { useMutation } from 'react-query';
import { CellValue } from 'react-table';

const getCellValue = (row: Excel.Row, cellIndex: string) => {
    const cell = row.getCell(cellIndex);

    return cell.text ? cell.text.toString() : '';
};

type excelProperty =
    | 'name'
    | 'template_name'
    | 'property_type'
    | 'member_1'
    | 'name_1'
    | 'phone_number_1'
    | 'member_2'
    | 'name_2'
    | 'phone_number_2'
    | 'member_3'
    | 'name_3'
    | 'phone_number_3'
    | 'access_date'
    | 'planpubl_date'
    | 'property_number'
    | 'apartment_number'
    | 'street'
    | 'postalcode'
    | 'city'
    | 'country'
    | 'commune'
    | 'parish'
    | 'property_term'
    | 'property_area'
    | 'plot_area'
    | 'built_year'
    | 'rooms'
    | 'floors'
    | 'bathrooms'
    | 'assess_value'
    | 'heating'
    | 'house'
    | 'stairwell'
    | 'floor';

const getImportMembersList = async (file: File): Promise<ExcelPropertyImport[]> => {
    const wb = new Excel.Workbook();

    const content = await wb.xlsx.load(await file.arrayBuffer());
    const worksheet = content.worksheets[0];
    const rowStartIndex = 5;
    const numberOfRows = worksheet.rowCount - 4;

    const myCell = new Map<excelProperty, CellValue>();
    worksheet.getRow(3).eachCell((cell) => {
        cell.value && myCell.set(cell.value as excelProperty, cell.col);
    });

    const rows = worksheet.getRows(rowStartIndex, numberOfRows) ?? [];

    const properties = rows
        .filter((row) => row.hasValues)
        .map((row): ExcelPropertyImport => {
            const addMember = (index: 1 | 2 | 3) => {
                return {
                    email: getCellValue(row, myCell.get(`member_${index}`)),
                    name: getCellValue(row, myCell.get(`name_${index}`)),
                    phone_number: getCellValue(row, myCell.get(`phone_number_${index}`)),
                    property_name: getCellValue(row, myCell.get('name')),
                };
            };
            //
            const memberIndexArray = [1, 2, 3] as const;
            const members = memberIndexArray
                .map((index) => {
                    return addMember(index);
                })
                .filter((member): member is ExcelMemberImport => !!member.email);

            return {
                name: getCellValue(row, myCell.get('name')),
                template_name: getCellValue(row, myCell.get('template_name')),
                property_type: getCellValue(row, myCell.get('property_type')),
                members: members,
                access_date: getCellValue(row, myCell.get('access_date')),
                planpubl_date: getCellValue(row, myCell.get('planpubl_date')),
                property_number: getCellValue(row, myCell.get('property_number')),
                apartment_number: getCellValue(row, myCell.get('apartment_number')),
                street: getCellValue(row, myCell.get('street')),
                postalcode: getCellValue(row, myCell.get('postalcode')),
                city: getCellValue(row, myCell.get('city')),
                country: getCellValue(row, myCell.get('country')),
                commune: getCellValue(row, myCell.get('commune')),
                parish: getCellValue(row, myCell.get('parish')),
                property_term: getCellValue(row, myCell.get('property_term')),
                property_area: getCellValue(row, myCell.get('property_area')),
                plot_area: getCellValue(row, myCell.get('plot_area')),
                built_year: getCellValue(row, myCell.get('built_year')),
                rooms: getCellValue(row, myCell.get('rooms')),
                floors: getCellValue(row, myCell.get('floors')),
                bathrooms: getCellValue(row, myCell.get('bathrooms')),
                assess_value: getCellValue(row, myCell.get('assess_value')),
                heating: getCellValue(row, myCell.get('heating')),
                floor: getCellValue(row, myCell.get('floor')),
                stairwell: getCellValue(row, myCell.get('stairwell')),
            };
        });
    return properties;
};

const useGetImportMembersList = () => {
    return useMutation((file: File) => getImportMembersList(file));
};

export default useGetImportMembersList;
