import { SupplierFilter } from '@app/api/models/Addons';
import * as React from 'react';
import { AddonReportStore } from '../../AddonReportStore';
import { FilterProperty } from './ReportFilterProperty';
import { Filters } from '../../../../../components/Filters/Filters';
import { FilterAddonSelected } from './FilterAddonSelected';

interface Props {
    setFilter: (f: SupplierFilter) => void;
    filter: SupplierFilter;
}

export const AddonsGrossFilterPanel = (props: Props) => {
    return (
        <div>
            <Filters showLabel marginBottom={'12px'}>
                <FilterProperty setFilter={props.setFilter} filter={props.filter} store={AddonReportStore} />
                <FilterAddonSelected setFilter={props.setFilter} filter={props.filter} />
            </Filters>
        </div>
    );
};
