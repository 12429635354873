import { observable } from 'mobx';
import ProjectSettingsApi from '@app/api/public/ProjectSettingsApi';
import { createValidator, required, ValidationErrors } from '../../../lib/validation';

import { ProjectMailTemplate } from '@app/api/models/ProjectSettings';
import ProjectsApi, { ProjectDetail } from '@app/api/public/ProjectsApi';
import SettingsApi from '@app/api/public/SettingsApi';
import { L } from '../../../lib/i18n';

const mailTemplateValidator = createValidator({
    templateName: [required()],
    file: [required()]
});

class ProjectMailTemplateStoreClass {
    @observable templates: ProjectMailTemplate[] = [];
    @observable template: Partial<ProjectMailTemplate> = {};

    @observable projects: ProjectDetail[] = [];
    @observable selectedProjectId: string;

    //@observable newMailTemplateFile: Partial<ProjectMailTemplate> = {};
    @observable selectedTemplateId: string;

    @observable errors: ValidationErrors = {};

    async load() {
        if (this.selectedProjectId) {
            const result = await ProjectSettingsApi.getProjectMailTemplatesList(this.selectedProjectId);
            if (result.message === 'ok') {
                this.templates = result.data;
            }
        }
    }

    async loadProjects() {
        this.projects = await ProjectsApi.list();
    }

    setMailTemplateFile = (file: File) => {
        this.template.mail_template_id = this.selectedTemplateId;
        this.template.file = file;
    };

    setMailTemplateText = (value: any) => {
        this.template.data = value;
    };

    async downloadTemplate(data: string, name: string) {
        if (data != undefined) {
            return new File([data], `${L(name)}.mjml`, {
                type: 'text/plain'
            });
        }
    }

    async previewMailWithPlaceholders() {
        return await SettingsApi.previewMailWithPlaceholders(this.template.data as string);
    }

    async uploadMailTemplate() {
        const result = mailTemplateValidator.test(this.template);
        this.errors = result.errors;
        const t = this.templates.find(t => t.mail_template_id === this.selectedTemplateId);
        if (t && t.id) this.template.id = t.id;

        const r = await ProjectSettingsApi.uploadProjectMailTemplate(this.selectedProjectId, this.template);
        this.template = {} as Partial<ProjectMailTemplate>;
        this.load();
        return r;
    }

    async get(name: string) {
        this.template = (await ProjectSettingsApi.getProjectMailTemplate(
            this.selectedProjectId,
            name
        )) as ProjectMailTemplate;
    }
}

export const ProjectMailTemplateStore = new ProjectMailTemplateStoreClass();
