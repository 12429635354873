import React, { useMemo } from 'react';
import { L } from '../../../../../lib/i18n';
import { ExcelPropertyImport } from '@app/api/models/Properties';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<ExcelPropertyImport>();

export default function propertyColumns() {
    return useMemo(
        () => [
            columnHelper.display({
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                meta: {
                    styles: {
                        flexShrink: 0,
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => <CellContainer>{info.row.original?.name}</CellContainer>,
                header: () => {
                    return L('name');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.property_type, {
                id: 'property_type',
                cell: (info) => (
                    <CellContainer>
                        <Data>{L(`${info.row.original.property_type}`)}</Data>
                    </CellContainer>
                ),
                header: () => {
                    return L('property_type');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.template_name, {
                id: 'template_name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{L(`${info.row.original.template_name}`)}</Data>
                    </CellContainer>
                ),
                header: () => {
                    return L('template_name');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.members, {
                id: 'members',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.members.length}</Data>
                    </CellContainer>
                ),
                header: () => {
                    return L('members');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [],
    );
}
