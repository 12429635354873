import AddonsApi from '@app/api/public/AddonsApi';
import { useMutation, useQueryClient } from 'react-query';
import { orderConfirmationKey } from './useGetOrderConfirmationSignedUrl';

const useCreateOrderConfirmation = () => {
    const queryClient = useQueryClient();
    return useMutation((params: { propertyId: number }) => AddonsApi.createOrderConfirmationPdf(params.propertyId), {
        onSuccess: (_data, params) => {
            queryClient.invalidateQueries(orderConfirmationKey.byId(params.propertyId));
        }
    });
};

export default useCreateOrderConfirmation;
