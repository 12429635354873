import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import CopyDocumentsPage from './pages/CopyDocumentsPage';
import DeleteDocumentsPage from './pages/DeleteDocumentsPage';

export const CopyDocumentsRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/copy-documents">
                    <Route index element={<CopyDocumentsPage />} />
                    <Route path="deletion">
                        <Route index element={<DeleteDocumentsPage />} />
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
