import React, { FC } from 'react';
import styled from '@emotion/styled';
import DocumentsArea from './DocumentsArea';
import { Card } from '../Layout/Card';
import { style } from '../Shared/Style';
import { DragEndEvent } from '@dnd-kit/core';
import { ListDocumentsOutput } from '@app/trpc-api/public/BindersApi/BindersApi';
import EmptyState from '../EmptyStateCommunity/EmptyStateCommunity';
import { L } from '../../lib/i18n';
import { Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadError, onUploadSuccess } from '@ourliving/ourliving-ui';

const StyledCard = styled(Card)({
    display: 'grid',
    backgroundColor: style.colors.white,
    alignContent: 'start',
    padding: '0',
});

const RestyledEmptyState = styled(EmptyState)({
    padding: '0 3rem',
    minHeight: '300px',
});

type Props = {
    documents?: ListDocumentsOutput;
    saveMultipleDocs: (files: File[]) => void;
    removeDocument: (documentId: number) => void;
    renameDocument: (documentId: number, name: string) => void;
    updateDocType: ({
        documentId,
        document_subtype,
        document_type,
    }: {
        documentId: number;
        document_subtype: number;
        document_type: number;
    }) => void;
    handleDragEnd: (event: DragEndEvent) => void;
    isUpdatingSort: boolean;
};

const DocumentAreaAndDropZone: FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
    documents,
    saveMultipleDocs,
    removeDocument,
    renameDocument,
    updateDocType,
    handleDragEnd,
    isUpdatingSort,
    ...rest
}) => {
    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return;
        saveMultipleDocs([param.file]);
    };

    return (
        <StyledCard {...rest}>
            {documents && documents?.length > 0 ? (
                <DocumentsArea
                    isUpdatingSort={isUpdatingSort}
                    sortable={true}
                    documents={documents}
                    removeDocument={removeDocument}
                    renameDocument={renameDocument}
                    updateDocType={updateDocType}
                    handleDragEnd={handleDragEnd}
                />
            ) : (
                <RestyledEmptyState>
                    <EmptyState.Title>{L('no_documents')}</EmptyState.Title>
                    <EmptyState.Description>
                        <p>{L('no_documents_description')}</p>
                    </EmptyState.Description>
                </RestyledEmptyState>
            )}
            <div style={{ marginTop: '1rem' }}>
                <Dropzone onUpload={onUpload} multiple={true} variant="Document" />
            </div>
        </StyledCard>
    );
};

export default DocumentAreaAndDropZone;
