import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export default function useRemoveSpaceImage() {
    return useMutation(async ({ documentId, entityId }: { documentId: number; entityId: number }) => {
        return trpc.BindersApi.removeSpaceImage.mutate({
            id: documentId,
            entityId,
        });
    });
}
