import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import PropertiesPage from './pages/PropertyPages/PropertiesPage';
import ProjectPage from './pages/ProjectPages/ProjectPage';
import EditProjectPage from './pages/ProjectPages/EditProjectPage';
import AddProjectPage from './pages/ProjectPages/AddProjectPage';
import PropertyContactsPage from './pages/PropertyPages/PropertyContactsPage';
import { ContactDetailPage } from './components/Contacts/ContactDetailPage';
import AddPropertyPage from './pages/PropertyPages/AddPropertyPage';
import EditPropertyPage from './pages/PropertyPages/EditPropertyPage';
import PropertyProductsPage from './pages/PropertyPages/PropertyProductsPage';
import PropertySpacesPage from './pages/SpacePages/PropertySpacesPage';
import AddSpacePage from './pages/SpacePages/AddSpacePage';
import SpaceDetailPage from './pages/SpacePages/SpaceDetailPage';
import PropertyDocumentsPage from './pages/PropertyPages/PropertyDocumentsPage';
import AddProductPageBinders from './pages/ProductPages/AddProductPageBinders';
import EditProductPage from './pages/ProductPages/EditProductPage';
import PropertyMembersPage from './pages/MemberPages/PropertyMembersPage';
import PropertyMemberDetailPage from './pages/MemberPages/PropertyMemberDetailPage';
import ImportPropertyPage from './pages/PropertyPages/ImportPropertiesPage/ImportPropertyPage';
import PropertyTemplatesPage from './pages/PropertyPages/PropertyTemplatesPage';
import SpaceProductsPage from './pages/SpacePages/SpaceProductsPage';
import PackageProductsPage from './pages/ProductPages/PackageProductsPage';
import EditSpaceProductPage from './pages/ProductPages/EditSpaceProductPage';
import TodoListPage from './pages/TodoPages/TodoListPage';
import TodoNewPage from './pages/TodoPages/TodoNewPage';
import TodoDetailPage from './pages/TodoPages/TodoDetailPage';
import AdministrationPage from './pages/AdminPages/AdministrationPage';
import AddNewProductToPackage from './pages/ProductPages/AddNewProductToPackage';

const RoutesUnderBinders = ({ projectStatus }: { projectStatus: 'active' | 'transferred' | 'both' }) => {
    return (
        <>
            <Route index element={<ProjectPage projectStatus={projectStatus} />} />
            <Route path="new" element={<AddProjectPage />} />
            <Route path=":id">
                <Route index element={<EditProjectPage />} />
                <Route path="templates">
                    <Route index element={<PropertyTemplatesPage />} />
                    <Route path="new" element={<AddPropertyPage template />} />
                    <Route path=":propertyId">
                        <Route index element={<EditPropertyPage template />} />
                        <Route path="members">
                            <Route index element={<PropertyMembersPage />} />
                            <Route path=":memberId" element={<PropertyMemberDetailPage />} />
                        </Route>
                        <Route path="todos">
                            <Route index element={<TodoListPage />} />
                            <Route path="new" element={<TodoNewPage />} />
                            <Route path=":todoId" element={<TodoDetailPage />} />
                        </Route>
                        <Route path="documents" element={<PropertyDocumentsPage />} />
                        <Route path="contacts">
                            <Route index element={<PropertyContactsPage />} />
                            <Route path=":contactId" element={<ContactDetailPage />} />
                        </Route>
                        <Route path="spaces">
                            <Route index element={<PropertySpacesPage />} />
                            <Route path="new" element={<AddSpacePage />} />
                            <Route path=":spaceId">
                                <Route index element={<SpaceDetailPage />} />
                                <Route path="products">
                                    <Route index element={<SpaceProductsPage />} />
                                    <Route path="new" element={<AddProductPageBinders />} />
                                    <Route path=":productId">
                                        <Route index element={<EditSpaceProductPage />} />
                                        <Route path="package">
                                            <Route index element={<PackageProductsPage />} />
                                            <Route path="new">
                                                <Route index element={<AddNewProductToPackage />} />
                                            </Route>
                                            <Route path=":packageItemId">
                                                <Route index element={<EditSpaceProductPage />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="products">
                            <Route index element={<PropertyProductsPage />} />
                            <Route path="new" element={<AddProductPageBinders />} />
                            <Route path=":productId">
                                <Route index element={<EditProductPage />} />
                                <Route path="package">
                                    <Route index element={<PackageProductsPage />} />
                                    <Route path="new">
                                        <Route index element={<AddNewProductToPackage />} />
                                    </Route>
                                    <Route path=":packageItemId">
                                        <Route index element={<EditProductPage />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="properties">
                    <Route index element={<PropertiesPage />} />
                    <Route path="new" element={<AddPropertyPage />} />
                    <Route path="import" element={<ImportPropertyPage />} />
                    <Route path=":propertyId">
                        <Route index element={<EditPropertyPage />} />
                        <Route path="members">
                            <Route index element={<PropertyMembersPage />} />
                            <Route path=":memberId" element={<PropertyMemberDetailPage />} />
                        </Route>
                        <Route path="todos">
                            <Route index element={<TodoListPage />} />
                            <Route path="new" element={<TodoNewPage />} />
                            <Route path=":todoId" element={<TodoDetailPage />} />
                        </Route>
                        <Route path="documents" element={<PropertyDocumentsPage />} />
                        <Route path="contacts">
                            <Route index element={<PropertyContactsPage />} />
                            <Route path=":contactId" element={<ContactDetailPage />} />
                        </Route>
                        <Route path="spaces">
                            <Route index element={<PropertySpacesPage />} />
                            <Route path="new" element={<AddSpacePage />} />
                            <Route path=":spaceId">
                                <Route index element={<SpaceDetailPage />} />
                                <Route path="products">
                                    <Route index element={<SpaceProductsPage />} />
                                    <Route path="new" element={<AddProductPageBinders />} />
                                    <Route path=":productId">
                                        <Route index element={<EditSpaceProductPage />} />
                                        <Route path="package">
                                            <Route index element={<PackageProductsPage />} />
                                            <Route path="new">
                                                <Route index element={<AddNewProductToPackage />} />
                                            </Route>
                                            <Route path=":packageItemId">
                                                <Route index element={<EditSpaceProductPage />} />
                                            </Route>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="products">
                            <Route index element={<PropertyProductsPage />} />
                            <Route path="new" element={<AddProductPageBinders />} />
                            <Route path=":productId">
                                <Route index element={<EditProductPage />} />
                                <Route path="package">
                                    <Route index element={<PackageProductsPage />} />
                                    <Route path="new">
                                        <Route index element={<AddNewProductToPackage />} />
                                    </Route>
                                    <Route path=":packageItemId">
                                        <Route index element={<EditProductPage />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Route>
                <Route path="admins">
                    <Route index element={<AdministrationPage />} />
                </Route>
            </Route>
        </>
    );
};

export const BindersPage = () => {
    return (
        <Routes>
            <Route path="/binders/transferred">{RoutesUnderBinders({ projectStatus: 'transferred' })}</Route>
            <Route path="/binders">{RoutesUnderBinders({ projectStatus: 'active' })}</Route>
        </Routes>
    );
};
