import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useHistory, useParams } from 'react-router-dom';
import { FormField } from '../../../components/Forms/FormField';
import { style } from '../../../components/Shared/Style';
import { Loading } from '../../../components/Loading/Loading';
import { Card } from '../../../components/Layout/Card';
import { L } from '../../../lib/i18n';
import { Avatar, Button } from '@ourliving/ourliving-ui';
import { DisplayField } from '../../../components/DisplayField/DisplayField';
import { DatetimeWorklog } from '../../../components/DateTimeWorklog/DateStatusWorklog';
import { useGetContactById } from '../hooks/useGetContacts';
import { margin } from '../../../components/Shared/Style/margin';
import { AccountContact } from '@app/api/models/AccountContacts';
import useSaveContact from '../hooks/useSaveContact';
import useRemoveContact from '../hooks/useRemoveContact';
import toast from 'react-hot-toast';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import Label from '../../../components/Forms/Label';
import useGetSignedUrlById from '../../../lib/hooks/useGetSignedUrlById';

const Content = styled.div({
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.l,
    paddingBottom: style.margin.xs,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});

const ContactText = styled.input({
    background: style.colors.white,
    border: '1px solid #F0F1F7',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    outline: 'none',
    lineHeight: '1.5em',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s,
});

const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
});

const UploadArea = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});

export const ContactDetailPage = () => {
    const { id } = useParams<{ id: string }>();

    const [confirmDelete, setConfirmDelete] = useState(true);
    const [contactState, setContactState] = useState<Partial<AccountContact>>({});
    const [localImage, setlocalImage] = useState('');
    const [file, setFile] = useState<File>();
    const inputFileRef = useRef<HTMLInputElement>(null);

    const { mutate: removeContact } = useRemoveContact();
    const { mutate: saveContact } = useSaveContact();
    const { data: contact } = useGetContactById(+id);
    const { data: img } = useGetSignedUrlById(contact?.profile_image_data?.original?.id, '200x200');

    const history = useHistory();

    useEffect(() => {
        if (!contact) return;
        setContactState({ ...contact });
    }, [contact]);

    const save = () => {
        if (!contactState) return;
        if (contactState.name) {
            saveContact(
                { ...contactState, file },
                {
                    onSuccess: () => {
                        toast.success(`${contactState.name} ${L('contact_saved')}`);
                    },
                },
            );
        }
    };

    const remove = () => {
        if (!contact) return;
        removeContact(
            { id: contact.id },
            {
                onSuccess: () => {
                    toast.success(`${contact.name} ${L('removed')}`);
                    history.push('/contacts');
                },
            },
        );
    };

    const onChange = (evt: any) => {
        setContactState({ ...contactState, [evt.target.name]: evt.target.value });
    };
    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const myImg = URL.createObjectURL(file);
        setlocalImage(myImg);
        setFile(file);
        setContactState({ ...contact, [e.target.name]: file });
    };

    if (!contact) return <Loading />;

    return (
        <>
            <Content>
                <Card padding="0" width="49%">
                    {L('contact')}
                </Card>
                <Card padding="0" width="49%">
                    {L('contact_extra_information')}
                </Card>
            </Content>
            <Content>
                <Card width="49%" background={style.colors.white}>
                    <Label title={L('profile_image')}>
                        <UploadArea>
                            <ClickArea htmlFor={'contactImage'}>
                                <Avatar src={localImage ? localImage : img} name={contact?.name || contact?.email} />
                            </ClickArea>
                            <input
                                ref={inputFileRef}
                                style={{ display: 'none' }}
                                type="file"
                                id="contactImage"
                                name="profile_image_data"
                                onChange={(e) => changeHandler(e)}
                            />
                        </UploadArea>
                    </Label>
                    <TextContainer>
                        <FormField title={L('contact_name')}>
                            <ContactText
                                placeholder={L('contact_name')}
                                name="name"
                                value={contactState.name || ''}
                                onChange={onChange}
                            />
                        </FormField>
                        <FormField title={L('contact_company')}>
                            <ContactText
                                placeholder={L('contact_company')}
                                name="company"
                                value={contactState.company || ''}
                                onChange={onChange}
                            />
                        </FormField>
                        <FormField title={L('contact_title')}>
                            <ContactText
                                placeholder={L('contact_title')}
                                name="title"
                                value={contactState.title || ''}
                                onChange={onChange}
                            />
                        </FormField>
                        <FormField title={L('phone_number')}>
                            <ContactText
                                placeholder={L('phone_number')}
                                name="phone_number"
                                value={contactState.phone_number || ''}
                                onChange={onChange}
                            />
                        </FormField>
                        <FormField title={L('contact_mail')}>
                            <ContactText
                                placeholder={L('contact_mail')}
                                name="email"
                                value={contactState.email || ''}
                                onChange={onChange}
                            />
                        </FormField>

                        <FormField title={L('contact_city')}>
                            <ContactText
                                placeholder={L('contact_city')}
                                name="city"
                                value={contactState.city || ''}
                                onChange={onChange}
                            />
                        </FormField>
                    </TextContainer>
                    <ButtonContainer>
                        {confirmDelete ? (
                            <Button
                                style={{ marginRight: margin.s }}
                                onClick={() => setConfirmDelete(false)}
                                variant={'primary'}
                            >
                                {L('delete')}
                            </Button>
                        ) : (
                            <Button style={{ marginRight: margin.s }} onClick={remove} variant={'danger'}>
                                {L('confirm')}
                            </Button>
                        )}
                        <Button onClick={save} style={{ marginRight: '0px' }}>
                            {L('save')}
                        </Button>
                    </ButtonContainer>
                </Card>
                <Card width="49%" background={style.colors.white}>
                    <DisplayField title={L('created_at')}>
                        {<DatetimeWorklog date={contactState.created_at || ''} />}
                    </DisplayField>
                    <DisplayField title={L('updated_at')}>
                        {<DatetimeWorklog date={contactState.updated_at || ''} />}
                    </DisplayField>
                </Card>
            </Content>
        </>
    );
};
