import { useMutation, useQueryClient } from 'react-query';
import { AddProduct } from '../../binders/schemas';
import { trpc } from '../../../lib/trpc';
import { getProductFilterKeys, getProductsKeys } from '../../binders/hooks/productQueries';

export default function useAddProductToTemplate() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddProduct }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.TemplatesApi.saveInventoryMaterial.query({
                product: dataToSave,
                propertyId: dataToSave.property_id,
            });
        },
        {
            onSettled: async (_data, _err, variables) => {
                queryClient.invalidateQueries(getProductsKeys.all);
                queryClient.invalidateQueries(
                    getProductFilterKeys.list({ propertyId: variables.inventoryMaterial.property_id }),
                );
            },
        },
    );
}
