import styled from '@emotion/styled';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { style } from '../../../../components/Shared/Style';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import H1 from '../../../../components/typography/H1';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import { Avatar } from '@ourliving/ourliving-ui';
import { desktop } from '../../../../components/MediaQuery/breakpoints';

const Form = styled.form({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: style.colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
});

const UploadArea = styled.div({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    marginTop: '2rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const AddContactFormSchema = z.object({
    name: z.string().min(1, { message: 'error_required' }),
    company: z.string().optional(),
    title: z.string().optional(),
    email: z.string().email({ message: 'contact_mail_error' }).optional().or(z.literal('')),
    phone_number: z.string().optional(),
    city: z.string().optional(),
    info: z.string().optional(),
});

export type AddContactForm = z.infer<typeof AddContactFormSchema>;

type Props = {
    onSubmit: (contact: AddContactForm, file?: File) => void;
    children?: ReactNode;
};

const ContactForm = ({ children, onSubmit }: Props) => {
    const [localImage, setlocalImage] = useState('');
    const [file, setFile] = useState<File>();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<AddContactForm>({
        resolver: zodResolver(AddContactFormSchema),
    });

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const myImg = URL.createObjectURL(file);
        setlocalImage(myImg);
        setFile(file);
    };

    return (
        <Form onSubmit={handleSubmit((data) => onSubmit(data, file))}>
            <H1>{`${L('new')} ${L('contact').toLocaleLowerCase()}`}</H1>
            <Label title={L('profile_image')}>
                <UploadArea>
                    <ClickArea htmlFor={'contactImage'}>
                        <Avatar src={localImage ? localImage : ''} name={''} />
                    </ClickArea>
                    <input
                        accept="image/*"
                        onChange={(e) => changeHandler(e)}
                        style={{ display: 'none' }}
                        type="file"
                        id="contactImage"
                    />
                </UploadArea>
            </Label>

            <ErrorAndInputWrapper errorMsg={errors.name?.message ? L(errors.name?.message) : ''}>
                <Label title={L('name')}>
                    <RestyledInput isError={!!errors.name} placeholder={L('name')} {...register('name')} />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.company?.message ? L(errors.company?.message) : ''}>
                <Label title={L('contact_company')}>
                    <RestyledInput
                        isError={!!errors.company}
                        placeholder={L('contact_company')}
                        {...register('company')}
                    />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.title?.message ? L(errors.title?.message) : ''}>
                <Label title={L('contact_title')}>
                    <RestyledInput isError={!!errors.title} placeholder={L('contact_title')} {...register('title')} />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.phone_number?.message ? L(errors.phone_number?.message) : ''}>
                <Label title={L('phone_number')}>
                    <RestyledInput
                        isError={!!errors.phone_number}
                        placeholder={L('phone_number')}
                        {...register('phone_number')}
                    />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.email?.message ? L(errors.email?.message) : ''}>
                <Label title={L('contact_mail')}>
                    <RestyledInput isError={!!errors.email} placeholder={L('contact_mail')} {...register('email')} />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.city?.message ? L(errors.city?.message) : ''}>
                <Label title={L('contact_city')}>
                    <RestyledInput isError={!!errors.city} placeholder={L('contact_city')} {...register('city')} />
                </Label>
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={errors.info?.message ? L(errors.info?.message) : ''}>
                <Label title={L('contact_extra_information')}>
                    <RestyledInput
                        isError={!!errors.info}
                        placeholder={L('contact_extra_information')}
                        {...register('info')}
                    />
                </Label>
            </ErrorAndInputWrapper>

            {children}
        </Form>
    );
};

export default ContactForm;
