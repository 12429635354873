import { SigningUser } from '@app/api/models/Orders';
import { Property } from '@app/api/models/Properties';
import styled from '@emotion/styled';
import React from 'react';
import toast from 'react-hot-toast';
import { Button } from '@ourliving/ourliving-ui';
import Spinner from '../../../../../components/Spinner/Spinner';
import { L } from '../../../../../lib/i18n';
import useAddUserToOrder from '../../hooks/useAddUserToOrder';
import useSetSignedManually from '../../hooks/useSetSignedManually';

const FlexContainer = styled.div({
    display: 'flex'
});

type Props = { propertyId: Property['id'] } & SigningUser &
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const SignUserOrder = ({ order_id, propertyId, user_id, status, email, is_user_on_order, ...rest }: Props) => {
    const { mutate: setSignedManually, status: signStatus } = useSetSignedManually();
    const { mutate: addUserToOrder, status: addUserToOrderStatus } = useAddUserToOrder();

    if (status === 'done') return null;
    const handleSign = () => {
        setSignedManually(
            { propertyId, orderId: order_id, signingUserId: user_id },
            {
                onError: () => {
                    toast(`❌ ${L('sign_manual_error')}`);
                },
                onSuccess: () => {
                    toast(`✅ ${email} ${L('sign_manual_success')}`);
                }
            }
        );
    };
    const handleAddUserToOrder = () => {
        addUserToOrder(
            { propertyId, userId: user_id },
            {
                onError: () => {
                    toast(`❌ ${L('add_user_to_order_manual_error')}`);
                },
                onSuccess: () => {
                    toast(`✅ ${email} ${L('add_user_to_order_manual_success')}`);
                }
            }
        );
    };

    if (!is_user_on_order) {
        return (
            <>
                {addUserToOrderStatus === 'idle' && (
                    <Button onClick={() => handleAddUserToOrder()}>{L('add_user_to_order')}</Button>
                )}
                {addUserToOrderStatus === 'loading' && <Spinner />}
                {addUserToOrderStatus === 'success' && null}
                {addUserToOrderStatus === 'error' && (
                    <Button onClick={() => handleAddUserToOrder()}>{L('add_user_to_order')}</Button>
                )}
            </>
        );
    }
    return (
        <>
            {signStatus === 'idle' && <Button onClick={() => handleSign()}>{L('sign')}</Button>}
            {signStatus === 'loading' && <Spinner />}
            {signStatus === 'success' && null}
            {signStatus === 'error' && <Button onClick={() => handleSign()}>{L('sign')}</Button>}
        </>
    );
};

export default SignUserOrder;
