import React from 'react';
import { Route } from 'react-router-dom';
import { NewsListPage } from './ListPage/NewsListPage';
import { NewsDetailPage } from './DetailPage/NewsDetailPage';

export const routes = [
    <Route key="0" exact path="/news" component={NewsListPage} />,
    <Route key="1" exact path="/news/:id" component={NewsDetailPage} />
];

export const navigation = [{ name: 'Nyheter', link: '/news', icon: 'News' }];
