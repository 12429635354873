import AddonsApi from '@app/api/public/AddonsApi';
import MembersApi from '@app/api/public/MembersApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';
import { getOrderUserKeys } from './useGetUsersOnOrder';

const setAddMember = async ({ propertyId, name, email }: Variables) => {
    const member = await MembersApi.addMember(email, name, propertyId);
    let r: any;
    if (member && member.user_id) r = await AddonsApi.addUserToOrder(+propertyId, member.user_id);
    return Promise.all([member, r]);
};

type Variables = {
    email: string;
    name: string;
    propertyId: number;
};

const useAddMember = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => setAddMember(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getOrderUserKeys.all);
            queryClient.invalidateQueries(getPropertyKeys.all);
        }
    });
};

export default useAddMember;
