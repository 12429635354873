import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import {
    useListBrands,
    useListSuppliers,
    useListProffesions,
    useListProductTypes,
} from '../../hooks/addProductQueries';
import { SubmitHandler } from 'react-hook-form';
import PackageProductForm from '../../components/Products/PackageProductForm';
import { AddPackageProduct } from '../../schemas';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { useGetSpaceName } from '../../hooks/spaceHooks/spaceQueries';
import useAddProductToPackage from '../../hooks/productHooks/useAddProductToPackage';
import { useGetProductName } from '../../hooks/productQueries';

const AddProductComponent = ({
    projectId,
    propertyId,
    packageId,
    spaceId,
}: {
    projectId: string;
    propertyId: string;
    packageId: string;
    spaceId?: string;
}) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const navigate = useNavigate();

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProductToPackage();

    const onSubmit: SubmitHandler<AddPackageProduct> = (data) =>
        saveInventoryMaterial(
            { inventoryMaterial: data, parentId: +packageId },
            {
                onSuccess: () => {
                    toast.success(L('product_added'));
                    navigate('..');
                },
            },
        );

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const isUnderSpaces = location.pathname.includes('/spaces/');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);
    const { data: packageNameData } = useGetProductName(+packageId);
    const { data: spaceNameData, status } = useGetSpaceName(spaceId ? +spaceId : null);
    return (
        <>
            {isUnderSpaces && (
                <>
                    <Breadcrumbs style={{ marginBottom: '1rem' }}>
                        <Breadcrumbs.Item to="../../../../../../../../..">
                            {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                        </Breadcrumbs.Item>
                        <Breadcrumbs.Item to="../../../../../../..">
                            {projectName ?? L('project_literally')}
                        </Breadcrumbs.Item>
                        <Breadcrumbs.Item to="../../../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                        <Breadcrumbs.Item to="../../..">{spaceNameData?.name ?? L('space')}</Breadcrumbs.Item>
                        <Breadcrumbs.Item to="..">{packageNameData?.name ?? L('package')}</Breadcrumbs.Item>
                        <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                    </Breadcrumbs>
                </>
            )}
            {!isUnderSpaces && (
                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                    <Breadcrumbs.Item to="../../../../../../..">
                        {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item to="../../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                    <Breadcrumbs.Item to="../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                    <Breadcrumbs.Item to="..">{packageNameData?.name ?? L('package')}</Breadcrumbs.Item>
                    <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                </Breadcrumbs>
            )}
            {status !== 'loading' && (
                <H1>{spaceId ? spaceNameData?.name ?? L('space') : propertyName ?? L('property')}</H1>
            )}
            <CardWithDivider
                topArea={<CardTitle>{L('add_product')}</CardTitle>}
                mainArea={<PackageProductForm onSubmit={onSubmit} options={options} />}
            />
        </>
    );
};

export default function AddNewProductToPackage() {
    const {
        id: projectId,
        propertyId,
        spaceId,
        productId,
    } = useParams<{ id: string; propertyId: string; productId: string; spaceId?: string }>();
    return projectId && propertyId && productId ? (
        <AddProductComponent projectId={projectId} propertyId={propertyId} spaceId={spaceId} packageId={productId} />
    ) : null;
}
