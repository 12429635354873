import { useObserver } from 'mobx-react';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Header } from '../../../../components/Layout/Header';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import { usePromise } from '../../../../lib/react';
import { LoginStore } from '../../../login/LoginStore';
import { AddonProductStore } from '../AddonProductStore';
import { Impersonate } from '../Components/Impersonate';

export function AddonProductListPage(props: RouteComponentProps<{ propertyId: string }>) {
    const id = props.match.params.propertyId;
    const [ready] = usePromise(async () => {
        await AddonProductStore.list(id);
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return <Impersonate propertyId={id} />;
    });
}
