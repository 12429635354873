import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';

type variables = {
    text: string;
    isTransferred: boolean;
};
const useSaveResponseText = () => {
    const client = useQueryClient();
    return useMutation((variables: variables) => TicketsApi.saveResponseText(variables.text, variables.isTransferred), {
        onSuccess: (data, variables) => {
            client.invalidateQueries(ticketQueryKeys.responseText());
        }
    });
};

export { useSaveResponseText };
