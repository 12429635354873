import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { RouterOutputs } from '@app/trpc-api';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { L } from '../../lib/i18n';
import TableImageWithFallback from '../binders/components/TableImageWithFallback';

const columnHelper = createColumnHelper<RouterOutputs['ProductLibraryApi']['listProducts']['items'][0]>();

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const ItalicSpan = styled.span({
    fontStyle: 'italic',
});

const columns = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        enableResizing: false,
    }),
    columnHelper.display({
        id: 'image',
        cell: ({ row }) => (
            <div style={{ width: '100px', aspectRatio: '4/3' }}>
                <TableImageWithFallback id={row.original?.product_image_data?.original?.id} />
            </div>
        ),
        enableResizing: false,
        size: 75,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
    }),
    columnHelper.accessor('name', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{row.original.name ? L(row.original.name) : <ItalicSpan>{L('name_missing')}</ItalicSpan>}</Data>
            </CellContainer>
        ),
        minSize: 135,
        size: 140,
        header: () => <Flex>{L('product_group')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('function', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>
                    {row.original?.function && row.original.function.length > 0 ? L(row.original.function) : ''}
                </Data>
            </CellContainer>
        ),
        minSize: 90,
        size: 130,
        header: () => <Flex>{L('function')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('product_name', {
        cell: ({ row }) =>
            row.original?.count ? (
                <CellContainer>
                    <Data>
                        {row.original?.product_name ? (
                            row.original.product_name
                        ) : (
                            <ItalicSpan>{L('name_missing')}</ItalicSpan>
                        )}{' '}
                        <span
                            style={{
                                fontWeight: 'bolder',
                            }}
                        >
                            ({row.original?.count}C)
                        </span>
                    </Data>
                </CellContainer>
            ) : (
                <CellContainer>
                    <Data>{row.original?.product_name}</Data>
                </CellContainer>
            ),
        minSize: 80,
        size: 250,
        header: () => <Flex>{L('product_title')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('color', {
        cell: ({ row }) => <CellContainer>{row.original.color ? L(row.original?.color) : ''}</CellContainer>,
        minSize: 60,
        size: 150,
        header: () => <Flex>{L('product_color')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('brand', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{row.original?.brand ? L(row.original.brand) : ''}</Data>
            </CellContainer>
        ),
        minSize: 115,
        size: 150,
        header: () => <Flex>{L('brand')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('source', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{row.original.source ? L(row.original.source) : ''}</Data>
            </CellContainer>
        ),
        minSize: 115,
        size: 150,
        header: () => <Flex>{L('source')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
];

export default columns;
