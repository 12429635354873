import React from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import ContentNewsForm, { AddContentNews } from '../../components/ContentNews/ContentNewsForm';
import useAddContentNews from '../../hooks/ContentNews/useAddContentNews';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { getErrorMessage } from '@app/shared/lib/getErrorMessage';

const AddContentNewsPage = () => {
    const navigate = useNavigate();

    const { mutate: addContentContentNews } = useAddContentNews();
    const { id } = useParams<{ id: string }>();

    const onSubmit: SubmitHandler<AddContentNews> = ({ image, ...news }) => {
        if (!id) return;
        addContentContentNews(
            { news: news, file: image, projectId: +id },
            {
                onSuccess: ({ id }) => {
                    toast.success(L('content_news_added'));
                    navigate(`../${id}`);
                },
                onError: (err) => {
                    toast.error(L(getErrorMessage(err)));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_content_news')}</CardTitle>}
            mainArea={<ContentNewsForm onSubmit={onSubmit} />}
        />
    );
};

export default AddContentNewsPage;
