import { Maintenance } from '@app/api/public/MaintenancesApi';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { QueryStatus } from 'react-query';
import { Button } from '@ourliving/ourliving-ui';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import FormHeader from '../../../components/Forms/FormHeader';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import Spinner from '../../../components/Spinner/Spinner';
import { L } from '../../../lib/i18n';
import { COLUMNS } from './columns';
import Table from './Table';

const GridSection = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    gridColumnGap: '20px',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateAreas: `
    "plnadedDate interval intervalEndDate"
    "ButtonWraper ButtonWraper ButtonWraper"
    `,
});

const Wrapper = styled.div({
    width: '100%',
});

const Value = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FCFDFE',
    color: 'black',
    width: '50px',
    height: '32px',
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',
    border: '1px solid #F0F1F7',
});
const ValueWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '32px',
});
const StyledValueInput = styled(StyledInput)({
    borderRadius: '4px 0 0 4px',
    borderRight: 0,
});

const ButtonWraper = styled.div({
    gridArea: 'ButtonWraper',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'center',
    width: '100%',
    margin: '40px 0px',
});

type Props = {
    handleChange: (value: Partial<Maintenance>) => void;
    intervalEndDate: string | undefined;
    maintenanceItem: Partial<Maintenance> | undefined;
    setIntervalEndDate: React.Dispatch<React.SetStateAction<string | undefined>>;
    validIntervals: string[];
    setView: React.Dispatch<React.SetStateAction<'edit' | 'preview'>>;
    handleSave: () => void;
    status: QueryStatus;
    maintenanceIntevalItems: Partial<Maintenance>[];
};

const MaintenancePreview = ({
    handleChange,
    intervalEndDate,
    maintenanceItem,
    setIntervalEndDate,
    validIntervals,
    setView,
    handleSave,
    status,
    maintenanceIntevalItems,
}: Props) => {
    const [month, setMonth] = useState<number>(0);
    const [year, setYear] = useState<number>(0);

    useEffect(() => {
        handleChange({ interval: year * 12 + month });
    }, [month, year]);
    return (
        <Wrapper>
            <FormHeader style={{ margin: '0px' }}>
                {L('create_interval_of')} {maintenanceItem?.name}
            </FormHeader>
            <GridSection>
                <Label title={L('planned_date')}>
                    <StyledInput
                        type={'date'}
                        value={
                            maintenanceItem?.planned_date
                                ? dayjs(maintenanceItem?.planned_date).format('YYYY-MM-DD')
                                : ''
                        }
                        onChange={(e) => handleChange({ planned_date: e.target.value })}
                    />
                </Label>
                {/* intervall */}
                <Label title={L('interval_year')}>
                    <ValueWrapper>
                        <StyledValueInput
                            type={'number'}
                            min={1}
                            max={100}
                            onChange={(e) => {
                                setYear(+e.target.value);
                            }}
                            value={year || ''}
                        />
                        <Value>{L('year')}</Value>
                    </ValueWrapper>
                </Label>
                <Label title={L('interval_months')}>
                    <ValueWrapper>
                        <StyledValueInput
                            type={'number'}
                            min={1}
                            max={100}
                            onChange={(e) => {
                                setMonth(+e.target.value);
                            }}
                            value={month || ''}
                        />
                        <Value>{L('month_short')}</Value>
                    </ValueWrapper>
                </Label>
                {/* start slutdatum intervall datum */}
                <Label title={L('interval_end_date')}>
                    <Dropdown
                        label={L('year')}
                        value={intervalEndDate}
                        onChange={(value) => setIntervalEndDate(value)}
                        options={validIntervals.map((date) => ({ value: date, label: dayjs(date).format('YYYY-MM') }))}
                    />
                </Label>
            </GridSection>

            <ButtonWraper>
                <Button type="button" onClick={() => setView('edit')}>
                    {L('back')}
                </Button>

                {status === 'idle' && <Button onClick={() => handleSave()}>{L('save')}</Button>}
                {status === 'loading' && (
                    <Button onClick={() => handleSave()} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && <Button onClick={() => handleSave()}>{L('error')}</Button>}
                {status === 'success' && <Button onClick={() => handleSave()}>{L('saved')}</Button>}
            </ButtonWraper>

            <FormHeader>{L('previewing')}</FormHeader>
            {maintenanceItem && (
                <Table
                    Columns={COLUMNS}
                    to={'/maintenance/'}
                    search={false}
                    edit={false}
                    Data={[maintenanceItem, ...maintenanceIntevalItems]}
                />
            )}
        </Wrapper>
    );
};

export default MaintenancePreview;
