import { useMutation } from 'react-query';
import { RouterInput, trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../ContentInformation/uploadToAws';

export default function useUploadProductDocumentsPropertyTemplate() {
    return useMutation(
        async ({
            files,
            propertyId,
            productId,
            documentType,
        }: {
            files: File[];
            propertyId: number;
            productId: number;
            documentType?: RouterInput['ProductsApi']['uploadProductDocumentTemplateProperty']['documentType'];
        }) => {
            const responses = files.map(async (file) => {
                const fileToSave = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                };

                const response = await trpc.ProductsApi.uploadProductDocumentTemplateProperty.mutate({
                    file: fileToSave,
                    propertyId,
                    productId,
                    documentType: documentType,
                });

                if (response) {
                    await uploadToAws({
                        preSingedUrl: response.preSingedUrl,
                        file,
                        cleanup: async () => {
                            await trpc.ProductsApi.removeProductDocumentTemplateProperty.mutate({
                                id: response.id,
                                entityId: +productId,
                            });
                        },
                    });
                }
            });
            return await Promise.all(responses);
        },
    );
}
