import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { RouterInputs } from '@app/trpc-api';

export const getProjectKeys = {
    all: ['binders-project'] as const,
    lists: () => [...getProjectKeys.all, 'list'] as const,
    list: (params: RouterInputs['BindersApi']['getProjectsOnStatus']) => [...getProjectKeys.lists(), params] as const,
    listLean: (params: RouterInputs['BindersApi']['getProjectsLean']) =>
        [...getProjectKeys.lists(), 'lean', params] as const,
    details: () => [...getProjectKeys.all, 'detail'] as const,
    detail: (projectId?: number) => [...getProjectKeys.details(), projectId] as const,
    projectOwners: (params: RouterInputs['BindersApi']['listProjectOwners']) =>
        [...getProjectKeys.all, 'projectOwners', params] as const,
    projectCommunes: (params: RouterInputs['BindersApi']['listProjectCommunes']) =>
        [...getProjectKeys.all, 'communes', params] as const,
    images: () => [...getProjectKeys.all, 'images'] as const,
    imagesByProjectId: (projectId: number) => [...getProjectKeys.images(), projectId, 'images'] as const,
    documents: () => [...getProjectKeys.all, 'documents'] as const,
    docsByProjectId: (params: { projectId: number | null; search: string; page?: string }) =>
        [...getProjectKeys.documents(), params] as const,
    docsByProjectIdInfinite: (params: { projectId: number | null; search: string; page?: string }) =>
        [...getProjectKeys.documents(), 'infinite', params] as const,
};

export function useGetProject(projectId: number) {
    return useQuery(getProjectKeys.detail(projectId), () => trpc.BindersApi.getProject.query({ projectId: projectId }));
}

export function useGetProjectImages(projectId: number) {
    return useQuery(
        [...getProjectKeys.imagesByProjectId(projectId)],
        async () =>
            trpc.BindersApi.listProjectImages.query({
                projectId,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProjects() {
    return useQuery(getProjectKeys.lists(), () => trpc.BindersApi.getProjects.query());
}

export function useGetProjectsLean(params: RouterInputs['BindersApi']['getProjectsLean']) {
    return useQuery(getProjectKeys.listLean(params), () => trpc.BindersApi.getProjectsLean.query(params));
}

export function useGetProjectsOnStatus(params: RouterInputs['BindersApi']['getProjectsOnStatus']) {
    return useQuery(getProjectKeys.list(params), () => trpc.BindersApi.getProjectsOnStatus.query(params), {
        keepPreviousData: true,
    });
}

export function useGetProjectOwners(params: RouterInputs['BindersApi']['listProjectOwners']) {
    return useQuery(getProjectKeys.projectOwners(params), () => trpc.BindersApi.listProjectOwners.query(params));
}

export function useGetProjectCommunes(params: RouterInputs['BindersApi']['listProjectCommunes']) {
    return useQuery(getProjectKeys.projectCommunes(params), () => trpc.BindersApi.listProjectCommunes.query(params));
}

export function useGetProjectDocuments({ projectId, search }: { projectId: number | null; search: string }) {
    return useQuery(
        getProjectKeys.docsByProjectId({ projectId, search }),
        () => {
            if (projectId === null) return Promise.reject(new Error('no project id provided'));
            return trpc.BindersApi.getProjectDocuments.query({
                projectId,
                search,
            });
        },
        {
            enabled: !!projectId,
            keepPreviousData: true,
        },
    );
}

export function useGetProjectDocumentsPaginated({ projectId, search }: { projectId: number | null; search: string }) {
    return useInfiniteQuery(
        getProjectKeys.docsByProjectIdInfinite({ projectId, search }),
        async ({ pageParam = 1 }) => {
            if (projectId === null) return Promise.reject(new Error('no project id provided'));
            return trpc.BindersApi.getProjectDocumentsPaginated.query({
                projectId,
                search,
                page: pageParam,
            });
        },
        {
            enabled: !!projectId,
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}
