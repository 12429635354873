import * as React from 'react';
import { usePromise } from '../../../../lib/react';
import { Header } from '../../../../components/Layout/Header';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import { Loading } from '../../../../components/Loading/Loading';
import { useObserver } from 'mobx-react';
import { Table } from '../../../../components/Table/Table';
import { Card } from '../../../../components/Layout/Card';
import { EntitySupplierStore } from './EntitySupplierStore';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { AboveCard } from '../../../../components/Layout/AboveCard';
import { Button } from '@ourliving/ourliving-ui';
import { NewSupplier } from './Components/NewSupplier';
import { style } from '../../../../components/Shared/Style';
import { Icon } from '../../../../components/Icon/Icon';

const Text = styled.div({
    textAlign: 'left',
});

export function EntitySupplierListPage() {
    const [ready] = usePromise(async () => {
        await EntitySupplierStore.load();
    }, []);

    const [newSupplier, setNewSupplier] = React.useState(false);

    // const onClick = async (id: number) => {
    //     EntitySupplierStore.selectEntitySupplierId = id;
    //     await EntitySupplierStore.getById();
    // };

    const onSelect = (property_id: string) => {
        if (EntitySupplierStore.selectedEntitySupplierIds.has(property_id)) {
            EntitySupplierStore.selectedEntitySupplierIds.delete(property_id);
        } else {
            EntitySupplierStore.selectedEntitySupplierIds.add(property_id);
        }
    };

    const onNew = () => {
        setNewSupplier(true);
        EntitySupplierStore.entitySupplierEdit = {};
    };

    const onEdit = async (id: number) => {
        await EntitySupplierStore.getById(id);
        setNewSupplier(true);
    };

    const onRemove = () => {
        EntitySupplierStore.remove();
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }
        const entitySuppliers = EntitySupplierStore.entitySuppliers;
        const rows = entitySuppliers.map((r) => ({
            brand: <Text>{r.brand_name}</Text>,
            product_type: <Text>{L(`${r.product_type_name}`)}</Text>,
            supplier: <Text>{r.supplier_name}</Text>,
            profession: <Text>{r.profession_name}</Text>,
            edit: <Icon.Edit onClick={() => onEdit(r.id)} />,
            select: (
                <Checkbox
                    name="Slutdatum"
                    defaultChecked={EntitySupplierStore.selectedEntitySupplierIds.has(r.id.toString())}
                    onChange={() => onSelect(r.id.toString())}
                />
            ),
        }));

        return (
            <>
                <Header to={'./'} text={`<-` + L('backtoreport')} title={L('')} />
                <AboveCard padding={'0px 32px'} justifyContent={'space-between'}>
                    <Button onClick={onNew} style={{ margin: '0px' }}>
                        {L('new')}
                    </Button>
                    <span style={{ color: style.colors.errors }}>
                        {EntitySupplierStore.saveError && EntitySupplierStore.saveError}
                    </span>

                    <Button
                        onClick={onRemove}
                        style={{ margin: '0px' }}
                        variant={'danger'}
                        disabled={EntitySupplierStore.selectedEntitySupplierIds.size === 0}
                    >
                        {L('remove_chosen')}
                    </Button>
                </AboveCard>
                <Card>
                    {newSupplier && (
                        <NewSupplier id={EntitySupplierStore.entitySupplierEdit?.id} setNewSupplier={setNewSupplier} />
                    )}
                    <Table
                        columns={[
                            { key: 'product_type', header: L('function'), width: '2fr' },
                            { key: 'brand', header: L('brand'), width: '2fr' },
                            { key: 'supplier', header: L('supplier'), width: '2fr' },
                            {
                                key: 'profession',
                                header: L('profession'),
                                width: '2fr',
                            },
                            {
                                key: 'edit',
                                header: L('edit'),
                                width: '0.6fr',
                            },
                            {
                                key: 'select',
                                header: L('select'),
                                width: '0.6fr',
                            },
                        ]}
                        data={rows}
                    />
                </Card>
            </>
        );
    });
}
