import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import {
    useListBrands,
    useListSuppliers,
    useListProffesions,
    useListProductTypes,
    useListProperties,
    useListSpaces,
} from '../../binders/hooks/addProductQueries';
import { SubmitHandler } from 'react-hook-form';
import ProductForm from '../../binders/components/Products/ProductForm';
import { AddProduct } from '../../binders/schemas';
import {
    getProductsKeys,
    useGetProduct,
    useGetProductsDocuments,
    useGetProductsImages,
} from '../../binders/hooks/productQueries';
import useUpdateProduct from '../../binders/hooks/productHooks/useUpdateProduct';
import DocumentAreaAndDropZone from '../../../components/DocumentCommunity/DocumentAreaAndDropZone';
import dayjs from 'dayjs';
import ErrorAndInputWrapper, { InputWithError } from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { Separator } from '../../../components/Separator/Separator';
import useDragEndDnd from '../../../hooks/useDragEndDndNew';
import { Loading } from '../../../components/Loading/Loading';
import useUploadProductDocuments from '../../binders/hooks/productHooks/useUploadProductDocuments';
import useUpdateProductDocSort from '../../binders/hooks/productHooks/useUpdateProductDocSort';
import useUpdateProductDocName from '../../binders/hooks/productHooks/useUpdateProductDocName';
import useUpdateProductDocType from '../../binders/hooks/productHooks/useUpdateProductDocType';
import useRemoveProductDocument from '../../binders/hooks/productHooks/useRemoveProductDocument';
import { useGetProperty } from '../../binders/hooks/propertyHooks/propertyQueries';
import Images from '../../binders/components/Draggables/Images';
import ImageDropZoneNoText from '../../../components/ImageDropzone/ImageDropZoneNoText';

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const Divider = styled(Separator)({
    backgroundColor: style.colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const EditProductCopyFunction = ({ productId }: { productId: string }) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const { data: product, isLoading } = useGetProduct(+productId);
    const { data: productProperty } = useGetProperty(product?.property_id ?? null);
    const { data: properties } = useListProperties(productProperty?.project_id ?? null);
    const [selectedPropertyId, setSelectedPropertyId] = useState(0);
    const { data: spaces } = useListSpaces(selectedPropertyId);

    const handleSetSelectedPropertyId = (val: number) => setSelectedPropertyId(val);

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        properties: properties ? properties.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: updateInventoryMaterial } = useUpdateProduct();

    const onSubmit: SubmitHandler<AddProduct> = (data) =>
        updateInventoryMaterial(
            { inventoryMaterial: data, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('product_updated'));
                },
                onError: () => {
                    toast.error(L('product_not_updated'));
                },
            },
        );

    const { data: documents } = useGetProductsDocuments(+productId);
    const { data: images } = useGetProductsImages(+productId);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateProductDocSort();
    const { mutate: uploadDocs } = useUploadProductDocuments();
    const { mutate: removeDocument } = useRemoveProductDocument();
    const { mutate: renameDocument } = useUpdateProductDocName();
    const { mutate: updateDocType } = useUpdateProductDocType();

    const queryClient = useQueryClient();
    const handleSaveMultipleDocs = (files: File[]) => {
        if (!product?.property_id) return;
        uploadDocs(
            { files, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('documents_uploaded'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
            },
        );
    };

    const onImageUpload = (files: File[]) => {
        if (!product?.property_id) return;
        uploadDocs(
            { files, productId: +productId, documentType: 0 },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const handleRemoveDocument = (documentId: number) => {
        removeDocument(
            { documentId, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRenameDocument = (documentId: number, name: string) => {
        renameDocument(
            { docId: documentId, name, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('renamed_document')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleUpdateDocType = ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => {
        updateDocType(
            { docId: documentId, document_type, document_subtype, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('updated_document_types'));
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDragEndDocs = useDragEndDnd({
        queryKey: getProductsKeys.documentsByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const handleDragEndImages = useDragEndDnd({
        queryKey: getProductsKeys.imagesByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const imagesData =
        images?.map((document) => {
            return { id: document.id, url: document.signed_url };
        }) ?? [];

    if (isLoading) return <Loading />;

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('edit_product')}</CardTitle>}
            mainArea={
                <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '1rem' }}>
                    {product && (
                        <ProductForm
                            handleSetSelectedPropertyId={handleSetSelectedPropertyId}
                            onSubmit={onSubmit}
                            options={options}
                            defaultValues={product}
                            oneFieldPerRow
                            edit
                        />
                    )}
                    <DividerContainer>
                        <Divider decorative orientation="vertical" />
                    </DividerContainer>
                    <div>
                        <div>
                            <div>
                                <ErrorAndInputWrapper>
                                    <Label title={L('created_at')}>
                                        <InputWithError
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(product?.created_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </Label>
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <Label title={L('updated_at')}>
                                        <InputWithError
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(product?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </Label>
                                </ErrorAndInputWrapper>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Images
                                    imageData={imagesData}
                                    handleRemoveImage={handleRemoveDocument}
                                    handleDragEnd={handleDragEndImages}
                                    isUpdatingSort={isUpdatingSort}
                                >
                                    <ImageDropZoneNoText onDrop={onImageUpload} />
                                </Images>
                            </div>
                            <StyledH3>{L('documents')}</StyledH3>
                            <DocumentAreaAndDropZone
                                documents={documents}
                                removeDocument={handleRemoveDocument}
                                saveMultipleDocs={handleSaveMultipleDocs}
                                renameDocument={handleRenameDocument}
                                updateDocType={handleUpdateDocType}
                                handleDragEnd={handleDragEndDocs}
                                isUpdatingSort={isUpdatingSort}
                            />
                        </div>
                    </div>
                </div>
            }
        />
    );
};

export default EditProductCopyFunction;
