import styled from '@emotion/styled';
import React, { FC } from 'react';

const HeaderText = styled.h2({
    fontsize: '1rem',
    fontWeight: 600,
});

const FormHeader: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
    children,
    ...rest
}) => {
    return <HeaderText {...rest}>{children}</HeaderText>;
};

export default FormHeader;
