import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';
import { getProductFilterKeys, getProductsKeys } from '../productQueries';
import { getPropertyProductsTemplatesKeys } from '../../../project-templates/hooks/PropertyTemplates/propertyProductsTemplateQueries';
import { TRPCClientError } from '@trpc/client';

const useImportTemplateProductToLibraryProduct = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (options: { products: { id: number; package_type: string | null }[]; toProductId: number }) => {
            return trpc.BindersApi.importTemplateProductsToLibraryProduct.mutate({
                products: options.products,
                toProductId: options.toProductId,
            });
        },
        {
            onSettled: (data) => {
                const numberOfErrors: number[] = [];
                const numberOfSuccesses: number[] = [];
                data?.forEach((item) => {
                    if (item.message.status === 'error') numberOfErrors.push(item.id);
                    if (item.message.status === 'ok') numberOfSuccesses.push(item.id);
                });
                if (numberOfErrors.length > 0) toast.error(L('copy_fail'));
                else if (numberOfSuccesses.length > 0 && numberOfErrors.length === 0)
                    numberOfSuccesses.length > 1
                        ? toast.success(`${numberOfSuccesses.length} ${L('products_was_added').toLowerCase()}`)
                        : toast.success(`${numberOfSuccesses.length} ${L('product_was_added').toLowerCase()}`);
                queryClient.invalidateQueries([...getProductsKeys.infiniteLists()]);
                queryClient.invalidateQueries([...getProductsKeys.lists()]);
                queryClient.invalidateQueries([...getPropertyProductsTemplatesKeys.infiniteLists()]);
                queryClient.invalidateQueries(getProductFilterKeys.lists());
                queryClient.invalidateQueries(getProductFilterKeys.lists());
            },
            onError(err) {
                if (err instanceof TRPCClientError) {
                    if (err.message.includes('product is a package and cannot be added to another product'))
                        toast.error(L('add_package_to_package_err'));
                    if (err.message.includes(`a product can't be added to another product in a package`))
                        toast.error(L('add_product_to_package_product_err'));
                } else toast.error(L('an_error_occurred'));
            },
        },
    );
};

export default useImportTemplateProductToLibraryProduct;
