const whoisCreator = (creatorId: number, myId?: number, boardMembers?: number[]) => {
    if (myId === creatorId) {
        return 'me';
    }
    if (boardMembers?.includes(creatorId)) {
        return 'collaborator';
    }
    return 'customer';
};

export default whoisCreator;
