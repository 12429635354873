import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { ReportHeaderLogo } from './SupplierReportHeaderLogo';
import { ReportHeaderPrintDate } from './SupplierReportHeaderPrintDate';

const Header = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: style.fontSize.gamma,
    lineHeight: style.lineHeight.normal,
    padding: `${style.margin.m}px ${style.margin.m}px ${style.margin.xs}px ${style.margin.m}px`,
});

const Row = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
});

const Properties = styled.div({
    fontSize: style.fontSize.seta
});

const Property = styled.span({
    fontSize: style.fontSize.micro,
    lineHeight: style.lineHeight.tight
});

const Project = styled.div({});

export const ReportHeader = ({ data, logo }) => {
    return (
        <Header>
            <Row>
                <ReportHeaderLogo logo={logo} />
                <ReportHeaderPrintDate />
            </Row>
            <Project>{data?.name}</Project>
            <Properties>
                Lägenheter: {''}
                {data?.properties
                    ?.sort((a, b) => a.id - b.id)
                    ?.map((r, i) => (
                        <Property key={i}>{`${r.name}, `}</Property>
                    ))}
            </Properties>
        </Header>
    );
};
