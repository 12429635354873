import { ExcelPropertyImport } from '@app/api/models/Properties';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../../lib/i18n';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';

const saveImportPropertiesList = async (properties: ExcelPropertyImport[], projectId: number) => {
    const propertyPromises = properties.map(async (property) => {
        const template = await getPropertyFromTemplateName(property.template_name, projectId);
        if (template === null) {
            toast.error(`${property.template_name} ${L('template_not_found')}`);
            throw new Error('Template not found');
        }
        return {
            template,
            property: property,
        };
    });
    const propertyTemplates = await Promise.all(propertyPromises);
    const propertyTemplatePromise = await Promise.all(
        propertyTemplates.map(async (propertyTemplate) => {
            const propertyId =
                propertyTemplate.template &&
                (await trpc.BindersApi.copyFullPropertyImport.mutate({
                    toPropertyName: propertyTemplate.property.name,
                    propertyId: propertyTemplate.template.id,
                    copyDocuments: true,
                    projectId: projectId,
                    property: propertyTemplate.property,
                }));

            return await Promise.all(
                propertyTemplate.property.members.map(async (member) => {
                    return (
                        propertyId &&
                        (await trpc.MembersApi.addUserToProperty.mutate({
                            email: member.email,
                            propertyId: propertyId,
                            name: member.name,
                        }))
                    );
                }),
            );
        }),
    );
    return propertyTemplatePromise;
};

const getPropertyFromTemplateName = (template_name: string, projectId: number) => {
    return trpc.BindersApi.getPropertyFromTemplateName.query({ templateName: template_name, projectId });
};

type Variables = {
    properties: ExcelPropertyImport[];
    projectId: number;
};

const useSaveImportPropertiesList = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (variables: Variables) => await saveImportPropertiesList(variables.properties, variables.projectId),
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
            },
        },
    );
};

export { useSaveImportPropertiesList };
