import React from 'react';
import { Route } from 'react-router-dom';
import { ContactsPage } from './ContactsPage';

export const routes = [
    <Route key="0" exact path="/contacts" component={ContactsPage} />,
    <Route key="1" path="/contacts/:id" component={ContactsPage} />
];

export const navigation = [{ name: 'contacts', link: '/contacts', icon: 'Contact' }];
