import { ContentCalendarStore } from '../ContentCalendarStore';
import React from 'react';
import { usePromise } from '../../../lib/react';
import { useObserver } from 'mobx-react';
import { FormField } from '../../../components/Forms/FormField';
import { TextInput } from '../../../components/Forms/Text';
import { Button, Dropzone } from '@ourliving/ourliving-ui';
import { DisplayField } from '../../../components/DisplayField/DisplayField';
import { Card } from '../../../components/Layout/Card';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import { Table } from '../../../components/Table/Table';
import { Editor } from '@tinymce/tinymce-react';
import { DatePick } from '../../../components/DatePick/DatePick';
import { DatetimeWorklog } from '../../../components/DateTimeWorklog/DateStatusWorklog';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui/dist/components/DropZone/DropZone';
//#region css

const Content = styled.div({
    display: 'flex',
    flexDirection: 'row',
    padding: style.margin.l,
});

const Time = styled.input({
    display: 'flex',
    width: '10%',
    minWidth: '70px',
    paddingLeft: style.margin.s,
});

const Checkbox = styled.input({
    display: 'flex',
    width: '5%',
});

const Attendees = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});

const ContentRight = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
});

const Search = styled.input({
    background: style.colors.white,
    border: '1px solid #F0F1F7',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    lineHeight: '1.5em',
    marginRight: style.margin.m,
    fontSize: style.fontSize.seta,
    padding: style.margin.xs,
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
});

const Text = styled.div({
    padding: style.margin.xs,
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    marginRight: style.margin.m,
    lineHeight: '32px',
});

//#endregion

export function CalendarDetailEditor(props: { id?: string }) {
    const store = ContentCalendarStore;

    const [ready] = usePromise(async () => {
        await store.load(props.id);
    }, [props.id]);

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return;

        store.setValue(param.file, 'image');
        store.imageUrl = URL.createObjectURL(param.file);
    };

    const onChange = (e) => {
        store.searchFilter = e.target.value;
    };

    const checkChange = (e) => {
        store.setValue(e.target.checked, e.target.name);
    };

    const save = () => {
        store.save();
    };

    const setValue = (value: any, name: any) => {
        store.setValue(value, name);
    };

    return useObserver(() => {
        const f = store.participants.filter((email) => email.user_email.toLowerCase().includes(store.searchFilter));

        const items =
            ready &&
            f.map((f) => ({
                email: <div>{f.user_email}</div>,
                antal: <div>{f.count}</div>,
                skapad: <DatetimeWorklog date={f.created_at} />,
            }));
        if (!ready) return null;
        const { errors, model } = store;
        return (
            <>
                <Content>
                    <Card width="49%" background={style.colors.white} margin={`0 12px 0 0px`}>
                        <FormField title="Bild" error={errors.title}>
                            <Dropzone
                                onUpload={onUpload}
                                variant="Media"
                                remove={() => (store.imageUrl = '')}
                                url={store.imageUrl}
                            />
                        </FormField>

                        <FormField title={L('title')} error={errors.title}>
                            <TextInput name="title" value={model.title || ''} onChange={store.setValue} />
                        </FormField>
                        <FormField title={L('event_date')} error={errors.title} />
                        <DatePick
                            date={store.loadDate || store.date}
                            dateChange={(date) => setValue(date, 'event_date')}
                            name={'event_date'}
                        />
                        <FormField title={L('time')} error={errors.title}>
                            <Time
                                type="time"
                                min="00:00"
                                max="23:59"
                                name="event_time"
                                defaultValue={store.loadTime || ''}
                                onChange={(e) => setValue(e.target.value, e.target.name)}
                            />
                        </FormField>
                        <FormField title={L('location_calendar')} error={errors.title}>
                            <TextInput name="location" value={model.location || ''} onChange={store.setValue} />
                        </FormField>

                        <FormField title={L('published')}>
                            <Checkbox
                                type="checkbox"
                                name="locked"
                                defaultChecked={model.locked || undefined}
                                onChange={checkChange}
                            />
                        </FormField>
                        <FormField title={L('registration_open')}>
                            <Checkbox
                                type="checkbox"
                                name="register_enabled"
                                defaultChecked={model.register_enabled || undefined}
                                onChange={checkChange}
                            />
                        </FormField>
                        <FormField title={L('boardmember_area')}>
                            <Checkbox
                                type="checkbox"
                                // name="boardmember_area"
                                // defaultChecked={model.boardmember_area || undefined}
                                // onChange={checkChange}
                            />
                        </FormField>
                        <FormField title="Description" error={errors.description}>
                            <Editor
                                initialValue={model.description || ''}
                                value={model.description}
                                apiKey={'280lwnf4vg3t97aajf1fzc9hm89s7ikxzzq26tjd7hn09aaa'}
                                outputFormat="html"
                                onEditorChange={(value) => setValue(value, 'description')}
                                init={{ menubar: false, branding: false }}
                                textareaName="description"
                            />
                        </FormField>
                        <ButtonContainer>
                            <StyledNavLink to="./">
                                <Button>{L('cancel')}</Button>
                            </StyledNavLink>
                            <Button onClick={save} style={{ marginRight: style.margin.s }}>
                                {L('save')}
                            </Button>
                        </ButtonContainer>
                    </Card>
                    <ContentRight>
                        <Card background={style.colors.white}>
                            <DisplayField title={L('created_at')}>
                                <DatetimeWorklog date={model.created_at} />
                            </DisplayField>
                            <DisplayField title={L('published')}>{model.published_at}</DisplayField>
                            <DisplayField title={L('created_by')}>{model.created_by_id}</DisplayField>
                            <ButtonContainer>
                                <Button>{L('notify_again')}</Button>
                            </ButtonContainer>
                        </Card>
                        <Card margin="16px 0px 0px 0px" background={style.colors.white}>
                            <Attendees>
                                <Search onChange={onChange} placeholder={L('search_attendee')} />
                                <Text>
                                    {L('signed')}: {store.participantAmount}
                                </Text>
                                <Text>
                                    {L('participants')}: {store.participantCount}
                                </Text>
                            </Attendees>
                            <Table
                                columns={[
                                    { key: 'email', header: L('email'), arrows: true, width: '1.5fr' },
                                    { key: 'antal', header: L('antal'), arrows: true, width: '0.5fr' },
                                    { key: 'skapad', header: L('created'), arrows: true, width: '0.5fr' },
                                ]}
                                data={items}
                            />
                        </Card>
                    </ContentRight>
                </Content>
            </>
        );
    });
}
