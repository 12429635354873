import { SupplierFilter } from '@app/api/models/Addons';
import { style } from '@app/shared/lib/Style';
import styled from '@emotion/styled';
import * as React from 'react';
import { L } from '../../../../../lib/i18n';
import { useAddonReport } from '../context/AddonReportContext';

interface Props {
    filterPanel: {
        filterName: string;
        filterListValue?: string[] | number[];
        isID?: boolean;
        list: any[];
    }[];
}

const Container = styled.div({
    width: '70%',
    marginTop: style.margin.m
});

const Name = styled.div({
    color: style.colors.secondary,
    fontSize: style.fontSize.milli,
    textTransform: 'uppercase',
    fontWeight: style.fontWeight.bold,
    width: '10%',
    height: '100%',
    paddingTop: style.margin.xs
});

const Row = styled.div({
    width: '100%'
});

const Value = styled.div({
    display: 'inline-block',
    margin: `2px ${style.margin.xs}px 2px 0px`,
    backgroundColor: style.colors.pageBackground,
    border: `1px solid ${style.colors.black50}`,
    padding: '2px',
    borderRadius: style.roundCornerSize.small,
    cursor: 'pointer',
    fontSize: style.fontSize.milli,
    color: '#808080'
});

const onClick = (props, value, name) => {
    const updatedFilter = props.filter[name].filter(filterValue => filterValue !== value);
    props.setFilter({
        ...props.filter,
        [name]: updatedFilter
    });
};

export const ListActiveFilter = (props: Props) => {
    const { filter, setFilter } = useAddonReport();
    const r =
        props.filterPanel &&
        props.filterPanel.map(panel => {
            return (
                <Row key={panel.filterName}>
                    {panel && panel?.filterListValue && panel.filterListValue?.length > 0 && (
                        <Name>{`${L(panel.filterName)}`}</Name>
                    )}
                    {panel.filterListValue?.map((value, i) =>
                        panel.isID ? (
                            <Value onClick={() => onClick({ filter, setFilter }, value, panel.filterName)} key={i}>
                                {L(panel.list.find(({ id: property_id }) => property_id === value)?.name)}
                            </Value>
                        ) : (
                            <Value onClick={() => onClick({ filter, setFilter }, value, panel.filterName)} key={i}>
                                {L(value)}
                            </Value>
                        )
                    )}
                </Row>
            );
        });
    return <Container>{r}</Container>;
};
