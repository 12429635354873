import styled from '@emotion/styled';
import RemoveOutline from '../../../../components/Icon/e-remove-outline-12.svg';

export const CrossIcon = styled(RemoveOutline)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '16px',
    width: '16px',
    fill: 'black',
    stroke: 'black'
});
