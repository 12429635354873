import * as React from 'react';
import { LoginStore } from '../../../login/LoginStore';
import { useObserver } from 'mobx-react';
import { Button } from '@ourliving/ourliving-ui';
import { history } from '../../../../lib/routing';
import { L } from '../../../../lib/i18n';
import { style } from '../../../../components/Shared/Style';
import styled from '@emotion/styled';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import { useState } from 'react';
import { usePromise } from '../../../../lib/react';
import { Loading } from '../../../../components/Loading/Loading';
import Logo from '../../../../components/Logo/Logo';

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs,
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
});

const LoginCard = styled.div({
    width: '90%',
});

const Padding = styled.div({
    paddingBottom: style.margin.m,
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center',
});

const Card = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 60px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px',
    maxWidth: '300px',
    maxHeight: '360px',
});

const StyledLogo = styled(Logo)({
    maxWidth: '200px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit',
});

interface Props {
    children?: any;
    propertyId: string;
}

export const Impersonate = ({ propertyId }) => {
    const [user, setUser] = useState('');

    const [ready] = usePromise(async () => {
        await LoginStore.getList(propertyId);
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        const login = (evt) => {
            evt.preventDefault();
            LoginStore.loginImpersonatePropertyUser(propertyId, user);
        };

        return (
            <form onSubmit={login} key={history.location.pathname}>
                <Background>
                    <Card>
                        <StyledLogo />
                        <LoginCard>
                            <Padding>
                                <Header>{L('user')}</Header>

                                <Dropdown
                                    options={LoginStore.users.map((p) => ({ label: p.name, value: p.id }))}
                                    onChange={(value) => setUser(value)}
                                    value={user}
                                />
                            </Padding>

                            <ButtonMenu>
                                <Button type={'submit'} onClick={login} style={{ marginRight: '0px' }}>
                                    {L('login')}
                                </Button>
                            </ButtonMenu>
                        </LoginCard>
                    </Card>
                </Background>
            </form>
        );
    });
};
