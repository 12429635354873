import * as React from 'react';
import { Route, Routes, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import ProfessionSettingsPage from './Professions/ProfessionSettingsPage';
import SupplierSettingsPage from './Suppliers/SupplierSettingsPage';
import TermsAndConditionsPage from './TermsAndConditions/TermsAndConditionsPage';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { ProfessionDetailPage } from './Professions/ProfessionDetailPage';
import { SupplierDetailPage } from './Suppliers/SupplierDetailPage';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { useGetProfessionById } from './Professions/hooks/professionQueries';
import { useGetSupplierById } from './Suppliers/hooks/supplierQueries';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const ProfessionName = () => {
    const { id } = useParams<{ id: string }>();
    const Name = ({ id }: { id: number }) => {
        const { data: profession, isLoading } = useGetProfessionById({ id: id, keepPreviousData: true });
        if (isLoading) return <></>;
        return profession?.name ? <>{profession.name}</> : <>{L('profession')}</>;
    };

    return id ? <Name id={+id} /> : <>{L('profession')}</>;
};

const SupplierName = () => {
    const { id } = useParams<{ id: string }>();
    const Name = ({ id }: { id: number }) => {
        const { data: profession, isLoading } = useGetSupplierById({ id: id, keepPreviousData: true });
        if (isLoading) return <></>;
        return profession?.name ? <>{profession.name}</> : <>{L('profession')}</>;
    };

    return id ? <Name id={+id} /> : <>{L('profession')}</>;
};

const BreadcrumbsNavigation = () => {
    return (
        <Routes>
            <Route path="addon-settings">
                <Route index />
                <Route path=":id">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('profession')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{<ProfessionName />}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                </Route>
                <Route path="suppliers">
                    <Route path=":id">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="..">{L('supplier')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>{<SupplierName />}</Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                    </Route>
                </Route>
                <Route path="termsAndConditions" />
            </Route>
        </Routes>
    );
};

const Navigation = () => {
    return (
        <Menu>
            <BigTabNav>
                <Routes>
                    <Route path="addon-settings">
                        <Route
                            index
                            element={
                                <>
                                    <BigNavItem end to=".">
                                        {L('profession')}
                                    </BigNavItem>
                                    <BigNavItem end to="suppliers">
                                        {L('suppliers')}
                                    </BigNavItem>
                                    <BigNavItem end to="termsAndConditions">
                                        {L('terms_and_conditions')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="suppliers"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('profession')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('suppliers')}
                                    </BigNavItem>
                                    <BigNavItem end to="../termsAndConditions">
                                        {L('terms_and_conditions')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="termsAndConditions"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('profession')}
                                    </BigNavItem>
                                    <BigNavItem end to="../suppliers">
                                        {L('suppliers')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('terms_and_conditions')}
                                    </BigNavItem>
                                </>
                            }
                        />
                    </Route>
                </Routes>
            </BigTabNav>
        </Menu>
    );
};

export const AddonsSettingPage = () => {
    return (
        <>
            <BreadcrumbsNavigation />
            <Navigation />
            <Routes>
                <Route path="addon-settings">
                    <Route index element={<ProfessionSettingsPage />} />
                    <Route path=":id" element={<ProfessionDetailPage />} />

                    <Route path="suppliers">
                        <Route index element={<SupplierSettingsPage />} />
                        <Route path=":id" element={<SupplierDetailPage />} />
                    </Route>
                    <Route path="termsAndConditions" element={<TermsAndConditionsPage />} />
                </Route>
            </Routes>
        </>
    );
};
