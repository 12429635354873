import { useMutation, useQueryClient } from 'react-query';
import { accountTodoQueryKeys } from './accountTodoQueries';
import toast from 'react-hot-toast';
import { trpc } from '../../../lib/trpc';
import { L } from '../../../lib/i18n';

type Input = {
    description?: string;
    name: string;
    due_at?: string;
    template_for_type?: string;
    template_for_subtype?: string;
    tips?: string;
    recurrency?: number;
};
const useCreateTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ ...input }: Input) => {
            return trpc.AccountTodoApi.createNewTodo.mutate({
                ...input,
            });
        },

        onSettled: () => {
            queryClient.invalidateQueries(accountTodoQueryKeys.lists());
        },
    });
};

const useUpdateTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ todoId, ...input }: Input & { todoId: number }) => {
            return trpc.AccountTodoApi.updateTodo.mutate({
                ...input,
                id: todoId,
            });
        },
        onSettled: (_data, _error, input) => {
            queryClient.invalidateQueries(accountTodoQueryKeys.lists());
            queryClient.invalidateQueries(accountTodoQueryKeys.detail({ id: input.todoId }));
        },
    });
};

const useRemoveTodo = () => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ todoId }: { todoId: number }) => {
            return trpc.AccountTodoApi.removeTodo.mutate({
                id: todoId,
            });
        },
        onSuccess: () => {
            toast.success(L('todo_removed'));
        },
        onError: () => {
            toast.success(L('todo_remove_error'));
        },
        onSettled: (_data, _error, input) => {
            queryClient.invalidateQueries(accountTodoQueryKeys.lists());
            queryClient.invalidateQueries(accountTodoQueryKeys.detail({ id: input.todoId }));
        },
    });
};

export { useCreateTodo, useUpdateTodo, useRemoveTodo };
