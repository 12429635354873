import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import TemplateFromPropertyForm, { FormType } from '../../binders/components/Properties/TemplateFromPropertyForm';
import { L } from '../../../lib/i18n';
import { useListPropertyTemplates } from '../hooks/propertyTemplateQueries';
import { useGetProjectsLean } from '../../binders/hooks/projectHooks/projectQueries';
import useCreateTemplateFromProp from '../../../lib/hooks/useCreateTemplateFromProp';

type Props = {
    handleCloseModal: () => void;
    templateId: number | null;
};

const CreateTemplateFromTemplate = ({ handleCloseModal, templateId }: Props) => {
    const { data: properties } = useListPropertyTemplates({ search: '', sorting: [] });

    const { mutate } = useCreateTemplateFromProp();

    const { data: projects } = useGetProjectsLean({ projectStatus: 'both', templateStatus: 'regular' });

    const onSubmit: SubmitHandler<FormType> = ({ templateLevel, project, newName, property }) => {
        mutate(
            {
                projectId: templateLevel === 'project' ? project?.value : undefined,
                newName,
                propertyId: property.value,
            },
            {
                onSuccess: () => {
                    toast.success(`${L('property_template_created')}`);
                    handleCloseModal();
                },
                onError: () => {
                    toast.success(`${L('an_error_occurred')}`);
                },
            },
        );
    };

    if (!!properties && !!projects) {
        return (
            <TemplateFromPropertyForm
                properties={properties}
                projects={projects}
                propertyId={templateId}
                onSubmit={onSubmit}
                headerTitle={L('new_template_from_property_template')}
            />
        );
    }
};

export default CreateTemplateFromTemplate;
