import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import Close from '../../../components/Close/Close';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import useGetFilteredProducts from '../hooks/useGetFilteredProducts';
import { L } from '../../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';
import useSaveProduct from '../hooks/useSaveProduct';
import { useParams } from 'react-router-dom';

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
});

const Modal = styled.div({
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'white',
    overflowY: 'scroll',
    padding: '0px 20px 20px 20px',
    zIndex: 200,
    ['@media screen and (min-width: 1024px)']: {
        width: '50vw',
        height: 'auto',
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    ['@media screen and (min-width: 1224px)']: {
        width: '30vw',
    },
});

const ModalOverlay = styled.div({
    ['@media screen and (min-width: 1024px)']: {
        display: 'flex',
        width: '100%',
        overflowX: 'hidden',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(128, 128, 128, 0.7)',
        zIndex: 100,
        bottom: 0,
    },
});

const AddProductButton = styled.div({
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#4282FF',
    fontSize: '1.5rem',
    cursor: 'pointer',
    fontWeight: 300,
});

const CloseWrapper = styled.div({
    width: '100%',
    position: 'relative',
    marginTop: '1rem',
    marginBottom: '4rem',
});

const ButtonWrapper = styled.div({
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
});

const StyledButton = styled(Button)({
    marginRight: '0px',
});

const AddProductModal = () => {
    const [openModal, setOpenModal] = useState(false);
    const { data: filteredProducts } = useGetFilteredProducts();
    const { mutate: saveProduct } = useSaveProduct();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            setOpenModal(false);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setOpenModal]);

    const params = useParams<{ projectId: string; propertyId: string; spaceId: string }>();

    const [functionOption, setFunctionOption] = useState('');
    const [name, setName] = useState('');
    const [productName, setProductName] = useState('');
    const [brand, setBrand] = useState('');
    const [eNumber, setENumber] = useState('');
    const [serialNumber, setSerialNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        saveProduct({
            name,
            entity_subtype: functionOption,
            parent_id: params.spaceId,
            propertyId: params.propertyId,
            attr: {
                product: productName,
                brand,
                e_no: eNumber,
                serial_number: serialNumber,
            },
        });
        setFunctionOption('');
        setOpenModal(false);
    };

    return (
        <>
            {openModal ? (
                <ModalOverlay>
                    <Modal ref={ref}>
                        <CloseWrapper>
                            <Close onClose={() => setOpenModal(false)} />
                        </CloseWrapper>
                        <form onSubmit={handleSubmit}>
                            <Header>{L('add_product')} </Header>
                            <Dropdown
                                onChange={(value) => setFunctionOption(value)}
                                value={functionOption}
                                options={
                                    filteredProducts?.map((product) => {
                                        return { value: product.id, label: L(product.name) };
                                    }) || []
                                }
                            />
                            <Label title={'Namn'} />
                            <Input
                                type="text"
                                placeholder={'Namn'}
                                required={true}
                                onChange={(e) => setName(e.target.value)}
                            />

                            <Label title={'Produktnamn'} />
                            <Input
                                type="text"
                                placeholder={'Produktnamn'}
                                onChange={(e) => setProductName(e.target.value)}
                            />

                            <Label title={'Märke'} />
                            <Input
                                type="text"
                                placeholder={'Märke'}
                                required={true}
                                onChange={(e) => setBrand(e.target.value)}
                            />

                            <Label title={'E-Nr'} />
                            <Input type="text" placeholder={'E-Nr'} onChange={(e) => setENumber(e.target.value)} />

                            <Label title={'Serienummer'} />
                            <Input
                                type="text"
                                placeholder={'Serienummer'}
                                onChange={(e) => setSerialNumber(e.target.value)}
                            />
                            <ButtonWrapper>
                                <StyledButton type="submit">Spara</StyledButton>
                            </ButtonWrapper>
                        </form>
                    </Modal>
                </ModalOverlay>
            ) : (
                <AddProductButton onClick={() => setOpenModal(true)}>+</AddProductButton>
            )}
        </>
    );
};

export default AddProductModal;
