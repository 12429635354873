import React from 'react';
import { IPageOf } from '@app/api/lib/db';
import { L } from '../../../lib/i18n';
import PaginationContainer from './emotion/Pagination/PaginationContainer';
import DirectionsMarker from './emotion/Pagination/DirectionsMarker';
import StyledSpan from './emotion/StyledSpan';
import StyledLeftArrow from './emotion/Pagination/StyledLeftArrow';
import StyledRightARrow from './emotion/Pagination/StyledRightArrow';

interface PagerProps {
    page: IPageOf<any>;
    onChangePage(newPage: number): void;
}

export function Pagination(props: PagerProps) {
    if (props.page.pages < 1) return null;
    const canGoBack = props.page.currentPage > 1;
    const canGoForwards = props.page.currentPage < props.page.pages;
    const goBack = () => props.onChangePage(props.page.currentPage - 1);
    const goForward = () => props.onChangePage(props.page.currentPage + 1);

    return (
        <PaginationContainer>
            <DirectionsMarker disabled={!canGoBack} onClick={goBack}>
                <span>
                    <StyledLeftArrow />
                </span>
            </DirectionsMarker>
            <StyledSpan>{`${L('page')} ${props.page.currentPage} ${L('of')} ${props.page.pages}`}</StyledSpan>
            <DirectionsMarker disabled={!canGoForwards} onClick={goForward}>
                <span>
                    <StyledRightARrow />
                </span>
            </DirectionsMarker>
        </PaginationContainer>
    );
}
