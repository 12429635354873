import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProjectKeys } from '../projectHooks/projectQueries';

const useSetProjectTransferred = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (data: { projectId: number }) => {
            return trpc.BindersApi.setProjectTransferred.mutate({ projectId: data.projectId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.lists());
            },
        },
    );
};

export default useSetProjectTransferred;
