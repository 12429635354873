import { File } from 'koa-rpc-call';

export function downloadFile(name: string, file: File) {
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, name);
        return;
    }
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.download = name;
    link.href = fileURL;
    document.body.appendChild(link);
    link.click();
}
