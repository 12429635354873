import React from 'react';
import Label from '../../../../components/Forms/Label';
import { Loading } from '../../../../components/Loading/Loading';
import { DragEndEvent } from '@dnd-kit/core';
import DraggableSortGrid, { GridContainer, Skeleton } from './DraggableSortGrid';
import { L } from '../../../../lib/i18n';
import SortablePhoto from './Photo';

type Props = {
    imageData: {
        id: number;
        url: string;
    }[];
    isUploading?: boolean;
    handleRemoveImage: (documentId: number) => void;
    outerWrapperCSS?: React.CSSProperties;
    handleDragEnd: (event: DragEndEvent) => void;
    isUpdatingSort: boolean;
    children?: React.ReactNode;
};

const Images = ({
    imageData,
    handleRemoveImage,
    outerWrapperCSS,
    handleDragEnd,
    isUpdatingSort,
    isUploading,
    children,
}: Props) => {
    if (!imageData) return <Loading />;

    return (
        <div style={outerWrapperCSS}>
            <Label style={{ width: '100%' }} title={L('pictures')} />
            <DraggableSortGrid
                isUploading={isUploading}
                items={imageData}
                onDragEnd={handleDragEnd}
                disableSort={isUpdatingSort}
            >
                <GridContainer>
                    {imageData.map(({ url, id }, index) => (
                        <SortablePhoto remove={handleRemoveImage} id={id} key={id} url={url} index={index} />
                    ))}
                    {isUploading && <Skeleton />}
                    {children}
                </GridContainer>
            </DraggableSortGrid>
        </div>
    );
};

export default Images;
