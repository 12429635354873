import styled from '@emotion/styled';
import React, { useState } from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { style } from '@app/shared/lib/Style';
import SortArrows from '../../../components/Table/sortArrows/SortArrows';
import { colors } from '@app/shared/lib/Style/colors';
import { margin } from '@app/shared/lib/Style/margin';
import HorizontalDividerIcon from '../../../components/Icon/horizontal-divider.svg';
import DraggableTableRow from './DraggableTableRow';
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { verticalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';

const Styles = styled.div({
    /* These styles are suggested for the table fill all available space in its containing element */
    // display: 'block',
    /* These styles are required for a horizontaly scrollable table overflow */
    '&': {
        maxWidth: '100%',
        minWidth: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        padding: '0',
    },

    '& .table': {
        borderSpacing: 0,
        width: 'calc(100vw - 375px)',
        minWidth: '100%',
        ['@media (max-width: 1025px)']: {
            width: '100vw',
        },

        '.th': {
            color: colors.secondary,
            padding: `0px 16px ${style.margin.m}px 0px`,
            fontSize: style.fontSize.epsilon,

            /* These styles are required for a scrollable body to align with the header properly */
            overflowX: 'hidden',
        },

        '.td': {
            borderTop: `1px solid ${style.colors.black50}`,
            padding: '16px 16px 16px 0px',
            fontSize: style.fontSize.centi,
            textAlign: 'start',
            overflowX: 'hidden',
        },

        '.tbody': {
            /* These styles are required for a scrollable table body */
            // overflowX: 'hidden'
        },

        '.th, .td': {
            margin: 0,
            /* In this example we use an absolutely position resizer,
       so this is required. */

            position: 'relative',
            ':last-child': {
                paddingRight: '0px',
            },
        },
        '.td .tr div:first-of-type': {
            paddingLeft: '0px',
        },
    },
});

const TBodyContainer = styled.div({});

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
    width: '100%',
});

const ResizerContainer = styled.div({
    position: 'absolute',
    right: 0,
    top: 2,
    cursor: 'col-resize',
    userSelect: 'none',
    touchAction: 'none',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    paddingLeft: margin.s,
    paddingRight: margin.s,
});

const HorizontalDivider = styled(HorizontalDividerIcon)({
    width: '16px',
    height: 'auto',
    '& path': {
        fill: colors.secondary,
    },
});

export type Props<T extends { [key: string]: unknown; id: number }> = {
    table: Table<T>;
    rowClickHandler?: (row: Row<T>) => void;
    items: number[] | undefined;
    handleDragEnd: (event: DragEndEvent) => void;
    isUpdatingSort?: boolean;
};

export default function GenericTableDnD<T extends { [key: string]: unknown; id: number }>({
    table,
    rowClickHandler,
    items,
    handleDragEnd,
    isUpdatingSort,
}: Props<T>) {
    const [showResizeHandler, setShowResizeHandler] = useState(false);
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

    return (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} css>
            <Styles>
                <div className="table" role="table">
                    <div>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <div
                                style={{
                                    display: 'flex',
                                    flex: '1 0 auto',
                                    minWidth: '0px',
                                    marginLeft: '34px',
                                }}
                                className="tr"
                                key={headerGroup.id}
                                role="row"
                            >
                                {headerGroup.headers.map((header) => (
                                    <div
                                        key={header.id}
                                        style={{
                                            boxSizing: 'border-box',
                                            position: 'relative',
                                            cursor: header.column.getCanSort() ? 'pointer' : '',
                                            justifyContent: 'flex-start',
                                            alignItems: 'flex-start',
                                            display: 'flex',
                                            width: header.getSize(),
                                            ...header.column.columnDef?.meta?.styles,
                                        }}
                                        className="th"
                                        role="columnheader"
                                        onMouseEnter={() => setShowResizeHandler(true)}
                                        onMouseLeave={() => setShowResizeHandler(false)}
                                    >
                                        <FlexContainer>
                                            <FlexContainer
                                                {...{
                                                    onClick: header.column.getToggleSortingHandler(),
                                                }}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                                {header.column.getCanSort() && (
                                                    <SortArrows
                                                        activeSort={
                                                            header.column.getIsSorted()
                                                                ? header.column.getIsSorted() === 'desc'
                                                                    ? 'desc'
                                                                    : 'asc'
                                                                : undefined
                                                        }
                                                    />
                                                )}
                                            </FlexContainer>
                                            {header.column.getCanResize() && (
                                                <ResizerContainer
                                                    onMouseDown={header.getResizeHandler()}
                                                    onTouchStart={header.getResizeHandler()}
                                                >
                                                    {showResizeHandler && <HorizontalDivider />}
                                                </ResizerContainer>
                                            )}
                                        </FlexContainer>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <TBodyContainer>
                        <div className="tbody" role="rowgroup">
                            <SortableContext
                                items={items ?? []}
                                strategy={verticalListSortingStrategy}
                                disabled={isUpdatingSort}
                            >
                                {table.getRowModel().rows.map((row) => (
                                    <DraggableTableRow
                                        row={row}
                                        key={row.original.id}
                                        rowClickHandler={rowClickHandler}
                                    />
                                ))}
                            </SortableContext>
                        </div>
                    </TBodyContainer>
                </div>
            </Styles>
        </DndContext>
    );
}
