import React, { useMemo, useState } from 'react';
import GenericTableVirtualized from '../GenericTableVirtualizedInfinite';
import {
    useGetPackageTypes,
    useGetTemplateProducts,
    useGetTemplateProductsListFilters,
} from '../../hooks/productQueries';
import { FiltersProducts } from '../../DataType';
import {
    RowSelectionState,
    SortingState,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import { L } from '../../../../lib/i18n';
import columns from './ProductTemplateImportColumns';
import { Loading } from '../../../../components/Loading/Loading';
import FilterSearchContainer from '../emotion/Filters/FilterSearchContainer';
import ContentContainer from '../emotion/ContentContainer';
import FilterTableData from '../FilterTableData';
import PaddedContainer from '../emotion/PaddedContainer';
import Row from '../emotion/Row';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import ChosenFilters from '../ChosenFilters/ChosenFilters';
import { useLocalStorage } from 'react-use';
import { TableMeta } from '../../TableMetaTypes/ProductTemplatesTableMeta';
import StyledParagraph from '../emotion/StyledParagraph';
import { Button } from '@ourliving/ourliving-ui';
import styled from '@emotion/styled';
import RightCornerMenu from '../emotion/RightCornerMenu';
import H2 from '../../../../components/typography/H2';
import LibrarySource from '../LibrarySource';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import toast from 'react-hot-toast';

type PropertyFilterProducts = Omit<FiltersProducts, 'property' | 'space'>;

const options = [
    { name: 'product_group', value: 'selectedProductTypes', newValue: 'setSelectedProductTypes', id: 1 },
    { name: 'package', value: 'selectedPackages', newValue: 'setSelectedPackages', id: 2 },
];

type PackageTypeValues = TableMeta['packageTypes'][0]['name'];

const TableMenuContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 0,
    alignItems: 'center',
});

type HandleImport1 = (params: {
    propertyId: number;
    destinationId: number;
    products: {
        id: number;
        package_type: string | null;
    }[];
}) => void;

type HandleImport2 = (params: {
    destinationId: number;
    products: {
        id: number;
        package_type: string | null;
    }[];
}) => void;

type Base = {
    libraries: {
        id: number;
        name: string;
    }[];
};

interface PropsWithOnlyProduct extends Base {
    propertyId?: never;
    productId: number;
    spaceId?: never;
    handleImportToAccountProduct: HandleImport2;
    handleImportToPropertyProduct?: undefined;
    spaces?: never;
}
interface PropsWithSpaces extends Base {
    propertyId: number;
    productId?: number;
    spaceId?: string;
    handleImportToPropertyProduct: HandleImport1;
    handleImportToAccountProduct?: undefined;
    spaces?: {
        id: number;
        name: string | null;
        property_id: number | null;
        template: boolean | null;
    }[];
}

const AddProductFromTemplateComponent = ({
    propertyId,
    productId,
    libraries,
    spaceId,
    handleImportToPropertyProduct,
    handleImportToAccountProduct,
    spaces,
}: PropsWithOnlyProduct | PropsWithSpaces) => {
    const [searchField, setSearchField] = useState('');

    const handleSearch = (value: string) => {
        setSearchField(value);
    };

    const [accountIds, setAccountIds] = useState<number[]>(libraries.map((library) => library.id));

    const { data: packageTypes } = useGetPackageTypes();
    const spaceOptions =
        spaces?.map((option) => ({
            value: String(option.id),
            label: String(option.name),
        })) ?? [];
    const [packageVals, setPackageVals] = useState<{ id: number; value: PackageTypeValues | null }[]>([]);

    const [destinationSpace, setDestinationSpace] = useState<string | undefined>(spaceId);

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [sorting, setSorting] = useState<SortingState>([]);

    const { data: filter } = useGetTemplateProductsListFilters(accountIds);

    const productTypesFilter = filter?.productGroups;
    const packageTypeFilter = filter?.packages;

    const [localStorageValue, setLocalStorageValue] = useLocalStorage<{
        product_group: string[];
        package: string[];
        searchField: string;
    }>(`templateProducts`, { searchField, product_group: [], package: [] });

    const handleClearAll = () => {
        setLocalStorageValue(() => ({ searchField, product_group: [], package: [] }));
    };

    const updateFilter = (key: string, values: string[]) => {
        if (!localStorageValue) return;
        const localStorageFilters = { ...localStorageValue };
        localStorageFilters[key] = values;
        setLocalStorageValue(() => localStorageFilters);
    };

    //Get all filters when initial loads are done
    const filterOptions = useMemo(() => {
        const filteredFilterData: PropertyFilterProducts = {
            product_group: [],
            package: [],
        };
        if (productTypesFilter && packageTypeFilter) {
            productTypesFilter.forEach((data) => {
                if (
                    filteredFilterData?.product_group &&
                    !filteredFilterData.product_group.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.product_group.push({ value: data.name.toString(), label: L(data.name) });
                }
            });
            packageTypeFilter.forEach((data) => {
                if (
                    filteredFilterData?.package &&
                    !filteredFilterData.package.find((filter) => filter.value === data.package_name)
                ) {
                    filteredFilterData.package.push({
                        value: data.id.toString(),
                        label: data.package_name ? data.package_name : L('name_missing'),
                    });
                }
            });
        }
        return filteredFilterData;
    }, [productTypesFilter, packageTypeFilter]);

    const optionMetadata = [
        { name: 'product_group', initialTexts: [L('producttype'), L('producttypes')], api: 'productgroup' },
        { name: 'package', initialTexts: [L('package'), L('package')], api: 'count' },
    ];

    const setSelectedProductTypes = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('product_group', onlyValues);
    };

    const setSelectedPackages = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('package', onlyValues);
    };

    const selectedFiltersWithLabel = {
        selectedProductTypes:
            localStorageValue?.product_group?.flatMap((type) => {
                const option = filterOptions.product_group.find((filter) => filter.value === type);
                return option ?? [];
            }) ?? [],
        selectedPackages:
            localStorageValue?.package.flatMap((type) => {
                const option = filterOptions.package.find((filter) => filter.value === type);
                return option ?? [];
            }) ?? [],
    };

    const clearInput = (type: keyof FiltersProducts, value: string) => {
        if (!localStorageValue) return;
        const localStorageFilters = { ...localStorageValue };
        localStorageFilters[type] = localStorageFilters[type].filter((item) => value !== item);
        setLocalStorageValue(() => localStorageFilters);
    };

    const { fetchNextPage, hasNextPage, isFetching, data, isLoading } = useGetTemplateProducts({
        filters: selectedFiltersWithLabel,
        sortOptions: sorting,
        search: searchField,
        accountIds,
    });

    const totalDBRowCount = Number(data?.pages?.[0]?.totalItems ?? 0);

    const totalFetched = data?.pages.map((page) => page.items).flatMap((item) => item).length ?? 0;

    const mutatedData = useMemo(() => {
        if (!data) return [];
        const allItems = data.pages.map((page) => page.items).flatMap((item) => item);
        return allItems.map((item) => {
            const foundItem = packageVals.find((val) => val.id === item.id);
            return {
                ...item,
                package_type: foundItem ? foundItem.value : item.package_type,
            };
        });
    }, [data, packageVals]);

    const updatePackageType = async (newValue: PackageTypeValues | null, itemId: number) => {
        const packageValsCopy = [...packageVals];
        const foundIndex = packageValsCopy.findIndex((copiedItem) => copiedItem.id === itemId);

        if (foundIndex >= 0) {
            packageValsCopy[foundIndex].value = newValue;
        } else
            packageValsCopy.push({
                id: itemId,
                value: newValue,
            });
        setPackageVals(packageValsCopy);
    };

    const importSelectedProducts = () => {
        const items = table
            .getSelectedRowModel()
            .flatRows.map(({ original }) => ({ id: original.id, package_type: original.package_type }));

        if (spaces && handleImportToPropertyProduct) {
            if (!destinationSpace) {
                toast.error(L('please_select_space'));
                return;
            }
            handleImportToPropertyProduct({
                propertyId: +propertyId,
                products: items,
                destinationId: +destinationSpace,
            });
            return;
        }

        if (productId && propertyId && handleImportToPropertyProduct) {
            handleImportToPropertyProduct({
                propertyId: +propertyId,
                products: items,
                destinationId: +productId,
            });
            return;
        }
        if (handleImportToAccountProduct) {
            handleImportToAccountProduct({
                products: items,
                destinationId: +productId,
            });
        }
    };

    const table = useReactTable({
        data: mutatedData,
        columns,
        state: { sorting, rowSelection },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        manualSorting: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        enableRowSelection: true,
        meta: {
            packageTypes,
            updatePackageType,
        } as TableMeta,
    });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div style={{ padding: '1rem' }}>
            <H2>{L('template_inventory')}</H2>
            <FilterSearchContainer>
                <ContentContainer>
                    <FilterTableData
                        options={options}
                        filterOptions={filterOptions}
                        selected={{
                            selectedProductTypes: selectedFiltersWithLabel.selectedProductTypes,
                            selectedPackages: selectedFiltersWithLabel.selectedPackages,
                        }}
                        setSelected={{
                            setSelectedProductTypes,
                            setSelectedPackages,
                        }}
                        clearAll={handleClearAll}
                    />
                    {libraries && (
                        <LibrarySource
                            libraries={libraries}
                            accountIds={accountIds?.map((id) => id.toString()) ?? []}
                            setAccountIds={(accountIds) => setAccountIds(accountIds.map((id) => +id))}
                        />
                    )}
                </ContentContainer>
                <PaddedContainer>
                    <Row>
                        <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                    </Row>
                </PaddedContainer>
            </FilterSearchContainer>
            {filterOptions && (
                <ChosenFilters
                    inputs={{
                        product_group: selectedFiltersWithLabel.selectedProductTypes,
                        package: selectedFiltersWithLabel.selectedPackages,
                    }}
                    onRemove={clearInput}
                    optionMetadata={optionMetadata}
                />
            )}
            <TableMenuContainer>
                <StyledParagraph style={{ paddingBottom: '1rem' }}>
                    {Object.values(rowSelection).length > 0
                        ? Object.values(rowSelection).length +
                          'st ' +
                          (Object.values(rowSelection).length > 1 ? L('chosen_products') : L('chosen_product'))
                        : L('no_chosen_products')}
                </StyledParagraph>
                <RightCornerMenu>
                    <ContentContainer>
                        {spaces && (
                            <RadixSelect
                                placeholder={L('space')}
                                options={spaceOptions}
                                value={destinationSpace}
                                onValueChange={(value) => setDestinationSpace(value)}
                                selectTriggerStyle={{ minWidth: '140px' }}
                                resetValue={() => setDestinationSpace(undefined)}
                            />
                        )}
                        <div>
                            <Button
                                disabled={!(Object.values(rowSelection).length > 0)}
                                onClick={() => importSelectedProducts()}
                            >
                                {L('add')}
                            </Button>
                        </div>
                    </ContentContainer>
                </RightCornerMenu>
            </TableMenuContainer>
            <GenericTableVirtualized
                table={table}
                estimateSize={50}
                handleFetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetching={isFetching}
                totalDBRowCount={totalDBRowCount}
                totalFetched={totalFetched}
            />
        </div>
    );
};

export default AddProductFromTemplateComponent;
