import React from 'react';
import DocumentsTable from '../../../components/Table/DocumentTables/DocumentsTable';
import useGetServicePartnerDocumentsById from '../hooks/useGetServicePartnerDocumentsById';
import useDeleteServicePartnerDocument from '../hooks/useDeleteServicePartnerDocument';
import useSaveServicePartnerDocument from '../hooks/useSaveServicePartnerDocuments';
import { useParams } from 'react-router-dom';

const ServicePartnerDocumentTableById = () => {
    const { id } = useParams<{ id: string }>();
    const { data: documentItem } = useGetServicePartnerDocumentsById(id);

    const { mutate: saveDocument } = useSaveServicePartnerDocument();
    const { mutate: deleteDocument } = useDeleteServicePartnerDocument();

    if (!documentItem) return null;
    return (
        <DocumentsTable
            itemId={id.toString()}
            list={documentItem}
            deleteDocument={(documentItem) =>
                deleteDocument({ documentId: documentItem.documentId, queryId: id.toString() })
            }
            saveDocument={(documentItem) => saveDocument(documentItem)}
        />
    );
};

export default ServicePartnerDocumentTableById;
