import { Order } from '@app/api/models/Orders';
import { Property } from '@app/api/models/Properties';
import AddonsApi from '@app/api/public/AddonsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';
import { getOrderUserKeys } from './useGetUsersOnOrder';

const removeOrder = async ({ propertyId, orderId }: Variables) => {
    return await AddonsApi.deleteOrder(+orderId, +propertyId);
};

type Variables = {
    propertyId: Property['id'];
    orderId: Order['id'];
};

const useRemoveOrder = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => removeOrder(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getOrderUserKeys.all);
            queryClient.invalidateQueries(getPropertyKeys.all);
        }
    });
};

export default useRemoveOrder;
