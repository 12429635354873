import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';

export const getPropertyContactsKeys = {
    all: ['property-account-contacts'] as const,
    lists: () => [...getPropertyContactsKeys.all, 'list'] as const,
    list: (property_id: number, page: number) => [...getPropertyContactsKeys.lists(), property_id, page],
    listInfinite: (property_id: number, sorting?: SortingState, search?: string) => [
        ...getPropertyContactsKeys.lists(),
        'listInfinite',
        property_id,
        sorting,
        search,
    ],
    details: () => [...getPropertyContactsKeys.all, 'detail'] as const,
    detail: (contactId: number) => [...getPropertyContactsKeys.details(), { contactId }] as const,
};

export function useGetInfinitePropertyContacts({
    property_id,
    keepPreviousData,
    search,
    sorting,
}: {
    property_id: number;
    keepPreviousData: boolean;
    search: string;
    sorting: SortingState;
}) {
    return useInfiniteQuery(
        getPropertyContactsKeys.listInfinite(property_id, sorting, search),
        async ({ pageParam = 1 }) =>
            await trpc.PropertyContactsApi.getPropertyContactsPaginated.query({
                propertyId: property_id,
                page: pageParam,
                search,
                sorting,
            }),
        {
            keepPreviousData,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useGetContact(contactId: number) {
    return useQuery(
        getPropertyContactsKeys.detail(contactId),
        async () => await trpc.PropertyContactsApi.getPropertyContact.query({ contactId }),
    );
}
