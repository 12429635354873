import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Input from '../../../components/Forms/Input';
import useUpdateProduct from '../hooks/useUpdateProduct';
import Label from '../../../components/Forms/Label';
import useSaveProductImage from '../hooks/useSaveProductImage';
import useGetSignedUrl from '../../../lib/hooks/useGetSignedUrl';
import Camera from '../../../public/assets/camera.svg';
import ProductImage from './ProductImage';
import { Button } from '@ourliving/ourliving-ui';
import useDeleteProduct from '../hooks/useDeleteProduct';
import TypePlateModal from './TypePlateModal';
import { Product } from '@app/api/models/Properties';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';

const Card = styled.div({
    padding: '12px 12px',
    borderBottom: '1px solid #979797',
    cursor: 'pointer',
    ['@media screen and (min-width: 1024px)']: {
        padding: '12px 0px',
    },
});

const InfoWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    '&.isCompleted': {
        borderLeft: '5px solid #29CC97',
    },
    '&.isNotCompleted': {
        borderLeft: '5px solid grey',
    },
});

const ProductName = styled.span({
    color: '#444444',
    fontSize: '12px',
    fontWeight: 300,
});

const ExpandedContainer = styled.div({
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '30px',
});

const NamesWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-all',
    ['@media screen and (min-width: 1024px)']: {
        width: '60%',
    },
    ['@media screen and (min-width: 1200px)']: {
        width: '75%',
    },
});

const SmallProductImage = styled.div(({ url }: { url?: string }) => {
    return {
        width: '25px',
        height: '25px',
        backgroundImage: `url(${url})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    };
});

const NoProductImage = styled(SmallProductImage)({
    backgroundImage: 'none',
    width: '25px',
    height: '25px',
});

const NoImage = styled(Camera)({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ImgLabel = styled.label({
    cursor: 'pointer',
});

const ImageWrapper = styled.div({
    width: '100%',
});

const FileInput = styled.input({
    display: 'none',
});

const ButtonsWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '1rem',
});

const StyledButton = styled(Button)({
    marginRight: '0px',
});

type Props = {
    product: Product;
};

export type ProductAttr = {
    article_no: string;
    rsk_no: string;
    gtin_ean: string;
    pnc_number: string;
    order_no: string;
    e_no: string;
    serial_number: string;
};

const ProductCard = ({ product }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const [attr, setAttr] = useState<ProductAttr>({
        article_no: '',
        rsk_no: '',
        gtin_ean: '',
        pnc_number: '',
        order_no: '',
        e_no: '',
        serial_number: '',
    });

    const { mutate: updateProduct } = useUpdateProduct();
    const { data: url } = useGetSignedUrl(product?.documents?.[0]?.document_data);

    const { mutate: saveProductImage } = useSaveProductImage();
    const { mutate: deleteProduct } = useDeleteProduct();

    const [openTypePlateModal, setOpenTypePlateModal] = useState(false);

    useEffect(() => {
        if (!product) return;
        setAttr({
            e_no: product?.attr?.e_no || '',
            serial_number: product?.attr?.serial_number || '',
            article_no: product?.attr?.article_no || '',
            rsk_no: product?.attr?.rsk_no || '',
            gtin_ean: product?.attr?.gtin_ean || '',
            pnc_number: product?.attr?.pnc_number || '',
            order_no: product?.attr?.order_no || '',
        });
    }, [product]);

    const uploadedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        saveProductImage({
            file: file,
            productId: product.id,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateProduct({
            product: {
                e_no: attr?.e_no,
                serial_number: attr?.serial_number,
                article_no: attr?.article_no,
                rsk_no: attr?.rsk_no,
                gtin_ean: attr?.gtin_ean,
                pnc_number: attr?.pnc_number,
                order_no: attr?.order_no,
            },
            completed: true,
            id: product.id,
        });
    };

    const handleDelete = () => {
        deleteProduct(product.id);
    };

    return (
        <>
            <Card>
                <InfoWrapper
                    className={product?.inventory_completed ? 'isCompleted' : 'isNotCompleted'}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <NamesWrapper>
                        <span>{product.name}</span>
                        <ProductName>{product.product_name}</ProductName>
                    </NamesWrapper>
                    {isExpanded === false &&
                        (url ? (
                            <SmallProductImage url={url} />
                        ) : (
                            <NoProductImage>
                                <NoImage />
                            </NoProductImage>
                        ))}
                </InfoWrapper>
                {isExpanded && (
                    <ExpandedContainer>
                        <form onSubmit={handleSubmit}>
                            <Label title="Typskylt">
                                <ImageWrapper>
                                    <ImgLabel onClick={() => url && setOpenTypePlateModal(true)}>
                                        <ProductImage url={url} />
                                        {!url && <FileInput type="file" onChange={uploadedImage} />}
                                    </ImgLabel>
                                </ImageWrapper>
                            </Label>

                            <Label title="E-nr" />
                            <StyledInput
                                value={attr?.e_no || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, e_no: e.target.value }))}
                                placeholder="E-nr"
                                type="text"
                            />

                            <Label title="Serienummer" />
                            <StyledInput
                                value={attr?.serial_number || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, serial_number: e.target.value }))}
                                placeholder="Serienummer"
                                type="text"
                            />

                            <Label title="ArtikelNummer" />
                            <StyledInput
                                value={attr?.article_no || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, article_no: e.target.value }))}
                                placeholder="ArtikelNummer"
                                type="text"
                            />

                            <Label title="Rsknummer" />
                            <StyledInput
                                value={attr?.rsk_no || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, rsk_no: e.target.value }))}
                                placeholder="Rsknummer"
                                type="text"
                            />

                            <Label title="GTIN/EAN" />
                            <StyledInput
                                value={attr?.gtin_ean || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, gtin_ean: e.target.value }))}
                                placeholder="GTIN/EAN"
                                type="text"
                            />

                            <Label title="Pncnummer" />
                            <StyledInput
                                value={attr?.pnc_number || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, pnc_number: e.target.value }))}
                                placeholder="Pncnummer"
                                type="text"
                            />

                            <Label title="Ordernummer" />
                            <StyledInput
                                value={attr?.order_no || ''}
                                onChange={(e) => setAttr((prev) => ({ ...prev, order_no: e.target.value }))}
                                placeholder="Ordernummer"
                                type="text"
                            />

                            <ButtonsWrapper>
                                <ConfirmationButton props={{ onConfirm: handleDelete }} />

                                <StyledButton type="submit">Spara</StyledButton>
                            </ButtonsWrapper>
                        </form>
                        {openTypePlateModal && (
                            <TypePlateModal
                                product={product}
                                setOpenTypePlateModal={setOpenTypePlateModal}
                                openTypePlateModal={openTypePlateModal}
                                attr={attr}
                                setAttr={setAttr}
                            />
                        )}
                    </ExpandedContainer>
                )}
            </Card>
        </>
    );
};

export default ProductCard;
