import React, { useEffect, useMemo } from 'react';
import { L } from '../../../lib/i18n';
import { RowSelectionState, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useGetPropertySpaces } from '../hooks/spaceQueries';
import { RouterOutput } from '../../../lib/trpc';
import { fontSize } from '../../../components/Shared/Style/typography';
import { colors } from '../../../components/Shared/Style/colors';
import GenericTable from './GenericTable';

type SpaceByPropertyIds = RouterOutput['BindersApi']['listSpacesByPropertyIds'][0];

const columnHelper = createColumnHelper<SpaceByPropertyIds>();

export default function CopyToSpacesList({
    propertyIds,
    handleSetSelectedSpaces,
    handleSetNumberOfSpaces,
    spaceSearch,
    selectedSpaces,
}: {
    propertyIds: number[];
    handleSetSelectedSpaces: (val: RowSelectionState) => void;
    handleSetNumberOfSpaces: (val: number | null) => void;
    spaceSearch: string;
    selectedSpaces: Record<string, boolean> | null;
}) {
    const { data } = useGetPropertySpaces(propertyIds, spaceSearch);

    const dataMemo = useMemo(() => {
        return data || [];
    }, [data]);

    const columnsMemo = useMemo(() => {
        return [
            columnHelper.display({
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                header: L('name'),
                cell: (info) => <div>{info.getValue()}</div>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.property_name, {
                id: 'property_name',
                header: L('property_name'),
                cell: (info) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {info.row.original?.template_property ? (
                            <span style={{ fontSize: fontSize.milli, color: colors.primary }}>{L('template')}</span>
                        ) : (
                            <></>
                        )}
                        <span>{info.row.original.property_name}</span>
                    </div>
                ),
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.number_of_products, {
                id: 'number_of_products',
                header: L('number_of_products'),
                cell: (info) => <div>{info.getValue()}</div>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ];
    }, [data]);

    const tableData = useReactTable({
        data: dataMemo,
        columns: columnsMemo,
        getCoreRowModel: getCoreRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection: selectedSpaces ?? {} },
        onRowSelectionChange: (updater) => {
            if (typeof updater === 'function') {
                const val = updater(selectedSpaces ?? {});
                handleSetSelectedSpaces(val);
            }
        },
        getRowId: (originalRow) => String(originalRow.id),
    });

    useEffect(() => {
        handleSetNumberOfSpaces(data ? data.length : null);
    }, [data]);

    return data && data.length > 0 ? (
        <GenericTable shouldFillWidth={false} table={tableData} />
    ) : spaceSearch ? (
        <p>{L('no_property_spaces_found_description')}</p>
    ) : (
        <p>{L('no_spaces_in_chosen_properties')}</p>
    );
}
