import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import Input from '../../../components/Forms/Input';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RadixSelect from '../../../components/RadixSelect/RadixSelect';
import { Button } from '@ourliving/ourliving-ui';
import { TextArea } from '@ourliving/ourliving-ui';
import { useGetPropertyTypes } from '../../binders/hooks/propertyHooks/propertyQueries';
import { z } from 'zod';
import { useGetPropertyTemplateById } from '../hooks/propertyTemplateQueries';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';

const InnerFormWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0 1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const RestyledTextArea = styled(TextArea)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

export const AddPropertyTemplateSchema = z.object({
    name: z.string().min(1, 'error_required'),
    property_type: z.number({ required_error: 'error_required' }).or(
        z.string({ required_error: 'error_required' }).transform((val, ctx) => {
            const parsed = parseInt(val);
            if (isNaN(parsed)) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'error_required',
                });
                return z.NEVER;
            }
            return parsed;
        }),
    ),
    description: z.string().optional(),
    property_number: z.string().optional(),
    apartment_number: z.string().optional(),
    street: z.string(),
    postal_code: z.string(),
    postal_city: z.string().optional(),
    country: z.string().optional(),
    commune: z.string().optional(),
    parish: z.string().optional(),
    property_term: z.string().optional(),
    plot_area: z
        .number()
        .min(1, { message: 'empty_field_or_larger_than_zero' })
        .nullish()
        .or(
            z
                .nan()
                .transform(() => undefined)
                .optional(),
        ),
    property_area: z
        .number()
        .min(1, { message: 'empty_field_or_larger_than_zero' })
        .nullish()
        .or(
            z
                .nan()
                .transform(() => undefined)
                .optional(),
        ),
    built_year: z.string().optional(),
    rooms: z.string().optional(),
    floors: z.string().optional(),
    bathrooms: z.string().optional(),
    assess_value: z.string().optional(),
    heating: z.string().optional(),
    access_date: z.string().optional(),
    monthly_fee: z.string().optional(),
    percentage_of_community: z.string().optional(),
    floor: z.string().optional(),
    house: z.string().optional(),
    stairwell: z.string().optional(),
    banner: z.instanceof(File, { message: 'please_select_a_file' }).optional(),
});

export type AddPropertyTemplate = z.infer<typeof AddPropertyTemplateSchema>;

type Props = {
    onSubmit: (data: AddPropertyTemplate) => void;
    propertyData?: ReturnType<typeof useGetPropertyTemplateById>['data'];
    handleDeleteProperty?: () => void;
    oneFieldPerRow?: boolean;
};

const PropertyTemplateForm = ({ onSubmit, propertyData, handleDeleteProperty, oneFieldPerRow = false }: Props) => {
    const defaultValues = useMemo(() => {
        if (propertyData) {
            const { created_at, updated_at, ...rest } = propertyData;
            return { ...rest, ...propertyData.attr };
        }
        return null;
    }, [propertyData]);

    const { data: propertyTypes } = useGetPropertyTypes();

    const propertyTypesOptions = useMemo(() => {
        if (!propertyTypes) return [];
        return propertyTypes.map((type) => ({ value: type.id, label: L(type.name) }));
    }, [propertyTypes]);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddPropertyTemplate>({
        resolver: zodResolver(AddPropertyTemplateSchema),
        defaultValues: {
            ...defaultValues,
            plot_area: defaultValues?.plot_area ? Number(defaultValues.plot_area) : undefined,
            property_area: defaultValues?.property_area ? Number(defaultValues.property_area) : undefined,
        },
    });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InnerFormWrapper>
                <ErrorAndInputWrapper errorMsg={errors.name?.message ? L(errors.name?.message) : ''}>
                    <Label title={L('property_name')} />
                    <RestyledInput isError={!!errors.name} type="text" {...register('name')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.property_type?.message ? L(errors.property_type?.message) : ''}>
                    <Label title={L('property_type')} />
                    <Controller
                        control={control}
                        name="property_type"
                        render={({ field: { onChange, value } }) => (
                            <RadixSelect
                                isError={!!errors.property_type}
                                onValueChange={onChange}
                                value={value}
                                options={propertyTypesOptions}
                            />
                        )}
                    />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper
                    style={{
                        gridColumn: oneFieldPerRow ? '1 / -1' : 'inherit',
                    }}
                    errorMsg={errors.description?.message ? L(errors.description?.message) : ''}
                >
                    <Label title={L('description')} />
                    <RestyledTextArea isError={!!errors.description} {...register('description')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper
                    errorMsg={errors.apartment_number?.message ? L(errors.apartment_number?.message) : ''}
                >
                    <Label title={L('apartment_number')} />
                    <RestyledInput isError={!!errors.apartment_number} type="text" {...register('apartment_number')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper
                    errorMsg={errors.property_number?.message ? L(errors.property_number?.message) : ''}
                >
                    <Label title={L('property_number')} />
                    <RestyledInput isError={!!errors.property_number} type="text" {...register('property_number')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.street?.message ? L(errors.street?.message) : ''}>
                    <Label title={L('property_street')} />
                    <RestyledInput isError={!!errors.street} type="text" {...register('street')} />
                </ErrorAndInputWrapper>

                <div style={{ display: 'flex', columnGap: '1rem' }}>
                    <ErrorAndInputWrapper errorMsg={errors.postal_code?.message ? L(errors.postal_code?.message) : ''}>
                        <Label title={L('property_postal_code')} />
                        <RestyledInput isError={!!errors.postal_code} type="text" {...register('postal_code')} />
                    </ErrorAndInputWrapper>

                    <ErrorAndInputWrapper errorMsg={errors.postal_city?.message ? L(errors.postal_city?.message) : ''}>
                        <Label title={L('property_postal_city')} />
                        <RestyledInput isError={!!errors.postal_city} type="text" {...register('postal_city')} />
                    </ErrorAndInputWrapper>
                </div>

                <ErrorAndInputWrapper errorMsg={errors.country?.message ? L(errors.country?.message) : ''}>
                    <Label title={L('country')} />
                    <RestyledInput isError={!!errors.country} type="text" {...register('country')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.commune?.message ? L(errors.commune?.message) : ''}>
                    <Label title={L('commune')} />
                    <RestyledInput isError={!!errors.commune} type="text" {...register('commune')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.parish?.message ? L(errors.parish?.message) : ''}>
                    <Label title={L('property_parish')} />
                    <RestyledInput isError={!!errors.parish} type="text" {...register('parish')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.property_term?.message ? L(errors.property_term?.message) : ''}>
                    <Label title={L('property_designation')} />
                    <RestyledInput isError={!!errors.property_term} type="text" {...register('property_term')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.property_area?.message ? L(errors.property_area?.message) : ''}>
                    <Label title={L('property_area')} />
                    <RestyledInput
                        isError={!!errors.property_area}
                        type="number"
                        step="any"
                        {...register('property_area', { valueAsNumber: true })}
                    />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.plot_area?.message ? L(errors.plot_area?.message) : ''}>
                    <Label title={L('property_plot_area')} />
                    <RestyledInput
                        isError={!!errors.plot_area}
                        type="number"
                        step="any"
                        {...register('plot_area', { valueAsNumber: true })}
                    />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.built_year?.message ? L(errors.built_year?.message) : ''}>
                    <Label title={L('property_built_year')} />
                    <RestyledInput isError={!!errors.built_year} type="number" {...register('built_year')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.rooms?.message ? L(errors.rooms?.message) : ''}>
                    <Label title={L('property_rooms')} />
                    <RestyledInput isError={!!errors.rooms} type="number" {...register('rooms')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.floors?.message ? L(errors.floors?.message) : ''}>
                    <Label title={L('property_floors')} />
                    <RestyledInput isError={!!errors.floors} type="number" {...register('floors')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.bathrooms?.message ? L(errors.bathrooms?.message) : ''}>
                    <Label title={L('property_bathrooms')} />
                    <RestyledInput isError={!!errors.bathrooms} type="number" {...register('bathrooms')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.assess_value?.message ? L(errors.assess_value?.message) : ''}>
                    <Label title={L('property_assess_value')} />
                    <RestyledInput isError={!!errors.assess_value} type="number" {...register('assess_value')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.heating?.message ? L(errors.heating?.message) : ''}>
                    <Label title={L('property_heating')} />
                    <RestyledInput isError={!!errors.heating} type="text" {...register('heating')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.stairwell?.message ? L(errors.stairwell?.message) : ''}>
                    <Label title={L('property_stairwell')} />
                    <RestyledInput isError={!!errors.stairwell} type="text" {...register('stairwell')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.house?.message ? L(errors.house?.message) : ''}>
                    <Label title={L('property_house')} />
                    <RestyledInput isError={!!errors.house} type="text" {...register('house')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.floor?.message ? L(errors.floor?.message) : ''}>
                    <Label title={L('property_floor')} />
                    <RestyledInput isError={!!errors.floor} type="number" {...register('floor')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper
                    errorMsg={errors.percentage_of_community?.message ? L(errors.percentage_of_community?.message) : ''}
                >
                    <Label title={L('property_proportion')} />
                    <RestyledInput
                        isError={!!errors.percentage_of_community}
                        type="number"
                        {...register('percentage_of_community')}
                    />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.monthly_fee?.message ? L(errors.monthly_fee?.message) : ''}>
                    <Label title={L('property_monthly')} />
                    <RestyledInput isError={!!errors.monthly_fee} type="text" {...register('monthly_fee')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={errors.access_date?.message ? L(errors.access_date?.message) : ''}>
                    <Label title={L('property_access_date')} />
                    <RestyledInput isError={!!errors.access_date} type="date" {...register('access_date')} />
                </ErrorAndInputWrapper>
            </InnerFormWrapper>

            <ButtonWraper>
                {handleDeleteProperty && (
                    <ConfirmationButton
                        type="button"
                        props={{
                            onConfirm: () => {
                                handleDeleteProperty();
                            },
                        }}
                    />
                )}
                <Button type="submit">{L('save')}</Button>
            </ButtonWraper>
        </form>
    );
};

export default PropertyTemplateForm;
