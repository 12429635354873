import React from 'react';
import Document from './Document';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableItem from './SortableItem';
import styled from '@emotion/styled';
import { ListDocumentsOutput } from '@app/trpc-api/public/BindersApi/BindersApi';

const DocumentList = styled.ul({
    listStyle: 'none',
    padding: 0,
    margin: 0,
});

type Props = {
    documents?: ListDocumentsOutput;
    removeDocument: (documentId: number) => void;
    renameDocument: (documentId: number, name: string) => void;
    updateDocType: ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => void;
    sortable?: boolean;
    handleDragEnd: (event: DragEndEvent) => void;
    isUpdatingSort: boolean;
};
const DocumentsArea = ({
    removeDocument,
    documents,
    sortable,
    renameDocument,
    updateDocType,
    handleDragEnd,
    isUpdatingSort,
}: Props) => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    if (documents) {
        if (sortable) {
            return (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} css>
                    <SortableContext
                        disabled={isUpdatingSort}
                        strategy={verticalListSortingStrategy}
                        items={documents.map((doc) => doc.id)}
                    >
                        <DocumentList>
                            {documents?.map((doc) => (
                                <SortableItem
                                    key={String(doc.id) + doc.document_data?.original?.metadata?.filename}
                                    id={doc.id}
                                >
                                    <Document
                                        documentDetail={{
                                            filename: doc.document_data?.original?.metadata?.filename,
                                            name: doc.name,
                                            originalId: doc.document_data?.original?.id,
                                            document_type: doc.document_type,
                                            document_subtype: doc.document_subtype,
                                        }}
                                        deleteDocument={() => removeDocument(doc.id)}
                                        editDocumentName={(name) => renameDocument(doc.id, name)}
                                        updateDocType={(document_type: number, document_subtype: number) =>
                                            updateDocType({
                                                documentId: doc.id,
                                                document_subtype,
                                                document_type,
                                            })
                                        }
                                    />
                                </SortableItem>
                            ))}
                        </DocumentList>
                    </SortableContext>
                </DndContext>
            );
        } else {
            const Documents = documents.map((doc) => {
                return (
                    <Document
                        key={doc.id}
                        documentDetail={{
                            filename: doc.document_data?.original?.metadata?.filename,
                            name: doc.name,
                            originalId: doc.document_data?.original?.id,
                            document_type: doc.document_type,
                            document_subtype: doc.document_subtype,
                        }}
                        deleteDocument={() => {
                            removeDocument(doc.id);
                        }}
                        editDocumentName={(name) => renameDocument(doc.id, name)}
                        updateDocType={(document_type: number, document_subtype: number) => ({
                            docId: doc.id,
                            document_type,
                            document_subtype,
                        })}
                    />
                );
            });

            return <DocumentList>{Documents}</DocumentList>;
        }
    }

    return null;
};

export default DocumentsArea;
