import React from 'react';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { style } from '../../../../components/Shared/Style';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../../components/Loading/Loading';
import { useGetContact } from '../../hooks/propertyHooks/propertyContactsQueries';
import { L } from '../../../../lib/i18n';
import ContactForm, { EditContactForm } from './EditContactForm';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import useEditPropertyContact from '../../hooks/propertyHooks/useEditPropertyContact';
import Label from '../../../../components/Forms/Label';
import Input from '../../../../components/Forms/Input';
import dayjs from 'dayjs';
import useDeletePropertyContact from '../../hooks/propertyHooks/useDeletePropertyContact';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Separator } from '../../../../components/Separator/Separator';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';

const Divider = styled(Separator)({
    backgroundColor: style.colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: style.margin.s,
});

const ContactDetailComponent = ({
    contactId,
    project_id,
    propertyId,
}: {
    project_id: string;
    propertyId: string;
    contactId: string;
}) => {
    const { data: contact, isLoading } = useGetContact(+contactId);
    const navigate = useNavigate();

    const { mutate } = useEditPropertyContact();
    const onSubmit = (contact: EditContactForm, file?: File) =>
        mutate(
            { contact, id: +contactId, file },
            {
                onSuccess: () => {
                    toast.success(`${L('contact')} ${contact.name} ${L('saved').toLocaleLowerCase()}`);
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );

    const { mutate: remove } = useDeletePropertyContact();

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(project_id);
    const propertyName = useGetPropertyName(propertyId);

    if (isLoading) return <Loading />;

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{contact?.name ?? L('contact')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            {status !== 'loading' && <H1>{contact?.name ?? L('contact')}</H1>}
            <CardWithDivider
                topArea={<CardTitle>{L('edit_contact')}</CardTitle>}
                mainArea={
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', columnGap: '1rem' }}>
                        <ContactForm contact={contact} onSubmit={onSubmit}>
                            <ButtonContainer>
                                <ConfirmationButton
                                    type="button"
                                    props={{
                                        shouldPreventDefault: true,
                                        onConfirm: () => {
                                            if (!contact?.id || !contact?.property_id) {
                                                return;
                                            }
                                            remove(
                                                {
                                                    id: +contact.id,
                                                    property_id: +contact.property_id,
                                                },
                                                {
                                                    onSuccess: () => {
                                                        toast.success(`${contact.name} ${L('removed').toLowerCase()}`);
                                                        navigate('..');
                                                    },
                                                    onError: (err) => {
                                                        if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                                                    },
                                                },
                                            );
                                        },
                                    }}
                                />
                                <Button type="submit" style={{ marginRight: '0px' }}>
                                    {L('save')}
                                </Button>
                            </ButtonContainer>
                        </ContactForm>
                        <DividerContainer>
                            <Divider decorative orientation="vertical" />
                        </DividerContainer>
                        <div>
                            <Label title={L('created_at')}>
                                <Input
                                    type="datetime-local"
                                    readOnly
                                    value={dayjs(contact?.created_at).format('YYYY-MM-DDTHH:mm')}
                                />
                            </Label>
                            <Label title={L('updated_at')}>
                                <Input
                                    type="datetime-local"
                                    readOnly
                                    value={dayjs(contact?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                />
                            </Label>
                        </div>
                    </div>
                }
            />
        </>
    );
};

export const ContactDetailPage = () => {
    const {
        id: project_id,
        propertyId,
        contactId,
    } = useParams<{
        id: string;
        propertyId: string;
        contactId: string;
    }>();
    return project_id && propertyId && contactId ? (
        <ContactDetailComponent contactId={contactId} project_id={project_id} propertyId={propertyId} />
    ) : null;
};
