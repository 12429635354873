import { Property } from '@app/api/models/Properties';
import AddonsApi from '@app/api/public/AddonsApi';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { L } from '../../../../lib/i18n';

const sendConfirmation = async ({ propertyIds }: Variables) => {
    const promises = propertyIds.map(async propertyId => AddonsApi.sendConfirmation(+propertyId));

    return await Promise.all(promises);
};

type Variables = {
    propertyIds: Property['id'][];
    propertyName: Property['name'][];
};

const useSendConfirmation = () => {
    return useMutation((variables: Variables) => sendConfirmation(variables), {
        onMutate: variables => {
            const propertyNames = variables.propertyName.join(', ');
            const id = toast.loading(`${L('sending_confirmation_properties')} ${propertyNames}`);

            return id;
        },
        onSuccess: (data, variables, context) => {
            if (context) {
                toast.success(`${L('confirmation_mail_sent')}`, { id: context });
            }
        },
        onError: (error, variables, context) => {
            if (context) {
                toast.error(`${L('error_sending_confirmation_mail')}`, { id: context });
            }
        }
    });
};

export default useSendConfirmation;
