import { AccountContact } from '@app/api/models/AccountContacts';
import { Project, ProjectDetail } from '@app/api/models/Projects';
import AccountContactsApi from '@app/api/public/AccountContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import z from 'zod';

const errorSchema = z.object({ project: z.string(), status: z.string() }).array();

export const copyContactsQueryKeys = {
    all: ['contacts'],
    copyContactToProjects: (contacts: AccountContact[], projects: Project[]) =>
        [...copyContactsQueryKeys.all, 'copy', { contacts, projects }] as const
};

const copyContactsToProjects = async ({
    contacts,
    projects
}: {
    contacts: AccountContact['id'][];
    projects: ProjectDetail[];
}) => {
    const response = await Promise.all(
        projects.map(async project => {
            try {
                const response = await AccountContactsApi.copyContactsToProject(contacts, project.id);
                if (response.status === 'error') return { project: project.name, status: response.status };
                return { project: project.name, status: 'success' };
            } catch (error) {
                return { project: project.name, status: 'unknown' };
            }
        })
    );
    const errors = response.find(item => item?.status === 'error' || item?.status === 'unknown');
    if (errors) throw response;
};

const useCopyContactsToProjects = () => {
    const queryClient = useQueryClient();
    return useMutation(copyContactsToProjects, {
        onSuccess: () => {
            toast.success('Kopiering genomförd');
        },
        onError: err => {
            console.log(err);

            const validatedError = errorSchema.safeParse(err);
            /* Shows error message  */
            if (validatedError.success) {
                const errors = validatedError.data.filter(item => item.status === 'error' || item.status === 'unknown');
                errors.map(item => {
                    toast.error(`Det gick inte att kopiera till ${item.project}`);
                });
                const success = validatedError.data.filter(item => item.status === 'success');
                success.length && toast.success(`${success.length} kopierade kontakter`);
            }
        },
        onSettled: () => {
            queryClient.invalidateQueries(copyContactsQueryKeys.all);
        }
    });
};

export { useCopyContactsToProjects as default };
