import AddonsApi from '@app/api/public/AddonsApi';
import { useQuery } from 'react-query';

const getHeaderInfo = async (propertyId: string) => {
    return await AddonsApi.getOrderConfirmationHeaderInfo(propertyId);
};

const useGetHeaderInfo = (propertyId: string) => {
    return useQuery(['addonsHeaderData', propertyId], () => getHeaderInfo(propertyId));
};

export default useGetHeaderInfo;
