import styled from '@emotion/styled';
import React from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import { L } from '../../../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import ContactForm, { AddContactForm } from './AddContactForm';
import useSavePropertyContact from '../../hooks/propertyHooks/useSavePropertyContact';

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

type Props = {
    handleToggleModal: () => void;
    property_id: number;
};

const NewContactModal = ({ handleToggleModal, property_id }: Props) => {
    const { mutate, status } = useSavePropertyContact();
    const onSubmit = (contact: AddContactForm, file?: File) =>
        mutate(
            { contact, property_id: property_id, file },
            {
                onSuccess: () => {
                    toast.success(`${L('contact')} ${contact.name} ${L('saved').toLocaleLowerCase()}`);
                    handleToggleModal();
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));

                    handleToggleModal();
                },
            },
        );

    return (
        <ContactForm onSubmit={onSubmit}>
            <ButtonWraper>
                <Button type="button" onClick={() => handleToggleModal()}>
                    {L('cancel')}
                </Button>

                {status === 'idle' && <Button type={'submit'}>{L('save')}</Button>}
                {status === 'loading' && (
                    <Button style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && <Button type={'submit'}>{L('error')}</Button>}
                {status === 'success' && <Button type={'submit'}>{L('saved')}</Button>}
            </ButtonWraper>
        </ContactForm>
    );
};

export default NewContactModal;
