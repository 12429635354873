import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';
import { AddProperty } from '../../schemas';

const useSaveProperty = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ projectId, property }: { property: AddProperty & { template: boolean }; projectId: number }) => {
            return trpc.BindersApi.saveProperty.query({ property, projectId });
        },
        {
            onSettled: (data) => {
                if (data?.project_id) queryClient.invalidateQueries(getPropertiesKeys.list(data.project_id));
            },
        },
    );
};

export default useSaveProperty;
