import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useParams } from 'react-router-dom';

export const getMaintenanceQueryKey = 'getMaintenance';

const getMaintenance = async (projectId: string, id: string) => {
    const r = await MaintenancesApi.id(+projectId, +id);
    return r;
};

const useGetMaintenance = () => {
    const { id, projectId } = useParams<{ id: string; projectId: string }>();
    return useQuery([getMaintenanceQueryKey, { id, projectId }], () => getMaintenance(projectId, id));
};

export default useGetMaintenance;
