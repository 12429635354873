import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { formatCurrency } from '@app/shared/lib/formatting';

interface Props {
    name: string;
    price: number;
    sortOrder: string;
    onClick?: (e) => void;
    offsettop?: number;
}

const Space = styled.div({
    display: 'flex',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: style.colors.background,
    color: style.colors.black90
});

const SpaceItems = styled.div({
    display: 'grid',
    width: '100%',
    alignItems: 'center',
    fontSize: style.fontSize.centi,
    minHeight: '54px',
    gridTemplateColumns: '1fr 1fr',
    padding: `0px ${style.margin.l}px`
});

const Items = styled.div({
    fontSize: style.fontSize.delta,
    lineHeight: '22px',
    justifySelf: 'start',
    alignSelf: 'center',
    '&:first-of-type': { justifySelf: 'start' },
    '&:last-of-type': { justifySelf: 'end' }
});

export const ProductListSpaces = (p: Props) => {
    return (
        <Space>
            <SpaceItems>
                <Items>
                    {p.sortOrder}. {p.name}
                </Items>
                <Items>{formatCurrency(p?.price)} </Items>
            </SpaceItems>
        </Space>
    );
};
