import { useMutation } from 'react-query';
import { RouterInput, trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../../../project-templates/hooks/ContentInformation/uploadToAws';

export default function useUploadProductDocuments() {
    return useMutation(
        async ({
            files,
            productId,
            documentType,
        }: {
            files: File[];
            productId: number;
            documentType?: RouterInput['BindersApi']['uploadPropertyDocument']['documentType'];
        }) => {
            const responses = files.map(async (file) => {
                const fileToSave = {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                };

                const response = await trpc.ProductsApi.uploadProductDocument.mutate({
                    file: fileToSave,
                    entityId: productId,
                    documentType: documentType,
                });

                if (response) {
                    await uploadToAws({
                        preSingedUrl: response.preSingedUrl,
                        file,
                        cleanup: async () => {
                            await trpc.ProductsApi.removeProductDocument.mutate({
                                id: response.id,
                                entityId: +productId,
                            });
                        },
                    });
                }
            });
            return await Promise.all(responses);
        },
    );
}
