import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';

const CompanyInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100%/3)',
    paddingLeft: style.margin.m
});
const CompanyText = styled.div({
    width: '100%',
    fontWeight: style.fontWeight.normal
});

const Title = styled.div({
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold
});

export const PropertyCompanyInfo = ({ CompanyTitle, project_info }) => {
    return (
        <>
            <CompanyInfo>
                {CompanyTitle && <Title>{CompanyTitle}</Title>}
                {project_info?.name && <CompanyText> {project_info?.name}</CompanyText>}
                {project_info?.address?.addressline1 && (
                    <CompanyText> {project_info?.address?.addressline1}</CompanyText>
                )}
                {project_info?.address?.addressline2 && (
                    <CompanyText> {project_info?.address?.addressline2}</CompanyText>
                )}
                {(project_info?.address?.postal_code || project_info?.address?.city) && (
                    <CompanyText>{`${project_info?.address?.postal_code} ${project_info?.address?.city}`}</CompanyText>
                )}
                {project_info?.phone_number && <CompanyText>{project_info?.phone_number}</CompanyText>}
                {project_info?.url && <CompanyText>{project_info?.url}</CompanyText>}
            </CompanyInfo>
        </>
    );
};
