import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import Input from '../../../components/Forms/Input';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';

const NewCommentContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0.5rem  1rem',
    gap: 10,
});
export default function NewComment() {
    return (
        <NewCommentContainer>
            <Input placeholder={L('write_something')} /> <Button>{L('send')}</Button>
        </NewCommentContainer>
    );
}
