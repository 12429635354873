import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../lib/i18n';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import styled from '@emotion/styled';
import { Separator } from '../../../components/Separator/Separator';
import { colors } from '../../../components/Shared/Style/colors';
import { useQueryClient } from 'react-query';
import { style } from '../../../components/Shared/Style';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import dayjs from 'dayjs';
import { useGetSpaceTypes } from '../../binders/hooks/spaceHooks/spaceQueries';
import { AddSpace } from '../../binders/schemas';
import SpaceForm from '../../binders/components/Spaces/SpaceForm';
import Images from '../../binders/components/Draggables/Images';
import { useDeleteSpace } from '../../project-templates/hooks/PropertyTemplates/propertySpacesTemplateQueries';
import useDragEndDnd from '../../../hooks/useDragEndDndNew';
import useUploadSpaceImagesTemplateProperty from '../../project-templates/hooks/Space/useUploadSpaceImagesTemplateProperty';
import useUpdateSpaceDocSortTemplateProperty from '../../project-templates/hooks/Space/useUpdateSpaceDocSortTemplateProperty';
import useRemoveSpaceImageTemplateProperty from '../../project-templates/hooks/Space/useRemoveSpaceImageTemplateProperty';
import {
    useGetSpaceImagesPropertyTemplate,
    getTemplatePropertySpaceKeys,
} from '../../project-templates/hooks/Space/spaceQueries';
import useUpdateSpaceOnPropertyTemplate from '../hooks/useUpdateSpaceOnPropertyTemplate';
import { useGetPropertySpace } from '../hooks/propertyTemplateQueries';
import ImageDropZoneNoText from '../../../components/ImageDropzone/ImageDropZoneNoText';

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const PropertySpaceTemplateDetailPage = () => {
    const { id: propertyId, spaceId } = useParams<{ id: string; spaceId: string }>();
    return propertyId && spaceId ? <Page propertyId={+propertyId} spaceId={+spaceId} /> : null;
};

const Page = ({ propertyId, spaceId }: { propertyId: number; spaceId: number }) => {
    const { data: spaceData, status } = useGetPropertySpace(+spaceId, +propertyId);
    const { data: spaceTypes } = useGetSpaceTypes();
    const { data: images } = useGetSpaceImagesPropertyTemplate(+spaceId);
    const { mutate: deleteSpace } = useDeleteSpace();
    const { mutate: deleteDocument } = useRemoveSpaceImageTemplateProperty();
    const { mutate: saveImage } = useUploadSpaceImagesTemplateProperty();
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateSpaceDocSortTemplateProperty();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const onImageUpload = (files: File[]) => {
        saveImage(
            { files, spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries(getTemplatePropertySpaceKeys.documentsBySpaceId(+spaceId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDeleteSpace = () => {
        deleteSpace(
            { spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(`${spaceData?.space_name} ${L('removed').toLowerCase()}`);
                    navigate(`..`);
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRemoveImage = (documentId: number) => {
        deleteDocument(
            { documentId, entityId: +spaceId },
            {
                onSuccess: () => {
                    toast.success(`${L('picture')} ${L('removed').toLowerCase()}`);
                    queryClient.invalidateQueries(getTemplatePropertySpaceKeys.documentsBySpaceId(+spaceId));
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const spaceTypeOptions = useMemo(
        () =>
            spaceTypes?.map((type) => {
                return {
                    value: type.id,
                    label: L(type.name),
                };
            }) || [],
        [spaceTypes],
    );

    const { mutate: updateSpace } = useUpdateSpaceOnPropertyTemplate();

    const onSubmit: SubmitHandler<AddSpace> = (data) => {
        updateSpace(
            { data, spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('updated_space'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const imageData: { id: number; url: string }[] = useMemo(() => {
        if (!images) return [];
        return images.map((image) => {
            return { id: image.id, url: image.signed_url };
        });
    }, [images]);

    const handleDragEnd = useDragEndDnd({
        queryKey: getTemplatePropertySpaceKeys.documentsBySpaceId(+spaceId),
        updateSortOrder: updateSortBulk,
        documentableId: +spaceId,
        reverseArray: true,
    });

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('space')}</CardTitle>}
            mainArea={
                status !== 'loading' && (
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', columnGap: '1rem' }}>
                        <SpaceForm
                            onSubmit={onSubmit}
                            spaceTypeOptions={spaceTypeOptions}
                            spaceData={spaceData}
                            handleDeleteSpace={handleDeleteSpace}
                            mediaQueryWidth="100%"
                        />
                        <DividerContainer>
                            <Divider decorative orientation="vertical" />
                        </DividerContainer>
                        <div>
                            <div>
                                <Images
                                    imageData={imageData}
                                    handleRemoveImage={handleRemoveImage}
                                    handleDragEnd={handleDragEnd}
                                    isUpdatingSort={isUpdatingSort}
                                >
                                    <ImageDropZoneNoText onDrop={onImageUpload} />
                                </Images>
                            </div>
                            <div>
                                <StyledH3>{L('extra_information')}</StyledH3>
                                <ErrorAndInputWrapper>
                                    <Label title={L('created_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(spaceData?.created_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <Label title={L('updated_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(spaceData?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                            </div>
                        </div>
                    </div>
                )
            }
        />
    );
};

export default PropertySpaceTemplateDetailPage;
