import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { formatCurrency } from '@app/shared/lib/formatting';

interface Props {
    title: string;
    price: string;
}

const Summarize = styled.div({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    backgroundColor: style.colors.white,
    color: style.colors.black90,
    padding: style.margin.m,
    paddingBottom: style.margin.xs,
    pageBreakBefore: 'always',
    paddingLeft: style.margin.l,
    paddingRight: style.margin.l
});

const SummarizeItems = styled.div({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: style.fontSize.centi,
    minHeight: '54px'
});

const Items = styled.div({
    fontSize: style.fontSize.gamma,
    lineHeight: '22px',
    justifySelf: 'start',
    alignSelf: 'center'
});

export const PropertyAddonsSummarize = (p: Props) => {
    return (
        <Summarize>
            <SummarizeItems>
                <Items>{p.title}:</Items>
                <Items>{formatCurrency(+p?.price)} inkl. moms</Items>
            </SummarizeItems>
        </Summarize>
    );
};
