import React from 'react';
import { L } from '../../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import useCreateTemplateFromProp from '../../../../lib/hooks/useCreateTemplateFromProp';
import { useGetPropertiesLean } from '../../hooks/propertyHooks/propertyQueries';
import { useGetProjectsLean } from '../../hooks/projectHooks/projectQueries';
import TemplateFromPropertyForm, { FormType } from './TemplateFromPropertyForm';

type Props = {
    handleCloseModal: () => void;
    propertyId: number | null;
    projectId: number;
};

const CreateTemplateFromProperty = ({ handleCloseModal, propertyId, projectId }: Props) => {
    const { data: properties } = useGetPropertiesLean({
        projectId,
        shouldFetch: 'all',
    });

    const { mutate } = useCreateTemplateFromProp();

    const { data: projects } = useGetProjectsLean({ projectStatus: 'both', templateStatus: 'regular' });

    const onSubmit: SubmitHandler<FormType> = ({ templateLevel, project, newName, property }) => {
        mutate(
            {
                projectId: templateLevel === 'project' ? project?.value : undefined,
                newName,
                propertyId: property.value,
            },
            {
                onSuccess: () => {
                    toast.success(`${L('property_template_created')}`);
                    handleCloseModal();
                },
                onError: () => {
                    toast.success(`${L('an_error_occurred')}`);
                },
            },
        );
    };

    if (!!properties && !!projects) {
        return (
            <TemplateFromPropertyForm
                properties={properties}
                projects={projects}
                propertyId={propertyId}
                onSubmit={onSubmit}
                projectId={projectId}
            />
        );
    }
};

export default CreateTemplateFromProperty;
