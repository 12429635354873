import { Contact } from '@app/api/models/Contacts';
import AccountContactsApi from '@app/api/public/AccountContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { contactsQueryKey } from './useGetContacts';

const saveContact = (contact: Partial<Contact> & { file?: File }) => {
    return AccountContactsApi.save(contact);
};

const useSaveContact = () => {
    const queryClient = useQueryClient();

    return useMutation((contact: Partial<Contact> & { file?: File }) => saveContact(contact), {
        onError: err => {
            /* Shows error message */

            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: (data, err, variables) => {
            variables.id && queryClient.invalidateQueries(contactsQueryKey.getContactById(variables.id));
            queryClient.invalidateQueries(contactsQueryKey.getContactList());
        }
    });
};

export default useSaveContact;
