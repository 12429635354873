import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';

export const getSupplierKeys = {
    all: ['suppliers'] as const,
    lists: () => [...getSupplierKeys.all, 'list'] as const,
    list: () => [...getSupplierKeys.all, 'list'],
    listInfinite: (search?: string | null, sorting?: SortingState) => [
        ...getSupplierKeys.lists(),
        'listInfinite',
        search,
        sorting
    ],
    details: () => [...getSupplierKeys.all, 'detail'] as const,
    detail: (supplierId: number) => [...getSupplierKeys.details(), { supplierId }] as const
};

export function useGetInfinteSuppliers({
    keepPreviousData,
    search,
    sorting
}: {
    keepPreviousData: boolean;
    search: string | null;
    sorting: SortingState;
}) {
    return useInfiniteQuery(
        getSupplierKeys.listInfinite(search, sorting),
        async ({ pageParam = 1 }) =>
            await trpc.SupplierApi.getSuppliersPaginated.query({
                page: pageParam,
                search,
                sorting
            }),
        {
            keepPreviousData,
            getNextPageParam: lastPage => {
                return lastPage.nextCursor;
            }
        }
    );
}

export function useGetSupplierById({ keepPreviousData, id }: { keepPreviousData: boolean; id: number }) {
    return useQuery(
        getSupplierKeys.detail(id),
        async () =>
            await trpc.SupplierApi.getSupplierById.query({
                id
            }),
        {
            keepPreviousData
        }
    );
}
