import React from 'react';
import toast from 'react-hot-toast';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';
import { AddAccountProduct } from '../../binders/schemas';
import ProductTemplateForm from '../components/ProductTemplateForm';
import { useAddProduct } from '../../project-templates/hooks/InventoryTemplates/inventoryTemplateQueries';
import { useNavigate } from 'react-router-dom-v5-compat';

export default function AddInventoryTemplatePage() {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();

    const navigate = useNavigate();

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProduct();

    const onSubmit: SubmitHandler<AddAccountProduct> = (data) => {
        saveInventoryMaterial(
            { inventoryMaterial: data },
            {
                onSuccess: () => {
                    toast.success(L('product_added'));
                    navigate('..');
                },
                onError: () => {
                    toast.error(L('product_not_added'));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('information')}</CardTitle>}
            mainArea={<ProductTemplateForm onSubmit={onSubmit} options={options} defaultValues={{}} />}
        />
    );
}
