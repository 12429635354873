import React, { useMemo } from 'react';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import DropdownContainer from '../../binders/components/emotion/DropdownContainer';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { useGetProjectDocuments, useGetProjects } from '../../binders/hooks/projectHooks/projectQueries';
import { useLocalStorage } from 'react-use';
import FormatOptionsLabel from '../../binders/components/FormatOptionsLabel';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import useGetDeleteDocumentsListColumns from './useGetDeleteDocumentsListColumns';
import H1 from '../../../components/typography/H1';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import { useSearchParams } from 'react-router-dom-v5-compat';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';
import useDeletePropertyDocuments from '../hooks/useDeletePropertyDocuments';
import GenericTableVirtualized from '../../binders/components/GenericTableVirtualized';

const StyledCardWithDivider = styled(CardWithDivider)({
    alignSelf: 'start',
    '& .main': {
        overflowY: 'auto',
    },
});

const Grid = styled.div({
    display: 'grid',
    gap: '1rem',
    alignItems: 'center',
});

const DeleteDocumentsPage = () => {
    const columns = useGetDeleteDocumentsListColumns();
    const { data: projects } = useGetProjects();
    const [localStorageValue, setLocalStorageValue] = useLocalStorage<{
        project: { value: string; label: string } | null;
    }>('deleteDocumentsPageSelection', {
        project: null,
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';
    const { mutate: deleteDocuments } = useDeletePropertyDocuments();

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const { data: documents } = useGetProjectDocuments({
        projectId: localStorageValue?.project?.value ? +localStorageValue.project.value : null,
        search: searchField,
    });

    const setProject = (val: { value: string; label: string } | null) =>
        setLocalStorageValue({
            project: val,
        });

    const documentsMemo = useMemo(() => {
        return documents || [];
    }, [documents]);

    const table = useReactTable({
        data: documentsMemo,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getRowId: (originalRow) => originalRow.id.toString(),
    });

    const projectOption =
        projects?.map((option) => ({
            value: String(option.id),
            label: String(option.name),
            template: option.template,
        })) ?? [];

    const selectedRows = Object.keys(table.getSelectedRowModel().rowsById);

    return (
        <>
            <H1>{L('document_management')}</H1>
            <BigTabNav>
                <BigNavItem end to="..">
                    {L('document_copying')}
                </BigNavItem>
                <BigNavItem end to=".">
                    {L('document_deletion')}
                </BigNavItem>
            </BigTabNav>

            <Grid>
                <StyledCardWithDivider
                    topArea={
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CardTitle>
                                {L('documents')} {`(${documentsMemo.length})`}
                            </CardTitle>
                            <div
                                style={{
                                    display: 'grid',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    gridTemplateColumns: 'repeat(3, auto)',
                                }}
                            >
                                <DropdownContainer>
                                    <ReactSelect
                                        placeholder={L('project')}
                                        options={projectOption}
                                        value={localStorageValue?.project ?? null}
                                        onChange={(value) => {
                                            setProject(value);
                                        }}
                                        isSearchable={true}
                                        width={'140px'}
                                        formatOptionLabel={FormatOptionsLabel}
                                    />
                                </DropdownContainer>
                                <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                                <ConfirmationButton
                                    disabled={selectedRows.length === 0}
                                    props={{
                                        onConfirm: () => {
                                            deleteDocuments(selectedRows.map((id) => +id));
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    }
                    mainArea={
                        <>
                            <GenericTableVirtualized table={table} shouldFillWidth={false} estimateSize={50} />
                            {documentsMemo.length === 0 && !searchField && (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_documents')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        {L('no_documents_delete_function_description')}
                                    </EmptyState.Description>
                                </EmptyState>
                            )}
                            {documentsMemo.length === 0 && searchField && (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_documents_found')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        {L('no_documents_found_description')}
                                    </EmptyState.Description>
                                </EmptyState>
                            )}
                        </>
                    }
                />
            </Grid>
        </>
    );
};

export default DeleteDocumentsPage;
