import React from 'react';
import DocumentAreaAndDropZone from '../../../components/DocumentCommunity/DocumentAreaAndDropZone';
import { getProductsKeys, useGetProductsDocuments } from '../../binders/hooks/productQueries';
import useUploadProductDocuments from '../../binders/hooks/productHooks/useUploadProductDocuments';
import useRemoveProductDocument from '../../binders/hooks/productHooks/useRemoveProductDocument';
import useUpdateProductDocName from '../../binders/hooks/productHooks/useUpdateProductDocName';
import useUpdateProductDocType from '../../binders/hooks/productHooks/useUpdateProductDocType';
import { useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import useDragEndDndNew from '../../../hooks/useDragEndDndNew';

type Props = {
    updateSortBulk: Parameters<typeof useDragEndDndNew>[0]['updateSortOrder'];
    productId: number;
    isUpdatingSort: boolean;
};

const ProductDocumentList = ({ productId, updateSortBulk, isUpdatingSort }: Props) => {
    const { data: documents } = useGetProductsDocuments(productId);
    const { mutate: uploadDocs } = useUploadProductDocuments();
    const { mutate: removeDocument } = useRemoveProductDocument();
    const { mutate: renameDocument } = useUpdateProductDocName();
    const { mutate: updateDocType } = useUpdateProductDocType();

    const queryClient = useQueryClient();
    const handleSaveMultipleDocs = (files: File[]) => {
        uploadDocs(
            { files, productId },
            {
                onSuccess: () => {
                    toast.success(L('documents_uploaded'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
            },
        );
    };

    const handleRemoveDocument = (documentId: number) => {
        removeDocument(
            { documentId, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRenameDocument = (documentId: number, name: string) => {
        renameDocument(
            { docId: documentId, name, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('renamed_document')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleUpdateDocType = ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => {
        updateDocType(
            { docId: documentId, document_type, document_subtype, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('updated_document_types'));
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDragEndDocs = useDragEndDndNew({
        queryKey: getProductsKeys.documentsByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    return (
        <DocumentAreaAndDropZone
            documents={documents}
            removeDocument={handleRemoveDocument}
            saveMultipleDocs={handleSaveMultipleDocs}
            renameDocument={handleRenameDocument}
            updateDocType={handleUpdateDocType}
            handleDragEnd={handleDragEndDocs}
            isUpdatingSort={isUpdatingSort}
        />
    );
};

export default ProductDocumentList;
