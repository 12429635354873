import React, { useCallback, useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import { Button } from '@ourliving/ourliving-ui';
import CardTitle from '../../../components/typography/CardTitle';
import { useNavigate } from 'react-router-dom-v5-compat';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    Row as RowType,
} from '@tanstack/react-table';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import GenericTableVirtualized from '../../binders/components/GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import Row from '../../binders/components/emotion/Row';
import { useGetInfinitePropertyTemplates } from '../hooks/propertyTemplateQueries';
import Dialog from '../../../components/Dialog/Dialog';
import CreateTemplateFromTemplate from '../components/CreateTemplateFromTemplate';

export type FiltersSpaces = {
    space_type: { value: string; label: string }[];
};

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

type PropertyTemplate = NonNullable<ReturnType<typeof useGetInfinitePropertyTemplates>['data']>['pages'][0]['items'][0];

type PropertyTemplatesColumnsTableMeta = {
    handleTemplateBtnClick: (id: number) => void;
};

const columnHelper = createColumnHelper<PropertyTemplate>();

const PropertyTemplatesPage = () => {
    const navigate = useNavigate();
    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('template_name')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.type_name, {
                id: 'type_name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{L(info.row.original.type_name)}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('project_type_literally')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.display({
                id: 'create_template',
                cell: ({ table, row }) => (
                    <CellContainer
                        style={{
                            marginLeft: 'auto',
                        }}
                    >
                        <div>
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    (table.options.meta as PropertyTemplatesColumnsTableMeta).handleTemplateBtnClick(
                                        row.original.id,
                                    );
                                }}
                            >
                                {L('create_template')}
                            </Button>
                        </div>
                    </CellContainer>
                ),
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
                enableResizing: false,
            }),
        ],
        [],
    );

    const searchParams = new URLSearchParams(location.search);

    const searchField = useMemo(() => searchParams.get('searchField') ?? '', [searchParams]);

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };

    const [sorting, setSorting] = useState<SortingState>([]);

    const { fetchNextPage, hasNextPage, isFetching, data } = useGetInfinitePropertyTemplates({
        sorting,
        search: searchField,
    });

    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);
    const totalDBRowCount = useMemo(() => Number(data?.pages?.[0]?.totalItems ?? 0), [data]);
    const totalFetched = useMemo(() => flatData.length, [flatData]);
    const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
    const [selectedPropertyForTemplate, setSelectedPropertyForTemplate] = useState<number | null>(null);

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        meta: {
            handleTemplateBtnClick: (id) => {
                setSelectedPropertyForTemplate(id);
                setOpenCreateTemplate(true);
            },
        } satisfies PropertyTemplatesColumnsTableMeta,
    });

    const handleClickRow = useCallback((row: RowType<PropertyTemplate>) => {
        navigate(`${row.original.id}`);
    }, []);

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>
                        {L('property_templates')} {data ? `(${data.pages[0].totalItems})` : ''}
                    </CardTitle>
                    <Button
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                            navigate(`new`);
                        }}
                    >
                        {L('new_property_template')}
                    </Button>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <Dialog open={openCreateTemplate} onOpenChange={setOpenCreateTemplate}>
                        <CreateTemplateFromTemplate
                            handleCloseModal={() => {
                                setOpenCreateTemplate(false);
                                setSelectedPropertyForTemplate(null);
                            }}
                            templateId={selectedPropertyForTemplate}
                        />
                    </Dialog>
                    <FilterSearchContainer>
                        <div style={{ marginLeft: 'auto' }}>
                            <Row>
                                <GlobalFilter
                                    filter={searchField ?? ''}
                                    setFilter={handleSearch}
                                    placeholder={L('search')}
                                />
                            </Row>
                        </div>
                    </FilterSearchContainer>

                    <GenericTableVirtualized
                        table={table}
                        handleFetchNextPage={() => fetchNextPage()}
                        hasNextPage={hasNextPage}
                        isFetching={isFetching}
                        totalDBRowCount={totalDBRowCount}
                        totalFetched={totalFetched}
                        estimateSize={50}
                        rowClickHandler={handleClickRow}
                    />
                    {flatData?.length === 0 &&
                        data &&
                        (!searchField ? (
                            <EmptyState>
                                <EmptyState.Title>{L('no_property_templates')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_property_templates_description')}</p>
                                    <p>{L('click_button_below')}</p>
                                    <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                                        {L('new_property_template')}
                                    </Button>
                                </EmptyState.Description>
                            </EmptyState>
                        ) : (
                            <EmptyState>
                                <EmptyState.Title>{L('no_property_templates_found')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_property_templates_found_description')}</p>
                                    <p>{L('please_try_something_else')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        ))}
                </>
            }
        />
    );
};

export default PropertyTemplatesPage;
