import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProjectKeys } from './projectQueries';
import { SaveProject } from '@app/trpc-api/models/Binders';

const useSaveProject = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (dataToSave: SaveProject) => {
            return trpc.BindersApi.saveProject.query({ project: dataToSave });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.lists());
            }
        }
    );
};

export default useSaveProject;
