import styled from '@emotion/styled';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom-v5-compat';
import { colors } from '../Shared/Style/colors';

const StyledNavLink = styled(NavLink)(() => ({
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    letterSpacing: '0.41px',
    color: colors.tabInactive,
    boxSizing: 'border-box',
    display: 'block',
    padding: '0px',
    textDecoration: 'none',

    '&:hover': {
        color: colors.tabActive,
        cursor: 'pointer'
    },
    '&.active': {
        color: colors.tabActive,
        textDecoration: 'none',
        borderBottom: `1px solid ${colors?.tabActive}`
    }
}));

const BigNavItem: React.FC<NavLinkProps> = ({ children, ...rest }) => {
    return <StyledNavLink {...rest}>{children}</StyledNavLink>;
};

export default BigNavItem;
