import { useQueryClient, useMutation } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { InventoryFilter } from '@app/api/models/Inventory';
import { filteredProducts } from './useGetFilteredProducts';

export const updateCheckedProduct = async (product: InventoryFilter) => {
    return InventoryApi.insertInventoryFilter(product.id);
};

const useUpdateCheckedProduct = () => {
    const queryClient = useQueryClient();

    return useMutation((product: InventoryFilter) => updateCheckedProduct(product), {
        onMutate: product => {
            queryClient.cancelQueries([...filteredProducts.id(product.id)]);
            queryClient.setQueryData([...filteredProducts.id(product.id)], product);
        },
        onSettled: (_data, _error) => {
            queryClient.invalidateQueries([...filteredProducts.all]);
        }
    });
};

export default useUpdateCheckedProduct;
