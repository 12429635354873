import React, { useEffect, useState } from 'react';
import * as Select from '@radix-ui/react-select';
import styled from '@emotion/styled';
import { L } from '../../lib/i18n';
import { colors } from '../Shared/Style/colors';
import { ChevronDown, X as CrossIcon } from 'react-feather';
import { ComponentProps } from 'react';

const SelectItem = styled(Select.Item)({
    fontSize: '0.8rem',
    lineHeight: 1,
    color: colors.inputColor,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    userSelect: 'none',
    cursor: 'pointer',
    padding: `8px 12px`,
    width: '100%',
    boxSizing: 'border-box',
    '&[data-highlighted]': {
        outline: 'none',
        backgroundColor: '#DEEBFF',
    },
    '&:hover': {
        outline: 'none',
        backgroundColor: '#DEEBFF',
    },
    '&[data-state="checked"]': {
        backgroundColor: colors.tabInactive,
    },
});

const SelectTrigger = styled(Select.Trigger)({
    all: 'unset',
    alignItems: 'center',
    backgroundColor: '#FCFDFE',
    borderColor: colors.inputBorder,
    borderRadius: '4px',
    borderStyle: 'solid',
    borderWidth: '1px',
    cursor: 'pointer',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: '32px',
    outline: '0!important',
    position: 'relative',
    transition: 'all 100ms',
    boxSizing: 'border-box',
    fontSize: '0.8rem',
    color: colors.inputColor,
    width: '100%',
    paddingLeft: '12px',
    '&:focus': { boxShadow: `0 0 0 1px ${colors.tabInactive}` },
    '&[data-is-error="true"]': {
        borderColor: '#ff4545',
    },
    '&[data-disabled]': {
        backgroundColor: colors.inputDisabled,
        borderColor: '#e6e6e6',
    },
    '&[data-placeholder]': {
        color: colors.inputPlaceholderColor,
    },
});

const SelectGroup = styled(Select.Group)({
    maxHeight: '300px',
    overflowY: 'auto',
    paddingBottom: '4px',
    paddingTop: '4px',
    position: 'relative',
    boxSizing: 'border-box',
    width: '100%',
});

const SelectContent = styled(Select.Content)({
    overflow: 'hidden',
    backgroundColor: 'white',
    boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    top: '0',
    width: 'var(--radix-select-trigger-width)',
});

const IconContainer = styled.div({
    paddingRight: 3,
    paddingLeft: 3,
});

interface Props extends Omit<ComponentProps<typeof Select.Root>, 'value'> {
    options: {
        value: string | number | undefined;
        label: string;
    }[];
    value?: string | number | null;
    placeholder?: string;
    isError?: boolean;
    selectTriggerStyle?: React.CSSProperties;
    resetValue?: () => void;
}

const RadixSelect = ({
    options,
    placeholder,
    isError = false,
    value,
    selectTriggerStyle,
    resetValue,
    open,
    ...rest
}: Props) => {
    const [key, setKey] = useState<number>(+new Date());
    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                document.body.style.pointerEvents = '';
            }, 500);
        }
    }, [open]);
    return (
        <Select.Root value={value || value === 0 ? String(value) : undefined} key={key} open={open} {...rest}>
            <SelectTrigger data-is-error={String(isError)} style={selectTriggerStyle}>
                <Select.Value placeholder={placeholder ? L(placeholder) : L('please_select')} />
                <div style={{ display: 'flex' }}>
                    {resetValue && (
                        <IconContainer>
                            <CrossIcon
                                onPointerDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    resetValue();
                                    setKey(+new Date());
                                }}
                                color="#999"
                                size={16}
                            />
                        </IconContainer>
                    )}
                    <IconContainer>
                        <ChevronDown color="#999" size={16} />
                    </IconContainer>
                </div>
            </SelectTrigger>
            <Select.Portal>
                <SelectContent position="popper">
                    <Select.Viewport>
                        <SelectGroup>
                            {options.map((option) => (
                                <SelectItem key={option.value} value={String(option.value)}>
                                    <Select.ItemText>{option.label}</Select.ItemText>
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </Select.Viewport>
                </SelectContent>
            </Select.Portal>
        </Select.Root>
    );
};

export default RadixSelect;
