import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdatePropertyTemplateDocType = () => {
    return useMutation(
        async (params: { docId: number; document_type: number; document_subtype: number; propertyId: number }) => {
            return trpc.TemplatesApi.updatePropertyTemplateDocType.mutate(params);
        },
    );
};

export default useUpdatePropertyTemplateDocType;
