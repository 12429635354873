import React from 'react';
import styled from '@emotion/styled';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { Filters } from '../../../components/Filters/Filters';
import { L } from '../../../lib/i18n';
import { SelectOption } from '../DataType';
import Cross from '../../../components/Icon/e-remove-outline-12.svg';
import { style } from '../../../components/Shared/Style';

const DropdownContainer = styled.div({
    display: 'flex',
    columnGap: '0.625rem',
});

const StyledCross = styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    color: '#4B506D66',
    marginRight: '5px',
});

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase',
});

type Props<T extends Record<string, { value: string; label: string }[]>> = {
    filterOptions: T;
    options: Record<Extract<keyof T, string>, string>;
    selected: {
        [key in keyof T]: SelectOption[];
    };
    setSelectedFilter: <K extends keyof T>(key: K, value: SelectOption[]) => void;
    clearAll: () => void;
};

const FilterTableDataV2 = <T extends Record<string, { value: string; label: string }[]>>({
    filterOptions,
    options,
    selected,
    setSelectedFilter,
    clearAll,
}: Props<T>) => {
    return (
        <Filters marginBottom={'0'} marginRight={'0'}>
            <DropdownContainer>
                {Object.entries(filterOptions).map((option) => {
                    return (
                        <ReactSelect
                            key={option[0]}
                            closeMenuOnSelect={false}
                            placeholder={L(options[option[0]])}
                            controlShouldRenderValue={false}
                            isMulti
                            hideSelectedOptions={false}
                            options={option[1]}
                            value={selected[option[0]]}
                            onChange={(multiValues) => setSelectedFilter(option[0], [...multiValues])}
                            isSearchable={true}
                        />
                    );
                })}
            </DropdownContainer>
            <div style={{ display: 'flex', cursor: 'pointer', marginLeft: '0.5rem' }} onClick={() => clearAll()}>
                <StyledCross />
                <Label>{L('clear_filter')}</Label>
            </div>
        </Filters>
    );
};

export default FilterTableDataV2;
