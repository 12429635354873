import { useQuery } from 'react-query';
import MaintenancesApi, { MaintenanceFilter } from '@app/api/public/MaintenancesApi';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { useParams } from 'react-router-dom';

const getMaintenanceList = async (projectId: string, filter?: MaintenanceFilter | undefined) => {
    return await MaintenancesApi.list(+projectId, filter);
};

const useGetMaintenanceList = (filter?: MaintenanceFilter | undefined) => {
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery([getMaintenanceQueryKey, { projectId, filter }], () => getMaintenanceList(projectId, filter), {
        keepPreviousData: true
    });
};

export default useGetMaintenanceList;
