import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase',
    minWidth: '90.5px',
    color: style.colors.secondary
});

export default Label;
