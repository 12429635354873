import { configure, RpcRequestInit } from 'koa-rpc-call/browser';

async function onRequest(info: RpcRequestInit, finish: (i: RpcRequestInit) => Promise<any>) {
    if (!info.headers) info.headers = {};
    info.headers['Authorization'] = localStorage.TOKEN ? `Basic ${localStorage.TOKEN}` : '';
    try {
        return await finish(info);
    } catch (ex) {
        if (ex.response && (ex.response as any).status === 401) {
            delete localStorage.TOKEN;
            location.reload();
        } else {
            throw ex;
        }
    }
}

configure({ onRequest });
