import React, { useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';

const StyledCircle = styled.circle(
    (props: { strokesize: number; radius: number; current: string | undefined; total: string | undefined }) => {
        const { strokesize, radius } = props;
        const normalizedRadius = radius - strokesize * 2;
        const circumference = normalizedRadius * 2 * Math.PI;
        const progress = (props.current / props.total) * 100;
        const noProgress = props.current + props.total;
        return {
            transform: 'rotate(-90deg)',
            transformOrigin: 'center',
            radius: radius,
            x: 0,
            y: 0,
            stroke: progress > 99 || noProgress === 0 ? 'green' : 'orange',
            fill: 'transparent',
            strokeWidth: strokesize,
            strokeDasharray: circumference + ' ' + circumference,
            r: normalizedRadius,
            cx: radius,
            cy: radius
        };
    }
);

const AnimatedCircle = animated(StyledCircle);
const StyledCircleBackground = styled.circle(
    (props: { strokesize: number; radius: number; current: string | undefined; total: string | undefined }) => {
        const { strokesize, radius } = props;
        const normalizedRadius = radius - strokesize * 2;
        const circumference = normalizedRadius * 2 * Math.PI;
        const progress = (props.current / props.total) * 100;
        const noProgress = props.current + props.total;
        return {
            transform: 'rotate(-90deg)',
            transformOrigin: 'center',
            radius: radius,
            x: 0,
            y: 0,

            stroke: progress > 99 || noProgress === 0 ? 'green' : 'orange',

            opacity: '0.4',
            fill: 'transparent',
            strokeWidth: strokesize,
            strokeDasharray: circumference + ' ' + circumference,
            r: normalizedRadius,
            cx: radius,
            cy: radius
        };
    }
);
const StyledText = styled.text((props: { current: string | undefined; total: string | undefined }) => {
    const { radius } = props;
    return {
        fontSize: props.current + props.totalt > 18 ? `${radius / 2.8}px` : `${radius / 2.6}px`,
        size: radius,
        dominantBaseline: 'middle',
        textAnchor: 'middle'
    };
});
const StyledSvg = styled.svg({
    display: 'block',
    marginTop: 'auto',
    marginBottom: 'auto',
    backgroundColor: 'inherit',
    borderRadius: '100%',
    opacity: 1
});

export type props = {
    current: string | number | undefined;
    total: string | number | undefined;
    radius?: number;
    stroke?: number;
    startPercentage?: number;
};

const RadialProgressBar: React.FC<props> = ({ current, total, radius = 20, stroke = 2, startPercentage }) => {
    const safeCurrent = typeof +current === 'number' ? +current : 0;
    const safeTotal = typeof +total === 'number' ? +total : 0;
    const safestartPercentage = startPercentage && typeof +startPercentage === 'number' ? +startPercentage : 0;
    const latestOffset = useRef(
        safestartPercentage || safestartPercentage === 0 ? safestartPercentage : safeCurrent / safeTotal
    );

    const getOffset = value => {
        if (typeof +value !== 'number') {
            return 0;
        }
        let percentage = +value;
        if (+value > 1) {
            percentage = 1;
        }
        if (+value < 0) {
            percentage = 0;
        }
        const normalizedRadius = radius - stroke * 2;
        const circumference = normalizedRadius * 2 * Math.PI;
        return circumference - percentage * circumference;
    };

    const springOffset = useSpring({
        value: getOffset(safeTotal !== 0 ? safeCurrent / safeTotal : 0),
        from: { value: getOffset(latestOffset.current) },
        onStart: () => {
            if (safeTotal === 0) {
                latestOffset.current = 0;
                return;
            }
            latestOffset.current = safeCurrent / safeTotal;
        }
    });

    return (
        <StyledSvg height={radius * 2} width={radius * 2}>
            <AnimatedCircle
                strokeDashoffset={springOffset.value}
                current={current}
                total={total}
                radius={radius}
                strokesize={stroke}
            />
            <StyledCircleBackground current={current} total={total} radius={radius} strokesize={stroke} />
            <StyledText x={radius} y={radius + 1.5} current={current} totalt={total} radius={radius}>
                {current} / {total}
            </StyledText>
        </StyledSvg>
    );
};

export default RadialProgressBar;
