import React from 'react';
import { Header } from '../../components/Layout/Header';
import { NotificationCount } from '../../components/NotificationCount/NotificationCount';
import { NewCount } from '../../components/NewCount/NewCount';
import { NavLink } from 'react-router-dom';
import { L } from '../../lib/i18n';
import { Table } from '../../components/Table/Table';
import { Loading } from '../../components/Loading/Loading';
import { style } from '../../components/Shared/Style';
import styled from '@emotion/styled';
import { checkMediaQuery } from '../../components/checkMediaQuery/checkMediaQuery';
import { useGetTicketDashboard } from '../tickets/hooks/ticketQueries';
import useGetProjects from '../../lib/hooks/useGetProjects';

const ProjectName = styled.div({
    fontWeight: style.fontWeight.bold,
    textAlign: 'left',
});

const ProjectAddress = styled.div({
    fontWeight: style.fontWeight.normal,
    fontSize: style.fontSize.milli,
    textAlign: 'left',
});

const ProjectLink = styled(NavLink)({
    textDecoration: 'none',
    fontWeight: style.fontWeight.normal,
    fontSize: style.fontSize.seta,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.2px',
    textAlign: 'center',
    color: '#252733',
});

export const TicketDashboardPage = () => {
    const phoneDevice = checkMediaQuery(`${style.device.smartphone}`);

    const { data: dashboard } = useGetTicketDashboard();
    const { data: projects, isLoading } = useGetProjects('ticket');

    if (isLoading) {
        return <Loading />;
    }

    const rows =
        projects?.map((project) => ({
            property: (
                <>
                    <div>
                        <ProjectLink to={`/tickets?project_id=${project.id}`}>
                            <ProjectName>{project.name}</ProjectName>
                        </ProjectLink>
                    </div>
                    <ProjectAddress>
                        {(project.project_info?.address?.addressline1
                            ? project.project_info?.address?.addressline1
                            : '') +
                            ' ' +
                            (project.project_info?.address?.addressline2
                                ? project.project_info?.address?.addressline2
                                : '') +
                            ' ' +
                            (project.zip ? project.zip : '') +
                            ' ' +
                            (project.city ? project.city : '')}
                    </ProjectAddress>
                </>
            ),
            allTickets: (
                <NewCount count={dashboard?.tickets?.find((ticket) => ticket.projectId === project.id)?.allTickets} />
            ),
            newTickets: (
                <NewCount count={dashboard?.tickets?.find((ticket) => ticket.projectId === project.id)?.newTickets} />
            ),
            newMessages: phoneDevice ? null : (
                <NotificationCount>
                    {dashboard?.messages?.find((message) => message.projectId === project.id)?.newMessages}
                </NotificationCount>
            ),
        })) || [];

    return (
        <div>
            <Header title={L('projects')} />
            <Table
                columns={[
                    {
                        key: 'property',
                        header: L('property'),
                        width: '2fr',
                    },
                    {
                        key: 'allTickets',
                        header: 'Öppna ' + L('tickets'),
                        width: 'max-content',
                    },
                    {
                        key: 'newTickets',
                        header: 'Nya ' + L('tickets'),
                        width: 'max-content',
                    },
                    {
                        key: 'newMessages',
                        header: phoneDevice ? null : L('messages'),
                        width: 'max-content',
                    },
                ]}
                data={rows}
            />
        </div>
    );
};
