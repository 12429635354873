import { Order } from '@app/api/models/Orders';
import { Project } from '@app/api/models/Projects';
import { Property } from '@app/api/models/Properties';
import AddonsApi, { GrossListFilter } from '@app/api/public/AddonsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';
import { getOrderUserKeys } from './useGetUsersOnOrder';

const enableCheckout = async ({ propertyId, enableCheckout }: Variables) => {

    return await AddonsApi.setEnableCheckout(propertyId, enableCheckout);

};

type Variables = {
    propertyId: Property['id'];
    enableCheckout: boolean;
};

const useenableCheckout = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => enableCheckout(variables));
};

export default useenableCheckout;
