Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getAccount"] = b.createRpcProxy("AccountSettingsApi", "getAccount", {
  "urlPrefix": "/rpc"
});
o["getAccountById"] = b.createRpcProxy("AccountSettingsApi", "getAccountById", {
  "urlPrefix": "/rpc"
});
o["uploadAccounMailTemplate"] = b.createRpcProxy("AccountSettingsApi", "uploadAccounMailTemplate", {
  "urlPrefix": "/rpc"
});
o["getAccountMailTemplate"] = b.createRpcProxy("AccountSettingsApi", "getAccountMailTemplate", {
  "urlPrefix": "/rpc"
});
o["getAccountMailTemplatesList"] = b.createRpcProxy("AccountSettingsApi", "getAccountMailTemplatesList", {
  "urlPrefix": "/rpc"
});
o["deleteAccountMailTemplate"] = b.createRpcProxy("AccountSettingsApi", "deleteAccountMailTemplate", {
  "urlPrefix": "/rpc"
});
o["getEntitySupplierList"] = b.createRpcProxy("AccountSettingsApi", "getEntitySupplierList", {
  "urlPrefix": "/rpc"
});
o["saveEntitySupplier"] = b.createRpcProxy("AccountSettingsApi", "saveEntitySupplier", {
  "urlPrefix": "/rpc"
});
o["removeEntitySupplier"] = b.createRpcProxy("AccountSettingsApi", "removeEntitySupplier", {
  "urlPrefix": "/rpc"
});
o["getSuppliers"] = b.createRpcProxy("AccountSettingsApi", "getSuppliers", {
  "urlPrefix": "/rpc"
});
o["getProfessions"] = b.createRpcProxy("AccountSettingsApi", "getProfessions", {
  "urlPrefix": "/rpc"
});
o["getProductTypes"] = b.createRpcProxy("AccountSettingsApi", "getProductTypes", {
  "urlPrefix": "/rpc"
});
o["getBrands"] = b.createRpcProxy("AccountSettingsApi", "getBrands", {
  "urlPrefix": "/rpc"
});
o["listAccountAddonInfo"] = b.createRpcProxy("AccountSettingsApi", "listAccountAddonInfo", {
  "urlPrefix": "/rpc"
});
o["saveAccountAddonInfo"] = b.createRpcProxy("AccountSettingsApi", "saveAccountAddonInfo", {
  "urlPrefix": "/rpc"
});