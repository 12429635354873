import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import React, { useState } from 'react';
import { Property } from '@app/api/models/Properties';
import { useGetProductDetail } from './hooks/useGetProductDetail';
import { Project } from '@app/api/models/Projects';
import useGetSignedUrls from '../../lib/hooks/useGetSignedUrls';
import CarouselHero from './components/ProductImageCarousel';
import styled from '@emotion/styled';
import Label from '../../components/Forms/Label';
import Input from '../../components/Forms/Input';
import useGetSignedUrlById from '../../lib/hooks/useGetSignedUrlById';

const StyledCarouselHero = styled(CarouselHero)({
    'div:first-of-type': {
        gap: '2rem',
    },
});
const Container = styled.div({
    display: 'grid',
    alignItems: 'center',
});

const StyledLabel = styled(Label)({
    marginTop: '18px',
});

const Wrapper = styled.div({
    backgroundColor: 'white',
    width: '600px',
    padding: '2rem',
    maxHeight: '700px',
    overflow: 'auto',
    ':focus-visible': {
        outline: 'none',
    },
});

type Props = {
    productId: Property['id'];
    propertyId: Project['id'];
};

const ProductPage = ({ productId, propertyId }: Props) => {
    const { data } = useGetProductDetail(productId, propertyId);
    const { data: signedUrls } = useGetSignedUrls(data?.documents);

    const { data: nameplate } = useGetSignedUrlById(
        data?.documents?.find((doc) => doc.document_type === 'nameplate')?.document_data.original.id || undefined,
        '800x0',
    );

    return (
        <Wrapper>
            <PageTitle>{data?.name}</PageTitle>
            {signedUrls && signedUrls.length > 0 ? (
                <>
                    <StyledLabel name="product" title={L('product_images')} />
                    <StyledCarouselHero urls={signedUrls && signedUrls} title={data?.name} to="/properties" />
                </>
            ) : (
                <></>
            )}
            <Container>
                {nameplate && (
                    <>
                        <StyledLabel name="product" title={L('nameplate')} />
                        <img style={{ width: '100%' }} src={nameplate} />
                    </>
                )}
                {data?.attr.product && (
                    <>
                        <StyledLabel name="product" title={L('product')} />
                        <Input disabled={true} id="product" value={data.attr.product || ''} />
                    </>
                )}
                {data?.color && (
                    <>
                        <StyledLabel name="product_color" title={L('product_color')} />
                        <Input disabled={true} id="product_color" value={data.attr.color || ''} />
                    </>
                )}
                {data?.model && (
                    <>
                        <StyledLabel name="brand" title={L('brand')} />
                        <Input disabled={true} id="brand" value={data.model || ''} />
                    </>
                )}

                {data?.attr.article_no && (
                    <>
                        <StyledLabel name="article_no" title={L('article_no')} />
                        <Input disabled={true} id="article_no" value={data.attr.article_no || ''} />
                    </>
                )}
                {data?.attr.rsk_no && (
                    <>
                        <StyledLabel name="rsk_no" title={L('rsk_no')} />
                        <Input disabled={true} id="rsk_no" value={data.attr.rsk_no || ''} />
                    </>
                )}
                {data?.attr.e_no && (
                    <>
                        <StyledLabel name="e_no" title={L('e_no')} />
                        <Input disabled={true} id="e_no" value={data.attr.e_no || ''} />
                    </>
                )}

                {data?.attr.serial_number && (
                    <>
                        <StyledLabel name="serial_number" title={L('serial_number')} />
                        <Input disabled={true} id="serial_number" value={data.attr.serial_number || ''} />
                    </>
                )}
                {data?.attr.gtin_ean && (
                    <>
                        <StyledLabel name="gtin_ean" title={L('gtin_ean')} />
                        <Input disabled={true} id="gtin_ean" value={data.attr.gtin_ean || ''} />
                    </>
                )}
                {data?.attr.url && (
                    <>
                        <StyledLabel name="product_url" title={L('product_url')} />
                        <Input disabled={true} id="product_url" value={data.url || ''} />
                    </>
                )}
                {data?.extra_information && (
                    <>
                        <StyledLabel name="extra_information" title={L('extra_information')} />
                        <Input disabled={true} id="extra_information" value={data.extra_information || ''} />
                    </>
                )}
                {data?.attr.service_advice && (
                    <>
                        <StyledLabel name="service_advice" title={L('service_advice')} />
                        <Input disabled={true} id="service_advice" value={data.attr.service_advice || ''} />
                    </>
                )}
                {data?.attr.purchase_store && (
                    <>
                        <StyledLabel name="purchase_store" title={L('purchase_store')} />
                        <Input disabled={true} id="purchase_store" value={data.attr.purchase_store || ''} />
                    </>
                )}
                {data?.attr.purchase_date && (
                    <>
                        <StyledLabel name="purchase_date" title={L('purchase_date')} />
                        <Input disabled={true} id="purchase_date" value={data.attr.purchase_date || ''} />
                    </>
                )}
                {data?.attr.purchase_price && (
                    <>
                        <StyledLabel name="purchase_price" title={L('purchase_price')} />
                        <Input disabled={true} id="purchase_price" value={data.attr.purchase_price || ''} />
                    </>
                )}
                {data?.attr.warranty_end_date && (
                    <>
                        <StyledLabel name="warranty_end_date" title={L('warranty_end_date')} />
                        <Input disabled={true} id="warranty_end_date" value={data.attr.warranty_end_date || ''} />
                    </>
                )}
                <StyledLabel name="productid" title={L('id')} />
                <Input disabled={true} id="productid" value={productId || ''} />
            </Container>
        </Wrapper>
    );
};

export default ProductPage;
