import { useQuery } from 'react-query';
import NotificationsApi from '@app/api/public/NotificationsApi';
import { getNotificationKeys } from '@app/shared/queryKeys/notifications';

export const useGetNotificationBellCount = () => {
    return useQuery(getNotificationKeys.counts(), () => NotificationsApi.getNotificationCount());
};

export const useNotificationList = (search: string, page: number, type?: string | undefined, isOpenModal?: boolean) => {
    return useQuery(
        getNotificationKeys.list(search, page, type, isOpenModal),
        async () => await NotificationsApi.list(search, page, type, isOpenModal),
        {
            keepPreviousData: true
        }
    );
};

export const useGetNotificationTypes = () => {
    return useQuery(getNotificationKeys.notificationTypes(), () => NotificationsApi.getNotificationTypes());
};
