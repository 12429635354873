import { useQuery } from 'react-query';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';

export const getServicePartnerCategoriesQueryKey = 'getServicePartnerCategories';

const getServicePartnerCategories = async (id: number) => {
    const r = await ServicePartnersApi.listServicePartnerCategories(id);
    return r;
};

const useGetServicePartnerCategories = (id: string) => {
    return useQuery([getServicePartnerCategoriesQueryKey, id], () => getServicePartnerCategories(+id));
};

export default useGetServicePartnerCategories;
