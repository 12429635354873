import styled from '@emotion/styled';

export default styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    ['@media screen and (max-width: 700px)']: {
        flexWrap: 'wrap'
    }
});
