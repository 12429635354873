import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';
import { AddProperty } from '../../schemas';

const useEditProperty = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({
            projectId,
            property,
            propertyId,
        }: {
            property: AddProperty;
            projectId: number;
            propertyId: number;
        }) => {
            return trpc.BindersApi.editProperty.query({ property, projectId, propertyId });
        },
        {
            onSettled: (data) => {
                if (data?.project_id) queryClient.invalidateQueries(getPropertiesKeys.detail(data.id));
            },
        },
    );
};

export default useEditProperty;
