import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import InventoryApi from '@app/api/public/InventoryApi';
import { Project } from '@app/api/models/Projects';

const getPropertiesByProject = async (id: Project['id']) => {
    const r = await InventoryApi.listProperties(id);
    return r;
};

const useGetPropertiesByProject = () => {
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery(['getPropertiesByProject', projectId], () => getPropertiesByProject(+projectId));
};

export default useGetPropertiesByProject;
