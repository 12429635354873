import { DocumentDetail } from '@app/api/models/Documents';
import AddonsApi, { SupplierFilter } from '@app/api/public/AddonsApi';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

export type ListProps = {
    property_ids: number[];
    filter?: SupplierFilter;
    fields?: string[];
};

async function GetSignedUrlsOriginals(documents: DocumentDetail[]) {
    if (documents && documents.length > 0) {
        for (let i = 0; i < documents.length; i++) {
            if (documents[i].document_data && documents[i].document_data.original) {
                documents[i].signed_url =
                    (await DocumentsApi.getSignedUrl(documents[i].document_data.original.id)) || '';
            }
        }
        return documents;
    }
}

const getListData = async (data: ListProps) => {
    const list = await AddonsApi.rptProducts(data.property_ids, data.filter);
    const signedList = await Promise.all(
        list.map(async AddonProduct => {
            AddonProduct.documents.forEach(
                doc => (doc.document_data.original.id = `200x200/${doc.document_data.original.id}`)
            );
            let url = '';
            if (AddonProduct.documents && AddonProduct.documents.length > 0) {
                const signedDocumentDetails = await GetSignedUrlsOriginals([AddonProduct.documents[0]]);
                url = (signedDocumentDetails && signedDocumentDetails[0].signed_url) || '';
            }
            return { ...AddonProduct, signedUrl: url };
        })
    );
    return signedList;
};

const useGetListData = (data: ListProps) => {
    return useQuery(['listSupplierInfo', { data }], () => getListData(data));
};
export type GetListData = Exclude<ReturnType<typeof useGetListData>['data'], undefined>;

export default useGetListData;
