import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import PackageProductsComponent from '../../components/Products/PackageProductComponent';

const PackageProductsPage = () => {
    const { productId, propertyId } = useParams<{ id: string; productId: string; propertyId: string }>();

    const isUnderSpaces = location.pathname.includes('/spaces/');

    if (isUnderSpaces && productId && propertyId) {
        return <PackageProductsComponent productId={productId} propertyId={propertyId} />;
    }
    return productId && propertyId ? <PackageProductsComponent productId={productId} propertyId={propertyId} /> : null;
};

export default PackageProductsPage;
