import { useLocation, useHistory } from 'react-router';
import { useMemo } from 'react';

export default function useQueryParams() {
    const { search } = useLocation();
    const history = useHistory();

    const paramsMemo = useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);

    const getQueryString = (type: string, defaultValue: string) => {
        const query = paramsMemo.get(type);
        if (query) {
            return query;
        }
        return query ?? defaultValue;
    };

    const updateQueryString = (type: string, newValue: string) => {
        paramsMemo.set(type, newValue);

        history.push({ search: paramsMemo.toString() });
    };
    return {
        getQueryString,
        updateQueryString,
        search
    };
}
