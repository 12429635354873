import styled from '@emotion/styled';
import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../components/Modal/Modal';
import { style } from '../../../../components/Shared/Style';
import { L } from '../../../../lib/i18n';

const MailFrame = styled.iframe({
    width: '100%',
    height: '100%',
    padding: '1px',
});

export function PreviewMailTemplate({ forwardRef, isActive, setIsActive, data }) {
    return (
        <Modal width={'60%'} height={'80%'} forwardRef={forwardRef} isActive={isActive}>
            <MailFrame frameBorder="0" srcDoc={data || ''} />
            <Button style={{ marginRight: '0px', marginTop: style.margin.m }} onClick={() => setIsActive(false)}>
                {L('cancel')}
            </Button>
        </Modal>
    );
}
