import React from 'react';
import styled from '@emotion/styled';
import { ProductListSpaces } from './ProductListSpaces';
import { ProductListConfirmPage } from './ProductListConfirmed';
import { ProductListingV2 } from '@app/api/models/Addons';

const Addon = styled.div({
    display: 'block',
    width: '100%',
    breakInside: 'avoid',
    pageBreakInside: 'avoid'
});

interface Props {
    r: ProductListingV2[];
}

const sum = (parent_id: number, productListing: ProductListingV2[]) => {
    if (!productListing) return;
    if (parent_id === 0) {
        const totalPrice = productListing
            .filter(product => product.selected)
            .reduce((sum, product) => sum + +(product.addon_selected_info.price || 0), 0);
        return { sum: totalPrice };
    } else {
        const totalPrice = productListing
            .filter(product => product.parent_ids.includes(parent_id))
            .filter(product => product.selected)
            .reduce((sum, product) => sum + +(product.addon_selected_info.price || 0), 0);
        return {
            sum: totalPrice
        };
    }
};

export const ProductList = (props: Props) => {
    const list = props.r;
    const spaces = list.filter(spaces => spaces.parent_id === null);

    const rs = spaces.map((space, index) => {
        return (
            <Addon id={space.name} key={space.id}>
                <ProductListSpaces name={space.name} sortOrder={`${index + 1}`} price={sum(space.id, list)?.sum || 0} />
                <ProductListConfirmPage addonList={list} space_id={space.id} />
            </Addon>
        );
    });

    return <div>{rs}</div>;
};
