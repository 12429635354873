import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';
import styled from '@emotion/styled';
import * as EmailValidator from 'email-validator';
import Input from '../../../../../components/Forms/Input';
import Label from '../../../../../components/Forms/Label';
import useAddMember from '../../hooks/useInviteMember';
import toast from 'react-hot-toast';
import { Property } from '@app/api/models/Properties';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

type Props = {
    forwardRef: any;
    isActive: boolean;
    setIsActive: any;
    propertyId: Property['id'];
};

export function AddonAddMember({ forwardRef, isActive, setIsActive, propertyId }: Props) {
    const [mail, setMail] = React.useState('');
    const [name, setName] = React.useState('');

    const [inviteMailError, setInviteMailError] = React.useState('');
    const { mutate: addMember } = useAddMember();

    const onClick = () => {
        if (!EmailValidator.validate(mail)) return;

        addMember(
            { propertyId: propertyId, email: mail, name: name },
            {
                onSuccess: () => {
                    toast(`✅ ${mail} ${L('invited_success')}`);
                    setIsActive(false);
                },
                onError: () => {
                    toast(`❌ ${L('error_invite')} ${mail}`);
                    setIsActive(false);
                },
            },
        );
    };

    const onChange = (e) => {
        setMail(e.target.value);
        if (!EmailValidator.validate(e.target.value)) {
            setInviteMailError('reset_not_mail');
        } else setInviteMailError('');
    };

    return (
        <Modal width={'440px'} height={'350px'} forwardRef={forwardRef} isActive={isActive}>
            <SubTitle>{L('addons_add_member')} </SubTitle>
            <Label title={L('name')} error={L(inviteMailError)}>
                <Input name="name" onChange={(e) => setName(e.target.value)} />
            </Label>
            <Label title={L('email')} error={L(inviteMailError)}>
                <Input name="email" onChange={onChange} />
            </Label>
            <Button
                onClick={onClick}
                style={{
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                    marginTop: style.margin.l,
                    marginRight: '0px',
                }}
            >
                {L('add_member')}
            </Button>
        </Modal>
    );
}
