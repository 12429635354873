import { z } from 'zod';

export const AddProductOnPropertyTemplateSchema = z.object({
    property_id: z.number({ required_error: 'error_required' }),
    name: z.string().min(1, { message: 'error_required' }),
    brand_id: z.number().nullish(),
    parent_id: z.number({ required_error: 'error_required' }),
    package_type: z.string().optional(),
    supplier_id: z.number().nullish(),
    profession_id: z.number().nullish(),
    attr: z.object({
        product: z.string().min(1, { message: 'error_required' }),
        description: z.string().optional(),
        url: z.string().optional(),
        color: z.string().optional(),
        model: z.string().optional(),
        warranty: z.boolean().optional(),
        is_private: z.boolean().optional(),
        manufacturer: z.string().optional(),
        purchase_date: z.string().optional(),
        serial_number: z.string().optional(),
        purchase_price: z.string().optional(),
        purchase_store: z.string().optional(),
        service_advice: z.string().optional(),
        warranty_end_date: z.string().optional(),
        extra_information: z.string().optional(),
        choice: z.string().optional(),
        info: z.string().optional(),
        belonging: z.string().optional(),
        space: z.string().optional(),
        article_no: z.string().optional(),
        rsk_no: z.string().optional(),
        e_no: z.string().optional(),
        gtin_ean: z.string().optional(),
        brand: z.string().optional(),
        pnc_number: z.string().optional(),
        order_number: z.string().optional(),
        order_no: z.string().optional(),
    }),
    entity_type: z.number().optional(),
    entity_subtype: z.number({ required_error: 'error_required' }),
});

export type AddProductOnPropertyTemplate = z.infer<typeof AddProductOnPropertyTemplateSchema>;
