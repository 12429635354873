import { useQuery } from 'react-query';
import { trpc } from '../../../lib/trpc';
import { ProductLibraryFilter } from '@app/trpc-api/models/ProductLibrary';

export const getLibraryKeys = {
    all: ['binders-library'] as const,
    lists: () => [...getLibraryKeys.all, 'list'] as const,
    list: (
        page?: number,
        rowCount?: number,
        filter?: ProductLibraryFilter,
        searchQuery?: string,
        sortType?: { id: string; desc: true | false }[],
    ) => [...getLibraryKeys.lists(), { page, rowCount, filter, searchQuery, sortType }] as const,
};

export const getLibraryFilterKeys = {
    all: ['library-filter'] as const,
    list: (accountIds: string[]) => [...getLibraryKeys.all, 'list', { accountIds }] as const,
};

export function useGetLibrary(
    page: number,
    rowCount: number,
    filter: ProductLibraryFilter,
    searchQuery: string,
    sortType: { id: string; desc: true | false }[],
) {
    return useQuery(
        getLibraryKeys.list(page, rowCount, filter, searchQuery, sortType),
        () =>
            trpc.ProductLibraryApi.listProducts.query({
                page,
                rowCount,
                filter,
                searchQuery,
                sortType,
                accountIds: filter.accountIds.map((id) => +id),
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetLibraries() {
    return useQuery(getLibraryKeys.list(), () => trpc.ProductLibraryApi.getLibraries.query(), {
        keepPreviousData: true,
    });
}

export function useGetLibraryFilter(accountIds: string[], resetFilters?: () => void) {
    return useQuery(
        getLibraryFilterKeys.list(accountIds),
        () => trpc.ProductLibraryApi.getLibraryFilter.query({ accountIds: accountIds.map((id) => +id) }),
        {
            keepPreviousData: true,
            onError: () => {
                if (resetFilters) resetFilters();
            },
        },
    );
}
