import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import SpacesList from '../../components/Spaces/SpacesList';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import TopAreaContainer from '../../components/TopAreaContainer';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';

const PropertySpaceComponent = ({ propertyId }: { propertyId: string }) => {
    const navigate = useNavigate();
    const [numberOfSpaces, setNumberOfSpaces] = useState<number | null>(null);
    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../..'}>{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to=".">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="../products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="../members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="../contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="../todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="../documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>
                            {L('spaces')} {numberOfSpaces ? `(${numberOfSpaces})` : ''}
                        </CardTitle>
                        <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                            {L('new_space')}
                        </Button>
                    </TopAreaContainer>
                }
                mainArea={
                    <SpacesList handleSetNumberOfSpaces={(val) => setNumberOfSpaces(val)} propertyId={+propertyId} />
                }
            />
        </>
    );
};

const PropertySpacesPage = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return propertyId ? <PropertySpaceComponent propertyId={propertyId} /> : null;
};

export default PropertySpacesPage;
