import React, { useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom-v5-compat';
import { useQueryClient } from 'react-query';
import {
    getPropertiesKeys,
    useGetPropertyDocumentTypes,
    useGetPropertyDocuments,
} from '../../hooks/propertyHooks/propertyQueries';
import { FieldValues, UseFormReset } from 'react-hook-form';
import { AddPropertyDocument, DocumentSubtypeSchema, DocumentTypeSchema } from '../../schemas';
import styled from '@emotion/styled';
import { useGetDocumentSubtypes } from '../../hooks/documentTypeQueries';
import * as Dialog from '@radix-ui/react-dialog';
import { Breadcrumbs, Button, Dropzone, onUploadError, onUploadSuccess } from '@ourliving/ourliving-ui';
import useUpdatePropertyDocName from '../../hooks/useUpdatePropertyDocName';
import useUpdatePropertyDocType from '../../hooks/useUpdatePropertyDocType';
import PropertyDocumentForm from '../../components/PropertyDocumentForm';
import useUpdatePropertyDocSort from '../../hooks/useUpdatePropertyDocSort';
import useDragEndDnd from '../../../../hooks/useDragEndDndNew';
import useUploadPropertyDocuments from '../../hooks/propertyHooks/useUploadPropertyDocuments';
import useUploadPropertyDocument from '../../hooks/propertyHooks/useUploadPropertyDocument';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';
import PropertyDocumentsColumns, {
    PropertyDocumentsTableMeta as TableMeta,
} from '../../components/Properties/PropertyDocumentsColumns';
import { style } from '../../../../components/Shared/Style';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import GenericTableDnD from '../../components/GenericTableDnD';
import useDeletePropertyDocuments from '../../hooks/useDeletePropertyDocuments';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const AddPropertyDocumentsComponent = ({ propertyId }: { propertyId: string }) => {
    const { mutate: updateSort, isLoading: isUpdatingSort } = useUpdatePropertyDocSort();
    const [open, setOpen] = useState(false);
    const [openUploadMany, setOpenUploadMany] = useState(false);
    const { data: documentTypes } = useGetPropertyDocumentTypes();
    const { data: documentSubtypes } = useGetDocumentSubtypes();
    const { data: documents, status } = useGetPropertyDocuments(+propertyId);
    const { mutate: uploadDocument } = useUploadPropertyDocument();
    const { mutate: uploadMultiplePropertyDocs } = useUploadPropertyDocuments();
    const { mutate: deleteDocuments } = useDeletePropertyDocuments();
    const { mutate: renameDocument } = useUpdatePropertyDocName();
    const { mutate: updateDocType } = useUpdatePropertyDocType();

    const queryClient = useQueryClient();
    const handleSaveMultipleDocs = (files: File[]) => {
        uploadMultiplePropertyDocs(
            { files, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('documents_uploaded'));
                    setOpen(false);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                },
            },
        );
    };

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return;
        handleSaveMultipleDocs([param.file]);
        setOpenUploadMany(false);
    };

    const handleRemoveDocument = (documentId: number) => {
        deleteDocuments(
            { documentIds: [documentId], propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRemoveDocuments = (documentIds: string[]) => {
        deleteDocuments(
            { documentIds: documentIds.map((id) => +id), propertyId: +propertyId },
            {
                onSuccess: (_err, vars) => {
                    if (vars.documentIds.length === 1) toast.success(`${L('document_removed')}`);
                    if (vars.documentIds.length > 1) toast.success(`${L('documents_removed')}`);
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                    tableData.resetRowSelection();
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRenameDocument = (documentId: number, name: string) => {
        renameDocument(
            { docId: documentId, name, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(`${L('renamed_document')}`);
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleUpdateDocType = ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => {
        updateDocType(
            { docId: documentId, document_type, document_subtype, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('updated_document_types'));
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDocSaveMutation = <T extends FieldValues>({
        name,
        document_subtype,
        document_type,
        file,
        reset,
    }: AddPropertyDocument & { reset: UseFormReset<T> }) => {
        const parsedDocumentType = DocumentTypeSchema.parse(document_type);
        const parsedDocumentSubtype = DocumentSubtypeSchema.optional().parse(document_subtype);
        uploadDocument(
            {
                file,
                name,
                propertyId: +propertyId,
                documentType: parsedDocumentType,
                documentSubtype: parsedDocumentSubtype,
            },
            {
                onSuccess: () => {
                    toast.success(L('document_uploaded'));
                    reset();
                    setOpen(false);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getPropertiesKeys.documentsByPropertyId(+propertyId));
                },
            },
        );
    };

    const items = documents?.map((item) => item.id) ?? [];

    const tableData = useReactTable({
        data: documents ?? [],
        columns: PropertyDocumentsColumns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        getRowId: (originalRow) => originalRow.id.toString(),
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        enableRowSelection: true,
        meta: {
            removeDocument: handleRemoveDocument,
            renameDocument: handleRenameDocument,
            updateDocType: handleUpdateDocType,
        } satisfies TableMeta,
    });

    const handleDragEnd = useDragEndDnd({
        queryKey: getPropertiesKeys.documentsByPropertyId(+propertyId),
        updateSortOrder: updateSort,
        documentableId: +propertyId,
        reverseArray: true,
        isFiltering: tableData.getState().sorting.length > 0,
    });

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Dialog.Root open={!!open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <div style={{ width: '400px' }}>
                                {documentTypes && documentSubtypes && (
                                    <PropertyDocumentForm
                                        documentSubtypes={documentSubtypes}
                                        documentTypes={documentTypes}
                                        handleDocSaveMutation={handleDocSaveMutation}
                                    />
                                )}
                            </div>
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <Dialog.Root open={!!openUploadMany} onOpenChange={setOpenUploadMany}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <div
                                style={{
                                    backgroundColor: style.colors.white,
                                    borderRadius: style.roundCornerSize.small,
                                    padding: '2rem',
                                    width: '400px',
                                }}
                            >
                                <Dropzone onUpload={onUpload} multiple variant="All" />
                            </div>
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="../products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="../members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="../contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="../todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>
                            {L('documents')} {documents ? `(${documents?.length})` : ''}
                        </CardTitle>
                        <div style={{ display: 'flex', gap: '1rem' }}>
                            <Button onClick={() => setOpenUploadMany(true)}>{L('upload_many')}</Button>
                            <Button onClick={() => setOpen(true)}>{L('new_document')}</Button>
                            <ConfirmationButton
                                disabled={!!(Object.keys(tableData.getState().rowSelection).length < 1)}
                                props={{
                                    onConfirm: () => {
                                        handleRemoveDocuments(Object.keys(tableData.getState().rowSelection));
                                    },
                                    idleVariant: 'secondary',
                                    confirmVariant: 'danger',
                                }}
                            >
                                {L('remove_selected_documents')} :{' '}
                                {Object.keys(tableData.getState().rowSelection).length}
                            </ConfirmationButton>
                        </div>
                    </div>
                }
                mainArea={
                    <>
                        {documents && (
                            <GenericTableDnD
                                items={items}
                                table={tableData}
                                handleDragEnd={handleDragEnd}
                                isUpdatingSort={isUpdatingSort}
                            />
                        )}
                    </>
                }
            />
        </>
    );
};

export default function AddPropertyDocumentsPage() {
    const { propertyId } = useParams<{ propertyId: string }>();

    return propertyId ? <AddPropertyDocumentsComponent propertyId={propertyId} /> : null;
}
