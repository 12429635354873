import React, { useState } from 'react';
import styled from '@emotion/styled';
import useGetProductsByProperty from '../hooks/useGetProductsByProperty';
import ProductCard from './ProductCard';
import { L } from '../../../lib/i18n';
import Filter from './Filter';
import useGetFilteredProducts from '../hooks/useGetFilteredProducts';
import BackArrow from '../../../public/assets/backarrow.svg';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import useGetProjects from '../hooks/useGetProjects';
import useGetPropertiesByProject from '../hooks/useGetPropertiesByProject';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import useGetPropertyById from '../hooks/useGetPropertyById';
import AddProductModal from './AddProductModal';

const FirstLine = styled.div({
    borderTop: '1px solid #979797'
});

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
    marginLeft: '12px',
    ['@media screen and (min-width: 1024px)']: {
        marginLeft: '0px'
    }
});

const Top = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const StyledBackArrow = styled(BackArrow)({
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const FilterText = styled.span({
    fontStyle: 'italic',
    fontSize: '12px',
    width: '100%',
    textAlign: 'end',
    margin: '10px 0px'
});

const BreadCrumbContainer = styled.div({
    display: 'flex',
    marginLeft: '12px',
    marginTop: '12px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const Slash = styled.span({
    textDecoration: 'none',
    fontSize: '16px',
    color: '#9DA3B4',
    fontWeight: 400,
    margin: '0px 5px'
});

const FunctionsWrapper = styled.h1({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.5rem',
    marginRight: '0.5rem'
});

const Products = () => {
    const { data: products } = useGetProductsByProperty();
    const { data: projects } = useGetProjects();
    const { data: properties } = useGetPropertiesByProject();
    const { data: spaces } = useGetPropertyById();
    const params = useParams<{ projectId: string; propertyId: string; spaceId: string }>();

    const projectById = projects?.find(project => project.id === params.projectId);
    const propertyById = properties?.find(project => project.id === params.propertyId);
    const spaceById = spaces?.find(project => project.id === params.spaceId);

    const url = useLocation();
    const history = useHistory();
    const prevUrl = url.pathname.replace(`/space/${params.spaceId}`, '');

    const { data: filterOptions } = useGetFilteredProducts();

    const [showOnlyUncompleted, setShowOnlyUncompleted] = useState(false);

    const checkedProducts = filterOptions?.filter(product => product.checked === true);

    const filteredProducts = products?.filter(
        product =>
            product.entity_subtype ===
            checkedProducts?.find(checkedProduct => checkedProduct.name === product.entity_subtype)?.name
    );

    if (!filteredProducts) return null;

    const unCompletedProducts = filteredProducts?.filter(
        product =>
            (product?.attr.e_no === null || product?.attr.e_no?.length <= 0) && product?.attr.serial_number?.length <= 0
    );

    return (
        <>
            <StyledBackArrow onClick={() => history.push(prevUrl)} />
            <BreadCrumbContainer>
                <Breadcrumb breadcrumbs={[{ name: projectById?.name, to: '/inventory' }]} />
                <Slash>/</Slash>
                <Breadcrumb breadcrumbs={[{ name: propertyById?.name, to: `/inventory/project/${projectById?.id}` }]} />
                <Slash>/</Slash>
                <Breadcrumb breadcrumbs={[{ name: spaceById?.name, to: prevUrl }]} />
            </BreadCrumbContainer>
            <Top>
                <Header>{L('Inventarier')} </Header>
                {showOnlyUncompleted && <FilterText>Visa endast ej inventerade</FilterText>}
                <FunctionsWrapper>
                    <Filter setIsOtherFilterOpen={setShowOnlyUncompleted} isOtherFilterOpen={showOnlyUncompleted} />
                    <AddProductModal />
                </FunctionsWrapper>
            </Top>

            {filteredProducts?.length <= 0 && 'Här var det tomt.'}

            {showOnlyUncompleted ? (
                <>
                    {unCompletedProducts?.length > 0 && <FirstLine />}
                    {unCompletedProducts?.map(product => (
                        <div key={product.id}>
                            <ProductCard product={product} />
                        </div>
                    ))}
                </>
            ) : (
                <>
                    {filteredProducts?.length > 0 && <FirstLine />}
                    {filteredProducts?.map(product => (
                        <div key={product.id}>
                            <ProductCard product={product} />
                        </div>
                    ))}
                </>
            )}
        </>
    );
};

export default Products;
