import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { formatCurrency } from '@app/shared/lib/formatting';

const OrderInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    lineHeight: style.lineHeight.normal,
    padding: style.margin.s,
    paddingLeft: style.margin.l,
    paddingRight: style.margin.l
});

const Title = styled.div({
    fontSize: style.fontSize.epsilon,
    color: style.colors.black90
});

export const PropertyOrderInfo = ({ date, price }) => {
    return (
        <OrderInfo>
            <Title>Orderdatum: {date}</Title>
            <Title>Ordervärde: {formatCurrency(price)} inkl. moms</Title>
        </OrderInfo>
    );
};
