import styled from '@emotion/styled';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Products from './Components/Products';
import Projects from './Components/Projects';
import Properties from './Components/Properties';
import Spaces from './Components/Spaces';

const MobileWrapper = styled.div({
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});
const DesktopWrapper = styled.div({
    display: 'none',
    ['@media screen and (min-width: 1024px)']: {
        display: 'grid',
        gridTemplateColumns: '25% 25% 25% 25%',
        width: '97%',
        margin: '0px auto'
    }
});
const Wrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',

    ['@media screen and (min-width: 1024px)']: {
        padding: '10px',
        margin: '0.3em'
    }
});

export function InventoryPage() {
    return (
        <Switch>
            <Route path="/inventory">
                <MobileWrapper>
                    <Route exact path="/inventory">
                        <Projects />
                    </Route>
                    <Route exact path="/inventory/project/:projectId">
                        <Properties />
                    </Route>
                    <Route exact path="/inventory/project/:projectId/property/:propertyId">
                        <Spaces />
                    </Route>
                    <Route exact path="/inventory/project/:projectId/property/:propertyId/space/:spaceId">
                        <Products />
                    </Route>
                </MobileWrapper>
                <DesktopWrapper>
                    <Wrapper>
                        <Projects />
                    </Wrapper>
                    <Route path="/inventory/project/:projectId">
                        <Wrapper>
                            <Properties />
                        </Wrapper>
                    </Route>
                    <Route path="/inventory/project/:projectId/property/:propertyId">
                        <Wrapper>
                            <Spaces />
                        </Wrapper>
                    </Route>
                    <Route path="/inventory/project/:projectId/property/:propertyId/space/:spaceId">
                        <Wrapper>
                            <Products />
                        </Wrapper>
                    </Route>
                </DesktopWrapper>
            </Route>
        </Switch>
    );
}
