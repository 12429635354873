import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';

const Info = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
    textAlign: 'left',
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal,
    padding: style.margin.s,
    flexWrap: 'wrap',
    paddingLeft: style.margin.l,
    paddingRight: style.margin.l
});

const Text = styled.div({
    overflowWrap: 'break-word'
});

const User = styled.div({
    width: 'calc(100%/3)',
    paddingBottom: style.margin.s
});

const Title = styled.div({
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold
});

export const PropertyUsers = ({ title, users }) => {
    const r = users?.map(user => (
        <User key={user.user_id}>
            {title && <Title>{title}</Title>}
            {user.name && <Text>{user.name}</Text>}
            {user?.address?.addressline1 && <Text>{user?.address?.addressline1}</Text>}
            {user?.address?.addressline2 && <Text>{user?.address?.addressline2}</Text>}
            {(user?.address?.postal_code || user?.address?.city) && (
                <Text>{`${user?.address?.postal_code} ${user?.address?.city}`}</Text>
            )}
            {user?.address?.country && <Text>{user?.address?.country}</Text>}
            {user?.phone_number && <Text>{user?.phone_number}</Text>}
            {user?.email && <Text>{user?.email}</Text>}
        </User>
    ));
    return <Info>{r}</Info>;
};
