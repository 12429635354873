import { SortingState } from '@tanstack/react-table';
import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const getProjectTemplatesKeys = {
    all: [{ scope: 'templates-projects' }] as const,
    lists: () => [{ ...getProjectTemplatesKeys.all[0], entity: 'list' }] as const,
    listIdName: () => [{ ...getProjectTemplatesKeys.lists()[0], listIdName: 'listIdName' }] as const,
    listInfinite: (params: { sorting: SortingState; search: string }) =>
        [{ ...getProjectTemplatesKeys.lists()[0], params }] as const,
    details: () => [{ ...getProjectTemplatesKeys.all[0], entity: 'detail' }] as const,
    detail: (templateId?: number) => [{ ...getProjectTemplatesKeys.details()[0], templateId }] as const,
    images: () => [{ ...getProjectTemplatesKeys.all[0], entity: 'images' }] as const,
    imagesByTemplateId: (templateId: number) => [{ ...getProjectTemplatesKeys.images()[0], templateId }] as const,
};

export function useGetInfiniteProjectTemplates({ sorting, search }: { sorting: SortingState; search: string }) {
    return useInfiniteQuery(
        getProjectTemplatesKeys.listInfinite({ sorting, search }),
        async ({ pageParam = 1 }) => trpc.TemplatesApi.getProjectTemplates.query({ page: pageParam, sorting, search }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useGetProjectTemplateById(templateId?: number) {
    return useQuery(
        getProjectTemplatesKeys.detail(templateId),
        async ({ queryKey }) => {
            const { templateId } = queryKey[0];
            if (!templateId) return;
            return trpc.TemplatesApi.getProjectTemplateById.query(templateId);
        },
        {
            keepPreviousData: true,
            enabled: !!templateId,
        },
    );
}

export function useGetProjectTemplateImages(templateId: number) {
    return useQuery({
        queryKey: getProjectTemplatesKeys.imagesByTemplateId(templateId),
        queryFn: async ({ queryKey }) =>
            trpc.TemplatesApi.getTemplateImages.query({
                projectId: queryKey[0].templateId,
            }),

        keepPreviousData: true,
    });
}

export function useGetProjectTemplatesIdName() {
    return useQuery({
        queryKey: getProjectTemplatesKeys.listIdName(),
        queryFn: async () => trpc.TemplatesApi.listProjectTemplatesIdName.query(),
        keepPreviousData: true,
    });
}
