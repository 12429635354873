import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import { useGetProperties } from '../../hooks/propertyHooks/propertyQueries';
import { Breadcrumbs, Button, DropdownButton } from '@ourliving/ourliving-ui';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { margin } from '../../../../components/Shared/Style/margin';
import CardTitle from '../../../../components/typography/CardTitle';
import TopAreaContainer from '../../components/TopAreaContainer';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import GenericTable from '../../components/GenericTable';
import { Property } from '@app/trpc-api/models/Binders';
import useDeleteProperties from '../../hooks/propertyHooks/useDeleteProperties';
import toast from 'react-hot-toast';
import propertyColumns, { PropertyColumnsTableMeta } from './PropertyColumns';
import AddPropertyFromTemplate from '../../components/Properties/AddPropertyFromTemplate';
import usePublishProperties from '../../hooks/propertyHooks/usePublishProperties';
import Dialog from '../../../../components/Dialog/Dialog';
import { useLocalStorage } from 'react-use';
import AddPropertyFromOrgTemplate from '../../components/Properties/AddPropertyFromOrgTemplate';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import H1 from '../../../../components/typography/H1';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import { Loading } from '../../../../components/Loading/Loading';
import CreateTemplateFromProperty from '../../components/Properties/CreateTemplateFromProperty';

function Page({ projectId }: { projectId: string }) {
    const { data, status } = useGetProperties({ projectId: Number(projectId) });
    const { mutate: deleteProperties } = useDeleteProperties();
    const { mutate: publishProperties } = usePublishProperties();
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
    const [selectedPropertyForTemplate, setSelectedPropertyForTemplate] = useState<number | null>(null);

    const table = useReactTable({
        data: data ?? [],
        columns: propertyColumns(),
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
        meta: {
            handleTemplateBtnClick: (id) => {
                setSelectedPropertyForTemplate(id);
                setOpenCreateTemplate(true);
            },
        } satisfies PropertyColumnsTableMeta,
    });

    const deleteProperty = (properties: Property[]) => {
        deleteProperties(
            { properties, projectId: +projectId },
            {
                onSuccess: () => {
                    toast.success(`${L('properties_delete_successfully')}`);
                    table.resetRowSelection();
                },
                onError: () => {
                    toast.error(`${L('properties_delete_failed')}`);
                    table.resetRowSelection();
                },
            },
        );
    };

    const publishProperty = (properties: Property[]) => {
        publishProperties(
            {
                properties,
                projectId: +projectId,
            },
            {
                onSuccess: () => {
                    toast.success(`${L('properties_publish_successfully')}`);
                    table.resetRowSelection();
                },
                onError: () => {
                    toast.error(`${L('properties_publish_failed')}`);
                    table.resetRowSelection();
                },
            },
        );
    };

    const selectedData = table.getSelectedRowModel().flatRows.map((data) => data.original);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [openOrgTemplate, setOpenOrgTemplate] = useState(false);

    const [localNewPropertyButtonState, setNewPropertyButtonState] = useLocalStorage<
        'new_property_from_template' | 'new_property_from_org_template' | 'import_properties' | 'new_property'
    >('bindersPropertiesPage', 'new_property');
    const newPropertyButtonState = localNewPropertyButtonState || 'new_property';

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const project = useGetProjectName(projectId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Dialog open={openTemplate} onOpenChange={setOpenTemplate}>
                <AddPropertyFromTemplate handleCloseModal={() => setOpenTemplate(false)} projectId={+projectId} />
            </Dialog>
            <Dialog open={openCreateTemplate} onOpenChange={setOpenCreateTemplate}>
                <CreateTemplateFromProperty
                    handleCloseModal={() => {
                        setOpenCreateTemplate(false);
                        setSelectedPropertyForTemplate(null);
                    }}
                    projectId={+projectId}
                    propertyId={selectedPropertyForTemplate}
                />
            </Dialog>
            <Dialog open={openOrgTemplate} onOpenChange={setOpenOrgTemplate}>
                <AddPropertyFromOrgTemplate handleCloseModal={() => setOpenOrgTemplate(false)} projectId={+projectId} />
            </Dialog>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{project ?? L('project_literally')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project ?? L('project_literally')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to=".">
                        {L('properties')}
                    </BigNavItem>
                    <BigNavItem end to="../templates">
                        {L('property_templates')}
                    </BigNavItem>
                    <BigNavItem end to="../admins">
                        {L('admins')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_project')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>
                            {L('properties')} {data ? `(${data.length})` : ''}
                        </CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                            <>
                                <DropdownButton
                                    options={[
                                        {
                                            id: 'new_property_from_template',
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('new_property_from_template')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={() => {
                                                        setOpenTemplate(true);
                                                    }}
                                                >
                                                    {L('new_property_from_template')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                        {
                                            id: 'new_property_from_org_template',
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('new_property_from_org_template')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            button: (
                                                <DropdownButton.Button
                                                    onClick={() => {
                                                        setOpenOrgTemplate(true);
                                                    }}
                                                >
                                                    {L('new_property_from_org_template')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                        {
                                            id: 'import_properties',
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('import_properties')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            button: (
                                                <DropdownButton.Button onClick={() => navigate(`import`)}>
                                                    {L('import_properties')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                        {
                                            id: 'new_property',
                                            dropdownItem: (
                                                <DropdownButton.DropdownItem>
                                                    {L('new_property')}
                                                </DropdownButton.DropdownItem>
                                            ),
                                            button: (
                                                <DropdownButton.Button onClick={() => navigate(`new`)}>
                                                    {L('new_property')}
                                                </DropdownButton.Button>
                                            ),
                                        },
                                    ]}
                                    optionId={newPropertyButtonState}
                                    setOptionId={(state) => {
                                        setNewPropertyButtonState(state);
                                    }}
                                />
                                <ConfirmationButton
                                    disabled={!Object.keys(rowSelection).length}
                                    props={{
                                        onConfirm: () => {
                                            deleteProperty(selectedData);
                                        },
                                        idleVariant: 'secondary',
                                        confirmVariant: 'danger',
                                    }}
                                >
                                    {L('remove_selected_properties')} : {Object.keys(rowSelection).length}
                                </ConfirmationButton>

                                <ConfirmationButton
                                    disabled={!Object.keys(rowSelection).length}
                                    props={{
                                        onConfirm: () => {
                                            publishProperty(selectedData);
                                        },
                                        idleVariant: 'secondary',
                                        confirmVariant: 'primary',
                                    }}
                                >
                                    {L('publish_selected_properties')} : {Object.keys(rowSelection).length}
                                </ConfirmationButton>
                            </>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <GenericTable table={table} />
                        {data?.length === 0 && data && (
                            <EmptyState>
                                <EmptyState.Title>{L('no_properties_empty_state')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_properties_description')}</p>
                                    <p>{L('click_button_below')}</p>
                                    <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                                        {L('new_property')}
                                    </Button>
                                </EmptyState.Description>
                            </EmptyState>
                        )}
                    </>
                }
            />
        </>
    );
}

export default function PropertiesPage() {
    const { id: projectId } = useParams<{ id: string }>();
    return projectId ? <Page projectId={projectId} /> : null;
}
