import React from 'react';
import styled from '@emotion/styled';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { colors } from '../../Shared/Style/colors';

export const StyledNavLink = styled(NavLink)(() => {
    return {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '19px',
        color: colors.tabInactive,

        boxSizing: 'border-box',
        display: 'inline-block',
        marginRight: '16px',
        paddingTop: '16px',
        textDecoration: 'none',
        paddingBottom: '8px',

        '&:hover': {
            color: colors.tabActive,
            cursor: 'pointer',
            textDecoration: 'none'
        },
        '&.active': {
            color: colors.tabActive,
            textDecoration: 'none',
            borderBottom: `1px solid ${colors.tabActive}`
        }
    };
});

const SmallTab: React.FC<NavLinkProps> = ({ children, ...rest }) => {
    return (
        <li>
            <StyledNavLink {...rest}>{children}</StyledNavLink>
        </li>
    );
};

export default SmallTab;
