import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';

const PropertiesQueryKeys = {
    all: ['getProperties'] as const,
    list: () => [...PropertiesQueryKeys.all, 'list'] as const,
    feature: (feature?: string) => [...PropertiesQueryKeys.list(), feature] as const,
    projects: () => [...PropertiesQueryKeys.all, 'project'] as const,
    project: (projectId?: string) => [...PropertiesQueryKeys.all, 'project', projectId] as const
};

const useGetProperties = (feature?: string) => {
    return useQuery(feature ? PropertiesQueryKeys.feature(feature) : PropertiesQueryKeys.list(), () =>
        ProjectsApi.list()
    );
};
const getProjectProperties = (projectId?: string) => {
    if (!projectId) return;
    return ProjectsApi.projectProperies(+projectId);
};

const useGetProjectProperties = (projectId?: string) => {
    return useQuery(PropertiesQueryKeys.project(projectId), () => getProjectProperties(projectId), {
        enabled: !!projectId
    });
};

const useGetAccountProperties = () => {
    return useQuery(PropertiesQueryKeys.projects(), () => ProjectsApi.projectProperies());
};
export { useGetProperties as default, useGetProjectProperties, useGetAccountProperties };
