import React, { useMemo } from 'react';
import { AddPropertyDocument, AddPropertyDocumentSchema } from '../../binders/schemas';
import { Controller, FieldValues, SubmitHandler, UseFormReset, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { L } from '../../../lib/i18n';
import ErrorAndInputWrapper, { InputWithError } from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import RadixSelect from '../../../components/RadixSelect/RadixSelect';
import { Button, Dropzone } from '@ourliving/ourliving-ui';
import AttachmentItem from '../../binders/components/AttachmentItem';
import TrashCan from '../../../components/Icon/themed/TrashCan';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui';

type FormType = Omit<AddPropertyDocument, 'file'> & { file: File | null };

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '1rem',
});

const DocumentForm = ({
    documentTypes,
    documentSubtypes,
    handleDocSaveMutation,
}: {
    documentTypes: {
        id: number;
        name: string;
    }[];
    documentSubtypes: {
        id: number;
        name: string;
    }[];
    handleDocSaveMutation: <T extends FieldValues>({
        name,
        document_subtype,
        document_type,
        file,
        reset,
    }: {
        name: string;
        document_type: number;
        file: File;
        document_subtype?: number | undefined;
    } & {
        reset: UseFormReset<T>;
    }) => void;
}) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
    } = useForm<FormType>({
        resolver: zodResolver(AddPropertyDocumentSchema),
        defaultValues: {
            document_type: 11,
            name: '',
            file: null,
        },
    });

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return toast.error(`${param.errorCode} ${L('an_error_occurred')}`);
        setValue('file', param.file);
    };

    const file = useWatch({ name: 'file', control });

    const selectedDocumentType = useWatch({ name: 'document_type', control });

    const documentTypeOptions = useMemo(() => {
        return documentTypes?.map((type) => ({ value: type.id, label: L(type.name) })) || [];
    }, [documentTypes]);

    const documentSubtypeOptions = useMemo(() => {
        if (selectedDocumentType === 5)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('plan'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        if (selectedDocumentType === 10)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('manual'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        return [];
    }, [documentSubtypes, documentTypes, selectedDocumentType]);

    const onSubmit: SubmitHandler<AddPropertyDocument> = (params) => {
        handleDocSaveMutation({ ...params, reset });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="file"
                render={({ field: { onChange } }) => (
                    <ErrorAndInputWrapper errorMsg={errors.file?.message ? L(errors.file?.message) : ''}>
                        <Dropzone onUpload={onUpload} variant="Document" />
                    </ErrorAndInputWrapper>
                )}
            />

            {file && (
                <div style={{ padding: '1rem 0 1rem, 0' }}>
                    <AttachmentItem fileType={file.type} name={file.name}>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                reset((fieldValues) => ({
                                    ...fieldValues,
                                    file: null,
                                }));
                            }}
                        >
                            <TrashCan />
                        </div>
                    </AttachmentItem>
                </div>
            )}

            <ErrorAndInputWrapper errorMsg={L(errors.name?.message ?? '')}>
                <Label title={L('name')} />
                <InputWithError isError={!!errors.name} type="text" {...register('name')} />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.document_type?.message ?? '')}>
                <Label title={L('category')} />
                <Controller
                    control={control}
                    name="document_type"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.document_type}
                            onValueChange={(val) => {
                                setValue('document_subtype', undefined);
                                onChange(+val);
                            }}
                            value={value}
                            options={documentTypeOptions}
                        />
                    )}
                />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.document_subtype?.message ?? '')}>
                <Label title={L('sub_category')} />
                <Controller
                    control={control}
                    name="document_subtype"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.document_subtype}
                            onValueChange={(val) => onChange(+val)}
                            value={value}
                            options={documentSubtypeOptions}
                            disabled={selectedDocumentType !== 5 && selectedDocumentType !== 10}
                        />
                    )}
                />
            </ErrorAndInputWrapper>
            <div style={{ marginTop: style.margin.m, marginLeft: 'auto' }}>
                <Button>{L('save')}</Button>
            </div>
        </Form>
    );
};

export default DocumentForm;
