import styled from '@emotion/styled';
import React, { FC } from 'react';

const StyledPageTitle = styled.h1({
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: '100%',
    margin: '0px 0px 44px 0px',
    textTransform: 'capitalize',
});
const PageTitle: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
    children,
    ...rest
}) => {
    return <StyledPageTitle {...rest}>{children}</StyledPageTitle>;
};

export default PageTitle;
