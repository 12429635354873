import styled from '@emotion/styled';
import React from 'react';
import { position } from 'styled-system';
import { Insets, Stylable } from './types';

const Div = styled.div<Insets & { position: any }>(({ top, right, bottom, left, position }) => ({
    top,
    right,
    bottom,
    left,
    position: position ? position : 'absolute',
    width: 32,
    height: 32,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    color: 'white',
    borderRadius: '50%',
    fontSize: '2.25rem',
    textAlign: 'center',
    pointerEvents: 'all',
    cursor: 'pointer',
    zIndex: 10
}));

const Inner = styled.span({
    lineHeight: 0,
    verticalAlign: 7
});

type Props = Insets & {
    onClose: () => void;
    position?: any;
    className?: string;
};

const Close: React.FC<Props & Stylable> = ({ className, position, onClose, ...pos }) => {
    return (
        <Div className={className} position={position} onClick={onClose} {...pos}>
            <Inner>&times;</Inner>
        </Div>
    );
};

export default Close;
