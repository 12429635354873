import { Dimension } from '@app/api/lib/resize';
import { DocumentDetail } from '@app/api/models/Documents';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

export const getSingedUrlQueryKey = 'getSignedUrl';
const getSignedUrl = async (document?: DocumentDetail['document_data'], dimension?: Dimension) => {
    if (!document) return;
    return await DocumentsApi.getSignedUrl(`${dimension ? dimension + '/' : ''}${document?.original?.id}`);
};
const useGetSignedUrl = (document?: DocumentDetail['document_data'], dimension?: Dimension) => {
    return useQuery(
        [[getSingedUrlQueryKey], `${dimension ? dimension + '/' : ''}${document?.original?.id}`],
        () => {
            return getSignedUrl(document, dimension);
        },
        {
            enabled: !!document
        }
    );
};

export default useGetSignedUrl;
