import React from 'react';
import styled from '@emotion/styled';
import { Link, useParams } from 'react-router-dom';
import { colors } from '../../../components/Shared/Style/colors';

const AddButton = styled(Link)({
    fontSize: '1.5rem',
    color: 'white',
    backgroundColor: colors.plusButton,
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    textDecoration: 'none'
});

const Plus = styled.span({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const AddItemButton = ({ ...rest }) => {
    const { projectId } = useParams<{ projectId: string }>();
    return (
        <AddButton to={`/maintenance/${projectId}/add`} {...rest}>
            <Plus>+</Plus>
        </AddButton>
    );
};

export default AddItemButton;
