import React from 'react';
import useGetSignedUrlById from '../../../lib/hooks/useGetSignedUrlById';

const TableImageWithFallback = ({ id }) => {
    const { data } = useGetSignedUrlById(id, '200x0');
    if (data) {
        return (
            <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={data}
                onError={(e) => {
                    // change to fallback if link is broken
                    e.currentTarget.onerror = null;
                    e.currentTarget.src =
                        'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving-icon.png';
                }}
            />
        );
    } else {
        return (
            <img
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                src={'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/ourliving-icon.png'}
            />
        );
    }
};

export default TableImageWithFallback;
