import * as React from 'react';
import { Property, SupplierFilter } from '@app/api/models/Addons';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { MultiSelect } from '../../../../../components/Dropdown/MultiSelect';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import DropdownFlexContainer from './DropdownFlexContainer';

const Container = styled.div({ paddingRight: '10px', width: '180px' });

interface Props {
    filter: { property?: number[] };
    setFilter: (filter: Partial<SupplierFilter>) => void;
    store: { properties: Property[]; propertyIds: number[]; loadAddonSuppliers(projectId?: string): Promise<void> };
}

export const FilterProperty = (props: Props) => {
    const name = 'property';

    const handleOnChange = value => {
        event?.preventDefault();
        props.setFilter({
            ...props.filter,
            [name]: [...new Set<number>(value?.flatMap(id => id.value))]
        });
    };

    const sortedProperties = props.store.properties
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

    const options = sortedProperties.slice().map(v => ({
        value: v.id,
        label: (
            <DropdownFlexContainer>
                <Checkbox onChange={() => handleOnChange} checked={props.filter[name]?.includes(v.id) || false} />
                <span>{v.name}</span>
            </DropdownFlexContainer>
        )
    }));

    const selectAll = [
        {
            value: props.store.properties?.map(property => property.id),
            label: 'Välj alla'
        },
        ...options
    ];

    return (
        <Container>
            <MultiSelect
                isMulti={true}
                isClearable
                value={props.filter[name]?.map(id => ({
                    value: id,
                    label: props.store.properties?.find(({ id: property_id }) => property_id === id)?.name
                }))}
                onChange={handleOnChange}
                name={name}
                label={L(name)}
                options={selectAll}
            />
        </Container>
    );
};
