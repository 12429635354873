import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { L } from '../../lib/i18n';

const Container = styled.div((props: { marginBottom?: string; marginRight?: string }) => ({
    color: style.colors.secondary,
    fontSize: style.fontSize.seta,
    marginBottom: props.marginBottom || style.margin.l,
    marginRight: style.margin.m,
    display: 'flex',
    alignItems: 'center'
}));

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase'
});

export const Filters = ({ showLabel = true, marginBottom = '', children, ...rest }) => {
    return (
        <Container marginBottom={marginBottom} {...rest}>
            <Label className="label">{showLabel && `${L('filter_by')}:`} </Label>
            {children}
        </Container>
    );
};
