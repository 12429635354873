import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import InventoryApi from '@app/api/public/InventoryApi';

export const productsQueryKey = 'getProductsByProperty';

const getProductsByProperty = async (id: string) => {
    const r = await InventoryApi.listProducts(id);
    return r;
};

const useGetProductsByProperty = (parentId?: string) => {
    const { spaceId } = useParams<{ spaceId: string }>();
    return useQuery([productsQueryKey, parentId || spaceId], () => getProductsByProperty(parentId || spaceId));
};

export default useGetProductsByProperty;
