import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import EditProductCopyFunction from '../components/EditProductCopyFunction';

export default function EditProductCopyFunctionPage() {
    const { productId, packageItemId } = useParams<{ productId: string; packageItemId?: string }>();

    if (packageItemId) return <EditProductCopyFunction productId={packageItemId} />;
    return productId ? <EditProductCopyFunction productId={productId} /> : null;
}
