import React from 'react';
import FileDocx from '../Icon/file-docx.svg';
import FileXlsx from '../Icon/file-xlsx.svg';
import FilePdf from '../Icon/file-pdf.svg';
import FileHtml from '../Icon/file-html.svg';
import FileTxt from '../Icon/file-txt.svg';
import FileSvg from '../Icon/file-svg.svg';
import FileImg from '../Icon/polaroid-photo.svg';
import File from '../Icon/file.svg';

const FileTypeIcon = ({ fileType, ...rest }: { fileType?: string } & React.SVGProps<SVGSVGElement>) => {
    switch (fileType) {
        case 'doc' || 'docx':
            return <FileDocx {...rest} />;
        case 'xls' || 'xlsx':
            return <FileXlsx {...rest} />;
        case 'pdf':
            return <FilePdf {...rest} />;
        case 'htm' || 'html':
            return <FileHtml {...rest} />;
        case 'txt':
            return <FileTxt {...rest} />;
        case 'svg':
            return <FileSvg {...rest} />;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'PNG':
            return <FileImg {...rest} />;
        case 'webp':
            return <FileImg {...rest} />;
        default:
            return <File {...rest} />;
    }
};

export default FileTypeIcon;
