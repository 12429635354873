import React, { FC } from 'react';
import styled from '@emotion/styled';

const Container = styled.div({
    width: '50%',
    backgroundColor: 'white',
    padding: '50px',
    borderRadius: '4px',
});

const SettingsPage: FC = ({ children }: { children: any }) => {
    return <Container>{children}</Container>;
};

export default SettingsPage;
