import React, { useState } from 'react';
import styled from '@emotion/styled';
import FilterIcon from '../../../public/assets/filter.svg';
import Close from '../../../components/Close/Close';
import { useSpring, animated } from 'react-spring';
import useGetFilteredProducts from '../hooks/useGetFilteredProducts';
import FilterComponent from './FilterComponent';
import { L } from '../../../lib/i18n';

const Icon = styled(FilterIcon)({
    marginLeft: '20px',
    cursor: 'pointer'
});

const Modal = styled.div({
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'white',
    overflowY: 'scroll',
    padding: '0px 20px 20px 20px'
});

const DesktopModal = styled.div({
    width: '400px',
    height: '100vh',
    position: 'fixed',
    padding: '0px 20px 20px 20px',
    top: 0,
    right: 0,
    overflowY: 'scroll',
    background: 'white'
});

const StyledClose = styled(Close)({
    display: 'block',
    margin: '10px 0px'
});

const CloseContainer = styled.div({
    position: 'sticky',
    top: '0',
    backgroundColor: 'white',
    width: '100%',
    height: '60px'
});

const Wrapper = styled.div({
    position: 'relative',
    width: '100%'
});

const MobileContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',

    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const DesktopContainer = styled.div({
    display: 'none',
    ['@media screen and (min-width: 1024px)']: {
        display: 'flex',
        flexDirection: 'column'
    }
});

const StyledInput = styled.input({
    width: '18px',
    height: '18px',
    margin: '5px 0px'
});

const ProductWrapper = styled.label({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0.5rem 30px 0.5rem',
    cursor: 'pointer'
});

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400
});

const Filter = ({ setIsOtherFilterOpen, isOtherFilterOpen }) => {
    const [openModal, setOpenModal] = useState(false);

    const slideIn = useSpring({
        transform: openModal ? 'translateX(0px)' : 'translateX(300px)',
        config: { friction: 30 }
    });

    const AnimatedModal = animated(DesktopModal);

    const { data: filteredProducts } = useGetFilteredProducts();

    filteredProducts?.sort((a, b) => b.checked - a.checked);

    return (
        <>
            <MobileContainer>
                <Icon height="24" width="24" onClick={() => setOpenModal(true)} />
                {openModal && (
                    <Modal>
                        <Wrapper>
                            <CloseContainer>
                                <StyledClose onClose={() => setOpenModal(false)} />
                            </CloseContainer>
                            <Header>{L('Filter')} </Header>
                            <ProductWrapper>
                                <span>Visa endast ej inventerade</span>
                                <StyledInput
                                    type="checkbox"
                                    checked={isOtherFilterOpen}
                                    onChange={() => setIsOtherFilterOpen(!isOtherFilterOpen)}
                                />
                            </ProductWrapper>
                            <Header>{L('Funktion')} </Header>
                            {filteredProducts &&
                                filteredProducts.map(product => <FilterComponent product={product} key={product.id} />)}
                        </Wrapper>
                    </Modal>
                )}
            </MobileContainer>
            <DesktopContainer>
                <Icon height="24" width="24" onClick={() => setOpenModal(true)} />
                {openModal && (
                    <AnimatedModal style={slideIn}>
                        <Wrapper>
                            <CloseContainer>
                                <StyledClose onClose={() => setOpenModal(false)} />
                            </CloseContainer>
                            <Header>{L('Filter')} </Header>
                            <ProductWrapper>
                                <span>Visa endast ej inventerade</span>
                                <StyledInput
                                    type="checkbox"
                                    checked={isOtherFilterOpen}
                                    onChange={() => setIsOtherFilterOpen(!isOtherFilterOpen)}
                                />
                            </ProductWrapper>
                            <Header>{L('Funktion')} </Header>
                            {filteredProducts &&
                                filteredProducts.map(product => <FilterComponent product={product} key={product.id} />)}
                        </Wrapper>
                    </AnimatedModal>
                )}
            </DesktopContainer>
        </>
    );
};

export default Filter;
