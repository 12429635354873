import React, { FC } from 'react';
import { MainMenu } from '../MainMenu/MainMenu';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { Header } from '../Header/Header';
import { styleVariables } from '@ourliving/ourliving-ui';

export const mobileBreakpoint = '500px';

const PageContainer = styled.div(() => {
    return {
        height: `100vh`,
        display: 'grid',
        gridTemplateAreas: `
        "header header"
        "nav content"
        `,
        gridTemplateColumns: 'auto 1fr',
        ['@media screen and (min-width: 1024px)']: {
            background: style.colors.pageBackground,
        },
    };
});

const ContentContainer = styled.div({
    overflowY: 'auto',
    [`@media screen and (max-width: ${mobileBreakpoint})`]: {
        paddingTop: styleVariables.header.height,
    },
});

const Content = styled.div({
    padding: '1rem 1rem',
    ['@media screen and (min-width: 1024px)']: {
        padding: '2rem 2rem',
    },
});

type Props = {
    children?: React.ReactNode;
};

export const PageWithNavigation: FC<Props> = ({ children }) => {
    return (
        <div>
            <PageContainer>
                <Header />
                <MainMenu />
                <ContentContainer>
                    <Content>{children}</Content>
                </ContentContainer>
            </PageContainer>
        </div>
    );
};
