import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoDetail from './TodoDetail';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import H1 from '../../../../components/typography/H1';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import { L } from '../../../../lib/i18n';
import { useGetTodoDetail } from './hooks/todoQueries';
import { Loading } from '../../../../components/Loading/Loading';

const TodoDetailPage = () => {
    const { todoId, propertyId, id } = useParams<{ todoId: string; propertyId: string; id: string }>();

    return todoId && propertyId && id ? <Page todoId={+todoId} propertyId={propertyId} id={id} /> : null;
};
const Page = ({ todoId, propertyId, id }: { todoId: number; propertyId: string; id: string }) => {
    const todoQuery = useGetTodoDetail({ todoId, propertyId: +propertyId });

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(id);
    const propertyName = useGetPropertyName(propertyId);

    if (todoQuery.status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../../..'}>{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{todoQuery?.data?.name ?? L('edit_todo')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{todoQuery.data?.name ?? L('todo')}</H1>
            {todoId && propertyId && <TodoDetail todoId={+todoId} propertyId={+propertyId} />}
        </>
    );
};

export default TodoDetailPage;
