import { useMutation, useQueryClient } from 'react-query';
import { getPropertiesKeys } from '../../areas/binders/hooks/propertyHooks/propertyQueries';
import { trpc } from '../trpc';
import { getPropertyTemplatesKeys } from '../../areas/property-templates/hooks/propertyTemplateQueries';

export const useCreateTemplateFromProp = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({
            newName,
            propertyId,
            projectId,
        }: {
            propertyId: number;
            newName?: string;
            projectId?: number | null;
        }) => {
            return trpc.BindersApi.createTemplateFromProperty.mutate({
                toPropertyName: newName,
                propertyId: propertyId,
                projectId,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
                queryClient.invalidateQueries(getPropertyTemplatesKeys.lists());
            },
        },
    );
};

export default useCreateTemplateFromProp;
