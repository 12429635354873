import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';

const Header = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'left',
    fontSize: style.fontSize.gamma,
    lineHeight: style.lineHeight.normal,
    padding: `${style.margin.m}px ${style.margin.m}px`
});

const Title = styled.div({ fontWeight: style.fontWeight.bold });

const Text = styled.div({});

export const PropertyHeader = ({ title, property_name, project_name }) => {
    return (
        <Header>
            <Title>{title}</Title>
            <Text>
                {project_name} {property_name}
            </Text>
        </Header>
    );
};
