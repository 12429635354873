import styled from '@emotion/styled';
import React from 'react';
import ReactQuill from 'react-quill';
import { colors } from '../Shared/Style/colors';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'],
        ['clean']
    ]
};

const StyledQuill = styled(ReactQuill)(() => ({
    '.ql-editor': {
        backgroundColor: colors.inputBackground
    },
    '.ql-blank.ql-blank': {
        backgroundColor: colors.inputBackground
    }
}));

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image'];

const Quill = React.forwardRef<ReactQuill, React.ComponentProps<typeof ReactQuill>>(({ ...rest }, ref) => {
    return <StyledQuill ref={ref} modules={modules} formats={formats} placeholder="Skriv något här..." {...rest} />;
});

export default Quill;
