import * as React from 'react';
import { usePromise } from '../../../../lib/react';
import { Header } from '../../../../components/Layout/Header';
import { L } from '../../../../lib/i18n';
import { style } from '../../../../components/Shared/Style';
import styled from '@emotion/styled';
import { Loading } from '../../../../components/Loading/Loading';
import { useObserver } from 'mobx-react';
import { Table } from '../../../../components/Table/Table';
import { Card } from '../../../../components/Layout/Card';
import { NavLink } from 'react-router-dom';
import { AddonProjectStore } from '../AddonProjectStore';
import { useOnClickOutside } from '../../../../components/Functions/ClickOutsideArea';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Icon } from '../../../../components/Icon/Icon';
import { AddonProjectSettings } from './Components/AddonProjectSettings';
import { AddonProjectInfo } from './Components/AddonProjectInfo';

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.black
});

const Text = styled.div({
    textAlign: 'left'
});

export function AddonProjectListPage() {
    const [ready] = usePromise(async () => {
        await AddonProjectStore.load();
    }, []);

    const clickRef = React.useRef(null);

    const [isSettingsActive, setSettingsActive] = React.useState(false);
    const [isInfoActive, setInfoActive] = React.useState(false);

    useOnClickOutside(clickRef, () => {
        setSettingsActive(false);
        setInfoActive(false);
    });

    const onMoreClick = async (projectId: string) => {
        AddonProjectStore.selectedProjectId = projectId;
        await AddonProjectStore.getById();
        setSettingsActive(true);
    };

    const onInfoClick = async (projectId: string) => {
        AddonProjectStore.selectedProjectId = projectId;
        await AddonProjectStore.getAddonInfo();
        setInfoActive(true);
    };

    const onAutoOrderConfirmation = (event: any, property_id: string) => {
        AddonProjectStore.setAutoConfirmation(property_id, event.target.checked);
    };
    const onSetSingleSigner = (event: any, property_id: string) => {
        AddonProjectStore.setSingleSigner(property_id, event.target.checked);
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        const projects = AddonProjectStore.projects;
        const rows = projects.map(r => ({
            name: (
                <StyledNavLink to={`/addons/project/${r.id}`}>
                    <Text>{r.name}</Text>
                </StyledNavLink>
            ),
            no_properties: (
                <StyledNavLink to={`/addons/project/${r.id}`}>
                    <Text>{r.property_count}</Text>
                </StyledNavLink>
            ),
            auto_confirm: (
                <Checkbox
                    name="enable_autoConfirm"
                    defaultChecked={r.addon_auto_confirmation}
                    onChange={e => onAutoOrderConfirmation(e, r.id.toString())}
                />
            ),
            single_signer: (
                <Checkbox
                    name="enable_single_signer"
                    defaultChecked={r.addon_single_signer}
                    onChange={e => onSetSingleSigner(e, r.id.toString())}
                />
            ),
            settings_more: <Icon.MoreVertical onClick={() => onMoreClick(r.id.toString())} />,
            info: (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Icon.Information onClick={() => onInfoClick(r.id.toString())} />
                </div>
            )
        }));

        return (
            <>
                <Header title={L('projects')} />

                <Card padding={`0px 0px ${style.margin.l}px 0px`}>
                    <Table
                        columns={[
                            { key: 'name', header: L('name'), arrows: true, width: '1.5fr' },
                            { key: 'no_properties', header: L('no_properties'), arrows: true, width: '1fr' },
                            { key: 'auto_confirm', header: L('addon_auto_confirmation') },
                            { key: 'single_signer', header: L('single_signer') },
                            {
                                key: 'settings_more',
                                header: L('settings'),
                                arrows: false,
                                width: '0.5fr'
                            },
                            {
                                key: 'info',
                                header: '',
                                arrows: false,
                                width: '0.2fr'
                            }
                        ]}
                        data={rows}
                    />
                </Card>
                <AddonProjectSettings
                    forwardRef={clickRef}
                    isActive={isSettingsActive}
                    setIsActive={setSettingsActive}
                />
                <AddonProjectInfo forwardRef={clickRef} isActive={isInfoActive} setIsActive={setInfoActive} />
            </>
        );
    });
}
