import { useMutation, useQuery, useQueryClient } from 'react-query';

// Hook
export const basketKey = 'ourliving-basket';

const getItem = () => {
    try {
        // Get from local storage by key
        const item = window.localStorage.getItem(basketKey);
        // Parse stored json or if none return initialValue
        return item ? JSON.parse(item) : [];
    } catch (error) {
        // If error also return initialValue
        return [];
    }
};
export function useBasket<T>() {
    const queryClient = useQueryClient();
    const { data: storedIds } = useQuery('storedIds', getItem, {
        keepPreviousData: true,
    });

    const setStoredIds = useMutation(
        (value: number[]) => {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedIds) : value;
            // Save to local storage
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(basketKey, JSON.stringify(valueToStore));
                window.dispatchEvent(new Event('storage'));
                return Promise.resolve(valueToStore);
            }
            return Promise.reject('window undefined');
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries('storedIds');
            },
        },
    );

    return [storedIds || [], setStoredIds.mutate] as const;
}
