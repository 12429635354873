import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';

export default styled.div({
    color: style.colors.secondary,
    fontSize: style.fontSize.seta,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});
