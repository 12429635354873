import { ServicePartner } from '@app/api/public/ServicePartnersApi';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@ourliving/ourliving-ui';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { TextArea } from '@ourliving/ourliving-ui';
import Spinner from '../../../components/Spinner/Spinner';
import H2 from '../../../components/typography/H2';
import { L } from '../../../lib/i18n';
import useSaveServicePartner from '../hooks/useSaveServicePartner';

const Form = styled.form({
    display: 'grid',
    marginBottom: '2rem',
});

const FormArea = styled.div({
    display: 'grid',
    gridTemplateColumns: `1fr 1fr`,
    gap: '1rem',
});

const StyledButton = styled(Button)({
    placeSelf: 'start end',
    marginTop: '1.5rem',
});

const StyledTextArea = styled(TextArea)({
    resize: 'vertical',
});

type Props = {
    InitialFormData?: Partial<ServicePartner>;
};

const ServicePartnerForm = ({ InitialFormData }: Props) => {
    const history = useHistory();
    const { id, projectId } = useParams<{ id: string; projectId: string }>();

    const [servicePartner, setServicePartner] = useState<Pick<ServicePartner, 'name' | 'description'>>({
        name: InitialFormData?.name || '',
        description: InitialFormData?.description || '',
    });
    const [contact, setContact] = useState<ServicePartner['contact']>({
        name: InitialFormData?.contact?.name || '',
        email: InitialFormData?.contact?.email || '',
        phone_number: InitialFormData?.contact?.phone_number || '',
    });
    const [adress, setAdress] = useState<ServicePartner['address']>({
        addressLine1: InitialFormData?.address?.addressLine1 || '',
        addressLine2: InitialFormData?.address?.addressLine2 || '',
        postalCode: InitialFormData?.address?.postalCode || '',
        city: InitialFormData?.address?.city || '',
        country: InitialFormData?.address?.country || '',
    });
    const saveServicePartner = useSaveServicePartner();

    const handleOnChange = (value: Partial<ServicePartner>) => {
        setServicePartner({ ...servicePartner, ...value });
    };

    const handleContactOnChange = (value: Partial<ServicePartner['contact']>) => {
        setContact({ ...contact, ...value });
    };

    const handleAdressOnChange = (value: Partial<ServicePartner['address']>) => {
        setAdress({ ...adress, ...value });
    };

    const handleOnSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        saveServicePartner.mutate(
            { ...InitialFormData, ...servicePartner, address: { ...adress }, contact: { ...contact } },
            {
                onSuccess: (data) => {
                    if (!id) {
                        history.push(`/maintenance/${projectId}/service_partner/${data.id}`);
                    }
                },
            },
        );
    };
    return (
        <Form onSubmit={handleOnSubmit}>
            <H2>{L('service_partner_info')}</H2>
            <FormArea>
                {/* General */}
                <Label title={L('service_partner_name')}>
                    <Input
                        value={servicePartner.name}
                        onChange={(e) => handleOnChange({ name: e.target.value })}
                        required
                    />
                </Label>

                {/* Adress */}
                <Label title={L('address_1')}>
                    <Input
                        value={adress.addressLine1}
                        onChange={(e) => handleAdressOnChange({ addressLine1: e.target.value })}
                    />
                </Label>
                <Label title={L('address_2')}>
                    <Input
                        value={adress.addressLine2}
                        onChange={(e) => handleAdressOnChange({ addressLine2: e.target.value })}
                    />
                </Label>

                <Label title={L('city')}>
                    <Input value={adress.city} onChange={(e) => handleAdressOnChange({ city: e.target.value })} />
                </Label>

                <Label title={L('country')}>
                    <Input value={adress.country} onChange={(e) => handleAdressOnChange({ country: e.target.value })} />
                </Label>
                <Label title={L('postalCode')}>
                    <Input
                        value={adress.postalCode}
                        onChange={(e) => handleAdressOnChange({ postalCode: e.target.value })}
                    />
                </Label>
                <Label title={L('description')} style={{ gridColumn: '1 / -1' }}>
                    <StyledTextArea onChange={(e) => handleOnChange({ description: e.target.value })} />
                </Label>
            </FormArea>

            {/* Contact */}
            <H2 style={{ marginTop: '2rem' }}>{L('contact_person')}</H2>
            <FormArea>
                <Label title={L('contact_name')}>
                    <Input value={contact.name} onChange={(e) => handleContactOnChange({ name: e.target.value })} />
                </Label>

                <Label title={L('telephone_number')}>
                    <Input
                        value={contact.phone_number}
                        onChange={(e) => handleContactOnChange({ phone_number: e.target.value })}
                    />
                </Label>

                <Label title={L('contact_mail')}>
                    <Input
                        type={'email'}
                        value={contact.email}
                        onChange={(e) => handleContactOnChange({ email: e.target.value })}
                    />
                </Label>
            </FormArea>

            {saveServicePartner.status === 'idle' && <StyledButton type={'submit'}>{L('save')}</StyledButton>}
            {saveServicePartner.status === 'loading' && (
                <StyledButton style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spinner />
                </StyledButton>
            )}
            {saveServicePartner.status === 'error' && <StyledButton type={'submit'}>{L('save')}</StyledButton>}
            {saveServicePartner.status === 'success' && <StyledButton type={'submit'}>{L('saved')}</StyledButton>}
        </Form>
    );
};

export default ServicePartnerForm;
