import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { PropertyCompanyInfo } from './PropertyCompanyInfo';
import { PropertyCompanyLogo } from './PropertyCompanyLogo';
import { L } from '../../../../lib/i18n';

const Info = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal,
    padding: style.margin.s,
    paddingLeft: style.margin.l,
    paddingRight: style.margin.l
});

const Row = styled.div({
    display: 'flex',
    flexDirection: 'row'
});

const PropInfo = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100%/3)'
});

const Text = styled.div({
    width: '60%',
    fontWeight: style.fontWeight.normal
});

const BoldText = styled.div({
    width: '40%',
    color: style.colors.black,
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal
});

const Title = styled.div({
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold
});

export const PropertyInfo = ({
    PropertyTitle,
    project,
    property_type,
    living_space,
    no_rooms,
    property,
    project_logo,
    project_info
}) => {
    return (
        <Info>
            <PropInfo>
                {PropertyTitle && <Title>{PropertyTitle}</Title>}
                {project && (
                    <Row>
                        <BoldText>Projekt:</BoldText>
                        <Text>{project}</Text>
                    </Row>
                )}
                {project_info?.project_registration_number && (
                    <Row>
                        <BoldText>Orgnummer: </BoldText> <Text>{project_info?.project_registration_number}</Text>
                    </Row>
                )}
                {property && (
                    <Row>
                        <BoldText>Bostad: </BoldText> <Text> {property}</Text>
                    </Row>
                )}
                {property_type && (
                    <Row>
                        <BoldText>Bostadstyp: </BoldText> <Text> {L(`${property_type}`)}</Text>
                    </Row>
                )}
                {living_space && (
                    <Row>
                        <BoldText>Boarea:</BoldText> <Text> {living_space}</Text>
                    </Row>
                )}
                {no_rooms && (
                    <Row>
                        <BoldText>Antal Rum:</BoldText> <Text>{no_rooms}</Text>
                    </Row>
                )}
            </PropInfo>
            <PropertyCompanyInfo CompanyTitle={'Säljare'} project_info={project_info} />
            <PropertyCompanyLogo image={project_logo} />
        </Info>
    );
};
