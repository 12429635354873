import React from 'react';
import { L } from '../../../../lib/i18n';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import Input from '../../../../components/Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import toast from 'react-hot-toast';
import { useGetProperties } from '../../hooks/propertyHooks/propertyQueries';
import useAddPropertyFromTemplate from '../../hooks/propertyHooks/useAddPropertyFromTemplate';
import H2 from '../../../../components/typography/H2';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    minWidth: '400px',
    padding: '1rem',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const AddPropertyFromTemplateSchema = z.object({
    propertyId: z.number(),
    newName: z.string(),
});

type FormType = z.infer<typeof AddPropertyFromTemplateSchema>;

type Props = {
    handleCloseModal: () => void;
    projectId: number;
};

const AddPropertyFromTemplate = ({ handleCloseModal, projectId }: Props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<FormType>({
        resolver: zodResolver(AddPropertyFromTemplateSchema),
    });

    const { mutate: addFromTemplate } = useAddPropertyFromTemplate();

    const onSubmit: SubmitHandler<FormType> = (params) => {
        addFromTemplate(
            { ...params, projectId },
            {
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
                onSuccess: () => {
                    toast.success(L('property_created_from_template'));
                    handleCloseModal();
                },
            },
        );
    };

    const { data: templates } = useGetProperties({ projectId: Number(projectId), shouldFetch: 'template' });

    const templateOptions =
        templates?.map((template) => ({
            label: template.name,
            value: template.id,
        })) ?? [];

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <H2>{L('new_property_from_template')}</H2>
            <ErrorAndInputWrapper errorMsg={L(errors.propertyId?.message ?? '')}>
                <Label title={L('template')} />
                <Controller
                    control={control}
                    name="propertyId"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.propertyId}
                            options={templateOptions}
                            onValueChange={(val) => {
                                onChange(+val);
                                const correctLabel = templates?.find((template) => template.id === +val)?.name ?? '';
                                setValue('newName', correctLabel);
                            }}
                            value={value}
                        />
                    )}
                />
            </ErrorAndInputWrapper>
            <ErrorAndInputWrapper errorMsg={L(errors.newName?.message ?? '')}>
                <Label title={L('name')} />
                <RestyledInput isError={!!errors.newName} type="text" {...register('newName')} />
            </ErrorAndInputWrapper>
            <div style={{ marginTop: style.margin.m, display: 'flex', justifyContent: 'end' }}>
                <Button>{L('save')}</Button>
            </div>
        </Form>
    );
};

export default AddPropertyFromTemplate;
