import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import {
    useListBrands,
    useListSuppliers,
    useListProffesions,
    useListProductTypes,
    useListProperties,
    useListSpaces,
} from '../../hooks/addProductQueries';
import { SubmitHandler } from 'react-hook-form';
import ProductForm from '../../components/Products/ProductForm';
import { AddProduct } from '../../schemas';
import {
    getProductsKeys,
    useGetProduct,
    useGetProductName,
    useGetProductsDocuments,
    useGetProductsImages,
} from '../../hooks/productQueries';
import useUpdateProduct from '../../hooks/productHooks/useUpdateProduct';
import DocumentAreaAndDropZone from '../../../../components/DocumentCommunity/DocumentAreaAndDropZone';
import dayjs from 'dayjs';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import { Separator } from '../../../../components/Separator/Separator';
import useDragEndDnd from '../../../../hooks/useDragEndDndNew';
import Images from '../../components/Draggables/Images';
import { Loading } from '../../../../components/Loading/Loading';
import useUploadProductDocuments from '../../hooks/productHooks/useUploadProductDocuments';
import useUpdateProductDocSort from '../../hooks/productHooks/useUpdateProductDocSort';
import useUpdateProductDocName from '../../hooks/productHooks/useUpdateProductDocName';
import useUpdateProductDocType from '../../hooks/productHooks/useUpdateProductDocType';
import useRemoveProductDocument from '../../hooks/productHooks/useRemoveProductDocument';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import { useParams } from 'react-router-dom-v5-compat';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import { useGetSpaceName } from '../../hooks/spaceHooks/spaceQueries';
import H1 from '../../../../components/typography/H1';
import ImageDropZoneNoText from '../../../../components/ImageDropzone/ImageDropZoneNoText';

const Divider = styled(Separator)({
    backgroundColor: style.colors.divider,
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const Wrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',
    paddingBottom: '2rem',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const EditProduct = ({
    projectId,
    productId,
    propertyId,
}: {
    projectId: string;
    productId: string;
    propertyId: string;
}) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();

    const { data: properties } = useListProperties(Number(projectId));
    const [selectedPropertyId, setSelectedPropertyId] = useState(0);
    const { data: spaces } = useListSpaces(selectedPropertyId);

    const { packageItemId } = useParams<{ packageItemId: string }>();

    const handleSetSelectedPropertyId = (val: number) => setSelectedPropertyId(val);

    const { data: product, isLoading } = useGetProduct(+productId);

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        properties: properties ? properties.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: updateInventoryMaterial } = useUpdateProduct();

    const onSubmit: SubmitHandler<AddProduct> = (data) =>
        updateInventoryMaterial(
            { inventoryMaterial: data, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('product_updated'));
                },
                onError: () => {
                    toast.error(L('product_not_updated'));
                },
            },
        );

    const { data: documents } = useGetProductsDocuments(+productId);
    const { data: images } = useGetProductsImages(+productId);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateProductDocSort();
    const { mutate: uploadDocs } = useUploadProductDocuments();
    const { mutate: removeDocument } = useRemoveProductDocument();
    const { mutate: renameDocument } = useUpdateProductDocName();
    const { mutate: updateDocType } = useUpdateProductDocType();

    const queryClient = useQueryClient();
    const handleSaveMultipleDocs = (files: File[]) => {
        uploadDocs(
            { files, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('documents_uploaded'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
            },
        );
    };

    const onImageUpload = (files: File[]) => {
        uploadDocs(
            { files, productId: +productId, documentType: 0 },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRemoveDocument = (documentId: number) => {
        removeDocument(
            { documentId, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRenameDocument = (documentId: number, name: string) => {
        renameDocument(
            { docId: documentId, name, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('renamed_document')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleUpdateDocType = ({
        documentId,
        document_type,
        document_subtype,
    }: {
        documentId: number;
        document_type: number;
        document_subtype: number;
    }) => {
        updateDocType(
            { docId: documentId, document_type, document_subtype, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('updated_document_types'));
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDragEndDocs = useDragEndDnd({
        queryKey: getProductsKeys.documentsByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const handleDragEndImages = useDragEndDnd({
        queryKey: getProductsKeys.imagesByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    const imagesData =
        images?.map((document) => {
            return { id: document.id, url: document.signed_url };
        }) ?? [];

    const { spaceId } = useParams<{ spaceId: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const isUnderPackage = location.pathname.includes('/package');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);
    const productParentId =
        product?.parent_id !== undefined && product.parent_entity_type !== 0 ? product.parent_id : null;
    const { data: spaceNameData } = useGetSpaceName(isUnderPackage && spaceId ? +spaceId : productParentId);
    const { data: parentProductNameData } = useGetProductName(productParentId);
    const productTitle = product?.attr?.product ? product.attr.product : L('product');

    const regularBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="../../../../..">
                {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    const packageBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="../../../../../../..">
                {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="..">{parentProductNameData?.name ?? L('product')}</Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    const regularSpaceBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="../../../../../../..">
                {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="..">{spaceNameData?.name ?? L('space')}</Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    const packageSpaceBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="../../../../../../../../..">
                {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../../../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="../../..">{spaceNameData?.name ?? L('space')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="..">{parentProductNameData?.name ?? L('product')}</Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            {!spaceId && (
                <>
                    {packageItemId && (
                        <>
                            {packageBreadcrumbs}
                            {<H1>{productTitle}</H1>}
                        </>
                    )}

                    {!packageItemId && (
                        <>
                            {regularBreadcrumbs}
                            {<H1>{productTitle}</H1>}
                            <BigTabMenu>
                                <BigTabNav>
                                    <BigNavItem end to=".">
                                        {L('edit_product')}
                                    </BigNavItem>
                                    <BigNavItem end to="package">
                                        {L('content_of_packages')}
                                    </BigNavItem>
                                </BigTabNav>
                            </BigTabMenu>
                        </>
                    )}
                </>
            )}

            {spaceId && (
                <>
                    {packageItemId && (
                        <>
                            {packageSpaceBreadcrumbs}
                            {<H1>{productTitle}</H1>}
                        </>
                    )}
                    {!packageItemId && (
                        <>
                            {regularSpaceBreadcrumbs}
                            {<H1>{productTitle}</H1>}

                            <BigTabMenu>
                                <BigTabNav>
                                    <BigNavItem end to=".">
                                        {L('edit_product')}
                                    </BigNavItem>
                                    <BigNavItem end to="package">
                                        {L('content_of_packages')}
                                    </BigNavItem>
                                </BigTabNav>
                            </BigTabMenu>
                        </>
                    )}
                </>
            )}

            <Wrapper>
                <CardWithDivider
                    topArea={<CardTitle>{L('edit_product')}</CardTitle>}
                    mainArea={
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '1rem' }}>
                            {product && (
                                <ProductForm
                                    handleSetSelectedPropertyId={handleSetSelectedPropertyId}
                                    onSubmit={onSubmit}
                                    options={options}
                                    defaultValues={product}
                                    oneFieldPerRow
                                    edit
                                />
                            )}
                            <DividerContainer>
                                <Divider decorative orientation="vertical" />
                            </DividerContainer>
                            <div>
                                <div>
                                    <div>
                                        <ErrorAndInputWrapper>
                                            <Label title={L('created_at')}>
                                                <InputWithError
                                                    type="datetime-local"
                                                    readOnly
                                                    value={dayjs(product?.created_at).format('YYYY-MM-DDTHH:mm')}
                                                />
                                            </Label>
                                        </ErrorAndInputWrapper>
                                        <ErrorAndInputWrapper>
                                            <Label title={L('updated_at')}>
                                                <InputWithError
                                                    type="datetime-local"
                                                    readOnly
                                                    value={dayjs(product?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                                />
                                            </Label>
                                        </ErrorAndInputWrapper>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Images
                                            imageData={imagesData}
                                            handleRemoveImage={handleRemoveDocument}
                                            handleDragEnd={handleDragEndImages}
                                            isUpdatingSort={isUpdatingSort}
                                        >
                                            <ImageDropZoneNoText onDrop={onImageUpload} />
                                        </Images>
                                    </div>
                                    <StyledH3>{L('documents')}</StyledH3>
                                    <DocumentAreaAndDropZone
                                        documents={documents}
                                        removeDocument={handleRemoveDocument}
                                        saveMultipleDocs={handleSaveMultipleDocs}
                                        renameDocument={handleRenameDocument}
                                        updateDocType={handleUpdateDocType}
                                        handleDragEnd={handleDragEndDocs}
                                        isUpdatingSort={isUpdatingSort}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />
            </Wrapper>
        </>
    );
};

export default EditProduct;
