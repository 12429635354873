import React, { HTMLAttributes, useCallback } from 'react';
import { DropEvent, DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { colors } from '../Shared/Style/colors';
import { roundCornerSize } from '../Shared/Style/box';
import { QueryStatus } from 'react-query';
import { BarLoader } from 'react-spinners';
import toast from 'react-hot-toast';
import { Icon } from '../Icon/Icon';
import { L } from '../../lib/i18n';

const StyledDropZone = styled.div({
    backgroundColor: colors.white,
    minWidth: '100px',
    aspectRatio: '1/1',
    borderRadius: roundCornerSize.medium,
    border: `1px dashed ${colors.inputBorder}`,
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
});

const WithImageDropZone = styled(StyledDropZone)({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    padding: '0px',
    overflow: 'hidden',
    placeItems: 'start end',
    '.hover_info': {
        display: 'none',
    },
    '.upload': {
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        display: 'grid',
        height: '100%',
    },
    ':hover': {
        placeItems: 'center',
        '.hover_info': {
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            display: 'grid',
            height: '100%',
            width: '100%',
        },

        '.with_image': {
            display: 'none',
        },
    },
});

const StyledUploadIcon = styled.div({
    width: 'max-content',
    display: 'flex',
});

const UploadContainer = styled.div({
    display: 'grid',
    gap: '0.5rem',
    position: 'relative',
    placeItems: 'center',
});

const ProgressBar = styled.div(({ theme }) => ({
    borderRadius: '8px',
    bottom: '-16px',
    height: '8px',
    backgroundColor: theme.progressBarBackground,
    overflow: 'hidden',
    width: '100%',
    position: 'absolute',
}));

const Progress = styled.div(({ theme }) => ({
    backgroundColor: theme.primary,
    width: '100%',
    height: '100%',
    translate: '-100%',
    borderRadius: '8px',
}));

const UploadText = styled.p(({ theme }) => ({
    margin: '0px',
    color: theme.black,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    '& span': {
        color: theme.primary,
    },
}));

const UploadIcon = () => {
    return (
        <StyledUploadIcon>
            <Icon.UploadIcon size="large" />
        </StyledUploadIcon>
    );
};

const UploadInfo = ({ children }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <UploadContainer>
            <UploadIcon />
            <UploadText>
                <span>Ladda upp</span>
            </UploadText>
            {children}
        </UploadContainer>
    );
};

const WithImage = () => {
    return (
        <UploadContainer
            className={'with_image'}
            style={{
                backgroundColor: colors.white,
                padding: '8px',
                margin: '8px',
                borderRadius: roundCornerSize.medium,
                width: 'max-content',
            }}
        >
            <UploadIcon />
        </UploadContainer>
    );
};

const OnUploadComponent = ({
    acceptedFiles,
    progress,
}: { acceptedFiles: File[]; progress?: number } & HTMLAttributes<HTMLDivElement>) => {
    return (
        <UploadContainer>
            <UploadIcon />
            <UploadText>{acceptedFiles?.[0]?.name}</UploadText>
            {typeof progress === 'number' ? (
                <ProgressBar>
                    <Progress
                        style={{
                            translate: `${progress - 100}%`,
                        }}
                    />
                </ProgressBar>
            ) : (
                <BarLoader
                    color={colors.primary}
                    cssOverride={{
                        position: 'absolute',
                        bottom: '-16px',
                        width: '100%',
                    }}
                />
            )}
        </UploadContainer>
    );
};

type OnDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;
export type Props = {
    dropzoneOptions?: DropzoneOptions;
    onDrop: OnDrop;
    url?: string;
    status?: QueryStatus;
};
const ImageDropZoneNoText = ({ dropzoneOptions, onDrop, url, status = 'idle' }: Props) => {
    const onDropCallback = useCallback<
        <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
    >((...rest) => {
        onDrop(...rest);
    }, []);

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        onDrop: onDropCallback,
        onDropRejected(fileRejections) {
            fileRejections.forEach((fileRejection) => {
                fileRejection.errors.forEach((error) => {
                    const errorCode = error.code.replace(/-/g, '_');
                    toast.error(`${fileRejection.file.name} ${L(errorCode)}`);
                });
            });
        },
        accept: {
            'image/*': [],
        },
        ...dropzoneOptions,
        maxSize: 100000000,
    });

    if (url) {
        return (
            <WithImageDropZone
                style={{
                    backgroundImage: `url(${url})`,
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {status === 'loading' ? (
                    <OnUploadComponent acceptedFiles={acceptedFiles} />
                ) : (
                    <>
                        <WithImage />
                        <UploadInfo />
                    </>
                )}
            </WithImageDropZone>
        );
    }
    return (
        <StyledDropZone {...getRootProps()}>
            <input {...getInputProps()} />
            {status === 'loading' ? <OnUploadComponent acceptedFiles={acceptedFiles} /> : <UploadInfo />}
        </StyledDropZone>
    );
};

export default ImageDropZoneNoText;
