import styled from '@emotion/styled';
import React from 'react';
import { L } from '../../../lib/i18n';
import useGetPropertyById from '../hooks/useGetPropertyById';
import SpaceComponent from './SpaceComponent';
import BackArrow from '../../../public/assets/backarrow.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useGetProjects from '../hooks/useGetProjects';
import useGetPropertiesByProject from '../hooks/useGetPropertiesByProject';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

const FirstLine = styled.div({
    borderTop: '1px solid #979797'
});

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
    marginLeft: '12px',
    ['@media screen and (min-width: 1024px)']: {
        marginLeft: '0px'
    }
});

const StyledBackArrow = styled(BackArrow)({
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const BreadCrumbContainer = styled.div({
    display: 'flex',
    marginLeft: '12px',
    marginTop: '12px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const Slash = styled.span({
    textDecoration: 'none',
    fontSize: '16px',
    color: '#9DA3B4',
    fontWeight: 400,
    margin: '0px 5px'
});

const Spaces = () => {
    const { data: spaces } = useGetPropertyById();
    const { data: projects } = useGetProjects();
    const { data: properties } = useGetPropertiesByProject();
    const params = useParams<{ projectId: string; propertyId: string }>();

    const projectById = projects?.find(project => project.id === params.projectId);
    const propertyById = properties?.find(project => project.id === params.propertyId);

    const url = useLocation();
    const history = useHistory();
    const prevUrl = url.pathname.replace(`/property/${params.propertyId}`, '');

    if (!spaces) return null;

    return (
        <>
            <StyledBackArrow onClick={() => history.push(prevUrl)} />
            <BreadCrumbContainer>
                <Breadcrumb breadcrumbs={[{ name: projectById?.name, to: '/inventory' }]} />
                <Slash>/</Slash>
                <Breadcrumb breadcrumbs={[{ name: propertyById?.name, to: prevUrl }]} />
            </BreadCrumbContainer>

            <Header>{L('space')}</Header>

            {spaces.length > 0 ? (
                <>
                    {spaces?.map(space => (
                        <SpaceComponent space={space} key={space.id} />
                    ))}
                </>
            ) : (
                'Här var det tomt'
            )}
        </>
    );
};

export default Spaces;
