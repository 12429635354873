import { useQuery } from 'react-query';
import { trpc } from '../../../../../lib/trpc';

export const todoQueryKeys = {
    all: [{ scope: 'todos' }] as const,
    lists: () => [{ ...todoQueryKeys.all[0], entity: 'list' }] as const,
    list: (params: { propertyId: number; template?: boolean; search?: string }) =>
        [{ ...todoQueryKeys.lists()[0], params }] as const,
    details: () => [{ ...todoQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (params: { propertyId: number; id?: number }) => [{ ...todoQueryKeys.details()[0], params }] as const,
    recurrency: () => [{ ...todoQueryKeys.all[0], entity: 'recurrency' }] as const,
    entityTypes: () => [{ ...todoQueryKeys.all[0], entity: 'entityType' }] as const,
    entitySubtypes: () => [{ ...todoQueryKeys.all[0], entity: 'entitySubtypes' }] as const,
    entitySubtype: (params: { entityType?: string }) => [{ ...todoQueryKeys.entitySubtypes[0], params }] as const,
};

const useGetTodos = ({ propertyId, template }: { propertyId: number; template?: boolean }) => {
    return useQuery({
        queryKey: todoQueryKeys.list({ propertyId, template }),
        queryFn: ({ queryKey }) => {
            const {
                params: { propertyId, template },
            } = queryKey[0];

            return trpc.TodoApi.listTodosProperty.query({
                propertyId,
                template,
            });
        },
    });
};

const useGetTodoDetail = ({ propertyId, todoId }: { propertyId: number; todoId?: number }) => {
    return useQuery({
        queryKey: todoQueryKeys.detail({ propertyId, id: todoId }),
        queryFn: ({ queryKey }) => {
            const {
                params: { propertyId, id },
            } = queryKey[0];
            if (!id) return;

            return trpc.TodoApi.todoProperty.query({
                propertyId,
                todoId: id,
            });
        },
        enabled: !!todoId,
    });
};

const useGetTodoRecurrency = () => {
    return useQuery({
        queryKey: todoQueryKeys.recurrency(),
        queryFn: () => {
            return trpc.TodoApi.recurrency.query();
        },
    });
};
const useGetTodoEntityType = () => {
    return useQuery({
        queryKey: todoQueryKeys.entityTypes(),
        queryFn: () => {
            return trpc.TodoApi.entityTypes.query();
        },
    });
};

const useGetTodoEntitySubtype = ({ entityType }: { entityType?: string }) => {
    return useQuery({
        queryKey: todoQueryKeys.entitySubtype({ entityType }),
        queryFn: ({ queryKey }) => {
            const {
                params: { entityType },
            } = queryKey[0];

            return trpc.TodoApi.entitySubtypes.query({
                entityType: entityType,
            });
        },
        enabled: !!entityType,
    });
};

export { useGetTodos, useGetTodoDetail, useGetTodoRecurrency, useGetTodoEntityType, useGetTodoEntitySubtype };
