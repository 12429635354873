import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { uploadToAws } from '../ContentInformation/uploadToAws';

export default function useUploadTemplateImages() {
    return useMutation(async ({ files, projectId }: { files: File[]; projectId: number }) => {
        const responses = files.map(async (file) => {
            const image = {
                name: file.name,
                size: file.size,
                type: file.type,
            };

            const response = await trpc.TemplatesApi.saveTemplateImage.mutate({
                file: image,
                projectId,
            });

            if (response) {
                await uploadToAws({
                    preSingedUrl: response.preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.TemplatesApi.removeTemplateImage.mutate({
                            id: response.id,
                            projectId: projectId,
                        });
                    },
                });
            }
        });
        return await Promise.all(responses);
    });
}
