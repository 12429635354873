import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoNew from './TodoNew';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';

const TodoNewPage = () => {
    const { propertyId, id } = useParams<{ propertyId: string; id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(id);
    const propertyName = useGetPropertyName(propertyId);
    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../../..'}>{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('create_new_todo')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            {propertyId && <TodoNew propertyId={+propertyId} />}
        </>
    );
};

export default TodoNewPage;
