import React from 'react';
import { Menu, Icon } from '@ourliving/ourliving-ui';
import { L } from '../../lib/i18n';
import { useLocation } from 'react-router-dom-v5-compat';
import { Icon as LocalIcons } from '../Icon/Icon';

export const MenuData = () => {
    const location = useLocation();

    const groupHasActiveChild = (pathnames: string[]) => {
        return pathnames.findIndex((path) => location.pathname.startsWith(path)) === -1 ? false : true;
    };

    return (
        <>
            <Menu.Item icon={<Icon.HousePricing />} link="/" active={location.pathname === '/'}>
                {L('overview')}
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item icon={<Icon.PCheck />} link="/tickets" active={location.pathname.startsWith('/tickets')}>
                {L('tickets')}
            </Menu.Item>
            <Menu.Item icon={<Icon.Notification />} link="/alerts" active={location.pathname.startsWith('/alerts')}>
                {L('alerts')}
            </Menu.Item>

            <Menu.Divider />

            <Menu.Item icon={<Icon.Projects />} link="/binders" active={location.pathname.startsWith('/binders')}>
                {L('project_literally')}
            </Menu.Item>
            <Menu.Item icon={<Icon.AppStore />} link="/addons" active={location.pathname.startsWith('/addons')}>
                {L('addons')}
            </Menu.Item>

            <Menu.Group
                icon={<Icon.Block />}
                title={L('root_templates')}
                active={groupHasActiveChild(['/project-templates', '/contacts', '/property-templates', '/todos'])}
            >
                <Menu.Item link="/project-templates" active={location.pathname.startsWith('/project-templates')}>
                    {L('project_templates')}
                </Menu.Item>
                <Menu.Item link="/property-templates" active={location.pathname.startsWith('/property-templates')}>
                    {L('property_templates')}
                </Menu.Item>
                <Menu.Item link="/todos" active={location.pathname.startsWith('/todos')}>
                    {L('todos')}
                </Menu.Item>
                <Menu.Item link="/contacts" active={location.pathname.startsWith('/contacts')}>
                    {L('contacts')}
                </Menu.Item>
            </Menu.Group>
            <Menu.Item
                icon={<Icon.BathFaucet />}
                link="/inventory-library"
                active={location.pathname.startsWith('/inventory-library')}
            >
                {L('inventory_lib')}
            </Menu.Item>

            <Menu.Item
                icon={<LocalIcons.CopyFilesIcon />}
                link="/copy-function"
                active={location.pathname.startsWith('/copy-function')}
            >
                {L('product_copying')}
            </Menu.Item>
            <Menu.Item
                icon={<Icon.File />}
                link="/copy-documents"
                active={location.pathname.startsWith('/copy-documents')}
            >
                {L('document_management')}
            </Menu.Item>
            <Menu.Item icon={<Icon.Wrench />} link="/maintenance" active={location.pathname.startsWith('/maintenance')}>
                {L('maintenance')}
            </Menu.Item>
            <Menu.Group
                icon={<Icon.SettingsGear />}
                title={L('settings')}
                active={groupHasActiveChild([
                    '/addon-settings',
                    '/template-settings',
                    '/administration-settings',
                    '/ticket-settings',
                ])}
            >
                <Menu.Item link="/addon-settings" active={location.pathname.startsWith('/addon-settings')}>
                    {L('addon')}
                </Menu.Item>
                <Menu.Item link="/template-settings" active={location.pathname.startsWith('/template-settings')}>
                    {L('templates')}
                </Menu.Item>
                <Menu.Item
                    link="/administration-settings"
                    active={location.pathname.startsWith('/administration-settings')}
                >
                    {L('admins')}
                </Menu.Item>
                <Menu.Item link="/ticket-settings" active={location.pathname.startsWith('/ticket-settings')}>
                    {L('tickets')}
                </Menu.Item>
                <Menu.Item link="/information-settings" active={location.pathname.startsWith('/information-settings')}>
                    {L('content_information')}
                </Menu.Item>
            </Menu.Group>
        </>
    );
};
