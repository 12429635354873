import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';

import { useParams } from 'react-router-dom';
export const getMaintenanceDocumentsQueryKey = 'getDocumentsList';
const getMaintenanceDocuments = async (projectId: string, id: number) => {
    return await MaintenancesApi.listDocuments(+projectId, id);
};

const useGetMaintenanceDocuments = () => {
    const { id } = useParams<{ id: string }>();
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery([getMaintenanceDocumentsQueryKey, { id, projectId }], () =>
        getMaintenanceDocuments(projectId, +id)
    );
};

export default useGetMaintenanceDocuments;
