import React, { useState, useEffect, useRef, FormEvent } from 'react';
import styled from '@emotion/styled';
import Close from '../../../components/Close/Close';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';
import useSaveProductImage from '../hooks/useSaveProductImage';
import useUpdateProduct from '../hooks/useUpdateProduct';
import CameraIcon from '../../../public/assets/camera.svg';
import { useGetOcrTextList, useGetOcrWords } from '../hooks/ocrQuery';
import isEqual from 'lodash/isEqual';
import { google } from '@google-cloud/vision/build/protos/protos';
import EditableDropdown from './EditableDropdown';
import useGetSignedUrl from '../../../lib/hooks/useGetSignedUrl';
import { Product } from '@app/api/models/Properties';
import { ProductAttr } from './ProductCard';
import { colors } from '../../../components/Shared/Style/colors';
import { autorun } from 'mobx';

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
});

const Modal = styled.div({
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: 'white',
    padding: '0px 20px 20px 20px',
    overflowY: 'hidden',
    zIndex: 200,
    ['@media screen and (min-width: 1024px)']: {
        width: '50vw',
        height: 'fit-content',
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    },
    ['@media screen and (min-width: 1224px)']: {
        width: '65vw',
    },
});

const ModalOverlay = styled.div({
    ['@media screen and (min-width: 1024px)']: {
        display: 'flex',
        width: '100%',
        overflowX: 'hidden',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(128, 128, 128, 0.7)',
        zIndex: 100,
        bottom: 0,
    },
});

const CloseWrapper = styled.div({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '1rem',
    marginBottom: '1rem',
    alignItems: 'center',
});

const ButtonWrapper = styled.div({
    width: '100%',
    marginTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
});

const StyledButton = styled(Button)({
    marginRight: '0px',
});

const FileInput = styled.input({
    display: 'none',
});

const ImageWrapper = styled.div({
    width: 'auto',
    height: 'auto',
    ['@media screen and (min-width: 768px)']: {
        width: '60%',
    },
    ['@media screen and (min-width: 1024px)']: {
        width: '100%',
    },
    ['@media screen and (min-width: 1224px)']: {
        width: 'auto',
        flex: 1,
    },
});

const ButtonLabel = styled.label({
    cursor: 'pointer',
});

const EditTypePlate = styled.div({
    color: '#86AE43',
    borderColor: '#86AE43',
    background: 'inherit',
    border: '1px solid #86AE43',
    fontSize: '14px',
    fontWeight: 700,
    borderRadius: '4px',
    lineHeight: '16px',
    padding: '8px',
    height: '32px',
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
});

const OcrImage = styled.img({
    width: '100%',
    height: '100%',
    objectPosition: 'center',
});

const DropdownWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '5px',
    borderRadius: '5px',
    border: '1px solid transparent',
});

const ENrDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'e_no' && colors.e_no}`,
}));

const SerialNumberDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'serial_number' && colors.serial_number}`,
}));

const GTIN_EANDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'gtin_ean' && colors.gtin_ean}`,
}));

const RskNumberDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'rsk_no' && colors.rsk_no}`,
}));

const ArticleNumberDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'article_no' && colors.article_no}`,
}));

const PncNumberDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'pnc_number' && colors.pnc_number}`,
}));

const OrderNumberDropdownWrapper = styled(DropdownWrapper)((props: { activeInput?: string }) => ({
    border: `1px solid ${props.activeInput === 'order_no' && colors.order_no}`,
}));

const ENrBox = styled.div({
    width: '30px',
    height: '30px',
    borderRadius: '5px',
    backgroundColor: colors.e_no,
});

const SerialNrBox = styled(ENrBox)({
    backgroundColor: colors.serial_number,
});

const GTIN_EANNrBox = styled(ENrBox)({
    backgroundColor: colors.gtin_ean,
});

const RskNrBox = styled(ENrBox)({
    backgroundColor: colors.rsk_no,
});

const PncNrBox = styled(ENrBox)({
    backgroundColor: colors.pnc_number,
});

const ArtivleNrBox = styled(ENrBox)({
    backgroundColor: colors.article_no,
});

const OrderNrBox = styled(ENrBox)({
    backgroundColor: colors.order_no,
});

const WordBox = styled.div((props: { word; widthRatio: number; heightRatio: number }) => ({
    position: 'absolute',
    top: `${props?.word?.boundingPoly?.vertices?.[0]?.y / props?.heightRatio - 4}px`,
    left: `${props?.word?.boundingPoly?.vertices?.[0]?.x / props?.widthRatio - 4}px`,
    width: `${
        (props?.word?.boundingPoly?.vertices?.[2]?.x - props?.word?.boundingPoly?.vertices?.[0].x) / props.widthRatio +
        8
    }px`,
    height: `${
        (props?.word?.boundingPoly?.vertices?.[2]?.y - props?.word?.boundingPoly?.vertices?.[0].y) / props.heightRatio +
        8
    }px`,
    padding: '5px',
    backgroundColor: 'rgba(0,153,0,0.3)',
    '&.e_no': {
        backgroundColor: `${colors.e_no}4D`,
    },
    '&.serial_number': {
        backgroundColor: `${colors.serial_number}4D`,
    },
    '&.gtin_ean': {
        backgroundColor: `${colors.gtin_ean}4D`,
    },
    '&.rsk_no': {
        backgroundColor: `${colors.rsk_no}4D`,
    },
    '&.article_no': {
        backgroundColor: `${colors.article_no}4D`,
    },
    '&.pnc_number': {
        backgroundColor: `${colors.pnc_number}4D`,
    },
    '&.order_no': {
        backgroundColor: `${colors.order_no}4D`,
    },
}));

const RelativeContainer = styled.div({
    position: 'relative',
    display: 'flex',
});

const InputWrapper = styled.div((props: { modalImageHeight: number }) => ({
    maxHeight: '25vh',
    overflowY: 'scroll',
    ['@media screen and (min-width: 1224px)']: {
        maxHeight: `${props.modalImageHeight}px`,
        flex: 1,
    },
}));

const StyledClose = styled(Close)({
    position: 'relative',
});

const ImageInputWrapper = styled.div({
    ['@media screen and (min-width: 1224px)']: {
        display: 'flex',
        flexDirection: 'row',
    },
});

type ActiveWords = {
    e_no: google.cloud.vision.v1.IEntityAnnotation | undefined;
    serial_number: google.cloud.vision.v1.IEntityAnnotation | undefined;
    gtin_ean: google.cloud.vision.v1.IEntityAnnotation | undefined;
    rsk_no: google.cloud.vision.v1.IEntityAnnotation | undefined;
    pnc_number: google.cloud.vision.v1.IEntityAnnotation | undefined;
    article_no: google.cloud.vision.v1.IEntityAnnotation | undefined;
    order_no: google.cloud.vision.v1.IEntityAnnotation | undefined;
};

type Props = {
    product: Product;
    openTypePlateModal: boolean;
    attr: ProductAttr;
    setAttr: React.Dispatch<React.SetStateAction<ProductAttr>>;
    setOpenTypePlateModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const TypePlateModal = ({ product, openTypePlateModal, setOpenTypePlateModal, attr, setAttr }: Props) => {
    const { mutate: updateProduct } = useUpdateProduct();
    const { mutate: saveProductImage } = useSaveProductImage();

    const documentDetail = product?.documents?.[0];

    const { data: signedUrl } = useGetSignedUrl(documentDetail.document_data);

    const ref = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const [activeInput, setActiveInput] = useState<
        'serial_number' | 'gtin_ean' | 'rsk_no' | 'article_no' | 'pnc_number' | 'order_no' | 'e_no'
    >('e_no');
    const [activeWord, setActiveWord] = useState<ActiveWords>({
        serial_number: undefined,
        e_no: undefined,
        gtin_ean: undefined,
        rsk_no: undefined,
        article_no: undefined,
        pnc_number: undefined,
        order_no: undefined,
    });

    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            setOpenTypePlateModal(false);
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, setOpenTypePlateModal]);
    const { data: imageWords } = useGetOcrTextList({ document: documentDetail, signedUrl: signedUrl });
    const { data: textAnnotations } = useGetOcrWords({ document: documentDetail, signedUrl: signedUrl });

    const imageNaturalHeight = imageRef.current?.naturalHeight || 0;
    const imageNaturalWidth = imageRef.current?.naturalWidth || 0;

    const modalImageHeight = imageRef.current?.height || 0;
    const modalImageWidth = imageRef.current?.width || 0;

    const widthRatio = imageNaturalWidth / modalImageWidth;
    const heightRatio = imageNaturalHeight / modalImageHeight;

    const handleClick = (word?: string | null) => {
        if (!word) return;
        if (activeInput === 'e_no') {
            setAttr((prev) => ({ ...prev, e_no: word }));
        }
        if (activeInput === 'serial_number') {
            setAttr((prev) => ({ ...prev, serial_number: word }));
        }
        if (activeInput === 'gtin_ean') {
            setAttr((prev) => ({ ...prev, gtin_ean: word }));
        }
        if (activeInput === 'rsk_no') {
            setAttr((prev) => ({ ...prev, rsk_no: word }));
        }
        if (activeInput === 'article_no') {
            setAttr((prev) => ({ ...prev, article_no: word }));
        }
        if (activeInput === 'pnc_number') {
            setAttr((prev) => ({ ...prev, pnc_number: word }));
        }
        if (activeInput === 'order_no') {
            setAttr((prev) => ({ ...prev, order_no: word }));
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        updateProduct({
            product: {
                e_no: attr?.e_no,
                serial_number: attr?.serial_number,
                article_no: attr?.article_no,
                rsk_no: attr?.rsk_no,
                gtin_ean: attr?.gtin_ean,
                pnc_number: attr?.pnc_number,
                order_no: attr?.order_no,
            },
            id: product.id,
        });
        setOpenTypePlateModal(false);
    };

    const uploadedImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        saveProductImage({
            file: file,
            productId: product.id,
        });
    };

    const findSelected = (activeWord: ActiveWords, word: google.cloud.vision.v1.IEntityAnnotation) => {
        const matchedWord = Object.entries(activeWord).find(([key, value]) => isEqual(value, word));
        return matchedWord?.[0];
    };

    useEffect(() => {
        textAnnotations?.forEach((word) => {
            if (word.description === attr?.e_no) setActiveWord((prev) => ({ ...prev, e_no: word }));
            if (word.description === attr?.serial_number) setActiveWord((prev) => ({ ...prev, serial_number: word }));
            if (word.description === attr?.article_no) setActiveWord((prev) => ({ ...prev, article_no: word }));
            if (word.description === attr?.rsk_no) setActiveWord((prev) => ({ ...prev, rsk_no: word }));
            if (word.description === attr?.gtin_ean) setActiveWord((prev) => ({ ...prev, gtin_ean: word }));
            if (word.description === attr?.pnc_number) setActiveWord((prev) => ({ ...prev, pnc_number: word }));
            if (word.description === attr?.order_no) setActiveWord((prev) => ({ ...prev, order_no: word }));
        });
    }, [textAnnotations, attr]);

    return (
        <>
            {openTypePlateModal && (
                <ModalOverlay>
                    <Modal ref={ref}>
                        <CloseWrapper>
                            <StyledClose onClose={() => setOpenTypePlateModal(false)} />
                            <ButtonLabel>
                                <FileInput type="file" onChange={uploadedImage} />
                                <EditTypePlate>
                                    <CameraIcon width="20" height="20" />
                                    Redigera typskylt
                                </EditTypePlate>
                            </ButtonLabel>
                        </CloseWrapper>
                        <form onSubmit={handleSubmit}>
                            <>
                                <Label title="Typskylt" />
                                <ImageInputWrapper>
                                    <ImageWrapper>
                                        <RelativeContainer>
                                            <OcrImage ref={imageRef} src={signedUrl} />
                                            {activeInput && (
                                                <>
                                                    {textAnnotations
                                                        ?.filter((_word, i) => i !== 0)
                                                        .map((word, i) => {
                                                            const selected = findSelected(activeWord, word);
                                                            return (
                                                                <WordBox
                                                                    className={selected ? selected : ''}
                                                                    onClick={() => {
                                                                        handleClick(word?.description);
                                                                        setActiveWord((prev) => ({
                                                                            ...prev,
                                                                            [activeInput]: word,
                                                                        }));
                                                                    }}
                                                                    word={word && word}
                                                                    widthRatio={widthRatio}
                                                                    heightRatio={heightRatio}
                                                                    key={i}
                                                                />
                                                            );
                                                        })}
                                                </>
                                            )}
                                        </RelativeContainer>
                                    </ImageWrapper>

                                    <InputWrapper modalImageHeight={modalImageHeight}>
                                        <Label title="E-nr" />
                                        <ENrDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('e_no')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) => setAttr((prev) => ({ ...prev, e_no: value }))}
                                                value={attr?.e_no}
                                            />
                                            <ENrBox />
                                        </ENrDropdownWrapper>

                                        <Label title="Serienummer" />
                                        <SerialNumberDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('serial_number')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) =>
                                                    setAttr((prev) => ({ ...prev, serial_number: value }))
                                                }
                                                value={attr?.serial_number}
                                            />
                                            <SerialNrBox />
                                        </SerialNumberDropdownWrapper>

                                        <Label title="Artikelnummer" />
                                        <ArticleNumberDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('article_no')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) =>
                                                    setAttr((prev) => ({ ...prev, article_no: value }))
                                                }
                                                value={attr?.article_no}
                                            />
                                            <ArtivleNrBox />
                                        </ArticleNumberDropdownWrapper>

                                        <Label title="Rsknummer" />
                                        <RskNumberDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('rsk_no')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) => setAttr((prev) => ({ ...prev, rsk_no: value }))}
                                                value={attr?.rsk_no}
                                            />
                                            <RskNrBox />
                                        </RskNumberDropdownWrapper>

                                        <Label title="GTIN/EAN" />
                                        <GTIN_EANDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('gtin_ean')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) => setAttr((prev) => ({ ...prev, gtin_ean: value }))}
                                                value={attr?.gtin_ean}
                                            />
                                            <GTIN_EANNrBox />
                                        </GTIN_EANDropdownWrapper>

                                        <Label title="Pncnummer" />
                                        <PncNumberDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('pnc_number')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) =>
                                                    setAttr((prev) => ({ ...prev, pnc_number: value }))
                                                }
                                                value={attr?.pnc_number}
                                            />
                                            <PncNrBox />
                                        </PncNumberDropdownWrapper>

                                        <Label title="Ordernummer" />
                                        <OrderNumberDropdownWrapper
                                            activeInput={activeInput}
                                            onClick={() => setActiveInput('order_no')}
                                        >
                                            <EditableDropdown
                                                imageWords={imageWords}
                                                setState={(value) => setAttr((prev) => ({ ...prev, order_no: value }))}
                                                value={attr?.order_no}
                                            />
                                            <OrderNrBox />
                                        </OrderNumberDropdownWrapper>
                                    </InputWrapper>
                                </ImageInputWrapper>

                                <ButtonWrapper>
                                    <StyledButton type="submit">Spara</StyledButton>
                                </ButtonWrapper>
                            </>
                        </form>
                    </Modal>
                </ModalOverlay>
            )}
        </>
    );
};

export default TypePlateModal;
