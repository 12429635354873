import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';

const useDeleteProperty = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (propertyId: number) => {
            return trpc.BindersApi.deletePropertyTemplateOnProject.query({ propertyId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
            },
        },
    );
};

export default useDeleteProperty;
