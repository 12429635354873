const size = {
    smartphone: '450px',
    tablet: '1024px',
    mediumDevice: '1200px'
};

export const device = {
    smartphone: `(max-width: ${size.smartphone})`,
    tablet: `(max-width: ${size.tablet})`,
    mediumDevice: `(max-width: ${size.mediumDevice})`
};
