import AddonsApi from '@app/api/public/AddonsApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';
import { getOrderUserKeys } from './useGetUsersOnOrder';

const setSignedManually = async ({ propertyId, orderId, signingUserId }: Variables) => {
    return await AddonsApi.setSignedManually(+propertyId, +orderId, +signingUserId);
};

type Variables = {
    propertyId: string;
    orderId: string;
    signingUserId: string;
};

const useSetSignedManually = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => setSignedManually(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getOrderUserKeys.all);
            queryClient.invalidateQueries(getPropertyKeys.all);
        }
    });
};

export default useSetSignedManually;
