import React from 'react';
import { Loading } from '../../../components/Loading/Loading';
import useGetContacts from '../hooks/useGetContacts';
import useTableCheckbox from './TableProvider';
import { COLUMNS } from './Columns';
import TableRowSelection from './TableRowSelection';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import TopArea from './TopArea';
import { useModal } from '../../../components/Modal/Context/ModalContext';


const TableListPage = ({
    ToggleIsModalOpen
}: {
    ToggleIsModalOpen: ReturnType<typeof useModal>['ToggleIsModalOpen'];
}) => {
    const { data: contacts } = useGetContacts();

    const useTable = useTableCheckbox({ data: contacts?.items || [], columns: COLUMNS });

    const selectedData = useTable.selectedFlatRows.map(data => {
        return data.original;
    });

    if (!contacts) {
        return <Loading />;
    }

    return (
        <>
            <CardWithDivider
                topArea={
                    <TopArea
                        toggleIsModalOpen={() => ToggleIsModalOpen()}
                        selectedContacts={selectedData}
                        onSubmit={(value: boolean) => useTable.toggleAllRowsSelected(value)}
                    />
                }
                mainArea={<TableRowSelection useTable={useTable} contacts={contacts.items} />}
            />
        </>
    );
};

export default TableListPage;
