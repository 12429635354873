import React from 'react';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import {
    getProjectTemplatesKeys,
    useGetProjectTemplateImages,
} from '../../hooks/ProjectTemplates/projectTemplateQueries';
import Images from '../../../binders/components/Draggables/Images';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import useUploadTemplateImages from '../../hooks/ProjectTemplates/useUploadTemplateImages';
import useRemoveTemplateImage from '../../hooks/ProjectTemplates/useRemoveTemplateImage';
import useSortTemplateImages from '../../hooks/ProjectTemplates/useSortTemplateImages';
import { optimisticSort } from '../../../../hooks/useDragEndDnd';
import { Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadError, onUploadSuccess } from '@ourliving/ourliving-ui';

const ProjectTemplateImages = ({ projectId }: { projectId: number }) => {
    const { data: images } = useGetProjectTemplateImages(projectId);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useSortTemplateImages();
    const { mutate: saveDocuments, isLoading } = useUploadTemplateImages();
    const queryClient = useQueryClient();

    const onUpload = (param: onUploadError | onUploadSuccess) => {
        if (param.status === 'error') {
            toast.error(L('pg_unkown'));
            return;
        }
        saveDocuments(
            { files: [param.file], projectId },
            {
                onSuccess: () => {
                    toast.success(L('images_uploaded'));
                    queryClient.invalidateQueries(getProjectTemplatesKeys.imagesByTemplateId(projectId));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const { mutate: deleteDocument } = useRemoveTemplateImage();

    const handleRemoveImage = (documentId: number) => {
        deleteDocument(
            { documentId, projectId },
            {
                onSuccess: () => {
                    toast.success(`${L('picture')} ${L('removed').toLowerCase()}`);
                    queryClient.invalidateQueries(getProjectTemplatesKeys.imagesByTemplateId(projectId));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const imageData = images?.map((image) => {
        return { id: image.id, url: image.url };
    });

    return (
        <div>
            <div
                style={{
                    marginBottom: '0.5rem',
                }}
            >
                {imageData &&
                    (imageData.length > 0 ? (
                        <Images
                            imageData={imageData}
                            handleRemoveImage={handleRemoveImage}
                            isUploading={isLoading}
                            handleDragEnd={(event) => {
                                const sorted = optimisticSort({
                                    client: queryClient,
                                    event,
                                    queryKey: getProjectTemplatesKeys.imagesByTemplateId(projectId),
                                    reverseArray: true,
                                });

                                if (sorted) {
                                    updateSortBulk({
                                        projectId,
                                        ids: sorted?.map((item) => item.id),
                                    });
                                }
                            }}
                            isUpdatingSort={isUpdatingSort}
                        />
                    ) : (
                        <EmptyState>
                            <EmptyState.Title>{L('no_images')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('no_images_description')}</p>
                            </EmptyState.Description>
                        </EmptyState>
                    ))}
            </div>
            <Dropzone onUpload={onUpload} variant="Media" multiple={true} />
        </div>
    );
};

export default ProjectTemplateImages;
