import React, { FC } from 'react';
import { HTMLAttributes } from 'react';
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

const clickFlashCss = keyframes`
  50% {
    opacity:0.25
  }
`;

export const clickFlash = {
    animation: `${clickFlashCss} 0.2s linear`
};

const IconWrapperButton = styled.div({
    height: '32px',
    width: '32xp',
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    '&:active': clickFlash
});

const IconWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return (
        <IconWrapperButton role="button" {...rest}>
            {children}
        </IconWrapperButton>
    );
};

export default IconWrapper;
