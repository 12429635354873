import styled from '@emotion/styled';
import React, { FC } from 'react';

const StyledH1 = styled.h1({
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '128%',
    letterSpacing: '0.41px',
});
const H1: FC<React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>> = ({
    children,
    ...rest
}) => {
    return <StyledH1 {...rest}>{children}</StyledH1>;
};

export default H1;
