import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { getSettingsListByTypeKey } from './useGetSettingsListByType';
import { SettingsType } from '@app/api/models/Maintenances';

type Variables = {
    id: number;
    settingstype: SettingsType;
};

const deleteSettingsListByType = async (variables: Variables) => {
    switch (variables.settingstype) {
        case 'category':
            return MaintenancesApi.deleteCategory(variables.id);

        case 'status':
            return MaintenancesApi.deleteStatus(variables.id);

        case 'type':
            return MaintenancesApi.deleteType(variables.id);

        case 'unit':
            return MaintenancesApi.deleteUnit(variables.id);
    }
};

const useDeleteSettingsListByType = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => deleteSettingsListByType(variables), {
        onSuccess: (_data, variables) => {
            queryClient.invalidateQueries([getSettingsListByTypeKey, { settingstype: variables.settingstype }]);
        }
    });
};

export default useDeleteSettingsListByType;
