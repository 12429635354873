import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@app/shared/lib/getErrorMessage';
import {
    useAddContentOrgInformation,
    useCreateDraftContentOrgInformation,
} from '../../project-templates/hooks/ContentInformation/useAddContentInformation';
import ContentInformationForm from '../components/ContentInformation/ContentInformationForm';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { L } from '../../../lib/i18n';
import { z } from 'zod';

const AddContentInformationSchema = z.object({
    title: z.string().min(1, { message: 'required' }),
    description: z.string().min(1, { message: 'required' }),
    image: z.instanceof(File, { message: 'please_select_a_file' }).optional(),
    locked: z.boolean(),
});

const AddContentInformationPage = () => {
    const navigate = useNavigate();

    const { mutate: addContentInformationItem } = useAddContentOrgInformation();
    const { mutate: createDraft } = useCreateDraftContentOrgInformation();

    const onSubmit = (data: z.infer<typeof AddContentInformationSchema>, type: 'publish' | 'draft') => {
        if (type === 'publish') {
            addContentInformationItem(
                { information: data },
                {
                    onSuccess: ({ id: informationId }) => {
                        toast.success(L('content_information_added'));
                        navigate(`../${informationId}`);
                    },
                    onError: (err) => {
                        toast.error(L(getErrorMessage(err)));
                    },
                },
            );
        } else {
            createDraft(
                { information: data },
                {
                    onSuccess: ({ id: informationId }) => {
                        toast.success(L('content_information_added'));
                        navigate(`../${informationId}`);
                    },
                    onError: (err) => {
                        toast.error(L(getErrorMessage(err)));
                    },
                },
            );
        }
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_content_information')}</CardTitle>}
            mainArea={<ContentInformationForm onSubmit={onSubmit} />}
        />
    );
};

export default AddContentInformationPage;
