import styled from '@emotion/styled';
import React from 'react';
import ErrorAndInputWrapper, {
    InputWithError,
    TextAreaWithError,
} from '../../../components/Forms/ErrorAndInputWrapper';
import LabelRequired from '../../../components/Forms/LabelRequired';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import RadixSelect from '../../../components/RadixSelect/RadixSelect';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddProductOnPropertyTemplate, AddProductOnPropertyTemplateSchema } from '../schemas';

const Form = styled.form({
    paddingBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    ['& label']: {
        width: '100%',
    },
});

const InputGroupContainer = styled.div<{ oneFieldPerRow?: boolean }>(
    {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
        gap: 20,
        ['@media only screen and (max-width: 800px)']: {
            gap: 10,
            gridAutoFlow: 'row',
        },
    },
    (props) =>
        props.oneFieldPerRow
            ? {
                  gap: 0,
                  gridAutoFlow: 'row',
              }
            : {},
);

type InputOptions = {
    value: number;
    label: string;
};

type Props = {
    onSubmit: SubmitHandler<AddProductOnPropertyTemplate>;
    propertyId: number;
    options: {
        brands: InputOptions[];
        suppliers: InputOptions[];
        professions: InputOptions[];
        productTypes: InputOptions[];
        spaces: InputOptions[];
        package_type: InputOptions[];
    };
    defaultValues: Partial<AddProductOnPropertyTemplate>;
    oneFieldPerRow?: boolean;
};

const PropertyProductTemplateForm = ({
    onSubmit,
    options,
    propertyId,
    defaultValues,
    oneFieldPerRow = false,
}: Props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        clearErrors,
    } = useForm<AddProductOnPropertyTemplate>({
        resolver: zodResolver(AddProductOnPropertyTemplateSchema),
        defaultValues,
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.entity_subtype?.message ?? '')}>
                    <LabelRequired title={L('function')} required={true}>
                        <Controller
                            control={control}
                            name="entity_subtype"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    isError={!!errors.entity_subtype}
                                    value={value}
                                    onValueChange={(val) => {
                                        onChange(+val);
                                        const label = options.productTypes.find((item) => item.value === +val)?.label;
                                        if (label) {
                                            setValue('name', label);
                                            clearErrors('name');
                                        }
                                    }}
                                    options={options.productTypes}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
                <ErrorAndInputWrapper errorMsg={L(errors.name?.message ?? '')}>
                    <LabelRequired title={L('product_group')} required={true}>
                        <InputWithError isError={!!errors.name} {...register('name')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.product?.message ?? '')}>
                    <LabelRequired title={L('product')} error={errors['attr.product']} required={true}>
                        <InputWithError isError={!!errors.attr?.product} {...register('attr.product')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <ErrorAndInputWrapper errorMsg={L(errors.attr?.description?.message ?? '')}>
                <LabelRequired title={L('description')}>
                    <TextAreaWithError isError={!!errors.attr?.description} {...register('attr.description')} />
                </LabelRequired>
            </ErrorAndInputWrapper>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.color?.message ?? '')}>
                    <LabelRequired title={L('colors')}>
                        <InputWithError isError={!!errors.attr?.color} {...register('attr.color')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.brand_id?.message ?? '')}>
                    <LabelRequired title={L('brand')}>
                        <Controller
                            control={control}
                            name="brand_id"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    isError={!!errors.brand_id}
                                    value={value}
                                    onValueChange={(val) => onChange(+val)}
                                    options={options.brands}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors?.parent_id?.message ?? '')}>
                    <LabelRequired title={L('space')}>
                        <Controller
                            control={control}
                            name="parent_id"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    disabled={!propertyId}
                                    isError={!!errors?.parent_id}
                                    value={value}
                                    onValueChange={(val) => onChange(+val)}
                                    options={options.spaces}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.package_type?.message ?? '')}>
                    <LabelRequired title={L('choice')}>
                        <Controller
                            control={control}
                            name="package_type"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    isError={!!errors.package_type}
                                    value={value}
                                    onValueChange={onChange}
                                    options={options.package_type}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.supplier_id?.message ?? '')}>
                    <LabelRequired title={L('supplier')}>
                        <Controller
                            control={control}
                            name="supplier_id"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    isError={!!errors.supplier_id}
                                    value={value}
                                    onValueChange={(val) => onChange(+val)}
                                    options={options.suppliers}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.profession_id?.message ?? '')}>
                    <LabelRequired title={L('performer')}>
                        <Controller
                            control={control}
                            name="profession_id"
                            render={({ field: { onChange, value } }) => (
                                <RadixSelect
                                    isError={!!errors.profession_id}
                                    value={value}
                                    onValueChange={(val) => onChange(+val)}
                                    options={options.professions}
                                />
                            )}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.article_no?.message ?? '')}>
                    <LabelRequired title={L('article_number')}>
                        <InputWithError isError={!!errors.attr?.article_no} {...register('attr.article_no')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.rsk_no?.message ?? '')}>
                    <LabelRequired title={L('rsk_number')}>
                        <InputWithError isError={!!errors.attr?.rsk_no} {...register('attr.rsk_no')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.e_no?.message ?? '')}>
                    <LabelRequired title={L('e_number')}>
                        <InputWithError isError={!!errors.attr?.e_no} {...register('attr.e_no')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.serial_number?.message ?? '')}>
                    <LabelRequired title={L('serial_number')}>
                        <InputWithError isError={!!errors.attr?.serial_number} {...register('attr.serial_number')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.gtin_ean?.message ?? '')}>
                    <LabelRequired title={L('gtin_ean')}>
                        <InputWithError isError={!!errors.attr?.gtin_ean} {...register('attr.gtin_ean')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.pnc_number?.message ?? '')}>
                    <LabelRequired title={L('pnc_number')}>
                        <InputWithError isError={!!errors.attr?.pnc_number} {...register('attr.pnc_number')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.order_number?.message ?? '')}>
                    <LabelRequired title={L('order_number')}>
                        <InputWithError isError={!!errors.attr?.order_number} {...register('attr.order_number')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.order_no?.message ?? '')}>
                    <LabelRequired title={L('appointment_number')}>
                        <InputWithError isError={!!errors.attr?.order_no} {...register('attr.order_no')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <ErrorAndInputWrapper errorMsg={L(errors.attr?.service_advice?.message ?? '')}>
                <LabelRequired title={L('service_advice')}>
                    <TextAreaWithError isError={!!errors.attr?.service_advice} {...register('attr.service_advice')} />
                </LabelRequired>
            </ErrorAndInputWrapper>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.warranty_end_date?.message ?? '')}>
                    <LabelRequired title={L('warranty_end_date')}>
                        <InputWithError
                            isError={!!errors.attr?.warranty_end_date}
                            type="date"
                            {...register('attr.warranty_end_date')}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.purchase_price?.message ?? '')}>
                    <LabelRequired title={L('price')}>
                        <InputWithError
                            type="number"
                            isError={!!errors.attr?.purchase_price}
                            {...register('attr.purchase_price')}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.warranty?.message ?? '')}>
                    <LabelRequired title={L('warranty')}>
                        <Checkbox {...register('attr.warranty')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.is_private?.message ?? '')}>
                    <LabelRequired title={L('is_private')}>
                        <Checkbox {...register('attr.is_private')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>

            <InputGroupContainer oneFieldPerRow={oneFieldPerRow}>
                <ErrorAndInputWrapper errorMsg={L(errors.attr?.purchase_store?.message ?? '')}>
                    <LabelRequired title={L('purchase_point')}>
                        <InputWithError isError={!!errors.attr?.purchase_store} {...register('attr.purchase_store')} />
                    </LabelRequired>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.attr?.purchase_date?.message ?? '')}>
                    <LabelRequired title={L('purchase_date')}>
                        <InputWithError
                            isError={!!errors.attr?.purchase_date}
                            type="date"
                            {...register('attr.purchase_date')}
                        />
                    </LabelRequired>
                </ErrorAndInputWrapper>
            </InputGroupContainer>
            <Button type="submit" style={{ marginLeft: 'auto', marginTop: '1rem' }}>
                {L('save')}
            </Button>
        </Form>
    );
};

export default PropertyProductTemplateForm;
