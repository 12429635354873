import styled from '@emotion/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../edit-pen.svg';

const EditIcon = styled(Icon)(() => {
    return {
        height: '24px',
        width: '24px',
        '& path': {
            stroke: colors.icons,
        },
    };
});
const Edit: FC = ({ ...rest }) => {
    return <EditIcon {...rest} />;
};

export default Edit;
