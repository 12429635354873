import React from 'react';
import { L } from '../../../lib/i18n';
import Close from '../../../components/SliderMenu/Close/Close';
import NotificationHandler from '../notificationHandler/NotificationHandler';
import { animated, useSpring } from 'react-spring';
import { colors } from '../../../components/Shared/Style/colors';
import { NavLink } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';
import { useNotificationList } from '../hooks/notificationQueries';
import { Loading } from '../../../components/Loading/Loading';
import styled from '@emotion/styled';
import useHandleClickOutside from '../../../lib/hooks/useHandleClickOutside';
import H3 from '../../../components/typography/H3';

const ModalContainer = animated(
    styled.div({
        position: 'fixed',
        top: 0,
        right: 0,
        width: '380px',
        height: '100vh',
        overflowY: 'scroll',
        backgroundColor: '#F7F8FC',
        color: 'black',
        boxShadow: '0 0 35px gray',
        zIndex: 1,
        '& img': {
            width: '100%',
            maxWidth: '100%',
            objectFit: 'cover',
            backgroundPosition: 'center'
        },
        '& iframe': {
            width: '100%',
            maxWidth: '100%'
        }
    })
);

const ModalWrapper = styled.div({
    width: '90%',
    margin: '10px auto',
    display: 'flex',
    flexDirection: 'column'
});

const BellContainer = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '34px'
});

const StyledClose = styled(Close)({
    fontWeight: 100,
    top: 10,
    left: 10
});

const StyledNavLink = styled(NavLink)(({ size }: { size?: string }) => ({
    display: 'block',
    whiteSpace: 'nowrap',
    color: colors.primary,
    '&:hover': {
        color: colors.black
    }
}));

export default function NotificationModal({
    isOpenModal,
    handleCloseModal,
    to,
    size
}: {
    isOpenModal: boolean;
    handleCloseModal: () => void;
    to: LinkProps['to'];
    size?: string;
}) {
    const { data: notifications, isLoading } = useNotificationList('', 1, undefined, isOpenModal);
    const slideIn = useSpring({
        transform: isOpenModal ? 'translateX(0px)' : 'translateX(400px)',
        config: { friction: 30 }
    });

    const ref = React.useRef<HTMLDivElement>(null);
    useHandleClickOutside<HTMLDivElement>({ ref, clickOutsideFunc: handleCloseModal });

    if (isLoading || !isOpenModal) return <></>;
    return (
        <ModalContainer ref={ref} style={slideIn}>
            <StyledClose inverted onClose={() => handleCloseModal()} />
            <ModalWrapper>
                <BellContainer>
                    <H3>{L('my_notifications')}</H3>
                    <StyledNavLink
                        onClick={() => handleCloseModal()}
                        aria-label="notification bell"
                        size={size}
                        to={to}
                    >
                        {L('see_all')}
                    </StyledNavLink>
                </BellContainer>
                {notifications?.items ? <NotificationHandler items={notifications.items} /> : <Loading />}
            </ModalWrapper>
        </ModalContainer>
    );
}
