import React, { useState } from 'react';
import { Header } from '../../../components/Layout/Header';
import { AlertFilterPanel } from '../Filter/AlertFilterPanel';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { AlertFilter } from '@app/api/public/AlertsApi';
import { AlertCard } from './AlertCard';
import { style } from '@app/shared/lib/Style';
import { AlertDetail } from '../DetailPage/AlertDetail';
import { AlertDetailNew } from '../DetailPage/AlertDetailNew';
import { Link, Route, Switch } from 'react-router-dom';
import { useGetAlerts } from '../hooks/useAlertQueries';

const Menu = styled.div({
    display: 'flex',
    justifyContent: 'space-between'
});

const Wrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '340px 1fr',
    columnGap: '2rem'
});

const Filter = styled.div({
    flexGrow: 1
});

const Details = styled.div({
    flexGrow: 1
});

const Cards = styled.div({
    overflowY: 'auto',
    '&-webkit-scrollbar': { height: '12px', width: '8px', background: '#AFAFAF' },
    '&-webkit-scrollbar-thumb': { background: '#F0F1F7' },
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column'
});

export const AlertListPage = () => {
    const [filter, updateFilter] = useState<AlertFilter>({});
    const { data: alerts } = useGetAlerts(filter);

    const setFilter = (filter: AlertFilter) => {
        updateFilter({ ...filter, project: filter.project, properties: filter.properties });
    };

    return (
        <>
            <Header title={L('alerts')} />
            <Menu>
                <Filter>
                    <AlertFilterPanel filter={filter} onChange={setFilter} />
                </Filter>
                <Switch>
                    <Route exact path="/alerts/:id">
                        <Link to="/alerts">
                            <Button style={{ marginBottom: '12px' }}>{L('new_alert')}</Button>
                        </Link>
                    </Route>
                </Switch>
            </Menu>
            <Wrapper>
                <Cards>{alerts && alerts.map(alert => <AlertCard key={alert.id} alert={alert} />)}</Cards>
                <Details>
                    <Switch>
                        <Route exact path="/alerts">
                            <AlertDetailNew />
                        </Route>
                        <Route exact path="/alerts/:id">
                            <AlertDetail />
                        </Route>
                    </Switch>
                </Details>
            </Wrapper>
        </>
    );
};
