import React from 'react';
import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';
import DocumentItem from '../../../components/document/DocumentItem';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { p } from '../../../components/Shared/Style/typography';
import { useGetTicketMessages } from '../hooks/ticketQueries';
import formatChatDate from '../lib/formatChatDate';
import { useDocumentDetails } from '../../../lib/hooks/useGetDocumentDetail';

const StyledMessage = styled.div({
    ...p,
    display: 'grid',
    gap: '0.5rem',
    marginRight: '1rem',
    minWidth: '20ch',
    padding: '0.75rem',
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
    alignSelf: 'start',
    '&.me': {
        alignSelf: 'end',
        marginLeft: '1rem',
        marginRight: '0rem',
        backgroundColor: colors.myBubble
    },

    '&.collaborator': {
        backgroundColor: colors.collaboratorBubble
    }
});

const MessageHeader = styled.div({
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: '1ch'
});

const StyledDocument = styled(DocumentItem)({
    backgroundColor: colors.myBubble,
    borderRadius: style.roundCornerSize.small,
    padding: '0.5rem',
    '& p': {
        margin: '0'
    },

    '&.me': {
        backgroundColor: colors.white
    },
    '&.collaborator': {
        backgroundColor: colors.white
    }
});

const StyledTextMessage = styled.div({
    maxWidth: '100%',
    overflow: 'hidden',
    overflowWrap: 'break-word',
    whiteSpace: 'pre-wrap'
});
type MessageSender = 'me' | 'customer' | 'collaborator';

type MessageProps = {
    message: NonNullable<ReturnType<typeof useGetTicketMessages>['data']>[0];
    messageSender: MessageSender;
} & HTMLAttributes<HTMLDivElement>;
const Message = ({
    message: { text, created_name, updated_at, attachment_ids },
    messageSender,
    ...rest
}: MessageProps) => {
    const documentDetalQueries = useDocumentDetails(attachment_ids);
    const Documents = documentDetalQueries?.map(query => {
        const data = query.data;
        if (!data) return null;
        return <StyledDocument key={data.id} document={data} className={messageSender} />;
    });
    return (
        <StyledMessage {...rest} className={messageSender}>
            <MessageHeader>
                {messageSender !== 'me' && <span>{created_name}</span>}
                <span>{formatChatDate(updated_at)}</span>
            </MessageHeader>
            <StyledTextMessage>{text?.plain_text}</StyledTextMessage>
            {Documents}
        </StyledMessage>
    );
};

export default Message;
