import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import InventoryTemplatesPage from './pages/InventoryTemplatesPage';
import AddInventoryTemplatePage from './pages/AddInventoryTemplatePage';
import EditInventoryTemplatePage from './pages/EditInventoryTemplatePage';
import PackageProductsPage from './pages/PackageProductsPage';
import AddNewProductToTemplatePackage from '../copy-function/pages/AddNewProductToTemplatePackage';

export const InventoryLibrary = () => {
    return (
        <>
            <Routes>
                <Route path="inventory-library">
                    <Route index element={<InventoryTemplatesPage />} />
                    <Route path="new" element={<AddInventoryTemplatePage />} />
                    <Route path=":id">
                        <Route index element={<EditInventoryTemplatePage />} />
                        <Route path="package">
                            <Route index element={<PackageProductsPage />} />
                            <Route path="new">
                                <Route index element={<AddNewProductToTemplatePackage />} />
                            </Route>
                            <Route path=":packageItemId">
                                <Route index element={<EditInventoryTemplatePage />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
