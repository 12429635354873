import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timeZones from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/sv';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timeZones);
dayjs.tz.guess();
dayjs.locale('sv');

dayjs.updateLocale('sv', {
    relativeTime: {
        future: 'om %s',
        past: '%s sedan',
        s: '< 1 min',
        m: 'minut',
        mm: '%d minuter',
        h: 'en timme',
        hh: '%d timmar',
        d: 'en dag',
        dd: '%d dagar',
        M: 'en månad',
        MM: '%d månader',
        y: 'ett år',
        yy: '%d år',
    },
});

export function capitalize(value: string) {
    return value.toLowerCase().replace(/(^| )[a-z]/g, (v) => v.toUpperCase());
}

const datetimeFormat = new Intl.DateTimeFormat('sv-SE', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit',
});

const dateFormat = new Intl.DateTimeFormat('sv-SE', {
    weekday: 'long',
    day: '2-digit',
    month: 'long',
});

export function formatDateIsoDayjs(date?: Date | string | undefined | null) {
    return dayjs(date || undefined)
        .tz()
        .format('YYYY-MM-DD HH:mm');
}

export function formatDatetime(date: string | Date) {
    let value = 0;
    if (typeof date === 'string') {
        value = Date.parse(date);
    } else {
        value = +date;
    }
    return datetimeFormat.format(value);
}

export function formatDate(date: string | Date) {
    let value = 0;
    if (typeof date === 'string') {
        value = Date.parse(date);
    } else {
        value = +date;
    }
    return dateFormat.format(value);
}

export function formatCurrency(amount: number, isCurrency = true) {
    return new Intl.NumberFormat('sv-SE', {
        style: isCurrency ? 'currency' : 'decimal',
        currency: 'SEK',
        minimumFractionDigits: 0,
    }).format(+amount);
}

export function formatInputDateTimeDayjs(date?: Date | string | undefined | dayjs.Dayjs | null) {
    if (!dayjs(date).isValid()) return '';
    return dayjs(date).tz().format('YYYY-MM-DDTHH:mm');
}
