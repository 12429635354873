import * as React from 'react';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import ReactPasswordStrength from 'react-password-strength';
import { useEffect, useState } from 'react';
import Label from '../../../components/Forms/Label';
import { Button } from '@ourliving/ourliving-ui';
import useChangePassword from '../hooks/useChangePassword';
import Spinner from '../../../components/Spinner/Spinner';
import { useRef } from 'react';

import H2 from '../../../components/typography/H2';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';

const LoginInput = styled.input({
    width: '100%',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const ErrorMessage = styled.strong({
    color: 'red'
});

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const SuccessMessage = styled.strong({
    color: style.colors.primary
});

const LoginForm = styled.div({});

const StyledReactPasswordStrength = styled(ReactPasswordStrength)({
    background: style.colors.inputBackground,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    border: `1px solid ${style.colors.inputBorder}`,
    outline: 'none',
    fontSize: style.fontSize.seta,
    lineHeight: style.lineHeight.normal,
    letterSpacing: '0.3px',
    fontFamily: 'DM sans',
    '.ReactPasswordStrength-strength-desc': {
        width: '30%'
    },
    '.ReactPasswordStrength-input': {
        padding: `${style.margin.s}px`,
        fontSize: style.fontSize.seta,
        height: '39px',
        width: '100%',
        letterSpacing: '0.3px',
        fontFamily: 'DM sans'
    }
});

export type PasswordState =
    | ''
    | 'expired'
    | 'no_exists'
    | 'no_valid_token'
    | 'error'
    | 'password_too_weak'
    | 'password_changed'
    | 'password_empty'
    | 'password_not_matched';

export type ChangeCallback = {
    password: string;
    isValid: boolean;
};

const StyledH2 = styled(H2)({});

export const ChangePasswordProfilePage = () => {
    const [state, setState] = useState<PasswordState>('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState<ChangeCallback | undefined>();
    const [newRepeatPassword, setNewRepeatPassword] = useState<ChangeCallback | undefined>();
    const [isValid, setIsValid] = useState(false);
    const newPasswordRef = useRef<{ clear: () => void }>();
    const newRepeatPasswordRef = useRef<{ clear: () => void }>();

    const { mutate: changePassword, status: changePasswordStatus, reset } = useChangePassword();

    const handleUpdatePassword = async () => {
        setState('');
        if (!newPassword || !newRepeatPassword) return;
        if (newPassword.password !== newRepeatPassword.password) {
            return setState('password_not_matched');
        }
        changePassword(
            { oldPassword, newPassword: newPassword.password, newRepeatPassword: newRepeatPassword.password },
            {
                onSuccess: () => {
                    setOldPassword('');
                    newPasswordRef?.current?.clear();
                    setNewPassword(undefined);
                    newRepeatPasswordRef?.current?.clear();
                    setNewRepeatPassword(undefined);
                    toast.success(L('change_password_success'));
                },
                onSettled: () => {
                    reset();
                },
                onError: () => {
                    toast.error(L('change_password_error'));
                }
            }
        );
    };

    useEffect(() => {
        setState('');
        if (!newPassword?.isValid) {
            return setIsValid(false);
        }
        if (!newRepeatPassword?.isValid) {
            return setIsValid(false);
        }
        if (!oldPassword) {
            return setIsValid(false);
        }

        if (newPassword.password !== newRepeatPassword.password) {
            setState('password_not_matched');
            return setIsValid(false);
        }

        setIsValid(true);
    }, [oldPassword, newPassword, newRepeatPassword]);

    return (
        <>
            <StyledH2>{L('change_password')}</StyledH2>
            <LoginForm>
                {state && state !== 'password_changed' && (
                    <Error>
                        <ErrorMessage>{L('reset_' + state)}</ErrorMessage>
                    </Error>
                )}
                {state === 'password_changed' && (
                    <Sucess>
                        <SuccessMessage>{L('reset_password_changed_logged_in')}</SuccessMessage>
                    </Sucess>
                )}
                <Label title={L('old_password')}>
                    <LoginInput id="password" type="password" onChange={e => setOldPassword(e.target.value)} />
                </Label>

                <Label title={L('new_password')}>
                    <StyledReactPasswordStrength
                        style={{
                            border: `1px solid ${style.colors.inputBorder}`
                        }}
                        minLength={5}
                        minScore={2}
                        ref={newPasswordRef}
                        tooShortWord={L('password_short')}
                        changeCallback={e => setNewPassword(e)}
                        scoreWords={[
                            L('password_not_ok'),
                            L('password_not_ok'),
                            L('password_ok'),
                            L('password_strong'),
                            L('password_great')
                        ]}
                        inputProps={{ autoComplete: 'off', placeholder: [L('password_required_length')] }}
                    />
                </Label>
                <Label title={L('new_password')}>
                    <StyledReactPasswordStrength
                        style={{
                            border: `1px solid ${style.colors.inputBorder}`
                        }}
                        ref={newRepeatPasswordRef}
                        minLength={6}
                        minScore={2}
                        tooShortWord={L('password_short')}
                        scoreWords={[
                            L('password_not_ok'),
                            L('password_not_ok'),
                            L('password_ok'),
                            L('password_strong'),
                            L('password_great')
                        ]}
                        changeCallback={e => setNewRepeatPassword(e)}
                        inputProps={{
                            autoComplete: 'off',
                            placeholder: [L('password_required_length')]
                        }}
                    />
                </Label>

                <div style={{ display: 'grid', width: '100%', marginTop: '1rem' }}>
                    {changePasswordStatus === 'idle' && (
                        <Button
                            onClick={handleUpdatePassword}
                            style={{ marginRight: '0px', justifySelf: 'end' }}
                            disabled={!isValid}
                        >
                            {state ? L(state) : L('change_password')}
                        </Button>
                    )}
                    {changePasswordStatus === 'loading' && (
                        <Button
                            onClick={handleUpdatePassword}
                            style={{
                                marginRight: '0px',
                                justifySelf: 'end',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                            disabled={!isValid}
                        >
                            <Spinner />
                        </Button>
                    )}
                    {changePasswordStatus === 'success' && (
                        <Button
                            onClick={handleUpdatePassword}
                            style={{ marginRight: '0px', justifySelf: 'end' }}
                            disabled={!isValid}
                        >
                            {L('change_password_success')}
                        </Button>
                    )}
                    {changePasswordStatus === 'error' && (
                        <Button
                            onClick={handleUpdatePassword}
                            style={{ marginRight: '0px', justifySelf: 'end' }}
                            disabled={!isValid}
                        >
                            {L('error')}
                        </Button>
                    )}
                </div>
            </LoginForm>
        </>
    );
};
