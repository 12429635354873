import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyMemberKeys } from './propertyMemberQueries';

export default function useUpdateMemberOnProperty() {
    const client = useQueryClient();
    return useMutation(
        async (params: { email: string; propertyId: number; membershipId: number }) => {
            return trpc.MembersApi.updateUserOnProperty.mutate(params);
        },
        {
            onSettled: (_data, _error, variables) => {
                client.invalidateQueries(getPropertyMemberKeys.list(variables.propertyId));
                client.invalidateQueries(
                    getPropertyMemberKeys.detail({ id: variables.membershipId, propertyId: variables.propertyId }),
                );
            },
        },
    );
}
