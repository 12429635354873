import React from 'react';
import { PropertyAddonsSummarize } from './PropertyAddonsSummarize';
import { PropertyBanner } from './PropertyBanner';
import { PropertyHeader } from './PropertyHeader';
import { PropertyInfo } from './PropertyInformation';
import { PropertyOrderInfo } from './PropertyOrderInfo';
import { PropertyUsers } from './PropertyUsers';

export const PropertyList = ({ headerInfo }) => {
    return (
        <>
            <PropertyBanner image={headerInfo?.property_image} />
            <PropertyHeader
                title={'Orderbekräftelse inredningsval'}
                project_name={headerInfo?.project_name}
                property_name={headerInfo?.property_name}
            />
            <PropertyInfo
                project={headerInfo?.project_name}
                PropertyTitle={'Bostadsinformation'}
                property_type={headerInfo?.property_info?.property_type}
                living_space={headerInfo?.property_info?.property_area}
                no_rooms={headerInfo?.property_info?.rooms}
                property={headerInfo?.property_name}
                project_logo={headerInfo?.project_logo}
                project_info={headerInfo?.project_info}
            />

            <PropertyUsers title={'Köpare'} users={headerInfo?.users} />
            <PropertyOrderInfo date={headerInfo?.order_date} price={headerInfo?.total} />
            <PropertyAddonsSummarize title={'Sammanställning av inredningsval'} price={headerInfo?.total} />
        </>
    );
};
