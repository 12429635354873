Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["id"] = b.createRpcProxy("MaintenancesApi", "id", {
  "urlPrefix": "/rpc"
});
o["list"] = b.createRpcProxy("MaintenancesApi", "list", {
  "urlPrefix": "/rpc"
});
o["saveCategory"] = b.createRpcProxy("MaintenancesApi", "saveCategory", {
  "urlPrefix": "/rpc"
});
o["saveStatus"] = b.createRpcProxy("MaintenancesApi", "saveStatus", {
  "urlPrefix": "/rpc"
});
o["saveUnit"] = b.createRpcProxy("MaintenancesApi", "saveUnit", {
  "urlPrefix": "/rpc"
});
o["saveType"] = b.createRpcProxy("MaintenancesApi", "saveType", {
  "urlPrefix": "/rpc"
});
o["listAnalyticPlannedCompletedCostByYear"] = b.createRpcProxy("MaintenancesApi", "listAnalyticPlannedCompletedCostByYear", {
  "urlPrefix": "/rpc"
});
o["listCategories"] = b.createRpcProxy("MaintenancesApi", "listCategories", {
  "urlPrefix": "/rpc"
});
o["listStatus"] = b.createRpcProxy("MaintenancesApi", "listStatus", {
  "urlPrefix": "/rpc"
});
o["listTypes"] = b.createRpcProxy("MaintenancesApi", "listTypes", {
  "urlPrefix": "/rpc"
});
o["listUnits"] = b.createRpcProxy("MaintenancesApi", "listUnits", {
  "urlPrefix": "/rpc"
});
o["deleteCategory"] = b.createRpcProxy("MaintenancesApi", "deleteCategory", {
  "urlPrefix": "/rpc"
});
o["deleteType"] = b.createRpcProxy("MaintenancesApi", "deleteType", {
  "urlPrefix": "/rpc"
});
o["deleteStatus"] = b.createRpcProxy("MaintenancesApi", "deleteStatus", {
  "urlPrefix": "/rpc"
});
o["deleteUnit"] = b.createRpcProxy("MaintenancesApi", "deleteUnit", {
  "urlPrefix": "/rpc"
});
o["save"] = b.createRpcProxy("MaintenancesApi", "save", {
  "urlPrefix": "/rpc"
});
o["delete"] = b.createRpcProxy("MaintenancesApi", "delete", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = b.createRpcProxy("MaintenancesApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = b.createRpcProxy("MaintenancesApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = b.createRpcProxy("MaintenancesApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromMaintenance"] = b.createRpcProxy("MaintenancesApi", "deleteDocumentFromMaintenance", {
  "urlPrefix": "/rpc"
});
o["listServicePartners"] = b.createRpcProxy("MaintenancesApi", "listServicePartners", {
  "urlPrefix": "/rpc"
});