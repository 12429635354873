import styled from '@emotion/styled';
import React from 'react';
import { colors } from '../Shared/Style/colors';
import Input from './Input';

const Value = styled.div<{ fontSize: string }>(({ fontSize }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.inputBackground,
    color: 'black',
    borderBottomRightRadius: '4px',
    borderTopRightRadius: '4px',

    border: `1px solid ${colors.inputBorder}`,
    fontSize: fontSize,
    '&[aria-disabled=true]': {
        cursor: 'default',
        color: colors.inputPlaceholderColor,
        backgroundColor: colors.inputDisabled
    }
}));

const ValueWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 50px'
});

const StyledValueInput = styled(Input)({
    borderRadius: '4px 0 0 4px',
    height: '32px',
    borderRight: 0
});

type Props = {
    splitInput: any;
    fontSize?: string;
};

const InputSplit = ({
    className,
    splitInput,
    fontSize,
    ...rest
}: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <ValueWrapper className={className}>
            <StyledValueInput {...rest} />
            <Value aria-disabled={rest.readOnly} fontSize={fontSize || 'inherit'}>
                {splitInput}
            </Value>
        </ValueWrapper>
    );
};

export default InputSplit;
