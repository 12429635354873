import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import EditPen from '../../../../components/Icon/pen-2.svg';

const StyledIcon = styled(EditPen)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '12px',
    width: '12px',
    fill: style.colors.primary
});

export default function EditPenComponent(props: ComponentProps<'svg'>) {
    return <StyledIcon {...props} />;
}
