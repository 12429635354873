Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["listProducts"] = b.createRpcProxy("InventoryApi", "listProducts", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = b.createRpcProxy("InventoryApi", "listProperties", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listProjects"] = b.createRpcProxy("InventoryApi", "listProjects", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listSpaces"] = b.createRpcProxy("InventoryApi", "listSpaces", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listInventoryFilter"] = b.createRpcProxy("InventoryApi", "listInventoryFilter", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["insertInventoryFilter"] = b.createRpcProxy("InventoryApi", "insertInventoryFilter", {
  "urlPrefix": "/rpc"
});
o["deleteInventoryFilter"] = b.createRpcProxy("InventoryApi", "deleteInventoryFilter", {
  "urlPrefix": "/rpc"
});
o["updateProductInfo"] = b.createRpcProxy("InventoryApi", "updateProductInfo", {
  "urlPrefix": "/rpc"
});
o["saveInventory"] = b.createRpcProxy("InventoryApi", "saveInventory", {
  "urlPrefix": "/rpc"
});
o["deleteInventory"] = b.createRpcProxy("InventoryApi", "deleteInventory", {
  "urlPrefix": "/rpc"
});
o["getJsonFromOcr"] = b.createRpcProxy("InventoryApi", "getJsonFromOcr", {
  "urlPrefix": "/rpc"
});
o["uploadTypePlate"] = b.createRpcProxy("InventoryApi", "uploadTypePlate", {
  "urlPrefix": "/rpc"
});