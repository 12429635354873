import { BinderProperties } from '@app/trpc-api/models/Binders';
import styled from '@emotion/styled';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { L } from '../../../../lib/i18n';
import dayjs from 'dayjs';
import { Button } from '@ourliving/ourliving-ui';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'black',
    fontWeight: 'bolder',
    '&:hover': {
        textDecoration: 'underline',
    },
});

export type PropertyColumnsTableMeta = {
    handleTemplateBtnClick: (id: number) => void;
};

const columnHelper = createColumnHelper<BinderProperties>();

export default function propertyColumns() {
    return useMemo(
        () => [
            columnHelper.display({
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                meta: {
                    styles: {
                        flexShrink: 0,
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${location.pathname}/${info.row.original.id}/spaces`}>
                            <Data>{info.getValue()}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.apartment_number, {
                id: 'apartment_number',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${location.pathname}/${info.row.original.id}/spaces`}>
                            <Data>{`${info.row.original?.apartment_number}`}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('apartment_number')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),

            columnHelper.accessor((row) => row.address?.street, {
                id: 'street',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.address?.street ?? '-'}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('street')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.space_count, {
                id: 'space_count',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${location.pathname}/${info.row.original.id}/spaces`}>
                            <Data>{`${info.row.original?.space_count} st`}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('spaces')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.product_count, {
                id: 'product_count',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${location.pathname}/${info.row.original.id}/products`}>
                            <Data>{`${info.row.original?.product_count} st`}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('products')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.member_count, {
                id: 'member_count',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${location.pathname}/${info.row.original.id}/members`}>
                            <Data>{`${info.row.original.member_count} st`}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('members')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),

            columnHelper.accessor((row) => row.access_date, {
                id: 'access_date',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.access_date ?? '-'}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('access_date')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),

            columnHelper.accessor((row) => row.published_at, {
                id: 'published_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>
                            {info.row.original.published_at
                                ? dayjs(info.row.original.published_at).format('YYYY-MM-DD HH:mm')
                                : ''}
                        </Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('published')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.display({
                id: 'create_template',
                cell: ({ table, row }) => (
                    <CellContainer>
                        <div>
                            <Button
                                onClick={() =>
                                    (table.options.meta as PropertyColumnsTableMeta).handleTemplateBtnClick(
                                        row.original.id,
                                    )
                                }
                            >
                                {L('create_template')}
                            </Button>
                        </div>
                    </CellContainer>
                ),
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
                enableResizing: false,
            }),
        ],
        [],
    );
}
