Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getDashboardInfo"] = b.createRpcProxy("TicketsApi", "getDashboardInfo", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getInfiniteList"] = b.createRpcProxy("TicketsApi", "getInfiniteList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getTicketListCsv"] = b.createRpcProxy("TicketsApi", "getTicketListCsv", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["archiveTicket"] = b.createRpcProxy("TicketsApi", "archiveTicket", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getArchive"] = b.createRpcProxy("TicketsApi", "getArchive", {
  "urlPrefix": "/rpc"
});
o["getArchivePaginated"] = b.createRpcProxy("TicketsApi", "getArchivePaginated", {
  "urlPrefix": "/rpc"
});
o["getbyid"] = b.createRpcProxy("TicketsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["getEvents"] = b.createRpcProxy("TicketsApi", "getEvents", {
  "urlPrefix": "/rpc"
});
o["markAsRead"] = b.createRpcProxy("TicketsApi", "markAsRead", {
  "urlPrefix": "/rpc"
});
o["saveEvent"] = b.createRpcProxy("TicketsApi", "saveEvent", {
  "urlPrefix": "/rpc"
});
o["addAttachmentsToTicket"] = b.createRpcProxy("TicketsApi", "addAttachmentsToTicket", {
  "urlPrefix": "/rpc"
});
o["setLastReadThread"] = b.createRpcProxy("TicketsApi", "setLastReadThread", {
  "urlPrefix": "/rpc"
});
o["createNewTicket"] = b.createRpcProxy("TicketsApi", "createNewTicket", {
  "urlPrefix": "/rpc"
});
o["saveTicket"] = b.createRpcProxy("TicketsApi", "saveTicket", {
  "urlPrefix": "/rpc"
});
o["getAssignees"] = b.createRpcProxy("TicketsApi", "getAssignees", {
  "urlPrefix": "/rpc"
});
o["getProperties"] = b.createRpcProxy("TicketsApi", "getProperties", {
  "urlPrefix": "/rpc"
});
o["getSpacesForProperty"] = b.createRpcProxy("TicketsApi", "getSpacesForProperty", {
  "urlPrefix": "/rpc"
});
o["getInventories"] = b.createRpcProxy("TicketsApi", "getInventories", {
  "urlPrefix": "/rpc"
});
o["getMessages"] = b.createRpcProxy("TicketsApi", "getMessages", {
  "urlPrefix": "/rpc"
});
o["addAttachemntsToMessage"] = b.createRpcProxy("TicketsApi", "addAttachemntsToMessage", {
  "urlPrefix": "/rpc"
});
o["saveMessage"] = b.createRpcProxy("TicketsApi", "saveMessage", {
  "urlPrefix": "/rpc"
});
o["getNotes"] = b.createRpcProxy("TicketsApi", "getNotes", {
  "urlPrefix": "/rpc"
});
o["removeNote"] = b.createRpcProxy("TicketsApi", "removeNote", {
  "urlPrefix": "/rpc"
});
o["addAttachemntsToNote"] = b.createRpcProxy("TicketsApi", "addAttachemntsToNote", {
  "urlPrefix": "/rpc"
});
o["saveNote"] = b.createRpcProxy("TicketsApi", "saveNote", {
  "urlPrefix": "/rpc"
});
o["sendTicketAsMail"] = b.createRpcProxy("TicketsApi", "sendTicketAsMail", {
  "urlPrefix": "/rpc"
});
o["previewMail"] = b.createRpcProxy("TicketsApi", "previewMail", {
  "urlPrefix": "/rpc"
});
o["getSettingsByType"] = b.createRpcProxy("TicketsApi", "getSettingsByType", {
  "urlPrefix": "/rpc"
});
o["saveSettingByType"] = b.createRpcProxy("TicketsApi", "saveSettingByType", {
  "urlPrefix": "/rpc"
});
o["removeSettingByType"] = b.createRpcProxy("TicketsApi", "removeSettingByType", {
  "urlPrefix": "/rpc"
});
o["getResponseText"] = b.createRpcProxy("TicketsApi", "getResponseText", {
  "urlPrefix": "/rpc"
});
o["saveResponseText"] = b.createRpcProxy("TicketsApi", "saveResponseText", {
  "urlPrefix": "/rpc"
});
o["getInfoText"] = b.createRpcProxy("TicketsApi", "getInfoText", {
  "urlPrefix": "/rpc"
});
o["saveInfoText"] = b.createRpcProxy("TicketsApi", "saveInfoText", {
  "urlPrefix": "/rpc"
});
o["listTicketFilters"] = b.createRpcProxy("TicketsApi", "listTicketFilters", {
  "urlPrefix": "/rpc"
});