import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import SpaceForm from '../../components/Spaces/SpaceForm';
import { useGetSpaceTypes } from '../../hooks/spaceHooks/spaceQueries';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { SubmitHandler } from 'react-hook-form';
import { AddSpace } from '../../schemas';
import useSaveSpace from '../../hooks/spaceHooks/useSaveSpace';
import toast from 'react-hot-toast';
import H1 from '../../../../components/typography/H1';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';

const AddSpaceComponent = ({ projectId, propertyId }: { projectId: string; propertyId: string }) => {
    const { data: spaceTypes } = useGetSpaceTypes();
    const spaceTypeOptions = useMemo(
        () =>
            spaceTypes?.map((type) => {
                return {
                    value: type.id,
                    label: L(type.name),
                };
            }) || [],
        [spaceTypes],
    );

    const { mutate: saveSpace } = useSaveSpace();

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<AddSpace> = (data) => {
        saveSpace(
            { data, propertyId: +propertyId },
            {
                onSuccess: (variables) => {
                    toast.success(L('added_space'));
                    if (variables.id) navigate(`../${variables.id}`);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../../..'}>{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('new_space')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <CardWithDivider
                topArea={<CardTitle>{L('add_space')}</CardTitle>}
                mainArea={<SpaceForm onSubmit={onSubmit} spaceTypeOptions={spaceTypeOptions} />}
            />
        </>
    );
};

export default function AddSpacePage() {
    const { id: projectId, propertyId } = useParams<{ id: string; propertyId: string }>();
    return projectId && propertyId ? <AddSpaceComponent projectId={projectId} propertyId={propertyId} /> : null;
}
