import React from 'react';
import { Route } from 'react-router-dom';
import { CalendarListPage } from './ListPage/CalendarListPage';
import { CalendarDetailPage } from './DetailPage/CalendarDetailPage';

export const routes = [
    <Route key="0" exact path="/calendar" component={CalendarListPage} />,
    <Route key="1" exact path="/calendar/:id" component={CalendarDetailPage} />
];

export const navigation = [{ name: 'Kalender', link: '/calendar', icon: 'Calendar' }];
