import React from 'react';
import ChosenFilter from './ChosenFilter';
import { Filters, SelectOption } from '../../DataType';
import OptionContainer from '../emotion/Filters/OptionContainer';
import CategoryContainer from '../emotion/Filters/CategoryContainer';
export default function ChosenFilters({
    inputs,
    optionMetadata,
    onRemove,
}: {
    inputs: { [key: string]: SelectOption[] };
    optionMetadata: { name: string; initialTexts: string[]; api: string }[];
    onRemove: (indexor: string, value: string) => void;
}) {
    return (
        <OptionContainer>
            {optionMetadata.map((option, index) => (
                <div key={index}>
                    {inputs[option.name]?.length > 0 && (
                        <CategoryContainer>
                            <ChosenFilter
                                initialTexts={option.initialTexts}
                                values={inputs[option.name].map((option: Filters['function'][0]) => option)}
                                onRemove={(value) => onRemove(option.name, value)}
                            />
                        </CategoryContainer>
                    )}
                </div>
            ))}
        </OptionContainer>
    );
}
