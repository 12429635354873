import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import { colors } from '../Shared/Style/colors';

const StyledInputField = styled.input(() => ({
    color: `${colors.inputColor}`,
    border: `1px solid ${colors.inputBorder}`,
    width: '100%',
    height: '32px',
    backgroundColor: colors.inputBackground,
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: '3px 10px 2px 10px',
    fontSize: '80%',
    '&::placeholder': {
        color: `${colors.inputPlaceholderColor}`,
        fontSize: '0.8rem'
    },
    '&:read-only': {
        color: `${colors.inputPlaceholderColor}`,
        cursor: 'default',
        backgroundColor: colors.inputDisabled
    }
}));

const Input = forwardRef<HTMLInputElement, ComponentPropsWithoutRef<'input'>>(({ ...rest }, ref) => {
    return <StyledInputField ref={ref} {...rest} />;
});

export default Input;
