import styled from '@emotion/styled';
import React, { FC, HTMLAttributes } from 'react';
import { colors } from '../Shared/Style/colors';

const StyledH3 = styled.h3<{ color?: any }>(props => {
    return {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1rem',
        lineHeight: '19px',
        color: (props.color && colors[props.color]) || colors.textColor2
    };
});

type Props = {
    color?: any;
};
const H3: FC<Props & HTMLAttributes<HTMLHeadingElement>> = ({ children, color, ...rest }) => {
    return (
        <StyledH3 color={color} {...rest}>
            {children}
        </StyledH3>
    );
};

export default H3;
