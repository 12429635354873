import styled from '@emotion/styled';
import React, { FC } from 'react';
type Props = {
    description: string;
    isCollapsed?: boolean;
    className?: string;
};

const Description = styled.p({
    maxHeight: '3.6em',
    lineHeight: '1.8em',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '13px',
    fontWeight: 400,
    gridArea: 'description',
    placeSelf: 'start',
    margin: '0px',
    maxWidth: '100%'
});

const maxTextLength = 178;

const NoticeDescription: FC<Props> = ({ description }) => {
    const partialText =
        description?.length > maxTextLength ? `${description.substr(0, maxTextLength)}... ` : description;
    return <Description>{partialText}</Description>;
};

export default NoticeDescription;
