import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQueries, useQuery } from 'react-query';

const documentDetailQueryKey = {
    all: ['documentDetail'],
    byId: (id?: string) => [...documentDetailQueryKey.all, { id }],
};

const documentDetail = async (id?: string) => {
    if (!id) return;
    return DocumentsApi.getbyid(id.toString());
};

const useDocumentDetail = (id?: string) => {
    return useQuery([documentDetailQueryKey.byId()], () => documentDetail(), {
        enabled: !!id,
    });
};

const useDocumentDetails = (ids?: string[]) => {
    return useQueries(
        ids?.map((id) => ({
            queryKey: documentDetailQueryKey.byId(id),
            queryFn: () => documentDetail(id),
        })) || []
    );
};

export { useDocumentDetail, useDocumentDetails };
