import React from 'react';
import { Header } from '../../../components/Layout/Header';
import { Content } from '../../../components/Layout/Content';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { NewsDetailEditor } from './NewsDetailEditor';
import { RouteComponentProps } from 'react-router';

interface Props {
    id?: string;
}

const Container = styled.div({});

export const NewsDetailPage = (props: RouteComponentProps<{ id: string }>) => {
    const id = props.match.params.id;

    return (
        <>
            <Header title={L('news')} to={'./'} text={`<-` + L('back_to_news')} />
            <Content>
                <div>
                    <Container>{id === 'new' ? <NewsDetailEditor /> : <NewsDetailEditor id={id} />}</Container>
                </div>
            </Content>
        </>
    );
};
