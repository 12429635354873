import { useQueryClient, useMutation } from 'react-query';
import ServicePartnerApi from '@app/api/public/ServicePartnersApi';
import { servicePartnerDocumentsListQueryKey } from './useGetServicePartnerDocumentsById';

type Variables = { itemId: string; name: string; file: File };

export const SaveServicePartnerDocuments = async (itemId: number, name: string, file: File) => {
    return ServicePartnerApi.saveDocument(itemId, name, file);
};

const useSaveServicePartnerDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Variables) => SaveServicePartnerDocuments(+variables.itemId, variables.name, variables.file),
        {
            onSettled: (_data, _error, variables) => {
                queryClient.invalidateQueries([servicePartnerDocumentsListQueryKey]);
            }
        }
    );
};

// export const saveEditedDocuments = async (document) => {
//     return await ServicePartnerApi.saveEditDocument(document);
// };

export default useSaveServicePartnerDocuments;
