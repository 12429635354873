import { SortOptions } from '@app/api/models/Binders';
import { ProductFilter, PropertyProductFilter } from '@app/trpc-api/models/Binders';
import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../lib/trpc';
import { RouterInputs } from '@app/trpc-api';

export const getProductsKeys = {
    all: ['binders-products'] as const,
    lists: () => [...getProductsKeys.all, 'list'] as const,
    infiniteLists: () => [...getProductsKeys.lists(), 'infinite'] as const,
    list: (params: {
        projectId: number;
        filters?: ProductFilter;
        sortOptions?: SortOptions;
        page?: number;
        rowCount?: number;
        search: string;
    }) => [...getProductsKeys.lists(), params] as const,
    infiniteList: ({
        projectId,
        propertyId,
        filters,
        sortOptions,
        page,
        rowCount,
        search,
    }: {
        projectId?: number;
        propertyId?: number;
        search: string;
        filters?: ProductFilter;
        sortOptions?: SortOptions;
        page?: number;
        rowCount?: number;
    }) =>
        [
            ...getProductsKeys.infiniteLists(),
            { projectId, propertyId, filters, sortOptions, page, rowCount, search },
        ] as const,
    spaceList: (params: RouterInputs['BindersApi']['getSpaceProductsList']) =>
        [...getProductsKeys.lists(), 'space', params] as const,
    productsInPackage: (params: RouterInputs['ProductsApi']['listProductsOnProduct']) =>
        [...getProductsKeys.lists(), 'productsInPackage', params] as const,
    infiniteTemplateList: ({
        filters,
        sortOptions,
        page,
        rowCount,
        search,
        accountIds,
    }: {
        search: string;
        filters?: ProductFilter;
        sortOptions?: SortOptions;
        page?: number;
        rowCount?: number;
        accountIds: number[];
    }) =>
        [
            ...getProductsKeys.infiniteLists(),
            'templates',
            { filters, sortOptions, page, rowCount, search, accountIds },
        ] as const,
    byIds: (productIds: number[]) => [...getProductsKeys.all, 'byIds', { productIds }] as const,
    details: () => [...getProductsKeys.all, 'detail'] as const,
    detail: (params: { productId: number }) => [...getProductsKeys.details(), params],
    documents: () => [...getProductsKeys.all, 'documents'] as const,
    documentsByProductId: (productId: number) => [...getProductsKeys.documents(), productId] as const,
    imagesByProductId: (productId: number) => [...getProductsKeys.documents(), 'images', productId] as const,
    filters: () => [...getProductsKeys.all, 'filter'],
    filter: (projectId?: number) => [...getProductsKeys.filters(), projectId],
    spaceFilters: (spaceId?: number) => [...getProductsKeys.filters(), 'space', spaceId],
    productNames: () => [...getProductsKeys.details(), 'productName'] as const,
    productName: (productId: number | null) => [...getProductsKeys.productNames(), productId] as const,
};

export const getProductFilterKeys = {
    all: ['binders-products-filter'] as const,
    lists: () => [...getProductFilterKeys.all, 'list'] as const,
    list: (params: { propertyId: number }) => [...getProductFilterKeys.lists(), params],
    templateList: (accountIds: number[]) => [...getProductFilterKeys.lists(), 'template', accountIds],
};

export function useGetPropertyProductList({
    propertyId,
    filters,
    sortOptions,
    search,
}: {
    propertyId: number;
    filters: PropertyProductFilter;
    sortOptions: SortOptions;
    search: string;
}) {
    return useInfiniteQuery(
        getProductsKeys.infiniteList({ propertyId, filters, sortOptions, search }),
        async ({ pageParam = 1 }) =>
            trpc.BindersApi.getPropertyProductsListPaginated.query({
                propertyId,
                filter: filters,
                sortType: sortOptions,
                page: pageParam,
                search,
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useGetSpaceProductList(params: RouterInputs['BindersApi']['getSpaceProductsList']) {
    return useQuery(
        getProductsKeys.spaceList(params),
        async () => trpc.BindersApi.getSpaceProductsList.query({ ...params }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetSpaceProductsFilters(spaceId?: number) {
    return useQuery(
        getProductsKeys.spaceFilters(spaceId),
        () => {
            if (!spaceId) {
                return Promise.reject(new Error('no space id provided'));
            }
            return trpc.BindersApi.getSpaceProductsFilters.query({ entityId: spaceId });
        },
        {
            enabled: !!spaceId,
        },
    );
}

export function useGetProductList({
    projectId,
    filters,
    sortOptions,
    search,
    rowCount,
    page,
}: {
    projectId: number;
    filters: ProductFilter;
    sortOptions: SortOptions;
    search: string;
    rowCount: number;
    page: number;
}) {
    return useQuery(
        getProductsKeys.list({ projectId, filters, sortOptions, search, page, rowCount }),
        async () =>
            trpc.BindersApi.getProductsListPaginated.query({
                projectId,
                filter: filters,
                sortType: sortOptions,
                page,
                search,
                rowCount,
            }),
        {
            keepPreviousData: true,
            enabled: !!projectId,
        },
    );
}

export function useGetProductsListFilters(projectId?: number) {
    return useQuery(
        getProductsKeys.filter(projectId),
        () => {
            if (!projectId) {
                return Promise.reject(new Error('no project id provided'));
            }
            return trpc.BindersApi.getProductsListFilters.query({ projectId });
        },
        {
            enabled: !!projectId,
        },
    );
}

export function useGetProductsByIds(productIds: number[]) {
    return useQuery(
        getProductsKeys.byIds(productIds),
        () => trpc.ProductsApi.listProductsByIds.query({ entityIds: productIds }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsOnProductId(productId: number, search: string) {
    return useQuery(
        getProductsKeys.productsInPackage({ entityId: productId, search }),
        () => trpc.ProductsApi.listProductsOnProduct.query({ entityId: productId, search }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsOnLibraryProductId(productId: number, search: string) {
    return useQuery(
        getProductsKeys.productsInPackage({ entityId: productId, search }),
        () => trpc.ProductsApi.listProductsOnLibraryProduct.query({ entityId: productId, search }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsOnTemplateProductId(productId: number, search: string) {
    return useQuery(
        getProductsKeys.productsInPackage({ entityId: productId, search }),
        () => trpc.ProductsApi.listProductsOnTemplateProduct.query({ entityId: productId, search }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropertyProductsListFilters(propertyId: number) {
    return useQuery(
        getProductFilterKeys.list({ propertyId }),
        () => trpc.ProductsApi.getPropertyProductsListFilters.query({ propertyId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetTemplateProductsListFilters(accountIds: number[]) {
    return useQuery(
        getProductFilterKeys.templateList(accountIds),
        () => trpc.ProductsApi.getTemplateProductsListFilters.query({ accountIds }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProduct(productId: number) {
    return useQuery(
        getProductsKeys.detail({ productId }),
        () => trpc.ProductsApi.getProduct.query({ entityId: productId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsDocuments(productId: number) {
    return useQuery(
        [...getProductsKeys.documentsByProductId(productId)],
        async () => trpc.ProductsApi.listProductDocuments.query({ entityId: productId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsImages(productId: number) {
    return useQuery(
        [...getProductsKeys.imagesByProductId(productId)],
        async () => trpc.ProductsApi.listProductImages.query({ entityId: productId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsDocumentsTemplateProperty(productId: number) {
    return useQuery(
        [...getProductsKeys.documentsByProductId(productId)],
        async () => trpc.ProductsApi.listProductDocumentsTemplateProperty.query({ entityId: productId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetProductsImagesTemplateProperty(productId: number) {
    return useQuery(
        [...getProductsKeys.imagesByProductId(productId)],
        async () => trpc.ProductsApi.listProductImagesTemplateProperty.query({ entityId: productId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetTemplateProducts({
    filters,
    sortOptions,
    search,
    accountIds,
}: {
    filters: PropertyProductFilter;
    sortOptions: SortOptions;
    search: string;
    accountIds: number[];
}) {
    return useInfiniteQuery(
        getProductsKeys.infiniteTemplateList({ filters, sortOptions, search, accountIds }),
        async ({ pageParam = 1 }) =>
            trpc.BindersApi.getTemplateProductsList.query({
                filter: filters,
                sortType: sortOptions,
                page: pageParam,
                search,
                accountIds,
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useGetProductName(productId: number | null) {
    return useQuery(
        getProductsKeys.productName(productId),
        () => {
            if (!productId) {
                return Promise.reject(new Error('no productId id provided'));
            }
            return trpc.ProductsApi.getProductName.query({ entityId: productId });
        },
        {
            enabled: productId !== null,
        },
    );
}

export function useGetPackageTypes() {
    return useQuery(['package_types'], async () => trpc.BindersApi.listPackageTypes.query());
}
