import React, { useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import MembersList from '../../components/Members/MembersList';
import { useParams } from 'react-router-dom-v5-compat';
import MemberForm from '../../components/Members/MemberForm';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import { SubmitHandler } from 'react-hook-form';
import { AddMember } from '../../schemas';
import useAddMemberToProperty from '../../hooks/memberHooks/useAddMemberToProperty';
import toast from 'react-hot-toast';
import useRemoveMembersFromProperty from '../../hooks/memberHooks/useRemoveMembersFromProperty';
import { ListMembersByPropertyId } from '@app/trpc-api/models/Members';
import { margin } from '../../../../components/Shared/Style/margin';
import useInvitePropertyMember from '../../hooks/memberHooks/useInvitePropertyMember';
import Dialog from '../../../../components/Dialog/Dialog';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';

const PropertyMembers = ({ propertyId }: { propertyId: string }) => {
    const [open, setOpen] = useState(false);
    const [selectedMembers, setSelectedMembers] = React.useState<ListMembersByPropertyId[]>([]);
    const [numberOfMembers, setNumberOfMembers] = useState<number | null>(null);

    const { mutate: addMemberToProperty } = useAddMemberToProperty();
    const { mutate: removeSelectedMembers } = useRemoveMembersFromProperty();
    const { mutate: invitePropertyMembers } = useInvitePropertyMember();

    const onSubmit: SubmitHandler<AddMember> = (data) => {
        addMemberToProperty(
            { ...data, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('added_member'));
                    setOpen(false);
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const handleRemoveSelectedMembers = (members: ListMembersByPropertyId[]) => {
        const ids = members.map((member) => member.id);
        removeSelectedMembers(ids, {
            onSuccess(data) {
                const failedDeletion = data?.find((item) => item?.success === false);
                const successfulDeletions = data?.filter((item) => item?.success);
                if (failedDeletion) {
                    toast.error(L('an_error_occurred'));
                    return;
                }
                if (successfulDeletions?.length === 1) {
                    toast.success(L('removed_member'));
                }
                if (successfulDeletions?.length > 1) {
                    toast.success(L('removed_members'));
                }
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    const handleInviteSelectedMembers = (members: ListMembersByPropertyId[]) => {
        const ids = members.map((member) => member.id);
        invitePropertyMembers(ids, {
            onSuccess: () => {
                if (ids.length === 1) toast.success(L('invited_member'));
                if (ids.length > 1) toast.success(L('invited_members'));
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{propertyName ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="../products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="../contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="../todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="../documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <Dialog open={open} onOpenChange={setOpen}>
                <MemberForm onSubmit={onSubmit} />
            </Dialog>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>
                            {L('members')} {numberOfMembers !== null ? `(${numberOfMembers})` : ''}
                        </CardTitle>
                        <div style={{ display: 'flex', columnGap: margin.s }}>
                            <Button
                                disabled={selectedMembers.length === 0}
                                onClick={() => handleInviteSelectedMembers(selectedMembers)}
                            >
                                {selectedMembers.length > 1 ? L('invite_members') : L('invite_member')}
                            </Button>

                            <Button
                                disabled={selectedMembers.length === 0}
                                onClick={() => handleRemoveSelectedMembers(selectedMembers)}
                            >
                                {selectedMembers.length > 1 ? L('remove_members') : L('remove_member')}
                            </Button>
                            <Button onClick={() => setOpen(true)}>{L('new_member')}</Button>
                        </div>
                    </div>
                }
                mainArea={
                    <MembersList
                        handleSetSelectedMembers={(selectedMembers) => {
                            setSelectedMembers(selectedMembers);
                        }}
                        propertyId={+propertyId}
                        handleOpenModal={() => setOpen(true)}
                        handleSetNumberOfMembers={(val) => setNumberOfMembers(val)}
                    />
                }
            />
        </>
    );
};

const PropertyMembersPage = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return propertyId ? <PropertyMembers propertyId={propertyId} /> : null;
};

export default PropertyMembersPage;
