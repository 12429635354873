import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertiesKeys } from './propertyQueries';

const useAddPropertyFromTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ newName, propertyId, projectId }: { propertyId: number; projectId: number; newName: string }) => {
            return trpc.BindersApi.copyFullProperty.mutate({
                toPropertyName: newName,
                propertyId: propertyId,
                projectId,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
            },
        },
    );
};

export const useAddPropertyFromOrgTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({
            newName,
            propertyId,
            projectId,
            toTemplate,
        }: {
            propertyId: number;
            projectId: number;
            newName: string;
            toTemplate: boolean;
        }) => {
            return trpc.BindersApi.copyFullOrgProperty.mutate({
                toPropertyName: newName,
                propertyId: propertyId,
                toTemplate: toTemplate,
                projectId,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
            },
        },
    );
};

export default useAddPropertyFromTemplate;
