import styled from '@emotion/styled';
import * as React from 'react';
import { style } from '../../../../../components/Shared/Style';
import { FilterTypeOfProperty } from './PropertyFilterTypeOfProperty';

interface Props {
    onChange: (setFilter: boolean) => void;
    filter: boolean;
}

const Container = styled.div({
    color: style.colors.secondary,
    fontSize: style.fontSize.seta,
    marginBottom: style.margin.l,
    marginRight: style.margin.m
});

const Panel = styled.div({
    display: 'flex',
    alignItems: 'center'
});

export const AddonPropertyFilterPanel = (props: Props) => {
    return (
        <Container>
            <Panel>
                <FilterTypeOfProperty onChange={props.onChange} filter={props.filter}  />
            </Panel>
        </Container>
    );
};
