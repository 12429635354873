import styled from '@emotion/styled';
import React from 'react';

const Container = styled.div({
    display: 'flex',
    minHeight: '35px',
    alignItems: 'center'
});

export default function TopAreaContainer(props: React.ComponentProps<'div'>) {
    return <Container {...props} />;
}
