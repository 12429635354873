import * as React from 'react';
import { useObserver } from 'mobx-react';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import { Table } from '../../../components/Table/Table';
import { Button } from '@ourliving/ourliving-ui';
import { Icon } from '../../../components/Icon/Icon';
import { useEffect } from 'react';
import { usePromise } from '../../../lib/react';
import { Loading } from '../../../components/Loading/Loading';
import { AccountMailTemplateStore } from './AccountMailTemplateStore';
import { AccountMailTemplate } from '@app/api/models/AccountSettings';
import { downloadFile } from '../../../lib/download';
import { PreviewMailTemplate } from './Components/PreviewMailTemplate';
import { FileUpload } from '../../../components/FileUploadTicket/FileUpload';

//#region CSS

const ListProjectsModal = styled.div({
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const ListProjectsModalContent = styled.div({
    width: '600px',
    height: '500px',
    backgroundColor: 'white',
    borderRadius: '4px',
    textAlign: 'center',
    opacity: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
});

const ContentRightSide = styled.div({
    display: 'flex',
    padding: '32px',
    justifyContent: 'flex-end',
    width: '100%'
});
const ContentLeftSide = styled.div({
    display: 'flex',
    paddingLeft: '32px',
    justifyContent: 'flex-start',
    width: '100%'
});

const Menu = styled.div({
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
});
const MenuMiddleSection = styled.div({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '32px'
});

const FileNameContainer = styled.div({
    paddingTop: '12px'
});

const HeaderTitle = styled.div({
    fontSize: style.fontSize.gamma,
    fontWeight: style.fontWeight.bold,
    textAlign: 'left'
});

const MenuRight = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});

//#endregion

export const AccountMailTemplateList = () => {
    const store = AccountMailTemplateStore;
    const [ready] = usePromise(async () => {
        await store.load();
    }, []);

    useEffect(() => {
        store.newMailTemplateFile = { ...store.newMailTemplateFile };
    }, []);

    const [editMailTemplate, setEditMailTemplate] = React.useState(false);
    const [editTinyMCE, setEditTinyMCE] = React.useState(false);
    const [isPreview, setIsPreview] = React.useState(false);
    const [data, setData] = React.useState(null);

    //#region clickOutside

    const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    function useOnClickOutside(ref, handler) {
        useEffect(() => {
            const listener = event => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }

                handler(event);
            };

            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);

            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }, [ref, handler]);
    }

    useOnClickOutside(ref, () => (setEditMailTemplate(false), setEditTinyMCE(false), setIsPreview(false)));

    //#endregion

    async function save() {
        await store.uploadMailTemplate();
        setEditMailTemplate(false);
    }

    const openEditMailTemplate = (mail_template_id: string) => {
        store.selectedTemplateId = mail_template_id;
        setEditMailTemplate(true);
    };

    const previewTemplate = async (template: AccountMailTemplate) => {
        if (template.id != null) {
            store.template = template;
            const res = await store.previewMailWithPlaceholders();
            setIsPreview(true);
            setData(res.html);
        }
    };

    const downloadTemplate = async (data: string, name: string) => {
        const response = await store.downloadTemplate(data, name);
        if (response) downloadFile(response.name, response);
    };

    // const handleTinyMCEUpload = () => {
    //     setEditMailTemplate(true);
    //     setEditTinyMCE(true);
    // };

    async function onUpload(files: File[]) {
        store.setMailTemplateFile(files[0]);
    }

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        const dataTable = store.templates.map(i => ({
            templateName: <div>{L(`${i.template_name}`)}</div>,
            templateExists: <div>{i.id != null ? L('setting_mail_true') : L('setting_mail_false')}</div>,
            // addTemplateTinyMCEL: (
            //     <MenuRight>
            //         <Button onClick={handleTinyMCEUpload}>{L('tinyMCE')}</Button>
            //     </MenuRight>
            // ),
            addTemplateMJML: (
                <MenuRight>
                    <Button onClick={() => openEditMailTemplate(i.mail_template_id)}>{L('MJML')}</Button>
                </MenuRight>
            ),
            downloadMJML: (
                <MenuRight>
                    <Button onClick={() => downloadTemplate(i.data, i.template_name)}>{L('download')}</Button>
                </MenuRight>
            ),
            previewTemplate: (
                <MenuRight>
                    <Button onClick={() => previewTemplate(i)}>{L('show')}</Button>
                </MenuRight>
            )
        }));
        return (
            <>
                {editMailTemplate && (
                    <ListProjectsModal>
                        <ListProjectsModalContent ref={ref}>
                            <Menu>
                                <ContentLeftSide>
                                    <HeaderTitle>{L('upload_mailtemplate')}</HeaderTitle>
                                </ContentLeftSide>
                                <ContentRightSide>
                                    {editTinyMCE ? null : (
                                        <FileUpload allowOnly={'.mjml'} custom={'yes'} onFilesSelected={onUpload}>
                                            {L('settings_upload_template')}
                                        </FileUpload>
                                    )}
                                </ContentRightSide>
                            </Menu>
                            <MenuMiddleSection>
                                {editTinyMCE ? (
                                    <></>
                                ) : (
                                    // <Editor
                                    //     value={store.newMailTemplateFile.data}
                                    //     apiKey={'280lwnf4vg3t97aajf1fzc9hm89s7ikxzzq26tjd7hn09aaa'}
                                    //     outputFormat="html"
                                    //     onEditorChange={onChange}
                                    //     init={{ menubar: false, branding: false }}
                                    //     textareaName="description"
                                    // />
                                    <FileNameContainer>
                                        <Icon.Attachment />
                                        {(store.template.file && store.template.file.name) || ''}
                                    </FileNameContainer>
                                )}
                            </MenuMiddleSection>
                            <ContentRightSide>
                                <Button onClick={save}>{L('save')}</Button>
                                <Button onClick={() => setEditMailTemplate(false)} style={{ marginLeft: '10px' }}>
                                    {L('cancel')}
                                </Button>
                            </ContentRightSide>
                        </ListProjectsModalContent>
                    </ListProjectsModal>
                )}
                <Table
                    columns={[
                        {
                            key: 'templateName',
                            header: L('template_name'),
                            width: '2fr'
                        },
                        {
                            key: 'templateExists',
                            header: L('mail_template_exists'),
                            width: '0.5fr'
                        },
                        {
                            key: 'addTemplateMJML',
                            header: L('mail_template_upload'),
                            width: '0.5fr'
                        },
                        {
                            key: 'downloadMJML',
                            header: L('download'),
                            width: '0.5fr'
                        },
                        {
                            key: 'previewTemplate',
                            header: L('mail_template_preview'),
                            width: '0.5fr'
                        }
                        // {
                        //     key: 'addTemplateTinyMCE',
                        //     header: L('mail_template_type'),
                        //     width: '1fr'
                        // }
                    ]}
                    data={dataTable}
                />
                <PreviewMailTemplate data={data} forwardRef={ref} isActive={isPreview} setIsActive={setIsPreview} />
            </>
        );
    });
};
