import React from 'react';
import toast from 'react-hot-toast';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';
import ProductTemplateForm from '../components/ProductTemplateForm';
import { useParams } from 'react-router-dom-v5-compat';
import { Loading } from '../../../components/Loading/Loading';
import useUpdateProduct, {
    useGetProduct,
} from '../../project-templates/hooks/InventoryTemplates/inventoryTemplateQueries';
import { AddAccountProduct } from '../../binders/schemas';
import { useGetProductName, useGetProductsImages } from '../../binders/hooks/productQueries';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import H1 from '../../../components/typography/H1';
import BigTabMenu from '../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import ProductDocumentList from '../components/ProductDocumentList';
import styled from '@emotion/styled';
import useUpdateProductDocSort from '../../binders/hooks/productHooks/useUpdateProductDocSort';
import ProductImages from '../components/ProductImages';
import { Separator } from '../../../components/Separator/Separator';
import { style } from '../../../components/Shared/Style';

const Wrapper = styled.div({
    display: 'grid',
    rowGap: '1rem',
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

function EditInventoryTemplateComponent({ productId }: { productId: string }) {
    const { data: product, status } = useGetProduct(+productId);
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateProductDocSort();
    const { data: images } = useGetProductsImages(+productId);

    const Divider = styled(Separator)({
        backgroundColor: style.colors.divider,
    });

    const DividerContainer = styled.div({
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        ['@media screen and (max-width: 992px)']: {
            display: 'none',
        },
    });

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useUpdateProduct();

    const onSubmit: SubmitHandler<AddAccountProduct> = (data) => {
        saveInventoryMaterial(
            { inventoryMaterial: data, productId: +productId },
            {
                onSuccess: () => {
                    toast.success(L('product_updated'));
                },
                onError: () => {
                    toast.error(L('product_not_updated'));
                },
            },
        );
    };
    const urlIncludesCopyFunction = location.pathname.includes('copy-function');
    const { packageItemId } = useParams<{ packageItemId: string }>();
    const productParentId = product?.parent_id !== 0 && product?.parent_id !== undefined ? product.parent_id : null;
    const { data: parentProductNameData } = useGetProductName(productParentId);
    const productTitle = product?.attr?.product ? product.attr.product : L('product');

    const regularBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="..">{L('inventory_lib')}</Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    const packageBreadcrumbs = (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item to="../../..">{L('inventory_lib')}</Breadcrumbs.Item>
            <Breadcrumbs.Item to="..">
                {parentProductNameData?.name ? parentProductNameData?.name : L('product')}
            </Breadcrumbs.Item>
            <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
        </Breadcrumbs>
    );

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            {packageItemId && !urlIncludesCopyFunction && (
                <>
                    {packageBreadcrumbs}
                    {<H1>{productTitle}</H1>}
                </>
            )}

            {!packageItemId && !urlIncludesCopyFunction && (
                <>
                    {regularBreadcrumbs}
                    {<H1>{productTitle}</H1>}
                    <BigTabMenu>
                        <BigTabNav>
                            <BigNavItem end to=".">
                                {L('edit_product')}
                            </BigNavItem>
                            <BigNavItem end to="package">
                                {L('content_of_packages')}
                            </BigNavItem>
                        </BigTabNav>
                    </BigTabMenu>
                </>
            )}
            <Wrapper>
                <CardWithDivider
                    topArea={<CardTitle>{L('information')}</CardTitle>}
                    mainArea={
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', gap: '1rem' }}>
                            {product && (
                                <ProductTemplateForm
                                    onSubmit={onSubmit}
                                    options={options}
                                    defaultValues={{ ...product }}
                                    oneFieldPerRow
                                />
                            )}
                            <DividerContainer>
                                <Divider decorative orientation="vertical" />
                            </DividerContainer>
                            <div>
                                <div>
                                    <ProductImages
                                        productId={+productId}
                                        images={images ?? []}
                                        updateSortBulk={updateSortBulk}
                                        isUpdatingSort={isUpdatingSort}
                                    />
                                </div>
                                <StyledH3>{L('documents')}</StyledH3>
                                <ProductDocumentList
                                    productId={+productId}
                                    updateSortBulk={updateSortBulk}
                                    isUpdatingSort={isUpdatingSort}
                                />
                            </div>
                        </div>
                    }
                />
            </Wrapper>
        </>
    );
}

export default function EditInventoryTemplatePage() {
    const { id: productId, packageItemId } = useParams<{ id: string; packageItemId?: string }>();
    if (packageItemId) return <EditInventoryTemplateComponent productId={packageItemId} />;
    return productId ? <EditInventoryTemplateComponent productId={productId} /> : null;
}
