import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import TicketFilters from './TicketFilters';
import { TicketFilter, TicketListItem } from '@app/api/models/Tickets';
import { colors } from '@app/shared/lib/Style/colors';
import { useGetInfiniteTickets } from '../hooks/ticketQueries';
import dayjs from 'dayjs';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { Icon } from '../../../components/Icon/Icon';
import TicketSideMenu from './TicketSideMenu';
import { tooltip } from '../../../components/Shared/Style/typography';
import * as Dialog from '@radix-ui/react-dialog';
import { useSpring } from 'react-spring';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useLocalStorage } from 'react-use';
import useRenderCsv from '../hooks/useRenderCsv';
import { downloadFile } from '../../../lib/download';
import { margin } from '../../../components/Shared/Style/margin';
import GenericTableVirtualized from '../../binders/components/GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import Row from '../../binders/components/emotion/Row';
import ContentContainer from '../../binders/components/emotion/ContentContainer';
import PaddedContainer from '../../binders/components/emotion/PaddedContainer';
import {
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
    Row as RowType,
    SortingState,
    createColumnHelper,
} from '@tanstack/react-table';

const getOpposite = (color: string) => {
    const splitByParas = color.split(/[()]+/);
    if (splitByParas.length !== 3) return colors.white;
    const rgbDigits = splitByParas[1].split(', ');
    if (splitByParas.length < 3) return colors.white;
    const [red, green, blue] = rgbDigits.map((digits) => Number(digits));
    if (red * 0.299 + green * 0.587 + blue * 0.114 >= 200) {
        return colors.black;
    }
    return colors.white;
};

const TopAreaContainer = styled.div({
    display: 'flex',
    minHeight: '35px',
    alignItems: 'center',
});

const StyledTicketFilters = styled(TicketFilters)({
    margin: '0px',
});

const UnreadMessages = styled.div(() => ({
    ...tooltip,
    color: colors.white,
    borderRadius: '50%',
    height: '18px',
    width: '18px',
    backgroundColor: colors.red,
    display: 'grid',
    placeItems: 'center',
}));

const Span = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        color: isBefore ? colors.statusError : 'inherit',
    };
});

const StatusSpan = styled(Span)(({ statusColor, textColor }: { statusColor: string; textColor: string }) => {
    return {
        display: 'initial',
        backgroundColor: statusColor,
        minWidth: '4.5rem',
        maxWidth: '7rem',
        padding: '0.4rem 0.35rem',
        borderRadius: '15px',
        color: textColor,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
});

const UnreadMessagesContainer = styled.div({
    margin: '0.5rem 0',
    padding: '0.5rem 0px',
    maxWidth: '100%',
    overflow: 'hidden',
    fontSize: '0.75rem',
});

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const ColorContainer = styled(CellContainer)(({ boxcolor = 'inherit' }: { boxcolor?: string | null }) => {
    return {
        borderLeft: `6px solid ${boxcolor}`,
        paddingLeft: '1rem',
    };
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<TicketListItem & { newentries: number }>();

const TicketsList = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const [localStorageValue, setLocalStorageValue] = useLocalStorage(
        'ticketListFilters',
        searchParams.toString() ?? '',
    );

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else setSearchParams([]);
        setLocalStorageValue(() => '');
    };

    const updateFilter = <T extends keyof TicketFilter>(key: T, value: number) => {
        setSearchParams((searchParams) => {
            if (value) searchParams.set(key, String(value));
            else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
        setLocalStorageValue(() => newUrlParams.toString());
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (!localStorageValue || !isInitialRender) return;
        if (window.location.search) {
            setIsInitialRender(false);
            return;
        }
        setSearchParams(localStorageValue);
        setIsInitialRender(false);
    }, [localStorageValue, searchParams]);

    const selectedTicketId = () => {
        const res = searchParams.get('ticketId');
        if (typeof res === 'string' && res) return res;
        return '';
    };

    const slideIn = useSpring({
        transform: selectedTicketId() ? 'translateX(0px)' : 'translateX(300px)',
        config: { friction: 30 },
    });

    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.title, {
                id: 'title',
                cell: (info) => (
                    <ColorContainer boxcolor={info.row.original?.priority_color_code}>
                        <Data>{info.getValue()}</Data>
                        <Data
                            style={{ fontSize: '0.8em' }}
                        >{`#${info.row.original.project_id} - ${info.row.original.ticket_no}`}</Data>
                    </ColorContainer>
                ),
                header: () => <Flex>{L('title')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.property_name, {
                id: 'property_name',
                cell: (info) => (
                    <CellContainer>
                        <Data style={{ fontSize: '0.8em' }}>{info.row.original.project_name}</Data>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('property')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.assigned_name, {
                id: 'assigned_name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('assignee')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.status_name, {
                id: 'status_name',
                cell: ({ row }) => (
                    <CellContainer>
                        {row.original.ticket_status_id &&
                            row.original.status_name &&
                            row.original.status_color_code && (
                                <StatusSpan
                                    statusColor={row.original.status_color_code}
                                    textColor={getOpposite(row.original.status_color_code)}
                                >
                                    {row.original.status_name}
                                </StatusSpan>
                            )}
                    </CellContainer>
                ),
                header: () => <Flex>{L('status')}</Flex>,
                // not sure why this is needed
                sortDescFirst: false,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.created_at, {
                id: 'created_at',
                cell: (info) => (
                    <CellContainer>
                        <Data>{dayjs(info.getValue()).format('ddd DD MMM YYYY')}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('created')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor((row) => row.newentries, {
                id: 'newentries',
                cell: (info) => (
                    <UnreadMessagesContainer>
                        {!!info.row.original.newentries && info.row.original.newentries > 0 && (
                            <UnreadMessages style={{ gridArea: 'unreadMessages', justifySelf: 'end' }}>
                                <span>{info.row.original.newentries}</span>
                            </UnreadMessages>
                        )}
                    </UnreadMessagesContainer>
                ),
                header: () => null,
                enableSorting: false,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                        width: 120,
                    },
                },
            }),
            columnHelper.accessor(() => 'sidemenu', {
                id: 'sidemenu',
                cell: ({ row }) => (
                    <Icon.MoreVertical
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setSearchParams((searchParams) => {
                                searchParams.set('ticketId', String(row.original.id));
                                return searchParams;
                            });
                        }}
                    />
                ),
                header: () => null,
                enableSorting: false,
                meta: {
                    styles: {
                        width: 30,
                    },
                },
            }),
        ],
        [setSearchParams],
    );

    // Get relevant params from URL
    const project_id = searchParams.get('project_id');
    const property = searchParams.get('property');
    const assignee = searchParams.get('assignee');
    const status_id = searchParams.get('status_id');
    const priority_id = searchParams.get('priority_id');
    const type_id = searchParams.get('type_id');

    const filter = {
        project_id: project_id ? Number(project_id) : undefined,
        property: property ? Number(property) : undefined,
        assignee: assignee ? Number(assignee) : undefined,
        status_id: status_id ? Number(status_id) : undefined,
        priority_id: priority_id ? Number(priority_id) : undefined,
        type_id: type_id ? Number(type_id) : undefined,
    };

    const isFiltering = Object.values(filter).some((value) => !!value === true);

    const [sorting, setSorting] = useState<SortingState>([]);

    const { fetchNextPage, hasNextPage, isFetching, data } = useGetInfiniteTickets({
        filter,
        keepPreviousData: true,
        searchQuery: searchField,
        sorting,
    });

    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);

    const totalDBRowCount = Number(data?.pages?.[0]?.totalItems) * 10;
    const totalFetched = flatData.length;

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableColumnResizing: false,
    });

    const { mutate: csvFile } = useRenderCsv();

    const onDownload = async () => {
        csvFile(
            { sorting, searchQuery: searchField, filter },
            {
                onSuccess: (data) => {
                    downloadFile(data.name, data);
                },
            },
        );
    };

    const handleClickRow = useCallback((row: RowType<TicketListItem>) => {
        navigate(`${row.original.id}`);
    }, []);

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>{L('all_active_tickets')}</CardTitle>
                    <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                        <Button style={{ marginLeft: 'auto' }} onClick={() => onDownload()}>
                            {L('print_tickets')}
                        </Button>
                        <Button onClick={() => navigate('/tickets/new')}>{L('new_ticket')}</Button>
                    </div>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <Dialog.Root
                        open={!!selectedTicketId()}
                        onOpenChange={() => {
                            setSearchParams((searchParams) => {
                                searchParams.delete('ticketId');
                                return searchParams;
                            });
                        }}
                    >
                        <Dialog.Portal>
                            <Dialog.Overlay>
                                <Dialog.Content>
                                    <TicketSideMenu
                                        ticketId={selectedTicketId() ?? ''}
                                        handleClose={() => {
                                            setSearchParams((searchParams) => {
                                                searchParams.delete('ticketId');
                                                return searchParams;
                                            });
                                        }}
                                        slideIn={slideIn}
                                    />
                                </Dialog.Content>
                            </Dialog.Overlay>
                        </Dialog.Portal>
                    </Dialog.Root>

                    <FilterSearchContainer>
                        <ContentContainer>
                            <StyledTicketFilters
                                filter={filter}
                                handleFilter={updateFilter}
                                handleClearAll={handleClearAll}
                            />
                        </ContentContainer>
                        <PaddedContainer>
                            <Row>
                                <GlobalFilter
                                    filter={searchField ?? ''}
                                    setFilter={handleSearch}
                                    placeholder={L('search')}
                                />
                            </Row>
                        </PaddedContainer>
                    </FilterSearchContainer>

                    <GenericTableVirtualized
                        table={table}
                        handleFetchNextPage={() => fetchNextPage()}
                        hasNextPage={hasNextPage}
                        isFetching={isFetching}
                        totalDBRowCount={totalDBRowCount}
                        totalFetched={totalFetched}
                        estimateSize={50}
                        rowClickHandler={handleClickRow}
                    />
                    {flatData?.length === 0 && data && filter && !isFiltering && searchField === '' && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('no_tickets_description')}</p>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                    {flatData?.length === 0 && data && filter && searchField && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets')}</EmptyState.Title>
                            <EmptyState.Description>
                                <span>{L('no_tickets_matched')}</span>{' '}
                                <span style={{ fontStyle: 'italic' }}>{searchField}</span>.
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                    {flatData?.length === 0 && data && filter && !searchField && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_tickets')}</EmptyState.Title>
                            <EmptyState.Description>
                                <span>{L('no_tickets_matched_filters')}</span>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                </>
            }
        />
    );
};

export default TicketsList;
