import styled from '@emotion/styled';
import React from 'react';
import { FC } from 'react';
import { colors } from '../../Shared/Style/colors';
import Icon from '../transfer.svg';

const ThemedExchange = styled(Icon)(() => {
    return {
        height: '48px',
        width: '48px',
        '& g': {
            stroke: colors.icons,
        },
        '& path': {
            strokeWidth: '1px',
        },
    };
});
const Exchange: FC = ({ ...rest }) => {
    return <ThemedExchange {...rest} />;
};

export default Exchange;
