Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getById"] = b.createRpcProxy("ProjectsApi", "getById", {
  "urlPrefix": "/rpc"
});
o["getAddonInfo"] = b.createRpcProxy("ProjectsApi", "getAddonInfo", {
  "urlPrefix": "/rpc"
});
o["list"] = b.createRpcProxy("ProjectsApi", "list", {
  "urlPrefix": "/rpc"
});
o["getProjectSettings"] = b.createRpcProxy("ProjectsApi", "getProjectSettings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = b.createRpcProxy("ProjectsApi", "save", {
  "urlPrefix": "/rpc"
});
o["saveAddonInfo"] = b.createRpcProxy("ProjectsApi", "saveAddonInfo", {
  "urlPrefix": "/rpc"
});
o["setAutoConfirmation"] = b.createRpcProxy("ProjectsApi", "setAutoConfirmation", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["setSingleSigner"] = b.createRpcProxy("ProjectsApi", "setSingleSigner", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveProjectSettings"] = b.createRpcProxy("ProjectsApi", "saveProjectSettings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["projectProperies"] = b.createRpcProxy("ProjectsApi", "projectProperies", {
  "urlPrefix": "/rpc"
});
o["getProjectArea"] = b.createRpcProxy("ProjectsApi", "getProjectArea", {
  "urlPrefix": "/rpc"
});