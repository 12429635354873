import './lib/rpc';
import './components/Layout/layout.css';
import ReactDOM from 'react-dom/client';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { routes } from './navigation';
import { prepareI18n, L } from './lib/i18n';
import { ThemeProvider } from 'emotion-theming';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import io from 'socket.io-client';
import { CompatRouter } from 'react-router-dom-v5-compat';
import * as Sentry from '@sentry/react';
import sentryConfig from './sentry/sentryConfig';

dayjs.extend(timezone);
dayjs.tz.setDefault(dayjs.tz.guess());
import { Toaster } from 'react-hot-toast';

export const socket = io('/', {
    withCredentials: true,
});

const darkTheme = {
    background: '#F7F8FC',
};

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 5000,
            refetchOnWindowFocus: true,
        },
    },
});

const AppProviders = () => {
    const config = sentryConfig();
    Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.SENTRY_ENVIRONMENT,
        tracesSampleRate: 0.1,
    });

    useEffect(() => {
        socket.connect();
        const invalidateQueries = (queries: string[]) => {
            queryClient.invalidateQueries(queries);
        };
        socket.on('invalidate_queries', invalidateQueries);

        return () => {
            socket.off('invalidate_queries', invalidateQueries);
        };
    }, [socket.connected]);
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={darkTheme}>
                <BrowserRouter>
                    <CompatRouter>{routes}</CompatRouter>
                </BrowserRouter>
                <Toaster />
            </ThemeProvider>
            <ReactQueryDevtools position={'bottom-left'} />
        </QueryClientProvider>
    );
};

prepareI18n().then(() => {
    const app = document.createElement('div');
    document.title = 'Ourliving - ' + L('propmgmt');
    document.body.appendChild(app);
    const meta = document.createElement('meta');
    meta.name = 'viewport';

    meta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
    document.getElementsByTagName('head')[0].appendChild(meta);

    ReactDOM.createRoot(app!).render(<AppProviders />);
});
