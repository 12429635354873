import { Account } from '@app/api/models/Accounts';
import { L } from '../../../lib/i18n';
import React from 'react';
import CheckboxOption from './CheckboxOption';
import FlexContainer from './emotion/FlexContainer';
import Label from '../../../components/Filters/FilterLabel';
import styled from '@emotion/styled';

const FlexGapContainer = styled.div({ display: 'flex', alignItems: 'center', gap: '0.6rem' });

export default function LibrarySource({
    libraries,
    accountIds,
    setAccountIds
}: {
    libraries: Account[];
    accountIds: string[];
    setAccountIds: (param: string[]) => void;
}) {
    const changeSelected = (id: string) => {
        const exists = accountIds.includes(id);
        if (exists) {
            //Remove
            setAccountIds(accountIds.filter(accountId => accountId !== id));
        } else {
            //add
            const newAccountIds = [...accountIds];
            newAccountIds.push(id);
            setAccountIds(newAccountIds);
        }
    };
    return (
        <FlexContainer>
            <Label>{L('chosen_libraries')}:</Label>
            <FlexGapContainer>
                {libraries.map(library => (
                    <CheckboxOption
                        key={library.id}
                        name={library.name}
                        selected={accountIds.includes(String(library.id))}
                        changeSelected={() => changeSelected(String(library.id))}
                    />
                ))}
            </FlexGapContainer>
        </FlexContainer>
    );
}
