import React from 'react';
import { ProfileSettings } from './Components/ProfileSettings';
import ProfileImage from './Components/ProfileImage';
import styled from '@emotion/styled';
import { colors } from '@app/shared/lib/Style/colors';

const ProfileInfo = styled.div({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: 5,
    padding: '2rem',
});

export const ProfileSettingsPage = () => {
    return (
        <>
            <ProfileImage />
            <ProfileInfo>
                <ProfileSettings />
            </ProfileInfo>
        </>
    );
};
