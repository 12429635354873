import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentNewsQueryKeys } from './contentNewsQueries';
import { uploadToAws } from '../ContentInformation/uploadToAws';

export default function useUpdateContentNews() {
    const queryClient = useQueryClient();
    return useMutation(
        async ({
            contentNewsId,
            news,
            projectId,
            file,
        }: {
            news: { title?: string; description: string; cover_image_data?: null };
            projectId: number;
            contentNewsId: number;
            file?: File | null;
        }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;
            const { id, preSingedUrl } = await trpc.ContentNewsApi.updateContentNews.mutate({
                id: contentNewsId,
                projectId,
                title: news.title,
                description: news.description,
                file: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.ContentNewsApi.updateContentNews.mutate({
                            id,
                            projectId: projectId,
                            cover_image_data: null,
                        });
                    },
                });
            }

            return { id };
        },
        {
            onSettled: async (res, err, params) => {
                queryClient.invalidateQueries(ContentNewsQueryKeys.lists());
                if (res)
                    queryClient.invalidateQueries(
                        ContentNewsQueryKeys.detail({ contentNewsId: res.id, templateId: params.projectId }),
                    );
            },
        },
    );
}
