import { useQueryClient, useMutation, QueryClient } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { ProductAttr } from '@app/api/models/Properties';

type Variables = { id: string; product: Partial<ProductAttr>; completed?: boolean };

export const updateProduct = async ({ id, product, completed }: Variables) => {
    return InventoryApi.updateProductInfo(id, product, completed);
};

const useUpdateProduct = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => updateProduct(variables), {
        onSettled: (_data, _error, { product }) => {
            if (!product) return;
            queryClient.invalidateQueries(['getProductsByProperty']);
        }
    });
};

export default useUpdateProduct;
