import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading/Loading';
import { PropertyList } from './ConfirmationHeader/PopertyList';
import { ProductList } from './ConfirmationProducts/ProductList';
import useGetHeaderInfo from './hooks/useGetHeaderInfo';
import useGetListData from './hooks/useGetList';

const Wrapper = styled.div({
    backgroundColor: 'white',
    '@media screen': {
        display: 'none'
    }
});

const OrderConfirmationCreatePdf = ({ propertyId, handlePrint, setLoadingProperty }) => {
    const { data: list, isLoading: listLoading } = useGetListData(propertyId);
    const { data: header, isLoading } = useGetHeaderInfo(propertyId);
    const [isInitialLoad, setisInitialLoad] = useState(false);

    useEffect(() => {
        if (!isLoading && !listLoading) setisInitialLoad(true);
    }, [listLoading, isLoading]);

    useEffect(() => {
        isInitialLoad && handlePrint();
        isInitialLoad && setLoadingProperty('');
    }, [isInitialLoad]);

    if (!list || !header) return <Loading />;

    return (
        <Wrapper>
            <PropertyList headerInfo={header} />
            <ProductList r={list} />
        </Wrapper>
    );
};

export default OrderConfirmationCreatePdf;
