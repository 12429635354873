import React, { useMemo } from 'react';
import { L } from '../../../lib/i18n';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardTitle from '../../../components/typography/CardTitle';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import { Separator } from '../../../components/Separator/Separator';
import { style } from '../../../components/Shared/Style';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import dayjs from 'dayjs';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui';
import { useQueryClient } from 'react-query';
import useUpdatePropertyTemplate from '../../project-templates/hooks/PropertyTemplates/useUpdatePropertyTemplate';
import useDeleteDocument from '../../project-templates/hooks/PropertyTemplates/useDeletePropertyTemplateDoc';
import useUpdateDocSortBulk from '../../project-templates/hooks/PropertyTemplates/useUpdatePropertyTemplateDocSort';
import PropertyTemplateForm, { AddPropertyTemplate } from '../PropertyTemplates/PropertyTemplateForm';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import Images from '../../binders/components/Draggables/Images';
import {
    useGetPropertyTemplateById,
    useGetPropertyTemplateImages,
    getPropertyTemplatesKeys,
} from '../hooks/propertyTemplateQueries';
import useDeleteProperty from '../../project-templates/hooks/PropertyTemplates/useDeletePropertyTemplate';
import useDragEndDnd from '../../../hooks/useDragEndDndNew';
import useUploadPropertyTemplateDocs from '../../project-templates/hooks/PropertyTemplates/useUploadPropertyTemplateDocs';

export type PropertyTypesOptions = {
    value: string;
    label: string;
}[];

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const RestyledEmptyState = styled(EmptyState)({
    minHeight: 'auto',
    padding: '1rem',
});

const GridContainer = styled.div({
    display: 'grid',
    columnGap: '1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr auto 1fr',
    },
});

const EditPropertyPage = () => {
    const { id } = useParams<{ id: string }>();
    return id ? <EditProperty id={+id} /> : null;
};

const EditProperty = ({ id }: { id: number }) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { mutate: editProperty } = useUpdatePropertyTemplate();
    const { data: property } = useGetPropertyTemplateById(+id);
    const { mutate: deleteProperty } = useDeleteProperty();
    const { mutate: deleteDocument } = useDeleteDocument();
    const { mutate: saveDocuments } = useUploadPropertyTemplateDocs();
    const { data: images } = useGetPropertyTemplateImages(+id);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateDocSortBulk();
    const handleDragEnd = useDragEndDnd({
        queryKey: getPropertyTemplatesKeys.imagesByPropertyId(+id),
        updateSortOrder: updateSortBulk,
        documentableId: +id,
        reverseArray: true,
    });

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return;

        saveDocuments(
            { files: [param.file], documentType: 0, propertyId: +id },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries([...getPropertyTemplatesKeys.imagesByPropertyId(+id)]);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDeleteProperty = () => {
        deleteProperty(+id, {
            onSuccess: () => {
                toast.success(`${property?.name} ${L('removed').toLowerCase()}`);
                navigate(`..`);
            },
            onError: (err) => {
                if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
            },
        });
    };

    const handleRemoveImage = (documentId: number) => {
        deleteDocument(
            { documentId, propertyId: +id },
            {
                onSuccess: () => {
                    toast.success(`${L('picture')} ${L('removed').toLowerCase()}`);
                    queryClient.invalidateQueries(getPropertyTemplatesKeys.imagesByPropertyId(+id));
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const onSubmit: SubmitHandler<AddPropertyTemplate> = (data) => {
        editProperty(
            { data: data, templateId: +id },
            {
                onSuccess: () => {
                    toast.success(L('updated_property'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const imageData: { id: number; url: string }[] = useMemo(() => {
        if (!images) return [];
        return images.map((image) => {
            return { id: image.id, url: image.signed_url };
        });
    }, [images]);

    return (
        <div>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{L(property?.template ? L('edit_template') : L('edit_property'))}</CardTitle>
                    </TopAreaContainer>
                }
                mainArea={
                    <GridContainer>
                        <div>
                            {property && (
                                <PropertyTemplateForm
                                    propertyData={property}
                                    onSubmit={onSubmit}
                                    handleDeleteProperty={handleDeleteProperty}
                                    oneFieldPerRow
                                />
                            )}
                        </div>
                        <DividerContainer>
                            <Divider decorative orientation="vertical" />
                        </DividerContainer>
                        <div>
                            {images &&
                                (images.length > 0 ? (
                                    <Images
                                        outerWrapperCSS={{ marginBottom: style.margin.s }}
                                        imageData={imageData}
                                        handleRemoveImage={handleRemoveImage}
                                        handleDragEnd={handleDragEnd}
                                        isUpdatingSort={isUpdatingSort}
                                    />
                                ) : (
                                    <RestyledEmptyState>
                                        <EmptyState.Title>{L('no_images')}</EmptyState.Title>
                                        <EmptyState.Description>
                                            <p>{L('no_images_description')}</p>
                                        </EmptyState.Description>
                                    </RestyledEmptyState>
                                ))}

                            <Dropzone onUpload={onUpload} variant="Media" multiple />

                            <div>
                                <StyledH3>{L('extra_information')}</StyledH3>
                                <ErrorAndInputWrapper>
                                    <Label title={L('created_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(property?.created_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <Label title={L('updated_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(property?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                            </div>
                        </div>
                    </GridContainer>
                }
            />
        </div>
    );
};

export default EditPropertyPage;
