Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["saveSetting"] = b.createRpcProxy("PushNotificationApi", "saveSetting", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listSettings"] = b.createRpcProxy("PushNotificationApi", "listSettings", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveToken"] = b.createRpcProxy("PushNotificationApi", "saveToken", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});