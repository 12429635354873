import { trpc } from '../../../lib/trpc';
import { useMutation, useQueryClient } from 'react-query';
import { getProductsKeys } from './productQueries';
import { getPropertyProductsTemplatesKeys } from '../../project-templates/hooks/PropertyTemplates/propertyProductsTemplateQueries';

const useDeleteProducts = () => {
    const client = useQueryClient();
    return useMutation(
        async (products: number[]) => {
            return trpc.ProductsApi.deleteProducts.mutate({ entityIds: products });
        },
        {
            onSettled: () => {
                client.invalidateQueries(getProductsKeys.all);
                client.invalidateQueries(getPropertyProductsTemplatesKeys.infiniteLists());
            },
        },
    );
};

export default useDeleteProducts;
