import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import { getPropertiesKeys, useGetProperties } from '../../hooks/propertyHooks/propertyQueries';
import { Breadcrumbs, Button, DropdownButton } from '@ourliving/ourliving-ui';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { margin } from '../../../../components/Shared/Style/margin';
import CardTitle from '../../../../components/typography/CardTitle';
import TopAreaContainer from '../../components/TopAreaContainer';
import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import GenericTable from '../../components/GenericTable';
import { Property } from '@app/trpc-api/models/Binders';
import toast from 'react-hot-toast';
import propertyTemplateColumns, { PropertyTemplateColumnsTableMeta } from './PropertyTemplateColumns';
import { useQueryClient } from 'react-query';
import useDeleteTemplateProperties from '../../hooks/propertyHooks/useDeleteTemplateProperties';
import { useLocalStorage } from 'react-use';
import Dialog from '../../../../components/Dialog/Dialog';
import AddPropertyFromOrgTemplate from '../../components/Properties/AddPropertyFromOrgTemplate';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import H1 from '../../../../components/typography/H1';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import { Loading } from '../../../../components/Loading/Loading';
import CreateTemplateFromProperty from '../../components/Properties/CreateTemplateFromProperty';

const Page = ({ projectId }: { projectId: string }) => {
    const { data, status } = useGetProperties({ projectId: Number(projectId), shouldFetch: 'template' });
    const { mutate: deleteTemplateProperties } = useDeleteTemplateProperties();
    const navigate = useNavigate();
    const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
    const [open, setOpen] = useState(false);
    const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
    const [selectedPropertyForTemplate, setSelectedPropertyForTemplate] = useState<number | null>(null);

    const [localNewPropertyButtonState, setNewPropertyButtonState] = useLocalStorage<
        'new_template_from_org_template' | 'new_template'
    >('PropertyTemplatesPage', 'new_template');
    const newPropertyButtonState = localNewPropertyButtonState || 'new_template';
    const queryClient = useQueryClient();

    const table = useReactTable({
        data: data ?? [],
        columns: propertyTemplateColumns(),
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
        meta: {
            handleTemplateBtnClick: (id) => {
                setSelectedPropertyForTemplate(id);
                setOpenCreateTemplate(true);
            },
        } satisfies PropertyTemplateColumnsTableMeta,
    });

    const deleteProperty = (properties: Property[]) => {
        deleteTemplateProperties(
            { properties, projectId: +projectId },
            {
                onSuccess: () => {
                    toast.success(`${L('templates_delete_successfully')}`);
                    table.resetRowSelection();
                },
                onError: () => {
                    toast.error(`${L('templates_delete_failed')}`);
                    table.resetRowSelection();
                },
                onSettled: (_err, _data, ctx) => {
                    queryClient.invalidateQueries(getPropertiesKeys.list(ctx.projectId, true));
                },
            },
        );
    };

    const selectedData = table.getSelectedRowModel().flatRows.map((data) => data.original);

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const project = useGetProjectName(projectId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Dialog open={openCreateTemplate} onOpenChange={setOpenCreateTemplate}>
                <CreateTemplateFromProperty
                    handleCloseModal={() => {
                        setOpenCreateTemplate(false);
                        setSelectedPropertyForTemplate(null);
                    }}
                    projectId={+projectId}
                    propertyId={selectedPropertyForTemplate}
                />
            </Dialog>
            <Dialog open={open} onOpenChange={setOpen}>
                <AddPropertyFromOrgTemplate
                    handleCloseModal={() => setOpen(false)}
                    projectId={+projectId}
                    template={true}
                />
            </Dialog>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{project ?? L('project_literally')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project ?? L('project_literally')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../properties">
                        {L('properties')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('property_templates')}
                    </BigNavItem>
                    <BigNavItem end to="../admins">
                        {L('admins')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_project')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>
                            {L('property_templates')} {data ? `(${data.length})` : ''}
                        </CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                            <DropdownButton
                                options={[
                                    {
                                        id: 'new_template',
                                        dropdownItem: (
                                            <DropdownButton.DropdownItem>
                                                {L('new_template')}
                                            </DropdownButton.DropdownItem>
                                        ),
                                        button: (
                                            <DropdownButton.Button onClick={() => navigate(`new`)}>
                                                {L('new_template')}
                                            </DropdownButton.Button>
                                        ),
                                    },
                                    {
                                        id: 'new_template_from_org_template',
                                        dropdownItem: (
                                            <DropdownButton.DropdownItem>
                                                {L('new_template_from_org_template')}
                                            </DropdownButton.DropdownItem>
                                        ),
                                        button: (
                                            <DropdownButton.Button
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                {L('new_template_from_org_template')}
                                            </DropdownButton.Button>
                                        ),
                                    },
                                ]}
                                optionId={newPropertyButtonState}
                                setOptionId={(state) => {
                                    setNewPropertyButtonState(state);
                                }}
                            />

                            <ConfirmationButton
                                disabled={!Object.keys(rowSelection).length}
                                props={{
                                    onConfirm: () => {
                                        deleteProperty(selectedData);
                                    },
                                    idleVariant: 'secondary',
                                    confirmVariant: 'danger',
                                }}
                            >
                                {L('remove_selected_templates')} : {Object.keys(rowSelection).length}
                            </ConfirmationButton>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <GenericTable table={table} />
                        {data?.length === 0 && data && (
                            <EmptyState>
                                <EmptyState.Title>{L('no_templates')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_templates_description')}</p>
                                    <p>{L('click_button_below')}</p>
                                    <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('/new')}>
                                        {L('new_template')}
                                    </Button>
                                </EmptyState.Description>
                            </EmptyState>
                        )}
                    </>
                }
            />
        </>
    );
};

export default function PropertyTemplatesPage() {
    const { id: projectId } = useParams<{ id: string }>();
    return projectId ? <Page projectId={projectId} /> : null;
}
