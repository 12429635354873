import styled from '@emotion/styled';
import React from 'react';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import PageTitle from '../../components/typography/PageTitle';
import { L } from '../../lib/i18n';
import EditTicket from './components/EditTicket';
import { useGetTicket } from './hooks/ticketQueries';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import { Separator } from '../../components/Separator/Separator';
import TicketsArchive from './components/TicketsArchive';
import CustomerCommunication from './components/CustomerCommunication';
import Notes from './components/Notes';
import Log from './components/Log';
import ModalProvider from '../../components/Modal/Context/ModalContext';
import HandleInitialTicketData from './components/HandleInitialTicketData';
import TicketsList from './components/TicketsList';
import PrimaryTicket from './components/PrimaryTicket';
import { colors } from '@app/shared/lib/Style/colors';
import { margin } from '@app/shared/lib/Style/margin';
import CardWithDivider from '../../components/Layout/CardWithDivider';
import CardTitle from '../../components/typography/CardTitle';
import { Routes, Route, useParams, Outlet } from 'react-router-dom-v5-compat';

const PageWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '6fr auto 8fr',
    gap: '1rem',
    alignItems: 'start',
    ['@media screen and (max-width: 992px)']: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        rowGap: margin.l,
    },
});

const SmallerBigNavItem = styled(BigNavItem)({
    fontSize: '1rem',
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const DividerContainerMobile = styled.div({
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    display: 'none',
    ['@media screen and (max-width: 992px)']: {
        display: 'flex',
    },
});

const TopAreaContainer = styled.div({
    display: 'flex',
    minHeight: '35px',
    alignItems: 'center',
});

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerMobile = styled(Separator)({
    backgroundColor: colors.divider,
    width: '100%',
    height: '1px',
});

const TicketNav = () => {
    return (
        <BigTabNav>
            <SmallerBigNavItem end to={`.`}>
                {L('ticket')}
            </SmallerBigNavItem>
            <SmallerBigNavItem to={`notes`}>{L('notes')}</SmallerBigNavItem>
            <SmallerBigNavItem to={`log`}>{L('log')}</SmallerBigNavItem>
        </BigTabNav>
    );
};

const TicketTitle = () => {
    const { id } = useParams<{ id: string }>();
    const { data: ticket, isFetched } = useGetTicket(id);
    if (!isFetched) return null;
    return (
        <>
            <Divider orientation="vertical" />
            <BigNavItem to={`.`}>{ticket?.title ?? L('ticket')}</BigNavItem>
        </>
    );
};

const TicketsPage = () => {
    return (
        <>
            <Routes>
                <Route
                    path="/tickets/*"
                    element={
                        <>
                            <PageTitle>{L('tickets')}</PageTitle>
                            <BigTabNav>
                                <BigNavItem end to={'.'}>
                                    {L('active')}
                                </BigNavItem>
                                <BigNavItem end to={'./archive'}>
                                    {L('archive')}
                                </BigNavItem>
                                <Routes>
                                    <Route path={'archive'} element={null} />
                                    <Route path={':id/*'} element={<TicketTitle />} />
                                    <Route path={'archive/:id/*'} element={<TicketTitle />} />
                                </Routes>
                            </BigTabNav>
                            <Routes>
                                <Route path=":id/*" element={<TicketNav />} />
                                <Route path="archive" element={null} />
                                <Route path="archive/:id/*" element={<TicketNav />} />
                            </Routes>
                            <Outlet />
                        </>
                    }
                >
                    <Route index element={<TicketsList />} />
                    <Route
                        path="new"
                        element={
                            <CardWithDivider
                                topArea={
                                    <TopAreaContainer>
                                        <CardTitle>{L('new_ticket')}</CardTitle>
                                    </TopAreaContainer>
                                }
                                mainArea={<EditTicket isNewTicket />}
                            />
                        }
                    />
                    <Route
                        path=":id"
                        element={
                            <ModalProvider>
                                <PrimaryTicket>
                                    <PageWrapper>
                                        {/* Uses HandleInitialTicketData to unmount EditTicket */}
                                        <HandleInitialTicketData />
                                        <DividerContainer>
                                            <Divider decorative orientation="vertical" />
                                        </DividerContainer>
                                        <DividerContainerMobile>
                                            <DividerMobile decorative orientation="vertical" />
                                        </DividerContainerMobile>
                                        <CustomerCommunication />
                                    </PageWrapper>
                                </PrimaryTicket>
                            </ModalProvider>
                        }
                    />
                    <Route path=":id/log" element={<Log />} />
                    <Route path=":id/notes" element={<Notes />} />
                    <Route path={'archive'} element={<TicketsArchive />} />
                    <Route path="archive/:id/log" element={<Log />} />
                    <Route path="archive/:id/notes" element={<Notes readOnly />} />
                    <Route
                        path="archive/:id"
                        element={
                            <PrimaryTicket readOnly>
                                <PageWrapper>
                                    <HandleInitialTicketData readOnly />
                                    <DividerContainer>
                                        <Divider decorative orientation="vertical" />
                                    </DividerContainer>
                                    <DividerContainerMobile>
                                        <DividerMobile decorative orientation="vertical" />
                                    </DividerContainerMobile>
                                    <CustomerCommunication readOnly />
                                </PageWrapper>
                            </PrimaryTicket>
                        }
                    />
                </Route>
            </Routes>
        </>
    );
};

export default TicketsPage;
