import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import {
    useGetProjectCommunes,
    useGetProjectOwners,
    useGetProjectsOnStatus,
} from '../../hooks/projectHooks/projectQueries';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import { Button } from '@ourliving/ourliving-ui';
import CardTitle from '../../../../components/typography/CardTitle';
import TopAreaContainer from '../../components/TopAreaContainer';
import { margin } from '../../../../components/Shared/Style/margin';
import AddProjectFromTemplate from '../../components/Projects/AddProjectFromTemplate';
import Dialog from '../../../../components/Dialog/Dialog';
import GenericTable from '../../components/GenericTable';
import { SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import columns from '../../components/Projects/ProjectColumns';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import FilterSearchContainer from '../../components/emotion/Filters/FilterSearchContainer';
import Row from '../../components/emotion/Row';
import ContentContainer from '../../components/emotion/ContentContainer';
import TableFilter from '../../components/TableFilter';
import { useGetUsedProjectTypes } from '../../hooks/projectHooks/projectTypeQueries';
import ChosenFilters from '../../components/ChosenFilters/ChosenFilters';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';
import { useLocalStorage } from 'react-use';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';

type Filters = {
    project_type: { value: string; label: string }[];
    project_owner: { value: string; label: string }[];
    commune: { value: string; label: string }[];
};

export default function ProjectPage({ projectStatus }: { projectStatus: 'transferred' | 'active' | 'both' }) {
    const [searchParams, setSearchParams] = useSearchParams();

    const searchField = searchParams.get('searchField') ?? '';

    const [localStorageValue, setLocalStorageValue] = useLocalStorage<Record<string, boolean>>(
        'bindersProjectColumnVisibilityState',
        { image: false },
    );

    const { data: projectTypesData } = useGetUsedProjectTypes(projectStatus);
    const { data: projectOwnersData } = useGetProjectOwners(projectStatus);
    const { data: projectCommunesData } = useGetProjectCommunes(projectStatus);

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const options = [
        { name: 'project_type_literally', value: 'project_type', newValue: 'setSelectedProjectType', id: 1 },
        { name: 'project_owner', value: 'project_owner', newValue: 'setSelectedProjectOwner', id: 2 },
        { name: 'postal_locality', value: 'commune', newValue: 'setSelectedCommune', id: 3 },
    ];

    const filterOptions: Filters = {
        project_type: [],
        project_owner: [],
        commune: [],
    };

    projectTypesData?.forEach((data) => {
        filterOptions.project_type.push({
            value: data.project_type.toString(),
            label: L(data.name),
        });
    });
    projectOwnersData?.forEach((data) => {
        filterOptions.project_owner.push({
            value: data.user_id.toString(),
            label: data.name ?? L('name_missing'),
        });
    });
    projectCommunesData?.forEach((data) => {
        filterOptions.commune.push({
            value: data.commune,
            label: data.commune,
        });
    });

    const projectTypesParams = searchParams.getAll('project_type');

    const projectOwnersParams = searchParams.getAll('project_owner');

    const communesParams = searchParams.getAll('commune');

    const setSelectedProjectType = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('project_type', onlyValues);
    };

    const setSelectedProjectOwner = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('project_owner', onlyValues);
    };

    const setSelectedCommune = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('commune', onlyValues);
    };

    const selectedFiltersWithLabel = {
        project_type: projectTypesParams?.flatMap((type) => {
            const option = filterOptions.project_type.find((filter) => filter.value === type);
            return option ?? [];
        }),
        project_owner: projectOwnersParams.flatMap((owner) => {
            const option = filterOptions.project_owner.find((filter) => filter.value === owner);
            return option ?? [];
        }),
        commune: communesParams.flatMap((commune) => {
            const option = filterOptions.commune.find((filter) => filter.value === commune);
            return option ?? [];
        }),
    };

    const updateFilter = (key: string, values: string[]) => {
        const valuesAsString = values.map((val) => String(val));
        if (values.length === 0) searchParams.delete(key);
        searchParams.delete('page');
        setSearchParams((searchParams) => {
            if (values.length > 0) {
                searchParams.delete(key);
                valuesAsString.forEach((val) => searchParams.append(key, val));
            } else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
    };

    const clearInputParams = (key: string, value: string, searchParams: URLSearchParams) => {
        const filteredParams = searchParams.getAll(key).filter((param) => param !== value);
        searchParams.delete(key);
        filteredParams.forEach((val) => searchParams.append(key, val));
        return searchParams;
    };

    const clearInput = (type: keyof Filters, value: string) => {
        if (type === 'project_type') {
            setSearchParams((searchParams) => {
                return clearInputParams('project_type', value, searchParams);
            });
        }

        if (type === 'project_owner') {
            setSearchParams((searchParams) => {
                return clearInputParams('project_owner', value, searchParams);
            });
        }

        if (type === 'commune') {
            setSearchParams((searchParams) => {
                return clearInputParams('commune', value, searchParams);
            });
        }
    };

    const optionMetadata = [
        {
            name: 'project_type',
            initialTexts: [L('project_type_literally'), L('project_type_literally')],
            api: 'project_type',
        },
        { name: 'project_owner', initialTexts: [L('project_owner'), L('project_owner')], api: 'project_owner' },
        {
            name: 'commune',
            initialTexts: [L('property_postal_city'), L('property_postal_city')],
            api: 'commune',
        },
    ];

    const [sorting, setSorting] = useState<SortingState>([]);

    const { data, status } = useGetProjectsOnStatus({
        projectStatus,
        search: searchField,
        sorting,
        filter: {
            project_type: projectTypesParams,
            project_owner: projectOwnersParams,
            commune: communesParams,
        },
    });

    const memoizedData = useMemo(() => data ?? [], [data]);
    const tableData = useReactTable({
        data: memoizedData,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        manualSorting: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        state: { sorting, columnVisibility: localStorageValue },
        onColumnVisibilityChange: setLocalStorageValue,
        onSortingChange: setSorting,
    });

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const titleToRender = () => {
        switch (projectStatus) {
            case 'active':
                return 'active_projects';
            case 'transferred':
                return 'transferred_projects';
            default:
                return 'all_projects';
        }
    };

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <AddProjectFromTemplate handleCloseModal={() => setOpen(false)} />
            </Dialog>

            <H1>{L('project_literally')}</H1>

            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to={'/binders'}>
                        {L('active')}
                    </BigNavItem>
                    <BigNavItem end to={'/binders/transferred'}>
                        {L('transferred_plural')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{`${L(titleToRender())} (${memoizedData.length})`}</CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s, alignItems: 'center' }}>
                            <Button
                                onClick={() => {
                                    setOpen(true);
                                }}
                            >
                                {L('new_project_from_template')}
                            </Button>
                            <Button role="link" onClick={() => navigate(`new`)}>
                                {L('new_project')}
                            </Button>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <FilterSearchContainer>
                            <ContentContainer>
                                <TableFilter
                                    options={options}
                                    selected={selectedFiltersWithLabel}
                                    filterOptions={filterOptions}
                                    setSelected={{
                                        setSelectedProjectType,
                                        setSelectedProjectOwner,
                                        setSelectedCommune,
                                    }}
                                />
                            </ContentContainer>
                            <div style={{ padding: '1rem 0 1rem 0', marginLeft: 'auto', display: 'flex', gap: '1rem' }}>
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        display: 'flex',
                                        columnGap: '0.5rem',
                                        alignItems: 'center',
                                    }}
                                >
                                    {`${L('show_with_image')}:`}
                                    <Checkbox
                                        checked={tableData.getColumn('image')?.getIsVisible()}
                                        onChange={tableData.getColumn('image')?.getToggleVisibilityHandler()}
                                    />
                                </div>
                                <Row>
                                    <GlobalFilter
                                        filter={searchField}
                                        setFilter={handleSearch}
                                        placeholder={L('search')}
                                    />
                                </Row>
                            </div>
                        </FilterSearchContainer>
                        {filterOptions && (
                            <ChosenFilters
                                inputs={selectedFiltersWithLabel}
                                onRemove={clearInput}
                                optionMetadata={optionMetadata}
                            />
                        )}
                        <GenericTable table={tableData} shouldFillWidth={false} />
                    </>
                }
            />
        </>
    );
}
