import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import PackageProductsLibraryComponent from '../components/PackageProductsLibraryComponent';

const PackageProductsLibraryPage = () => {
    const { id } = useParams<{ id: string }>();
    if (id) return <PackageProductsLibraryComponent productId={id} />;
    return null;
};

export default PackageProductsLibraryPage;
