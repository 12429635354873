import styled from '@emotion/styled';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import { Button } from '@ourliving/ourliving-ui';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import { zodResolver } from '@hookform/resolvers/zod';
import { L } from '../../../../lib/i18n';
import { useGetProjectTypes } from '../../../binders/hooks/projectHooks/projectTypeQueries';
import { z } from 'zod';
import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import {
    getProjectTemplatesKeys,
    useGetProjectTemplateById,
} from '../../hooks/ProjectTemplates/projectTemplateQueries';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom-v5-compat';
import Quill from '../../../../components/Quill/Quill';

const AddProjectTemplateSchema = z.object({
    name: z.string().min(1, 'error_required'),
    project_type: z.number({ required_error: 'error_required' }),
    contact_email: z.string().email().optional().or(z.literal('')),
    service_email: z.string().email().optional().or(z.literal('')),
    service_info: z.string().optional(),
});

export type AddProjectTemplate = z.infer<typeof AddProjectTemplateSchema>;

const ButtonWrapper = styled.div({
    display: 'flex',
    width: '100%',
    gap: '1rem',
    gridColumn: '1 / -1',
    justifyContent: 'end',
    paddingTop: '1rem',
});

const Form = styled.form({
    display: 'grid',
    alignItems: 'start',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
    gap: '0 1rem',
    width: '100%',
});

type Props = {
    id?: number;
};

const ProjectTemplateForm = ({ id }: Props) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { data: template } = useGetProjectTemplateById(id);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
        watch,
    } = useForm<AddProjectTemplate>({
        resolver: zodResolver(AddProjectTemplateSchema),
        values: {
            name: template?.name ?? '',
            contact_email: template?.contact_email ?? undefined,
            service_email: template?.service_email ?? undefined,
            service_info: template?.service_info ?? undefined,
            project_type: template?.project_type ? template?.project_type : 0,
        },
        resetOptions: {
            keepDirtyValues: false,
        },
        defaultValues: {
            project_type: template?.project_type ? template?.project_type : 0,
            contact_email: '',
            service_email: '',
            service_info: '',
            name: '',
        },
    });

    const { mutate: addTemplate } = useMutation({
        mutationFn: (value: AddProjectTemplate) =>
            trpc.TemplatesApi.addProjectTemplate.mutate({
                ...value,
            }),
    });
    const { mutate: updateTemplate } = useMutation({
        mutationFn: (value: AddProjectTemplate & { projectId: number }) =>
            trpc.TemplatesApi.updateProjectTemplate.mutate({
                ...value,
            }),
    });

    const { data: projectTypes } = useGetProjectTypes();

    const projectTypeOptions = projectTypes?.map((type) => ({ value: type.id.toString(), label: L(type.name) })) || [];

    return (
        <Form
            onSubmit={handleSubmit((data) => {
                if (id) {
                    return updateTemplate(
                        { ...data, projectId: id },
                        {
                            onSuccess: () => {
                                toast.success(L('project_template_updated'));
                            },
                            onError: () => {
                                toast.error(L('an_error_occurred'));
                            },
                            onSettled: async (res) => {
                                queryClient.invalidateQueries(getProjectTemplatesKeys.lists());
                                queryClient.invalidateQueries(getProjectTemplatesKeys.detail(res?.id));
                            },
                        },
                    );
                }

                return addTemplate(data, {
                    onSuccess: () => {
                        toast.success(L('project_template_created'));
                    },
                    onError: () => {
                        toast.error(L('an_error_occurred'));
                    },
                    onSettled: async (res) => {
                        queryClient.invalidateQueries(getProjectTemplatesKeys.lists());
                        queryClient.invalidateQueries(getProjectTemplatesKeys.detail(res?.id));
                        res?.id && navigate(`../${res.id}`);
                    },
                });
            })}
        >
            <ErrorAndInputWrapper errorMsg={L(errors.name?.message ?? '')}>
                <Label title={L('name')} />
                <InputWithError isError={!!errors.name} type="text" {...register('name')} />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.project_type?.message ?? '')}>
                <Label title={L('project_type_literally')} />

                <Controller
                    control={control}
                    name="project_type"
                    render={({ field: { onChange, value } }) => {
                        return (
                            <RadixSelect
                                isError={!!errors.project_type}
                                onValueChange={(valueChange) => {
                                    console.log('onValueChange');
                                    onChange(+valueChange);
                                }}
                                value={value.toString()}
                                options={projectTypeOptions}
                            />
                        );
                    }}
                />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.contact_email?.message ?? '')}>
                <Label title={L('contact_email')} />
                <InputWithError isError={!!errors.contact_email} type="text" {...register('contact_email')} />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.service_email?.message ?? '')}>
                <Label title={L('service_email')} />
                <InputWithError isError={!!errors.service_email} type="text" {...register('service_email')} />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper
                style={{
                    gridColumn: '1 / -1',
                }}
                errorMsg={L(errors.service_info?.message ?? '')}
            >
                <Label title={L('service_info')} />

                <Controller
                    name="service_info"
                    control={control}
                    render={({ field }) => {
                        return <Quill {...field} value={field.value} />;
                    }}
                />
            </ErrorAndInputWrapper>

            <ButtonWrapper>
                <Button disabled={isSubmitting} type="submit">
                    {L('save')}
                </Button>
            </ButtonWrapper>
        </Form>
    );
};

export default ProjectTemplateForm;
