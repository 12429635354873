import { Maintenance } from '@app/api/models/Maintenances';
import dayjs from 'dayjs';

const useGenerateMaintenanceIntevalItems = (maintenance?: Partial<Maintenance>, endYear?: number) => {
    if (!maintenance) return [];
    const item: Partial<Maintenance> = {
        description: maintenance.description,
        maintenance_category: maintenance.maintenance_category,
        maintenance_status: maintenance.maintenance_status,
        maintenance_type: maintenance.maintenance_type,
        maintenance_unit: maintenance.maintenance_unit,
        planned_cost: maintenance.planned_cost,
        planned_date: maintenance.planned_date,
        interval: maintenance.interval,
        name: maintenance.name
    };
    if (!endYear) return [];
    if (!item) return [];
    if (!item.interval) return [];
    if (!dayjs(item.planned_date).isValid()) return [];
    const interval = +item.interval;

    const maxYearSpan = 50;

    const start = +dayjs(item.planned_date).format('YYYY');

    const newMaintenanceList: Partial<Maintenance>[] = [];
    let previousInterval = dayjs(item.planned_date).format('YYYY-MM-DD');

    for (let i = +start; i < endYear; i = i + interval) {
        if (i + interval < maxYearSpan + start) {
            const newMaintenance: Partial<Maintenance> = {
                ...item,
                planned_date: dayjs(previousInterval)
                    .add(item.interval, 'year')
                    .format('YYYY-MM-DD')
            };
            newMaintenanceList.push(newMaintenance);
            previousInterval = dayjs(previousInterval)
                .add(item.interval, 'year')
                .format('YYYY-MM-DD');
        }
    }

    return newMaintenanceList;
};

export default useGenerateMaintenanceIntevalItems;
