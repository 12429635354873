import { useMutation } from 'react-query';
import { trpc } from '../../../lib/trpc';

const useDeletePropertyDocuments = () => {
    return useMutation(async ({ documentIds, propertyId }: { documentIds: number[]; propertyId: number }) => {
        return await Promise.all(
            documentIds.map(async (docId) => {
                return trpc.BindersApi.removePropertyDocument.mutate({ id: docId, propertyId });
            }),
        );
    });
};

export default useDeletePropertyDocuments;
