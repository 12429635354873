import React from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import ContentInformationForm, {
    AddContentInformation,
} from '../../components/ContentInformation/ContentInformationForm';
import useAddContentInformation from '../../hooks/ContentInformation/useAddContentInformation';
import toast from 'react-hot-toast';
import { getErrorMessage } from '@app/shared/lib/getErrorMessage';

const AddContentInformationPage = () => {
    const navigate = useNavigate();

    const { mutate: addContentInformationItem } = useAddContentInformation();
    const { id } = useParams<{ id: string }>();

    const onSubmit: SubmitHandler<AddContentInformation> = (data) => {
        if (!id) return;
        addContentInformationItem(
            { information: data, projectId: +id },
            {
                onSuccess: ({ id: informationId }) => {
                    toast.success(L('content_information_added'));
                    navigate(`../${informationId}`);
                },
                onError: (err) => {
                    toast.error(L(getErrorMessage(err)));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_content_information')}</CardTitle>}
            mainArea={<ContentInformationForm onSubmit={onSubmit} />}
        />
    );
};

export default AddContentInformationPage;
