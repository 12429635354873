import styled from '@emotion/styled';
import React from 'react';
import { L } from '../../../lib/i18n';
import useGetPropertiesByProject from '../hooks/useGetPropertiesByProject';
import PropertyComponent from './PropertyComponent';
import BackArrow from '../../../public/assets/backarrow.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import useGetProjects from '../hooks/useGetProjects';
interface Props {
    goBack?: string;
    display?: string;
}
const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
    marginLeft: '12px',
    ['@media screen and (min-width: 1024px)']: {
        marginLeft: '0px'
    }
});

const StyledBackArrow = styled(BackArrow)({
    marginLeft: '10px',
    width: '24px',
    height: '24px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const BreadCrumbContainer = styled.div({
    display: 'flex',
    marginLeft: '12px',
    marginTop: '12px',
    ['@media screen and (min-width: 1024px)']: {
        display: 'none'
    }
});

const Properties = (p: Props) => {
    const { data: properties } = useGetPropertiesByProject();
    const { data: projects } = useGetProjects();

    const params = useParams<{ projectId: string; propertyId: string; spaceId: string }>();

    // console.log('params', params);

    const projectById = projects?.find(project => project.id === +params.projectId);

    const url = useLocation();
    const history = useHistory();
    const prevUrl = url.pathname.replace(`/project/${params.projectId}`, '');

    if (!properties) return null;

    return (
        <>
            <StyledBackArrow onClick={() => history.push(prevUrl)} />
            <BreadCrumbContainer>
                <Breadcrumb breadcrumbs={[{ name: projectById?.name, to: prevUrl }]} />
            </BreadCrumbContainer>
            <Header>{L('Bostäder')}</Header>
            {properties.length > 0 ? (
                <>
                    {properties.map(property => (
                        <PropertyComponent property={property} key={property.id} />
                    ))}
                </>
            ) : (
                'Här var det tomt'
            )}
        </>
    );
};

export default Properties;
