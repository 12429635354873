import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateProductDocTypeTemplateProperty = () => {
    return useMutation(
        async (params: { docId: number; document_type: number; document_subtype: number; entityId: number }) => {
            return trpc.ProductsApi.updateProductDocTypeTemplateProperty.mutate(params);
        },
    );
};

export default useUpdateProductDocTypeTemplateProperty;
