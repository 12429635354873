import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import Input from '../../../../components/Forms/Input';
import { TextArea } from '@ourliving/ourliving-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@ourliving/ourliving-ui';
import { AddSpace, AddSpaceSchema } from '../../schemas';
import StyledCross from '../emotion/StyledCross';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import { useGetPropertySpace } from '../../hooks/spaceHooks/spaceQueries';
import { margin } from '../../../../components/Shared/Style/margin';

const InnerFormWrapper = styled.div((props: { mediaQueryWidth: string | undefined }) => ({
    display: 'grid',
    gap: '0 1rem',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: `
    "name  name"
    "space_type  space_type"
    "description  description"
    "dimension  dimension"
    "area  area"
    `,
    [`@media screen and (min-width: 1024px)`]: {
        width: props.mediaQueryWidth ?? '50%',
    },
}));

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const RestyledTextArea = styled(TextArea)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

type Props = {
    spaceTypeOptions: { value: number; label: string }[];
    onSubmit: (contact: AddSpace) => void;
    spaceData?: ReturnType<typeof useGetPropertySpace>['data'];
    handleDeleteSpace?: () => void;
    mediaQueryWidth?: string;
};

const SpaceForm = ({ spaceTypeOptions, onSubmit, spaceData, handleDeleteSpace, mediaQueryWidth }: Props) => {
    const [confirmDelete, setConfirmDelete] = useState(true);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddSpace>({
        resolver: zodResolver(AddSpaceSchema),
        defaultValues: {
            area: spaceData?.area ?? undefined,
            description: spaceData?.description ?? undefined,
            length: spaceData?.length ?? undefined,
            width: spaceData?.width ?? undefined,
            name: spaceData?.space_name ?? undefined,
            space_type: spaceData?.entity_subtype ?? undefined,
        },
    });
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <InnerFormWrapper mediaQueryWidth={mediaQueryWidth}>
                <ErrorAndInputWrapper style={{ gridArea: 'name' }} errorMsg={L(errors.name?.message ?? '')}>
                    <Label title={L('name')} />
                    <RestyledInput isError={!!errors.name} type="text" {...register('name')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper style={{ gridArea: 'space_type' }} errorMsg={L(errors.space_type?.message ?? '')}>
                    <Label title={L('space_type')} />
                    <Controller
                        control={control}
                        name="space_type"
                        render={({ field: { onChange, value } }) => (
                            <RadixSelect
                                isError={!!errors.space_type}
                                onValueChange={onChange}
                                value={value}
                                options={spaceTypeOptions}
                            />
                        )}
                    />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper
                    style={{ gridArea: 'description' }}
                    errorMsg={L(errors.description?.message ?? '')}
                >
                    <Label title={L('description')} />
                    <RestyledTextArea isError={!!errors.description} {...register('description')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper style={{ gridArea: 'dimension' }}>
                    <Label title={L('property_dimension_label')} />
                    <div
                        style={{
                            display: 'flex',
                            columnGap: '0.5rem',
                        }}
                    >
                        <ErrorAndInputWrapper
                            style={{ minHeight: 0, rowGap: 0 }}
                            errorMsg={L(errors.length?.message ?? '')}
                        >
                            <RestyledInput isError={!!errors.length} type="number" {...register('length')} />
                        </ErrorAndInputWrapper>
                        <div
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '32px' }}
                        >
                            <StyledCross />
                        </div>
                        <ErrorAndInputWrapper
                            style={{ minHeight: 0, rowGap: 0 }}
                            errorMsg={L(errors.width?.message ?? '')}
                        >
                            <RestyledInput isError={!!errors.width} type="number" {...register('width')} />
                        </ErrorAndInputWrapper>
                    </div>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper style={{ gridArea: 'area' }} errorMsg={L(errors.area?.message ?? '')}>
                    <Label title={L('area')} />
                    <RestyledInput isError={!!errors.area} type="number" {...register('area')} />
                </ErrorAndInputWrapper>
            </InnerFormWrapper>

            <ButtonWraper>
                {handleDeleteSpace &&
                    (confirmDelete ? (
                        <Button
                            type="button"
                            style={{ marginRight: margin.s }}
                            onClick={(e) => {
                                e.preventDefault();
                                setConfirmDelete(false);
                            }}
                        >
                            {L('delete')}
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            style={{ marginRight: margin.s }}
                            onClick={(e) => {
                                e.preventDefault();
                                handleDeleteSpace();
                            }}
                            variant="danger"
                        >
                            {L('confirm')}
                        </Button>
                    ))}

                <Button type="submit">{L('save')}</Button>
            </ButtonWraper>
        </form>
    );
};

export default SpaceForm;
