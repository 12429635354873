import React, { useState } from 'react';
import SearchIcon from '../../components/Icon/search.svg';
import styled from '@emotion/styled';
import { colors } from '../Shared/Style/colors';
import { L } from '../../lib/i18n';

export interface SearchInputProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

const Form = styled.form({
    marginTop: '2rem'
});

const Input = styled.input(() => {
    return {
        border: 'none',
        gridArea: 'input',
        fontSize: '20px',
        color: colors.textColor2,
        '&:focus': {
            outline: 'none'
        },
        '::placeholder': {
            fontStyle: 'italic'
        }
    };
});

const SearchBar = styled.div(() => {
    return {
        display: 'grid',
        gridTemplateAreas: `
        "icon input"
        `,
        gridTemplateColumns: 'min-content auto',

        columnGap: '0.7rem',
        padding: '0.7rem',
        borderRadius: '8px',
        backgroundColor: colors.white,
        '&:focus': {
            outline: 'solid'
        }
    };
});

const Label = styled.label({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%'
});

const StyledSearchIcon = styled(SearchIcon)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '22px',
    width: '22px'
});

const Search: React.FC<SearchInputProps> = ({ setValue, ...rest }) => {
    return (
        <Form {...rest}>
            <Label aria-label={L('search')} htmlFor="search" />
            <SearchBar>
                <StyledSearchIcon />
                <Input id="search" type="text" placeholder={L('search')} onChange={e => setValue(e.target.value)} />
            </SearchBar>
        </Form>
    );
};

export default Search;
