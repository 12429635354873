import React from 'react';
import { Route } from 'react-router-dom';
import { ForgottenPasswordPage } from './ForgottenPasswordPage';
import { SplashScreenPage } from '../login/SplashScreen';
import { ConfirmResetPage } from './ConfirmResetPage';

export const routes = [
    // <Route key="0" exact path="/" component={LoginPage} />,
    <Route key="1" exact path="/forgottenpassword" component={ForgottenPasswordPage} />,
    <Route key="2" exact path="/confirmaccount" component={ConfirmResetPage} />,
    <Route key="3" exact path="/resetpassword" component={ConfirmResetPage} />,
    <Route key="4" exact path="/splash" component={SplashScreenPage} />,
];
