import React, { FC } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../../components/Shared/Style/colors';
import EditProductImage from './EditProductImage';

const Image = styled.div((props: { url?: string | null }) => ({
    width: '100%',
    paddingTop: '56.25%',
    display: 'flex',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundImage: `url(${props.url})`,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    border: props.url ? '0px solid' : '1px solid',
    borderColor: colors.black80,
    borderRadius: '4px'
}));
const NoImage = styled(Image)(() => ({
    backgroundImage: 'none',
    backgroundColor: 'grey'
}));

type Props = {
    url?: string;
};

const ProductImage: FC<Props> = ({ url }) => {
    return (
        <>
            {url ? (
                <Image url={url} />
            ) : (
                <>
                    <NoImage>
                        <EditProductImage />
                    </NoImage>
                </>
            )}
        </>
    );
};

export default ProductImage;
