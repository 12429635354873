import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PropertiesApi from '@app/api/public/PropertiesApi';

const getPropertyById = async (id: string) => {
    const r = await PropertiesApi.listSpaces(id);
    return r;
};

const useGetPropertyById = () => {
    const { propertyId } = useParams<{ propertyId: string }>();
    return useQuery(['getPropertyById', propertyId], () => getPropertyById(propertyId));
};

export default useGetPropertyById;
