import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Maintenance } from '@app/api/public/MaintenancesApi';
import { Loading } from '../../../components/Loading/Loading';
import useGenerateMaintenanceIntevalItems from '../hooks/useGenerateMaintenanceIntevalItems';
import useValidIntervalEndDates from '../hooks/useValidIntervalEndDate';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import useSaveMultipleMaintance from '../hooks/useSaveMultipleMaintance';
import { useHistory, useParams } from 'react-router-dom';
import AddMaintenanceForm from '../components/AddMaintenanceForm';
import MaintenancePreview from '../components/MaintenancePreview';
import useGetServicePartnersByCategory from '../hooks/useGetServicePartnersByCategory';

const Container = styled.div({
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '4px',
    width: '100%',
    alignSelf: 'start'
});

const AddMaintenacePage = () => {
    const [view, setView] = useState<'edit' | 'preview'>('edit');
    const history = useHistory();
    const { projectId } = useParams<{ projectId: string }>();
    const { data: categoryList, status: categoryStatus } = useGetSettingsListByType('category');
    const { data: statusList, status: statusListStatus } = useGetSettingsListByType('status');
    const { data: typeList, status: typeStatus } = useGetSettingsListByType('type');

    const [maintenanceItem, setMaintenanceItem] = useState<Partial<Maintenance>>();
    const { data: servicePartners } = useGetServicePartnersByCategory(maintenanceItem?.maintenance_category?.id);
    const { mutate: save, status } = useSaveMultipleMaintance();

    const validIntervals = useValidIntervalEndDates(maintenanceItem?.planned_date, maintenanceItem?.interval);
    const [intervalEndYear, setIntervalEndYear] = useState<number | undefined>();
    const maintenanceIntevalItems = useGenerateMaintenanceIntevalItems(maintenanceItem, intervalEndYear);

    if (statusListStatus === 'loading') return <Loading />;
    if (categoryStatus === 'loading') return <Loading />;
    if (typeStatus === 'loading') return <Loading />;

    const handleChange = (value: Partial<Maintenance>) => {
        setMaintenanceItem(prev => {
            return { ...prev, ...value };
        });
    };

    const handleSave = () => {
        save(
            {
                maintenaces: [{ ...maintenanceItem }, ...maintenanceIntevalItems].map(maintenanceItem => {
                    return {
                        ...maintenanceItem,
                        planned_date: maintenanceItem?.planned_date ? maintenanceItem.planned_date : undefined,
                        completed_date: maintenanceItem?.completed_date ? maintenanceItem.completed_date : undefined
                    };
                })
            },
            { onSuccess: () => history.push(`/maintenance/${projectId}`) }
        );
    };

    const formProps = {
        categoryList,
        handleChange,
        maintenanceItem,
        setView,
        statusList,
        typeList,
        servicePartners
    };

    const previewProps = {
        handleChange,
        intervalEndYear,
        maintenanceItem,
        setIntervalEndYear,
        validIntervals,
        setView,
        handleSave,
        status,
        maintenanceIntevalItems
    };

    return (
        <Container>
            {view === 'edit' && <AddMaintenanceForm {...formProps} />}
            {view === 'preview' && <MaintenancePreview {...previewProps} />}
        </Container>
    );
};

export default AddMaintenacePage;
