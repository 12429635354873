import { useMutation, useQueryClient } from 'react-query';
import AlertsApi, { Alert } from '@app/api/public/AlertsApi';
import { alertQuqeryKeys } from './useAlertQueries';

type Variables = { alert: Partial<Alert> };

const saveAlert = async (variables: Variables) => {
    return await AlertsApi.save(variables.alert);
};

const useSaveAlert = () => {
    const queryClient = useQueryClient();

    return useMutation((variables: Variables) => saveAlert(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(alertQuqeryKeys.all);
        }
    });
};

export default useSaveAlert;
