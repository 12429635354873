import { useQuery } from 'react-query';
import BindersApi from '@app/api/public/BindersApi';

export const getAddProductKeys = {
    all: ['binders-add-product'] as const,
    listBrands: () => [...getAddProductKeys.all, 'listBrands'] as const,
    listSuppliers: () => [...getAddProductKeys.all, 'listSuppliers'] as const,
    listProffesions: () => [...getAddProductKeys.all, 'listProffesions'] as const,
    listProductTypes: () => [...getAddProductKeys.all, 'listProductTypes'] as const,
    listProperties: (projectId: number | null) => [...getAddProductKeys.all, { projectId }] as const,
    listSpaces: (propertyId?: number) => [...getAddProductKeys.all, { propertyId }] as const,
};

export function useListBrands() {
    return useQuery(getAddProductKeys.listBrands(), () => BindersApi.listBrands());
}

export function useListSuppliers() {
    return useQuery(getAddProductKeys.listSuppliers(), () => BindersApi.listSuppliers());
}

export function useListProffesions() {
    return useQuery(getAddProductKeys.listProffesions(), () => BindersApi.listProffesions());
}

export function useListProductTypes() {
    return useQuery(getAddProductKeys.listProductTypes(), () => BindersApi.listProductTypes());
}

export function useListProperties(projectId: number | null) {
    return useQuery(
        getAddProductKeys.listProperties(projectId),
        () => {
            if (!projectId) {
                return Promise.reject(new Error('no project id provided'));
            }
            return BindersApi.listProperties(projectId);
        },
        {
            enabled: projectId !== null,
        },
    );
}

const listSpaces = (propertyId?: number) => {
    if (!propertyId) return;
    return BindersApi.listSpaces(propertyId);
};

export function useListSpaces(propertyId?: number) {
    return useQuery(
        getAddProductKeys.listSpaces(propertyId),
        () => {
            if (!propertyId) {
                return Promise.reject(new Error('no property id provided'));
            }
            return listSpaces(propertyId);
        },
        {
            enabled: !!propertyId,
        },
    );
}
