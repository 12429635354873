import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProjectKeys } from './projectQueries';

const useAddProjectFromTemplate = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ new_name, project_id }: { project_id: number; new_name: string }) => {
            return trpc.TemplatesApi.newProjectFromTemplate.query({
                toProjectName: new_name,
                fromProjectId: project_id,
            });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.lists());
            },
        },
    );
};

export default useAddProjectFromTemplate;
