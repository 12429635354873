import React from 'react';
import styled from '@emotion/styled';

const ImageArea = styled.div({
    width: 'calc(100%/3)',
    position: 'relative'
});

const Logo = styled.img({
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100px',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    right: 0
});

export const PropertyCompanyLogo = ({ image }) => {
    return (
        <ImageArea>
            <Logo src={image} />
        </ImageArea>
    );
};
