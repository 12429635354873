import React from 'react';
import { Card } from '../../../../components/Layout/Card';
import { style } from '@app/shared/lib/Style';
import SettingsByType from '../components/SettingsByType';

export default function PriorityPage() {
    return (
        <Card background={style.colors.white}>
            <SettingsByType type="priority" />
        </Card>
    );
}
