import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export default function useRemoveTemplateImage() {
    return useMutation(async ({ documentId, projectId }: { documentId: number; projectId: number }) => {
        return trpc.TemplatesApi.removeTemplateImage.mutate({
            id: documentId,
            projectId,
        });
    });
}
