import React, { useEffect, useRef, useState } from 'react';
import { default as ReactSelect, OnChangeValue } from 'react-select';
import { useObserver } from 'mobx-react';
import { ChangeFunction } from '../../lib/react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';
import * as Icons from 'react-feather';
import isEqual from 'lodash/isEqual';

interface Option {
    value: any;
    label: any;
}

interface Props {
    style?: React.CSSProperties;
    placeholder?: string;
    label?: string;
    options: Array<Option>;
    name?: string;
    onChange: ChangeFunction<any>;
    value?: any;
    defaultValue?: OnChangeValue<Option, false>;
    isClearable?: boolean;
    disabled?: boolean;
    className?: string;
    error?: boolean;
}

const Option = styled.span({ color: style.colors.primary });

function formatOptionLabel(label, v, meta) {
    if (label && meta && meta.context === 'value') {
        return (
            <>
                {label}: <Option>{v.label}</Option>
            </>
        );
    }
    return v.label;
}
const styles = {
    container: st => ({ ...st, fontSize: '80%', height: 32 }),
    control: st => ({
        ...st,
        backgroundColor: 'transparent',
        borderRadius: 4,
        height: '32px',
        minHeight: '32px'
    }),
    indicatorsContainer: st => ({ ...st, padding: 0 }),
    indicatorSeparator: () => ({ display: 'none' }),
    placeholder: st => ({
        ...st,
        position: 'relative',
        transform: 'inherit',
        maxWidth: 'calc(100% - 8px)',
        cursor: 'pointer'
    }),
    singleValue: st => ({
        ...st,
        position: 'relative',
        transform: 'inherit'
    }),
    option: st => ({
        ...st,
        cursor: 'pointer'
    }),
    menu: st => ({ ...st, minWidth: '12em' }),
    input: st => ({ ...st, caretColor: 'transparent', maxWidth: 0 })
};

const components = {
    DropdownIndicator() {
        return <Icons.ChevronDown color="#999" style={{ paddingRight: 3 }} size={16} />;
    },
    ClearIndicator(props) {
        const click = evt => {
            props.clearValue();
            evt.stopPropagation();
        };
        return <Icons.X onMouseDown={click} onClick={click} color="#999" style={{ paddingRight: 3 }} size={16} />;
    }
};

export function Dropdown(props: Props) {
    const ref = useRef(null as any);
    const err_styles = { ...styles, control: st => ({ ...st, borderColor: 'red' }) };

    function onChange(v) {
        props.onChange(v ? v.value : undefined, props.name || '');
    }

    function onMenuOpen() {
        const menuList = ref.current.select as HTMLDivElement;
        if (!menuList) return;

        const menu = menuList.parentElement as HTMLDivElement;
        const menuRect = menu.getClientRects().item(0);
        if (!menuRect) return;

        const windowWidth = window.innerWidth;
        const overflow = menuRect.right - windowWidth + 4;
        if (overflow > 0) {
            menu.style.left = `${parseInt(menu.style.left || '0') - overflow}px`;
        }
    }
    const [selected, setSelected] = useState<OnChangeValue<Option, false>>(null);

    useEffect(() => {
        setSelected(props.options.find(opt => isEqual(opt.value, props.value)) || null);
    }, [props.value]);

    return useObserver(() => (
        <ReactSelect
            ref={ref}
            styles={props.error ? err_styles : styles}
            onMenuOpen={onMenuOpen}
            components={components}
            formatOptionLabel={(v, meta) => formatOptionLabel(props.label, v, meta)}
            onChange={onChange}
            placeholder={props.placeholder || props.label}
            options={props.options}
            value={selected}
            defaultValue={props.defaultValue}
            className={props.className}
            isClearable={props.isClearable}
            theme={theme => ({
                ...theme,
                borderRadius: 0,
                colors: {
                    ...theme.colors,
                    primary50: style.colors.primary50,
                    primary25: style.colors.primary25,
                    primary: style.colors.primary
                }
            })}
        />
    ));
}
