import React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import TodoListPage from './pages/TodoListPage';
import TodoNewPage from './pages/TodoNewPage';
import TodoDetailPage from './pages/TodoDetailPage';

const TodoPage = () => {
    return (
        <Routes>
            <Route path="/todos">
                <Route index element={<TodoListPage />} />
                <Route path=":todoId" element={<TodoDetailPage />} />
                <Route path="new" element={<TodoNewPage />} />
            </Route>
        </Routes>
    );
};

export default TodoPage;
