import * as React from 'react';
import styled from '@emotion/styled';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import { L } from '../../../../../lib/i18n';

const Container = styled.div({ paddingRight: '10px' });

interface Props {
    filter: { addon_type?: string };
    setFilter: (filter: any) => void;
}

export const FilterAddonType = (props: Props) => {
    const name = 'addon_type';
    const options = [
        { label: L('standard'), value: 'standard' },
        { label: L('optional'), value: 'optional' }
    ];

    return (
        <Container>
            <Dropdown
                isClearable
                value={props.filter[name]}
                onChange={(value, name) => props.setFilter({ ...props.filter, [name]: value })}
                name={name}
                label={L(name)}
                options={options}
            />
        </Container>
    );
};
