import { useQuery } from 'react-query';
import ServicePartnerApi from '@app/api/public/ServicePartnersApi';

export const servicePartnerDocumentsListQueryKey = 'getServicePartnerDocumentsList';
const useGetServicePartnerDocumentsById = (id?: string | number) => {
    return useQuery(
        [servicePartnerDocumentsListQueryKey, id?.toString()],
        async () => {
            if (!id) return;
            return await ServicePartnerApi.listDocuments(+id);
        },
        {
            enabled: !!id
        }
    );
};

export default useGetServicePartnerDocumentsById;
