import React, { useMemo } from 'react';
import { L } from '../../../../lib/i18n';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardTitle from '../../../../components/typography/CardTitle';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import PropertyForm from '../../components/Properties/PropertyForm';
import { AddProperty } from '../../schemas';
import {
    getPropertiesKeys,
    useGetProperty,
    useGetPropertyImages,
    useGetPropertyTypes,
} from '../../hooks/propertyHooks/propertyQueries';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import useEditProperty from '../../hooks/propertyHooks/useEditProperty';
import useDeleteProperty from '../../hooks/propertyHooks/useDeleteProperty';
import { TRPCClientError } from '@trpc/client';
import styled from '@emotion/styled';
import { colors } from '../../../../components/Shared/Style/colors';
import { Separator } from '../../../../components/Separator/Separator';
import { style } from '../../../../components/Shared/Style';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import Input from '../../../../components/Forms/Input';
import dayjs from 'dayjs';
import Images from '../../components/Draggables/Images';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import useUpdateDocSortBulk from '../../hooks/useUpdatePropertyDocSort';
import useDragEndDnd from '../../../../hooks/useDragEndDndNew';
import { useQueryClient } from 'react-query';
import TopAreaContainer from '../../components/TopAreaContainer';
import useUploadPropertyDocuments from '../../hooks/propertyHooks/useUploadPropertyDocuments';
import useDeletePropertyDocument from '../../hooks/useDeletePropertyDocument';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import { Breadcrumbs, Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadError, onUploadSuccess } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';

export type PropertyTypesOptions = {
    value: string;
    label: string;
}[];

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const RestyledEmptyState = styled(EmptyState)({
    minHeight: 'auto',
    padding: '1rem',
});

const GridContainer = styled.div({
    display: 'grid',
    columnGap: '1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr auto 1fr',
    },
});

const Page = ({ id, propertyId, template }: { id: string; propertyId: string; template: boolean }) => {
    const queryClient = useQueryClient();
    const { data: propertyTypes } = useGetPropertyTypes();
    const { mutate: editProperty } = useEditProperty();
    const { data: property, status } = useGetProperty(+propertyId);
    const { mutate: deleteProperty } = useDeleteProperty();
    const { mutate: deleteDocument } = useDeletePropertyDocument();
    const { mutate: uploadMultiplePropertyDocs } = useUploadPropertyDocuments();
    const { data: images } = useGetPropertyImages(+propertyId);
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateDocSortBulk();
    const handleDragEnd = useDragEndDnd({
        queryKey: getPropertiesKeys.imagesByPropertyId(+propertyId),
        updateSortOrder: updateSortBulk,
        documentableId: +propertyId,
        reverseArray: true,
    });

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') {
            toast.error(`${param.errorCode} ${L('an_error_occurred')}`);
            return;
        }
        uploadMultiplePropertyDocs(
            { files: [param.file], propertyId: +propertyId, documentType: 0 },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries([...getPropertiesKeys.imagesByPropertyId(+propertyId)]);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const navigate = useNavigate();

    const handleDeleteProperty = () => {
        deleteProperty(+propertyId, {
            onSuccess: () => {
                toast.success(`${property?.name} ${L('removed').toLowerCase()}`);
                navigate(`..`);
            },
            onError: (err) => {
                if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
            },
        });
    };

    const handleRemoveImage = (documentId: number) => {
        deleteDocument(
            { documentId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(`${L('picture')} ${L('removed').toLowerCase()}`);
                    queryClient.invalidateQueries(getPropertiesKeys.imagesByPropertyId(+propertyId));
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const propertyTypesOptions = useMemo<PropertyTypesOptions>(() => {
        if (!propertyTypes) return [];
        return propertyTypes?.map((item) => ({ value: item.id, label: L(item.name) }));
    }, [propertyTypes]);

    const onSubmit: SubmitHandler<AddProperty> = (data) => {
        if (!propertyId || !id) return;
        const dataToSave = { ...data, template };
        editProperty(
            { property: dataToSave, projectId: +id, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('updated_property'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const imageData: { id: number; url: string }[] = useMemo(() => {
        if (!images) return [];
        return images.map((image) => {
            return { id: image.id, url: image.signed_url };
        });
    }, [images]);

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const project = useGetProjectName(projectId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'../..'}>{project ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{property?.name ?? L('property')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{property?.name ?? L('property')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="spaces">
                        {L('spaces')}
                    </BigNavItem>
                    <BigNavItem end to="products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="members">
                        {L('members')}
                    </BigNavItem>
                    <BigNavItem end to="contacts">
                        {L('contacts')}
                    </BigNavItem>
                    <BigNavItem end to="todos">
                        {L('todos')}
                    </BigNavItem>
                    <BigNavItem end to="documents">
                        {L('documents')}
                    </BigNavItem>
                    <BigNavItem end to="">
                        {L('edit_property')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{L(template ? L('edit_template') : L('edit_property'))}</CardTitle>
                    </TopAreaContainer>
                }
                mainArea={
                    <GridContainer>
                        <div>
                            {property && (
                                <PropertyForm
                                    propertyData={property}
                                    onSubmit={onSubmit}
                                    propertyTypesOptions={propertyTypesOptions}
                                    handleDeleteProperty={handleDeleteProperty}
                                    oneFieldPerRow
                                />
                            )}
                        </div>
                        <DividerContainer>
                            <Divider decorative orientation="vertical" />
                        </DividerContainer>
                        <div>
                            {images &&
                                (images.length > 0 ? (
                                    <Images
                                        outerWrapperCSS={{ marginBottom: style.margin.s }}
                                        imageData={imageData}
                                        handleRemoveImage={handleRemoveImage}
                                        handleDragEnd={handleDragEnd}
                                        isUpdatingSort={isUpdatingSort}
                                    />
                                ) : (
                                    <RestyledEmptyState>
                                        <EmptyState.Title>{L('no_images')}</EmptyState.Title>
                                        <EmptyState.Description>
                                            <p>{L('no_images_description')}</p>
                                        </EmptyState.Description>
                                    </RestyledEmptyState>
                                ))}
                            <Dropzone multiple={true} variant="Media" onUpload={onUpload} />
                            <div>
                                <StyledH3>{L('extra_information')}</StyledH3>
                                <ErrorAndInputWrapper>
                                    <Label title={L('created_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(property?.created_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <Label title={L('updated_at')} />
                                    <Input
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(property?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                    />
                                </ErrorAndInputWrapper>
                            </div>
                        </div>
                    </GridContainer>
                }
            />
        </div>
    );
};

const EditPropertyPage = ({ template = false }: { template?: boolean }) => {
    const { id, propertyId } = useParams<{ id: string; propertyId: string }>();
    return id && propertyId ? <Page id={id} propertyId={propertyId} template={template} /> : null;
};

export default EditPropertyPage;
