import React from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import styled from '@emotion/styled';
import {
    ContentInformationQueryKeys,
    useGetContentInformationItem,
} from '../../hooks/ContentInformation/contentInformationQueries';
import { useParams } from 'react-router-dom-v5-compat';
import ContentInformationForm, {
    AddContentInformation,
} from '../../components/ContentInformation/ContentInformationForm';
import { SubmitHandler } from 'react-hook-form';
import useUpdateContentInformation from '../../hooks/ContentInformation/useUpdateContentInformation';
import toast from 'react-hot-toast';
import { trpc } from '../../../../lib/trpc';
import GroupDocumentsArea from '../../../../components/document/GroupDocumentsArea/GroupDocumentsArea';
import { uploadToAws } from '../../hooks/ContentInformation/uploadToAws';

const Wrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',
    paddingBottom: '2rem',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const ContentInformationDetailPage = () => {
    const { id: templateId, contentInfoId } = useParams<{ id: string; contentInfoId: string }>();
    return templateId && contentInfoId ? (
        <ContentInformationDetail templateId={+templateId} contentInfoId={+contentInfoId} />
    ) : null;
};

const ContentInformationDetail = ({ templateId, contentInfoId }: { templateId: number; contentInfoId: number }) => {
    const { data: contentInformation, status } = useGetContentInformationItem({
        templateId: +templateId,
        contentInfoId: +contentInfoId,
    });
    const { mutate: updateContentInformationItem } = useUpdateContentInformation();

    const onSubmit: SubmitHandler<AddContentInformation> = (data) => {
        updateContentInformationItem(
            {
                information: data,
                projectId: +templateId,
                contentInfoId: +contentInfoId,
                file: data.image,
            },
            {
                onSuccess: () => {
                    toast.success(L('content_information_updated'));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    return (
        <Wrapper>
            <CardWithDivider
                topArea={<CardTitle>{L('content_information')}</CardTitle>}
                mainArea={
                    status !== 'loading' && (
                        <ContentInformationForm
                            imageUrl={contentInformation?.url}
                            defaultValues={{
                                ...contentInformation,
                                title: contentInformation?.title ?? undefined,

                                description: contentInformation?.description ?? undefined,
                                image: undefined,
                            }}
                            onSubmit={onSubmit}
                        />
                    )
                }
            />
            <CardWithDivider
                topArea={<CardTitle>{L('document')}</CardTitle>}
                mainArea={
                    <div>
                        <GroupDocumentsArea
                            document={{
                                get: async () => {
                                    return (
                                        await trpc.ContentInformationApi.getDocuments.query({
                                            contentInformationId: contentInfoId,
                                            projectId: templateId,
                                        })
                                    ).map((doc) => ({
                                        id: doc.id,
                                        name: doc.name || '',
                                        type: doc.document_data?.original.metadata.mime_type || '',
                                        url: doc.url,
                                        groupId: doc.groupId,
                                        sort: doc.sort,
                                    }));
                                },
                                save: async (input) => {
                                    const response = await trpc.ContentInformationApi.addDocument.mutate({
                                        file: {
                                            name: input.name,
                                            size: input.file.size,
                                            type: input.file.type,
                                        },
                                        groupId: input.documentGroupId,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                    if (response.preSingedUrl) {
                                        await uploadToAws({
                                            file: input.file,
                                            preSingedUrl: response.preSingedUrl,
                                            cleanup: async () => {
                                                return await trpc.ContentInformationApi.removeDocument.mutate({
                                                    documentId: response.documentId,
                                                    contentInformationId: contentInfoId,
                                                    projectId: templateId,
                                                });
                                            },
                                        });
                                    }
                                },
                                sort: async ({ sortedArray }) => {
                                    return await trpc.ContentInformationApi.sortDocument.mutate({
                                        documents: sortedArray,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                },
                                removeDocument: async ({ id }) => {
                                    return await trpc.ContentInformationApi.removeDocument.mutate({
                                        documentId: id,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                },
                                renameDocument: async ({ id, name }) => {
                                    return await trpc.ContentInformationApi.renameDocument.mutate({
                                        documentId: id,
                                        name: name,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                },
                                switchGroup: async ({ groupId, docId }) => {
                                    return await trpc.ContentInformationApi.switchDocumentGroup.mutate({
                                        documentId: docId,
                                        groupId,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                },
                                queryKey: ContentInformationQueryKeys.docs(contentInfoId, templateId),
                            }}
                            documentGroup={{
                                get: async () => {
                                    return (
                                        await trpc.ContentInformationApi.getDocumentGroups.query({
                                            contentInformationId: contentInfoId,
                                            projectId: templateId,
                                        })
                                    ).map((group) => ({
                                        id: group.id,
                                        name: group.name,
                                        sort: group.sort,
                                    }));
                                },
                                sort: async ({ sortedArray }) => {
                                    return await trpc.ContentInformationApi.sortDocumentGroup.mutate({
                                        groups: sortedArray,
                                        contentInformationId: contentInfoId,
                                        projectId: templateId,
                                    });
                                },
                                queryKey: ContentInformationQueryKeys.docGroups(contentInfoId, templateId),
                            }}
                        />
                    </div>
                }
            />
        </Wrapper>
    );
};

export default ContentInformationDetailPage;
