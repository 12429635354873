import React, { useMemo } from 'react';
import { L } from '../../../../../lib/i18n';
import { ExcelMemberImport } from '@app/api/models/Properties';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<ExcelMemberImport>();

export default function memberColumns() {
    return useMemo(
        () => [
            columnHelper.accessor((row) => row.email, {
                id: 'email',
                cell: (info) => <CellContainer>{info.row.original?.email}</CellContainer>,
                header: () => {
                    return L('email');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{L(`${info.row.original.name}`)}</Data>
                    </CellContainer>
                ),
                header: () => {
                    return L('name');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.property_name, {
                id: 'property_name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.property_name}</Data>
                    </CellContainer>
                ),
                header: () => {
                    return L('property_name');
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [],
    );
}
