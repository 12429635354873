import { style } from '@app/shared/lib/Style';
import styled from '@emotion/styled';

export default styled.div({
    padding: '0.2rem 0.4rem',
    border: `2px solid ${style.colors.inputBorder}`,
    backgroundColor: style.colors.inputBackground,
    borderRadius: '1rem',
    color: '#B5B8C4',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    fontSize: '80%',
    height: '100%',
    ['@media screen and (min-width: 1024px)']: {
        padding: '0.4rem 0.7rem'
    }
});
