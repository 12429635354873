import React from 'react';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../types/ColumnType';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { Property } from '@app/api/models/Properties';

dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px'
});

export const COLUMNSPROPERTY: StyleColumn<Property>[] = [
    {
        Header: 'Namn',
        accessor: value => value.name,
        width: 50,
        style: {
            justifyContent: 'left',
            alignItems: 'center'
        },
        Cell: ({ row }) => {
            const item = row.original;
            return <Span>{item.name}</Span>;
        }
    }
];

export const COLUMNSMOBILE: StyleColumn<Property>[] = [
    {
        Header: 'Namn',
        accessor: project => project.name,
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center'
        },
        Cell: ({ row }) => {
            const item = row.original;
            return <Span>{item.name}</Span>;
        }
    }
];
