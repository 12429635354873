import styled from '@emotion/styled';
import { style } from '../../../../../components/Shared/Style';

export default styled.button({
    outline: `1px solid ${style.colors.primary}`,
    border: 'none',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    textAlign: 'center',
    lineHeight: '16px',
    borderRadius: '6px',
    boxSizing: 'border-box',
    fill: style.colors.primary,
    [':hover']: {
        outline: `2px solid ${style.colors.primary}`,
        cursor: 'pointer'
    },
    [':disabled']: {
        outline: `1px solid ${style.colors.lightgray}`,
        fill: style.colors.lightgray,
        cursor: 'inherit'
    }
});
