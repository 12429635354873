import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@ourliving/ourliving-ui';
import ChosenFilters from '../../binders/components/ChosenFilters/ChosenFilters';
import Navigation from '../../binders/components/Navigation';
import LibrarySource from '../../binders/components/LibrarySource';
import RowsNav from '../../binders/components/RowsNav';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import TableMenuContainer from '../../binders/components/emotion/TableMenuContainer';
import StyledParagraph from '../../binders/components/emotion/StyledParagraph';
import PaddedContainer from '../../binders/components/emotion/PaddedContainer';
import ContentContainer from '../../binders/components/emotion/ContentContainer';
import Row from '../../binders/components/emotion/Row';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import { Filters } from '../../binders/DataType';
import { useBasket } from '../../binders/hooks/useBasket';
import { useGetLibraries, useGetLibrary, useGetLibraryFilter } from '../../binders/hooks/libraryQueries';
import { useNavigate, useSearchParams } from 'react-router-dom-v5-compat';
import { useLocalStorage } from 'react-use';
import {
    RowSelectionState,
    SortingState,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import columns from '../../binders/components/Products/LibraryPageColumns';
import GenericTable from '../../binders/components/GenericTable';
import FilterTableData from '../../binders/components/FilterTableData';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { ProductLibraryFilter } from '@app/trpc-api/models/ProductLibrary';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { RouterOutputs } from '@app/trpc-api';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

const options = [{ name: 'function', value: 'selectedFunctions', newValue: 'setSelectedFunctions', id: 1 }];

type ProductData = RouterOutputs['ProductLibraryApi']['listProducts'];

const LibraryPage = () => {
    const navigate = useNavigate();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const selectedCount = Object.keys(rowSelection).length;
    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';
    const page = Number(searchParams.get('page') ?? 1);
    const rows = Number(searchParams.get('rows') ?? 10);

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const [localStorageValue, setLocalStorageValue] = useLocalStorage(
        'libraryPageFilters',
        searchParams.toString() ?? '',
    );

    const [columnVisibilityState, setColumnVisibilityState] = useLocalStorage<Record<string, boolean>>(
        'copyFunctionLibraryColumnVisibilityState',
        { image: false },
    );

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else {
            setSearchParams((searchParams) => {
                const libraries = searchParams.getAll('libraries');
                const newSearchParams = new URLSearchParams();
                libraries.forEach((val) => newSearchParams.append('libraries', val));
                return newSearchParams;
            });
        }
        setLocalStorageValue(() => '');
    };

    const updateFilter = (key: string, values: string[]) => {
        const valuesAsString = values.map((val) => String(val));
        if (values.length === 0) searchParams.delete(key);
        searchParams.delete('page');
        setSearchParams((searchParams) => {
            if (values.length > 0) {
                searchParams.delete(key);
                valuesAsString.forEach((val) => searchParams.append(key, val));
            } else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
        setLocalStorageValue(() => newUrlParams.toString());
    };

    const [isInitialRender, setIsInitialRender] = useState(true);

    useEffect(() => {
        if (!localStorageValue || !isInitialRender) return;
        if (window.location.search) {
            setIsInitialRender(false);
            return;
        }
        setSearchParams(localStorageValue);
        setIsInitialRender(false);
    }, [localStorageValue, searchParams]);

    const [data, setData] = useState<ProductData | undefined>();

    const { data: libraries } = useGetLibraries();

    const rowsToShow = useMemo(() => {
        return { value: Number(rows), label: rows + ' ' + L('rows') };
    }, [rows]);
    const [storedIds, setStoredIds] = useBasket();

    //Get relevant params from URL
    const filters: ProductLibraryFilter = {
        function: searchParams.getAll('function'),
        accountIds: searchParams.getAll('libraries'),
    };

    const handleResetLibraries = () => {
        updateFilter('libraries', []);
    };

    //Get filters
    const { data: filterData } = useGetLibraryFilter(filters.accountIds, handleResetLibraries);

    //Get the option data needed
    const filterOptions = useMemo(() => {
        const filteredFilterData: Filters = {
            function: [],
        };
        filterData?.forEach((data) => {
            if (!filteredFilterData.function.find((filter) => filter.value === data.function)) {
                filteredFilterData.function.push({ value: data.function, label: L(data.function) });
            }
        });

        filteredFilterData.function?.sort((a, b) => L(a.label).localeCompare(L(b.label), 'sv-SE'));
        return filteredFilterData;
    }, [filterData]);

    const functionParams = searchParams.getAll('function');

    //Get from URL params the selected filters
    const selectedFiltersWithLabel = {
        selectedFunctions: functionParams.flatMap((func) => {
            const option = filterOptions.function.find((filter) => filter.value === func);
            return option ?? [];
        }),
    };

    const setSelectedFunctions = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('function', onlyValues);
    };

    const clearInput = (type: keyof Filters, value: string) => {
        setSearchParams((searchParams) => {
            const filteredParams = searchParams.getAll(type).filter((param) => param !== value);
            searchParams.delete(type);
            filteredParams.forEach((val) => searchParams.append(type, val));
            return searchParams;
        });
    };

    const setPage = (newPage: number) => {
        updateFilter('page', [newPage.toString()]);
    };

    const setRowsToShow = (rows: number) => {
        updateFilter('rows', [rows.toString()]);
    };

    const optionMetadata = useMemo(
        () => [
            { name: 'name', initialTexts: [L('name'), L('names')], api: 'name' },
            { name: 'function', initialTexts: [L('function'), L('functions')], api: 'product_type_name' },
        ],
        [],
    );

    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.items.map((item) => {
            return { ...item, inbasket: !!storedIds.find((id) => id === item.id) };
        });
    }, [data, storedIds]);

    const addIdsToBasket = (ids: number[]) => {
        const oldIds = storedIds.filter((id) => !ids.includes(id));

        const newIds = [...oldIds, ...ids];
        const amountAdded = newIds.length - storedIds.length;

        setStoredIds(newIds, {
            onSuccess: () => {
                if (amountAdded < 1) toast.error(L('already_exist_in_basket'));
                else
                    toast.success(
                        `${L('added')} ${amountAdded} ${L(
                            amountAdded === 1 ? 'product' : 'products',
                        ).toLowerCase()} ${L('to_basket')}`,
                    );
            },
            onError: () => {
                toast.error(L('something_went_wrong'));
            },
        });
    };

    const table = useReactTable({
        data: filteredData,
        columns,
        state: { sorting, rowSelection, columnVisibility: columnVisibilityState },
        onColumnVisibilityChange: setColumnVisibilityState,
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
        manualSorting: true,
        sortDescFirst: false,
        enableSortingRemoval: true,
        getRowId: (originalRow) => String(originalRow.id),
        meta: {
            addIdsToBasket,
            storedIds,
            setStoredIds,
        },
    });

    const { data: libraryData, isLoading } = useGetLibrary(page, rowsToShow.value, filters, searchField, sorting);

    useEffect(() => {
        if (!libraryData) return;
        setData(libraryData);
    }, [libraryData]);

    const addToBasket = () => {
        const ids = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
        addIdsToBasket(ids);
    };

    const changeBasket = () => {
        addToBasket();
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>
                        {L('library')} {data ? `(${data.totalItems})` : ''}
                    </CardTitle>

                    <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                        {L('new_product')}
                    </Button>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <FilterSearchContainer>
                        <ContentContainer>
                            <FilterTableData
                                options={options}
                                filterOptions={filterOptions}
                                selected={{
                                    selectedFunctions: selectedFiltersWithLabel.selectedFunctions,
                                }}
                                setSelected={{
                                    setSelectedFunctions,
                                }}
                                clearAll={handleClearAll}
                            />

                            {libraries && (
                                <LibrarySource
                                    libraries={libraries}
                                    accountIds={filters.accountIds}
                                    setAccountIds={(accountIds) => updateFilter('libraries', accountIds)}
                                />
                            )}
                        </ContentContainer>
                        <PaddedContainer
                            style={{
                                display: 'flex',
                                columnGap: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    columnGap: '0.5rem',
                                    alignItems: 'center',
                                }}
                            >
                                {`${L('show_with_image')}:`}
                                <Checkbox
                                    checked={table.getColumn('image')?.getIsVisible()}
                                    onChange={table.getColumn('image')?.getToggleVisibilityHandler()}
                                />
                            </div>
                            <Row>
                                <RowsNav selectedType={rowsToShow} setSelectedType={setRowsToShow} />
                                <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                            </Row>
                        </PaddedContainer>
                    </FilterSearchContainer>

                    {filterOptions && (
                        <ChosenFilters
                            inputs={{ function: selectedFiltersWithLabel.selectedFunctions }}
                            onRemove={clearInput}
                            optionMetadata={optionMetadata}
                        />
                    )}
                    <TableMenuContainer>
                        <StyledParagraph style={{ padding: '1rem 0' }}>
                            {selectedCount > 0
                                ? selectedCount +
                                  'st ' +
                                  (selectedCount > 1 ? L('chosen_products') : L('chosen_product'))
                                : L('no_chosen_products')}
                        </StyledParagraph>
                        {selectedCount > 0 && (
                            <Button variant={'primary'} onClick={changeBasket}>
                                {L('add_to_basket')}
                            </Button>
                        )}
                    </TableMenuContainer>

                    <GenericTable table={table} rowClickHandler={({ original }) => navigate(`${original.id}`)} />
                    <Navigation data={data} setPage={setPage} />

                    {filteredData?.length === 0 &&
                        data &&
                        (!searchField &&
                        Object.values(selectedFiltersWithLabel).flatMap((item) => item).length === 0 ? (
                            <EmptyState>
                                <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_products_library_description')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        ) : (
                            <EmptyState>
                                <EmptyState.Title>{L('no_products_found')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_products_found_description')}</p>
                                    <p>{L('please_try_something_else')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        ))}
                </>
            }
        />
    );
};

export default LibraryPage;
