import React from 'react';
import { Filters, SelectOption } from '../../DataType';
import OptionContainer from '../emotion/Filters/OptionContainer';
import CategoryContainer from '../emotion/Filters/CategoryContainer';
import { Icon } from '../../../../components/Icon/Icon';
import LabelText from '../emotion/LabelText';
import Option from '../emotion/Filters/Option';
import HighlightedText from '../emotion/HighlightedText';
import ClickableIcon from '../emotion/ClickableIcon';

type Props<T extends Record<string, SelectOption[]>> = {
    inputs: T;
    labels: Record<Extract<keyof T, string>, string[]>;
    onRemove: (indexor: keyof T, value: string) => void;
};

const RemoveOutline = Icon['RemoveOutline'];

export default function ChosenFiltersV2<T extends Record<string, SelectOption[]>>({
    inputs,
    onRemove,
    labels,
}: Props<T>) {
    return (
        <OptionContainer>
            {Object.entries(inputs).map((option) => {
                return (
                    <div key={option[0]}>
                        {option[1]?.length > 0 && (
                            <CategoryContainer>
                                <LabelText>{labels[option[0]][option[1].length > 1 ? 1 : 0]}:</LabelText>
                                {option[1]
                                    .map((option: Filters['function'][0]) => option)
                                    .map((item, index) => (
                                        <Option key={index}>
                                            <HighlightedText key={index}>{item.label}</HighlightedText>
                                            <ClickableIcon onClick={() => onRemove(option[0], item.value)}>
                                                <RemoveOutline size={'small'} />
                                            </ClickableIcon>
                                        </Option>
                                    ))}
                            </CategoryContainer>
                        )}
                    </div>
                );
            })}
        </OptionContainer>
    );
}
