import { useMutation, useQueryClient } from 'react-query';
import ServicePartnersApi, { ServicePartner } from '@app/api/public/ServicePartnersApi';
import { getServicePartnersQueryKey } from './useGetServicePartners';
import { useParams } from 'react-router-dom';

type Variables = Partial<ServicePartner>;

export const saveServicePartner = async (projectId: string, variables: Variables) => {
    return await ServicePartnersApi.save(+projectId, variables);
};

const useSaveServicePartner = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ id: string; projectId: string }>();

    return useMutation((variables: Variables) => saveServicePartner(projectId, variables), {
        onSettled: () => {
            queryClient.invalidateQueries([getServicePartnersQueryKey]);
        }
    });
};

export default useSaveServicePartner;
