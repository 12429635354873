import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useParams } from 'react-router-dom';

const getAnalyticListQueryKey = 'getAnalyticList';
const getAnalyticList = async (projectId: string) => {
    return await MaintenancesApi.listAnalyticPlannedCompletedCostByYear(+projectId);
};

const useGetAnaltyicList = () => {
    const { projectId } = useParams<{ projectId: string }>();

    return useQuery([getAnalyticListQueryKey, { projectId }], () => getAnalyticList(projectId));
};

export default useGetAnaltyicList;
