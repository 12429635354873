import React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Divider = styled.div((p: { margin?: string }) => ({
    display: 'flex',
    borderBottom: 'solid 1px',
    width: '100%',
    color: style.colors.black50,
    textAlign: 'center',
    margin: p.margin ? p.margin : `${style.margin.m}px 0px`
}));

export function LineDivider(p: { margin?: string }) {
    return <Divider margin={p.margin} />;
}
