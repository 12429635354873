import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import TableHandler from '../components/TableHandler';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Filters } from '../../../components/Filters/Filters';
import { MaintenanceCategory, MaintenanceFilter, MaintenanceStatus } from '@app/api/models/Maintenances';
import useGetMaintenanceList from '../hooks/useGetMaintenanceList';
import useGetSettingsListByType from '../hooks/useGetSettingsListByType';
import { L } from '../../../lib/i18n';
import useGetProjects from '../../../lib/hooks/useGetProjects';
import { useHistory, useParams } from 'react-router-dom';

const Container = styled.div({
    width: '100%',
    backgroundColor: 'white',
    paddingLeft: '50px',
    paddingRight: '50px',
    borderRadius: '4px'
});
const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem'
});
const StyledFilters = styled(Filters)({
    marginTop: '1.5rem'
});

const MaintenanceTablePage = () => {
    const projects = useGetProjects('maintenance');
    const { projectId } = useParams<{ projectId: string }>();
    const history = useHistory();
    const [category, setCategory] = useState<MaintenanceCategory | undefined>();
    const [status, setStatus] = useState<MaintenanceStatus | undefined>();
    const [filter, setFilter] = useState<MaintenanceFilter>({
        categoryId: category?.id,
        statusId: status?.id
    });
    const { data: filteredData } = useGetMaintenanceList(filter);
    const { data: statusList } = useGetSettingsListByType('status');
    const { data: categoryList } = useGetSettingsListByType('category');

    useEffect(() => {
        setFilter({
            categoryId: category?.id,
            statusId: status?.id
        });
    }, [category, status]);

    const statusOptions = useMemo(() => {
        return statusList
            ? [
                  ...statusList.map(status => {
                      return { name: status.name, id: status.id };
                  }),
                  { name: L('maintenance_status_completed'), id: 'completed' }
              ]
            : [];
    }, [statusList]);
    if (!projects.data) return <div>Kunde inte hitta projekt</div>;

    return (
        <div style={{ width: '100%' }}>
            <StyledFilters>
                <StyledDropdown
                    label={'Bostad'}
                    onChange={value => history.push(`/maintenance/${value}`)}
                    value={projectId}
                    options={
                        projects?.data
                            ?.sort((a, b) => a.name.localeCompare(b.name, ['sv', 'dk']))
                            .map(project => {
                                return {
                                    value: project.id,
                                    label: project.name
                                };
                            }) || []
                    }
                />
                <StyledDropdown
                    isClearable
                    label={'Kategori'}
                    onChange={value => setCategory(value)}
                    value={category}
                    options={
                        categoryList?.map(category => {
                            return {
                                value: category,
                                label: category.name
                            };
                        }) || []
                    }
                />
                <StyledDropdown
                    isClearable
                    label={'Status'}
                    onChange={value => setStatus(value)}
                    value={status}
                    options={
                        statusOptions.map(status => {
                            return { value: status, label: status.name };
                        }) || []
                    }
                />
            </StyledFilters>
            <Container>
                <TableHandler filteredData={filteredData} />
            </Container>
        </div>
    );
};

export default MaintenanceTablePage;
