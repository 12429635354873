import { SupplierFilter } from '@app/api/public/AddonsApi';
import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Loading } from '../../../components/Loading/Loading';
import useGetHeaderInfo from './hooks/useGetHeaderInfo';
import useGetListData from './hooks/useGetList';
import { ReportHeader } from './ReportHeader/SupplierReportHeader';
import { ReportProducts } from './ReportProducts/SupplierReportProducts';

const Wrapper = styled.div({
    backgroundColor: 'white',
    '@media screen': {
        display: 'none'
    }
});

export type HeaderProps = {
    projectId: string;
    property_ids: number[];
};

interface Props extends HeaderProps {
    filter?: SupplierFilter;
    fields: string[];
    setLoadPrint: (loadPrint: boolean) => void;
}

const SupplierCreatePdf = (data: Props) => {
    const { data: header } = useGetHeaderInfo(data, data.filter?.property);
    const { data: list } = useGetListData(data);

    const componentRef = useRef<HTMLDivElement>(null);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `LeverantörsRapport-${header?.name}`
    });


    useEffect(() => {
        componentRef.current && handlePrint();
        componentRef.current && data.setLoadPrint(false);
    }, [componentRef.current]);

    if (!list || !header) return <Loading />;

    return (
        <Wrapper ref={componentRef}>
            <ReportHeader data={header} logo={header.project_logo} />
            <ReportProducts data={list} fields={data.fields} />
        </Wrapper>
    );
};

export default SupplierCreatePdf;
