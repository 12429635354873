import React, { useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import Search from '../../../components/Forms/Search';
import { L } from '../../../lib/i18n';
import AddNewServicePartnerButton from '../components/AddNewServicePartnerButton';
import ServicePartnerListHandler from '../components/ServicePartnerListHandler';
import { BorderDesktop } from '../../../components/Layout/BorderDesktop';
import H1 from '../../../components/typography/H1';
import AddServicePartner from './AddServicePartner';
import { colors } from '../../../components/Shared/Style/colors';
import EditServicePartner from './EditServicePartnerPage';

const Main = styled.main({
    width: '100%',
    marginBottom: '60px'
});

const StyledBorder = styled(BorderDesktop)({
    maxHeight: '100%'
});

const StyledServicePartnerListHandler = styled(ServicePartnerListHandler)({
    marginTop: '2.5rem'
});

const StyledH1 = styled(H1)({
    alignSelf: ' self-start',
    margin: '0px'
});

const StyledSearch = styled(Search)({
    margin: '0px'
});

const TitleAddContainer = styled.div({
    marginBottom: '2rem',
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'auto min-content'
});

const StyledAddNewServicePartnerButton = styled(AddNewServicePartnerButton)({
    marginLeft: 'auto'
});

const Card = styled.div({
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '300px min-content 1fr',
    gridTemplateAreas: `
    "aside border main"
    `,
    columnGap: '2rem'
});

const StyledAside = styled.aside({
    height: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
});

const StyledMain = styled.main({
    height: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column'
});

const ServicePartnerPage: React.FC = () => {
    const [value, setValue] = useState('');

    return (
        <Card>
            <StyledAside>
                <Main>
                    <TitleAddContainer>
                        <StyledAddNewServicePartnerButton />
                    </TitleAddContainer>
                    <StyledSearch setValue={setValue} />
                    <StyledServicePartnerListHandler searchValue={value} />
                </Main>
            </StyledAside>
            <StyledBorder />
            <StyledMain>
                <Switch>
                    <Route path={`/maintenance/:projectId/service_partner/add`}>
                        <AddServicePartner />
                    </Route>
                    <Route path={`/maintenance/:projectId/service_partner/:id`}>
                        <EditServicePartner />
                    </Route>
                </Switch>
            </StyledMain>
        </Card>
    );
};

export default ServicePartnerPage;
