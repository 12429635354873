import AddonsApi, { PropertyFilter } from '@app/api/public/AddonsApi';
import { useQuery } from 'react-query';

export const getPropertyKeys = {
    all: ['addonProperties'] as const,
    list: (projectId?: string, filter?: PropertyFilter) =>
        [...getPropertyKeys.all, 'list', { projectId: projectId, filter }] as const
};

const getPropertyList = async (projectId?: string, filter?: PropertyFilter) => {
    if (!projectId) throw new Error('no propertyId');
    const response = await AddonsApi.getPropertyList(+projectId, filter);
    if (!response) throw new Error('no response');
    return response;
};

const useGetPropertyList = (projectId?: string, filter?: PropertyFilter) => {
    return useQuery(getPropertyKeys.list(projectId, filter), () => getPropertyList(projectId, filter), {
        enabled: !!projectId
    });
};

export default useGetPropertyList;
