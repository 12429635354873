import dayjs from 'dayjs';

const useValidIntervalEndDates = (startDate?: string, intervalYears?: number) => {
    if (!startDate || !intervalYears) return [];
    if (!dayjs(startDate).isValid()) return [];
    const interval = +intervalYears;

    const maxYearSpan = 50;

    const start = +dayjs(startDate).format('YYYY');

    const validYears: number[] = [];

    for (let i = start; i <= start + maxYearSpan; i = i + interval) {
        if (i + interval <= maxYearSpan + start) {
            validYears.push(i + interval);
        }
    }

    return validYears;
};

export default useValidIntervalEndDates;
