import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import ChosenFilters from '../../binders/components/ChosenFilters/ChosenFilters';
import { ExportToExcel } from '../../binders/components/ExportToExcel';
import TableMenu from '../../binders/components/TableMenu';
import Comments from '../../binders/components/Comments';
import ProductsFilters from '../../binders/components/FilterTableData';
import Navigation from '../../binders/components/Navigation';
import RowsNav from '../../binders/components/RowsNav';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import Row from '../../binders/components/emotion/Row';
import ContentContainer from '../../binders/components/emotion/ContentContainer';
import PaddedContainer from '../../binders/components/emotion/PaddedContainer';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import { Loading } from '../../../components/Loading/Loading';
import { FiltersProducts } from '../../binders/DataType';
import { L } from '../../../lib/i18n';
import ProductsApi, { IPageOf } from '@app/api/public/ProductsApi';
import { useBasket } from '../../binders/hooks/useBasket';
import { Button } from '@ourliving/ourliving-ui';
import { getProductsKeys, useGetProductList, useGetProductsListFilters } from '../../binders/hooks/productQueries';
import { useSearchParams, useNavigate } from 'react-router-dom-v5-compat';
import { SortingState, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import GenericTable from '../../binders/components/GenericTable';
import { TableMeta } from '../../binders/TableMetaTypes/TableMetaType';
import columns from '../../binders/components/Products/ProductsPageColumns';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useGetProjects } from '../../binders/hooks/projectHooks/projectQueries';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import CardTitle from '../../../components/typography/CardTitle';
import { margin } from '../../../components/Shared/Style/margin';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { useLocalStorage } from 'react-use';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { InventoryData } from '@app/trpc-api/models/Binders';

type NewPrice = { id: number; price: number; addon_price: string };

export type MutatedInventoryData = InventoryData & {
    stagedDeletion: boolean;
    newPrice:
        | false
        | {
              id: number;
              price: number;
              addon_price: number;
              isProcentual: boolean;
          }
        | undefined;
    inbasket: boolean;
};

const options = [
    { name: 'property', value: 'selectedProperties', newValue: 'setSelectedProperties', id: 1 },
    { name: 'space', value: 'selectedSpaces', newValue: 'setSelectedSpaces', id: 2 },
    { name: 'product_group', value: 'selectedProductTypes', newValue: 'setSelectedProductTypes', id: 3 },
    { name: 'package', value: 'selectedPackage', newValue: 'setSelectedPackages', id: 4 },
];

const procentualAdd = (priceAdjustment: number, price: number) => {
    if (price) {
        return Math.round(priceAdjustment * price);
    } else {
        //NAN
        return null;
    }
};

const ProductsComponent = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { data: projectsData } = useGetProjects();
    const projectId = searchParams.get('project');

    const searchField = searchParams.get('searchField') ?? '';

    const navigate = useNavigate();

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else setSearchParams([]);
    };

    const [columnVisibilityState, setColumnVisibilityState] = useLocalStorage<Record<string, boolean>>(
        'copyFunctionExistingColumnVisibilityState',
        { image: false },
    );

    const updateFilter = (key: string, values: string[]) => {
        const valuesAsString = values.map((val) => String(val));
        if (values.length === 0) searchParams.delete(key);
        searchParams.delete('page');
        setSearchParams((searchParams) => {
            if (values.length > 0) {
                searchParams.delete(key);
                valuesAsString.forEach((val) => searchParams.append(key, val));
            } else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
    };

    const [data, setData] = useState<IPageOf<InventoryData> | undefined>();
    const queryClient = useQueryClient();
    const [previewDeletion, setPreviewDeletion] = useState<number[]>([]);
    const [previewPrices, setPreviewPrices] = useState<
        { id: number; price: number; addon_price: number; isProcentual: boolean }[]
    >([]);
    const [showModal, setShowModal] = useState(false);
    const [storedIds, setStoredIds] = useBasket();
    const updatePrice = useMutation(
        (stagedItems: { id: number; price: number }[]) => ProductsApi.updatePrice(stagedItems),
        {
            onSuccess: (changedItems) => {
                queryClient.invalidateQueries(getProductsKeys.all);
                setPreviewPrices([]);
                toast.success(
                    `${L('edited')} ${changedItems} ${L(changedItems === 1 ? 'product' : 'products').toLowerCase()}`,
                );
            },
            onError: () => {
                toast.error(L('something_went_wrong'));
            },
        },
    );

    const page = Number(searchParams.get('page') ?? 1);
    const rows = Number(searchParams.get('rows') ?? 10);

    const rowsToShow = useMemo(() => {
        return { value: Number(rows), label: rows + ' ' + L('rows') };
    }, [rows]);

    const setPage = (newPage: number) => {
        updateFilter('page', [newPage.toString()]);
    };

    const setRowsToShow = (rows: number) => {
        updateFilter('rows', [rows.toString()]);
    };

    const projectOptions =
        projectsData?.map((project) => ({ value: project.id.toString(), label: project.name })) ?? [];

    const { data: filter } = useGetProductsListFilters(projectId ? +projectId : undefined);
    const propertyFilter = filter?.properties;
    const spaceFilter = filter?.spaces;
    const productTypesFilter = filter?.productGroups;
    const packageFilters = filter?.packages;

    //Get all filters when initial loads are done
    const filterOptions = useMemo(() => {
        const filteredFilterData: FiltersProducts = {
            property: [],
            space: [],
            product_group: [],
            package: [],
        };
        if (propertyFilter && spaceFilter && productTypesFilter && packageFilters) {
            propertyFilter.forEach((data) => {
                if (
                    filteredFilterData?.property &&
                    !filteredFilterData.property.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.property.push({ value: data.id.toString(), label: data.name });
                }
            });

            spaceFilter.forEach((data) => {
                if (
                    filteredFilterData?.space &&
                    !filteredFilterData.space.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.space.push({ value: data.name.toString(), label: data.name });
                }
            });

            productTypesFilter.forEach((data) => {
                if (
                    filteredFilterData?.product_group &&
                    !filteredFilterData.product_group.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.product_group.push({ value: data.name, label: data.name });
                }
            });
            packageFilters.forEach((data) => {
                if (
                    filteredFilterData?.package &&
                    !filteredFilterData.package.find((filter) => filter.value === data.package_name)
                ) {
                    filteredFilterData.package.push({ value: data.id.toString(), label: data.package_name });
                }
            });
        }
        return filteredFilterData;
    }, [propertyFilter && spaceFilter && productTypesFilter]);

    const optionMetadata = useMemo(
        () => [
            { name: 'property', initialTexts: [L('property'), L('properties')], api: 'product_type_name' },
            { name: 'space', initialTexts: [L('space'), L('spaces')], api: 'space' },
            { name: 'product_group', initialTexts: [L('producttype'), L('producttypes')], api: 'productgroup' },
            { name: 'package', initialTexts: [L('package'), L('package')], api: 'count' },
        ],
        [],
    );

    const properties = searchParams.getAll('property');

    const spaces = searchParams.getAll('space');

    const productGroups = searchParams.getAll('product_group');

    const packageParam = searchParams.getAll('package');

    const setSelectedProperties = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('property', onlyValues);
    };

    const setSelectedSpaces = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('space', onlyValues);
    };

    const setSelectedProductTypes = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('product_group', onlyValues);
    };

    const setSelectedPackages = (values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter('package', onlyValues);
    };

    //Get from URL params the selected filters
    const selectedFiltersWithLabel = {
        selectedProperties: properties.flatMap((property) => {
            const option = filterOptions.property.find((filter) => filter.value === property);
            return option ?? [];
        }),
        selectedSpaces: spaces.flatMap((space) => {
            const option = filterOptions.space.find((filter) => filter.value === space);
            return option ?? [];
        }),
        selectedProductTypes: productGroups.flatMap((type) => {
            const option = filterOptions.product_group.find((filter) => filter.value === type);
            return option ?? [];
        }),
        selectedPackages: packageParam.flatMap((type) => {
            const option = filterOptions.package.find((filter) => filter.value === type);
            return option ?? [];
        }),
    };

    const toggleModal = () => {
        setShowModal((oldModal) => !oldModal);
    };

    // filter data
    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.items.map((data, index) => {
            return {
                ...data,
                stagedDeletion: previewDeletion.length > 0 && previewDeletion.includes(data.id),
                newPrice: previewPrices.length > 0 && previewPrices.find((price) => price.id === index),
                inbasket: !!storedIds.find((id) => id === data.id),
            };
        });
    }, [filterOptions, data, previewDeletion, previewPrices, storedIds]);

    const addIdsToBasket = (ids: number[]) => {
        const oldIds = storedIds.filter((id) => !ids.includes(id));

        const newIds = [...oldIds, ...ids];
        const amountAdded = newIds.length - storedIds.length;

        setStoredIds(newIds, {
            onSuccess: () => {
                if (amountAdded < 1) toast.error(L('already_exist_in_basket'));
                else
                    toast.success(
                        `${L('added')} ${amountAdded} ${L(
                            amountAdded === 1 ? 'product' : 'products',
                        ).toLowerCase()} ${L('to_basket')}`,
                    );
            },
            onError: () => {
                toast.error(L('something_went_wrong'));
            },
        });
    };

    const clearInputParams = (key: string, value: string, searchParams: URLSearchParams) => {
        const filteredParams = searchParams.getAll(key).filter((param) => param !== value);
        searchParams.delete(key);
        filteredParams.forEach((val) => searchParams.append(key, val));
        return searchParams;
    };

    //When you click X button next to selected filter, this triggers
    const clearInput = (type: keyof FiltersProducts, value: string) => {
        if (type === 'property') {
            setSearchParams((searchParams) => {
                // // Detta funkar i FireFox men inte i någon annan webbläsare atm.
                // searchParams.delete('property', value);
                return clearInputParams('property', value, searchParams);
            });
        }
        if (type === 'space') {
            setSearchParams((searchParams) => {
                return clearInputParams('space', value, searchParams);
            });
        }

        if (type === 'product_group') {
            setSearchParams((searchParams) => {
                return clearInputParams('product_group', value, searchParams);
            });
        }

        if (type === 'package') {
            setSearchParams((searchParams) => {
                return clearInputParams('package', value, searchParams);
            });
        }
    };

    //Change a single cell
    const setSingleCell = (id: number, price: number, addon_price: string) => {
        const previewPricesCopy = [...previewPrices];
        const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === id);

        if (foundIndex >= 0) {
            previewPricesCopy[foundIndex].price = Math.round(price);
            previewPricesCopy[foundIndex].isProcentual = false;
        } else
            previewPricesCopy.push({
                id,
                price: Math.round(price),
                addon_price: Number(addon_price),
                isProcentual: false,
            });
        setPreviewPrices(previewPricesCopy);
    };

    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = React.useState({});

    const table = useReactTable({
        data: filteredData,
        columns,
        state: { sorting, rowSelection, columnVisibility: columnVisibilityState },
        onColumnVisibilityChange: setColumnVisibilityState,
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
        manualSorting: true,
        sortDescFirst: false,
        enableSortingRemoval: true,
        getRowId: (originalRow) => String(originalRow.id),
        meta: {
            updateData: ({ id, price, addon_price }: NewPrice) => {
                setSingleCell(id, price, addon_price);
            },
            previewPrices,
            previewDeletion,
            addIdsToBasket,
            setStoredIds,
            storedIds,
        } as TableMeta,
    });

    const previewPrice = (price: number, isProcentual: boolean) => {
        const previewPricesCopy = [...previewPrices];
        table.getSelectedRowModel().flatRows.forEach((item) => {
            const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === item.original.id);
            if (foundIndex >= 0) {
                previewPricesCopy[foundIndex].price = price;
                previewPricesCopy[foundIndex].isProcentual = isProcentual;
            } else if ((item.original.addon_price || !isProcentual) && price >= 0) {
                previewPricesCopy.push({
                    id: item.original.id,
                    price,
                    addon_price: Number(item.original.addon_price),
                    isProcentual,
                });
            }
        });
        setPreviewPrices([...previewPricesCopy]);
    };

    const { data: binderData, isLoading } = useGetProductList({
        projectId: Number(projectId),
        filters: selectedFiltersWithLabel,
        sortOptions: sorting,
        search: searchField,
        page,
        rowCount: rows,
    });

    useEffect(() => {
        if (!binderData) return;
        setData(binderData);
    }, [binderData]);

    const cancelPrice = () => {
        setPreviewPrices([]);
    };

    const confirmPrice = async () => {
        const stagedItems = previewPrices
            .map((item) => {
                if (item.price) {
                    if (item.isProcentual) {
                        const calculatedPrice = procentualAdd(item.price, Number(item.addon_price));
                        return {
                            id: Number(item.id),
                            price: calculatedPrice && calculatedPrice >= 0 ? calculatedPrice : Number(item.addon_price),
                        };
                    } else {
                        return {
                            id: Number(item.id),
                            price: item.price >= 0 ? item.price : Number(item.addon_price),
                        };
                    }
                }
            })
            .flatMap((item) => (item ? [item] : []));
        if (stagedItems) updatePrice.mutate(stagedItems);
    };

    const addToBasket = () => {
        const ids = table.getSelectedRowModel().flatRows.map((row) => row.original.id);
        addIdsToBasket(ids);
    };

    if (isLoading) {
        return <Loading />;
    }

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>
                        {L('copy_function')} {data ? `(${data.totalItems})` : ''}
                    </CardTitle>
                    <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s, alignItems: 'center' }}>
                        <Button role="link" onClick={() => navigate('new')}>
                            {L('add_product')}
                        </Button>
                        <ExportToExcel
                            fileName={'Översiktsinventarie'}
                            projectId={projectId ? +projectId : undefined}
                            filters={selectedFiltersWithLabel}
                            search={searchField}
                        />
                    </div>
                    <Comments showModal={showModal} toggleModal={toggleModal} />
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <FilterSearchContainer>
                        <ContentContainer>
                            <ReactSelect
                                closeMenuOnSelect={false}
                                placeholder={L('project')}
                                hideSelectedOptions={false}
                                options={projectOptions}
                                isSearchable={true}
                                value={projectOptions.find((option) => option.value === projectId)}
                                onChange={(selected) => {
                                    if (!selected) return;
                                    setSearchParams({ project: selected.value });
                                }}
                            />
                            <ProductsFilters
                                options={options}
                                filterOptions={filterOptions}
                                selected={{
                                    selectedProperties: selectedFiltersWithLabel.selectedProperties,
                                    selectedSpaces: selectedFiltersWithLabel.selectedSpaces,
                                    selectedProductTypes: selectedFiltersWithLabel.selectedProductTypes,
                                    selectedPackages: selectedFiltersWithLabel.selectedPackages,
                                }}
                                setSelected={{
                                    setSelectedProperties,
                                    setSelectedSpaces,
                                    setSelectedProductTypes,
                                    setSelectedPackages,
                                }}
                                clearAll={handleClearAll}
                            />
                        </ContentContainer>
                        <PaddedContainer
                            style={{
                                display: 'flex',
                                columnGap: '1rem',
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    display: 'flex',
                                    columnGap: '0.5rem',
                                    alignItems: 'center',
                                }}
                            >
                                {`${L('show_with_image')}:`}
                                <Checkbox
                                    checked={table.getColumn('image')?.getIsVisible()}
                                    onChange={table.getColumn('image')?.getToggleVisibilityHandler()}
                                />
                            </div>
                            <Row>
                                <RowsNav selectedType={rowsToShow} setSelectedType={setRowsToShow} />
                                <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                            </Row>
                        </PaddedContainer>
                    </FilterSearchContainer>
                    {filterOptions && (
                        <ChosenFilters
                            inputs={{
                                property: selectedFiltersWithLabel.selectedProperties,
                                space: selectedFiltersWithLabel.selectedSpaces,
                                product_group: selectedFiltersWithLabel.selectedProductTypes,
                                package: selectedFiltersWithLabel.selectedPackages ?? [],
                            }}
                            onRemove={clearInput}
                            optionMetadata={optionMetadata}
                        />
                    )}
                    <TableMenu
                        selectedCount={Object.keys(rowSelection).length}
                        previewDeletion={previewDeletion}
                        changedPrices={previewPrices.length > 0}
                        setPreviewDeletion={setPreviewDeletion}
                        onCancelPrice={cancelPrice}
                        previewPrice={previewPrice}
                        addToBasket={addToBasket}
                        confirmPrice={confirmPrice}
                        tableData={table}
                        resetRowSelection={() => {
                            setRowSelection({});
                        }}
                    />
                    <GenericTable table={table} rowClickHandler={({ original }) => navigate(`${original.id}`)} />
                    <Navigation data={data} setPage={setPage} />

                    {filteredData?.length === 0 &&
                        data &&
                        (!searchField &&
                        Object.values(selectedFiltersWithLabel).flatMap((item) => item).length === 0 ? (
                            <EmptyState>
                                <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_products_description')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        ) : (
                            <EmptyState>
                                <EmptyState.Title>{L('no_products_found')}</EmptyState.Title>
                                <EmptyState.Description>
                                    <p>{L('no_products_found_description')}</p>
                                    <p>{L('please_try_something_else')}</p>
                                </EmptyState.Description>
                            </EmptyState>
                        ))}
                </>
            }
        />
    );
};

const ProductsPage = () => {
    return <ProductsComponent />;
};

export default ProductsPage;
