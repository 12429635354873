import styled from '@emotion/styled';
import React from 'react';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { style } from '../../../components/Shared/Style';

const StyledSpan = styled.span({
    fontSize: style.fontSize.milli,
    color: style.colors.secondary,
    textAlign: 'center'
});

const FlexGapContainer = styled.div({ display: 'flex', alignItems: 'center', gap: '0.3rem' });

export default function CheckboxOption({
    name,
    selected,
    changeSelected
}: {
    name: string;
    selected: boolean;
    changeSelected: () => void;
}) {
    return (
        <FlexGapContainer>
            <StyledSpan>{name}</StyledSpan>
            <Checkbox name={name} checked={selected} onChange={changeSelected} />
        </FlexGapContainer>
    );
}
