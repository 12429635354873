import { useMutation, useQueryClient } from 'react-query';
import { getServicePartnerCategoriesQueryKey } from './useGetServicePartnerCategories';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';

type Variables = {
    servicePartnerId: number;
    categoryId: number;
};
const deleteServicePartnerCategory = async (servicePartnerId: number, categoryId: number) => {
    return ServicePartnersApi.deleteServicePartnerCategory(servicePartnerId, categoryId);
};

const useDeleteServicePartnerCategory = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (variables: Variables) => deleteServicePartnerCategory(variables.servicePartnerId, variables.categoryId),
        {
            onSettled: () => {
                queryClient.invalidateQueries(getServicePartnerCategoriesQueryKey);
            },
        }
    );
};

export default useDeleteServicePartnerCategory;
