import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';
import { SpaceFilter } from '@app/trpc-api/models/Binders';

export const getSpaceKeys = {
    all: ['binder-property-spaces'] as const,
    lists: () => [...getSpaceKeys.all, 'list'] as const,
    list: ({
        propertyId,
        sorting,
        search,
        filter,
    }: {
        propertyId: number;
        sorting: SortingState;
        search: string;
        filter: SpaceFilter;
    }) => [...getSpaceKeys.lists(), { propertyId, sorting, search, filter }] as const,
    details: () => [...getSpaceKeys.all, 'detail'] as const,
    detail: (spaceId: number) => [...getSpaceKeys.details(), spaceId] as const,
    spaceNames: () => [...getSpaceKeys.details(), 'spaceName'] as const,
    spaceName: (spaceId: number | null) => [...getSpaceKeys.spaceNames(), spaceId] as const,
    documents: () => [...getSpaceKeys.all, 'documents'] as const,
    documentsBySpaceId: (spaceId: number) => [...getSpaceKeys.documents(), spaceId] as const,
};

export const getSpacesFilterKeys = {
    all: ['binders-spaces-filter'] as const,
    lists: () => [...getSpacesFilterKeys.all, 'list'] as const,
    list: (params: { propertyId: number }) => [...getSpacesFilterKeys.lists(), params],
};

export function useGetPropertySpace(spaceId: number, propertyId: number) {
    return useQuery(getSpaceKeys.detail(spaceId), () => trpc.BindersApi.getSpace.query({ spaceId, propertyId }), {
        keepPreviousData: true,
    });
}

export function useGetSpaceName(spaceId: number | null) {
    return useQuery(
        getSpaceKeys.spaceName(spaceId),
        () => {
            if (!spaceId) {
                return Promise.reject(new Error('no spaceId id provided'));
            }
            return trpc.BindersApi.getSpaceName.query({ entityId: spaceId });
        },
        {
            enabled: spaceId !== null,
        },
    );
}

export function useGetPropertySpacesFilters(propertyId: number) {
    return useQuery(
        getSpacesFilterKeys.list({ propertyId }),
        () => trpc.ProductsApi.getPropertySpacesFilters.query({ propertyId }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetSpacesByFilters({
    propertyId,
    sorting,
    search,
    filter,
}: {
    propertyId: number;
    sorting: SortingState;
    search: string;
    filter: SpaceFilter;
}) {
    return useQuery(
        [...getSpaceKeys.list({ propertyId, sorting, search, filter })],
        async ({ pageParam = 1 }) =>
            trpc.BindersApi.getPropertySpacesByFilters.query({ propertyId, page: pageParam, sorting, search, filter }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetSpaceImages(spaceId: number) {
    return useQuery(
        [...getSpaceKeys.documentsBySpaceId(spaceId)],
        async () => trpc.BindersApi.listSpaceImages.query({ entityId: +spaceId }),
        {
            keepPreviousData: true,
        },
    );
}

export const spaceTypeKey = 'space_types';

export function useGetSpaceTypes() {
    return useQuery(spaceTypeKey, () => trpc.BindersApi.getSpaceTypes.query(), {
        keepPreviousData: true,
    });
}
