/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { LoginPage } from './areas/login/LoginPage';
import { PageWithNavigation } from './components/Layout/Page';
import { routes as Todos } from './areas/todos/navigation';
import { routes as CopyDocuments } from './areas/copy-documents/navigation';

export const routes = (
    <Switch>
        {require('./areas/login/navigation').routes}
        <LoginPage>
            <PageWithNavigation>
                <Switch>
                    {require('./areas/dashboard/navigation').routes}
                    {require('./areas/tickets/navigation').routes}
                    {/* {require('./areas/messages/navigation').routes} */}
                    {require('./areas/inventory/navigation').routes}
                    {require('./areas/addons/navigation').routes}
                    {Todos}
                    {/* {require('./areas/documents/navigation').routes}
                    {require('./areas/houses/navigation').routes} */}
                    {require('./areas/alerts/navigation').routes}
                    {require('./areas/maintenances/navigation').routes}
                    {require('./areas/contacts/navigation').routes}
                    {require('./areas/content-calendar/navigation').routes}
                    {require('./areas/content-information/navigation').routes}
                    {require('./areas/content-news/navigation').routes}
                    {require('./areas/binders/navigation').routes}
                    {require('./areas/project-templates/navigation').routes}
                    {require('./areas/property-templates/navigation').routes}
                    {require('./areas/notifications/navigation').routes}
                    {require('./areas/profile/navigation').routes}
                    {require('./areas/inventory-library/navigation').routes}
                    {require('./areas/addon-settings/navigation').routes}
                    {require('./areas/template-settings/navigation').routes}
                    {require('./areas/administration-settings/navigation').routes}
                    {require('./areas/ticket-settings/navigation').routes}
                    {require('./areas/information-settings/navigation').routes}
                    {require('./areas/copy-function/navigation').routes}
                    {CopyDocuments}
                </Switch>
            </PageWithNavigation>
        </LoginPage>
        <Route>
            <div>404 not found</div>
        </Route>
    </Switch>
);
