import React from 'react';
import styled from '@emotion/styled';
import { StyleColumn } from '../../../types/ColumnType';
import { Link } from 'react-router-dom-v5-compat';
import { style } from '../../../components/Shared/Style';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import { L } from '../../../lib/i18n';
import { colors } from '../../../components/Shared/Style/colors';
import { margin } from '../../../components/Shared/Style/margin';
import { AccountContact } from '@app/api/models/AccountContacts';

dayjs.locale(sv);

const Span = styled.span({
    textAlign: 'left',
    fontSize: '14px',
});

const Properties = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    fontSize: style.fontSize.milli,
    textAlign: 'left',
    gap: '0.5rem',
    'span:not(:last-child):after': {
        content: `","`,
    },
});

const StyledNavLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
});

const StyledP = styled.p({
    color: colors.black80,
    marginTop: margin.s,
    fontSize: '12px',
});

export const COLUMNS: StyleColumn<AccountContact>[] = [
    {
        Header: 'Namn och mail',
        accessor: (value) => [value.email, value.name],
        width: 175,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contact = row.original;
            return (
                <StyledNavLink to={`/contacts/${contact.id}`}>
                    <Span>{contact.name}</Span>
                    <StyledP>{contact.email}</StyledP>
                </StyledNavLink>
            );
        },
    },
    {
        Header: 'Roll',
        accessor: (contact) => contact.title,
        width: 225,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contact = row.original;
            return (
                <Properties>
                    <Span key={contact.title}>{contact.title}</Span>
                </Properties>
            );
        },
    },
    {
        Header: () => L('telephone_number'),
        id: 'telephone_number',
        accessor: (contact) => contact.phone_number,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contact = row.original;
            return <Span>{contact.phone_number}</Span>;
        },
    },
];

export const COLUMNSMOBILE: StyleColumn<AccountContact>[] = [
    {
        Header: 'Namn och mail',
        accessor: (contact) => [contact.email, contact.name],
        width: 150,
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contact = row.original;
            return (
                <StyledNavLink to={`/contact/${contact.id}`}>
                    <Span>{contact.name}</Span>
                    <StyledP>{contact.email}</StyledP>
                </StyledNavLink>
            );
        },
    },

    {
        Header: 'Roll',
        accessor: 'title',
        style: {
            justifyContent: 'left',
            alignItems: 'center',
        },
        Cell: ({ row }) => {
            const contact = row.original;
            return <Span>{L(contact.title)}</Span>;
        },
    },
];
