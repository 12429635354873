import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useHistory, useParams } from 'react-router-dom';
import { getMaintenanceQueryKey } from './useGetMaintenance';
import { SaveMaintenance } from '@app/api/models/Maintenances';

type Variables = { maintenaces: Partial<SaveMaintenance>[] };

const saveMultipleMaintance = async (projectId: string, variables: Variables) => {
    const resp = await Promise.all(
        variables.maintenaces.map(maintenace => MaintenancesApi.save(+projectId, maintenace))
    );
    return resp;
};

const useSaveMultipleMaintance = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ projectId: string }>();
    return useMutation((variables: Variables) => saveMultipleMaintance(projectId, variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
            history.push(`/maintenance/${projectId}`);
        }
    });
};

export default useSaveMultipleMaintance;
