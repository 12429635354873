import React, { useRef, useState } from 'react';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import { variant } from 'styled-system';
import StyledCross from './emotion/StyledCross';
import LineThroughText from './emotion/LineThroughText';
import SpanButton from './emotion/SpanButton';
import PrimaryText from './emotion/PrimaryText';
import EditPen from './emotion/EditPen';
import { L } from '../../../lib/i18n';
import { TableMeta } from '../TableMetaTypes/TableMetaType';
import { InventoryData } from '@app/trpc-api/models/Binders';

const CellContainer = styled.div({
    display: 'flex',
    columnGap: '0.2rem',
    alignItems: 'center',
});

type CssDivProps = {
    variant?: 'center' | 'start';
};

const SpacedContainer = styled.div<CssDivProps>(
    {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: '0.3rem',
        [':hover']: {
            cursor: 'pointer',
        },
    },
    variant({
        variants: {
            center: {
                alignItems: 'center',
            },
            start: {
                alignItems: 'start',
            },
        },
    }),
);

type InputProps = {
    variant?: string;
};

const StyledInput = styled.input<InputProps>(
    {
        boxSizing: 'border-box',
        border: 'none',
        borderBottom: `1px solid ${style.colors.secondary}`,
        borderRadius: 0,
        width: 70,
        outline: 'none',
        '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        appearance: 'textfield',
    },
    variant({
        variants: {
            primary: {
                color: '',
            },
            green: {
                color: 'green',
            },
            red: {
                color: 'red',
            },
        },
    }),
);

const CenteredSpan = styled.span({ alignItems: 'center', display: 'flex' });

const procentualAdd = (priceAdjustment: number, price: number) => {
    if (price >= 0) {
        return Math.round(priceAdjustment * price);
    } else {
        //NAN
        return '-';
    }
};

export type MutatedInventoryData = InventoryData & {
    stagedDeletion: boolean;
    newPrice:
        | false
        | {
              id: number;
              price: number;
              addon_price: number;
              isProcentual: boolean;
          }
        | undefined;
    inbasket: boolean;
};

type Props = Pick<TableMeta, 'updateData' | 'previewPrices' | 'previewDeletion'> &
    Pick<InventoryData, 'addon_price' | 'id'>;

export default function NumericCell({ previewDeletion, previewPrices, updateData, addon_price, id }: Props) {
    const initialValue: string | number | undefined = Number(addon_price) ? Number(addon_price) : 0;

    // const updateData = tableProps.table.options.meta?.updateData;
    // const previewDeletion = tableProps.table.options.meta?.previewDeletion;

    const [showInput, setShowInput] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    // const onFocus = () => {
    //     tableProps.table.options.meta?.toggleRowSelected(true);
    // };

    const newPrice = previewPrices.find((item) => id === item.id);

    const onExit = (e: React.FocusEvent<HTMLInputElement>) => {
        if (Number(e.target.value) === initialValue || Number(e.target.value) < 0) {
            if (inputRef?.current) {
                inputRef.current.value = '';
            }
            // tableProps.cell.row.toggleRowSelected(false);
            setShowInput(false);
            return;
        }
        if (!Number(e.target.value) && e.target.value === '') {
            // tableProps.cell.row.toggleRowSelected(false);
            setShowInput(false);
            return;
        }
        if (Number(e.target.value) === 0) {
            if (!updateData) return;
            updateData({
                id,
                price: 0,
                addon_price: addon_price,
            });
            setShowInput(false);
            return;
        }
        if (!updateData) return;
        updateData({
            id,
            price: Number(e.target.value),
            addon_price: addon_price,
        });
        setShowInput(false);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            onExit(e);
        }
    };

    return (
        <>
            {newPrice && !showInput ? (
                <SpacedContainer>
                    <LineThroughText>{addon_price ? addon_price + ` ${L('currencySEK')}` : 'Standard'}</LineThroughText>{' '}
                    <PrimaryText>
                        {newPrice.isProcentual ? procentualAdd(newPrice.price, Number(addon_price)) : newPrice.price}{' '}
                        {L('currencySEK')}
                    </PrimaryText>
                    <SpanButton
                        role="button"
                        onClick={() => {
                            setShowInput(true);
                        }}
                    >
                        <EditPen />
                    </SpanButton>
                </SpacedContainer>
            ) : showInput ? (
                <SpacedContainer variant={'center'}>
                    <CellContainer>
                        <StyledInput
                            type="number"
                            pattern=".{10,10}"
                            ref={inputRef}
                            onBlur={onExit}
                            onKeyDown={onKeyDown}
                            // onFocus={onFocus}
                            placeholder={initialValue.toString()}
                            onDoubleClick={(e) => {
                                e.stopPropagation();
                            }}
                        />
                        <CenteredSpan
                            onClick={() => {
                                setShowInput(false);
                            }}
                        >
                            {<StyledCross />}
                        </CenteredSpan>
                    </CellContainer>
                </SpacedContainer>
            ) : (
                <SpacedContainer
                    variant={'center'}
                    onClick={() => {
                        // Can't edit price if items are staged for deletion.
                        // TODO. Make more clear.
                        if (previewDeletion && previewDeletion.length <= 0) {
                            setShowInput(true);
                        }
                    }}
                >
                    <span>{addon_price ? addon_price + ' ' + L('currencySEK') : 'Standard'}</span>
                    <CenteredSpan>
                        <EditPen />
                    </CenteredSpan>
                </SpacedContainer>
            )}
        </>
    );
}
