import React, { useEffect } from 'react';

type Params<T extends HTMLElement> = {
    ref: React.RefObject<T>;
    clickOutsideFunc: () => void;
};

const useHandleClickOutside = <T extends HTMLElement>({ ref, clickOutsideFunc }: Params<T>) => {
    return useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (target && ref.current && !ref.current.contains(target)) {
                clickOutsideFunc();
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref]);
};

export default useHandleClickOutside;
