import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import Input from '../../../components/Forms/Input';
import { TextArea } from '@ourliving/ourliving-ui';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import {
    useGetAssignees,
    useGetInventoriesForSpace,
    useGetSettingsByType,
    useGetSpacesForProperty,
    useGetTicket,
} from '../hooks/ticketQueries';
import DocumentClick from '../../../components/document/DocumentClick/DocumentClick';
import { useHistory } from 'react-router-dom';
import checkUnsavedData from '../../../lib/helpers/checkUnsavedData';
import { SaveTicket } from '@app/api/models/Tickets';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import toast from 'react-hot-toast';
import useSaveTicket from '../hooks/useSaveTicket';
import InputSplit from '../../../components/Forms/InputSplit';
import { Button, Dropzone } from '@ourliving/ourliving-ui';
import formatOptionLabelColorBox from './formatOptionsLabel';
import useGetSignedUrlById from '../../../lib/hooks/useGetSignedUrlById';
import { useDocumentDetails } from '../../../lib/hooks/useGetDocumentDetail';
import useGetProjects from '../../../lib/hooks/useGetProjects';
import { useGetProjectProperties } from '../../../lib/hooks/useGetProperties';
import ProductPage from '../ProductPage';
import { Product } from '@app/api/models/Products';
import { Property } from '@app/api/models/Properties';
import { colors } from '../../../components/Shared/Style/colors';
import { style } from '../../../components/Shared/Style';
import * as Dialog from '@radix-ui/react-dialog';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui';

type Ticket = Partial<SaveTicket>;

const SettingsWrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    maxWidth: '100%',
    flexDirection: 'column',
    padding: '0.4rem 1rem 0.4rem 0.4rem',
    backgroundColor: colors.inputBackground,
    borderRadius: style.roundCornerSize.small,
});

const Wrapper = styled.div({
    height: '100%',
    overflow: 'auto',
    ['@media screen and (max-width: 992px)']: {
        width: '100%',
    },
});

const Form = styled.form({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '2rem',
    height: '60vh',
});

const ButtonWrapper = styled.div({
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
    alignItems: 'end',
    marginRight: '1rem',
    marginTop: style.margin.m,
});

const StyledTextArea = styled(TextArea)({
    color: colors.inputColor,
    fontSize: '80%',
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

type DocumentUrl = {
    id: string;
} & Omit<React.ComponentProps<typeof DocumentClick>, 'url'>;
const DocumentUrlProvider = ({ id, ...rest }: DocumentUrl) => {
    const { data } = useGetSignedUrlById(id, '1920x0');

    return <DocumentClick {...rest} url={data} />;
};

type TicketData = ReturnType<typeof useGetTicket>['data'];
type Props = {
    readOnly?: boolean;
    data?: TicketData;
    isNewTicket?: boolean;
};

const handleError = (isError?: boolean) => {
    return isError ? L('error_required') : undefined;
};
const EditTicket = ({ readOnly = false, data, isNewTicket = false }: Props) => {
    const history = useHistory();
    const [ticket, setTicket] = React.useState<Partial<SaveTicket>>({ ...data });
    const [errors, setErrors] = useState<Partial<Record<keyof SaveTicket, boolean>>>({});
    const handleChange = <T extends keyof Ticket>(key: T, value: Ticket[T]) => {
        if (readOnly) return;
        setErrors({});
        setTicket((prev) => ({ ...prev, [key]: value }));
    };

    useEffect(() => {
        if (!data) return;
        setTicket({ ...data });
    }, [data]);

    // Handle Projects
    const { data: projects } = useGetProjects('ticket');
    const projectsOptions = projects?.map((project) => ({ label: project.name, value: project.id })) || [];
    // Handle Properties
    const { data: properties } = useGetProjectProperties(ticket.project_id?.toString());
    // Handle Spaces
    const { data: spaces } = useGetSpacesForProperty(ticket.property_id?.toString());
    // Handle inventory
    const { data: inventories } = useGetInventoriesForSpace(ticket.space_id?.toString());

    // Handle assignee
    const { data: assignee } = useGetAssignees();
    // Handle Priority
    const { data: priorities } = useGetSettingsByType('priority');
    const priorityOptions = priorities?.map((priority) => ({ label: priority.name, value: priority })) || [];

    // Handle status
    const { data: statuses } = useGetSettingsByType('status');
    const statusOptions = statuses?.map((status) => ({ label: status.name, value: status })) || [];

    // Handle type
    const { data: types } = useGetSettingsByType('type');
    const typeOptions = types?.map((type) => ({ label: type.name, value: type })) || [];
    // Handle files
    const documentsQueries = useDocumentDetails(ticket?.attachment_ids);
    const savedDocuments = documentsQueries.map((query) => {
        if (!query.data) return null;
        const { name, id, document_data } = query.data;
        const attachments = ticket.attachment_ids || [];
        return (
            <DocumentUrlProvider
                key={id}
                id={document_data.original.id}
                name={name}
                fileType={document_data.original.metadata.mime_type}
                deleteDocument={
                    readOnly
                        ? undefined
                        : () => {
                              setTicket((prev) => ({
                                  ...prev,
                                  attachment_ids: [...attachments?.filter((item) => item !== id.toString())],
                              }));
                          }
                }
            />
        );
    });
    const [files, setFiles] = React.useState<File[]>([]);

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return toast.error(L('pg_unkown'));
        const duplicate = files.some((file) => file.name === param.file.name);

        if (!duplicate) {
            setFiles((prevFiles) => [...prevFiles, param.file]);
        }
    };

    const localFiles = files.map((file) => {
        return (
            <DocumentClick
                key={file.name}
                name={file.name}
                fileType={file.type}
                deleteDocument={
                    readOnly
                        ? undefined
                        : () => {
                              setFiles((prev) => [...prev].filter((item) => item.name !== file.name));
                          }
                }
            />
        );
    });

    const { mutate: saveTicket } = useSaveTicket();
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!ticket.title || !ticket.project_id || !ticket.description || !ticket.property_id) {
            setErrors({
                title: !ticket.title,
                description: !ticket.description,
                property_id: !ticket.property_id,
                project_id: !ticket.project_id,
            });
            return;
        }

        saveTicket(
            {
                ticket: {
                    ...ticket,
                    title: ticket.title,
                    description: ticket.description,
                    property_id: ticket.property_id,
                    project_id: ticket.project_id,
                },
                files,
            },
            {
                onSuccess: (data, vaiables) => {
                    setFiles([]);
                    toast.success(`${L('ticket_saved')}`);
                    if (!vaiables.ticket.id && data?.id) {
                        history.push(`/tickets/${data.id}`);
                    }
                },
                onError: (error) => {
                    if (error instanceof Error) {
                        toast.error(`${L(error.message)}`);
                    }
                },
            },
        );
    };

    const { push } = useHistory();

    const handleCancel = () => {
        const dataHasChanged = checkUnsavedData({
            originalData: data || {},
            changedData: ticket,
            buttonText: L('proceed_without_saving'),
            fn: () => {
                push('/tickets');
            },
        });
        if (!dataHasChanged) {
            push('/tickets');
        }
    };
    const [openProduct, setOpenProduct] = useState<React.ReactNode | undefined>();

    const handleClickProduct = (
        e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
        productId: Product['id'],
        propertyId: Property['id'],
    ) => {
        e.stopPropagation();
        setOpenProduct(<ProductPage productId={productId} propertyId={propertyId} />);
    };
    const propertyOptions = properties?.map((property) => ({ label: property.name, value: property.id })) || [];
    const assigneeOptions = assignee?.map((p) => ({ label: p.name, value: p.id })) || [];
    const spacesOptions = spaces?.map((p) => ({ label: p.name, value: p.id })) || [];
    const inventoriesOptions =
        inventories?.map((inventory) => {
            const propertyId = ticket.property_id;
            return {
                label: (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>{inventory.name}</span>

                        {propertyId && (
                            <span onClick={(e) => handleClickProduct(e, inventory.id, propertyId)}>Länk</span>
                        )}
                    </div>
                ),
                value: inventory.id,
            };
        }) || [];
    //const inventoriesOptions = inventories?.map(p => ({ label: p.name, value: p.id })) || [];

    const costumerCostOptions = [
        { label: L('residence'), value: true },
        { label: projectsOptions.find((project) => project.value === ticket.project_id)?.label || '', value: false },
    ];

    const [open, setOpen] = useState<React.ReactNode | undefined>();

    const projectSelect = data ? (
        <ReactSelect
            isDisabled
            placeholder={data.project_name}
            menuPortalTarget={document.parentElement}
            menuShouldBlockScroll={false}
            menuShouldScrollIntoView={true}
        />
    ) : (
        <ReactSelect
            placeholder={L('select_project')}
            isClearable
            value={projectsOptions.find((p) => p.value === ticket.project_id) ?? null}
            onChange={(e) => {
                handleChange('property_id', undefined);
                handleChange('inventory_id', undefined);
                handleChange('space_id', undefined);
                handleChange('project_id', e?.value);
            }}
            options={projectsOptions}
            menuPortalTarget={document.parentElement}
            menuShouldBlockScroll={false}
            menuShouldScrollIntoView={true}
        />
    );
    const property = data ? (
        <ReactSelect
            isDisabled
            placeholder={data.property_name}
            menuPortalTarget={document.parentElement}
            menuShouldBlockScroll={false}
            menuShouldScrollIntoView={true}
        />
    ) : (
        <ReactSelect
            placeholder={L('select_property')}
            isClearable
            value={propertyOptions.find((p) => p.value === ticket.property_id) ?? null}
            onChange={(e) => {
                handleChange('property_id', e?.value);
                handleChange('inventory_id', undefined);
                handleChange('space_id', undefined);
            }}
            options={propertyOptions}
            noOptionsMessage={() => L('no_project_selected')}
            menuPortalTarget={document.parentElement}
            menuShouldBlockScroll={false}
            menuShouldScrollIntoView={true}
        />
    );

    return (
        <>
            <Dialog.Root
                open={!!open}
                onOpenChange={(e) => {
                    if (!e) setOpen(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{open}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <Dialog.Root
                open={!!openProduct}
                onOpenChange={(e) => {
                    if (!e) setOpenProduct(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{openProduct}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <Wrapper>
                <Form style={{ display: isNewTicket ? 'grid' : 'inherit' }} onSubmit={handleSubmit}>
                    <SettingsWrapper
                        style={{
                            maxHeight: isNewTicket ? 'initial' : '',
                            display: isNewTicket ? 'grid' : '',
                            gridTemplateColumns: '3fr 1fr',
                        }}
                    >
                        <div>
                            <Label title={L('title')} error={handleError(errors.title)}>
                                <Input
                                    readOnly={readOnly}
                                    value={ticket.title || ''}
                                    onChange={(e) => handleChange('title', e.target.value)}
                                    required
                                />
                            </Label>

                            <Label title={L('description')} error={handleError(errors.description)}>
                                <StyledTextArea
                                    readOnly={readOnly}
                                    value={ticket.description?.plain_text || ''}
                                    onChange={(e) => handleChange('description', { plain_text: e.target.value })}
                                    required
                                />
                            </Label>
                            <div>
                                <Label title={L('attachments')} />
                                {savedDocuments}
                                {localFiles.length > 0 && <Label title={L('un_saved_files')}>{localFiles}</Label>}
                                <Dropzone onUpload={onUpload} multiple={true} />
                            </div>
                        </div>
                        <div>
                            <Label title={L('project')} error={handleError(errors.project_id)}>
                                {projectSelect}
                            </Label>
                            <Label title={L('property')} error={handleError(errors.property_id)}>
                                {property}
                            </Label>
                            <Label title={L('space')}>
                                <ReactSelect
                                    isDisabled={readOnly}
                                    value={spacesOptions.find((p) => p.value === ticket.space_id) ?? null}
                                    isClearable
                                    noOptionsMessage={() =>
                                        spacesOptions.find((p) => p.value === ticket.space_id)
                                            ? `${L('no_spaces')}`
                                            : `${L('no_property_selected')}`
                                    }
                                    onChange={(e) => {
                                        handleChange('inventory_id', undefined);
                                        handleChange('space_id', e?.value);
                                    }}
                                    options={spacesOptions}
                                    placeholder={L('select_space')}
                                    menuPortalTarget={document.parentElement}
                                    menuShouldBlockScroll={false}
                                    menuShouldScrollIntoView={true}
                                />
                            </Label>
                            <Label title={L('item')}>
                                <ReactSelect
                                    isDisabled={readOnly}
                                    placeholder={L('select_item')}
                                    noOptionsMessage={() =>
                                        spacesOptions.find((p) => p.value === ticket.space_id)
                                            ? `${L('no_items')}`
                                            : `${L('no_space_selected')}`
                                    }
                                    isClearable
                                    value={inventoriesOptions.find((p) => p.value === ticket.inventory_id) ?? null}
                                    onChange={(e) => handleChange('inventory_id', e?.value)}
                                    options={inventoriesOptions}
                                    menuPortalTarget={document.parentElement}
                                    menuShouldBlockScroll={false}
                                    menuShouldScrollIntoView={true}
                                />
                            </Label>
                            <Label title={L('assignee')} error={handleError(errors.assigned_id)}>
                                <ReactSelect
                                    isDisabled={readOnly}
                                    placeholder={L('select_assignee')}
                                    value={assigneeOptions.find((p) => p.value === ticket.assigned_id) ?? null}
                                    isClearable
                                    onChange={(e) => handleChange('assigned_id', e?.value ? e.value : null)}
                                    options={assigneeOptions}
                                    menuPortalTarget={document.parentElement}
                                    menuShouldBlockScroll={false}
                                    menuShouldScrollIntoView={true}
                                />
                            </Label>
                            <Label title={L('priority')} error={handleError(errors.ticket_priority_id)}>
                                <ReactSelect
                                    placeholder={L('select_priority')}
                                    isDisabled={readOnly}
                                    isClearable
                                    value={
                                        priorityOptions.find(
                                            (option) => option.value.id === ticket.ticket_priority_id,
                                        ) ?? null
                                    }
                                    options={priorityOptions}
                                    onChange={(e) => handleChange('ticket_priority_id', e?.value.id)}
                                    formatOptionLabel={formatOptionLabelColorBox}
                                    menuPortalTarget={document.parentElement}
                                    menuShouldBlockScroll={false}
                                    menuShouldScrollIntoView={true}
                                />
                            </Label>
                            <Label title={L('status')} error={handleError(errors.ticket_status_id)}>
                                <ReactSelect
                                    placeholder={L('select_status')}
                                    isClearable
                                    isDisabled={readOnly}
                                    value={
                                        statusOptions.find((option) => option.value.id === ticket.ticket_status_id) ??
                                        null
                                    }
                                    options={statusOptions}
                                    onChange={(e) => handleChange('ticket_status_id', e?.value.id)}
                                    formatOptionLabel={formatOptionLabelColorBox}
                                    menuPortalTarget={document.parentElement}
                                    menuShouldBlockScroll={false}
                                    menuShouldScrollIntoView={true}
                                />
                            </Label>
                            <Label title={L('type')} error={handleError(errors.ticket_type_id)}>
                                <ReactSelect
                                    placeholder={L('select_type')}
                                    isClearable
                                    isDisabled={readOnly}
                                    value={
                                        typeOptions.find((option) => option.value.id === ticket.ticket_type_id) ?? null
                                    }
                                    options={typeOptions}
                                    onChange={(e) => handleChange('ticket_type_id', e?.value.id)}
                                    formatOptionLabel={formatOptionLabelColorBox}
                                />
                            </Label>
                            <Label title={L('cost')}>
                                <InputSplit
                                    readOnly={readOnly}
                                    value={ticket.ticket_cost || ''}
                                    onChange={(e) => handleChange('ticket_cost', Number(e.target.value))}
                                    splitInput={'kr'}
                                    type={'number'}
                                    min={0}
                                />
                            </Label>
                            {!!ticket.ticket_cost && (
                                <Label title={L('who_pays')}>
                                    <ReactSelect
                                        isDisabled={readOnly}
                                        value={costumerCostOptions.find(
                                            (p) => !!p?.value === !!ticket?.is_ticket_customer_cost,
                                        )}
                                        onChange={(e) => handleChange('is_ticket_customer_cost', e?.value)}
                                        options={costumerCostOptions}
                                        menuPortalTarget={document.parentElement}
                                        menuShouldBlockScroll={false}
                                        menuShouldScrollIntoView={true}
                                    />
                                </Label>
                            )}
                        </div>
                    </SettingsWrapper>
                    {!readOnly && (
                        <ButtonWrapper>
                            {isNewTicket && (
                                <Button type={'button'} onClick={handleCancel} variant="secondary">
                                    {L('cancel')}
                                </Button>
                            )}
                            <Button>{L('save')}</Button>
                        </ButtonWrapper>
                    )}
                </Form>
            </Wrapper>
        </>
    );
};

export default EditTicket;
