import React from 'react';
import { Card } from '../../../../components/Layout/Card';
import { style } from '@app/shared/lib/Style';
import { L } from '../../../../lib/i18n';
import { useGetResponseText } from '../hooks/useGetResponseText';
import { useSaveResponseText } from '../hooks/useSaveResponseText';
import styled from '@emotion/styled';
import Label from '../../../../components/Forms/Label';
import { Button } from '@ourliving/ourliving-ui';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import triggerDirtyFieldsToast from '../../../../components/Forms/DirtyFieldsToast';
import Divider from '../../../../components/thread/divider/Divider';
import { Loading } from '../../../../components/Loading/Loading';
import { useGetServiceText } from '../hooks/useGetServiceText';
import { useSaveServiceText } from '../hooks/useSaveServiceText';
import Quill from '../../../../components/Quill/Quill';

const StyledLabel = styled(Label)({
    width: '100%',
});

const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
});

const ButtonWrapper = styled.div({
    width: '100%',
    display: 'grid',
    placeContent: 'end',
    marginTop: '1rem',
});

export default function ResponseTextPage() {
    const { data: responseText, status: responseStatus } = useGetResponseText();
    const { mutate: saveResponseText } = useSaveResponseText();

    const { data: serviceText, status: serviceResponse } = useGetServiceText();
    const { mutate: saveServiceText } = useSaveServiceText();

    const {
        register: serviceRegister,
        handleSubmit: serviceHandleSubmit,
        control: serviceControl,
        resetField: resetService,
    } = useForm({});

    const {
        register: responseRegister,
        handleSubmit: responseHandleSubmit,
        control: responseControl,
        resetField: resetResponse,
    } = useForm({});

    const handleConfirmationText = (data: { responseText: string; responseIsTransferred: boolean }) => {
        if (data.responseIsTransferred) {
            return triggerDirtyFieldsToast({
                title: L('confirmationTextUpdate'),
                continueButtonText: L('yes'),
                continueFn: () => {
                    saveResponseText(
                        {
                            text: data.responseText,
                            isTransferred: data.responseIsTransferred,
                        },
                        {
                            onSuccess: () => {
                                resetResponse('responseIsTransferred');
                                toast.success(L('confirmation_updated'));
                            },
                        },
                    );
                },
            });
        } else {
            saveResponseText(
                {
                    text: data.responseText,
                    isTransferred: data.responseIsTransferred,
                },
                {
                    onSuccess: () => {
                        resetResponse('responseIsTransferred');
                        toast.success(L('confirmation_updated'));
                    },
                },
            );
        }
    };
    const handleServiceText = (data: { serviceText: string; serviceIsTransferred: boolean }) => {
        if (data.serviceIsTransferred) {
            return triggerDirtyFieldsToast({
                title: L('confirmationTextUpdate'),
                continueButtonText: L('yes'),
                continueFn: () => {
                    saveServiceText(
                        {
                            text: data.serviceText,
                            isTransferred: data.serviceIsTransferred,
                        },
                        {
                            onSuccess: () => {
                                resetService('serviceIsTransferred');
                                toast.success(L('info_updated'));
                            },
                        },
                    );
                },
            });
        } else {
            saveServiceText(
                {
                    text: data.serviceText,
                    isTransferred: data.serviceIsTransferred,
                },
                {
                    onSuccess: () => {
                        resetService('serviceIsTransferred');
                        toast.success(L('info_updated'));
                    },
                },
            );
        }
    };

    if (responseStatus === 'loading' && serviceResponse === 'loading') {
        return <Loading />;
    }

    return (
        <Card background={style.colors.white}>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    serviceHandleSubmit(handleServiceText)();
                }}
            >
                <StyledLabel title={L('isTransferred')}>
                    <Checkbox {...serviceRegister('serviceIsTransferred')} />
                </StyledLabel>
                <StyledLabel title={L('ticket_customize_service_text')}>
                    <Controller
                        name="serviceText"
                        control={serviceControl}
                        render={({ field }) => {
                            return (
                                <Quill
                                    {...field}
                                    value={field.value ?? serviceText?.text}
                                    placeholder="Skriv något här..."
                                />
                            );
                        }}
                    />
                </StyledLabel>
                <ButtonWrapper>
                    <Button type="submit">{L(`save`)}</Button>
                </ButtonWrapper>
            </Form>
            <Divider />
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    responseHandleSubmit(handleConfirmationText)();
                }}
            >
                <StyledLabel title={L('isTransferred')}>
                    <Checkbox {...responseRegister('responseIsTransferred')} />
                </StyledLabel>
                <StyledLabel title={L('ticket_customize_confirmation_text')}>
                    <Controller
                        name="responseText"
                        control={responseControl}
                        render={({ field }) => {
                            return (
                                <Quill
                                    {...field}
                                    value={field.value ?? responseText?.text}
                                    placeholder="Skriv något här..."
                                />
                            );
                        }}
                    />
                </StyledLabel>
                <ButtonWrapper>
                    <Button type="submit">{L(`save`)}</Button>
                </ButtonWrapper>
            </Form>
        </Card>
    );
}
