import React from 'react';
import { L } from '../../../../lib/i18n';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import styled from '@emotion/styled';
import { ContentNewsQueryKeys, useGetContentNewsItem } from '../../hooks/ContentNews/contentNewsQueries';
import { useParams } from 'react-router-dom-v5-compat';
import ContentNewsForm, { AddContentNews } from '../../components/ContentNews/ContentNewsForm';
import { SubmitHandler } from 'react-hook-form';
import useUpdateContentNews from '../../hooks/ContentNews/useUpdateContentNews';
import toast from 'react-hot-toast';
import { trpc } from '../../../../lib/trpc';
import GroupDocumentsArea from '../../../../components/document/GroupDocumentsArea/GroupDocumentsArea';
import { uploadToAws } from '../../hooks/ContentInformation/uploadToAws';

const Wrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',
    paddingBottom: '2rem',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const ContentNewsDetailPage = () => {
    const { id: templateId, contentNewsId } = useParams<{ id: string; contentNewsId: string }>();
    return templateId && contentNewsId ? (
        <ContentNewsDetail templateId={+templateId} contentNewsId={+contentNewsId} />
    ) : null;
};

const ContentNewsDetail = ({ templateId, contentNewsId }: { templateId: number; contentNewsId: number }) => {
    const { data: contentNews, status } = useGetContentNewsItem({
        templateId: +templateId,
        contentNewsId: +contentNewsId,
    });
    const { mutate: updateContentNewsItem } = useUpdateContentNews();

    const onSubmit: SubmitHandler<AddContentNews> = (data) => {
        updateContentNewsItem(
            {
                news: data,
                projectId: +templateId,
                contentNewsId: +contentNewsId,
                file: data.image,
            },
            {
                onSuccess: () => {
                    toast.success(L('content_news_updated'));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    return (
        <Wrapper>
            <CardWithDivider
                topArea={<CardTitle>{L('content_news')}</CardTitle>}
                mainArea={
                    status !== 'loading' && (
                        <ContentNewsForm
                            imageUrl={contentNews?.url}
                            defaultValues={{
                                ...contentNews,
                                title: contentNews?.title ?? undefined,

                                description: contentNews?.description ?? undefined,
                                image: undefined,
                            }}
                            onSubmit={onSubmit}
                        />
                    )
                }
            />
            <CardWithDivider
                topArea={<CardTitle>{L('document')}</CardTitle>}
                mainArea={
                    <div>
                        <GroupDocumentsArea
                            document={{
                                get: async () => {
                                    return (
                                        await trpc.ContentNewsApi.getDocuments.query({
                                            contentNewsId: contentNewsId,
                                            projectId: templateId,
                                        })
                                    ).map((doc) => ({
                                        id: doc.id,
                                        name: doc.name || '',
                                        type: doc.document_data?.original.metadata.mime_type || '',
                                        url: doc.url,
                                        groupId: doc.groupId,
                                        sort: doc.sort,
                                    }));
                                },
                                save: async (input) => {
                                    const response = await trpc.ContentNewsApi.addDocument.mutate({
                                        file: {
                                            name: input.name,
                                            size: input.file.size,
                                            type: input.file.type,
                                        },
                                        groupId: input.documentGroupId,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                    if (response.preSingedUrl) {
                                        await uploadToAws({
                                            file: input.file,
                                            preSingedUrl: response.preSingedUrl,
                                            cleanup: async () => {
                                                return await trpc.ContentNewsApi.removeDocument.mutate({
                                                    documentId: response.documentId,
                                                    contentNewsId: contentNewsId,
                                                    projectId: templateId,
                                                });
                                            },
                                        });
                                    }
                                },
                                sort: async ({ sortedArray }) => {
                                    return await trpc.ContentNewsApi.sortDocument.mutate({
                                        documents: sortedArray,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                },
                                removeDocument: async ({ id }) => {
                                    return await trpc.ContentNewsApi.removeDocument.mutate({
                                        documentId: id,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                },
                                renameDocument: async ({ id, name }) => {
                                    return await trpc.ContentNewsApi.renameDocument.mutate({
                                        documentId: id,
                                        name: name,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                },
                                switchGroup: async ({ groupId, docId }) => {
                                    return await trpc.ContentNewsApi.switchDocumentGroup.mutate({
                                        documentId: docId,
                                        groupId,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                },
                                queryKey: ContentNewsQueryKeys.docs(contentNewsId, templateId),
                            }}
                            documentGroup={{
                                get: async () => {
                                    return (
                                        await trpc.ContentNewsApi.getDocumentGroups.query({
                                            contentNewsId: contentNewsId,
                                            projectId: templateId,
                                        })
                                    ).map((group) => ({
                                        id: group.id,
                                        name: group.name,
                                        sort: group.sort,
                                    }));
                                },
                                sort: async ({ sortedArray }) => {
                                    return await trpc.ContentNewsApi.sortDocumentGroup.mutate({
                                        groups: sortedArray,
                                        contentNewsId: contentNewsId,
                                        projectId: templateId,
                                    });
                                },
                                queryKey: ContentNewsQueryKeys.docGroups(contentNewsId, templateId),
                            }}
                        />
                    </div>
                }
            />
        </Wrapper>
    );
};

export default ContentNewsDetailPage;
