export const colors = {
    primary0: '#008000',
    statusDone: '#29CC97',
    statusWarning: '#FEC400',
    statusError: '#F12B2C',
    white: 'white',
    black: '#252733',
    black90: '#3E3E3E',
    black80: '#AFAFAF',
    black50: '#C5C7CD',
    black30: '#F0F1F7',
    black10: '#FCFDFE',
    background: '#F5F5F5',
    headerBackground: '#5d5d5d',
    primary: '#469253',
    primary50: '#C2D6A1',
    primary25: '#E4EDD6',
    alertPrimary: '#cfe2ff',
    secondary: '#9FA2B4',
    notofication: '#F12B2C',
    notificationCount: '#4282FF',
    tabInactive: '#A9D6B0',
    timeSlotBorder: '#e5e5e5',
    sideBarActive: '#272726',
    sideBarInactive: '#9A9898',
    inputDisabled: 'hsl(0, 0%, 95%)',
    newcount: '#2EB67D',
    pageBackground: '#FFFFFF',
    errors: 'red',
    inputBackground: '#FFFFFF',
    inputColor: 'hsl(0, 0%, 20%)',
    inputBorder: 'hsl(0, 0%, 80%)',
    inputPlaceholderColor: 'hsl(0, 0%, 50%)',
    deleteButton: '#dc3545',
    deleteButtonHover: '#ad2a36',
    plusButton: '#357adb',
    textColor1: '#9DA3B4',
    inputHoverBackground: '#E3F0FE',
    maintenance_completed_status: '#ABB8C3',
    textColor2: '#444444',
    tabActive: '#469253',
    avatarBackground: '#89d79b',
    divider: '#DFE0EB',
    smallTitle: '#ACACAC',
    sidebarBg: '#FAFAFA',
    alert_low: '#29CC97',
    alert_medium: '#FEC400',
    alert_high: '#EC6666',
    alertBackground: '#E0E0E0',
    progressBarBackground: '#F0F1F7',
    red: 'hsla(0, 100%, 48%, 1)',
    icons: '#000000',
    myBubble: 'hsl(82, 44%, 90%)',
    collaboratorBubble: 'hsl(191, 65%, 90%)'
};
