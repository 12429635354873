import { useQuery } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { DocumentDetail } from '@app/api/public/DocumentsApi';

export const ocrImageQueryKey = 'getOcrImage';

type Variables = {
    document: DocumentDetail;
};

const getJsonFromOcr = async (variables: Variables) => {
    if (!variables.document) return;
    const r = await InventoryApi.getJsonFromOcr(variables.document);
    return r;
};

const useGetOcrTextList = (variables: Variables) => {
    return useQuery([ocrImageQueryKey, variables.document], () => getJsonFromOcr(variables), {
        staleTime: Infinity,
        enabled: !!variables.document,
        select: data => {
            const text = data?.[0].textAnnotations?.[0].description
                ?.trim()
                .split('\n')
                .map(word => word.split(' '))
                .flat();
            return text;
        }
    });
};

const useGetOcrWords = (variables: Variables) => {
    return useQuery([ocrImageQueryKey, variables.document], () => getJsonFromOcr(variables), {
        enabled: !!variables.document,
        staleTime: Infinity,
        select: data => {
            const text = data?.[0].textAnnotations?.map(textAnnotation => textAnnotation);
            return text;
        }
    });
};

export { useGetOcrTextList, useGetOcrWords };
