import { Project } from '@app/api/models/Projects';
import AddonsApi, { SupplierFilter } from '@app/api/public/AddonsApi';
import { useMutation } from 'react-query';

const renderCsv = async ({ projectId, filter }: Variables) => {
    const r = await AddonsApi.getGrossList(projectId, filter);
    return r;
};

type Variables = {
    projectId: Project['id'];
    filter: SupplierFilter;
};

const useRenderCsv = () => {
    return useMutation(renderCsv);
};

export default useRenderCsv;
