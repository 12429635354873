Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getList"] = b.createRpcProxy("AccountContactsApi", "getList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = b.createRpcProxy("AccountContactsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["save"] = b.createRpcProxy("AccountContactsApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("AccountContactsApi", "remove", {
  "urlPrefix": "/rpc"
});
o["copyContactsToProject"] = b.createRpcProxy("AccountContactsApi", "copyContactsToProject", {
  "urlPrefix": "/rpc"
});
o["copyContactsToProperties"] = b.createRpcProxy("AccountContactsApi", "copyContactsToProperties", {
  "urlPrefix": "/rpc"
});