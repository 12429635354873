import { useMutation, useQueryClient } from 'react-query';
import { getProfessionKeys } from './professionQueries';
import { trpc } from '../../../../lib/trpc';

const useDeleteProfessions = () => {
    const client = useQueryClient();
    return useMutation(
        async (userIds: number[]) => {
            return await Promise.all(
                userIds.map(async (id) => {
                    return trpc.ProfessionApi.deleteProfession.query(id);
                }),
            );
        },
        {
            onSettled: () => {
                client.invalidateQueries(getProfessionKeys.lists());
            },
        },
    );
};

export default useDeleteProfessions;
