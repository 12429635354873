import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const useGetProjectArea = () => {
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery(['projectArea', { projectId }], () => ProjectsApi.getProjectArea(+projectId));
};

export default useGetProjectArea;
