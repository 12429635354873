import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../lib/trpc';
import { uploadToAws } from '../../project-templates/hooks/ContentInformation/uploadToAws';
import { AddPropertyDocument, DocumentTypeSchema, DocumentSubtypeSchema } from '../../binders/schemas';
import { getPropertiesKeys } from '../../binders/hooks/propertyHooks/propertyQueries';

export default function useUploadPropertyDocsToMultiple() {
    const queryClient = useQueryClient();

    return useMutation(
        async ({ documents, propertyIds }: { documents: AddPropertyDocument[]; propertyIds: number[] }) => {
            const promise = documents.map(async (doc) => {
                const fileToSave = {
                    name: doc.name,
                    size: doc.file.size,
                    type: doc.file.type,
                };
                const parsedDocType = DocumentTypeSchema.parse(doc.document_type);
                const parsedDocSubtype = DocumentSubtypeSchema.optional().parse(doc.document_subtype);

                const { preSignedUrl, uuid } = await trpc.BindersApi.getPresignedUrl.query({ file: fileToSave });

                const responses = propertyIds.map(async (propertyId) => {
                    const response = await trpc.BindersApi.savePropertyDocumentInDB.mutate({
                        file: fileToSave,
                        propertyId,
                        documentType: parsedDocType,
                        documentSubtype: parsedDocSubtype,
                        uuid,
                    });

                    return response;
                });

                await uploadToAws({
                    preSingedUrl: preSignedUrl,
                    file: doc.file,
                    cleanup: async () => {
                        const awaitedResponses = await Promise.all(responses);
                        awaitedResponses.forEach(async ({ id, propertyId }) => {
                            await trpc.BindersApi.removePropertyDocument.mutate({
                                id,
                                propertyId,
                            });
                        });
                    },
                });
                return Promise.all(responses);
            });
            return await Promise.all(promise);
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getPropertiesKeys.lists());
            },
        },
    );
}
