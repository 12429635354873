import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
} from '@tanstack/react-table';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { getSpaceKeys, useGetSpacesByFilters } from '../../hooks/spaceHooks/spaceQueries';
import { PropertySpace } from '@app/trpc-api/models/Binders';
import FilterSearchContainer from '../emotion/Filters/FilterSearchContainer';
import Row from '../emotion/Row';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { useGetPropertySpacesFilters } from '../../hooks/spaceHooks/spaceQueries';
import qs from 'qs';
import ContentContainer from '../emotion/ContentContainer';
import TableFilter from '../TableFilter';
import ChosenFilters from '../ChosenFilters/ChosenFilters';
import { useNavigate, Link } from 'react-router-dom-v5-compat';
import { Loading } from '../../../../components/Loading/Loading';
import TableImageWithFallback from '../TableImageWithFallback';
import PaddedContainer from '../emotion/PaddedContainer';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { useLocalStorage } from 'react-use';
import GenericTableDnD from '../GenericTableDnD';
import useDragEndDndGeneric from '../../../../hooks/useDragEndDndGeneric';
import useUpdateSpaceSort from '../../hooks/spaceHooks/useUpdateSpaceSort';

export type FiltersSpaces = {
    space_type: { value: string; label: string }[];
};

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<PropertySpace>();

type Props = {
    propertyId: number;
    handleSetNumberOfSpaces: (val: number | null) => void;
};

const options = [{ name: 'space_type', value: 'space_type', newValue: 'setSelectedSpaceType', id: 1 }];

const SpacesList = ({ propertyId, handleSetNumberOfSpaces }: Props) => {
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'image',
                cell: ({ row }) => (
                    <div style={{ width: '100px', aspectRatio: '4/3' }}>
                        <TableImageWithFallback id={row.original.space_image_data?.original?.id} />
                    </div>
                ),
                enableResizing: false,
                size: 75,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.space_name, {
                id: 'space_name',
                cell: (info) => (
                    <CellContainer>
                        <StyledLink to={`${info.row.original.id}`}>
                            <Data>{info.getValue()}</Data>
                        </StyledLink>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.description, {
                id: 'description',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('description')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.area, {
                id: 'area',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('area_in_m2')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.space_type, {
                id: 'space_type',
                cell: (info) => (
                    <CellContainer>
                        <Data>{L(info.row.original.space_type ?? '')}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('space_type')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [],
    );

    const [localStorageValue, setLocalStorageValue] = useLocalStorage<Record<string, boolean>>(
        'propertySpacesImageToggle',
        { image: false },
    );
    const { mutate: updateSort } = useUpdateSpaceSort();

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);

    const searchField = useMemo(() => searchParams.get('searchField') ?? '', [searchParams]);

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };

    const clearParam = useCallback(
        (type: keyof FiltersSpaces, value?: string) => {
            if (!value) {
                searchParams.delete(type);
                return;
            }
            const spaceParamString = searchParams.get(type);
            if (spaceParamString) {
                const parsedParams = qs.parse(spaceParamString, { parseArrays: true });
                const filteredParams = qs.stringify(
                    Object.values(parsedParams).filter((filter) => filter !== value),
                    { arrayFormat: 'comma' },
                );
                searchParams.set(type, filteredParams);
                navigate({
                    search: searchParams.toString(),
                });
            }
        },
        [searchParams],
    );

    //When you click X button next to selected filter, this triggers

    const clearInput = useCallback(
        (type: keyof FiltersSpaces, value: string) => {
            if (type === 'space_type') {
                clearParam(type, value);
            }
        },
        [clearParam],
    );

    const [sorting, setSorting] = useState<SortingState>([]);

    const { data: filter } = useGetPropertySpacesFilters(Number(propertyId));

    //Get all filters when initial loads are done
    const filterOptions = useMemo(() => {
        const filteredFilterData: FiltersSpaces = {
            space_type: [],
        };
        if (filter) {
            filter.spaceTypes.forEach((data) => {
                if (
                    filteredFilterData?.space_type &&
                    !filteredFilterData.space_type.find((filter) => filter.label === data.space_type)
                ) {
                    filteredFilterData.space_type.push({
                        value: data.space_type.toString(),
                        label: L(data.space_type),
                    });
                }
            });
        }
        return filteredFilterData;
    }, [filter]);

    const optionMetadata = useMemo(
        () => [{ name: 'space_type', initialTexts: [L('space_type'), L('space_types')], api: 'space_type' }],
        [],
    );

    const setSelectedSpaceType = useCallback(
        (values: { value: string; label: string }[]) => {
            const onlyValues = values.map((element) => element.value);
            const arrayToString = qs.stringify(onlyValues, { arrayFormat: 'comma' });
            searchParams.set('space_type', arrayToString);
            navigate({
                search: searchParams.toString(),
            });
        },
        [searchParams],
    );

    const selectedFilters = useMemo(() => {
        const selections: { space_type: { value: string; label: string }[] } = {
            space_type: [],
        };

        const spaceParamString = searchParams.get('space_type');
        if (spaceParamString) {
            const parsedParams = qs.parse(spaceParamString, { parseArrays: true });
            if (parsedParams) {
                selections['space_type'] = Object.values(parsedParams).map((spaceType) => {
                    const option = filterOptions.space_type.find((filter) => filter.value === spaceType);
                    return option ? option : { value: '', label: '' };
                });
            }
        }

        return selections;
    }, [searchParams, filterOptions]);

    const { data, status } = useGetSpacesByFilters({
        propertyId,
        sorting,
        search: searchField,
        filter: selectedFilters,
    });

    const isFiltering = selectedFilters.space_type.length > 0 || searchField.length > 0 || sorting.length > 0;

    const handleDragEnd = useDragEndDndGeneric({
        queryKey: getSpaceKeys.list({
            propertyId,
            sorting,
            search: searchField,
            filter: selectedFilters,
        }),
        updateSortOrder: updateSort,
        reverseArray: true,
        isFiltering,
    });

    useEffect(() => {
        handleSetNumberOfSpaces(data ? data.length ?? 0 : null);
    }, [data]);

    const table = useReactTable({
        data: data ?? [],
        columns,
        manualSorting: true,
        state: {
            sorting,
            columnVisibility: localStorageValue,
        },
        onSortingChange: setSorting,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onColumnVisibilityChange: setLocalStorageValue,
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
    });

    const items = data?.map((item) => item.id) ?? [];

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <FilterSearchContainer>
                <ContentContainer>
                    <TableFilter
                        options={options}
                        filterOptions={filterOptions}
                        selected={{
                            space_type: selectedFilters.space_type,
                        }}
                        setSelected={{
                            setSelectedSpaceType,
                        }}
                    />
                </ContentContainer>
                <ContentContainer>
                    <PaddedContainer
                        style={{
                            display: 'flex',
                            columnGap: '1rem',
                        }}
                    >
                        <div
                            style={{
                                marginLeft: 'auto',
                                display: 'flex',
                                columnGap: '0.5rem',
                                alignItems: 'center',
                            }}
                        >
                            {`${L('show_with_image')}:`}
                            <Checkbox
                                checked={table.getColumn('image')?.getIsVisible()}
                                onChange={table.getColumn('image')?.getToggleVisibilityHandler()}
                            />
                        </div>
                        <Row>
                            <GlobalFilter filter={searchField} setFilter={handleSearch} placeholder={L('search')} />
                        </Row>
                    </PaddedContainer>
                </ContentContainer>
            </FilterSearchContainer>
            {filterOptions && (
                <ChosenFilters
                    inputs={{
                        space_type: selectedFilters.space_type,
                    }}
                    onRemove={clearInput}
                    optionMetadata={optionMetadata}
                />
            )}
            <GenericTableDnD
                table={table}
                items={items}
                rowClickHandler={({ original }) => navigate(`${original.id}/products`)}
                handleDragEnd={handleDragEnd}
            />
            {data?.length === 0 &&
                data &&
                (!searchField ? (
                    <EmptyState>
                        <EmptyState.Title>{L('no_property_spaces')}</EmptyState.Title>
                        <EmptyState.Description>
                            <p>{L('no_property_spaces_description')}</p>
                            <p>{L('click_button_below')}</p>
                            <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                                {L('new_space')}
                            </Button>
                        </EmptyState.Description>
                    </EmptyState>
                ) : (
                    <EmptyState>
                        <EmptyState.Title>{L('no_property_spaces_found')}</EmptyState.Title>
                        <EmptyState.Description>
                            <p>{L('no_property_spaces_found_description')}</p>
                            <p>{L('please_try_something_else')}</p>
                        </EmptyState.Description>
                    </EmptyState>
                ))}
        </>
    );
};

export default SpacesList;
