import { useState, useEffect, useRef } from 'react';
import { history } from './routing';
import qs from 'qs';

export type ChangeFunction<T> = (value: any, name: string) => void;

type UseQuerystringResult<T> = [T, (arg: T) => void];

export function useQuerystring<T>(): UseQuerystringResult<T> {
    const [query, setQuery] = useState(() => qs.parse(history.location.search.substring(1)));
    useEffect(() => history.listen(q => setQuery(qs.parse(q.search.substring(1)))));
    function updateValues(values) {
        const search = qs.stringify(values);
        history.replace({ ...history.location, search });
    }
    return [query, updateValues] as any;
}

type PromiseState<T> =
    | {
          error?: Error;
          done: false;
          result: T | undefined;
      }
    | {
          error?: Error;
          done: true;
          result: T;
      };

export function usePromise<T>(fn: () => Promise<T>, dependencies: any[]): [boolean, T] {
    const active = useRef({ promise: null as any });
    const [state, setState] = useState<PromiseState<T>>({
        done: false,
        result: undefined
    });

    useEffect(() => {
        const promise = Promise.resolve<any>(undefined)
            .then(fn)
            .then(
                result => {
                    if (active.current.promise !== promise) return;
                    setState({ done: true, result });
                },
                error => {
                    if (active.current.promise !== promise) return;
                    setState({ done: false, error, result: undefined });
                    throw error;
                }
            );

        active.current.promise = promise;
        setState({ done: false, result: undefined });
        return () => (active.current.promise = undefined);
    }, dependencies);

    if (state.error) {
        throw state.error;
    }
    return [state.done, state.result as any];
}
