import { useGetProject } from './hooks/projectHooks/projectQueries';

const useGetProjectName = (id?: string) => {
    const Name = (id: string) => {
        const { data } = useGetProject(+id);
        return data?.name;
    };
    return id ? Name(id) : null;
};

export default useGetProjectName;
