import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const ContentNewsQueryKeys = {
    all: ['templates-content-news'] as const,
    lists: () => [...ContentNewsQueryKeys.all, 'list'] as const,
    list: (params: { templateId: number; search: string }) => [...ContentNewsQueryKeys.lists(), params] as const,
    details: () => [...ContentNewsQueryKeys.all, 'detail'] as const,
    detail: (params: { templateId: number; contentNewsId: number }) =>
        [...ContentNewsQueryKeys.details(), params] as const,
    docs: (id: number, projectId: number) => [...ContentNewsQueryKeys.all, 'docs', id, projectId] as const,
    docGroups: (id: number, projectId: number) => [...ContentNewsQueryKeys.all, 'docGroups', id, projectId] as const,
};

export function useListContentNewsItems({ templateId, search }: { templateId: number; search: string }) {
    return useInfiniteQuery(
        [...ContentNewsQueryKeys.list({ templateId, search })],
        async ({ pageParam = 1 }) =>
            trpc.ContentNewsApi.listContentNewsItems.query({
                projectId: templateId,
                search,
                page: pageParam,
            }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useGetContentNewsItem({ contentNewsId, templateId }: { contentNewsId: number; templateId: number }) {
    return useQuery(
        [...ContentNewsQueryKeys.detail({ contentNewsId: contentNewsId, templateId })],
        async () =>
            trpc.ContentNewsApi.getContentNewsItem.query({
                contentNewsId: contentNewsId,
                projectId: templateId,
            }),
        {
            keepPreviousData: true,
        },
    );
}
