import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import { style } from '@app/shared/lib/Style';
import { AddonReportStore } from '../../AddonReportStore';

const Container = styled.div({
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
});
const Text = styled.div({
    fontSize: style.fontSize.centi,
    color: '#808080'
});

interface Props {
    store: { isIncludingImage: boolean };
}

export const FilterIncludeImage = (props: Props) => {
    const handleOnChange = e => {
        props.store.isIncludingImage = e.target.checked;
        AddonReportStore.isIncludingImage
            ? (AddonReportStore.fields.add('image'), AddonReportStore.fields.delete('function'))
            : (AddonReportStore.fields.add('function'), AddonReportStore.fields.delete('image'));
    };

    return (
        <Container>
            <Checkbox onChange={e => handleOnChange(e)} defaultChecked={props.store.isIncludingImage} />
            <Text>{L('include_image')}</Text>
        </Container>
    );
};
