import * as React from 'react';
import { SettingStore } from '../../settings/SettingsStore';
import { useObserver } from 'mobx-react';
import { L } from '../../../lib/i18n';
import { Loading } from '../../../components/Loading/Loading';
import { usePromise } from '../../../lib/react';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import { Card } from '../../../components/Layout/Card';
import { Table } from '../../../components/Table/Table';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SettingsApi from '@app/api/public/SettingsApi';
import toast from 'react-hot-toast';

const TableContainer = styled.div({
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
});

const Header = styled.span({
    fontSize: style.fontSize.micro,
    margin: '0px 2px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
});

const settingsFeatureQueryKeys = {
    all: [{ scope: 'features' }],
    list: () => [{ ...settingsFeatureQueryKeys.all[0], entity: 'list' }],
};

export const SettingsProjectFeatures = () => {
    const queryClient = useQueryClient();
    const { data: projectsWithFeatures, status } = useQuery({
        queryKey: settingsFeatureQueryKeys.list(),
        queryFn: () => SettingsApi.listFeaturesAreaCardsAccounts(),
    });

    const { mutate: saveFeature } = useMutation(
        ({ projectId, feature }: { projectId: number; feature: string }) => {
            return SettingStore.saveFeatures(projectId, feature);
        },
        {
            onMutate: ({ projectId, feature }) => {
                return toast.loading(L('saving'));
            },
            onError: (error, variables, context) => {
                toast.error(L('error'), {
                    id: context,
                });
            },
            onSuccess: (data, variables, context) => {
                toast.success(L('saved'), {
                    id: context,
                });
                queryClient.invalidateQueries(settingsFeatureQueryKeys.list());
            },
        },
    );

    const { mutate: removeFeature } = useMutation(
        ({ projectId, feature }: { projectId: number; feature: string }) => {
            return SettingStore.removeFeatures(projectId, feature);
        },
        {
            onMutate: ({ projectId, feature }) => {
                return toast.loading(L('saving'));
            },
            onError: (error, variables, context) => {
                toast.error(L('error'), {
                    id: context,
                });
            },
            onSuccess: (data, variables, context) => {
                toast.success(L('saved'), {
                    id: context,
                });
                queryClient.invalidateQueries(settingsFeatureQueryKeys.list());
            },
        },
    );

    const onCheck = async (projectId: number, enabled: boolean, feature: string) => {
        if (enabled) {
            saveFeature({ projectId, feature });
        } else {
            removeFeature({ projectId, feature });
        }
    };

    if (status === 'loading') {
        return <Loading />;
    }

    const ts = projectsWithFeatures
        // .sort((a, b) => {
        //     if (a.name > b.name) return 1;
        //     if (a.name < b.name) return -1;
        //     else return 0;
        // })
        ?.map((project) => {
            const tables = {
                projects: <TableContainer style={{ justifyContent: 'flex-start' }}>{project.name}</TableContainer>,
            };
            project.features.forEach(
                (item) =>
                    (tables[item.feature] = (
                        <TableContainer>
                            <Checkbox
                                onChange={(e) => onCheck(project.id, e.target.checked, item.feature)}
                                checked={item.enabled}
                                defaultChecked={item.enabled}
                                name={item.feature}
                            />
                        </TableContainer>
                    )),
            );

            return tables;
        });

    return (
        <Card background={style.colors.white}>
            <Table
                columns={[
                    {
                        key: 'projects',
                        header: <Header style={{ justifyContent: 'flex-start' }}>{L('projects')}</Header>,
                        width: '1.5fr',
                    },
                    {
                        key: 'ticket',
                        header: <Header>{L('tickets')}</Header>,
                        width: '1.1fr',
                    },
                    {
                        key: 'addon',
                        header: <Header>{L('addon')}</Header>,
                        width: '1fr',
                    },
                    {
                        key: 'binder_readonly',
                        header: <Header>{L('binder_readonly')}</Header>,
                        width: '1fr',
                    },
                ]}
                data={ts}
            />
        </Card>
    );
};
