import { observable } from 'mobx';
import ProjectsApi from '@app/api/public/ProjectsApi';
import { Address } from '@app/api/models/Profile';
import { DocumentPage, ProjectDetail, ProjectInfo } from '../../../../api/models/Projects';

class AddonProjectStoreClass {
    @observable projects: ProjectDetail[] = [];
    @observable project: Partial<ProjectDetail>;

    @observable selectedProjectId: string;

    @observable settings: ProjectInfo = {} as ProjectInfo;
    @observable addon_info: DocumentPage = {} as DocumentPage;
    @observable address: Address = {} as Address;

    async load() {
        this.projects = (await ProjectsApi.list('addon')) || {};
    }

    async setAutoConfirmation(property_id: string, enableCheckout: boolean) {
        return await ProjectsApi.setAutoConfirmation(property_id, enableCheckout);
    }
    async setSingleSigner(property_id: string, enableSingleSigner: boolean) {
        return await ProjectsApi.setSingleSigner(property_id, enableSingleSigner);
    }

    async getById() {
        this.project = (await ProjectsApi.getById(+this.selectedProjectId)) || {};
        this.settings = this.project?.project_info as ProjectInfo;
    }

    async getAddonInfo() {
        const db =
            (await ProjectsApi.getAddonInfo(+this.selectedProjectId)) ||
            ({} as { id: string; addon_info: DocumentPage });
        this.addon_info = db.addon_info;
        this.settings = this.project?.project_info as ProjectInfo;
    }

    async saveAddonInfo() {
        console.log(JSON.stringify(this.addon_info));
        await ProjectsApi.saveAddonInfo(+this.selectedProjectId, this.addon_info);
    }

    async setAddonInfo(value: string, name: string) {
        this.addon_info[name] = value;
    }

    async setValue(value: string, name: string) {
        if (name === 'notification_email' || name === 'project_logo') {
            this.project[name] = value;
        } else {
            this.settings[name] = value;
            localStorage.setItem('settings', JSON.stringify(this.settings));
        }
    }

    async setValueAddress(value: string, name: string) {
        this.address[name] = value;
        this.settings.address = this.address;
        localStorage.setItem('settings', JSON.stringify(this.settings));
    }

    async saveProjectSettings() {
        await ProjectsApi.saveProjectSettings(
            this.selectedProjectId,
            this.settings,
            // this.auto_confirmation,
            this.project.notification_email,
            this.project.project_logo
        );
    }
}

export const AddonProjectStore = new AddonProjectStoreClass();
