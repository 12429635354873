import AddonsApi from '@app/api/public/AddonsApi';
import { useQuery } from 'react-query';

export const orderConfirmationKey = {
    all: ['confirmationPdf'],
    byId: (propertyId: number) => [...orderConfirmationKey.all, { propertyId }]
};

const getOrderConfirmationSignedUrl = async (propertyId: number) => {
    const response = await AddonsApi.getOrderConfirmationPdfSignedUrl(propertyId);
    return response;
};
const useGetOrderConfirmationSignedUrl = ({ propertyId }: { propertyId: number }) => {
    return useQuery(orderConfirmationKey.byId(propertyId), () => getOrderConfirmationSignedUrl(propertyId), {
        enabled: !!propertyId
    });
};

export default useGetOrderConfirmationSignedUrl;
