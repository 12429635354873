import React from 'react';
import styled from '@emotion/styled';

const Bannerimg = styled.img({
    width: '100%',
    maxHeight: '350px',
    objectFit: 'cover'
});

export const PropertyBanner = ({ image }) => {
    return <Bannerimg src={image} />;
};
