import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory, useLocation, useParams } from 'react-router-dom';
import RadialProgressBar from '../../../components/ProgressBar/RadialProgressBar';
import useGetProductsBySpaceId from '../hooks/useGetProductsBySpaceId';
import { Space } from '@app/api/models/Properties';

const Card = styled(NavLink)({
    padding: '12px',
    borderBottom: '1px solid #979797',
    cursor: 'pointer',
    textDecoration: 'none',
    '&:nth-of-type(1)': {
        borderTop: '1px solid #979797'
    },
    ['@media screen and (min-width: 1200px)']: {
        '&.active': { background: '#F0F1F7'}
     },
});

const InfoWrapper = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const PropertyName = styled.span({
    color: '#444444',
    fontSize: '16px',
    fontWeight: 300,
    width: '85%',
    wordBreak: 'break-all'
});

const RadialContainer = styled.div({
    width: '40px'
});
const SpaceComponent = ({ space, ...rest}: { space: Space }) => {
    const products = useGetProductsBySpaceId(space.id);
    const filteredProducts = products.filter(product => product.inventory_completed);
    const history = useHistory();

    const params = useParams<{ projectId: string; propertyId: string }>();

    return (
        <>
            <Card key={space.id} {...rest} to={`/inventory/project/${params.projectId}/property/${params.propertyId}/space/${space.id}`}>
                <InfoWrapper>
                    <PropertyName>{space.name}</PropertyName>
                    <RadialContainer>
                        <RadialProgressBar
                            current={filteredProducts?.length || 0}
                            total={products?.length || 0}
                            startPercentage={0}
                        />
                    </RadialContainer>
                </InfoWrapper>
            </Card>
        </>
    );
};

export default SpaceComponent;
