import { observable } from 'mobx';
import UsersApi, { IUser } from '@app/api/public/UsersApi';
import ApplicationSettingsApi from '@app/api/public/ApplicationSettingsApi';
import DocumentsApi, { DocumentDetail } from '@app/api/public/DocumentsApi';
import { User } from '@app/api/models/Users';
import { redirect } from '../../lib/routing';
import { basketKey } from '../binders/hooks/useBasket';

class LoginStoreClass {
    @observable state: '' | 'error' | 'projects' | 'loading' | 'failed' | 'logged-in' = '';
    @observable impersonate: '' | 'error' | 'loading' | 'failed' | 'logged-in' = '';
    @observable user: IUser;
    @observable projects: { id: string; name: string }[];
    @observable selectedProjectId: string;
    @observable impersonatedUser: IUser | null;
    @observable users: User[];
    @observable signedUrl: string;

    async verifyLogin() {
        this.state = 'loading';
        const user = await UsersApi.verifyLogin();
        if (user !== null) {
            this.user = user;
            this.state = 'logged-in';
        } else this.state = '';
    }

    async getList(propertyId: string) {
        this.users = await UsersApi.getPropertyUsers(propertyId);
    }

    async getProfileImage(url: DocumentDetail) {
        const r = await DocumentsApi.get(url?.document_data?.original?.id);
        if (r) this.signedUrl = r?.signed_url;
    }

    multiLogin = (project: string) => {
        this.selectedProjectId = project;
        UsersApi.setCookie(project);
        this.state = 'logged-in';
    };

    async loginImpersonatePropertyUser(propertyId: string, userId: string) {
        try {
            this.impersonatedUser = await UsersApi.loginImpersonatePropertyUser(userId, propertyId);
            if (this.impersonatedUser) {
                this.impersonate = 'logged-in';
                const residenceUrl = await ApplicationSettingsApi.getResidenceUrl();
                window.open(`${residenceUrl.url}/properties/${propertyId}/addons`, '_blank');
            }
        } catch (ex) {
            console.error(ex);
        }
    }

    logoutImpersonate = async () => {
        await UsersApi.logoutImpersonatePropertyUser();
        this.verifyLogin();
        redirect(`/addons`);
        location.reload();
    };

    async login(username: string, password: string) {
        this.state = 'loading';
        this.user = undefined as any;
        try {
            const user = await UsersApi.login(username, password);
            console.log('user', user);
            if (user !== null) {
                this.user = user;
                this.state = 'logged-in';
                await UsersApi.updateSignInAt(user.id);

                // if (user.hasMultipleProjects) {
                //     this.projects = await UsersApi.getProperties();
                //     this.state = 'projects';
                // }
            } else if (username || password) {
                this.state = 'failed';
            } else {
                this.state = '';
            }
        } catch (ex) {
            console.error(ex);
            this.state = 'error';
        }
    }

    logout = async () => {
        await UsersApi.logout();
        window.localStorage.setItem(basketKey, JSON.stringify([]));
        window.localStorage.setItem(
            'copyToPageSelections',
            JSON.stringify({
                selectedProperties: {},
                selectedSpaces: {},
                project: null,
            }),
        );
        window.localStorage.setItem(
            'copyDocumentsPageSelection',
            JSON.stringify({
                project: null,
                selectedProperties: {},
            }),
        );
        window.localStorage.setItem('inventoryLibraryFilters', '');
        window.localStorage.setItem('libraryPageFilters', '');

        location.reload();
    };
}

export const LoginStore = new LoginStoreClass();
