import * as React from 'react';
import { Outlet, Route, Routes, matchPath, useLocation, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import TemplatesList from './pages/ProjectTemplatePages/TemplatesPage';
import ProjectTemplateDetailPage from './pages/ProjectTemplatePages/ProjectTemplateDetailPage';
import AddProjectTemplatePage from './pages/ProjectTemplatePages/AddProjectTemplatePage';
import AddContentInformationPage from './pages/ContentInformationPages/AddContentInformationPage';
import ContentInformationDetailPage from './pages/ContentInformationPages/ContentInformationDetailPage';
import ContentInformationPage from './pages/ProjectTemplatePages/ContentInformationPage';
import ContentNewsPage from './pages/ProjectTemplatePages/ContentNewsPage';
import AddContentNewsPage from './pages/ContentInformationPages/AddContentNewsPage';
import ContentNewsDetailPage from './pages/ContentInformationPages/ContentNewsDetailPage';
import { useGetProjectTemplateById } from './hooks/ProjectTemplates/projectTemplateQueries';
import { Header } from '../../components/Layout/Header';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import { useGetContentInformationItem } from './hooks/ContentInformation/contentInformationQueries';
import { useGetContentNewsItem } from './hooks/ContentNews/contentNewsQueries';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const ProjectTemplateName = () => {
    const { id } = useParams<{ id: string }>();
    const { data: template } = useGetProjectTemplateById(id ? +id : undefined);

    return <>{template?.name || L('template')}</>;
};

const Title = () => {
    const { id } = useParams<{
        id: string;
    }>();
    if (id)
        return (
            <>
                <Outlet />
                <Header title={<ProjectTemplateName />} />
            </>
        );
    return (
        <>
            <Outlet />
            <Header title={L('project_templates')} />
        </>
    );
};

const TabMenu = () => {
    const location = useLocation();
    const match = matchPath({ path: '/project-templates/:id/*' }, location.pathname);

    if (match?.params['*']?.includes('/'))
        return (
            <>
                <Outlet />
            </>
        );
    return (
        <Menu>
            <BigTabNav>
                <BigNavItem end to={'.'}>
                    {L('edit_project_template')}
                </BigNavItem>
                <BigNavItem to={`content-information`}>{L('content_information_plural')}</BigNavItem>
                <BigNavItem to={`content-news`}>{L('news')}</BigNavItem>
            </BigTabNav>
        </Menu>
    );
};

const ContentInfoName = () => {
    const { id, contentInfoId } = useParams<{ id: string; contentInfoId: string }>();
    const Name = ({ id, contentInfoId }: { id: string; contentInfoId: string }) => {
        const { data: contentInformation, isLoading } = useGetContentInformationItem({
            templateId: +id,
            contentInfoId: +contentInfoId,
        });
        if (isLoading) return <></>;
        return contentInformation?.title ? <>{contentInformation.title}</> : <>{L('content_information')}</>;
    };

    return id && contentInfoId ? <Name id={id} contentInfoId={contentInfoId} /> : <>{L('content_information')}</>;
};

const ContentNewsName = () => {
    const { id, contentNewsId } = useParams<{ id: string; contentNewsId: string }>();
    const Name = ({ id, contentNewsId }: { id: string; contentNewsId: string }) => {
        const { data: contentNews, isLoading } = useGetContentNewsItem({
            templateId: +id,
            contentNewsId: +contentNewsId,
        });
        if (isLoading) return <></>;
        return contentNews?.title ? <>{contentNews.title}</> : <>{L('content_news')}</>;
    };

    return id && contentNewsId ? <Name id={id} contentNewsId={contentNewsId} /> : <>{L('content_news')}</>;
};

const ProjectsBreadcrumbs = ({ children }: { children?: React.ReactNode }) => {
    return (
        <Breadcrumbs style={{ marginBottom: '1rem' }}>
            <Breadcrumbs.Item key={1} to="../../..">
                {L('project_templates')}
            </Breadcrumbs.Item>
            <Breadcrumbs.Item key={2} to="..">
                <ProjectTemplateName />
            </Breadcrumbs.Item>
            {children}
        </Breadcrumbs>
    );
};

const BreadcrumbsNavigation = () => {
    return (
        <Routes>
            <Route path="project-templates" element={<Title />}>
                <Route index element={null} />
                <Route path="new">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('project_templates')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>{L('new_project_template')}</Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                </Route>
                <Route path=":id">
                    <Route
                        index
                        element={
                            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                <Breadcrumbs.Item to="..">{L('project_templates')}</Breadcrumbs.Item>
                                <Breadcrumbs.ItemActive>
                                    <ProjectTemplateName />
                                </Breadcrumbs.ItemActive>
                            </Breadcrumbs>
                        }
                    />
                    <Route path="content-information">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="../..">{L('project_templates')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>
                                        <ProjectTemplateName />
                                    </Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                        <Route
                            path=":contentInfoId"
                            element={
                                <ProjectsBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{<ContentInfoName />}</Breadcrumbs.ItemActive>
                                </ProjectsBreadcrumbs>
                            }
                        />
                        <Route
                            path="new"
                            element={
                                <ProjectsBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{L('new_content_information')}</Breadcrumbs.ItemActive>
                                </ProjectsBreadcrumbs>
                            }
                        />
                    </Route>
                    <Route path="content-news">
                        <Route
                            index
                            element={
                                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                                    <Breadcrumbs.Item to="../..">{L('project_templates')}</Breadcrumbs.Item>
                                    <Breadcrumbs.ItemActive>
                                        <ProjectTemplateName />
                                    </Breadcrumbs.ItemActive>
                                </Breadcrumbs>
                            }
                        />
                        <Route
                            path=":contentNewsId"
                            element={
                                <ProjectsBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{<ContentNewsName />}</Breadcrumbs.ItemActive>
                                </ProjectsBreadcrumbs>
                            }
                        />
                        <Route
                            path="new"
                            element={
                                <ProjectsBreadcrumbs>
                                    <Breadcrumbs.ItemActive>{L('new_content_news')}</Breadcrumbs.ItemActive>
                                </ProjectsBreadcrumbs>
                            }
                        />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

const Navigation = () => {
    return (
        <Routes>
            <Route path="project-templates">
                <Route index element={null} />
                <Route path="new" element={null} />
                <Route path=":id/*" element={<TabMenu />}>
                    <Route path="content-information">
                        <Route index element={null} />
                        <Route path="new" element={null} />
                        <Route path=":contentInfoId" element={null} />
                    </Route>
                    <Route path="content-news">
                        <Route index element={null} />
                        <Route path="new" element={null} />
                        <Route path=":contentNewsId" element={null} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
};

export const TemplatesPage = () => {
    return (
        <>
            <BreadcrumbsNavigation />
            <Navigation />
            <Routes>
                <Route path="project-templates">
                    <Route index element={<TemplatesList />} />
                    <Route path="new" element={<AddProjectTemplatePage />} />
                    <Route path=":id">
                        <Route index element={<ProjectTemplateDetailPage />} />
                        <Route path="content-information">
                            <Route index element={<ContentInformationPage />} />
                            <Route path="new" element={<AddContentInformationPage />} />
                            <Route path=":contentInfoId" element={<ContentInformationDetailPage />} />
                        </Route>

                        <Route path="content-news">
                            <Route index element={<ContentNewsPage />} />
                            <Route path="new" element={<AddContentNewsPage />} />
                            <Route path=":contentNewsId">
                                <Route index element={<ContentNewsDetailPage />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
