import 'regenerator-runtime/runtime';
import React, { useState } from 'react';
import Input from '../Forms/Input';
import { useAsyncDebounce } from 'react-table';
import styled from '@emotion/styled';
// import SearchIcon from '../../../components/Icon/search.svg';
import { style } from '../Shared/Style';

// const StyledSearchIcon = styled(SearchIcon)({
//     alignSelf: 'center',
//     gridArea: 'icon',
//     height: '16px',
//     width: '16px'
// });

const StyledInput = styled(Input)({
    border: 'none',
    paddingLeft: '0px',
    fontSize: '14px',

    '&::placeholder': {
        fontSize: '14px',
        color: '#4B506D66'
    }
});

const SearchBar = styled.div(() => {
    return {
        background: style.colors.inputBackground,
        border: `1px solid ${style.colors.inputBorder}`,
        borderRadius: style.roundCornerSize.small,
        outline: 'none',
        padding: `0px 4px 0px 24px`,
        fontSize: style.fontSize.seta,

        width: '240px',
        display: 'flex',

        gap: '1rem',
        '&:focus': {
            outline: 'solid'
        }
    };
});

type Props = {
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string | undefined>>;
};
const GlobalFilter = ({ filter, setFilter, ...rest }: Props & React.InputHTMLAttributes<HTMLInputElement>) => {
    const [value, setValue] = useState<string>(filter || '');

    const onChange = useAsyncDebounce((value: string) => {
        setFilter(value);
    }, 300);
    return (
        <>
            <SearchBar>
                {/* <StyledSearchIcon /> */}
                <StyledInput
                    {...rest}
                    value={value}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                />
            </SearchBar>
        </>
    );
};

export default GlobalFilter;
