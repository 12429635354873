import React from 'react';
import styled from '@emotion/styled';
import ProfessionList from './components/ProfessionList';
import { SubmitHandler, useForm } from 'react-hook-form';
import { style } from '../../../components/Shared/Style';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import { L } from '../../../lib/i18n';
import Input from '../../../components/Forms/Input';
import useAddProfession from './hooks/useAddProfession';
import toast from 'react-hot-toast';
import { TRPCClientError } from '@trpc/client';
import { Button } from '@ourliving/ourliving-ui';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import H2 from '../../../components/typography/H2';
import Dialog from '../../../components/Dialog/Dialog';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '1rem',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
    minWidth: '300px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        minWidth: '400px',
    },
}));

const ProfessionSettingsPage = () => {
    const [open, setOpen] = React.useState(false);

    const { mutate } = useAddProfession();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<{ name: string }>();

    const onSubmit: SubmitHandler<{ name: string }> = (data) => {
        mutate(data, {
            onSuccess: () => {
                toast.success(`${L('profession')} ${data.name} ${L('saved').toLocaleLowerCase()}`);
                reset();
                setOpen(!open);
            },
            onError: (err) => {
                if (err instanceof TRPCClientError) {
                    if (err.message.split(' ')[0] === '23505') {
                        toast.error(L('unique_name_error'));
                        return;
                    }
                    toast.error(L('pg_unkown'));
                }
            },
        });
    };

    return (
        <>
            <Dialog open={!!open} onOpenChange={setOpen}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <H2>{`${L('new_profession')}`}</H2>
                    <ErrorAndInputWrapper errorMsg={errors.name?.message ? L(errors.name?.message) : ''}>
                        <Label title={L('name')}>
                            <RestyledInput
                                isError={!!errors.name}
                                placeholder={L('name')}
                                {...register('name', {
                                    required: true,
                                    min: { message: 'error_required', value: 1 },
                                })}
                            />
                        </Label>
                    </ErrorAndInputWrapper>
                    <div style={{ marginTop: style.margin.m, display: 'flex', justifyContent: 'end' }}>
                        <Button>{L('save')}</Button>
                    </div>
                </Form>
            </Dialog>
            <ProfessionList handleSetOpen={() => setOpen(!open)} />
        </>
    );
};

export default ProfessionSettingsPage;
