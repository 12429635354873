import React from 'react';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import NewModal from '../../../components/Modal/NewModal';
import NewContactForm from '../NewContact/NewContactForm';
import TableListPage from './TableListPage';

//#region CSS

//#endregion

export const ContactListPage = () => {
    const { ToggleIsModalOpen } = useModal();

    return (
        <>
            <TableListPage ToggleIsModalOpen={ToggleIsModalOpen} />
            <NewModal>
                <NewContactForm />
            </NewModal>
        </>
    );
};
