import React from 'react';
import { convertArrayToCSV } from 'convert-array-to-csv';

import { saveAs } from 'file-saver';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';

import { ProductFilter } from '@app/trpc-api/models/Binders';
import { trpc } from '../../../lib/trpc';

type Props = {
    fileName: string;
    projectId?: number;
    filters: ProductFilter;
    search?: string;
} & React.ComponentPropsWithoutRef<'button'>;

//Take the filtered data and export it to excel file (csv)
export const ExportToExcel = ({ fileName, projectId, filters, search, ...rest }: Props) => {
    const exportData = async () => {
        if (!projectId) return;
        const data = await trpc.BindersApi.getProductsList.query({ filter: filters, projectId, search });
        return data.map((row) => {
            return {
                Bostad: row.property_name,
                Utrymme: row.space_name,
                Produktgrupp: L(row.name),
                Produkt: row.product_name,
                Färg: row.color,
                Pris: row?.addon_price ? row?.addon_price + ' kr' : 'Standard',
                Tillval: row.package_type === 'optional' ? 'Nej' : 'Ja',
            };
        });
    };
    const exportToCSV = async (fileName: string) => {
        const data = await exportData();
        const csv = convertArrayToCSV(data, { separator: ';' });
        const file = new File(['\uFEFF' + csv], `${fileName}.csv`, {
            type: 'text/csv; charset=utf-8',
        });
        const blob = new Blob([file]);

        saveAs(blob, `${fileName}.csv`);
    };

    return (
        <Button variant="secondary" disabled={!projectId} onClick={() => exportToCSV(fileName)} {...rest}>
            {L('export_to_excel')}
        </Button>
    );
};
