import { useMutation } from 'react-query';
import { trpc } from '../../../lib/trpc';

const useUpdatePropertyDocName = () => {
    return useMutation(async (params: { docId: number; name: string; propertyId: number }) => {
        return trpc.BindersApi.updatePropertyDocumentName.mutate(params);
    });
};

export default useUpdatePropertyDocName;
