Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["list"] = b.createRpcProxy("ContentNewsApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = b.createRpcProxy("ContentNewsApi", "get", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = b.createRpcProxy("ContentNewsApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("ContentNewsApi", "remove", {
  "urlPrefix": "/rpc"
});