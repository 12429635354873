import React from 'react';
import { Route } from 'react-router-dom';
import { TicketSettings } from './Routes';

export const routes = [<Route key={0} path="/ticket-settings" component={TicketSettings} />];

export const navigation = [
    {
        name: 'settings_tickets',
        link: '/ticket-settings',
        match: '/ticket-settings',
        icon: 'tickets',
    },
];
