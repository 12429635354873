import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import useGetAnaltyicList from '../hooks/useGetAnalyticList';
import { AnalyticCostByYear } from '@app/api/models/Maintenances';
import { cumulativeAvrage } from '../../../lib/simpleMovingAverage';
import dayjs from 'dayjs';

ChartJS.register(...registerables);

const Container = styled.div({
    position: 'relative',
    width: '100%'
});

const StackedGraph = () => {
    const { data: analtyicList } = useGetAnaltyicList();

    if (!analtyicList) return null;
    const cma = cumulativeAvrage(analtyicList);
    const uniqueYears = [...new Set(analtyicList.map(item => item.year)).values()];
    const maxYear = Math.max(...uniqueYears);

    const years = (toYear: number) => {
        let startYear = +dayjs().format('YYYY') - 1;

        const years: string[] = [];

        while (startYear++ < toYear) {
            years.push(startYear.toString());
        }

        return years;
    };

    const data = {};
    analtyicList.forEach(item => {
        data[item.maintenance_category.name] = [];
    });
    analtyicList.forEach(item => {
        data[item.maintenance_category.name].push({ ...item });
    });

    const planedCost = Object.values(data).map((item: AnalyticCostByYear[]) => {
        return {
            label: `Planerad ${item[0].maintenance_category.name}`,
            backgroundColor: item[0].maintenance_category.color,
            stack: 'planedCost',
            data: Object.entries(data)
                .filter(value => value.includes(item[0].maintenance_category.name))
                .map(([key, value]: [string, AnalyticCostByYear[]]) => {
                    return value.map(item => {
                        return {
                            x: item.year,
                            y: item.planned_amount
                        };
                    });
                })
                .flatMap(value => value)
        };
    });
    const completedCost = Object.values(data).map((item: AnalyticCostByYear[]) => {
        return {
            label: `Utfall ${item[0].maintenance_category.name}`,
            backgroundColor: item[0].maintenance_category.color,
            stack: 'completedCost',
            data: Object.entries(data)
                .filter(value => value.includes(item[0].maintenance_category.name))
                .map(([key, value]: [string, AnalyticCostByYear[]]) => {
                    return value.map(item => {
                        return {
                            x: item.year,
                            y: item.completed_amount
                        };
                    });
                })
                .flatMap(value => value)
        };
    });

    const avrageCostLine = {
        type: 'line',
        label: 'genomsnitt',
        data: [...cma],
        tension: 0.1
    };
    const graphData = {
        labels: years(maxYear),
        datasets: [...planedCost, ...completedCost, avrageCostLine].sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom' as const
            },
            title: {
                display: true,
                text: 'Årliga kostnader'
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '( År )',
                    align: 'start'
                },
                stacked: true
            },
            y: {
                title: {
                    display: true,
                    text: '( Kr )',
                    align: 'start'
                },

                stacked: true
            }
        }
    };
    return <Container>{<Bar height={100} options={options} data={graphData} />}</Container>;
};

export default StackedGraph;
