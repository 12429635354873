Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["list"] = b.createRpcProxy("AlertsApi", "list", {
  "urlPrefix": "/rpc"
});
o["id"] = b.createRpcProxy("AlertsApi", "id", {
  "urlPrefix": "/rpc"
});
o["save"] = b.createRpcProxy("AlertsApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("AlertsApi", "remove", {
  "urlPrefix": "/rpc"
});