import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateSpaceDocSort = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.BindersApi.updateSpaceDocSort.mutate({ docs: params.docs, entityId: params.documentable_id });
    });
};

export default useUpdateSpaceDocSort;
