import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import { Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../components/Loading/Loading';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { useGetTicketMessages, useGetTicket, useGetAssignees } from '../hooks/ticketQueries';
import useSaveMessage from '../hooks/useSaveMessage';
import AttachmentIcon from '../../../components/Icon/attachment.svg';
import DocumentClick from '../../../components/document/DocumentClick/DocumentClick';
import useSetLastReadThread from '../hooks/useSetLastReadThread';
import CustomerMessages from './CustomerMessages';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { TextArea } from '@ourliving/ourliving-ui';
import useGetProfile from '../../../lib/hooks/useGetProfile';
import { colors } from '../../../components/Shared/Style/colors';

dayjs.extend(isBetween);

const InvisibleTextArea = styled(TextArea)({
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0px',
});
const FakeInput = styled.div(() => ({
    background: colors.inputBackground,
    border: `1px solid ${colors.inputBorder}`,
    width: '100%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    resize: 'vertical',
    '&:read-only': {
        color: colors.inputPlaceholderColor,
        cursor: 'default',
    },
}));

const StyledAttachmentIcon = styled(AttachmentIcon)({
    height: '24px',
    width: '24px',
});

const Wrapper = styled.div({
    ['@media screen and (max-width: 992px)']: {
        width: '100%',
    },
});

const Form = styled.form({
    display: 'grid',
    gap: '0.5rem',
    marginTop: '0.5rem',
});

const CustomerCommunication = ({ readOnly }: { readOnly?: boolean }) => {
    const { id } = useParams<{ id: string }>();

    const { data: profile } = useGetProfile();

    const { data: ticket } = useGetTicket(id);
    const [message, setMessage] = React.useState('');
    const { data } = useGetTicketMessages('residence', id);
    const { mutate, status } = useSaveMessage();
    const [files, setFiles] = React.useState<File[]>([]);
    const { mutate: setLastReadThread } = useSetLastReadThread();
    const { data: assignees } = useGetAssignees();
    const assigneeIds = assignees?.map((assignee) => assignee.id) ?? [];

    const lastReadRef = useRef('');

    useEffect(() => {
        if (lastReadRef.current) return;
        if (!ticket?.residence_last_read) return;
        lastReadRef.current = ticket?.residence_last_read;
    }, [ticket]);
    useEffect(() => {
        if (!id) return;
        setLastReadThread({ ticketId: +id, threadType: 'residence' });
        return () => {
            setLastReadThread({ ticketId: +id, threadType: 'residence' });
        };
    }, [id]);

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!id) return;
        mutate(
            {
                message: {
                    text: {
                        plain_text: message,
                    },
                },
                files: files,
                ticketId: +id,
                thread_type: 'residence',
            },
            {
                onSuccess: () => {
                    setMessage('');
                    setFiles([]);
                },
            },
        );
    };

    const Documents = files.map((file) => {
        const url = URL.createObjectURL(file);
        const handleDelete = () => {
            setFiles(files.filter((item) => item.name !== file.name));
        };
        return (
            <DocumentClick
                key={file.name}
                fileType={file.type}
                url={url}
                name={file.name}
                deleteDocument={handleDelete}
            />
        );
    });

    const handleAddAttachments = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            const eventFiles = Array.from(e.target.files);
            const newFiles = files.filter((file) => !eventFiles.some((item) => item.name === file.name));
            setFiles([...newFiles, ...eventFiles]);
        }
    };
    if (!ticket) return <Loading />;
    const Chat = profile?.id ? (
        <CustomerMessages
            assingeesIds={assigneeIds}
            lastRead={lastReadRef.current}
            messages={data || []}
            profileId={+profile.id}
        />
    ) : (
        <Loading />
    );
    return (
        <Wrapper>
            {Chat}
            {!readOnly && (
                <Form onSubmit={onSubmit}>
                    <FakeInput>
                        <InvisibleTextArea
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder={L('chat_placeholder')}
                        />
                        {Documents}
                    </FakeInput>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <label style={{ cursor: 'pointer' }}>
                            <input type={'file'} style={{ display: 'none' }} onChange={handleAddAttachments} />
                            <StyledAttachmentIcon />
                        </label>
                        <Button
                            disabled={!message && !files.length}
                            isLoading={status === 'loading'}
                            style={{ placeSelf: 'end' }}
                        >
                            {L('send')}
                        </Button>
                    </div>
                </Form>
            )}
        </Wrapper>
    );
};

export default CustomerCommunication;
