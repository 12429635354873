import React, { useState } from 'react';
import { Header } from '../../../components/Layout/Header';
import { Content } from '../../../components/Layout/Content';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';
import { usePromise } from '../../../lib/react';
import ContentNewsApi from '@app/api/public/ProjectContentNewsApi';
import { Pagination } from '../../../components/Layout/Pagination';
import { RawHtml } from '../../../components/Layout/RawHtml';
import { NavLink } from 'react-router-dom';
import { style } from '../../../components/Shared/Style';
import { Button } from '@ourliving/ourliving-ui';
import { Card } from '../../../components/Layout/Card';
import { Table } from '../../../components/Table/Table';
import { Icon } from '../../../components/Icon/Icon';
import { ContentNewsStore } from '../ContentNewsStore';
import { SearchField } from '../../../components/SearchField/SearchField';
import { useObserver } from 'mobx-react';
import { Loading } from '../../../components/Loading/Loading';

interface Props {
    location: { search: string };
}

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    lineHeight: '32px'
});

const Menu = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: style.margin.s
});

const SearchMenu = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: style.margin.s
});

const TextRight = styled.div({
    textAlign: 'right'
});

const Container = styled.div({
    display: 'flex'
});

export const NewsListPage = () => {
    const removeNews = (id: string) => {
        ContentNewsStore.remove(id);
    };

    const onChange = e => {
        ContentNewsStore.searchFilter = e.target.value;
    };

    const [page, setPage] = useState(0);
    const [ready, list] = usePromise(async () => {
        return await ContentNewsApi.list(page);
    }, [page]);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        const f = list.items.filter(title => title.title.toLowerCase().includes(ContentNewsStore.searchFilter));

        const items = f.map(it => ({
            title: (
                <StyledNavLink to={`/news/${it.id}`}>
                    <div>{it.title}</div>
                </StyledNavLink>
            ),
            description: (
                <StyledNavLink to={`/news/${it.id}`}>
                    <RawHtml content={it.description} />
                </StyledNavLink>
            ),
            published: (
                <StyledNavLink to={`/news/${it.id}`}>
                    <div>{it.id}</div>
                </StyledNavLink>
            ),
            boardmembers: (
                <>
                    <input type="checkbox" />
                </>
            ),
            trashcan: (
                <TextRight>
                    <Icon.TrashCan onClick={() => removeNews(it.id)} />
                </TextRight>
            )
        }));

        return (
            <>
                <Header title={L('news')} />
                <Content>
                    <Menu>
                        <StyledNavLink to={`/news/new`}>
                            <Button>{L('news_new')}</Button>
                        </StyledNavLink>
                    </Menu>
                    <Card background={style.colors.white}>
                        <SearchMenu>
                            <SearchField onChange={onChange} placeholder={L('search_news')} />
                            {L('amount')} {L('posts')}: {list.totalItems}
                        </SearchMenu>
                        <Container>
                            {ready && (
                                <div style={{ width: '100%' }}>
                                    <Table
                                        columns={[
                                            { key: 'title', header: L('title'), arrows: true, width: '0.5fr' },
                                            { key: 'description', header: L('info'), arrows: true, width: '0.5fr' },
                                            { key: 'published', header: L('published'), arrows: true, width: '0.5fr' },
                                            {
                                                key: 'boardmembers',
                                                header: L('boardmember_area'),
                                                arrows: true,
                                                width: '0.5fr'
                                            },
                                            {
                                                key: 'trashcan',
                                                width: '0.5fr'
                                            }
                                        ]}
                                        data={items}
                                    />
                                    <Pagination page={list} onChangePage={setPage} />
                                </div>
                            )}
                        </Container>
                    </Card>
                </Content>
            </>
        );
    });
};
