import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoNew from '../../../binders/pages/TodoPages/TodoNew';
import useGetPropertyName from '../../../binders/useGetPropertyName';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import H1 from '../../../../components/typography/H1';
import { L } from '../../../../lib/i18n';

const TodoTemplateNewPage = () => {
    const { id: propertyId } = useParams<{ todoId: string; id: string }>();
    const propertyName = useGetPropertyName(propertyId);
    return propertyId ? (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../..'}>{L(L('property_templates'))}</Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('new_todo')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{propertyName ?? L('property')}</H1>
            <TodoNew propertyId={+propertyId} />
        </>
    ) : null;
};

export default TodoTemplateNewPage;
