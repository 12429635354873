Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getFeatureProjects"] = b.createRpcProxy("SettingsApi", "getFeatureProjects", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProjects"] = b.createRpcProxy("SettingsApi", "getProjects", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveFeatureProjects"] = b.createRpcProxy("SettingsApi", "saveFeatureProjects", {
  "urlPrefix": "/rpc"
});
o["removeFeatureProjects"] = b.createRpcProxy("SettingsApi", "removeFeatureProjects", {
  "urlPrefix": "/rpc"
});
o["previewMail"] = b.createRpcProxy("SettingsApi", "previewMail", {
  "urlPrefix": "/rpc"
});
o["previewMailWithPlaceholders"] = b.createRpcProxy("SettingsApi", "previewMailWithPlaceholders", {
  "urlPrefix": "/rpc"
});
o["listFeaturesAreaCardsAccounts"] = b.createRpcProxy("SettingsApi", "listFeaturesAreaCardsAccounts", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listProjects"] = b.createRpcProxy("SettingsApi", "listProjects", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});