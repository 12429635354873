import React, { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Loading } from '../../../components/Loading/Loading';
import useGetContacts from '../hooks/useGetContacts';
import { COLUMNSCONTACTS } from '../components/ColumnsContact';
import TableRowSelectionContacts from '../components/TableRowSelectionContacts';
import Exchange from '../../../components/Icon/themed/Exchange';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import { Button } from '@ourliving/ourliving-ui';
import useGetProperties from '../hooks/useGetProperties';
import { COLUMNSPROPERTY } from '../components/ColumnsProperty';
import useCopyContactsToProperties from '../hooks/useCopyContactsToProperties';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import TableRowSelectionProperties from './TableRowSelectionProperties';
import FilterArea from './FilterArea';
import { ProjectFilter } from '@app/api/models/Projects';
import useTableCheckbox from './TableProvider';
import { L } from '../../../lib/i18n';

//#region CSS

const GridCopyContainer = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fill, minmax(250px, 1fr) )',
    gap: '1rem',
    [`@media screen and (min-width: ${desktop}px)`]: {
        gridTemplateColumns: '1fr 3rem 1fr',
        gap: '2rem'
    },
    alignItems: 'center'
});

const StyledExchange = styled(Exchange)({
    justifySelf: 'center'
});

const StyledCardWithDivider = styled(CardWithDivider)({
    alignSelf: 'start'
});

const ButtonContainer = styled.div({
    display: 'flex',
    justifyContent: 'end',
    marginBottom: '1rem'
});

//#endregion

export const PropertiesContactsListPage = () => {
    const { data: contacts, status: contactStatus } = useGetContacts();
    const { data: properties, status: propertyStatus } = useGetProperties();
    const { mutate: copyContactsToProperties } = useCopyContactsToProperties();
    const contactsMemo = React.useMemo(() => (contacts?.items ? contacts.items : []), [contacts]);

    const [filter, setFilter] = useState<ProjectFilter>({
        id: 0
    });

    const filteredData = useMemo(() => {
        return (
            properties?.filter(property => {
                if (!filter.id) return false;
                return property.project_id === filter.id;
            }) || []
        );
    }, [filter, properties]);

    const useTableContacts = useTableCheckbox({ data: contactsMemo, columns: COLUMNSCONTACTS });
    const useTableProperties = useTableCheckbox({ data: filteredData, columns: COLUMNSPROPERTY });

    const selectedDataContacts = useTableContacts.selectedFlatRows.map(data => {
        return data.original.id;
    });

    const selectedDataProperties = useTableProperties.selectedFlatRows.map(data => {
        return data.original.id;
    });

    const copyContactsToProperty = () => {
        copyContactsToProperties({ contacts: selectedDataContacts, properties: selectedDataProperties });
        useTableContacts.toggleAllRowsSelected(false);
        useTableProperties.toggleAllRowsSelected(false);
    };

    if (contactStatus === 'success' && propertyStatus === 'success') {
        return (
            <>
                <ButtonContainer>
                    {selectedDataContacts.length > 0 && selectedDataProperties.length > 0 ? (
                        <Button onClick={() => copyContactsToProperty()}>{L('copy_contacts')}</Button>
                    ) : (
                        <Button disabled={true}>{L('copy_contacts')}</Button>
                    )}
                </ButtonContainer>
                <GridCopyContainer>
                    <StyledCardWithDivider
                        mainArea={<TableRowSelectionContacts useTable={useTableContacts} contacts={contacts.items} />}
                    />
                    <StyledExchange />

                    <StyledCardWithDivider
                        mainArea={
                            <TableRowSelectionProperties
                                useTable={useTableProperties}
                                filterArea={<FilterArea filter={filter} setFilter={setFilter} />}
                                projectId={filter.id}
                                properties={filteredData}
                            />
                        }
                    />
                </GridCopyContainer>
            </>
        );
    }

    if (contactStatus === 'loading' || propertyStatus === 'loading') {
        return <Loading />;
    }

    return <div>Error</div>;
};
