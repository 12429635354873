import React, { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import styled from '@emotion/styled';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@ourliving/ourliving-ui';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import * as Dialog from '@radix-ui/react-dialog';
import { zodResolver } from '@hookform/resolvers/zod';
import TrashCan from '../../../../components/Icon/themed/TrashCan';
import Edit from '../../../../components/Icon/themed/Edit';
import Folder from '../../../../components/Icon/themed/Folder';
import Download from '../../../../components/Icon/themed/Download';
import Copy from '../../../../components/Icon/themed/Copy';
import { colors } from '../../../../components/Shared/Style/colors';
import { useGetDocumentSubtypes, useGetDocumentTypes } from '../../hooks/documentTypeQueries';
import { EditPropertyDocType, EditPropertyDocTypeSchema } from '../../schemas';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import { L } from '../../../../lib/i18n';
import Label from '../../../../components/Forms/Label';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import { style } from '../../../../components/Shared/Style';
import useGetSignedUrlById from '../../../../lib/hooks/useGetSignedUrlById';

const DialogWrapper = styled.div(() => ({
    padding: '22px 32px 22px 32px',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: `1px solid ${colors.divider}`,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    width: '100%',
    overflow: 'visible',
}));

const DropDownContent = styled(DropdownMenu.Content)({
    padding: '0.5rem',
    backgroundColor: colors.white,
    color: colors.textColor2,
    borderRadius: style.roundCornerSize.small,
    boxShadow: '0px 1px 14px rgba(0, 0, 0, 0.1)',
});

const StyledArrow = styled(DropdownMenu.Arrow)({
    fill: colors.white,
});

const DeleteIcon = styled(TrashCan)({
    height: '1.3rem',
    width: '1.3rem',
});
const EditIcon = styled(Edit)({
    height: '1.3rem',
    width: '1.3rem',
});

const FolderIcon = styled(Folder)({
    height: '1.3rem',
    width: '1.3rem',
});

const DownloadIcon = styled(Download)({
    height: '1.3rem',
    width: '1.3rem',
});

const CopyIcon = styled(Copy)({
    height: '1.3rem',
    width: '1.3rem',
});

const ContextItem = styled.li(() => ({
    padding: '1rem 2rem 1rem 1rem ',
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    gap: '1rem',
    cursor: 'pointer',
}));

const StyledItem = styled(DropdownMenu.Item)(() => ({
    '&:focus-visible': {
        outline: `${colors.primary50} auto 1px`,
    },
}));

const ButtonWrapper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '2rem',
    gap: '0.5rem',
});

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const P = styled.p(() => {
    return {
        fontSize: '1rem',
        padding: '0px',
        color: colors.textColor2,
        textDecoration: 'none',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'start',

        '&:hover': {
            cursor: 'pointer',
        },
    };
});

type Props = {
    deleteDocument?: () => void;
    editDocumentName?: (name: string) => void;
    updateDocType?: (document_type: number, document_subtype?: number) => void;
    url?: string;
    name: string;
    typesOfDoc: { document_type: number; document_subtype: number };
    documentId: string;
};

const StyledTrigger = styled(DropdownMenu.Trigger)(() => ({
    backgroundColor: 'inherit',
    border: 'none',
    padding: '0px',
    margin: '0px',
    cursor: 'pointer',
    '&:focus-visible': {
        outline: `${colors.primary50} auto 1px`,
    },
    '&:focus, &:hover': {
        outline: `${colors.primary50} auto 1px`,
    },
}));

const DocumentDropdown = ({
    name,
    deleteDocument,
    editDocumentName,
    updateDocType,
    typesOfDoc,
    documentId,
    ...rest
}: Props) => {
    const [open, setOpen] = useState<'changeName' | 'changeType' | ''>('');
    const { data: url } = useGetSignedUrlById(documentId);
    const handleCopy = async () => {
        if (!url) return;
        await navigator?.clipboard?.writeText(url);
        toast(`url ${L('copied_to_clipboard')}`);
    };
    const handleDownload = async () => {
        if (!url) return;

        window.open(url);
    };

    const { data: documentTypes } = useGetDocumentTypes();
    const { data: documentSubtypes } = useGetDocumentSubtypes();

    const {
        register: registerNameForm,
        handleSubmit: handleNameFormSubmit,
        formState: { errors: nameErrors },
    } = useForm<{ name: string }>({ defaultValues: { name } });

    const {
        handleSubmit: handleDocTypeFormSubmit,
        formState: { errors: docTypeErrors },
        control,
        setValue,
    } = useForm<EditPropertyDocType>({
        resolver: zodResolver(EditPropertyDocTypeSchema),
        defaultValues: { ...typesOfDoc },
    });

    const selectedDocumentType = useWatch({ name: 'document_type', control });

    const documentTypeOptions = useMemo(() => {
        return documentTypes?.map((type) => ({ value: type.id, label: L(type.name) })) || [];
    }, [documentTypes]);

    const documentSubtypeOptions = useMemo(() => {
        if (selectedDocumentType === 5)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('plan'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        if (selectedDocumentType === 10)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('manual'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        return [];
    }, [documentSubtypes, documentTypes, selectedDocumentType]);

    const onNameSubmit: SubmitHandler<{ name: string }> = (data) => {
        editDocumentName && editDocumentName(data.name.trim());
        setOpen('');
    };

    const onDocTypeSubmit: SubmitHandler<EditPropertyDocType> = ({ document_type, document_subtype }) => {
        updateDocType && updateDocType(document_type, document_subtype);
        setOpen('');
    };

    return (
        <>
            <Dialog.Root
                open={!!open}
                onOpenChange={() => {
                    setOpen('');
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            {open === 'changeName' && (
                                <DialogWrapper>
                                    <form onSubmit={handleNameFormSubmit(onNameSubmit)}>
                                        <ErrorAndInputWrapper errorMsg={L(nameErrors.name?.message ?? '')}>
                                            <Label title={L('edit_name')}>
                                                <InputWithError
                                                    isError={!!nameErrors.name}
                                                    {...registerNameForm('name', {
                                                        required: { value: true, message: L('error_required') },
                                                        min: { value: 1, message: L('error_required') },
                                                    })}
                                                />
                                            </Label>
                                        </ErrorAndInputWrapper>
                                        <ButtonWrapper>
                                            <Button type="button" onClick={() => setOpen('')}>
                                                {L('cancel')}
                                            </Button>
                                            <Button role="submit">{L('save')}</Button>
                                        </ButtonWrapper>
                                    </form>
                                </DialogWrapper>
                            )}
                            {open === 'changeType' && (
                                <DialogWrapper>
                                    <form onSubmit={handleDocTypeFormSubmit(onDocTypeSubmit)}>
                                        <ErrorAndInputWrapper
                                            errorMsg={
                                                docTypeErrors.document_type?.message
                                                    ? L(docTypeErrors.document_type?.message)
                                                    : ''
                                            }
                                        >
                                            <Label title={L('category')} />
                                            <Controller
                                                control={control}
                                                name="document_type"
                                                render={({ field: { onChange, value } }) => (
                                                    <RadixSelect
                                                        isError={!!docTypeErrors.document_type}
                                                        onValueChange={(val) => {
                                                            setValue('document_subtype', undefined);
                                                            onChange(+val);
                                                        }}
                                                        value={value}
                                                        options={documentTypeOptions}
                                                    />
                                                )}
                                            />
                                        </ErrorAndInputWrapper>

                                        <ErrorAndInputWrapper
                                            errorMsg={L(docTypeErrors.document_subtype?.message ?? '')}
                                        >
                                            <Label title={L('sub_category')} />
                                            <Controller
                                                control={control}
                                                name="document_subtype"
                                                render={({ field: { onChange, value } }) => (
                                                    <RadixSelect
                                                        isError={!!docTypeErrors.document_subtype}
                                                        onValueChange={(val) => onChange(+val)}
                                                        value={value}
                                                        options={documentSubtypeOptions}
                                                        disabled={
                                                            selectedDocumentType !== 5 && selectedDocumentType !== 10
                                                        }
                                                    />
                                                )}
                                            />
                                        </ErrorAndInputWrapper>

                                        <ButtonWrapper>
                                            <Button type="button" onClick={() => setOpen('')}>
                                                {L('cancel')}
                                            </Button>
                                            <Button type="submit">{L('save')}</Button>
                                        </ButtonWrapper>
                                    </form>
                                </DialogWrapper>
                            )}
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <DropdownMenu.Root modal={false}>
                <StyledTrigger {...rest}>
                    {name && <P>{name}</P>} {!name && <P style={{ fontStyle: 'italic' }}>Namn saknas</P>}
                </StyledTrigger>

                <DropdownMenu.Portal>
                    <DropDownContent sideOffset={1}>
                        {editDocumentName && (
                            <>
                                <StyledItem onClick={() => setOpen('changeName')}>
                                    <ContextItem>
                                        <EditIcon />

                                        <span>{L('edit_name')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {updateDocType && (
                            <>
                                <StyledItem onClick={() => setOpen('changeType')}>
                                    <ContextItem>
                                        <FolderIcon />

                                        <span>{L('edit_document_type')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {url && navigator?.clipboard && (
                            <>
                                <StyledItem onClick={() => handleCopy()}>
                                    <ContextItem>
                                        <CopyIcon />
                                        <span>{L('copy')}</span>
                                    </ContextItem>
                                </StyledItem>
                                <StyledItem onClick={handleDownload}>
                                    <ContextItem>
                                        <DownloadIcon />
                                        <span>{L('download')}</span>
                                    </ContextItem>
                                </StyledItem>
                            </>
                        )}
                        {deleteDocument && (
                            <StyledItem onClick={() => deleteDocument()}>
                                <ContextItem>
                                    <DeleteIcon />
                                    <span>{L('remove')}</span>
                                </ContextItem>
                            </StyledItem>
                        )}
                        <StyledArrow />
                    </DropDownContent>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
        </>
    );
};

export default DocumentDropdown;
