import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import toast from 'react-hot-toast';
import { QueryClient, QueryKey, UseMutateFunction, useQueryClient } from 'react-query';
import { L } from '../lib/i18n';

type DraggableItem = {
    id: number;
};

type Params = {
    queryKey: QueryKey;
    updateSortOrder: UseMutateFunction<
        number,
        unknown,
        {
            docs: { id: number }[];
            documentable_id: number;
        },
        unknown
    >;
    reverseArray?: boolean;
    isFiltering?: boolean;
    documentableId: number;
};

export const optimisticSort = <T extends DraggableItem>({
    client,
    event,
    queryKey,
    reverseArray = false,
}: {
    event: DragEndEvent;
    client: QueryClient;
    queryKey: QueryKey;
    reverseArray?: boolean;
}) => {
    const oldQueryData = client.getQueryData<T[]>(queryKey);

    const { active, over } = event;
    if (!oldQueryData) return;
    const activeItem = oldQueryData.find((item) => item.id === active.id);
    const overItem = oldQueryData.find((item) => item.id === over?.id);
    if (!activeItem || !overItem) return;
    if (activeItem !== overItem) {
        const oldIndex = oldQueryData?.indexOf(activeItem);
        const newIndex = oldQueryData?.indexOf(overItem);
        const newArray = arrayMove(oldQueryData, oldIndex, newIndex);
        client.setQueryData(queryKey, () => newArray);
        if (reverseArray) newArray.reverse();
        return newArray;
    }
};

const useDragEndDndNew = <T extends DraggableItem>({
    queryKey,
    updateSortOrder,
    reverseArray = false,
    isFiltering = false,
    documentableId,
}: Params) => {
    const client = useQueryClient();

    const dragEnd = (event: DragEndEvent) => {
        if (isFiltering) toast.error(L('cannot_change_order_error'));
        return optimisticSort<T>({
            client,
            event,
            queryKey,
            reverseArray,
        });
    };

    return (event: DragEndEvent) => {
        const sortedArray = dragEnd(event);
        if (sortedArray) return updateSortOrder({ docs: sortedArray, documentable_id: documentableId });
    };
};

export default useDragEndDndNew;
