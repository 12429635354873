import { createValidator, required } from '../../lib/validation';
import { observable } from 'mobx';
import { ContentNewsItem } from '@app/api/models/ProjectContentNews';
import ContentNewsApi from '@app/api/public/ProjectContentNewsApi';
import { redirect } from '../../lib/routing';
import DocumentsApi from '@app/api/public/DocumentsApi';

const rules = createValidator({
    title: [required()],
    description: [required()]
});

class ContentNewsStoreClass {
    posted = false;
    @observable errors: any = {};
    @observable model: Partial<ContentNewsItem> = {};

    @observable imageUrl: string;
    @observable imagePreview: string;

    @observable searchFilter: string[] = [];

    async load(id: string | undefined) {
        this.posted = false;
        if (!id) {
            this.model = {};
            this.imageUrl = '';
        } else {
            this.model = (await ContentNewsApi.get(id)) || {};
        }

        if (this.model.cover_image_data) {
            this.imageUrl = await DocumentsApi.getSignedUrlStore(this.model.cover_image_data.id);
        }
    }

    setValue = async (value: any, name: string) => {
        this.model[name] = value;
        if (this.posted) {
            const validation = rules.test(this.model);
            this.errors = validation.errors;
        }
    };

    save = async (projectId: number) => {
        this.posted = true;
        const validation = rules.test(this.model);
        this.errors = validation.errors;
        if (!validation.ok) return;

        const result = await ContentNewsApi.save(this.model, projectId);
        if (result.id !== this.model.id) {
            return redirect(`/news/${result.id}`);
        }

        this.model = result;
    };

    remove = async (id: string) => {
        await ContentNewsApi.remove(id);
        this.load(id);
        redirect(`/news/`);
    };
}
export const ContentNewsStore = new ContentNewsStoreClass();
