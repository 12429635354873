import { SaveTicket } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';

type CreateTicketVariables = { ticket: SaveTicket; files?: File[] };
const useCreateNewTicket = () => {
    const client = useQueryClient();
    return useMutation(
        (variables: CreateTicketVariables) => {
            if (variables.ticket.id) {
                return TicketsApi.saveTicket(variables);
            } else {
                return TicketsApi.createNewTicket(variables);
            }
        },
        {
            onSettled: (response, error, varables) => {
                if (varables.ticket.id) {
                    client.invalidateQueries(ticketQueryKeys.id(varables.ticket.id.toString()));
                }
                client.invalidateQueries(ticketQueryKeys.list());
            }
        }
    );
};

export default useCreateNewTicket;
