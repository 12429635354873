import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import useAddProduct from '../../binders/hooks/productHooks/useAddProduct';
import { SubmitHandler } from 'react-hook-form';
import { AddProduct } from '../../binders/schemas';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import {
    useListBrands,
    useListProductTypes,
    useListProffesions,
    useListProperties,
    useListSpaces,
    useListSuppliers,
} from '../../binders/hooks/addProductQueries';
import { useGetProjects } from '../../binders/hooks/projectHooks/projectQueries';
import ProductForm from '../../binders/components/Products/ProductForm';

const AddProductPage = () => {
    const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>();
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const { data: projects } = useGetProjects();
    const { data: properties } = useListProperties(selectedProjectId ? Number(selectedProjectId) : undefined);
    const [selectedPropertyId, setSelectedPropertyId] = useState<number | undefined>();
    const { data: spaces } = useListSpaces(selectedPropertyId ? Number(selectedPropertyId) : undefined);

    const handleSetSelectedPropertyId = (val: number | undefined) => setSelectedPropertyId(val);
    const handleSetSelectedProjectId = (val: number | undefined) => setSelectedProjectId(val);

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        properties: properties ? properties.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        projects: projects ? projects.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProduct();

    const onSubmit: SubmitHandler<AddProduct> = (data) =>
        saveInventoryMaterial(
            { inventoryMaterial: data },
            {
                onSuccess: () => {
                    toast.success(L('product_added'));
                },
                onError: () => {
                    toast.error(L('product_not_added'));
                },
            },
        );
    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_product')}</CardTitle>}
            mainArea={
                <ProductForm
                    handleSetSelectedPropertyId={handleSetSelectedPropertyId}
                    handleSetSelectedProjectId={handleSetSelectedProjectId}
                    onSubmit={onSubmit}
                    options={options}
                />
            }
        />
    );
};

export default AddProductPage;
