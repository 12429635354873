import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';

const getMaintenanceCategories = async () => {
    const r = await MaintenancesApi.listCategories();
    return r;
};

const useGetMaintenanceCategories = () => {
    return useQuery(['getMaintenanceCategories'], () => getMaintenanceCategories());
};

export default useGetMaintenanceCategories;
