import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getSpaceKeys, getSpacesFilterKeys } from './spaceQueries';
import { AddSpace } from '../../schemas';

const useSaveSpace = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ data, propertyId }: { data: AddSpace; propertyId: number }) => {
            const { name, space_type, ...attr } = data;
            const spaceData = { name, space_type, attr };
            return trpc.BindersApi.saveSpace.query({ space: spaceData, propertyId });
        },
        {
            onSettled: (variables) => {
                queryClient.invalidateQueries(getSpaceKeys.lists());
                if (variables?.propertyId)
                    queryClient.invalidateQueries(getSpacesFilterKeys.list({ propertyId: variables.propertyId }));
            },
        },
    );
};

export default useSaveSpace;
