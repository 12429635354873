import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { style } from '../Shared/Style';

const spinner = keyframes({
    '0%': {
        opacity: 0,
        WebkitTransform: 'scale(0)'
    },
    '100%': {
        opacity: 1,
        WebkitTransform: 'scale(1)'
    }
});

const Page = styled.div({
    minHeight: '50vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const Loader = styled.div({
    width: '80px',
    height: '80px',
    backgroundColor: style.colors.primary,
    borderRadius: '100%',
    webkitAnimation: `${spinner} 1.0s infinite ease-in-out`,
    MozAnimation: `${spinner} 1.0s infinite ease-in-out`,
    animation: `${spinner} 1.0s infinite ease-in-out`
});

export const Loading = () => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const t = setTimeout(() => setVisible(true), 300);
        return () => clearTimeout(t);
    }, []);
    if (!visible) return null;
    return (
        <Page>
            <Loader />
        </Page>
    );
};
