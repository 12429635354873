import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { documentGroupQueryKeys } from './documentGroupQueries';

export default function useUpdateDocumentGroup() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { id: number; name: string; projectId: number }) => {
            return trpc.ContentDocumentsApi.updateDocumentGroup.mutate({ ...options, id: +options.id });
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(documentGroupQueryKeys.list({ templateId: data.projectId }));
                queryClient.invalidateQueries(
                    documentGroupQueryKeys.detail({ id: data.id, projectId: data.projectId }),
                );
            },
        },
    );
}
