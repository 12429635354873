import axios from 'axios';

export type AwsUpload = {
    preSingedUrl: string;
    file: File;
    cleanup: () => Promise<void>;
};

export const uploadToAws = async ({ file, preSingedUrl, cleanup }: AwsUpload) => {
    const res = await axios.put(preSingedUrl, file);
    if (res.status >= 300) {
        await cleanup();
    }
};
