import React, { useMemo } from 'react';
import { useGetTodos } from './hooks/todoQueries';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { Button } from '@ourliving/ourliving-ui';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import { useRemoveTodo } from './hooks/todoMutations';
import GenericTable from '../../components/GenericTable';
import { Loading } from '../../../../components/Loading/Loading';

const columnHelper = createColumnHelper<NonNullable<ReturnType<typeof useGetTodos>['data']>[0]>();

const Cell = styled.div({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const TodoList = ({ propertyId }: { propertyId: number }) => {
    const todosQuery = useGetTodos({ propertyId });
    const { mutate: removeTodo } = useRemoveTodo();
    const data = todosQuery?.data ?? [];
    const status = todosQuery.status;
    const navigate = useNavigate();

    const Columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                header: () => <span>{L('name')}</span>,
                cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.description, {
                id: 'description',
                header: () => <span>{L('description')}</span>,
                cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.type, {
                id: 'type',
                header: () => <span>{L('type')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.subType, {
                id: 'subType',
                header: () => <span>{L('subType')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.recurrency, {
                id: 'recurrency',
                header: () => <span>{L('recurrency')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.id, {
                id: 'remove',
                header: () => <span />,
                cell: ({ getValue }) => (
                    <div>
                        <ConfirmationButton
                            props={{
                                onConfirm: () =>
                                    removeTodo({
                                        propertyId,
                                        todoId: getValue(),
                                    }),
                                stopPropagation: true,
                            }}
                        />
                    </div>
                ),
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
                enableSorting: false,
            }),
        ],
        [todosQuery.data],
    );

    const table = useReactTable({
        columns: Columns,
        data: data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <CardWithDivider
            topArea={
                <Flex>
                    <CardTitle>{L('todo_list')}</CardTitle>
                    <Link to={'./new'}>
                        <Button>{L('create_new_todo')}</Button>
                    </Link>
                </Flex>
            }
            mainArea={
                <div>
                    <GenericTable table={table} rowClickHandler={(row) => navigate(`./${row.original.id}`)} />
                </div>
            }
        />
    );
};

export default TodoList;
