import { useMutation } from 'react-query';
import { trpc } from '../../../lib/trpc';

const useUpdatePropertyDocType = () => {
    return useMutation(
        async (params: { docId: number; document_type: number; document_subtype?: number; propertyId: number }) => {
            return trpc.BindersApi.updatePropertyDocType.mutate(params);
        },
    );
};

export default useUpdatePropertyDocType;
