import React from 'react';
import styled from '@emotion/styled';
import { style } from '@app/shared/lib/Style';
import { formatDate } from '@app/shared/lib/formatting';

const PrintDate = styled.div({
    display: 'flex',
    justifyContent: 'space-evenly'
});

const ActiveDate = styled.div({
    textAlign: 'left',
    fontSize: style.fontSize.micro
});

const Text = styled.span({
    fontSize: style.fontSize.micro
});

export const ReportHeaderPrintDate = () => {
    return (
        <PrintDate>
            <Text>Utskriftsdatum: </Text>
            <ActiveDate>{formatDate(new Date())}</ActiveDate>
        </PrintDate>
    );
};
