import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyMemberKeys } from './propertyMemberQueries';

export default function useAddMemberToProperty() {
    const client = useQueryClient();
    return useMutation(
        async (params: { email: string; propertyId: number }) => {
            return trpc.MembersApi.addUserToProperty.mutate(params);
        },
        {
            onSettled: (_error, _data, ctx) => {
                client.invalidateQueries(getPropertyMemberKeys.list(ctx.propertyId));
            },
        },
    );
}
