import React, { useEffect, useMemo, useState } from 'react';
import { getCoreRowModel, useReactTable, SortingState, getSortedRowModel } from '@tanstack/react-table';
import Comments from '../../binders/components/Comments';
import FilterSearchContainer from '../../binders/components/emotion/Filters/FilterSearchContainer';
import Row from '../../binders/components/emotion/Row';
import { Loading } from '../../../components/Loading/Loading';
import { L } from '../../../lib/i18n';
import { useGetProduct, useGetProductsOnProductId } from '../../binders/hooks/productQueries';
import EmptyState from '../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { margin } from '../../../components/Shared/Style/margin';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import { TableMeta } from '../../binders/TableMetaTypes/PackageProductsTableMeta';
import { useSearchParams, useNavigate } from 'react-router-dom-v5-compat';
import columns from '../../binders/components/Products/PackagePageColumns';
import Dialog from '../../../components/Dialog/Dialog';
import GenericTable from '../../binders/components/GenericTable';
import GlobalFilter from '../../../components/Table/FilterComponents/GlobalFilterTable';
import { RouterOutput } from '../../../lib/trpc';
import TableMenu from '../../binders/components/TableMenuV2';
import useUpdateProductPrices from '../../binders/hooks/useUpdateProductPrices';
import toast from 'react-hot-toast';
import AddProductFromTemplateToPackage from '../../binders/components/Products/AddProductFromTemplateToPackage';
import { Button, DropdownButton } from '@ourliving/ourliving-ui';
import { useLocalStorage } from 'react-use';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

export type MutatedInventoryData = RouterOutput['ProductsApi']['listProductsOnProduct'][0] & {
    stagedDeletion: boolean;
    newPrice:
        | false
        | {
              id: number;
              price: number;
              addon_price: number;
              isProcentual: boolean;
          }
        | undefined;
};

type NewPrice = { id: number; price: number; addon_price: string };

const procentualAdd = (priceAdjustment: number, price: number) => {
    if (price) {
        return Math.round(priceAdjustment * price);
    } else {
        return null;
    }
};

const PackageProductsComponentCopyFunction = ({
    productId,
    tabsAndBreadcrumbs,
}: {
    productId: string;
    tabsAndBreadcrumbs?: React.ReactNode;
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';
    const [open, setOpen] = useState(false);

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const { data: product } = useGetProduct(+productId);

    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<Awaited<ReturnType<typeof useGetProductsOnProductId>>['data']>();
    const [previewDeletion, setPreviewDeletion] = useState<number[]>([]);
    const [previewPrices, setPreviewPrices] = useState<
        { id: number; price: number; addon_price: number; isProcentual: boolean }[]
    >([]);

    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const toggleModal = () => {
        setShowModal((oldModal) => !oldModal);
    };

    const { data: productData, isLoading } = useGetProductsOnProductId(+productId, searchField);

    useEffect(() => {
        if (!productData) return;
        setData(productData);
    }, [productData]);

    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.map((data, index) => {
            return {
                ...data,
                stagedDeletion: previewDeletion.length > 0 && previewDeletion.includes(data.id),
                newPrice: previewPrices.length > 0 && previewPrices.find((price) => price.id === index),
            };
        });
    }, [data, previewDeletion, previewPrices]);

    const previewPrice = (price: number, isProcentual: boolean) => {
        const previewPricesCopy = [...previewPrices];
        tableData.getSelectedRowModel().flatRows.forEach((item) => {
            const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === item.original.id);
            if (foundIndex >= 0) {
                previewPricesCopy[foundIndex].price = price;
                previewPricesCopy[foundIndex].isProcentual = isProcentual;
            } else if ((item.original.addon_price || !isProcentual) && price >= 0) {
                previewPricesCopy.push({
                    id: item.original.id,
                    price,
                    addon_price: Number(item.original.addon_price),
                    isProcentual,
                });
            }
        });
        setPreviewPrices([...previewPricesCopy]);
    };

    const cancelPrice = () => {
        setPreviewPrices([]);
    };

    const setSingleCell = (id: number, price: number, addon_price: string) => {
        const previewPricesCopy = [...previewPrices];
        const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === id);

        if (foundIndex >= 0) {
            previewPricesCopy[foundIndex].price = Math.round(price);
            previewPricesCopy[foundIndex].isProcentual = false;
        } else
            previewPricesCopy.push({
                id,
                price: Math.round(price),
                addon_price: Number(addon_price),
                isProcentual: false,
            });
        setPreviewPrices(previewPricesCopy);
    };

    const { mutate: updatePrice } = useUpdateProductPrices();

    const confirmPrice = async () => {
        const stagedItems = previewPrices
            .map((item) => {
                if (item.price) {
                    if (item.isProcentual) {
                        const calculatedPrice = procentualAdd(item.price, Number(item.addon_price));
                        return {
                            id: Number(item.id),
                            price: calculatedPrice && calculatedPrice >= 0 ? calculatedPrice : Number(item.addon_price),
                        };
                    } else {
                        return {
                            id: Number(item.id),
                            price: item.price >= 0 ? item.price : Number(item.addon_price),
                        };
                    }
                }
            })
            .flatMap((item) => (item ? [item] : []));
        if (stagedItems)
            updatePrice(stagedItems, {
                onSuccess: (changedItems) => {
                    setPreviewPrices([]);
                    toast.success(
                        `${L('edited')} ${changedItems} ${L(
                            changedItems === 1 ? 'product' : 'products',
                        ).toLowerCase()}`,
                    );
                },
                onError: () => {
                    toast.error(L('something_went_wrong'));
                },
            });
    };

    const [columnVisibilityState, setColumnVisibilityState] = useLocalStorage<Record<string, boolean>>(
        'packageProductsColumnVisibilityState',
        { image: false },
    );

    const [localNewProductButtonState, setNewProductButtonState] = useLocalStorage<
        'new_product' | 'new_product_from_template'
    >('copyFunctionPackageProductsPage', 'new_product');
    const newProductButtonState = localNewProductButtonState || 'new_product';
    const tableData = useReactTable({
        data: filteredData,
        columns,
        state: { sorting, rowSelection, columnVisibility: columnVisibilityState },
        onColumnVisibilityChange: setColumnVisibilityState,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        manualSorting: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        getRowId: (originalRow) => String(originalRow.id),
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        enableRowSelection: true,
        meta: {
            updateData: ({ id, price, addon_price }: NewPrice) => {
                setSingleCell(id, price, addon_price);
            },
            previewPrices,
            previewDeletion,
        } as TableMeta,
    });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Dialog content={{ style: { width: 'auto' } }} open={open} onOpenChange={setOpen}>
                {product?.property_id && (
                    <AddProductFromTemplateToPackage
                        productId={productId}
                        propertyId={product.property_id.toString()}
                    />
                )}
            </Dialog>
            {tabsAndBreadcrumbs}
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <Comments showModal={showModal} toggleModal={toggleModal} />
                        <CardTitle>{L('products')}</CardTitle>
                        <div style={{ display: 'flex', columnGap: margin.s, marginLeft: 'auto' }}>
                            <DropdownButton
                                options={[
                                    {
                                        id: 'new_product',
                                        dropdownItem: (
                                            <DropdownButton.DropdownItem>
                                                {L('new_product')}
                                            </DropdownButton.DropdownItem>
                                        ),
                                        button: (
                                            <DropdownButton.Button
                                                onClick={() => {
                                                    navigate('new');
                                                }}
                                            >
                                                {L('new_product')}
                                            </DropdownButton.Button>
                                        ),
                                    },
                                    {
                                        id: 'new_product_from_template',
                                        dropdownItem: (
                                            <DropdownButton.DropdownItem>
                                                {L('new_product_from_template')}
                                            </DropdownButton.DropdownItem>
                                        ),
                                        button: (
                                            <DropdownButton.Button
                                                onClick={() => {
                                                    setOpen(true);
                                                }}
                                            >
                                                {L('new_product_from_template')}
                                            </DropdownButton.Button>
                                        ),
                                    },
                                ]}
                                optionId={newProductButtonState}
                                setOptionId={(state) => {
                                    setNewProductButtonState(state);
                                }}
                            />
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <FilterSearchContainer
                            style={{
                                margin: '1rem 0 1rem 0',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: '1rem',
                                    marginLeft: 'auto',
                                }}
                            >
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        display: 'flex',
                                        columnGap: '0.5rem',
                                        alignItems: 'center',
                                    }}
                                >
                                    {`${L('show_with_image')}:`}
                                    <Checkbox
                                        checked={tableData.getColumn('image')?.getIsVisible()}
                                        onChange={tableData.getColumn('image')?.getToggleVisibilityHandler()}
                                    />
                                </div>
                                <Row>
                                    <GlobalFilter
                                        filter={searchField}
                                        setFilter={handleSearch}
                                        placeholder={L('search')}
                                    />
                                </Row>
                            </div>
                        </FilterSearchContainer>

                        <TableMenu
                            selectedCount={Object.keys(rowSelection).length}
                            previewDeletion={previewDeletion}
                            changedPrices={previewPrices.length > 0}
                            setPreviewDeletion={setPreviewDeletion}
                            onCancelPrice={cancelPrice}
                            previewPrice={previewPrice}
                            confirmPrice={confirmPrice}
                            tableData={tableData}
                            resetRowSelection={() => {
                                setRowSelection({});
                            }}
                        />

                        <GenericTable
                            table={tableData}
                            rowClickHandler={({ original }) => navigate(`${original.id}`)}
                        />
                        {filteredData &&
                            filteredData.length === 0 &&
                            (!searchField ? (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_on_space_description')}</p>
                                        <p>{L('click_button_below')}</p>
                                        <Button onClick={() => setOpen(true)}>{L('new_product_from_template')}</Button>
                                    </EmptyState.Description>
                                </EmptyState>
                            ) : (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_found_description')}</p>
                                        <p>{L('please_try_something_else')}</p>
                                    </EmptyState.Description>
                                </EmptyState>
                            ))}
                    </>
                }
            />
        </>
    );
};

export default PackageProductsComponentCopyFunction;
