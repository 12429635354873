import React, { ReactNode, useEffect, useState } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { QueryKey, useMutation, useQuery, useQueryClient } from 'react-query';
import { DndContext } from '@dnd-kit/core';
import DocumentItem from '../DocumentDropdown/DocumentItem';
import { Icon } from '../../Icon/Icon';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { Button, Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui/dist/components/DropZone/DropZone';
import Label from '../../Forms/Label';
import RadixSelect from '../../RadixSelect/RadixSelect';
import Dialog from '../../Dialog/Dialog';
import { useSortable, SortableContext, arrayMove } from '@dnd-kit/sortable';
import { optimisticSort } from '../../../hooks/useDragEndDnd';
import DocumentDropdown from '../DocumentDropdown/DocumentDropdown';

const Wrapper = styled.div({
    display: 'flex',
    gap: '1rem',
    justifyContent: 'end',
});

type SaveDocumentInput = {
    file: File;
    documentGroupId: number;
    name: string;
};

type SaveDocument = (input: SaveDocumentInput) => Promise<unknown>;

type UploadAreaProps = {
    saveDocument: SaveDocument;
    documentQueryKey: QueryKey;
    groups: { name: string; id: number }[];
};

const UploadArea = ({ saveDocument, documentQueryKey, groups }: UploadAreaProps) => {
    const [files, setFiles] = useState<onUploadSuccess['file'][]>([]);
    const [selectedGroup, setSelectedGroup] = useState<number>();
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const { mutate: saveDocumentMutation } = useMutation(
        (input: SaveDocumentInput) => {
            return saveDocument(input);
        },
        {
            onMutate: (input) => {
                const toastId = toast.loading(`${L('saving')} ${input.name}`);
                return toastId;
            },
            onError: (_error, input, toastId: string) => {
                toast.error(`${L('save_error')}`, {
                    id: toastId,
                });
            },
            onSuccess: (_data, input, toastId: string) => {
                toast.success(`${input.name} ${L('saved')}`, {
                    id: toastId,
                });
            },

            onSettled: () => {
                queryClient.invalidateQueries(documentQueryKey);
            },
        },
    );

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') {
            toast.error(`${param.errorCode} ${L('an_error_occurred')}`);
            return;
        }
        const duplicate = files.some((file) => file.name === param.file.name);
        if (!duplicate) {
            setFiles((prevFiles) => [...prevFiles, param.file]);
            setOpen(true);
        }
    };
    return (
        <>
            <Dialog
                open={open && !!files.length}
                onOpenChange={(open) => {
                    if (!open) {
                        setFiles([]);
                    }
                    setOpen(open);
                }}
            >
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!selectedGroup) return toast.error(L('no_document_group_selected'));

                        files.map((file) => {
                            saveDocumentMutation({
                                file: file,
                                documentGroupId: selectedGroup,
                                name: file.name,
                            });
                        });
                        setOpen(false);

                        setSelectedGroup(undefined);
                        setFiles([]);
                    }}
                >
                    <Label title={L('select_document_group')}>
                        <RadixSelect
                            placeholder={L('document_group_input')}
                            onValueChange={(value) => setSelectedGroup(+value)}
                            value={selectedGroup}
                            options={groups.map((group) => ({ label: group.name, value: group.id }))}
                        />
                    </Label>

                    <div style={{ paddingTop: '1rem' }}>
                        {files.map((file) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                    key={file.name}
                                >
                                    <DocumentItem fileType={file.type} name={file.name} />
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            setFiles((prevFiles) =>
                                                prevFiles.filter((prevFile) => prevFile.name !== file.name),
                                            )
                                        }
                                    >
                                        <Icon.TrashCan />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <Wrapper>
                        <Button
                            type={'button'}
                            onClick={() => {
                                setOpen(false);
                                setSelectedGroup(undefined);
                                setFiles([]);
                            }}
                        >
                            {L('cancel')}
                        </Button>
                        <Button disabled={!selectedGroup}>{L('save')}</Button>
                    </Wrapper>
                </form>
            </Dialog>
            <Dropzone onUpload={onUpload} variant="All" multiple={true} />
        </>
    );
};

const GroupName = styled.h2({
    fontSize: '1rem',
    marginBottom: 0,
});

const Draggable = styled.div({
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'auto 1fr',
});

const IconWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    placeItems: 'center',
});

const DocumentGroup = ({ id, children, name }: { id: number; children: ReactNode; name: string }) => {
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: active ? 'grabbing' : 'grab',
    };

    return (
        <div style={style} ref={setNodeRef}>
            <GroupName
                style={{
                    cursor: active ? 'grabbing' : 'grab',
                }}
                {...attributes}
                {...listeners}
            >
                {name}
            </GroupName>
            <div>{children}</div>
        </div>
    );
};

const SortableWithIcon = ({ id, children }: { id: number; children: ReactNode }) => {
    const { attributes, listeners, setNodeRef, transform, transition, active } = useSortable({
        id: id,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <Draggable ref={setNodeRef} style={style}>
            <IconWrapper
                style={{
                    cursor: active ? 'grabbing' : 'grab',
                }}
                {...listeners}
                {...attributes}
            >
                <Icon.VerticalMoreIcon
                    size="small"
                    style={{
                        justifySelf: 'left',
                        visibility: 'visible',
                    }}
                />
                <Icon.VerticalMoreIcon
                    size="small"
                    style={{
                        justifySelf: 'left',
                        visibility: 'visible',
                        marginLeft: '-6px',
                    }}
                />
            </IconWrapper>
            {children}
        </Draggable>
    );
};

type Document = {
    name: string;
    url: string;
    type: string;
    id: number;
    groupId?: number | null;
    sort?: number | null;
};

type DocumentGroup = {
    name: string;
    id: number;
    sortId?: number;
    sort?: number | null;
};

type SortFn = (input: {
    sortedArray: {
        id: number;
    }[];
}) => Promise<unknown>;
type GroupSortFn = (input: {
    sortedArray: {
        id: number;
        sortId?: number;
    }[];
}) => Promise<unknown>;

type Props = {
    document: {
        get: () => Promise<Document[]>;
        save: SaveDocument;
        queryKey: QueryKey;
        sort: SortFn;
        renameDocument: (input: { name: string; id: number }) => Promise<unknown>;
        removeDocument: (input: { id: number }) => Promise<unknown>;
        switchGroup: (input: { docId: number; groupId: number }) => Promise<unknown>;
    };
    documentGroup: {
        get: () => Promise<DocumentGroup[]>;
        sort: GroupSortFn;
        queryKey: QueryKey;
    };
};

const GroupDocumentsArea = ({ document, documentGroup }: Props) => {
    const queryClient = useQueryClient();
    const { data: documents } = useQuery({
        queryFn: document.get,
        queryKey: document.queryKey,
    });

    const documentGroupQuery = useQuery({
        queryFn: documentGroup.get,
        queryKey: documentGroup.queryKey,
    });

    const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);

    useEffect(() => {
        if (documentGroupQuery.data) {
            setDocumentGroups(
                documentGroupQuery.data?.filter((group) => documents?.find((doc) => doc.groupId === group.id)),
            );
        }
    }, [documentGroupQuery.data, documents]);

    const { mutate: sortDocuments } = useMutation({
        mutationFn: document.sort,
        onSuccess: () => {},
        onSettled: () => {
            queryClient.invalidateQueries(document.queryKey);
        },
    });

    const { mutate: sortGroups } = useMutation({
        mutationFn: documentGroup.sort,
        onSuccess: () => {},
        onSettled: () => {
            queryClient.invalidateQueries(documentGroup.queryKey);
        },
    });

    const { mutate: renameDocument } = useMutation({
        mutationFn: document.renameDocument,
        onSuccess: () => {},
        onSettled: () => {
            queryClient.invalidateQueries(document.queryKey);
        },
    });

    const { mutate: removeDocument } = useMutation({
        mutationFn: document.removeDocument,
        onSuccess: () => {},
        onSettled: () => {
            queryClient.invalidateQueries(document.queryKey);
        },
    });

    const { mutate: switchGroup } = useMutation({
        mutationFn: document.switchGroup,
        onSuccess: () => {},
        onSettled: () => {
            queryClient.invalidateQueries(document.queryKey);
        },
    });

    const selectableGroups = documentGroupQuery.data?.map((group) => ({ id: group.id, name: group.name })) || [];

    const DocumentGroups = documentGroups?.map((group) => {
        const documentsInGroup = documents?.filter((doc) => doc.groupId === group.id) || [];

        return (
            <DocumentGroup key={group.id} id={group.id} name={group.name}>
                <DndContext
                    onDragEnd={async (event) => {
                        const sorted = optimisticSort<DocumentGroup>({
                            client: queryClient,
                            queryKey: document.queryKey,
                            event: event,
                        });
                        if (sorted) {
                            sortDocuments({
                                sortedArray: sorted,
                            });
                        }
                    }}
                >
                    <SortableContext items={documentsInGroup}>
                        <div>
                            {documentsInGroup.map((doc) => {
                                return (
                                    <SortableWithIcon key={doc.id} id={doc.id}>
                                        <DocumentDropdown
                                            fileType={doc.type}
                                            name={doc.name}
                                            url={doc.url}
                                            deleteDocument={() => removeDocument({ id: doc.id })}
                                            editDocumentName={(newName) =>
                                                renameDocument({ id: doc.id, name: newName })
                                            }
                                            documentGroup={{
                                                documentGroups: selectableGroups,
                                                switchGroup: (groupId) =>
                                                    switchGroup({ docId: doc.id, groupId: groupId }),
                                            }}
                                        />
                                    </SortableWithIcon>
                                );
                            })}
                        </div>
                    </SortableContext>
                </DndContext>
            </DocumentGroup>
        );
    });

    return (
        <>
            <UploadArea groups={selectableGroups} documentQueryKey={document.queryKey} saveDocument={document.save} />
            <DndContext
                onDragOver={(event) => {
                    const { active, over } = event;
                    if (active.id !== over?.id) {
                        setDocumentGroups((documentGroups) => {
                            const oldIndex = documentGroups?.findIndex((document) => document.id === active.id);
                            const newIndex = documentGroups?.findIndex((document) => document.id === over?.id);
                            return arrayMove(documentGroups, oldIndex, newIndex);
                        });
                    }
                }}
                onDragEnd={async () => {
                    sortGroups({
                        sortedArray: documentGroups.map((group, i) => ({
                            id: group.id,
                            sortId: i,
                        })),
                    });
                }}
            >
                <SortableContext strategy={() => null} items={documentGroups.map((group) => group.id)}>
                    {DocumentGroups}
                </SortableContext>
            </DndContext>
        </>
    );
};

export default GroupDocumentsArea;
