import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateProjectDocSort = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.BindersApi.updateProjectDocSort.mutate({ docs: params.docs, projectId: params.documentable_id });
    });
};

export default useUpdateProjectDocSort;
