import styled from '@emotion/styled';
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { colors } from '../../../components/Shared/Style/colors';

const StyledCarouselWrapper = styled.div({
    marginBottom: '1rem',
    gridArea: 'headerCarousel'
});

const HeroBox = styled.div({
    gridTemplateAreas: `
        "headerCarousel"
    `,
    position: 'relative',
    width: '100%',
    display: 'grid',
    marginBottom: '1.5rem'
});

const StyledImg = styled.img((props: { url?: string | undefined | null; gradient?: boolean }) => ({
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: colors?.black90,
    width: '100%',
    backgroundImage: props.url
        ? props.gradient
            ? `linear-gradient(0deg, rgba(0,0,0,0.48783263305322133) 0%, rgba(0,0,0,0.0984768907563025) 37%, rgba(255,255,255,0) 55%, rgba(255,255,255,0) 64%, rgba(0,0,0,0.1741071428571429) 81%, rgba(0,0,0,0.41220238095238093) 100%), url(${props.url})`
            : `url(${props.url})`
        : ``,
    height: '30vh'
}));

export type Props = {
    urls: (string | undefined)[];
    to: string;
    gradient?: boolean;
    className?: string;
    title?: string;
};
const CarouselHero: React.FC<Props> = ({ to, urls, children, title, gradient = true }) => {
    return (
        <HeroBox>
            <StyledCarouselWrapper className="carousel-wrapper">
                <Carousel showThumbs={false} showStatus={false}>
                    {urls &&
                        urls.map(url => {
                            return (
                                <div key={url}>
                                    <StyledImg gradient={gradient} url={url} />
                                </div>
                            );
                        })}
                </Carousel>
            </StyledCarouselWrapper>
        </HeroBox>
    );
};

export default CarouselHero;
