import { L } from './i18n';

export function required(): ValidationRule {
    return {
        test(value: any) {
            if (!!value) return;
            return L('error_required');
        }
    };
}

export function requireOneOf(keys: string[]): ValidationRule {
    return {
        test(value: any, model: any) {
            if (keys.filter(k => !!model[k]).length >= 1) return;
            return L('error_oneisrequired');
        }
    };
}

export function requireEndDateLargerThanStartDate(value1: any, value2: any): ValidationRule {
    console.log('requireEndDateLargerThanStartDate', value1, value2);
    return {
        test() {
            if (value1 <= value2) return;
            return L('error_end_date_less_than_start_date');
        }
    };
}

class ModelValidator {
    rules: ValidationRules;
    constructor(rules: ValidationRules) {
        this.rules = rules;
    }
    test(model: any): ValidationResult {
        const results: ValidationResult = { ok: true, errors: {} };
        for (const rules of Object.entries(this.rules)) {
            const key = rules[0];
            const value = model[key];

            for (const rule of rules[1]) {
                const errorText = rule.test(value, model);
                if (errorText) {
                    results.errors[key] = errorText;
                    results.ok = false;
                    break;
                }
            }
        }
        return results;
    }
}

export interface ValidationRules {
    [key: string]: ValidationRule[];
}

export interface ValidationResult {
    ok: boolean;
    errors: ValidationErrors;
}

export type ValidationErrors = { [key: string]: string };

export interface ValidationRule {
    test(value: any, model: any): string | undefined;
}

export function createValidator(rules: ValidationRules): ModelValidator {
    return new ModelValidator(rules);
}
