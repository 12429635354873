import React, { createContext, useMemo, useState } from 'react';

//Logic
import { L } from '../../../../../lib/i18n';

//Hooks
import FlexContainer from '../../../components/emotion/FlexContainer';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import styled from '@emotion/styled';
import EmptyState from '../../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../../../components/Layout/CardWithDivider';
import { margin } from '../../../../../components/Shared/Style/margin';
import CardTitle from '../../../../../components/typography/CardTitle';
import TopAreaContainer from '../../../components/TopAreaContainer';
import useGetImportMembersList from '../../../hooks/propertyHooks/useGetImportProperties';
import { FileUpload } from '../../../../../components/FileUploadTicket/FileUpload';
import { style } from '../../../../../components/Shared/Style';
import { colors } from '../../../../../components/Shared/Style/colors';
import memberColumns from './ColumnsMembers';
import Spinner from '../../../../../components/Spinner/Spinner';
import GenericTable from '../../../components/GenericTable';
import { RowSelectionState, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import propertyColumns from './ColumnsProperties';
import { useSaveImportPropertiesList } from '../../../hooks/propertyHooks/useSaveImportPropertiesList';
import { ExcelPropertyImport } from '@app/api/models/Properties';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import useGetProjectName from '../../../useGetProjectName';
import H1 from '../../../../../components/typography/H1';

const StyledFileUpload = styled(FileUpload)({
    display: 'block',
    placeSelf: 'center',
    width: 'max-content',
});

const StyledFlexContainer = styled(FlexContainer)({
    justifyContent: 'flex-end',
});

const Span = styled.div(() => ({
    fontSize: style.fontSize.seta,
    display: 'inline-block',
    fontWeight: style.fontWeight.bold,
    color: colors.secondary,
    '&.active': {
        color: colors.primary,
    },
    '&:hover': {
        cursor: 'pointer',
    },
}));

const FlexWrapper = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
    marginBottom: style.margin.ml,
});

const SpanDivider = styled.div({
    borderRight: '1px solid grey',
});

const UploadSelectedButton = styled(Button)({
    placeSelf: 'end',
});

type PropertiesContextType = {
    upload: boolean;
    setUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

export const PropertyContext = createContext({} as PropertiesContextType);

const Page = ({ id }: { id: string }) => {
    const { mutate: mutateImport, data: dataImport, status } = useGetImportMembersList();

    const navigate = useNavigate();

    const dataImportMemo = useMemo(() => dataImport ?? [], [dataImport]);

    const { mutate: saveProperties } = useSaveImportPropertiesList();

    const [upload, setUpload] = useState(false);

    const members = dataImport?.flatMap((data) => data.members);

    const [view, setView] = useState<'properties' | 'members'>('properties');

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

    const propertiesTable = useReactTable({
        data: dataImportMemo,
        columns: propertyColumns(),
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
        state: { rowSelection },
        onRowSelectionChange: setRowSelection,
    });
    const membersTable = useReactTable({
        data: members ?? [],
        columns: memberColumns(),
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    const saveProperty = (properties: ExcelPropertyImport[]) => {
        saveProperties(
            { properties, projectId: +id },
            {
                onSuccess: () => {
                    toast.success(`${L('import_successful')}`);
                    setUpload(false);
                    propertiesTable.resetRowSelection();
                    navigate(`..`);
                },
                onError: () => {
                    setUpload(false);
                    propertiesTable.resetRowSelection();
                },
            },
        );
    };

    const selectedData = propertiesTable.getSelectedRowModel().flatRows.map((data) => {
        return data.original;
    });

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const project = useGetProjectName(projectId);

    if (status === 'loading') return <Spinner />;

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to={'../../..'}>
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to={'..'}>{project ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('import_properties')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project ?? L('project_literally')}</H1>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{L('properties')}</CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                            <StyledFileUpload custom={'yes'} onFilesSelected={(file) => mutateImport(file[0])}>
                                {L('import_properties')}
                            </StyledFileUpload>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        {dataImport ? (
                            <>
                                <StyledFlexContainer>
                                    <UploadSelectedButton
                                        disabled={Object.keys(rowSelection).length === 0}
                                        onClick={() => {
                                            saveProperty(selectedData);
                                            setUpload(true);
                                        }}
                                        isLoading={upload}
                                    >
                                        {L('upload_selected_properties')} : {Object.keys(rowSelection).length}st
                                    </UploadSelectedButton>
                                </StyledFlexContainer>
                                <FlexWrapper>
                                    <Span
                                        className={view === 'properties' ? 'active' : ''}
                                        onClick={() => {
                                            setView('properties');
                                        }}
                                    >
                                        {L('properties')}
                                    </Span>
                                    <SpanDivider />
                                    <Span
                                        className={view === 'members' ? 'active' : ''}
                                        onClick={() => {
                                            setView('members');
                                        }}
                                    >
                                        {L('members')}
                                    </Span>
                                </FlexWrapper>
                                {view === 'properties' ? (
                                    <GenericTable table={propertiesTable} />
                                ) : (
                                    <GenericTable table={membersTable} />
                                )}
                            </>
                        ) : (
                            <EmptyState>
                                <EmptyState.Title>{L('no_properties_imported')}</EmptyState.Title>

                                <EmptyState.Description>{L('no_properties_description')} </EmptyState.Description>
                                <EmptyState.Description> {L('click_button_below')} </EmptyState.Description>

                                <StyledFileUpload custom={'yes'} onFilesSelected={(file) => mutateImport(file[0])}>
                                    {L('import_properties')}
                                </StyledFileUpload>
                            </EmptyState>
                        )}
                    </>
                }
            />
        </>
    );
};

export default function ImportPropertyPage() {
    const { id } = useParams<{ id: string }>();
    return id ? <Page id={id} /> : null;
}
