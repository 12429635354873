import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { useGetNotificationBellCount } from './hooks/notificationQueries';
import { Icon } from '../../components/Icon/Icon';
import { NotificationCountV2 } from '../../components/NotificationCount/NotificationCountV2';

import { style } from '../../components/Shared/Style';
import NotificationModal from './components/NotificationModal';
import styled from '@emotion/styled';
import IconWrapper from '../../components/Icon/themed/IconWrapper';

const StyledNotificationCount = styled(NotificationCountV2)({
    top: '-7px',
    right: '-7px',
    fontSize: '0.7rem',
    color: style.colors.white,
    backgroundColor: style.colors.errors
});

type Props = {
    to: LinkProps['to'];
    size?: string;
    color: any;
};

export const NotificationBell: React.FC<Props> = ({ to, size, color }) => {
    const { data: bellCount } = useGetNotificationBellCount();
    const [isOpenModal, setIsOpenModal] = React.useState(false);
    const handleCloseModal = () => setIsOpenModal(false);
    return (
        <>
            <IconWrapper>
                <Icon.NotificationBell onClick={() => setIsOpenModal(!isOpenModal)} />
                <StyledNotificationCount counts={bellCount?.count ?? 0} color={style.colors.errors as 'errors'} />
            </IconWrapper>

            <NotificationModal isOpenModal={isOpenModal} handleCloseModal={handleCloseModal} to={to} size={size} />
        </>
    );
};
export default NotificationBell;
