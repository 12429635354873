Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getPropertyList"] = b.createRpcProxy("AddonsApi", "getPropertyList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getAddonInfoById"] = b.createRpcProxy("AddonsApi", "getAddonInfoById", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["list"] = b.createRpcProxy("AddonsApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listv2"] = b.createRpcProxy("AddonsApi", "listv2", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getOrderConfirmationHeaderInfo"] = b.createRpcProxy("AddonsApi", "getOrderConfirmationHeaderInfo", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["rptHeaderInfo"] = b.createRpcProxy("AddonsApi", "rptHeaderInfo", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProject"] = b.createRpcProxy("AddonsApi", "getProject", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProperty"] = b.createRpcProxy("AddonsApi", "getProperty", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["setAddonNotified"] = b.createRpcProxy("AddonsApi", "setAddonNotified", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProduct"] = b.createRpcProxy("AddonsApi", "getProduct", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["save"] = b.createRpcProxy("AddonsApi", "save", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["savePropertySetting"] = b.createRpcProxy("AddonsApi", "savePropertySetting", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getPropertyInfo"] = b.createRpcProxy("AddonsApi", "getPropertyInfo", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["setConfirmation"] = b.createRpcProxy("AddonsApi", "setConfirmation", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["sendConfirmation"] = b.createRpcProxy("AddonsApi", "sendConfirmation", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getOrderConfirmationRecipients"] = b.createRpcProxy("AddonsApi", "getOrderConfirmationRecipients", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProductList"] = b.createRpcProxy("AddonsApi", "getProductList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProductGroupList"] = b.createRpcProxy("AddonsApi", "getProductGroupList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["renderSupplierReportPdf"] = b.createRpcProxy("AddonsApi", "renderSupplierReportPdf", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["createOrderConfirmationPdf"] = b.createRpcProxy("AddonsApi", "createOrderConfirmationPdf", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getOrderConfirmationPdfSignedUrl"] = b.createRpcProxy("AddonsApi", "getOrderConfirmationPdfSignedUrl", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getProductsList"] = b.createRpcProxy("AddonsApi", "getProductsList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getGrossList"] = b.createRpcProxy("AddonsApi", "getGrossList", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["setEnableCheckout"] = b.createRpcProxy("AddonsApi", "setEnableCheckout", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["saveAddonEndDate"] = b.createRpcProxy("AddonsApi", "saveAddonEndDate", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["listAddonSupplier"] = b.createRpcProxy("AddonsApi", "listAddonSupplier", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["rptProducts"] = b.createRpcProxy("AddonsApi", "rptProducts", {
  "urlPrefix": "/rpc"
});
o["getUsersOnOrder"] = b.createRpcProxy("AddonsApi", "getUsersOnOrder", {
  "urlPrefix": "/rpc"
});
o["setSignedManually"] = b.createRpcProxy("AddonsApi", "setSignedManually", {
  "urlPrefix": "/rpc"
});
o["deleteOrder"] = b.createRpcProxy("AddonsApi", "deleteOrder", {
  "urlPrefix": "/rpc"
});
o["addUserToOrder"] = b.createRpcProxy("AddonsApi", "addUserToOrder", {
  "urlPrefix": "/rpc"
});
o["getPropertiesToNotify"] = b.createRpcProxy("AddonsApi", "getPropertiesToNotify", {
  "urlPrefix": "/rpc"
});
o["invite"] = b.createRpcProxy("AddonsApi", "invite", {
  "urlPrefix": "/rpc"
});
o["getAddonInfo"] = b.createRpcProxy("AddonsApi", "getAddonInfo", {
  "urlPrefix": "/rpc"
});
o["saveAddonInfo"] = b.createRpcProxy("AddonsApi", "saveAddonInfo", {
  "urlPrefix": "/rpc"
});