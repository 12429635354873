import { useGetProperty } from './hooks/propertyHooks/propertyQueries';

const useGetPropertyName = (id?: string) => {
    const Name = (id: string) => {
        const { data } = useGetProperty(+id);
        return data?.name;
    };
    return id ? Name(id) : null;
};

export default useGetPropertyName;
