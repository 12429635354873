import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getAdminKeys } from './useGetProjectAdmins';

const useRemoveProjectAdmin = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (data: { membershipIds: number[]; projectId: number }) => {
            return await Promise.all(
                data.membershipIds.map(async (id) => {
                    return trpc.MembersApi.removeProjectAdmin.mutate({ id, projectId: data.projectId });
                }),
            );
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getAdminKeys.lists());
            },
        },
    );
};

export default useRemoveProjectAdmin;
