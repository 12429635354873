import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import { style } from '@app/shared/lib/Style';

const Container = styled.div({
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
});
const Text = styled.div({
    fontSize: style.fontSize.centi,
    color: '#808080'
});

interface Props {
    filter: { has_price?: boolean };
    setFilter: (filter: any) => void;
}

export const FilterHasPrice = (props: Props) => {
    const name = 'has_price';

    const handleOnChange = e => {
        props.setFilter({
            ...props.filter,
            [name]: e.target.checked
        });
    };

    return (
        <Container>
            <Checkbox onChange={e => handleOnChange(e)} checked={props.filter[name] || false} name={name} />
            <Text>{L(name)}</Text>
        </Container>
    );
};
