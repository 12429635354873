import { useMutation, useQueryClient } from 'react-query';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';
import { getServicePartnerCategoriesQueryKey } from './useGetServicePartnerCategories';
import { useParams } from 'react-router-dom';

type Variables = { servicePartnerId: number; categoryId: number };

export const saveServicePartnerCategory = async (projectId: string, variables: Variables) => {
    return await ServicePartnersApi.saveServicePartnerCategory(
        +projectId,
        variables.servicePartnerId,
        variables.categoryId
    );
};

const useSaveServicePartner = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams<{ projectId: string }>();

    return useMutation((variables: Variables) => saveServicePartnerCategory(projectId, variables), {
        onSettled: () => {
            queryClient.invalidateQueries([getServicePartnerCategoriesQueryKey]);
        }
    });
};

export default useSaveServicePartner;
