import React from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import TopAreaContainer from '../../binders/components/TopAreaContainer';
import PropertySpacesTemplateList from '../PropertyTemplates/PropertySpacesTemplateList';

const PropertySpacesTemplatePage = () => {
    const { id: propertyId } = useParams<{ id: string }>();
    const navigate = useNavigate();

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>{L('spaces')}</CardTitle>
                    <Button style={{ marginLeft: 'auto' }} onClick={() => navigate('new')}>
                        {L('new_space')}
                    </Button>
                </TopAreaContainer>
            }
            mainArea={propertyId ? <PropertySpacesTemplateList propertyId={+propertyId} /> : null}
        />
    );
};

export default PropertySpacesTemplatePage;
