import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import useGetPropertyMembers from '../../hooks/useGetPropertyMembers';
import useInviteMembersToAddon from '../../hooks/useInviteMembersToAddon';
import { Property } from '@app/api/models/Properties';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    marginBottom: '1rem',
    alignItems: 'center',
});

const ModalItems = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    width: '100%',
    minHeight: '100%',
    paddingBottom: '2rem',
    paddingTop: '2rem',
});

const Container = styled.div({
    marginBottom: '2rem',
});

const FlexContainer = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
});

const StyledModal = styled(Modal)({
    minHeight: '300px',
    maxHeight: '80vh',
    minWidth: '600px',
    padding: '0px 2rem 0px 2rem',
});

type Props = {
    properties: Property[];
    forwardRef: React.MutableRefObject<null>;
    isActive: boolean;
    setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
};

export function AddonInviteMembers({ properties, forwardRef, isActive, setIsActive }: Props) {
    const { projectId } = useParams<{ projectId: string }>();
    const { mutate } = useInviteMembersToAddon();
    const userProperties = useGetPropertyMembers(properties?.map((p) => p.id) || []);

    const onClick = () => {
        if (!properties.length) return;
        mutate(
            { properties: properties.map((p) => p.id) },
            {
                onSuccess: () => {
                    setIsActive(false);
                },
            },
        );
    };

    const PropertiesWithUsers = properties?.map((property) => {
        const userProperty = userProperties
            .map(({ data }) => data)
            .find((propertyUsers) => propertyUsers?.propertyId === property.id);
        return (
            <Container key={property.id}>
                <SubTitle>{property.name}</SubTitle>
                {userProperty?.users.map((user) => {
                    return <p key={user.id}>{user.name}</p>;
                })}
            </Container>
        );
    });

    return (
        <StyledModal height="" width="" forwardRef={forwardRef} isActive={isActive}>
            <ModalItems>
                <div>
                    {PropertiesWithUsers}
                    <FlexContainer>
                        <Button
                            onClick={onClick}
                            style={{
                                alignSelf: 'flex-end',
                                justifySelf: 'flex-end',
                                marginTop: style.margin.m,
                                marginRight: '0px',
                                display: 'block',
                            }}
                        >
                            {L('addon_invite_members')}
                        </Button>
                    </FlexContainer>
                </div>
            </ModalItems>
        </StyledModal>
    );
}
