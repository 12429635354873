import React, { FC } from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

type Props = {
    title: string;
    error?: string;
    required?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

const Field = styled.label({
    margiBottom: style.margin.ml,
    display: 'block'
});

const Title = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between'
});

const Description = styled.div({});

const Errors = styled.span({
    color: 'red'
});

const LabelRequired: FC<Props &
    React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>> = p => {
    const errorText = p.error;
    const hasError = errorText !== undefined;

    return (
        <Field {...p}>
            <Title>
                <span>
                    <span>{p.title}</span>
                    <span>{p.required ? ' * ' : ' '}</span>
                </span>

                {hasError && <Errors>{errorText}</Errors>}
            </Title>
            <Description>{p.children}</Description>
        </Field>
    );
};

export default LabelRequired;
