import { style } from '@app/shared/lib/Style';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from '@ourliving/ourliving-ui';
import { Icon } from '../../../../../components/Icon/Icon';
import { AboveCard } from '../../../../../components/Layout/AboveCard';
import Spinner from '../../../../../components/Spinner/Spinner';
import { downloadFile } from '../../../../../lib/download';
import { L } from '../../../../../lib/i18n';
import { AddonReportStore } from '../../AddonReportStore';
import { AddonReportFilterPanel } from './AddonReportFilterPanel';
import BigTab from '../../../../../components/Tabs/BigTab/BigTab';
import { AddonsGrossFilterPanel } from './AddonsGrossFilterPanel';
import Label from '../../../../../components/Filters/FilterLabel';
import { useAddonReport } from '../context/AddonReportContext';
import useRenderCsv from '../../../property/hooks/useRenderCsv';

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.black,
    height: '32px',
});

const Buttons = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'end',
    gap: '10px',
});

const StyledButton = styled(Button)({
    [`@media screen and (max-width: 1438px)`]: {
        fontSize: style.fontSize.micro,
        padding: '0px 2px',
    },
});

const TabArea = styled.div({
    display: 'flex',
    width: 'max-content',
});

type Props = {
    projectId: number;
    handlePrint: () => void;
    loadPrint: boolean;
};

const RaportHandler = ({ projectId, handlePrint, loadPrint }: Props) => {
    const [tab, setTab] = useState<'supplier' | 'prices' | 'gross'>('supplier');
    const { filter, setFilter } = useAddonReport();

    const { mutate: csvFile, status } = useRenderCsv();

    const onDownload = async () => {
        if (tab === 'gross') {
            csvFile(
                { projectId, filter },
                {
                    onSuccess: (data) => {
                        downloadFile(data.name, data);
                    },
                },
            );
        }
    };

    const updateTab = async (tab) => {
        AddonReportStore.tab = tab;
        setTab(tab);
        await AddonReportStore.startPrintToPdf();
    };

    const onFilter = async () => {
        AddonReportStore.isWaitingFilters = true;
        await AddonReportStore.loadAddonSuppliers(projectId, filter);
        AddonReportStore.isWaitingFilters = false;
    };

    return (
        <AboveCard>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <TabArea>
                    <Label style={{ alignSelf: 'center' }}>{L('choose_report')}:</Label>
                    <BigTab
                        onClick={() => {
                            updateTab('supplier');
                        }}
                        style={{ paddingLeft: '0px' }}
                        className={tab === 'supplier' ? 'active' : ''}
                    >
                        {L('supplier')} - pdf
                    </BigTab>
                    <BigTab
                        onClick={() => {
                            updateTab('prices');
                        }}
                        className={tab === 'prices' ? 'active' : ''}
                    >
                        {L('prices')} - pdf
                    </BigTab>
                    <BigTab
                        onClick={() => {
                            updateTab('gross');
                        }}
                        className={tab === 'gross' ? 'active' : ''}
                    >
                        {L('Gross')} - xls
                    </BigTab>
                </TabArea>

                <div style={{ display: 'flex' }}>
                    {(tab === 'prices' || tab === 'supplier') && (
                        <AddonReportFilterPanel setFilter={setFilter} filter={filter} />
                    )}
                    {tab === 'gross' && <AddonsGrossFilterPanel setFilter={setFilter} filter={filter} />}
                    <Icon.Filter style={{ height: '21px' }} onClick={onFilter} />
                </div>
            </div>
            <Buttons>
                <StyledNavLink to={`/addons/project/${projectId}/report/supplier`}>
                    <StyledButton>
                        {L('supplier')} {L('and')} {L('profession')}
                    </StyledButton>
                </StyledNavLink>
                <Button
                    onClick={tab === 'gross' ? onDownload : handlePrint}
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            visibility: status === 'loading' || loadPrint ? 'hidden' : 'inherit',
                        }}
                    >
                        {L('download_pdf_report')}
                    </span>
                    {status === 'loading' ||
                        (loadPrint && (
                            <Spinner
                                style={{
                                    position: 'absolute',
                                }}
                            />
                        ))}
                </Button>
            </Buttons>
        </AboveCard>
    );
};

export default RaportHandler;
