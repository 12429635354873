import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';
import styled from '@emotion/styled';
import { FormField } from '../../../../../components/Forms/FormField';
import { AddonProjectStore } from '../../AddonProjectStore';
import { LineDivider } from '../../../../../components/Layout/LineDivider';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

const TextInput = styled.input({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    width: '100%',
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
});

const Row = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
});

export function AddonProjectSettings({ forwardRef, isActive, setIsActive }) {
    const store = AddonProjectStore;

    const onClick = () => {
        store.saveProjectSettings();
        setIsActive(false);
    };

    const onChange = (evt: any) => {
        store.setValue(evt.target.value, evt.target.name);
    };

    const onChangeAdress = (evt: any) => {
        store.setValueAddress(evt.target.value, evt.target.name);
    };

    return (
        <Modal height={'800px'} forwardRef={forwardRef} isActive={isActive}>
            <SubTitle>{L('settings')} </SubTitle>
            <Row>
                <FormField width={'45%'} title={L('name')} error={''}>
                    <TextInput defaultValue={store.project?.project_info?.name} name="name" onChange={onChange} />
                </FormField>
                <FormField width={'45%'} title={L('project_registration_number')} error={''}>
                    <TextInput
                        defaultValue={store.project?.project_info?.project_registration_number}
                        name="project_registration_number"
                        onChange={onChange}
                    />
                </FormField>
            </Row>
            <FormField title={L('street_name')} error={''}>
                <TextInput
                    defaultValue={store.project?.project_info?.address?.addressline1}
                    name="addressline1"
                    onChange={onChangeAdress}
                />
            </FormField>
            <FormField title={L('street_name')} error={''}>
                <TextInput
                    defaultValue={store.project?.project_info?.address?.addressline2}
                    name="addressline2"
                    onChange={onChangeAdress}
                />
            </FormField>
            <Row>
                <FormField width={'45%'} title={L('city')} error={''}>
                    <TextInput
                        defaultValue={store.project?.project_info?.address?.city}
                        name="city"
                        onChange={onChangeAdress}
                    />
                </FormField>
                <FormField width={'45%'} title={L('zip_code')} error={''}>
                    <TextInput
                        defaultValue={store.project?.project_info?.address?.postal_code}
                        name="postal_code"
                        onChange={onChangeAdress}
                    />
                </FormField>
            </Row>
            <Row>
                <FormField width={'45%'} title={L('country')} error={''}>
                    <TextInput
                        defaultValue={store.project?.project_info?.address?.country}
                        name="country"
                        onChange={onChangeAdress}
                    />
                </FormField>
                <FormField width={'45%'} title={L('phone_number')} error={''}>
                    <TextInput
                        defaultValue={store.project?.project_info?.phone_number}
                        name="phone_number"
                        onChange={onChange}
                    />
                </FormField>
            </Row>
            <Row>
                <FormField width={'45%'} title={L('homepage')} error={''}>
                    <TextInput defaultValue={store.project?.project_info?.url} name="url" onChange={onChange} />
                </FormField>
                <FormField width={'45%'} title={L('logo')} error={''}>
                    <TextInput defaultValue={store.project?.project_logo} name="project_logo" onChange={onChange} />
                </FormField>
            </Row>
            <LineDivider margin={`${style.margin.ml}px 0px 0px 0px`} />
            <FormField title={L('notification_email')} error={''}>
                <TextInput
                    defaultValue={store.project?.notification_email}
                    name="notification_email"
                    onChange={onChange}
                />
            </FormField>

            <Button
                onClick={onClick}
                style={{
                    alignSelf: 'flex-end',
                    justifySelf: 'flex-end',
                    marginTop: style.margin.l,
                    marginRight: '0px',
                }}
            >
                {L('save')}
            </Button>
        </Modal>
    );
}
