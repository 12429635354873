import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { useGetPropertyDocumentTypes } from '../../binders/hooks/propertyHooks/propertyQueries';
import { useGetDocumentSubtypes } from '../../binders/hooks/documentTypeQueries';
import { FileTypeIcon } from '../../../components/Icon/Icon';
import { L } from '../../../lib/i18n';
import { RouterOutput } from '../../../lib/trpc';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { fontSize } from '../../../components/Shared/Style/typography';
import { colors } from '@ourliving/ourliving-ui';
import { formatDateIsoDayjs } from '@app/shared/lib/formatting';

const columnHelper = createColumnHelper<RouterOutput['BindersApi']['getProjectDocuments'][0]>();

export type TableMeta = {
    removeRow: (id: string) => void;
    clearRows: () => void;
    numberOfDocs: number;
};

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const useGetDeleteDocumentsListColumns = () => {
    const { data: documentTypes } = useGetPropertyDocumentTypes();
    const { data: documentSubtypes } = useGetDocumentSubtypes();
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'selection',
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <div onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                            checked={row.getIsSelected()}
                            disabled={!row.getCanSelect()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    </div>
                ),
                size: 30,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
                enableResizing: false,
            }),
            columnHelper.display({
                id: 'file_type',
                cell: ({ row }) => (
                    <CellContainer>
                        <FileTypeIcon fileType={row.original.document_data?.original?.metadata?.mime_type} />
                    </CellContainer>
                ),
                size: 50,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.document_type, {
                id: 'document_type',
                cell: ({ row }) => {
                    const typeName = documentTypes?.find((type) => type.id === row.original.document_type)?.name;
                    return (
                        <CellContainer>
                            <Data>{typeName ? L(typeName) : ''}</Data>
                        </CellContainer>
                    );
                },
                header: () => <Flex>{L('category')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.document_subtype, {
                id: 'document_subtype',
                cell: ({ row }) => {
                    const typeName = documentSubtypes?.find((type) => type.id === row.original.document_subtype)?.name;
                    return (
                        <CellContainer>
                            <Data>{typeName ? L(typeName) : ''}</Data>
                        </CellContainer>
                    );
                },
                header: () => <Flex>{L('sub_category')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.property_name, {
                id: 'property',
                cell: ({ row }) => (
                    <CellContainer>
                        <Data style={{ display: 'flex', flexDirection: 'column' }}>
                            {row.original.property_template ? (
                                <span style={{ fontSize: fontSize.milli, color: colors.all.brandGreen }}>
                                    {L('template')}
                                </span>
                            ) : (
                                <></>
                            )}
                            <span>{row.original.property_name}</span>
                        </Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('property')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.created_at, {
                id: 'created_at',
                cell: ({ row }) => (
                    <CellContainer>
                        <Data>{formatDateIsoDayjs(row.original.created_at)}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('created_at')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [documentTypes, documentSubtypes],
    );
    return columns;
};

export default useGetDeleteDocumentsListColumns;
