import React from 'react';
import AddProductFromTemplateComponent from './AddProductFromTemplateComponent';
import { useGetLibraries } from '../../hooks/libraryQueries';
import useImportTemplateProduct from '../../hooks/CopyFunctionHooks/useImportTemplateProduct';

const AddProductFromTemplateToPackage = ({ propertyId, productId }: { propertyId: string; productId: string }) => {
    const { data: libraries } = useGetLibraries();
    const { mutate: importTemplateProduct } = useImportTemplateProduct();
    const handleImportTemplateProduct = ({
        products,
        destinationId,
        propertyId,
    }: {
        destinationId: number;
        products: {
            id: number;
            package_type: string | null;
        }[];
        propertyId: number;
    }) => {
        importTemplateProduct({ propertyId: +propertyId, products, spaceId: +destinationId });
    };

    return (
        libraries && (
            <AddProductFromTemplateComponent
                libraries={libraries}
                propertyId={+propertyId}
                productId={+productId}
                handleImportToPropertyProduct={handleImportTemplateProduct}
            />
        )
    );
};

export default AddProductFromTemplateToPackage;
