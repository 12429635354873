import * as React from 'react';
import { Route, Routes } from 'react-router-dom-v5-compat';
import { L } from '../../lib/i18n';
import styled from '@emotion/styled';
import BigTabNav from '../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../components/BigTabItem/BigTabListItemNav';
import SettingsTicketsPage from './Tickets/SettingsTicketsPage';
import StatusPage from './Tickets/pages/StatusPage';
import PriorityPage from './Tickets/pages/PriorityPage';
import ResponseTextPage from './Tickets/pages/ResponseTextPage';
import TypePage from './Tickets/pages/TypePage';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const Navigation = () => {
    return (
        <Menu>
            <BigTabNav>
                <Routes>
                    <Route path="ticket-settings">
                        <Route
                            index
                            element={
                                <>
                                    <BigNavItem end to=".">
                                        {L('status')}
                                    </BigNavItem>
                                    <BigNavItem end to="./priority">
                                        {L('priority')}
                                    </BigNavItem>
                                    <BigNavItem end to="./type">
                                        {L('type')}
                                    </BigNavItem>
                                    <BigNavItem end to="./text">
                                        {L('text')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="priority"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('status')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('priority')}
                                    </BigNavItem>
                                    <BigNavItem end to="../type">
                                        {L('type')}
                                    </BigNavItem>
                                    <BigNavItem end to="../text">
                                        {L('text')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="type"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('status')}
                                    </BigNavItem>
                                    <BigNavItem end to="../priority">
                                        {L('priority')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('type')}
                                    </BigNavItem>
                                    <BigNavItem end to="../text">
                                        {L('text')}
                                    </BigNavItem>
                                </>
                            }
                        />
                        <Route
                            path="text"
                            element={
                                <>
                                    <BigNavItem end to="..">
                                        {L('status')}
                                    </BigNavItem>
                                    <BigNavItem end to="../priority">
                                        {L('priority')}
                                    </BigNavItem>
                                    <BigNavItem end to="../type">
                                        {L('type')}
                                    </BigNavItem>
                                    <BigNavItem end to=".">
                                        {L('text')}
                                    </BigNavItem>
                                </>
                            }
                        />
                    </Route>
                </Routes>
            </BigTabNav>
        </Menu>
    );
};

export const TicketSettings = () => {
    return (
        <>
            <Navigation />
            <Routes>
                <Route path="ticket-settings">
                    <Route index element={<StatusPage />} />
                    <Route path="priority" element={<PriorityPage />} />
                    <Route path="type" element={<TypePage />} />
                    <Route path="text" element={<ResponseTextPage />} />
                </Route>
            </Routes>
        </>
    );
};
