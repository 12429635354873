import { ProjectFilter } from '@app/api/models/Projects';
import styled from '@emotion/styled';
import React from 'react';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { Filters } from '../../../components/Filters/Filters';
import { Loading } from '../../../components/Loading/Loading';
import useGetProjects from '../hooks/useGetProjects';

const StyledDropdown = styled(Dropdown)({
    marginRight: '1rem',
    minWidth: '100px'
});

const StyledFilters = styled(Filters)({
    marginTop: '0'
});

type Props = {
    setFilter: React.Dispatch<React.SetStateAction<ProjectFilter>>;
    filter: ProjectFilter;
};

const FilterArea: React.FC<Props> = ({ setFilter, filter }) => {
    const { data: projects } = useGetProjects();

    if (!projects) return <Loading />;

    return (
        <StyledFilters>
            <StyledDropdown
                isClearable
                label={'Välj Projekt'}
                onChange={value => setFilter({ ...filter, id: value })}
                value={filter.id}
                options={
                    projects?.map(project => {
                        return {
                            value: project.id,
                            label: project.name
                        };
                    }) || []
                }
            />
        </StyledFilters>
    );
};

export default FilterArea;
