import { observable } from 'mobx';
import AccountSettingsApi from '@app/api/public/AccountSettingsApi';
import {
    EntitySupplier,
    EntitySupplierList,
    Brand,
    Supplier,
    ProductType,
    Profession
} from '@app/api/public/AccountSettingsApi';

class EntitySupplierStoreClass {
    @observable entitySuppliers: EntitySupplierList[];
    @observable selectEntitySupplierId: number;
    @observable entitySupplier: Partial<EntitySupplier>;
    @observable selectedEntitySupplierIds: Set<string> = new Set();
    @observable entitySupplierEdit: Partial<EntitySupplier>;

    @observable brands: Brand[];
    @observable suppliers: Supplier[];
    @observable professions: Profession[];
    @observable productType: ProductType[];

    @observable searchFilter: string[] = [];

    @observable errors: string;
    @observable saveError: string;

    async load() {
        this.entitySuppliers = await AccountSettingsApi.getEntitySupplierList();
        this.brands = await AccountSettingsApi.getBrands();
        this.suppliers = await AccountSettingsApi.getSuppliers();
        this.professions = await AccountSettingsApi.getProfessions();
        this.productType = await AccountSettingsApi.getProductTypes();
    }

    async getById(id: number) {
        this.entitySupplierEdit = this.entitySuppliers.find(e => e.id === id);
    }

    async save() {
        const r = await AccountSettingsApi.saveEntitySupplier(this.entitySupplier);
        if (r && r.result.status === 'error') return (this.saveError = r.result?.message);
        r;
        this.saveError = '';
        this.saveError = '';
        this.load();
    }
    async remove() {
        await AccountSettingsApi.removeEntitySupplier([...this.selectedEntitySupplierIds]);
        this.load();
    }
}

export const EntitySupplierStore = new EntitySupplierStoreClass();
