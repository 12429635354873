import { useQuery } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { SettingsType } from '@app/api/models/Maintenances';

export const getSettingsListByTypeKey = 'getSettingsListByType';

const getSettingsListByType = async (settingstype: SettingsType) => {
    switch (settingstype) {
        case 'category':
            return MaintenancesApi.listCategories();

        case 'status':
            return MaintenancesApi.listStatus();

        case 'type':
            return MaintenancesApi.listTypes();

        case 'unit':
            return MaintenancesApi.listUnits();
    }
};

const useGetSettingsListByType = (settingstype: SettingsType) => {
    return useQuery([getSettingsListByTypeKey, { settingstype }], () => getSettingsListByType(settingstype));
};

export default useGetSettingsListByType;
