import styled from '@emotion/styled';
import React, { FC } from 'react';

const MessageTitle = styled.h1({
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 600,
    margin: 0,
    padding: 0,
});
const Title: FC = ({ children, ...rest }) => {
    return <MessageTitle {...rest}>{children}</MessageTitle>;
};

export default Title;
