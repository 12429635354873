import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { ContentNewsQueryKeys } from './contentNewsQueries';

export default function useRemoveContentNews() {
    const queryClient = useQueryClient();
    return useMutation(
        async (params: { projectId: number; contentNewsId: number }) => {
            return trpc.ContentNewsApi.removeContentNews.mutate({ ...params });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(ContentNewsQueryKeys.lists());
            },
        },
    );
}
