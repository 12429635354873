import { PropertyList } from '@app/api/models/Addons';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@ourliving/ourliving-ui';
import Spinner from '../../../../../components/Spinner/Spinner';
import { L } from '../../../../../lib/i18n';
import useCreateOrderConfirmation from '../../hooks/useCreateOrderConfirmation';
import useGetOrderConfirmationSignedUrl from '../../hooks/useGetOrderConfirmationSignedUrl';

const StyledA = styled.a({
    textDecoration: 'none',
});

const AddonOrderConfirmationButton = ({ id, no_members, no_signed_users }: PropertyList) => {
    const { mutate: createOrderConfirmation, status: createOrderStatus } = useCreateOrderConfirmation();
    const { data: signedUrl, status: getSignedUrlStatus } = useGetOrderConfirmationSignedUrl({ propertyId: id });

    if (signedUrl) {
        return (
            <StyledA href={signedUrl} target="_blank">
                <Button
                    style={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span>{L('show')}</span>
                </Button>
            </StyledA>
        );
    }

    if (!signedUrl) {
        return (
            <Button
                style={{
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                onClick={() => createOrderConfirmation({ propertyId: id })}
                variant={no_signed_users !== no_members || no_members === 0 ? 'danger' : 'primary'}
                disabled={no_signed_users !== no_members || no_members === 0}
            >
                <span
                    style={{
                        visibility: createOrderStatus === 'loading' ? 'hidden' : 'inherit',
                    }}
                >
                    {L('create')}
                </span>
                {createOrderStatus === 'loading' && (
                    <Spinner
                        style={{
                            position: 'absolute',
                        }}
                    />
                )}
            </Button>
        );
    }

    return <div>AddonOrderConfirmationButton</div>;
};

export default AddonOrderConfirmationButton;
