import { InviteType } from '@app/api/models/Members';
import AddonsApi from '@app/api/public/AddonsApi';
import MembersApi from '@app/api/public/MembersApi';
import { useMutation, useQueryClient } from 'react-query';
import { getPropertyKeys } from './useGetPropertyList';
import { getOrderUserKeys } from './useGetUsersOnOrder';

type Variables = {
    userId: number;
    propertyId: number;
};

const addUserToOrder = async ({ propertyId, userId }: Variables) => {
    return await AddonsApi.addUserToOrder(propertyId, userId);
};

const useAddUserToOrder = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => addUserToOrder(variables), {
        onSettled: () => {
            queryClient.invalidateQueries(getOrderUserKeys.all);
            queryClient.invalidateQueries(getPropertyKeys.all);
        }
    });
};

export default useAddUserToOrder;
