import { ContentNewsStore } from '../ContentNewsStore';
import React, { useState } from 'react';
import { usePromise } from '../../../lib/react';
import { useObserver } from 'mobx-react';
import { FormField } from '../../../components/Forms/FormField';
import { TextInput } from '../../../components/Forms/Text';
import { Button } from '@ourliving/ourliving-ui';
import { style } from '../../../components/Shared/Style';
import { Card } from '../../../components/Layout/Card';
import styled from '@emotion/styled';
import { DisplayField } from '../../../components/DisplayField/DisplayField';
import { L } from '../../../lib/i18n';
import { NavLink } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { Dropzone } from '@ourliving/ourliving-ui';
import { DatetimeWorklog } from '../../../components/DateTimeWorklog/DateStatusWorklog';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui/dist/components/DropZone/DropZone';

const Content = styled.div({
    display: 'flex',
    flexDirection: 'row',
    padding: style.margin.l,
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    marginRight: style.margin.m,
    lineHeight: '32px',
});

export function NewsDetailEditor(props: { id?: string }) {
    const store = ContentNewsStore;
    const [ready] = usePromise(async () => await store.load(props.id), [props.id]);

    const [value, setValue] = useState();

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return;
        store.setValue(param.file, 'image');
        store.imageUrl = URL.createObjectURL(param.file);
    };

    const save = () => {
        store.save(projectId);
    };

    const handleEditorChange = (value: any) => {
        setValue(value);
        store.setValue(value, 'description');
    };

    return useObserver(() => {
        if (!ready) return null;
        const { errors, model } = store;
        return (
            <>
                <Content>
                    <Card width="49%" background={style.colors.white} margin={`0 12px 0 0px`}>
                        <FormField title="Bild" error={errors.title}>
                            <Dropzone
                                onUpload={onUpload}
                                variant="Media"
                                url={store.imageUrl}
                                remove={() => {
                                    store.imageUrl = '';
                                }}
                            />
                        </FormField>
                        <FormField title="Title" error={errors.title}>
                            <TextInput name="title" value={model.title || ''} onChange={store.setValue} />
                        </FormField>
                        <FormField title={L('publish_start')} error={errors.title}>
                            <TextInput name="publish_start" value={model.title || ''} onChange={store.setValue} />
                        </FormField>
                        <FormField title={L('publish_end')} error={errors.title}>
                            <TextInput name="publish_end" value={model.title || ''} onChange={store.setValue} />
                        </FormField>
                        <FormField title={L('published')}>
                            <input type="checkbox" />
                        </FormField>
                        <FormField title={L('boardmember_area')}>
                            <input type="checkbox" />
                        </FormField>
                        <FormField title="Description" error={errors.description}>
                            <Editor
                                initialValue={model.description || 'hej'}
                                value={value}
                                apiKey={'280lwnf4vg3t97aajf1fzc9hm89s7ikxzzq26tjd7hn09aaa'}
                                outputFormat="html"
                                onEditorChange={handleEditorChange}
                                init={{ menubar: false, branding: false }}
                                textareaName="description"
                            />
                        </FormField>
                        <ButtonContainer>
                            <StyledNavLink to="./">
                                <Button>{L('cancel')}</Button>
                            </StyledNavLink>
                            <Button onClick={store.save} style={{ marginRight: style.margin.s }}>
                                {L('save')}
                            </Button>
                        </ButtonContainer>
                    </Card>
                    <Card background={style.colors.white} width="49%">
                        <DisplayField title={L('created_at')}>
                            <DatetimeWorklog date={model.created_at} />
                        </DisplayField>
                        <DisplayField title={L('published')}>{model.published_at}</DisplayField>
                        <DisplayField title={L('created_by')}>{model.created_by_id}</DisplayField>
                        <DisplayField title={L('notice_sent')}>{model.notified_at}</DisplayField>
                        <ButtonContainer>
                            <Button>{L('notify_again')}</Button>
                        </ButtonContainer>
                    </Card>
                </Content>
            </>
        );
    });
}
