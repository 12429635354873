import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import SpaceForm from '../../components/Spaces/SpaceForm';
import {
    getSpaceKeys,
    useGetPropertySpace,
    useGetSpaceImages,
    useGetSpaceTypes,
} from '../../hooks/spaceHooks/spaceQueries';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { SubmitHandler } from 'react-hook-form';
import { AddSpace } from '../../schemas';
import toast from 'react-hot-toast';
import useUpdateSpace from '../../hooks/spaceHooks/useUpdateSpace';
import useDeleteSpace from '../../hooks/spaceHooks/useDeleteSpace';
import { TRPCClientError } from '@trpc/client';
import styled from '@emotion/styled';
import { Separator } from '../../../../components/Separator/Separator';
import { colors } from '../../../../components/Shared/Style/colors';
import Images from '../../components/Draggables/Images';
import { useQueryClient } from 'react-query';
import { style } from '../../../../components/Shared/Style';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import dayjs from 'dayjs';
import useDragEndDnd from '../../../../hooks/useDragEndDndNew';
import useRemoveSpaceImage from '../../hooks/spaceHooks/useRemoveSpaceImage';
import useUploadSpaceImages from '../../hooks/spaceHooks/useUploadSpaceImages';
import useUpdateSpaceDocSort from '../../hooks/spaceHooks/useUpdateSpaceDocSort';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';
import ImageDropZoneNoText from '../../../../components/ImageDropzone/ImageDropZoneNoText';

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const SpaceDetailComponent = ({
    projectId,
    propertyId,
    spaceId,
}: {
    projectId: string;
    propertyId: string;
    spaceId: string;
}) => {
    const { data: spaceData, status } = useGetPropertySpace(+spaceId, +propertyId);
    const { data: spaceTypes } = useGetSpaceTypes();
    const { data: images } = useGetSpaceImages(+spaceId);
    const { mutate: deleteSpace } = useDeleteSpace();
    const { mutate: removeImage } = useRemoveSpaceImage();
    const { mutate: saveImage } = useUploadSpaceImages();
    const { mutate: updateSort, isLoading: isUpdatingSort } = useUpdateSpaceDocSort();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const onImageUpload = (files: File[]) => {
        saveImage(
            { files, spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
                onSettled: () => {
                    queryClient.invalidateQueries([...getSpaceKeys.documentsBySpaceId(+spaceId)]);
                },
            },
        );
    };

    const handleDeleteSpace = () => {
        deleteSpace(
            { spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(`${spaceData?.space_name} ${L('removed').toLowerCase()}`);
                    navigate(`..`);
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleRemoveImage = (documentId: number) => {
        removeImage(
            { documentId, entityId: +spaceId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries([...getSpaceKeys.documentsBySpaceId(+spaceId)]);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const spaceTypeOptions = useMemo(
        () =>
            spaceTypes?.map((type) => {
                return {
                    value: type.id,
                    label: L(type.name),
                };
            }) || [],
        [spaceTypes],
    );

    const { mutate: updateSpace } = useUpdateSpace();

    const onSubmit: SubmitHandler<AddSpace> = (data) => {
        updateSpace(
            { data, spaceId: +spaceId, propertyId: +propertyId },
            {
                onSuccess: () => {
                    toast.success(L('updated_space'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const imageData: { id: number; url: string }[] = useMemo(() => {
        if (!images) return [];
        return images.map((image) => {
            return { id: image.id, url: image.signed_url };
        });
    }, [images]);

    const handleDragEnd = useDragEndDnd({
        queryKey: getSpaceKeys.documentsBySpaceId(+spaceId),
        updateSortOrder: updateSort,
        documentableId: +spaceId,
        reverseArray: true,
    });

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{spaceData?.space_name ?? L('space')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            {status !== 'loading' && <H1>{spaceData?.space_name ?? L('space')}</H1>}
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="products">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('edit_space')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={<CardTitle>{L('edit_space')}</CardTitle>}
                mainArea={
                    status !== 'loading' && (
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto 1fr', columnGap: '1rem' }}>
                            <SpaceForm
                                onSubmit={onSubmit}
                                spaceTypeOptions={spaceTypeOptions}
                                spaceData={spaceData}
                                handleDeleteSpace={handleDeleteSpace}
                                mediaQueryWidth="100%"
                            />
                            <DividerContainer>
                                <Divider decorative orientation="vertical" />
                            </DividerContainer>
                            <div>
                                <div>
                                    <Images
                                        imageData={imageData}
                                        handleRemoveImage={handleRemoveImage}
                                        handleDragEnd={handleDragEnd}
                                        isUpdatingSort={isUpdatingSort}
                                    >
                                        <ImageDropZoneNoText onDrop={onImageUpload} />
                                    </Images>
                                </div>
                                <div>
                                    <StyledH3>{L('extra_information')}</StyledH3>
                                    <ErrorAndInputWrapper>
                                        <Label title={L('created_at')} />
                                        <Input
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(spaceData?.created_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper>
                                        <Label title={L('updated_at')} />
                                        <Input
                                            type="datetime-local"
                                            readOnly
                                            value={dayjs(spaceData?.updated_at).format('YYYY-MM-DDTHH:mm')}
                                        />
                                    </ErrorAndInputWrapper>
                                </div>
                            </div>
                        </div>
                    )
                }
            />
        </>
    );
};

export default function SpaceDetailPage() {
    const { id: projectId, propertyId, spaceId } = useParams<{ id: string; propertyId: string; spaceId: string }>();
    return projectId && propertyId && spaceId ? (
        <SpaceDetailComponent projectId={projectId} propertyId={propertyId} spaceId={spaceId} />
    ) : null;
}
