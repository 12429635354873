import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useDeletePropertyTemplateDocs = () => {
    return useMutation(async ({ documentIds, propertyId }: { documentIds: number[]; propertyId: number }) => {
        return await Promise.all(
            documentIds.map(async (docId) => {
                return await trpc.TemplatesApi.removePropertyTemplateDocument.mutate({
                    id: docId,
                    propertyId,
                });
            }),
        );
    });
};

export default useDeletePropertyTemplateDocs;
