import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import { RouterOutput } from '../../../../lib/trpc';
import TableImageWithFallback from '../TableImageWithFallback';
import { fontSize } from '../../../../components/Shared/Style/typography';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom-v5-compat';
import { colors } from '@ourliving/ourliving-ui';

const columnHelper = createColumnHelper<RouterOutput['BindersApi']['getProjectsOnStatus'][0]>();

type PillProps = { statusColor: string; textColor: string };

const Pill = styled.span(({ statusColor, textColor }: PillProps) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: statusColor,
        minWidth: '4.5rem',
        maxWidth: '7rem',
        padding: '0.4rem 0.35rem',
        borderRadius: '15px',
        color: textColor,
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };
});

const CellContainer = styled.div({
    display: 'grid',
    maxWidth: '100%',
    fontSize: fontSize.seta,
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '100%',
    gridTemplateRows: '1fr 1fr 1fr',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const BoldSpan = styled.span({
    fontWeight: 'bold',
});

const SmallerTextSpan = styled.span({
    fontSize: fontSize.milli,
});

const StyledLink = styled(Link)({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
        textDecoration: 'underline',
    },
});

const columns = [
    columnHelper.display({
        id: 'image',
        cell: ({ row }) => (
            <div style={{ width: '100px', aspectRatio: '4/3' }}>
                <TableImageWithFallback id={row.original?.project_image_data?.original?.id} />
            </div>
        ),
        enableResizing: false,
    }),
    columnHelper.accessor('name', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>
                    <StyledLink to={`${row.original.id}/properties`}>
                        <BoldSpan>{L(row.original.name)}</BoldSpan>
                    </StyledLink>
                </Data>
                {row.original.address && (
                    <Data>
                        <SmallerTextSpan>{L(row.original.address)}</SmallerTextSpan>
                    </Data>
                )}
                {row.original.commune && <Data>{L(row.original.commune)}</Data>}
            </CellContainer>
        ),
        minSize: 135,
        size: 150,
        header: () => <Flex>{L('project_name')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('username', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>
                    {row.original?.username ? (
                        <>{row.original?.username}</>
                    ) : row.original?.email ? (
                        <>{row.original?.email}</>
                    ) : (
                        <></>
                    )}
                </Data>
                <Data>
                    <SmallerTextSpan>{dayjs(row.original.created_at).format('YYYY-MM-DD HH:MM')}</SmallerTextSpan>
                </Data>
            </CellContainer>
        ),
        minSize: 90,
        size: 150,
        header: () => <Flex>{L('created')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('project_owner', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{row.original.project_owner}</Data>
            </CellContainer>
        ),
        minSize: 80,
        size: 150,
        header: () => <Flex>{L('project_owner')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('type_name', {
        cell: ({ row }) => (
            <CellContainer>
                <Data>{L(row.original?.type_name)}</Data>
            </CellContainer>
        ),
        minSize: 80,
        size: 150,
        header: () => <Flex>{L('project_type_translation')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('property_count', {
        cell: ({ row }) => {
            const propertyCount = !row.original.property_count ? 0 : Number(row.original.property_count);
            const publishedCount = row.original.published_count;

            const statusColor =
                propertyCount === publishedCount
                    ? colors.all.brandGreen
                    : publishedCount > 0
                      ? colors.all.sunSignal
                      : colors.all.errorSignal;

            return (
                <CellContainer style={{ marginLeft: 'auto' }}>
                    <Data>
                        <Pill
                            statusColor={statusColor}
                            textColor={colors.textColor.white}
                        >{`${publishedCount} / ${propertyCount}`}</Pill>
                    </Data>
                </CellContainer>
            );
        },
        size: 150,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
        header: () => <Flex>{L('property_count')}</Flex>,
    }),
];

export default columns;
