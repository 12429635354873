import React, { useState } from 'react';
import AdminList from '../components/AdminList';
import AdminForm from '../components/AdminForm';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import TopAreaContainer from '../../../binders/components/TopAreaContainer';
import CardTitle from '../../../../components/typography/CardTitle';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';
import useAddAdmin from '../hooks/useAddAdmin';
import { margin } from '../../../../components/Shared/Style/margin';
import { ListAdminsByAccountId } from '@app/trpc-api/models/Members';
import useInviteAdmins from '../hooks/useInviteAdmins';
import useRemoveAdmins from '../hooks/useRemoveAdmins';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import Dialog from '../../../../components/Dialog/Dialog';

const AdminSettingsPage = () => {
    const roleOptions = [
        { value: 'project_owner', label: L('project_owner') },
        { value: 'helpdesk', label: L('helpdesk') },
        { value: 'admin', label: L('admin') },
    ];

    const [selectedAdmins, setSelectedAdmins] = useState<ListAdminsByAccountId[]>([]);
    const { mutate: inviteAdmins } = useInviteAdmins();

    const [open, setOpen] = useState(false);
    const { mutate: addAdmin } = useAddAdmin();
    const { mutate: removeAdmins } = useRemoveAdmins();

    const handleInviteAdmins = (admin: ListAdminsByAccountId[]) => {
        const ids = admin.map((admin) => admin.id);
        inviteAdmins(ids, {
            onSuccess: () => {
                if (ids.length === 1) toast.success(L('invited_admin'));
                if (ids.length > 1) toast.success(L('invited_admins'));
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    const handleRemoveAdmins = (admin: ListAdminsByAccountId[]) => {
        const ids = admin.map((admin) => admin.id);
        removeAdmins(ids, {
            onSuccess: () => {
                if (ids.length === 1) toast.success(L('removed_admin'));
                if (ids.length > 1) toast.success(L('removed_admins'));
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    const onSubmit = (data: { email: string; role: string; name: string }) => {
        addAdmin(data, {
            onSuccess: () => {
                toast.success(L(`${data.role}_added`));
                setOpen(false);
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        });
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <AdminForm roleOptions={roleOptions} onSubmit={onSubmit} />
            </Dialog>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{L('admins')}</CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                            <ConfirmationButton
                                disabled={!Boolean(selectedAdmins.length > 0)}
                                props={{
                                    onConfirm: () => {
                                        handleRemoveAdmins(selectedAdmins);
                                    },
                                    confirmVariant: 'danger',
                                }}
                            >
                                {L('remove')}
                            </ConfirmationButton>
                            <Button
                                disabled={!Boolean(selectedAdmins.length > 0)}
                                onClick={() => handleInviteAdmins(selectedAdmins)}
                            >
                                {L('invite')}
                            </Button>
                            <Button onClick={() => setOpen(true)}>{L('new_admin')}</Button>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <AdminList
                        handleSetSelectedAdmins={(params: ListAdminsByAccountId[]) => {
                            setSelectedAdmins(params);
                        }}
                    />
                }
            />
        </>
    );
};

export default AdminSettingsPage;
