import React, { useState } from 'react';
import { ProjectMailTemplateStore } from './ProjectMailTemplateStore';
import { Loading } from '../../../components/Loading/Loading';
import { Content } from '../../../components/Layout/Content';
import { useObserver } from 'mobx-react';
import { ProjectMailTemplateList } from './ProjectMailTemplatesList';
import { usePromise } from '../../../lib/react';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';

interface Props {
    location: { search: string };
}

const DropdownBackground = styled.div({
    backgroundColor: 'white',
    width: '25%',
    marginBottom: style.margin.m
});

export const ProjectMailTemplatePage = () => {
    const [project, setProject] = useState('');

    const store = ProjectMailTemplateStore;

    const onChange = (id: string) => {
        setProject(id);
        store.selectedProjectId = id;
    };

    const [ready] = usePromise(async () => {
        await store.loadProjects();
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return (
            <>
                <DropdownBackground>
                    <Dropdown
                        options={store.projects.map(p => ({ label: p.name, value: p.id }))}
                        onChange={value => onChange(value)}
                        value={project}
                    />
                </DropdownBackground>
                <ProjectMailTemplateList />
            </>
        );
    });
};
