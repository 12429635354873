import styled from '@emotion/styled';
import React from 'react';
import { L } from '../../../lib/i18n';
import useGetProjects from '../hooks/useGetProjects';
import ProjectComponent from './ProjectComponent';

const Header = styled.h1({
    fontSize: '1.5rem',
    fontWeight: 400,
    marginLeft: '12px',
    ['@media screen and (min-width: 1024px)']: {
        marginLeft: '0px'
    }
});

const Projects = () => {
    const { data: projects } = useGetProjects();

    if (!projects) return null;
    

    return (
        <>
            <Header>{L('Projekt')}</Header>
            {projects && (
                <>
                    {projects.length > 0 ? (
                        <>
                            {projects.map(project => (
                                <ProjectComponent project={project} key={project.id} />
                            ))}
                        </>
                    ) : (
                        'Här var det tomt.'
                    )}
                </>
            )}
        </>
    );
};

export default Projects;
