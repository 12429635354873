import AccountContactsApi from '@app/api/public/AccountContactsApi';
import { useQuery } from 'react-query';

export const contactsQueryKey = {
    all: ['contact'],
    getContactById: (resourceId: number) => [...contactsQueryKey.all, 'detail', { resourceId }] as const,
    getContactList: () => [...contactsQueryKey.all, 'list'] as const
};

const getContactList = () => {
    return AccountContactsApi.getList(1);
};

const useGetContactList = () => {
    return useQuery(contactsQueryKey.getContactList(), () => getContactList());
};

const getContactById = (id: number) => {
    return AccountContactsApi.getbyid(+id);
};

const useGetContactById = (id: number) => {
    return useQuery(contactsQueryKey.getContactById(id), () => getContactById(id), {
        enabled: !!id
    });
};

export { useGetContactById, useGetContactList as default };
