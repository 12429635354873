import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import SmallerTable from '../../../../components/V8Tables/SmallerTable';
import triggerDirtyFieldsToast from '../../../../components/Forms/DirtyFieldsToast';
import { createColumnHelper, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { Icon } from '../../../../components/Icon/Icon';
import DocGroupUpdateWrapper from './DocGroupUpdateWrapper';
import { Button } from '@ourliving/ourliving-ui';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useGetDocumentGroups } from '../../hooks/DocumentGroups/documentGroupQueries';
import DocumentGroupForm from './DocumentGroupForm';
import useAddDocumentGroup from '../../hooks/DocumentGroups/useAddDocumentGroup';
import useRemoveProjectDocumentGroup from '../../hooks/DocumentGroups/useRemoveProjectDocumentGroup';
import toast from 'react-hot-toast';
import { SubmitHandler } from 'react-hook-form';
import FilterSearchContainer from '../../../binders/components/emotion/Filters/FilterSearchContainer';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { style } from '../../../../components/Shared/Style';
import { useSearchParams } from 'react-router-dom-v5-compat';
import Dialog from '../../../../components/Dialog/Dialog';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

type DocumentGroup = { id: number; name: string; projectId: number };
const columnHelper = createColumnHelper<DocumentGroup>();

const COLUMNS = (handleRemoveDocumentGroup: (params: { id: number; projectId: number; name: string }) => void) => {
    return [
        columnHelper.accessor((row) => row.name, {
            id: 'name',
            cell: (info) => (
                <CellContainer>
                    <Data>{info.getValue()}</Data>
                </CellContainer>
            ),
            header: () => <Flex>{L('name')}</Flex>,
            minSize: 110,
            size: 150,
            meta: {
                styles: {
                    flex: '1 0 auto',
                },
            },
        }),
        columnHelper.accessor((row) => row.name, {
            header: '',
            enableSorting: false,
            id: 'remove_group',
            cell: ({ row }) => (
                <CellContainer style={{ width: '100%', justifyContent: 'end' }}>
                    <Data
                        onClick={(e) => {
                            e.stopPropagation();
                            return triggerDirtyFieldsToast({
                                title: `${L('confirmation_document_group_delete')} ${row.original.name}`,
                                continueButtonText: L('yes'),
                                continueFn: () => {
                                    handleRemoveDocumentGroup({
                                        ...row.original,
                                    });
                                },
                                id: row.original.id.toString(),
                            });
                        }}
                    >
                        <Icon.TrashCan style={{ alignSelf: 'center' }} />
                    </Data>
                </CellContainer>
            ),
            minSize: 50,
            size: 50,
            meta: {
                styles: {
                    display: 'flex',
                },
            },
        }),
    ];
};

const DocumentListEmptyState = ({ search }: { search: string }) => {
    if (search) {
        return (
            <EmptyState>
                <EmptyState.Title>{L('no_document_groups_found')}</EmptyState.Title>
                <EmptyState.Description>
                    <p>{L('no_document_groups_found_description')}</p>
                    <p>{L('please_try_something_else')}</p>
                </EmptyState.Description>
            </EmptyState>
        );
    }

    return (
        <EmptyState>
            <EmptyState.Title>{L('no_document_groups')}</EmptyState.Title>
            <EmptyState.Description>
                <p>{L('get_started_by_clicking')}</p>
            </EmptyState.Description>
        </EmptyState>
    );
};

type Props = {
    projectName: string;
    projectId: number;
};

const DocumentGroupList = ({ projectName, projectId }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';

    const { data } = useGetDocumentGroups({ templateId: projectId, search: search });
    const documentGroups = useMemo(() => data || [], [data]);

    const { mutate: addDocumentGroup } = useAddDocumentGroup();

    const [openNewDocGroup, setOpenNewDocGroup] = useState(false);

    const [selectedDocumentGroup, setSelectedDocumentGroup] = useState<{
        id: number;
        projectId: number;
        name: string;
    }>();

    const { mutate: removeDocumentGroup } = useRemoveProjectDocumentGroup();

    const handleRemoveDocumentGroup = useCallback(
        (params: { id: number; projectId: number }) => {
            removeDocumentGroup(params);
        },
        [removeDocumentGroup],
    );

    const columns = useMemo(() => COLUMNS(handleRemoveDocumentGroup), [handleRemoveDocumentGroup]);
    const onDocumentGroupSubmit: SubmitHandler<{ name: string }> = ({ name }) => {
        addDocumentGroup(
            { name, projectId },
            {
                onSuccess: () => {
                    setOpenNewDocGroup(false);
                    toast.success(L('document_group_added'));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const handleSearch = (value: string) => {
        setSearchParams((prev) => {
            if (!value) {
                prev.delete('search');
            } else {
                prev.set('search', value);
            }
            return prev;
        });
    };

    const table = useReactTable({
        data: documentGroups,
        columns,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
    });

    return (
        <>
            <Dialog open={openNewDocGroup} onOpenChange={setOpenNewDocGroup}>
                <DocumentGroupForm heading={L('new_document_group')} onSubmit={onDocumentGroupSubmit} />
            </Dialog>

            <Dialog
                open={!!selectedDocumentGroup}
                onOpenChange={(open) => !open && setSelectedDocumentGroup(undefined)}
            >
                {selectedDocumentGroup && (
                    <DocGroupUpdateWrapper
                        onSuccess={() => setSelectedDocumentGroup(undefined)}
                        {...selectedDocumentGroup}
                    />
                )}
            </Dialog>

            <div>
                <StyledH3>{`${L('document_groups_for')} ${projectName}`}</StyledH3>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingBottom: '22px',
                    }}
                >
                    <FilterSearchContainer>
                        <div style={{ marginLeft: 'auto' }}>
                            <GlobalFilter filter={search} setFilter={handleSearch} placeholder={L('search')} />
                        </div>
                    </FilterSearchContainer>
                    <Button onClick={() => setOpenNewDocGroup(true)}>{L('new_document_group')}</Button>
                </div>
                <SmallerTable table={table} rowClickHandler={(row) => setSelectedDocumentGroup({ ...row.original })} />
                {documentGroups && documentGroups?.length === 0 && <DocumentListEmptyState search={search} />}
            </div>
        </>
    );
};

export default DocumentGroupList;
