import { useMutation, useQueryClient } from 'react-query';
import MaintenancesApi from '@app/api/public/MaintenancesApi';
import { useHistory, useParams } from 'react-router-dom';
import { getMaintenanceQueryKey } from './useGetMaintenance';

const deleteMaintenance = async (projectId: string, id: number) => {
    return MaintenancesApi.delete(+projectId, +id);
};

const useDeleteMaintenance = () => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const { projectId } = useParams<{ projectId: string }>();
    return useMutation((maintenance: { id: number }) => deleteMaintenance(projectId, maintenance.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(getMaintenanceQueryKey);
            history.push(`/maintenance/${projectId}`);
        }
    });
};

export default useDeleteMaintenance;
