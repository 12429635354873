import { useMutation, useQueryClient } from 'react-query';
import { AddProduct } from '../../schemas';
import { trpc } from '../../../../lib/trpc';
import { getProductsKeys } from '../productQueries';

export default function useUpdateProduct() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddProduct; productId: number }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.BindersApi.updateInventoryMaterial.query({ data: dataToSave, entityId: options.productId });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(getProductsKeys.all);
            },
        },
    );
}
