import * as React from 'react';
import { Menu, styleVariables } from '@ourliving/ourliving-ui';
import { MenuData } from './MenuData';
import { mobileBreakpoint } from '../Layout/Page';
import styled from '@emotion/styled';

const MenuContainer = styled.div(() => {
    return {
        height: `calc(100vh - ${styleVariables.header.height})`,
        overflowY: 'hidden',
        margin: '0',
        padding: '0',

        [`@media screen and (max-width: ${mobileBreakpoint})`]: {
            position: 'absolute',
            top: styleVariables.header.height,
            zIndex: 1,
            height: `calc(100vh - ${styleVariables.header.height})`,
        },
    };
});

export const MainMenu = () => {
    return (
        <MenuContainer tabIndex={-1}>
            <Menu>
                <MenuData />
            </Menu>
        </MenuContainer>
    );
};
