import React, { useCallback, useState } from 'react';
import ContentInformationList from '../../components/ContentInformation/ContentInformationList';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { margin } from '@app/shared/lib/Style/margin';
import { Button } from '@ourliving/ourliving-ui';
import { Link } from 'react-router-dom-v5-compat';
import useRemoveContentInfo from '../../hooks/ContentInformation/useRemoveContentInfo';

const ContentInformationPage = () => {
    const { mutate: removeContentInfo } = useRemoveContentInfo();
    const [numberOfItems, setNumberOfItems] = useState<number | null>(null);
    const handleRemoveContentInfo = useCallback(
        (params: { projectId: number; informationId: number }) => {
            removeContentInfo(params);
        },
        [removeContentInfo],
    );

    return (
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: margin.m }}>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>
                            {L('information_content')} {numberOfItems !== null ? `(${numberOfItems})` : ''}
                        </CardTitle>
                        <Link to="new">
                            <Button>{L('new_content_information')}</Button>
                        </Link>
                    </div>
                }
                mainArea={
                    <ContentInformationList
                        handleRemoveContentInfo={handleRemoveContentInfo}
                        handleSetNumberOfItems={(val) => setNumberOfItems(val)}
                    />
                }
            />
        </div>
    );
};

export default ContentInformationPage;
