import React from 'react';
import { IPageOf } from '@app/api/lib/db';

interface PagerProps {
    page: IPageOf<any>;
    onChangePage(newPage: number): void;
}

export function Pagination(props: PagerProps) {
    if (props.page.pages <= 1) return null;
    const canGoBack = props.page.currentPage > 1;
    const canGoForwards = props.page.currentPage < props.page.pages;
    const goBack = () => props.onChangePage(props.page.currentPage - 1);
    const goForward = () => props.onChangePage(props.page.currentPage + 1);

    return (
        <div>
            <button disabled={!canGoBack} onClick={goBack}>
                &laquo;
            </button>
            <span>
                Page {props.page.currentPage} of {props.page.pages}
            </span>
            <button disabled={!canGoForwards} onClick={goForward}>
                &raquo;
            </button>
        </div>
    );
}
