import AccountContactsApi from '@app/api/public/AccountContactsApi';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { L } from '../../../lib/i18n';
import { contactsQueryKey } from './useGetContacts';

const removeContact = (contact: { id: number }) => {
    return AccountContactsApi.remove(contact.id);
};

const useRemoveContact = () => {
    const queryClient = useQueryClient();
    return useMutation((contact: { id: number }) => removeContact(contact), {
        onError: err => {
            /* Shows error message */
            const error = typeof err === 'string' ? err : new Error(L('reset_error')).message;
            const message = error;
            toast.error(`${L(message)}`);
        },
        onSettled: () => {
            queryClient.invalidateQueries(contactsQueryKey.getContactList());
        }
    });
};

export default useRemoveContact;
