import React, { useEffect } from 'react';
import { L } from '../../lib/i18n';
import { Button } from '@ourliving/ourliving-ui';
import styled from '@emotion/styled';

type ConfirmationButtonProps = {
    children?: React.ReactNode;
    props: {
        onConfirm: () => void;
        confirm?: React.ReactNode;
        confirmVariant?: React.ComponentProps<typeof Button>['variant'];
        idleVariant?: React.ComponentProps<typeof Button>['variant'];
        shouldPreventDefault?: boolean;
        stopPropagation?: boolean;
    };
} & Omit<React.ComponentProps<typeof Button>, 'children'>;

const GridContainer = styled.span({
    gridTemplate: ` 'area' `,
});

const ConfirmationButton = ({
    props: {
        onConfirm,
        confirm,
        idleVariant = 'primary',
        confirmVariant = 'danger',
        shouldPreventDefault = false,
        stopPropagation = false,
    },
    children,
    ...rest
}: ConfirmationButtonProps) => {
    const [isConfirmed, setIsConfirmed] = React.useState(false);
    const ref = React.useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Node;
            if (target && ref.current && !ref.current.contains(target)) {
                setIsConfirmed(false);
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [ref]);

    return isConfirmed ? (
        <Button
            {...rest}
            ref={ref}
            onClick={(e) => {
                if (shouldPreventDefault) e.preventDefault();
                if (stopPropagation) e.stopPropagation();
                onConfirm();
            }}
            variant={confirmVariant}
        >
            <GridContainer style={{ display: 'grid' }}>
                <span style={{ visibility: 'hidden', gridArea: 'area' }}>{children}</span>
                <span style={{ gridArea: 'area' }}>{confirm ?? L('confirm')}</span>
            </GridContainer>
        </Button>
    ) : (
        <Button
            {...rest}
            onClick={(e) => {
                if (shouldPreventDefault) e.preventDefault();
                if (stopPropagation) e.stopPropagation();
                setIsConfirmed(true);
            }}
            variant={idleVariant}
        >
            {children ?? L('delete')}
        </Button>
    );
};

export default ConfirmationButton;
