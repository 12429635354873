import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { LocalPropertyDocument } from '../../binders/schemas';
import styled from '@emotion/styled';
import { useGetPropertyDocumentTypes } from '../../binders/hooks/propertyHooks/propertyQueries';
import { useGetDocumentSubtypes } from '../../binders/hooks/documentTypeQueries';
import { FileTypeIcon } from '../../../components/Icon/Icon';
import { L } from '../../../lib/i18n';
import { CrossIcon } from '../../binders/components/emotion/CrossIcon';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';

const columnHelper = createColumnHelper<LocalPropertyDocument>();

export type TableMeta = {
    removeRow: (id: string) => void;
    clearRows: () => void;
    numberOfDocs: number;
};

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const CanBtn = styled.div({
    [':hover']: {
        cursor: 'pointer',
    },
});

const useCopyDocumentsListColumns = () => {
    const { data: documentTypes } = useGetPropertyDocumentTypes();
    const { data: documentSubtypes } = useGetDocumentSubtypes();
    const columns = useMemo(
        () => [
            columnHelper.display({
                id: 'file_type',
                cell: ({ row }) => (
                    <CellContainer>
                        <FileTypeIcon fileType={row.original.file.type} />
                    </CellContainer>
                ),
                size: 50,
                meta: {
                    styles: {
                        flex: '0 0 auto',
                    },
                },
                enableResizing: false,
            }),
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('name')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.document_type, {
                id: 'document_type',
                cell: ({ row }) => {
                    const typeName = documentTypes?.find((type) => type.id === row.original.document_type)?.name;
                    return (
                        <CellContainer>
                            <Data>{typeName ? L(typeName) : ''}</Data>
                        </CellContainer>
                    );
                },
                header: () => <Flex>{L('category')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.document_subtype, {
                id: 'document_subtype',
                cell: ({ row }) => {
                    const typeName = documentSubtypes?.find((type) => type.id === row.original.document_subtype)?.name;
                    return (
                        <CellContainer>
                            <Data>{typeName ? L(typeName) : ''}</Data>
                        </CellContainer>
                    );
                },
                header: () => <Flex>{L('sub_category')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.display({
                id: 'id',
                header: ({ table }) => {
                    const tableMeta = table.options.meta as TableMeta;
                    return (
                        <ConfirmationButton
                            style={{ marginLeft: 'auto' }}
                            props={{
                                onConfirm: () => {
                                    tableMeta.clearRows();
                                },
                            }}
                            disabled={tableMeta.numberOfDocs === 0}
                        >
                            {L('clear')}
                        </ConfirmationButton>
                    );
                },
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
                enableResizing: false,
                cell: ({ row, table }) => (
                    <CellContainer style={{ justifyContent: 'flex-end', width: '100%', paddingRight: '1rem' }}>
                        <CanBtn
                            onClick={() => (table.options.meta as TableMeta).removeRow(row.original.id)}
                            role="button"
                        >
                            <CrossIcon />
                        </CanBtn>
                    </CellContainer>
                ),
            }),
        ],
        [documentTypes, documentSubtypes],
    );
    return columns;
};

export default useCopyDocumentsListColumns;
