import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const ContentInformationQueryKeys = {
    all: ['templates-content-information'] as const,
    lists: () => [...ContentInformationQueryKeys.all, 'list'] as const,
    list: (params: { templateId?: number; search: string }) =>
        [...ContentInformationQueryKeys.lists(), params] as const,
    drafts: () => [...ContentInformationQueryKeys.all, 'draft'] as const,
    draft: (params: { search: string }) => [...ContentInformationQueryKeys.drafts(), params] as const,
    details: () => [...ContentInformationQueryKeys.all, 'detail'] as const,
    detail: (params: { templateId: number; contentInfoId: number }) =>
        [...ContentInformationQueryKeys.details(), params] as const,
    orgDetail: (params: { contentInfoId: number }) => [...ContentInformationQueryKeys.details(), params] as const,
    docs: (id: number, projectId: number) => [...ContentInformationQueryKeys.all, 'docs', id, projectId] as const,
    allDocs: (id: number) => [...ContentInformationQueryKeys.all, 'docs', id] as const,
    docGroups: (id: number, projectId: number) =>
        [...ContentInformationQueryKeys.all, 'docGroups', id, projectId] as const,
    allDocGroups: (id: number) => [...ContentInformationQueryKeys.all, 'docGroups', id] as const,
};

export function useListContentInformationItems({ templateId, search }: { templateId: number; search: string }) {
    return useQuery(
        [...ContentInformationQueryKeys.list({ templateId, search })],
        async () =>
            trpc.ContentInformationApi.listContentInformationItems.query({
                projectId: templateId,
                search,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useListContentOrgInformationItems({ search }: { search: string }) {
    return useQuery(
        ContentInformationQueryKeys.list({ search }),
        async () =>
            trpc.ContentInformationApi.listContentOrgInformationItems.query({
                search,
                type: 'published',
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useListDraftContentOrgInformationItems({ search }: { search: string }) {
    return useQuery(
        ContentInformationQueryKeys.draft({ search }),
        async () =>
            trpc.ContentInformationApi.listContentOrgInformationItems.query({
                search,
                type: 'draft',
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetContentInformationItem(params: { templateId: number; contentInfoId: number }) {
    return useQuery(
        [...ContentInformationQueryKeys.detail(params)],
        async () =>
            trpc.ContentInformationApi.getContentInformationItem.query({
                contentInfoId: params.contentInfoId,
                projectId: params.templateId,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetContentOrgInformationItem(params: { contentInfoId: number }) {
    return useQuery(
        [...ContentInformationQueryKeys.orgDetail(params)],
        async () =>
            trpc.ContentInformationApi.getContentOrgInformationItem.query({
                contentInfoId: params.contentInfoId,
            }),
        {
            keepPreviousData: true,
        },
    );
}
