import React, { useCallback, useState } from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Link } from 'react-router-dom-v5-compat';
import { useRemoveContentOrgInfo } from '../../project-templates/hooks/ContentInformation/useRemoveContentInfo';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import ContentInformationList from '../components/ContentInformation/ContentInformationList';
import { L } from '../../../lib/i18n';
import H1 from '../../../components/typography/H1';
import BigTabMenu from '../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';

const ContentInformationPage = () => {
    const { mutate: removeContentInfo } = useRemoveContentOrgInfo();
    const [numberOfItems, setNumberOfItems] = useState<number | null>(null);
    const handleRemoveContentInfo = useCallback(
        (params: { informationId: number }) => {
            removeContentInfo(params);
        },
        [removeContentInfo],
    );

    return (
        <>
            <H1>{L('content_information')}</H1>

            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to={'/information-settings'}>
                        {L('all_published')}
                    </BigNavItem>
                    <BigNavItem end to={'/information-settings/draft'}>
                        {L('draft')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>
                            {L('all_information_published')} {numberOfItems !== null ? `(${numberOfItems})` : ''}
                        </CardTitle>
                        <Link to="new">
                            <Button>{L('new_content_information')}</Button>
                        </Link>
                    </div>
                }
                mainArea={
                    <ContentInformationList
                        handleRemoveContentInfo={handleRemoveContentInfo}
                        handleSetNumberOfItems={(val) => setNumberOfItems(val)}
                    />
                }
            />
        </>
    );
};

export default ContentInformationPage;
