import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';

const getServicePartnerById = async (projectId: string, id: string) => {
    return await ServicePartnersApi.getbyid(+projectId, +id);
};

const useGetServicePartnerById = () => {
    const { id, projectId } = useParams<{ id: string; projectId: string }>();
    return useQuery(['getServicePartnerById', { id, projectId }], () => getServicePartnerById(projectId, id));
};

export default useGetServicePartnerById;
