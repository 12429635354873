import { observable } from 'mobx';
import AccountSettingsApi from '@app/api/public/AccountSettingsApi';
import { createValidator, required, ValidationErrors } from '../../../lib/validation';
import { AccountMailTemplate } from '@app/api/models/AccountSettings';
import SettingsApi from '@app/api/public/SettingsApi';
import { L } from '../../../lib/i18n';

const mailTemplateValidator = createValidator({
    templateName: [required()],
    file: [required()]
});

class AccountMailTemplateStoreClass {
    @observable templates: AccountMailTemplate[] = [];
    @observable template: Partial<AccountMailTemplate> = {};
    @observable selectedTemplateId: string;

    @observable newMailTemplateFile: Partial<AccountMailTemplate> = {};

    @observable errors: ValidationErrors = {};

    async load() {
        this.templates = await AccountSettingsApi.getAccountMailTemplatesList();
    }

    async setMailTemplateFile(file: File) {
        this.template.mail_template_id = this.selectedTemplateId;
        this.template.file = file;
    }

    async downloadTemplate(data: string, name: string) {
        if (data != undefined) {
            return new File([data], `${L(name)}.mjml`, {
                type: 'text/plain'
            });
        }
    }

    async previewMailWithPlaceholders() {
        return await SettingsApi.previewMailWithPlaceholders(this.template.data as string);
    }

    async uploadMailTemplate() {
        const result = mailTemplateValidator.test(this.template);
        this.errors = result.errors;
        const t = this.templates.find(t => t.mail_template_id === this.selectedTemplateId);
        if (t && t.id) this.template.id = t.id;
        const r = await AccountSettingsApi.uploadAccounMailTemplate(this.template);
        this.template = {} as AccountMailTemplate;
        this.load();
        return r;
    }
}

export const AccountMailTemplateStore = new AccountMailTemplateStoreClass();
