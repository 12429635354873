import React from 'react';
import { Route } from 'react-router-dom';
import { Notifications } from './Notifications';
import { ProfileSettingsPage } from './ProfileSettingsPage';

export const routes = [
    <Route key="0" exact path="/profile/settings" component={ProfileSettingsPage} />,
    <Route key="1" exact path="/profile/notifications" component={Notifications} />,
];

export const navigation = [{ name: 'Profile', link: '/Profile' }];
