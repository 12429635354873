import styled from '@emotion/styled';

export default styled.div({
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
    padding: '1rem',
    paddingLeft: '0',
    ['@media screen and (max-width: 992px)']: {
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column'
    }
});
