import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import * as Dialog from '@radix-ui/react-dialog';
import styled from '@emotion/styled';
import NewContactModal from '../../binders/components/Contacts/NewContactModal';
import ContactsList from '../../binders/components/Contacts/ContactsList';

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const PropertyContactsTemplatePage = () => {
    const { id: propertyId } = useParams<{ id: string }>();
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Dialog.Root open={!!open} onOpenChange={setOpen}>
                <Dialog.Portal>
                    <Overlay>
                        <Content>
                            <NewContactModal property_id={+propertyId} handleToggleModal={() => setOpen(!open)} />
                        </Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <ContactsList handleToggleModal={() => setOpen(!open)} propertyId={+propertyId} />
        </>
    );
};

export default PropertyContactsTemplatePage;
