import { SortingState } from '@tanstack/react-table';
import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../lib/trpc';

export const getPropertyTemplatesKeys = {
    all: ['templates-properties'] as const,
    lists: () => [...getPropertyTemplatesKeys.all, 'list'] as const,
    list: (params: { sorting: SortingState; search: string }) => [...getPropertyTemplatesKeys.lists(), params] as const,
    listInfinite: (params: { sorting: SortingState; search: string }) =>
        [...getPropertyTemplatesKeys.lists(), 'infinite', params] as const,
    details: () => [...getPropertyTemplatesKeys.all, 'detail'] as const,
    detail: (templateId: number) => [...getPropertyTemplatesKeys.details(), templateId] as const,
    documents: () => [...getPropertyTemplatesKeys.all, 'documents'] as const,
    documentsByTemplateId: (templateId: number) => [...getPropertyTemplatesKeys.documents(), templateId] as const,
    images: () => [...getPropertyTemplatesKeys.all, 'images'] as const,
    imagesByPropertyId: (propertyId: number) => [...getPropertyTemplatesKeys.images(), propertyId, 'images'] as const,
};

export const getTemplatePropSpaceKeys = {
    all: ['template-prop_spaces'] as const,
    lists: () => [...getTemplatePropSpaceKeys.all, 'list'] as const,
    list: (property_id: number) => [...getTemplatePropSpaceKeys.lists(), property_id] as const,
    details: () => [...getTemplatePropSpaceKeys.all, 'detail'],
    detail: (spaceId) => [...getTemplatePropSpaceKeys.details(), spaceId],
};

export function useGetInfinitePropertyTemplates({ sorting, search }: { sorting: SortingState; search: string }) {
    return useInfiniteQuery(
        [...getPropertyTemplatesKeys.listInfinite({ sorting, search })],
        async ({ pageParam = 1 }) =>
            trpc.TemplatesApi.getPropertyTemplatesPaginated.query({ page: pageParam, sorting, search }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}

export function useListPropertyTemplates({ sorting, search }: { sorting: SortingState; search: string }) {
    return useQuery(
        [...getPropertyTemplatesKeys.list({ sorting, search })],
        async () => trpc.TemplatesApi.getPropertyTemplates.query({ sorting, search }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropertyTemplateById(templateId: number) {
    return useQuery(
        [...getPropertyTemplatesKeys.detail(templateId)],
        async () => trpc.TemplatesApi.getPropertyTemplateById.query(templateId),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropertyTemplateDocuments(propertyId: number) {
    return useQuery(
        [...getPropertyTemplatesKeys.documentsByTemplateId(propertyId)],
        async () =>
            trpc.TemplatesApi.listPropertyTemplateDocuments.query({
                propertyId,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropertyTemplateImages(propertyId: number) {
    return useQuery(
        [...getPropertyTemplatesKeys.imagesByPropertyId(propertyId)],
        async () =>
            trpc.TemplatesApi.listPropertyTemplateImages.query({
                propertyId,
            }),
        {
            keepPreviousData: true,
        },
    );
}

export function useGetPropSpaces(property_id: number) {
    return useQuery(
        getTemplatePropSpaceKeys.list(property_id),
        () => trpc.TemplatesApi.listPropertySpaces.query({ propertyId: property_id }),
        {
            enabled: !!property_id,
            keepPreviousData: true,
        },
    );
}

export function useGetPropertySpace(spaceId: number, propertyId: number) {
    return useQuery(
        getTemplatePropSpaceKeys.detail(spaceId),
        () => trpc.TemplatesApi.getSpace.query({ spaceId, propertyId }),
        {
            keepPreviousData: true,
        },
    );
}
