import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyMemberKeys } from './propertyMemberQueries';

export default function useRemoveMembersFromProperty() {
    const client = useQueryClient();
    return useMutation(
        async (userIds: number[]) => {
            return await Promise.all(
                userIds.map(async (id) => {
                    return await trpc.MembersApi.removeUserFromProperty.mutate(id);
                }),
            );
        },
        {
            onSettled: () => {
                client.invalidateQueries(getPropertyMemberKeys.lists());
            },
        },
    );
}
