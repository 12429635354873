import * as React from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { Header } from '../../../../components/Layout/Header';
import { L } from '../../../../lib/i18n';
import { style } from '../../../../components/Shared/Style';
import styled from '@emotion/styled';
import { Loading } from '../../../../components/Loading/Loading';
import { useObserver } from 'mobx-react';
import { Table } from '../../../../components/Table/Table';
import { Card } from '../../../../components/Layout/Card';
import { NavLink } from 'react-router-dom';
import { Button } from '@ourliving/ourliving-ui';
import { useOnClickOutside } from '../../../../components/Functions/ClickOutsideArea';
import { CurrencyFormatter } from '../../../../components/Formatter/Formatter';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { AddonEndDate } from './Components/AddonEndDate';
import { AboveCard } from '../../../../components/Layout/AboveCard';
import { AddonPropertyFilterPanel } from './Components/AddonPropertyFilterPage';
import { Icon } from '../../../../components/Icon/Icon';
import { AddonAddMember } from './Components/AddonAddMember';
import { AddonMoreVertical } from './Components/AddonMoreVertical';
import useGetPropertyList from '../hooks/useGetPropertyList';
import { AddonInviteMembers } from './Components/AddonInviteMembers';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import OrderConfirmationCreatePdf from '../../../client-pdf/Confirmation/OrderConfirmationCreatePdf';
import useSendConfirmation from '../hooks/useSendConfirmation';
import { Property } from '@app/api/models/Properties';
import useSetEndDate from '../hooks/useSetEndDate';
import { getPropertyKeys } from '../hooks/useGetPropertyList';
import { useQueryClient } from 'react-query';
import useenableCheckout from '../hooks/useEnableCheckout';
import AddonOrderConfirmationButton from './Components/AddonOrderConfirmationButton';
import Spinner from '../../../../components/Spinner/Spinner';
import { DateWorklog } from '../../../../components/DateWorklog/Dateworklog';

const Text = styled.div({
    textAlign: 'left',
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.black,
});

const ContainerButton = styled.button({
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    '&:disabled': {
        cursor: 'default',
        span: {
            filter: 'grayscale(1)',
        },
    },
});

const Buttoncontainer = styled.div({
    display: 'flex',
    gap: '8px',
});

export function AddonPropertyListPage(props: RouteComponentProps<{ projectId: string }>) {
    const project_id = props.match.params.projectId;
    const { projectId } = useParams<{ projectId: string }>();

    const queryClient = useQueryClient();

    const { mutate: sendConfirmation, status: sendConfirmationStatus } = useSendConfirmation();

    const componentRef = useRef(null);

    const [filter, updateFilter] = React.useState(false);

    const propertyListQuery = useGetPropertyList(projectId, { isTemplate: filter });
    const { mutate: setEndDate } = useSetEndDate();
    const { mutate: setEnableCheckout } = useenableCheckout();

    const clickRef = React.useRef(null);

    const [isEndDateActive, setisEndDateActive] = React.useState(false);
    const [isInviteMemberActive, setIsInviteMemberActive] = React.useState(false);
    const [selectedProperties, setSelectedProperties] = React.useState<Set<Property>>(new Set<Property>());
    const [selectedEndDate, setSelectedEndDate] = React.useState('');

    const [isAddingMember, setIsAddingMember] = React.useState(false);
    const [isSettingsActive, setSettingsActive] = React.useState(false);
    const [selectedProperty, setSelectedProperty] = React.useState<undefined | Property>(undefined);
    const [loadingProperty, setLoadingProperty] = React.useState<number>();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle:
            propertyListQuery.data &&
            `Orderbekräftelse-${propertyListQuery?.data[0].project_name}-${propertyListQuery?.data[0]?.name}`,
    });

    useOnClickOutside(clickRef, () => {
        setisEndDateActive(false);
        setIsAddingMember(false);
        setSettingsActive(false);
        setIsInviteMemberActive(false);
    });

    const onSelect = (property: Property) => {
        const copySelectedProperties = new Set([...selectedProperties]);
        if (copySelectedProperties.has(property)) {
            copySelectedProperties.delete(property);
        } else {
            copySelectedProperties.add(property);
        }
        setSelectedProperties(copySelectedProperties);
    };

    const onSendConfirmationClick = () => {
        const variables = {
            propertyIds: Array.from(selectedProperties).map((p) => p.id),
            propertyName: Array.from(selectedProperties).map((p) => p.name),
        };
        sendConfirmation(variables);
    };

    useOnClickOutside(clickRef, () => {
        setSettingsActive(false);
    });

    const onEndDateClick = () => {
        setisEndDateActive(true);
    };

    const onEnabeCheckout = (event: any, propertyId: Property['id']) => {
        setEnableCheckout({ propertyId, enableCheckout: event.target.checked });
    };

    const onInviteMembersClick = () => {
        setIsInviteMemberActive(true);
    };

    const onAddMember = (property: Property) => {
        setSelectedProperty(property);
        setIsAddingMember(true);
    };

    const onMoreVertical = (property: Property) => {
        setSettingsActive(true);
        setSelectedProperty(property);
    };

    const onSelectedEndDate = (endDate: string) => {
        if (selectedProperties) {
            setEndDate({ propertyIds: Array.from(selectedProperties).map((p) => p.id), endDate: endDate });
            queryClient.refetchQueries(getPropertyKeys.all);
        }
        setisEndDateActive(false);
    };

    return useObserver(() => {
        if (propertyListQuery.status === 'loading') {
            return (
                <>
                    <Header title={L('properties')} />
                    <Loading />
                </>
            );
        }

        if (propertyListQuery.status === 'error') {
            return (
                <>
                    <Header title={L('properties')} />
                    <div>{L('properties_error')}</div>
                </>
            );
        }

        if (propertyListQuery.status === 'success') {
            const properties = propertyListQuery.data;
            const rows = properties.map((property) => ({
                select: (
                    <Checkbox
                        name="Slutdatum"
                        defaultChecked={selectedProperties.has(property)}
                        onChange={() => onSelect(property)}
                    />
                ),
                name: (
                    <StyledNavLink to={`${location.pathname}/property/${property.id}`}>
                        <Text>
                            {property.name}, {property.street} {property.city}
                            <br />
                            <DateWorklog date={property.addon_end_date} />
                        </Text>
                    </StyledNavLink>
                ),
                signed: (
                    <Text>
                        {property.no_signed_users} / {property.no_members}
                    </Text>
                ),
                amount: (
                    <Text>
                        <CurrencyFormatter amount={property.amount} />
                    </Text>
                ),
                confirmation: <AddonOrderConfirmationButton {...property} />,
                add_member: <Icon.AddMember onClick={() => onAddMember(property)} />,
                enable_checkout: (
                    <Checkbox
                        name="enable_checkout"
                        defaultChecked={property.addon_enable_checkout}
                        onChange={(e) => onEnabeCheckout(e, property.id)}
                    />
                ),
                settings_more: (
                    <ContainerButton
                        onClick={() => onMoreVertical(property)}
                        disabled={property.order_id ? false : true}
                    >
                        <Icon.OptionFilled />
                    </ContainerButton>
                ),
            }));

            return (
                <>
                    <Header title={L('properties')} />
                    <AboveCard justifyContent={'space-between'}>
                        <AddonPropertyFilterPanel onChange={updateFilter} filter={filter} />
                        <Buttoncontainer>
                            <Button
                                onClick={onEndDateClick}
                                style={{ marginLeft: 'auto', justifyContent: 'flex-end', alignSelf: 'flex-start' }}
                                variant={selectedProperties.size > 0 ? 'primary' : 'danger'}
                                disabled={selectedProperties.size > 0 ? false : true}
                            >
                                {L('addon_end_date')}
                            </Button>
                            <Button
                                onClick={onInviteMembersClick}
                                style={{ marginLeft: 'auto', justifyContent: 'flex-end', alignSelf: 'flex-start' }}
                                variant={selectedProperties.size > 0 ? 'primary' : 'danger'}
                                disabled={selectedProperties.size > 0 ? false : true}
                            >
                                {L('addon_invite_members')}
                            </Button>

                            <Button
                                onClick={onSendConfirmationClick}
                                style={{
                                    marginLeft: 'auto',
                                    justifyContent: 'flex-end',
                                    alignSelf: 'flex-start',
                                    position: 'relative',
                                }}
                                variant={selectedProperties.size > 0 ? 'primary' : 'danger'}
                                disabled={
                                    selectedProperties.size > 0 ? false : true || sendConfirmationStatus === 'loading'
                                }
                            >
                                {sendConfirmationStatus === 'loading' && (
                                    <span
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <Spinner />
                                    </span>
                                )}
                                <span
                                    style={{
                                        visibility: sendConfirmationStatus === 'loading' ? 'hidden' : 'visible',
                                    }}
                                >
                                    {L('addon_send_confirmation')}
                                </span>
                            </Button>
                            <StyledNavLink to={`/addons/project/${project_id}/report`}>
                                <Button style={{ marginRight: '0px' }}>{L('report')}</Button>
                            </StyledNavLink>
                        </Buttoncontainer>
                    </AboveCard>
                    <Card padding={`0px 0px ${style.margin.l}px 0px`}>
                        <Table
                            columns={[
                                {
                                    key: 'select',
                                    header: 'Välj',
                                    arrows: false,
                                    width: '0.2fr',
                                },
                                { key: 'name', header: L('name'), arrows: true, width: '1fr' },
                                { key: 'signed', header: L('signed'), arrows: true, width: '0.5fr' },
                                { key: 'amount', header: L('amount'), arrows: true, width: '0.5fr' },
                                { key: 'confirmation', header: L('order'), arrows: false, width: '0.5fr' },
                                { key: 'add_member', header: 'Medlem', arrows: false, width: '0.3fr' },
                                { key: 'enable_checkout', header: 'Utcheckning', arrows: false, width: '0.3fr' },
                                {
                                    key: 'settings_more',
                                    // header: L('settings'),
                                    arrows: false,
                                    width: '0.3fr',
                                },
                            ]}
                            data={rows}
                        />
                    </Card>
                    <AddonEndDate
                        propertyName={[...selectedProperties].map((p) => p.name).join(', ')}
                        forwardRef={clickRef}
                        isActive={isEndDateActive}
                        onClick={onSelectedEndDate}
                    />
                    <AddonInviteMembers
                        properties={Array.from(selectedProperties)}
                        forwardRef={clickRef}
                        isActive={isInviteMemberActive}
                        setIsActive={setIsInviteMemberActive}
                    />
                    {selectedProperty && (
                        <AddonAddMember
                            propertyId={selectedProperty.id}
                            forwardRef={clickRef}
                            isActive={isAddingMember}
                            setIsActive={setIsAddingMember}
                        />
                    )}
                    {selectedProperty && (
                        <AddonMoreVertical
                            propertyId={selectedProperty.id}
                            forwardRef={clickRef}
                            isActive={isSettingsActive}
                            setIsActive={setSettingsActive}
                        />
                    )}
                    {loadingProperty && (
                        <div ref={componentRef}>
                            <OrderConfirmationCreatePdf
                                setLoadingProperty={setLoadingProperty}
                                handlePrint={handlePrint}
                                propertyId={loadingProperty}
                            />
                        </div>
                    )}
                </>
            );
        }
    });
}
