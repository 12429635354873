import React from 'react';
import NavigationContainer from './emotion/Pagination/NavigationContainer';
import { Pagination } from './Pagination';

export default function Navigation({ data, setPage }) {
    const changePage = (newPage: number) => {
        setPage(newPage);
    };

    return <NavigationContainer>{!!data && <Pagination page={data} onChangePage={changePage} />}</NavigationContainer>;
}
