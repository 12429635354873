import styled from '@emotion/styled';
import React, { useState } from 'react';
import H1 from '../../../components/typography/H1';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import { L } from '../../../lib/i18n';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import Spinner from '../../../components/Spinner/Spinner';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import useForwardTicketMail from '../hooks/useForwardTicketMail';
import toast from 'react-hot-toast';
import { useGetTicket } from '../hooks/ticketQueries';
import { TicketItem } from '@app/api/models/Tickets';

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end'
});

const Form = styled.form({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small
});

type Props = {
    ticket_id?: TicketItem['id'];
};

const ForwardTicketMail = ({ ticket_id }: Props) => {
    const { ToggleIsModalOpen } = useModal();
    const { mutate, status } = useForwardTicketMail();
    const [mail, setMail] = useState('');

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (validateEmail(e.target.value)) {
            setMail(e.target.value);
        }
    };

    return (
        <Form
            onClick={e => {
                e.stopPropagation();
            }}
            onSubmit={e => {
                e.preventDefault();
                if (mail && ticket_id) {
                    mutate(
                        { ticket_id, emails: [mail] },
                        {
                            onSuccess: () => {
                                toast.success(`${L('forward_to')} ${mail}`);
                                ToggleIsModalOpen();
                            },
                            onError: () => {
                                toast.error('Det gick inte att skicka iväg mail');
                                ToggleIsModalOpen();
                            }
                        }
                    );
                }
            }}
        >
            <H1>{L('forward_mail')}</H1>
            <Label title={L('contact_mail')}>
                <Input placeholder={L('contact_mail')} name="email" onChange={e => handleChange(e)} />
            </Label>

            <ButtonWraper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    Avbryt
                </Button>

                {status !== 'loading' && (
                    <Button disabled={mail ? false : true} type={'submit'}>
                        {L('send')}
                    </Button>
                )}
                {status === 'loading' && (
                    <Button style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
            </ButtonWraper>
        </Form>
    );
};

export default ForwardTicketMail;
