import styled from '@emotion/styled';
import React from 'react';
import { Link, matchPath, useLocation, useParams } from 'react-router-dom';
import { useSliderMenu } from '../../../components/SliderMenu/context/SliderMenuContext';
import { L } from '../../../lib/i18n';

const StyledLink = styled(Link)(() => ({
    color: 'black',
    textAlign: 'center',
    marginRight: '1rem',
    marginLeft: '1rem',
    marginBottom: '-3rem',
    textDecoration: 'none',
    ['@media screen and (min-width: 1024px)']: {
        borderBottom: '4px solid transparent',
        paddingBottom: '10px',
        marginBottom: '1rem'
    }
    // '&:hover': {
    //     borderBottom: '4px solid #73AF00',
    // },
}));

const LinkWrapper = styled.div({
    marginTop: '5rem'
});

const SliderMenuMaintenanceLinks = () => {
    const { ToggleIsSettingsOpen } = useSliderMenu();
    const path = useLocation();
    const match = matchPath(path.pathname, {
        path: '/maintenance/:projectId'
    });
    const params = match?.params as { projectId?: string };
    return (
        <LinkWrapper>
            <StyledLink onClick={ToggleIsSettingsOpen} to={`/maintenance/${params?.projectId}/category`}>
                {L('categories')}
            </StyledLink>
            <StyledLink onClick={ToggleIsSettingsOpen} to={`/maintenance/${params?.projectId}/status`}>
                {L('status')}
            </StyledLink>
            <StyledLink onClick={ToggleIsSettingsOpen} to={`/maintenance/${params?.projectId}/type`}>
                {L('type')}
            </StyledLink>
            <StyledLink onClick={ToggleIsSettingsOpen} to={`/maintenance/${params?.projectId}/service_partner`}>
                {L('service_partner')}
            </StyledLink>
        </LinkWrapper>
    );
};

export default SliderMenuMaintenanceLinks;
