import * as React from 'react';
import styled from '@emotion/styled';
import { L } from '../../../../../lib/i18n';
import { Checkbox } from '../../../../../components/Checkbox/Checkbox';
import { style } from '../../../../../components/Shared/Style';

const Container = styled.div({ paddingRight: '10px' });
const Label = styled.div({
    display: 'flex',
    alignItems: 'center',
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase'
});

interface Props {
    filter: boolean;
    onChange: (setFilter: any) => void;
}

export const FilterTypeOfProperty = (props: Props) => {
    const onSelect = (e: any) => {
        props.onChange(e.target.checked);
    };

    return (
        <Container>
            <Label>
                {L('show_templates')}:
                <Checkbox defaultChecked={false} onChange={onSelect} />
            </Label>
        </Container>
    );
};
