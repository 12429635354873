import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateSpaceSort = () => {
    return useMutation(async (params: { id: number }[]) => {
        const ids = params.map((item) => item.id);
        return trpc.BindersApi.updateSpaceSort.mutate({ entityIds: ids });
    });
};

export default useUpdateSpaceSort;
