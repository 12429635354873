import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import useDeleteSettingsListByType from '../hooks/useDeleteSettingsListByType';
import useSaveSettingsListByType from '../hooks/useSaveSettingsListByType';
import { Icon } from '../../../components/Icon/Icon';
import { MaintenanceCategory } from '@app/api/models/Maintenances';
import BoxColorPicker from './BoxColorPicker';

const Item = styled.input({
    backgroundColor: 'gray',
    padding: '10px',
    borderRadius: '4px',
    border: '1px solid black',
    width: '300px',
    margin: '1rem',
    paddingLeft: '1rem',
    color: 'white',
    '&:focus': {
        outline: 'none'
    }
});
const ItemWrapper = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});
const SettingsIcon = styled(Icon.Settings)({
    margin: '0.3rem',
    cursor: 'pointer'
});
const TrashCanIcon = styled(Icon.TrashCan)({
    margin: '0.3rem',
    cursor: 'pointer'
});
const InvisibleButton = styled.button({
    backgroundColor: 'transparent',
    border: 'none'
});
const Save = styled.div({
    backgroundColor: '#e0e0e0',
    cursor: 'pointer',
    padding: '5px',
    borderRadius: '4px'
});

type Props = {
    item: MaintenanceCategory;
    settingsType: 'category' | 'status' | 'unit' | 'type';
};

const CategorySettingItem = ({ item }: Props) => {
    const [color, setColor] = useState(item.color_code);
    const [name, setName] = useState(item.name);
    const { mutate: remove } = useDeleteSettingsListByType();
    const [editMode, setEditMode] = useState(false);
    const { mutate: save } = useSaveSettingsListByType('category');

    useEffect(() => {
        if (color === item.color_code) return;
        save({ ...item, color_code: color });
    }, [color, item]);

    return (
        <ItemWrapper key={item.id}>
            <BoxColorPicker initialColor={item.color_code || ''} getValue={value => setColor(value)} />

            <Item readOnly={!editMode} onChange={e => setName(e.target.value)} value={name} />
            <InvisibleButton onClick={() => setEditMode(prev => !prev)}>
                {editMode && (
                    <>
                        <Save onClick={() => save({ id: item.id, name: name, color_code: color })}>Spara</Save>
                    </>
                )}
                <SettingsIcon />
            </InvisibleButton>
            <InvisibleButton onClick={() => remove({ id: item.id, settingstype: 'category' })}>
                <TrashCanIcon />
            </InvisibleButton>
        </ItemWrapper>
    );
};

export default CategorySettingItem;
