import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { documentGroupQueryKeys } from './documentGroupQueries';

export default function useAddDocumentGroup() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { name: string; projectId: number }) => {
            return trpc.ContentDocumentsApi.addDocumentGroup.query(options);
        },
        {
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(documentGroupQueryKeys.list({ templateId: data.projectId }));
            },
        },
    );
}
