import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const documentGroupQueryKeys = {
    all: [{ scope: 'templates-document_groups' }] as const,
    lists: () => [{ ...documentGroupQueryKeys.all[0], entity: 'list' }] as const,
    list: (params: { templateId: number; search?: string }) =>
        [{ ...documentGroupQueryKeys.lists()[0], params }] as const,
    details: () => [{ ...documentGroupQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (params: { projectId: number; id: number }) =>
        [{ ...documentGroupQueryKeys.details()[0], params }] as const,
};

export function useGetDocumentGroups({ templateId, search }: { templateId: number; search?: string }) {
    return useQuery({
        queryKey: documentGroupQueryKeys.list({ templateId, search }),
        queryFn: async ({ queryKey }) => {
            const { templateId, search } = queryKey[0].params;
            return trpc.ContentDocumentsApi.listDocumentGroups.query({ projectId: templateId, search });
        },
        keepPreviousData: true,
    });
};
