import * as React from 'react';
import { Icon } from '../Icon/Icon';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    fill: '#9FA2B4',
    marginLeft: style.margin.s
});

export const SortArrows = () => {
    return (
        <Container>
            <Icon.SortUp size="small" />
            <Icon.SortDown size="small" />
        </Container>
    );
};
