import InventoryApi from '@app/api/public/InventoryApi';
import { useQueryClient, useMutation } from 'react-query';

export const deleteProduct = async (id: string) => {
    return await InventoryApi.deleteInventory(id);
};

const useDeleteProduct = () => {
    const queryClient = useQueryClient();

    return useMutation((productId: string) => deleteProduct(productId), {
        onSettled: (_data, _error, productId: string) => {
            queryClient.invalidateQueries(['getProductsByProperty']);
            queryClient.invalidateQueries(['getProductsByProperty', productId]);
        }
    });
};

export default useDeleteProduct;
