import React from 'react';
import DocumentGroupForm from './DocumentGroupForm';
import { SubmitHandler } from 'react-hook-form';
import useUpdateDocumentGroup from '../../hooks/DocumentGroups/useUpdateDocumentGroup';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';

type Props = {
    id: number;
    name: string;
    projectId: number;
    onSuccess: () => void;
};

const DocGroupUpdateWrapper = ({ projectId, id, name, onSuccess }: Props) => {
    const { mutate: updateDocumentGroup } = useUpdateDocumentGroup();

    const onDocumentGroupUpdateSubmit: SubmitHandler<{ name: string }> = (data) => {
        updateDocumentGroup(
            { id: id, projectId, name: data.name },
            {
                onSuccess: () => {
                    toast.success(L('document_group_updated'));
                    onSuccess();
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };
    return (
        <DocumentGroupForm
            heading={L('edit_document_group')}
            defaultValues={{ name: name }}
            onSubmit={onDocumentGroupUpdateSubmit}
        />
    );
};

export default DocGroupUpdateWrapper;
