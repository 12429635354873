import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import styled from '@emotion/styled';
import { useGetProductName } from '../../binders/hooks/productQueries';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import BigTabNav from '../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../components/BigTabItem/BigTabListItemNav';
import { L } from '../../../lib/i18n';
import H1 from '../../../components/typography/H1';
import PackageProductsLibraryComponent from '../../copy-function/components/PackageProductsLibraryComponent';

const Menu = styled.div({
    display: 'flex',
    flexFlow: 'column nowrap',
});

const PackageProductsPage = () => {
    const { id } = useParams<{ id: string }>();
    const { data: parentProductNameData } = useGetProductName(id ? +id : null);
    const productTitle = parentProductNameData?.name ? parentProductNameData.name : L('product');

    const tabsAndBreadcrumbs = (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../..">{L('inventory_lib')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{productTitle}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{productTitle}</H1>
            <Menu>
                <BigTabNav>
                    <BigNavItem end to="..">
                        {L('edit_product')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('content_of_packages')}
                    </BigNavItem>
                </BigTabNav>
            </Menu>
        </>
    );

    if (id) return <PackageProductsLibraryComponent productId={id} tabsAndBreadcrumbs={tabsAndBreadcrumbs} />;
    return null;
};

export default PackageProductsPage;
