import React from 'react';
import { L } from '../../../../lib/i18n';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import Input from '../../../../components/Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import { useGetProjectTemplatesIdName } from '../../../project-templates/hooks/ProjectTemplates/projectTemplateQueries';
import useAddProjectFromTemplate from '../../hooks/projectHooks/useAddProjectFromTemplate';
import toast from 'react-hot-toast';
import H2 from '../../../../components/typography/H2';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    minWidth: '400px',
    padding: '22px 32px 22px 32px',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const AddProductFromTemplateSchema = z.object({
    project_id: z.number(),
    new_name: z.string(),
});

type FormType = z.infer<typeof AddProductFromTemplateSchema>;

type Props = {
    handleCloseModal: () => void;
};

const AddProjectFromTemplate = ({ handleCloseModal }: Props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setValue,
    } = useForm<{ project_id: number; new_name: string }>({
        resolver: zodResolver(AddProductFromTemplateSchema),
    });

    const { mutate: addFromTemplate } = useAddProjectFromTemplate();

    const onSubmit: SubmitHandler<FormType> = (params) => {
        addFromTemplate(params, {
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
            onSuccess: () => {
                toast.success(L('project_created_from_template'));
                handleCloseModal();
            },
        });
    };

    const { data: templates } = useGetProjectTemplatesIdName();
    const templateOptions =
        templates?.map((template) => ({
            label: template.name,
            value: template.id,
        })) ?? [];

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <H2>{L('new_project_from_template')}</H2>
            <ErrorAndInputWrapper errorMsg={L(errors.project_id?.message ?? '')}>
                <Label title={L('template')} />
                <Controller
                    control={control}
                    name="project_id"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.project_id}
                            options={templateOptions}
                            onValueChange={(val) => {
                                onChange(+val);
                                const correctLabel = templates?.find((template) => template.id === +val)?.name ?? '';
                                setValue('new_name', correctLabel);
                            }}
                            value={value}
                        />
                    )}
                />
            </ErrorAndInputWrapper>
            <ErrorAndInputWrapper errorMsg={L(errors.new_name?.message ?? '')}>
                <Label title={L('new_name')} />
                <RestyledInput isError={!!errors.new_name} type="text" {...register('new_name')} />
            </ErrorAndInputWrapper>
            <div style={{ marginTop: style.margin.m, display: 'flex', justifyContent: 'end' }}>
                <Button>{L('save')}</Button>
            </div>
        </Form>
    );
};

export default AddProjectFromTemplate;
