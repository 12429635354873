import React from 'react';
import { Route } from 'react-router-dom';
import { AddonProjectListPage } from './project/ListPage/AddonProjectListPage';
import { AddonPropertyListPage } from './property/ListPage/AddonPropertyListPage';
import { AddonProductListPage } from './product/ListPage/AddonProductListPage';
import { AddonReportListPage } from './report/ListPage/AddonReportListPage';
import { EntitySupplierListPage } from './report/EntitySupplier/EntitySupplierListPage';
import { AddonProductDetailPage } from './product/DetailPage/AddonProductDetailPage';
import AddonReportProvider from './report/ListPage/context/AddonReportContext';

export const routes = [
    <Route key="0" exact path="/addons" component={AddonProjectListPage} />,
    <Route key="1" exact path="/addons/project/:projectId" component={AddonPropertyListPage} />,
    <Route key="2" exact path="/addons/project/:projectId/report">
        <AddonReportProvider>
            <AddonReportListPage />
        </AddonReportProvider>
    </Route>,
    <Route key="2" exact path="/addons/project/:projectId/report/supplier" component={EntitySupplierListPage} />,
    <Route key="3" exact path="/addons/project/:projectId/property/:propertyId" component={AddonProductListPage} />,
    <Route
        key="4"
        exact
        path="/addons/project/:projectId/property/:propertyId/product/:productId"
        component={AddonProductDetailPage}
    />,
];

export const navigation = [{ name: 'addons', link: '/addons', icon: 'Addon' }];
