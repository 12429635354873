import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';

export const getPropertyMemberKeys = {
    all: ['binder-property-members'] as const,
    lists: () => [...getPropertyMemberKeys.all, 'list'] as const,
    list: (propertyId: number) => [...getPropertyMemberKeys.lists(), propertyId],
    details: () => [...getPropertyMemberKeys.all, 'detail'] as const,
    detail: (params: { propertyId: number; id: number }) => [...getPropertyMemberKeys.details(), params] as const
};

export function useListPropertyMember(propertyId: number) {
    return useQuery(
        [...getPropertyMemberKeys.list(propertyId)],
        async () => trpc.MembersApi.listMembersByProperty.query({ propertyId }),
        {
            keepPreviousData: true
        }
    );
}

export function useGetPropertyMember(params: { id: number; propertyId: number }) {
    return useQuery(
        [...getPropertyMemberKeys.detail(params)],
        async () => trpc.MembersApi.getPropertyMember.query(params),
        {
            keepPreviousData: true
        }
    );
}
