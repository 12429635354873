import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { L } from '../../../lib/i18n';
import useGetProjects from '../hooks/useGetProjects';
import { AlertFilter } from '@app/api/models/Alterts';

const Container = styled.div({ paddingRight: '10px' });

interface Props {
    filter?: { project?: number };
    onChange: (filter: AlertFilter) => void;
}

export const FilterProject = ({ filter, onChange }: Props) => {
    const { data: projects } = useGetProjects();

    const name = 'project';
    const options = projects?.map(v => ({ value: v.id, label: v.name })) || [];

    return (
        <Container>
            <Dropdown
                isClearable
                value={(filter?.[name] && filter?.[name]) || ''}
                onChange={value => onChange({ ...filter, project: value })}
                label={L(name)}
                options={options.length === 0 ? [] : options}
            />
        </Container>
    );
};
