import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';
import { RouterOutput } from '../../../../lib/trpc';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import mime from 'mime';
import { FileTypeIcon } from '../../../../components/Icon/Icon';
import DocumentDropdown from './DocumentDropdown';

type DocumentsType = NonNullable<RouterOutput['BindersApi']['listPropertyDocuments']>;

export type PropertyDocumentsTableMeta = {
    removeDocument: (documentId: number) => void;
    renameDocument: (documentId: number, name: string) => void;
    updateDocType: ({
        documentId,
        document_subtype,
        document_type,
    }: {
        documentId: number;
        document_subtype: number;
        document_type: number;
    }) => void;
};

const columnHelper = createColumnHelper<DocumentsType[0]>();

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columns = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        enableResizing: false,
    }),
    columnHelper.display({
        id: 'fileType',
        cell: ({ row }) => {
            const fileType = mime.getType(row.original.name);
            return (
                <div>
                    <FileTypeIcon fileType={fileType ?? ''} />
                </div>
            );
        },
        size: 30,

        enableResizing: false,
    }),
    columnHelper.accessor('name', {
        cell: ({ row, table }) => {
            return (
                <CellContainer>
                    <DocumentDropdown
                        name={row.original.name}
                        typesOfDoc={{
                            document_type: row.original.document_type,
                            document_subtype: row.original.document_subtype,
                        }}
                        deleteDocument={() =>
                            (table.options.meta as PropertyDocumentsTableMeta)?.removeDocument(row.original.id)
                        }
                        editDocumentName={(name) =>
                            (table.options.meta as PropertyDocumentsTableMeta)?.renameDocument(row.original.id, name)
                        }
                        updateDocType={(document_type: number, document_subtype: number) =>
                            (table.options.meta as PropertyDocumentsTableMeta)?.updateDocType({
                                documentId: row.original.id,
                                document_subtype,
                                document_type,
                            })
                        }
                        documentId={row.original.document_data.original.id}
                    />
                </CellContainer>
            );
        },
        minSize: 200,
        size: 250,
        header: () => <Flex>{L('name')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor('document_type_name', {
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.getValue())}</Data>
            </CellContainer>
        ),
        minSize: 100,
        size: 120,
        header: () => <Flex>{L('category')}</Flex>,
        meta: {
            styles: {
                flex: '1 1 auto',
            },
        },
    }),
    columnHelper.accessor('document_subtype_name', {
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.getValue())}</Data>
            </CellContainer>
        ),
        minSize: 100,
        size: 120,
        header: () => <Flex>{L('sub_category')}</Flex>,
        meta: {
            styles: {
                flex: '1 1 auto',
            },
        },
    }),
];

export default columns;
