import { trpc } from '../../../lib/trpc';
import { useQuery } from 'react-query';

export const getSpaceKeys = {
    all: ['binders-spaces'] as const,
    lists: () => [...getSpaceKeys.all, 'list'] as const,
    listsPropertySpaces: () => [...getSpaceKeys.lists(), 'property-spaces'] as const,
    listPropertySpaces: (propertyIds: number[], search: string) =>
        [...getSpaceKeys.listsPropertySpaces(), ...propertyIds, search] as const,
};

export function useGetPropertySpaces(propertyIds: number[], search: string) {
    return useQuery(
        getSpaceKeys.listPropertySpaces(propertyIds, search),
        () => trpc.BindersApi.listSpacesByPropertyIds.query({ propertyIds, search }),
        { keepPreviousData: true },
    );
}
