import dayjs from 'dayjs';

const formatChatDate = (date: string | Date) => {
    if (dayjs(date).isSame(dayjs(), 'day')) {
        return dayjs(date).format('HH:mm');
    }
    if (dayjs(date).isSame(dayjs(), 'week')) {
        return dayjs(date).format('ddd HH:mm');
    }
    if (dayjs(date).isSame(dayjs(), 'year')) {
        return dayjs(date).format('ddd D MMM HH:mm');
    }
    return dayjs(date).format('YYYY ddd D MMM HH:mm');
};

export default formatChatDate;
