import { Property } from '@app/api/models/Properties';
import UsersApi from '@app/api/public/UsersApi';
import { useQueries } from 'react-query';

const getPropertyMembers = async (propertyId: Property['id']) => {
    const users = await UsersApi.getPropertyUsers(propertyId);

    return {
        propertyId,
        users
    };
};

const useGetPropertyMembers = (propertyIds: Property['id'][]) => {
    return useQueries(
        propertyIds.map(propertyId => {
            return {
                queryKey: ['propertyMembers', propertyId],
                queryFn: () => getPropertyMembers(propertyId),
                enabled: !!propertyId
            };
        })
    );
};

export default useGetPropertyMembers;
