import { useQuery } from 'react-query';
import ProfilesApi from '@app/api/public/ProfilesApi';

export const profileQueryKey = 'profile';

const useGetProfile = () => {
    return useQuery([profileQueryKey], async () => {
        return ProfilesApi.id();
    });
};

export default useGetProfile;
