import { useQuery } from 'react-query';
import ServicePartnersApi from '@app/api/public/ServicePartnersApi';
import { useParams } from 'react-router-dom';

export const getServicePartnersQueryKey = 'getServicePrtners';
const getServicePrtners = async (projectId: string) => {
    return await ServicePartnersApi.list(+projectId);
};

const useGetServicePartners = () => {
    const { projectId } = useParams<{ projectId: string }>();
    return useQuery([getServicePartnersQueryKey, { projectId }], () => getServicePrtners(projectId));
};

export default useGetServicePartners;
