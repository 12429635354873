import styled from '@emotion/styled';
import React from 'react';

const DropdownFlex = styled.div({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'space-between',
    gap: '4px',
    '& label': {
        alignSelf: 'center'
    }
});

const DropdownFlexContainer: React.FC = ({ children }) => {
    return <DropdownFlex>{children}</DropdownFlex>;
};

export default DropdownFlexContainer;
