import { useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';
import { SpaceFilter } from '@app/trpc-api/models/Binders';

export const getTemplatePropertySpaceKeys = {
    all: ['template-property-spaces'] as const,
    lists: () => [...getTemplatePropertySpaceKeys.all, 'list'] as const,
    listInfinite: ({
        propertyId,
        sorting,
        search,
        filter,
    }: {
        propertyId: number;
        sorting: SortingState;
        search: string;
        filter: SpaceFilter;
    }) => [...getTemplatePropertySpaceKeys.lists(), { propertyId, sorting, search, filter }] as const,
    details: () => [...getTemplatePropertySpaceKeys.all, 'detail'] as const,
    detail: (spaceId: number) => [...getTemplatePropertySpaceKeys.details(), spaceId] as const,
    documents: () => [...getTemplatePropertySpaceKeys.all, 'documents'] as const,
    documentsBySpaceId: (spaceId: number) => [...getTemplatePropertySpaceKeys.documents(), spaceId] as const,
};

export function useGetSpaceImagesPropertyTemplate(spaceId: number) {
    return useQuery(
        [...getTemplatePropertySpaceKeys.documentsBySpaceId(spaceId)],
        async () => trpc.TemplatesApi.listSpaceImages.query({ entityId: +spaceId }),
        {
            keepPreviousData: true,
        },
    );
}
