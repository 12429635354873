import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { createColumnHelper, useReactTable, getCoreRowModel, getSortedRowModel } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import ConfirmationButton from '../../../components/Buttons/ConfirmButton';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { useGetTodos } from '../hooks/accountTodoQueries';
import { useRemoveTodo } from '../hooks/accountTodoMutations';
import { L } from '../../../lib/i18n';
import GenericTable from '../../binders/components/GenericTable';
import { Header } from '../../../components/Layout/Header';

const columnHelper = createColumnHelper<NonNullable<ReturnType<typeof useGetTodos>['data']>[0]>();

const Cell = styled.div({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
});

const TodoList = () => {
    const todosQuery = useGetTodos();
    const { mutate: removeTodo } = useRemoveTodo();
    const data = todosQuery?.data ?? [];
    const navigate = useNavigate();

    const Columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                header: () => <span>{L('name')}</span>,
                cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.description, {
                id: 'description',
                header: () => <span>{L('description')}</span>,
                cell: ({ getValue }) => <Cell>{getValue()}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.type, {
                id: 'type',
                header: () => <span>{L('type')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.subType, {
                id: 'subType',
                header: () => <span>{L('subType')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.recurrency, {
                id: 'recurrency',
                header: () => <span>{L('recurrency')}</span>,
                cell: ({ getValue }) => <Cell>{L(getValue())}</Cell>,
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
            }),
            columnHelper.accessor((row) => row.id, {
                id: 'remove',
                header: () => <span />,
                cell: ({ getValue }) => (
                    <div>
                        <ConfirmationButton
                            props={{
                                onConfirm: () =>
                                    removeTodo({
                                        todoId: getValue(),
                                    }),
                                stopPropagation: true,
                            }}
                        />
                    </div>
                ),
                meta: {
                    styles: {
                        width: '100%',
                    },
                },
                enableSorting: false,
            }),
        ],
        [todosQuery.data],
    );

    const table = useReactTable({
        columns: Columns,
        data: data,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <Breadcrumbs style={{ paddingBottom: '1rem' }}>
                <Breadcrumbs.ItemActive>{L('todos')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <Header title={L('todos')} />
            <CardWithDivider
                topArea={
                    <Flex>
                        <CardTitle>{L('todo_list')}</CardTitle>
                        <Link to={'./new'}>
                            <Button>{L('create_new_todo')}</Button>
                        </Link>
                    </Flex>
                }
                mainArea={
                    <div>
                        <GenericTable table={table} rowClickHandler={(row) => navigate(`./${row.original.id}`)} />
                    </div>
                }
            />
        </>
    );
};

export default TodoList;
