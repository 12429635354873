import React from 'react';
import H1 from '../../../components/typography/H1';
import styled from '@emotion/styled';
import { L } from '../../../lib/i18n';
import ServicePartnerDocumentTableById from '../components/ServicePartnerDocumentTableById';
import ServicePartnerCagtegory from '../components/ServicePartnerCategory';
import useGetServicePartnerById from '../hooks/useGetServicePartnerById';
import ServicePartnerForm from '../components/ServicePartnerForm';
import { Loading } from '../../../components/Loading/Loading';

const EditServicePartner: React.FC = () => {
    const servicePartnerQuery = useGetServicePartnerById();

    if (servicePartnerQuery.status === 'loading') return <Loading />;

    if (!servicePartnerQuery.data) return <div>{L('error_service_partner')}</div>;
    return (
        <div style={{ paddingBottom: '200px' }}>
            <ServicePartnerForm InitialFormData={servicePartnerQuery.data} />
            <ServicePartnerDocumentTableById />
            <ServicePartnerCagtegory />
        </div>
    );
};

export default EditServicePartner;
