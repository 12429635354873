import React from 'react';
import { useGetLibraries } from '../../binders/hooks/libraryQueries';
import AddProductFromTemplateComponent from '../../binders/components/Products/AddProductFromTemplateComponent';
import useImportTemplateProductToLibraryProduct from '../../binders/hooks/CopyFunctionHooks/useImportTemplateProductToLibraryProduct';

const AddProductFromTemplateToLibraryPackage = ({ productId }: { productId: string }) => {
    const { data: libraries } = useGetLibraries();
    const { mutate: importTemplateProduct } = useImportTemplateProductToLibraryProduct();

    const handleImportTemplateProduct = ({
        products,
        destinationId,
    }: {
        destinationId: number;
        products: {
            id: number;
            package_type: string | null;
        }[];
    }) => {
        importTemplateProduct({ products, toProductId: destinationId });
    };

    return libraries ? (
        <AddProductFromTemplateComponent
            productId={+productId}
            libraries={libraries}
            handleImportToAccountProduct={handleImportTemplateProduct}
        />
    ) : null;
};

export default AddProductFromTemplateToLibraryPackage;
