import { Maintenance, MaintenanceCategory } from '@app/api/models/Maintenances';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { colors } from '../../../components/Shared/Style/colors';
import DocumentsTable from '../../../components/Table/DocumentTables/DocumentsTable';
import { L } from '../../../lib/i18n';
import { DocumentDetail } from '@app/api/public/DocumentsApi';
import { View } from '../page/EditMaintenancePage';
import InputSplit from '../../../components/Forms/InputSplit';
import { QueryStatus } from 'react-query';
import Spinner from '../../../components/Spinner/Spinner';
import { ServicePartner } from '@app/api/public/ServicePartnersApi';
import { Link, useParams } from 'react-router-dom';
import { TextArea } from '@ourliving/ourliving-ui';

const Wrapper = styled.div({
    width: '100%',
    color: colors.textColor1,
    display: 'grid',
    justifyItems: 'flex-start',
    gridTemplateColumns: '1fr',
    alignItems: 'center',
    gridGap: '20px',
});

const StyledInputSplit = styled(InputSplit)({
    '& input': {
        height: '32px',
    },
});
const GridSectionMain = styled.div({
    width: '100%',
    display: 'grid',
    justifyContent: 'flex-start',
    gridColumnGap: '20px',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateAreas: `
    "title title          category service_partner"
    "status status          type  type"
    "textarea   textarea   textarea  textarea"
    "plannedDate plannedDate plannedCost plannedCost"
    `,
});
const GridSectionChange = styled(GridSectionMain)({
    gridTemplateAreas: `
    "createdBy updatedBy"
    "createdAt updatedAt"
    `,
});

const StyledLink = styled(Link)({
    marginRight: 'auto',
    marginLeft: '0px',
});

const StyledInput = styled(Input)({
    width: '100%',
    height: '32px',
});

const StyledTextarea = styled(TextArea)({
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#FCFDFE',
    border: '1px solid #F0F1F7',
    height: '150px',
    resize: 'none',
    padding: '8px',
    fontSize: '14px',
    '&:focus': {
        outline: 'none',
    },
});

const Hr = styled.div({
    border: 'solid 1px',
    borderColor: 'gray',
    width: '100%',
    marginTop: '1.5rem',
});
const AddButton = styled(Button)({
    marginRight: 0,
    marginLeft: '1rem',
});
const ButtonWraper = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
});
const DocWrapper = styled.div({
    width: '100%',
});

type Props = {
    categoryList: MaintenanceCategory[] | null | undefined;
    deleteDoc: (variables: { documentId: string; queryId: string }) => void;
    documentItem: DocumentDetail[] | undefined;
    handleChange: (value: Partial<Maintenance>) => void;
    handleRemove: () => void;
    maintenanceItem: Partial<Maintenance> | undefined;
    saveDoc: (variables: { itemId: string; name: string; file: File }) => void;
    statusList: MaintenanceCategory[] | null | undefined;
    typeList: MaintenanceCategory[] | null | undefined;
    status: QueryStatus;
    handleSave: () => void;
    servicePartners: Pick<ServicePartner, 'id' | 'name'>[] | undefined;
};

const EditMaintenanceForm = ({
    categoryList,
    deleteDoc,
    documentItem,
    handleChange,
    handleRemove,
    maintenanceItem,
    saveDoc,
    statusList,
    typeList,
    status,
    handleSave,
    servicePartners,
}: Props) => {
    const { projectId } = useParams<{ projectId: string }>();
    return (
        <Wrapper>
            <GridSectionMain>
                <Label title={'Titel'} style={{ gridArea: 'title' }}>
                    <StyledInput
                        value={maintenanceItem?.name || ''}
                        onChange={(e) => handleChange({ name: e.target.value })}
                    />
                </Label>
                <Label title={'Kategori'} style={{ gridArea: 'category' }}>
                    <Dropdown
                        value={maintenanceItem?.maintenance_category || ''}
                        onChange={(value) => handleChange({ maintenance_category: value })}
                        label={'Kategori'}
                        options={
                            categoryList?.map((item) => {
                                return { value: { id: +item.id, name: item.name }, label: item.name };
                            }) || []
                        }
                    />
                </Label>
                <Label title={'Servicepartner'} style={{ gridArea: 'service_partner' }}>
                    <Dropdown
                        value={maintenanceItem?.service_partner_id ? +maintenanceItem?.service_partner_id : ''}
                        onChange={(value) => handleChange({ service_partner_id: +value })}
                        label={'Servicepartner'}
                        isClearable={true}
                        options={
                            servicePartners?.map((servicePartner) => {
                                return { value: +servicePartner.id, label: servicePartner.name };
                            }) || []
                        }
                    />
                </Label>
                <Label title={'Beskrivning'} style={{ gridArea: 'textarea' }}>
                    <StyledTextarea
                        value={maintenanceItem?.description || ''}
                        onChange={(e) => handleChange({ description: e.target.value })}
                    />
                </Label>

                <Label title={'Status'} style={{ gridArea: 'status' }}>
                    <Dropdown
                        value={maintenanceItem?.maintenance_status || ''}
                        onChange={(value) => handleChange({ maintenance_status: value })}
                        label={'Typ'}
                        options={
                            statusList?.map((item) => {
                                return {
                                    value: { id: +item.id, name: item.name, color_code: item.color_code },
                                    label: item.name,
                                };
                            }) || []
                        }
                    />
                </Label>
                <Label title={'Typ'} style={{ gridArea: 'type' }}>
                    <Dropdown
                        value={maintenanceItem?.maintenance_type || ''}
                        onChange={(value) => handleChange({ maintenance_type: value })}
                        label={'Typ'}
                        options={
                            typeList?.map((item) => {
                                return { value: { id: +item.id, name: item.name }, label: item.name };
                            }) || []
                        }
                    />
                </Label>
                <Label title={'Planerad kostnad'} style={{ gridArea: 'plannedCost' }}>
                    <StyledInputSplit
                        type={'number'}
                        value={maintenanceItem?.planned_cost ? parseInt(maintenanceItem.planned_cost) : ''}
                        onChange={(e) => handleChange({ planned_cost: e.target.value })}
                        splitInput={'kr'}
                    />
                </Label>
                <Label style={{ gridArea: 'plannedDate' }} title={L('planned_date')}>
                    <StyledInput
                        type={'date'}
                        value={
                            maintenanceItem?.planned_date
                                ? dayjs(maintenanceItem?.planned_date).format('YYYY-MM-DD')
                                : ''
                        }
                        onChange={(e) => handleChange({ planned_date: e.target.value })}
                    />
                </Label>
            </GridSectionMain>
            <Hr />
            {documentItem && (
                <DocWrapper>
                    <DocumentsTable saveDocument={saveDoc} deleteDocument={deleteDoc} list={documentItem} />
                </DocWrapper>
            )}

            <ButtonWraper>
                <StyledLink to={`/maintenance/${projectId}/${maintenanceItem?.id}/complete`}>
                    <AddButton style={{ marginLeft: '0px' }}>{L('complete_maintenance')}</AddButton>
                </StyledLink>

                <Button type="button" style={{ marginRight: '1rem' }} onClick={() => handleRemove()} variant="danger">
                    Radera
                </Button>

                {status === 'idle' && <Button onClick={() => handleSave()}>{L('save')}</Button>}
                {status === 'loading' && (
                    <Button onClick={() => handleSave()} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && <Button onClick={() => handleSave()}>{L('error')}</Button>}
                {status === 'success' && <Button onClick={() => handleSave()}>{L('saved')}</Button>}
            </ButtonWraper>
        </Wrapper>
    );
};

export default EditMaintenanceForm;
