import ProjectsApi from '@app/api/public/ProjectsApi';
import { useQuery } from 'react-query';

const getProjectsQueryKeys = {
    all: ['projects'],
    projectFeatures: (feature: string) => [...getProjectsQueryKeys.all, feature]
};

const getProjects = (feature: string) => {
    return ProjectsApi.list(feature);
};

const useGetProjects = (feature: string) => {
    return useQuery(getProjectsQueryKeys.projectFeatures(feature), () => getProjects(feature), {
        enabled: !!feature
    });
};

export default useGetProjects;
