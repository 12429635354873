import React, { useState } from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import styled from '@emotion/styled';

const FlexRightContainer = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
});

export default function ClearProducts({ clearRows }: { clearRows: () => void }) {
    const [removeConfirmed, setRemoveConfirmed] = useState(false);
    return (
        <FlexRightContainer onMouseLeave={() => setRemoveConfirmed(false)}>
            {removeConfirmed ? (
                <Button onClick={clearRows} variant="danger">
                    {L('empty_list')}
                </Button>
            ) : (
                <Button onClick={() => setRemoveConfirmed(true)} variant="primary">
                    {L('empty_list')}
                </Button>
            )}
        </FlexRightContainer>
    );
}
