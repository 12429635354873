import styled from '@emotion/styled';
import React, { FC } from 'react';
import { HTMLAttributes } from 'react';

const StyledBigTabNav = styled.div({
    display: 'flex',
    gap: '1rem',
    marginBottom: '30px'
});
const BigTabNav: FC<HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => {
    return <StyledBigTabNav {...rest}>{children}</StyledBigTabNav>;
};

export default BigTabNav;
