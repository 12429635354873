import React, { createContext, FC, useContext, useState } from 'react'

type SliderMenuContextType = {
    isSettingsOpen: boolean;
    ToggleIsSettingsOpen: () => void;
}

const SliderMenuContext = createContext({} as SliderMenuContextType);

const SliderMenuProvider:FC = ({children}) => {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    const ToggleIsSettingsOpen = () => {
        setIsSettingsOpen(prev => !prev);
    }

    const sliderMenuValue = {
        isSettingsOpen,
        ToggleIsSettingsOpen,
    }
    
    return (
        <SliderMenuContext.Provider value={sliderMenuValue}>{children}</SliderMenuContext.Provider>
    )
}

const useSliderMenu = () => {
    return useContext(SliderMenuContext);
}

export { useSliderMenu, SliderMenuContext, SliderMenuProvider as default}
