import { useMutation, useQueryClient } from 'react-query';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';
import { trpc } from '../../../../lib/trpc';
import { getSpaceKeys } from '../spaceQueries';

const useCopyProduct = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { productIds: number[]; spaceIds: number[] }) =>
            trpc.ProductsApi.copyProducts.mutate({ productIds: options.productIds, spaceIds: options.spaceIds }),
        {
            onSettled: (data) => {
                queryClient.invalidateQueries(getSpaceKeys.listsPropertySpaces());
                const numberOfErrors: number[] = [];
                const numberOfSuccesses: number[] = [];
                data?.forEach((item) => {
                    if (item.message.status === 'error') numberOfErrors.push(item.id);
                    if (item.message.status === 'ok') numberOfSuccesses.push(item.id);
                });
                if (numberOfErrors.length === 0 && numberOfSuccesses.length > 0) {
                    toast.success(`${L('successful_copies')}: ${numberOfSuccesses.length}`);
                    return;
                }
                if (numberOfErrors.length > 0) {
                    toast.success(`${L('successful_copies')}: ${numberOfSuccesses.length}`);
                    toast.error(`${L('failed_copies')}: ${numberOfErrors.length}`);
                }
            },
        },
    );
};

export default useCopyProduct;
