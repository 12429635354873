import { useQuery } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';
import { InventoryFilter } from '@app/api/models/Inventory';

const getFilteredProducts = async () => {
    const r = await InventoryApi.listInventoryFilter();
    return r;
};

export const filteredProducts = {
    all: ['getFilteredProducts'],
    id: (id: InventoryFilter['id']) => [...filteredProducts.all, id]
};

const useGetFilteredProducts = () => {
    return useQuery([...filteredProducts.all], getFilteredProducts);
};

export default useGetFilteredProducts;
