import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getProjectKeys } from './projectQueries';

const useDeleteProject = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (projectId: number) => {
            return trpc.BindersApi.deleteProject.mutate({ projectId });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.lists());
            },
        },
    );
};

export default useDeleteProject;
