import * as React from 'react';
import styled from '@emotion/styled';
import { useObserver } from 'mobx-react';
import { Loading } from '../../../../../components/Loading/Loading';
import { usePromise } from '../../../../../lib/react';
import { EntitySupplierStore } from '../EntitySupplierStore';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../../lib/i18n';
import { FormField } from '../../../../../components/Forms/FormField';
import { Dropdown } from '../../../../../components/Dropdown/Dropdown';
import { style } from '../../../../../components/Shared/Style';

const NewFields = styled.div({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: style.margin.m,
    border: '1px solid gray',
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.l,
});

const EmptyOption = styled.div({
    minHeight: '20px',
});

const Field = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '13%',
    minHeight: '40px',
});

export function NewSupplier({ setNewSupplier, id }) {
    const [brand, setBrand] = React.useState<number | null | undefined>();
    const [supplier, setSupplier] = React.useState<number | null | undefined>();
    const [type, setType] = React.useState<number | null | undefined>(0);
    const [professions, setProfessions] = React.useState<number | null | undefined>();

    const [ready] = usePromise(async () => {
        await EntitySupplierStore.load();
    }, []);

    React.useEffect(() => {
        setBrand(EntitySupplierStore.entitySupplierEdit?.brand_id);
        setSupplier(EntitySupplierStore.entitySupplierEdit?.supplier_id);
        setType(EntitySupplierStore.entitySupplierEdit?.product_type_id);
        setProfessions(EntitySupplierStore.entitySupplierEdit?.profession_id);
    }, [id]);

    const optionsBrands = EntitySupplierStore.brands.map((p) => ({ label: p.name, value: p.id }));
    const optionsProductType = EntitySupplierStore.productType
        .sort((a, b) => L(a.name).localeCompare(L(b.name), 'sv'))
        .map((p) => ({ label: L(p.name), value: p.id }));

    const optionsSuppliers = [
        {
            value: null,
            label: <EmptyOption />,
        },
        ...EntitySupplierStore.suppliers.map((p) => ({ label: p.name, value: p.id })),
    ];
    const optionsProfessions = [
        {
            value: null,
            label: <EmptyOption />,
        },
        ...EntitySupplierStore.professions.map((p) => ({ label: p.name, value: p.id })),
    ];

    const onSave = async () => {
        if (type === 0) return (EntitySupplierStore.errors = 'error_oneisrequired');
        EntitySupplierStore.entitySupplier = {
            id: id || undefined,
            brand_id: brand || null,
            supplier_id: supplier || null,
            product_type_id: type || null || undefined,
            profession_id: professions || null,
        };
        await EntitySupplierStore.save();
        if (!EntitySupplierStore.saveError) setNewSupplier(false);
    };

    const onCancel = () => {
        setNewSupplier(false);
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return (
            <NewFields>
                <Field>
                    <FormField error={L(EntitySupplierStore.errors)} title={L('function')}>
                        <Dropdown
                            options={optionsProductType}
                            onChange={(value) => setType(value)}
                            value={type}
                            placeholder={L('dropdown_select')}
                        />
                    </FormField>
                </Field>
                <Field>
                    <FormField title={L('brand')} error={''}>
                        <Dropdown
                            options={optionsBrands}
                            onChange={(value) => setBrand(value)}
                            value={brand}
                            placeholder={L('dropdown_select')}
                        />
                    </FormField>
                </Field>
                <Field>
                    <FormField title={L('supplier')} error={''}>
                        <Dropdown
                            options={optionsSuppliers}
                            onChange={(value) => setSupplier(value)}
                            value={supplier}
                            placeholder={L('dropdown_select')}
                        />
                    </FormField>
                </Field>
                <Field>
                    <FormField title={L('profession')} error={''}>
                        <Dropdown
                            options={optionsProfessions}
                            onChange={(value) => setProfessions(value)}
                            value={professions}
                            placeholder={L('dropdown_select')}
                        />
                    </FormField>
                </Field>
                <Field style={{ gap: '8px' }}>
                    <Button onClick={onSave} style={{ alignSelf: 'flex-end' }}>
                        {L('save')}
                    </Button>
                    <Button onClick={onCancel} style={{ alignSelf: 'flex-end' }} variant={'danger'}>
                        {L('cancel')}
                    </Button>
                </Field>
            </NewFields>
        );
    });
}
