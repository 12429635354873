import React from 'react';
import { Route } from 'react-router-dom';
import { InformationSettings } from './Routes';

export const routes = [<Route key={0} path="/information-settings" component={InformationSettings} />];

export const navigation = [
    {
        name: 'content-information',
        link: '/information-settings',
        match: '/information-settings',
        icon: 'templates',
    },
];
