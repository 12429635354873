import { observable } from 'mobx';
import UsersApi from '@app/api/public/UsersApi';

class ConfirmResetStoreClass {
    @observable newPassword: string;
    @observable confirmPassword: string;
    @observable isNewPasswordValid: boolean;
    @observable email: string;
    @observable token: string;
    @observable state:
        | ''
        | 'expired'
        | 'no_exists'
        | 'no_valid_token'
        | 'error'
        | 'password_too_weak'
        | 'password_changed'
        | 'password_empty'
        | 'password_not_matched' = '';

    async resetPassword(token: string) {
        await UsersApi.resetPassword(this.newPassword, token);
    }

    async setPasswordConfirm(token: string) {
        await UsersApi.setPasswordConfirm(this.newPassword, token);
    }

    async validatePassword() {
        if (!this.isNewPasswordValid) {
            this.state = 'password_too_weak';
        } else if (this.newPassword !== this.confirmPassword) {
            this.state = 'password_not_matched';
        } else if (!this.newPassword || !this.confirmPassword) {
            this.state = 'password_empty';
        } else {
            this.resetPassword(this.token);
            this.state = 'password_changed';
        }
    }

    async confirmSetPassword() {
        if (!this.isNewPasswordValid) {
            this.state = 'password_too_weak';
        } else if (this.newPassword !== this.confirmPassword) {
            this.state = 'password_not_matched';
        } else if (!this.newPassword || !this.confirmPassword) {
            this.state = 'password_empty';
        } else {
            this.setPasswordConfirm(this.token);
            this.state = 'password_changed';
        }
    }

    async validateTokenConfirmAccount(token: string) {
        if (!token) {
            this.state = 'error';
            return;
        }
        const r = await UsersApi.validateConfirmAccountToken(token);
        if (r && r.expired) this.state = 'expired';
        if (r && !r.valid) {
            this.state = 'no_exists';
        } else if (r && r.valid && !r.expired) {
            this.token = token;
        } else this.state = 'error';
    }

    async validateTokenResetPassword(token: string) {
        if (!token) {
            this.state = 'no_valid_token';
            return;
        }

        // check interval
        // const i = await UsersApi.checkRequestInterval();

        const r = await UsersApi.validateResetPasswordToken(token);
        if (r && r.expired) this.state = 'expired';
        if (r && !r.valid) {
            this.state = 'no_exists';
        } else if (r && r.valid && !r.expired) {
            this.token = token;
        } else this.state = 'error';
    }

    async setPassword(value: string, name: string) {
        this[name] = value;
    }
}

export const ConfirmResetStore = new ConfirmResetStoreClass();
