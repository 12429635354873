import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const InvisibleLinkWrapper = styled(Link)({
    color: 'inherit',
    fontWeight: 'inherit',
    cursor: 'pointer',
    textDecoration: 'none'
});

export default InvisibleLinkWrapper;
