Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["setCookie"] = b.createRpcProxy("UsersApi", "setCookie", {
  "urlPrefix": "/rpc"
});
o["verifyLogin"] = b.createRpcProxy("UsersApi", "verifyLogin", {
  "urlPrefix": "/rpc"
});
o["login"] = b.createRpcProxy("UsersApi", "login", {
  "urlPrefix": "/rpc"
});
o["loginImpersonatePropertyUser"] = b.createRpcProxy("UsersApi", "loginImpersonatePropertyUser", {
  "urlPrefix": "/rpc"
});
o["logoutImpersonatePropertyUser"] = b.createRpcProxy("UsersApi", "logoutImpersonatePropertyUser", {
  "urlPrefix": "/rpc"
});
o["logout"] = b.createRpcProxy("UsersApi", "logout", {
  "urlPrefix": "/rpc"
});
o["sendPasswordResetInstructions"] = b.createRpcProxy("UsersApi", "sendPasswordResetInstructions", {
  "urlPrefix": "/rpc"
});
o["validateConfirmAccountToken"] = b.createRpcProxy("UsersApi", "validateConfirmAccountToken", {
  "urlPrefix": "/rpc"
});
o["validateResetPasswordToken"] = b.createRpcProxy("UsersApi", "validateResetPasswordToken", {
  "urlPrefix": "/rpc"
});
o["resetPassword"] = b.createRpcProxy("UsersApi", "resetPassword", {
  "urlPrefix": "/rpc"
});
o["setPasswordConfirm"] = b.createRpcProxy("UsersApi", "setPasswordConfirm", {
  "urlPrefix": "/rpc"
});
o["resetPasswordRequest"] = b.createRpcProxy("UsersApi", "resetPasswordRequest", {
  "urlPrefix": "/rpc"
});
o["getPropertyUsers"] = b.createRpcProxy("UsersApi", "getPropertyUsers", {
  "urlPrefix": "/rpc"
});
o["updateSignInAt"] = b.createRpcProxy("UsersApi", "updateSignInAt", {
  "urlPrefix": "/rpc"
});
o["changePassword"] = b.createRpcProxy("UsersApi", "changePassword", {
  "urlPrefix": "/rpc"
});