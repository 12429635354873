import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import PropertyTemplateForm, { AddPropertyTemplate } from '../PropertyTemplates/PropertyTemplateForm';
import useAddPropertyTemplate from '../../project-templates/hooks/PropertyTemplates/useAddPropertyTemplate';

const AddPropertyTemplatePage = () => {
    const navigate = useNavigate();

    const { mutate: savePropertyTemplate } = useAddPropertyTemplate();

    const onSubmit: SubmitHandler<AddPropertyTemplate> = (data) => {
        savePropertyTemplate(
            { property: data },
            {
                onSuccess: (res) => {
                    toast.success(L('added_property_template'));
                    navigate(`../${res.id}`);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_property_template')}</CardTitle>}
            mainArea={<PropertyTemplateForm onSubmit={onSubmit} />}
        />
    );
};

export default AddPropertyTemplatePage;
