import styled from '@emotion/styled';
import React, { useState } from 'react';
import { flexRender, Row, Table } from '@tanstack/react-table';
import { style } from '@app/shared/lib/Style';
import { useVirtualizer } from '@tanstack/react-virtual';
import SortArrows from '../../../components/Table/sortArrows/SortArrows';
import { colors } from '@app/shared/lib/Style/colors';
import { margin } from '@app/shared/lib/Style/margin';
import HorizontalDividerIcon from '../../../components/Icon/horizontal-divider.svg';

const Styles = styled.div<{ shouldFillWidth: boolean }>((props) => ({
    /* These styles are suggested for the table fill all available space in its containing element */
    // display: 'block',
    /* These styles are required for a horizontaly scrollable table overflow */
    '&': {
        maxWidth: '100%',
        minWidth: '100%',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        padding: '0',
    },

    '& .table': {
        borderSpacing: 0,
        width: props.shouldFillWidth ? 'calc(100vw - 375px)' : 'auto',
        minWidth: props.shouldFillWidth ? '100%' : 'auto',
        ['@media (max-width: 1025px)']: {
            width: props.shouldFillWidth ? '100vw' : 'auto',
        },

        '.th': {
            color: colors.secondary,
            padding: `0px 16px ${style.margin.m}px 0px`,
            fontSize: style.fontSize.epsilon,

            /* These styles are required for a scrollable body to align with the header properly */
            overflowX: 'hidden',
        },

        '.td': {
            borderTop: `1px solid ${style.colors.black50}`,
            padding: '16px 16px 16px 0px',
            fontSize: style.fontSize.centi,
            textAlign: 'start',
            overflowX: 'hidden',
        },

        '.tbody': {
            /* These styles are required for a scrollable table body */
            // overflowX: 'hidden'
        },

        '.th, .td': {
            margin: 0,
            /* In this example we use an absolutely position resizer,
       so this is required. */

            position: 'relative',
            ':last-child': {
                paddingRight: '0px',
            },
        },
        '.td .tr div:first-of-type': {
            paddingLeft: '0px',
        },
    },
}));

const TBodyContainer = styled.div({
    maxHeight: '600px',
});

const FlexContainer = styled.div({
    display: 'flex',
    gap: '',
    alignItems: 'center',
    width: '100%',
});

const TRow = styled.div({
    display: 'flex',
    flex: '1 0 auto',
    minWidth: '0px',
});

const ResizerContainer = styled.div({
    position: 'absolute',
    right: 0,
    top: 2,
    cursor: 'col-resize',
    userSelect: 'none',
    touchAction: 'none',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    paddingLeft: margin.s,
    paddingRight: margin.s,
});

const HorizontalDivider = styled(HorizontalDividerIcon)({
    width: '16px',
    height: 'auto',
    '& path': {
        fill: colors.secondary,
    },
});

export type Props<T> = {
    table: Table<T>;
    estimateSize: number;
    rowClickHandler?: (row: Row<T>) => void;
    shouldFillWidth?: boolean;
};

export default function GenericTableVirtualized<T>({
    table,
    estimateSize,
    rowClickHandler,
    shouldFillWidth = true,
}: Props<T>) {
    const [showResizeHandler, setShowResizeHandler] = useState(false);

    //we need a reference to the scrolling element for logic down below
    const tableContainerRef = React.useRef<HTMLDivElement>(null);

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        estimateSize: () => estimateSize,
        count: rows.length,
    });
    const { getVirtualItems, getTotalSize } = rowVirtualizer;

    const paddingTop = getVirtualItems().length > 0 ? getVirtualItems()?.[0]?.start || 0 : 0;
    const paddingBottom =
        getVirtualItems().length > 0
            ? getTotalSize() - (getVirtualItems()?.[getVirtualItems().length - 1]?.end || 0)
            : 0;

    return (
        <Styles shouldFillWidth={shouldFillWidth} ref={tableContainerRef}>
            <div className="table" role="table">
                <div>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <div
                            style={{
                                display: 'flex',
                                flex: '1 0 auto',
                                minWidth: '0px',
                            }}
                            className="tr"
                            key={headerGroup.id}
                            role="row"
                        >
                            {headerGroup.headers.map((header) => (
                                <div
                                    key={header.id}
                                    style={{
                                        boxSizing: 'border-box',
                                        position: 'relative',
                                        cursor: header.column.getCanSort() ? 'pointer' : '',
                                        justifyContent: 'flex-start',
                                        alignItems: 'flex-start',
                                        display: 'flex',
                                        width: header.getSize(),
                                        ...header.column.columnDef?.meta?.styles,
                                    }}
                                    className="th"
                                    role="columnheader"
                                    onMouseEnter={() => setShowResizeHandler(true)}
                                    onMouseLeave={() => setShowResizeHandler(false)}
                                >
                                    <FlexContainer>
                                        <FlexContainer
                                            {...{
                                                onClick: header.column.getToggleSortingHandler(),
                                            }}
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                            {header.column.getCanSort() && (
                                                <SortArrows
                                                    activeSort={
                                                        header.column.getIsSorted()
                                                            ? header.column.getIsSorted() === 'desc'
                                                                ? 'desc'
                                                                : 'asc'
                                                            : undefined
                                                    }
                                                />
                                            )}
                                        </FlexContainer>
                                        {header.column.getCanResize() && (
                                            <ResizerContainer
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                            >
                                                {showResizeHandler && <HorizontalDivider />}
                                            </ResizerContainer>
                                        )}
                                    </FlexContainer>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <TBodyContainer>
                    <div className="tbody" role="rowgroup">
                        {paddingTop > 0 && (
                            <div
                                className="tr"
                                role="row"
                                style={{
                                    display: 'flex',
                                    flex: '1 0 auto',
                                    minWidth: '0px',
                                }}
                            >
                                <div className="td" role="cell" style={{ height: `${paddingTop}px` }} />
                            </div>
                        )}
                        {getVirtualItems().map((virtualRow) => {
                            const row = rows[virtualRow.index];
                            return (
                                <TRow
                                    role="row"
                                    key={row.id}
                                    data-index={virtualRow.index}
                                    onClick={() => {
                                        if (!rowClickHandler) return;
                                        rowClickHandler(row);
                                    }}
                                    style={rowClickHandler ? { cursor: 'pointer' } : {}}
                                    ref={rowVirtualizer.measureElement}
                                >
                                    {row.getVisibleCells().map((cell) => (
                                        <div
                                            role="cell"
                                            className="td"
                                            key={cell.id}
                                            style={{
                                                boxSizing: 'border-box',
                                                minWidth: '0px',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                display: 'flex',
                                                width: cell.column.getSize(),
                                                ...cell.column.columnDef?.meta?.styles,
                                            }}
                                        >
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </div>
                                    ))}
                                </TRow>
                            );
                        })}
                        {paddingBottom > 0 && (
                            <div
                                className="tr"
                                role="row"
                                style={{
                                    display: 'flex',
                                    flex: '1 0 auto',
                                    minWidth: '0px',
                                }}
                            >
                                <div className="td" role="cell" style={{ height: `${paddingBottom}px` }} />
                            </div>
                        )}
                    </div>
                </TBodyContainer>
            </div>
        </Styles>
    );
}
