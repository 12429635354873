import { SupplierFilter } from '@app/api/public/AddonsApi';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';

type AddonReportContextType = {
    filter: SupplierFilter;
    setFilter: (filter: SupplierFilter) => void;
};

const AddonReportContext = createContext<AddonReportContextType>({} as AddonReportContextType);

const AddonReportProvider: FC = ({ children }) => {
    const [filter, updateFilter] = useState<SupplierFilter>({} as SupplierFilter);

    useEffect(() => {
        return undefined;
    }, [filter]);

    const setFilter = (filter: SupplierFilter) => {
        updateFilter(filter);
    };
    const contextValues = {
        filter,
        setFilter
    };

    return <AddonReportContext.Provider value={contextValues}>{children}</AddonReportContext.Provider>;
};

const useAddonReport = () => {
    return useContext(AddonReportContext);
};

export { AddonReportContext, useAddonReport, AddonReportProvider as default };
