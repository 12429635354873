import { observable } from 'mobx';
import SettingsApi, { FeatureProjects, SettingProject, MailTemplateFile } from '@app/api/public/SettingsApi';
// import { FeatureProjects, SettingProject, MailTemplateFile } from '@app/api/models/Settings';
import AccountSettingsApi from '@app/api/public/AccountSettingsApi';
import { AccountAddonInfo } from '@app/api/models/AccountSettings';
import { Project } from '../../../api/models/Projects';
import { ProjectFeaturesList } from '@app/api/models/Settings';

class SettingStoreClass {
    @observable projectTicketsFeature: FeatureProjects[];
    @observable projectAddonsFeature: FeatureProjects[];
    @observable projectMaintenanceFeature: FeatureProjects[];
    @observable projectsTickets: SettingProject[] = [];
    @observable projectsAddons: SettingProject[] = [];
    @observable projectsMaintenance: SettingProject[] = [];
    @observable newMailTemplateFile: MailTemplateFile;

    @observable projects: Project[];

    @observable searchFilter: string[] = [];

    @observable errors: any = {};
    @observable accountAddonInfo: Partial<AccountAddonInfo> = {};

    @observable features: ProjectFeaturesList[] = [];

    async saveFeatures(projectId: number, feature: string) {
        const r = await SettingsApi.saveFeatureProjects(projectId, feature);
        this.listProjects();
        return r;
    }

    async removeFeatures(projectId: number, feature: string) {
        const r = await SettingsApi.removeFeatureProjects(projectId, feature);
        this.listProjects();
        return r;
    }

    async setValueTerms(value: string, name: string) {
        this.accountAddonInfo[name] = value;
        localStorage.setItem('terms', JSON.stringify(this.accountAddonInfo));
    }

    async listAccountAddonsInfo() {
        this.accountAddonInfo = (await AccountSettingsApi.listAccountAddonInfo()) || {};
    }

    async saveAccountAddonInfo() {
        await AccountSettingsApi.saveAccountAddonInfo(this.accountAddonInfo);
    }

    // async getFeatures(id: number, name: string) {
    //     return {
    //         name: name,
    //         id: id,
    //         list: await SettingsApi.listFeaturesAreaCards(id)
    //     };
    // }

    async listProjects() {
        this.projects = await SettingsApi.listProjects();
    }

    async listFeaturesAreaCards() {
        this.features = await SettingsApi.listFeaturesAreaCardsAccounts();
    }
}

export const SettingStore = new SettingStoreClass();
