import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { L } from '../../../lib/i18n';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { SubmitHandler } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useGetSpaceTypes } from '../../binders/hooks/spaceHooks/spaceQueries';
import { AddSpace } from '../../binders/schemas';
import SpaceForm from '../../binders/components/Spaces/SpaceForm';
import useSaveSpaceOnPropertyTemplate from '../hooks/useSaveSpaceOnPropertyTemplate';

const AddPropertyTemplateSpace = () => {
    const { id: propertyId } = useParams<{ id: string }>();
    return propertyId ? <Area propertyId={+propertyId} /> : null;
};

const Area = ({ propertyId }: { propertyId: number }) => {
    const { data: spaceTypes } = useGetSpaceTypes();
    const spaceTypeOptions = useMemo(
        () =>
            spaceTypes?.map((type) => {
                return {
                    value: type.id,
                    label: L(type.name),
                };
            }) || [],
        [spaceTypes],
    );

    const { mutate: saveSpace } = useSaveSpaceOnPropertyTemplate();

    const navigate = useNavigate();

    const onSubmit: SubmitHandler<AddSpace> = (data) => {
        saveSpace(
            { data, propertyId: +propertyId },
            {
                onSuccess: (variables) => {
                    toast.success(L('added_space'));
                    if (variables.id) navigate(`../${variables.id}`);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    return (
        <CardWithDivider
            topArea={<CardTitle>{L('add_space')}</CardTitle>}
            mainArea={<SpaceForm onSubmit={onSubmit} spaceTypeOptions={spaceTypeOptions} />}
        />
    );
};

export default AddPropertyTemplateSpace;
