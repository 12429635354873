import React from 'react';
import { Redirect } from 'react-router-dom';
import { Loading } from '../../../components/Loading/Loading';
import useGetProjects from '../../../lib/hooks/useGetProjects';

const MaintenaceListPage = () => {
    const projects = useGetProjects('maintenance');

    if (projects.status === 'loading') return <Loading />;

    if (!projects.data || projects?.data?.length === 0) return <div>Kunde inte hämta projekt</div>;
    return <Redirect to={`/maintenance/${projects.data[0].id}`} />;
};

export default MaintenaceListPage;
