import React, { HTMLAttributes } from 'react';
import mime from 'mime';
import DocumentDropdown from './DocumentDropdown/DocumentDropdown';
import useGetSignedUrlById from '../../lib/hooks/useGetSignedUrlById';

type Props = {
    documentDetail: {
        originalId: string;
        filename: string;
        name: string;
        document_subtype: number;
        document_type: number;
    };
    deleteDocument?: () => void;
    editDocumentName?: (name: string) => void;
    updateDocType?: (document_type: number, document_subtype: number) => void;
} & HTMLAttributes<HTMLDivElement>;
const Document = ({
    documentDetail: { originalId, filename, name, document_subtype, document_type },
    ...rest
}: Props) => {
    const { data: url } = useGetSignedUrlById(originalId);
    const fileType = mime.getType(filename);
    return (
        <DocumentDropdown
            name={name}
            url={url}
            fileType={fileType || ''}
            typesOfDoc={{ document_type, document_subtype }}
            {...rest}
        />
    );
};

export default Document;
