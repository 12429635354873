import React from 'react';
import { L } from '../../../../lib/i18n';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import Input from '../../../../components/Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import H2 from '../../../../components/typography/H2';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import { RouterOutput } from '../../../../lib/trpc';
import ReactSelect from '../../../../components/Dropdown/ReactSelect';
import FormatOptionsLabel from '../FormatOptionsLabel';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    minWidth: '400px',
    padding: '1rem',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const AddPropertyFromTemplateSchema = z
    .object({
        property: z.object({ value: z.number(), label: z.string() }),
        newName: z.string(),
        templateLevel: z.enum(['project', 'account']),
        project: z.object({ value: z.number(), label: z.string() }).nullish(),
    })
    .superRefine((val, ctx) => {
        if (val.templateLevel === 'project' && !val.project) {
            return ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'error_required',
                path: ['project'],
            });
        }
    });
export type FormType = z.infer<typeof AddPropertyFromTemplateSchema>;

type Props<T> = {
    properties: T;
    onSubmit: SubmitHandler<{
        property: {
            label: string;
            value: number;
        };
        newName: string;
        templateLevel: 'project' | 'account';
        project?:
            | {
                  label: string;
                  value: number;
              }
            | null
            | undefined;
    }>;
    propertyId: number | null;
    projects: RouterOutput['BindersApi']['getProjectsLean'];
    projectId?: number;
    headerTitle?: string;
};

const TemplateFromPropertyForm = <T extends { id: number; name: string; template: boolean }[]>({
    properties,
    onSubmit,
    propertyId,
    projects,
    projectId,
    headerTitle,
}: Props<T>) => {
    const originPropertyOptions = properties.map((property) => ({
        value: property.id,
        label: property.name,
        template: property.template,
    }));
    const projectOptions = projects.map((project) => ({
        value: project.id,
        label: project.name,
        template: project.template,
    }));

    const templateLevelOptions = [
        { value: 'account', label: L('organisation') },
        { value: 'project', label: L('project') },
    ];
    const initialSelectedProperty = originPropertyOptions.find((property) => property.value === propertyId);
    const projectIdInitialValue = projectOptions.find((project) => project.value === projectId);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
    } = useForm<FormType>({
        resolver: zodResolver(AddPropertyFromTemplateSchema),
        defaultValues: {
            property: initialSelectedProperty ?? undefined,
            newName: initialSelectedProperty?.label,
            templateLevel: 'project',
            project: projectIdInitialValue ?? null,
        },
    });
    const templateLevelFormValue = useWatch({ control, name: 'templateLevel' });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <H2>{headerTitle ?? L('new_template_from_property')}</H2>
            <ErrorAndInputWrapper errorMsg={L(errors.property?.message ?? '')}>
                <Label title={L('property')} />
                {/* <Controller
                    control={control}
                    name="propertyId"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.propertyId}
                            options={originPropertyOptions}
                            onValueChange={(val) => {
                                onChange(+val);
                                const correctLabel = properties?.find((property) => property.id === +val)?.name ?? '';
                                setValue('newName', correctLabel);
                            }}
                            value={value}
                        />
                    )}
                /> */}
                <Controller
                    control={control}
                    name="property"
                    render={({ field: { ref, ...rest } }) => (
                        <ReactSelect
                            {...rest}
                            placeholder={L('property')}
                            options={originPropertyOptions}
                            isSearchable={true}
                            width={'140px'}
                            formatOptionLabel={FormatOptionsLabel}
                        />
                    )}
                />
            </ErrorAndInputWrapper>
            <ErrorAndInputWrapper errorMsg={L(errors.newName?.message ?? '')}>
                <Label title={L('name')} />
                <RestyledInput isError={!!errors.newName} type="text" {...register('newName')} />
            </ErrorAndInputWrapper>
            <ErrorAndInputWrapper errorMsg={L(errors.property?.message ?? '')}>
                <Label title={L('template_level')} />
                <Controller
                    control={control}
                    name="templateLevel"
                    render={({ field: { onChange, ref, ...rest } }) => (
                        <RadixSelect
                            isError={!!errors.templateLevel}
                            options={templateLevelOptions}
                            {...rest}
                            onValueChange={(val) => {
                                if (val === 'account') {
                                    setValue('project', null);
                                }
                                onChange(val);
                            }}
                        />
                    )}
                />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper
                style={templateLevelFormValue === 'account' ? { display: 'hidden' } : {}}
                errorMsg={L(errors.project?.message ?? '')}
            >
                <Label title={L('project_literally')} />
                <Controller
                    control={control}
                    name="project"
                    render={({ field: { ref, ...rest } }) => (
                        <ReactSelect
                            {...rest}
                            placeholder={L('project')}
                            options={projectOptions}
                            isSearchable={true}
                            width={'140px'}
                            formatOptionLabel={FormatOptionsLabel}
                            isDisabled={templateLevelFormValue === 'account'}
                        />
                    )}
                />
            </ErrorAndInputWrapper>

            <div style={{ marginTop: style.margin.m, display: 'flex', justifyContent: 'end' }}>
                <Button>{L('save')}</Button>
            </div>
        </Form>
    );
};

export default TemplateFromPropertyForm;
