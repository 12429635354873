import { style } from '@app/shared/lib/Style';
import styled from '@emotion/styled';
import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import { Button, DropdownButton } from '@ourliving/ourliving-ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { L } from '../../../../lib/i18n';
import Quill from '../../../../components/Quill/Quill';
import { z } from 'zod';
import { LocalImage, Photo } from '../../../project-templates/components/Photo';
import { useLocalStorage } from 'react-use';

const AddContentInformationSchema = z.object({
    title: z.string().min(1, { message: 'required' }),
    description: z.string().min(1, { message: 'required' }),
    image: z.instanceof(File, { message: 'please_select_a_file' }).optional(),
    locked: z.boolean(),
});

export type AddContentInformation = z.infer<typeof AddContentInformationSchema>;

const InnerFormWrapper = styled.div({
    display: 'grid',
    gap: '0 1rem',
    width: '100%',
    gridTemplateColumns: '1fr',
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
});

type Props = {
    onSubmit: (data: AddContentInformation, type: 'publish' | 'draft') => void;
    defaultValues?: Partial<AddContentInformation>;
    imageUrl?: string;
};

const ContentInformationForm = ({ onSubmit, defaultValues, imageUrl }: Props) => {
    const [localSubmitButtonState, setSubmitButtonState] = useLocalStorage<'save_to_draft' | 'save_and_publish'>(
        'boardCreateNewInformation',
        'save_and_publish',
    );
    const submitButtonState = localSubmitButtonState || 'save_and_publish';
    const {
        register,
        handleSubmit,
        control,
        reset,
        getValues,
        formState: { errors },
    } = useForm<AddContentInformation>({
        resolver: zodResolver(AddContentInformationSchema),
        defaultValues: { locked: false, ...defaultValues },
    });

    const [localImage, setLocalImage] = useState('');

    const file = useWatch({ name: 'image', control });
    const inputRef = useRef<ElementRef<'input'> | null>(null);

    useEffect(() => {
        if (!file) {
            setLocalImage('');
            return;
        }
        const myImg = URL.createObjectURL(file);
        setLocalImage(myImg);
    }, [file]);

    const Image = () => {
        if (localImage) {
            return (
                <LocalImage
                    url={localImage}
                    handleRemove={() => {
                        if (!localImage) return;
                        reset({ ...getValues(), image: undefined });
                        if (inputRef.current) inputRef.current.value = '';
                    }}
                />
            );
        }
        if (imageUrl) {
            return <Photo url={imageUrl} />;
        }
        return null;
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InnerFormWrapper>
                <ErrorAndInputWrapper errorMsg={L(errors.image?.message ?? '')}>
                    <Label title={L('image')} />
                    <ErrorAndInputWrapper errorMsg={errors.image?.message ? L(errors.image?.message) : ''}>
                        <div style={{ width: '200px' }}>
                            <Image />
                        </div>

                        <Controller
                            control={control}
                            name="image"
                            render={({ field: { onChange, value, ...field } }) => {
                                return (
                                    <input
                                        {...field}
                                        style={{ display: 'none' }}
                                        type="file"
                                        accept="image/*"
                                        size={100000000}
                                        ref={inputRef}
                                        // type error
                                        value={value?.fileName}
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files.length > 0) {
                                                onChange(e.target.files[0]);
                                            }
                                        }}
                                    />
                                );
                            }}
                        />

                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                inputRef?.current?.click();
                            }}
                        >
                            {L('choose_image')}
                        </Button>
                    </ErrorAndInputWrapper>
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.title?.message ?? '')}>
                    <Label title={L('title')} />
                    <InputWithError isError={!!errors.title} type="text" {...register('title')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.description?.message ?? '')}>
                    <Label title={L('description')}>
                        <Controller
                            name="description"
                            control={control}
                            render={({ field }) => {
                                return <Quill {...field} placeholder={`${L('write_something')}...`} />;
                            }}
                        />
                    </Label>
                </ErrorAndInputWrapper>
            </InnerFormWrapper>

            <ButtonWrapper>
                <DropdownButton
                    optionId={submitButtonState}
                    setOptionId={(value) => setSubmitButtonState(value)}
                    options={[
                        {
                            id: 'save_to_draft',
                            dropdownItem: (
                                <DropdownButton.DropdownItem>{L('save_to_draft')}</DropdownButton.DropdownItem>
                            ),
                            button: (
                                <DropdownButton.Button
                                    onClick={handleSubmit((formValues) => onSubmit(formValues, 'draft'))}
                                >
                                    {L('save_to_draft')}
                                </DropdownButton.Button>
                            ),
                        },
                        {
                            id: 'save_and_publish',
                            dropdownItem: (
                                <DropdownButton.DropdownItem>{L('save_and_publish')}</DropdownButton.DropdownItem>
                            ),
                            button: (
                                <DropdownButton.Button
                                    onClick={handleSubmit((formValues) => onSubmit(formValues, 'publish'))}
                                >
                                    {L('save_and_publish')}
                                </DropdownButton.Button>
                            ),
                        },
                    ]}
                />
            </ButtonWrapper>
        </Form>
    );
};

export default ContentInformationForm;
