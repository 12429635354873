import * as React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import { useObserver } from 'mobx-react';
import { useQuerystring, usePromise } from '../../../lib/react';
import { Loading } from '../../../components/Loading/Loading';
import { Link } from 'react-router-dom';
import { ValidateToken } from './ValidateToken';
import Logo from '../../../components/Logo/Logo';

export interface PasswordToken {
    token: string;
}

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const SuccessMessage = styled.strong({
    color: style.colors.primary,
    textAlign: 'center'
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const LoginForm = styled.div({
    width: '80%'
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit'
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center'
});

const Form = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px'
});

export const ConfirmAccountPage = () => {
    const [tokens] = useQuerystring<PasswordToken>();

    const token = Object.keys(tokens);

    const [ready] = usePromise(async () => {
        ValidateToken(location.pathname, tokens[token[0]]);
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }
        return (
            <Background>
                <Form>
                    <StyledLogo />

                    <LoginForm>
                        <Sucess>
                            <SuccessMessage>{L('password_reset_account_active')}</SuccessMessage>
                        </Sucess>
                        <ButtonMenu>
                            <Link to="/">
                                <Button style={{ marginRight: '0px' }}>{L('to_login_page')}</Button>
                            </Link>
                        </ButtonMenu>
                    </LoginForm>
                </Form>
            </Background>
        );
    });
};
