import { useQuery } from 'react-query';
import { trpc } from '../../../lib/trpc';

export const accountTodoQueryKeys = {
    all: [{ scope: 'account_todos' }] as const,
    lists: () => [{ ...accountTodoQueryKeys.all[0], entity: 'list' }] as const,
    list: (params: { search?: string }) => [{ ...accountTodoQueryKeys.lists()[0], params }] as const,
    details: () => [{ ...accountTodoQueryKeys.all[0], entity: 'detail' }] as const,
    detail: (params: { id?: number }) => [{ ...accountTodoQueryKeys.details()[0], params }] as const,
    recurrency: () => [{ ...accountTodoQueryKeys.all[0], entity: 'recurrency' }] as const,
    entityTypes: () => [{ ...accountTodoQueryKeys.all[0], entity: 'entityType' }] as const,
    entitySubtypes: () => [{ ...accountTodoQueryKeys.all[0], entity: 'entitySubtypes' }] as const,
    entitySubtype: (params: { entityType?: string }) =>
        [{ ...accountTodoQueryKeys.entitySubtypes[0], params }] as const,
};

const useGetTodos = () => {
    return useQuery({
        queryKey: accountTodoQueryKeys.list({}),
        queryFn: ({ queryKey }) => {
            return trpc.AccountTodoApi.listTodos.query({});
        },
    });
};

const useGetTodoDetail = ({ todoId }: { todoId?: number }) => {
    return useQuery({
        queryKey: accountTodoQueryKeys.detail({ id: todoId }),
        queryFn: ({ queryKey }) => {
            const {
                params: { id },
            } = queryKey[0];
            if (!id) return;

            return trpc.AccountTodoApi.todo.query({
                todoId: id,
            });
        },
        enabled: !!todoId,
    });
};

const useGetTodoRecurrency = () => {
    return useQuery({
        queryKey: accountTodoQueryKeys.recurrency(),
        queryFn: () => {
            return trpc.AccountTodoApi.recurrency.query();
        },
    });
};
const useGetTodoEntityType = () => {
    return useQuery({
        queryKey: accountTodoQueryKeys.entityTypes(),
        queryFn: () => {
            return trpc.AccountTodoApi.entityTypes.query();
        },
    });
};

const useGetTodoEntitySubtype = ({ entityType }: { entityType?: string }) => {
    return useQuery({
        queryKey: accountTodoQueryKeys.entitySubtype({ entityType }),
        queryFn: ({ queryKey }) => {
            const {
                params: { entityType },
            } = queryKey[0];

            return trpc.AccountTodoApi.entitySubtypes.query({
                entityType: entityType,
            });
        },
        enabled: !!entityType,
    });
};

export { useGetTodos, useGetTodoDetail, useGetTodoRecurrency, useGetTodoEntityType, useGetTodoEntitySubtype };
