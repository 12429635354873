import { useQuery } from 'react-query';
import InventoryApi from '@app/api/public/InventoryApi';

const getProjects = async () => {
    const r = await InventoryApi.listProjects();
    return r;
};

const useGetProjects = () => {
    return useQuery('getProjects', getProjects);
};

export default useGetProjects;
