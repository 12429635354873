import * as React from 'react';
import { LoginStore } from './LoginStore';
import { ForgottenPasswordStore } from './ForgottenPasswordStore';
import { useObserver } from 'mobx-react';
import { Button } from '@ourliving/ourliving-ui';
import { history } from '../../lib/routing';
import { L } from '../../lib/i18n';
import { style } from '../../components/Shared/Style';
import styled from '@emotion/styled';
import { Dropdown } from '../../components/Dropdown/Dropdown';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { usePromise } from '../../lib/react';
import { Loading } from '../../components/Loading/Loading';
import Logo from '../../components/Logo/Logo';
import PushPermission from '../../components/PushPermission/PushPermission';
import { handleGetToken, isSupported } from '../../firebase';
import toast from 'react-hot-toast';

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TextField = styled.div({
    paddingBottom: style.margin.ml
});

const LoginCard = styled.div({
    width: '90%'
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    lineHeight: '32px',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold
});

const LoginInput = styled.input({
    width: '100%',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: `${style.margin.s} ${style.margin.m}`,
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit'
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l,
    minHeight: '19px'
});
const ErrorMessage = styled.strong({
    color: 'red'
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center'
});

const Card = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px'
});

// TODO
// ::placeholder {
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: 0.3px;
//     color: #4B506D;
//     opacity: 0.4;
// }

// ::-ms-input-placeholder {
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: 0.3px;
//     color: #4B506D;
//     opacity: 0.4;
//   }

interface Props {
    children: any;
}

export const LoginForm = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [project, setProject] = useState('');

    const [ready] = usePromise(async () => {
        await LoginStore.verifyLogin();
        ForgottenPasswordStore.state = '';
    }, []);

    const login = evt => {
        evt.preventDefault();
        LoginStore.login(username, password);
    };

    const multiLogin = evt => {
        evt.preventDefault();
        LoginStore.multiLogin(project);
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return (
            <form onSubmit={login} key={history.location.pathname}>
                <Background>
                    <Card>
                        <StyledLogo />
                        <LoginCard>
                            {LoginStore.state !== 'projects' ? (
                                <>
                                    <Error>
                                        {LoginStore.state === 'error' && (
                                            <ErrorMessage>{L('login_error')}</ErrorMessage>
                                        )}
                                        {LoginStore.state === 'failed' && (
                                            <ErrorMessage>{L('login_failed')}</ErrorMessage>
                                        )}
                                    </Error>
                                    <div>
                                        <Header>{L('email')}</Header>
                                        <TextField>
                                            <LoginInput type="text" onChange={e => setUsername(e.target.value)} />
                                        </TextField>
                                    </div>
                                    <div>
                                        <Header>{L('password')}</Header>
                                        <TextField>
                                            <LoginInput type="password" onChange={e => setPassword(e.target.value)} />
                                        </TextField>
                                    </div>
                                    <ButtonMenu>
                                        <Button type={'submit'} onClick={login}>
                                            {L('login')}
                                        </Button>
                                        <StyledNavLink to="/forgottenpassword">{L('forgot_password')}?</StyledNavLink>
                                    </ButtonMenu>
                                </>
                            ) : (
                                <>
                                    <Header>{L('projects')}</Header>
                                    <Dropdown
                                        options={LoginStore.projects.map(p => ({ label: p.name, value: p.id }))}
                                        onChange={value => setProject(value)}
                                        value={project}
                                    />

                                    <ButtonMenu>
                                        <Button
                                            style={{ marginTop: style.margin.l, width: '100%', marginRight: '0px' }}
                                            onClick={multiLogin}
                                        >
                                            {L('continue')}
                                        </Button>
                                    </ButtonMenu>
                                </>
                            )}
                        </LoginCard>
                    </Card>
                </Background>
            </form>
        );
    });
};

export const LoginPage = (props: { children: any }) => {
    return useObserver(() => {
        React.useEffect(() => {
            if ('serviceWorker' in navigator && 'PushManager' in window && 'Notification' in window) {
                if (Notification.permission === 'default') {
                    if (LoginStore.state === 'logged-in') {
                        isSupported()
                            .then(res => {
                                console.log(res);
                                if (!res) return;
                                toast(toast => <PushPermission toastId={toast.id} />, {
                                    duration: Infinity
                                });
                            })
                            .catch(err => console.log(err));
                    }
                }
                if (Notification.permission === 'granted') {
                    isSupported().then(res => {
                        if (!res) return;
                        handleGetToken();
                    });
                }
            }
        }, [LoginStore.state]);
        if (LoginStore.state === 'logged-in') {
            return <React.Fragment>{props.children}</React.Fragment>;
        }
        return <LoginForm />;
    });
};
