import { ConfirmResetStore } from '../ConfirmResetStore';

export const ValidateToken = async (location, token) => {
    {
        console.log(token);
        location === '/resetpassword'
            ? await ConfirmResetStore.validateTokenResetPassword(token)
            : await ConfirmResetStore.validateTokenConfirmAccount(token);
    }
};
