import React, { useMemo } from 'react';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom-v5-compat';
import CardTitle from '../../../../components/typography/CardTitle';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import styled from '@emotion/styled';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import useSaveProject from '../../hooks/projectHooks/useSaveProject';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import { AddProject, AddProjectSchema } from '../../schemas';
import { useGetProjectTypes } from '../../hooks/projectHooks/projectTypeQueries';

type ProjectTypeOptions = { value: number | undefined; label: string }[] | undefined;

const StyledForm = styled.form({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0 1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const AddProjectPage = () => {
    const { data: projectTypeData } = useGetProjectTypes();
    const projectTypeOptions = useMemo<ProjectTypeOptions>(
        () => projectTypeData?.map((item) => ({ value: item.id, label: L(item.name) })),
        [projectTypeData],
    );
    const navigate = useNavigate();

    const { mutate: addProject } = useSaveProject();

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddProject>({ resolver: zodResolver(AddProjectSchema) });

    const onSubmit: SubmitHandler<AddProject> = (data) => {
        const dataToSave: AddProject = {
            ...data,
        };
        return addProject(dataToSave, {
            onSuccess: (data) => {
                toast.success(L('added_project'));
                navigate(`../${data.id}`);
            },
            onError: () => {
                toast.error(L('added_project_failed'));
            },
        });
    };

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="..">{L('active_projects')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{L('new_project')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            {projectTypeOptions && (
                <CardWithDivider
                    topArea={<CardTitle>{L('add_project')}</CardTitle>}
                    mainArea={
                        <StyledForm onSubmit={handleSubmit(onSubmit)}>
                            <ErrorAndInputWrapper errorMsg={errors.name?.message ? L(errors.name?.message) : ''}>
                                <Label title={L('project_name')} />
                                <RestyledInput isError={!!errors.name} type="text" {...register('name')} />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper errorMsg={errors.address?.message ? L(errors.address?.message) : ''}>
                                <Label title={L('address')} />
                                <RestyledInput isError={!!errors.address} type="text" {...register('address')} />
                            </ErrorAndInputWrapper>
                            <div style={{ display: 'flex', columnGap: '1rem' }}>
                                <div style={{ width: '50%' }}>
                                    <ErrorAndInputWrapper errorMsg={errors.zip?.message ? L(errors.zip?.message) : ''}>
                                        <Label title={L('postalCode')} />
                                        <RestyledInput isError={!!errors.zip} type="text" {...register('zip')} />
                                    </ErrorAndInputWrapper>
                                </div>
                                <div style={{ width: '50%' }}>
                                    <ErrorAndInputWrapper
                                        errorMsg={errors.city?.message ? L(errors.city?.message) : ''}
                                    >
                                        <Label title={L('postal_locality')} />
                                        <RestyledInput isError={!!errors.city} type="text" {...register('city')} />
                                    </ErrorAndInputWrapper>
                                </div>
                            </div>
                            <ErrorAndInputWrapper errorMsg={errors.commune?.message ? L(errors.commune?.message) : ''}>
                                <Label title={L('commune')} />
                                <RestyledInput isError={!!errors.commune} type="text" {...register('commune')} />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper
                                errorMsg={errors.project_type?.message ? L(errors.project_type?.message) : ''}
                            >
                                <Label title={L('project_type_translation')} />
                                <Controller
                                    control={control}
                                    name="project_type"
                                    render={({ field: { onChange, value } }) => (
                                        <RadixSelect
                                            isError={!!errors.project_type}
                                            onValueChange={onChange}
                                            value={value}
                                            placeholder={L('please_select')}
                                            options={projectTypeOptions}
                                        />
                                    )}
                                />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper
                                errorMsg={errors.property_count?.message ? L(errors.property_count?.message) : ''}
                            >
                                <Label title={L('no_properties')} />
                                <RestyledInput
                                    isError={!!errors.property_count}
                                    type="number"
                                    {...register('property_count')}
                                />
                            </ErrorAndInputWrapper>

                            <ErrorAndInputWrapper
                                errorMsg={errors.access_date?.message ? L(errors.access_date?.message) : ''}
                            >
                                <Label title={L('access_date_translation')} />
                                <RestyledInput
                                    isError={!!errors.access_date}
                                    type="date"
                                    {...register('access_date')}
                                />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper
                                errorMsg={
                                    errors.addon_preview_date?.message ? L(errors.addon_preview_date?.message) : ''
                                }
                            >
                                <Label title={L('addon_start_date_preview')} />
                                <RestyledInput
                                    isError={!!errors.addon_preview_date}
                                    type="date"
                                    {...register('addon_preview_date')}
                                />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper
                                errorMsg={errors.addon_start_date?.message ? L(errors.addon_start_date?.message) : ''}
                            >
                                <Label title={L('addon_start_date')} />
                                <RestyledInput
                                    isError={!!errors.addon_start_date}
                                    type={'date'}
                                    {...register('addon_start_date')}
                                />
                            </ErrorAndInputWrapper>
                            <ErrorAndInputWrapper
                                errorMsg={errors.addon_end_date?.message ? L(errors.addon_end_date?.message) : ''}
                            >
                                <Label title={L('addon_end_date_translation')} />
                                <RestyledInput
                                    isError={!!errors.addon_end_date}
                                    type="date"
                                    {...register('addon_end_date')}
                                />
                            </ErrorAndInputWrapper>
                            <div style={{ gridColumn: '1 / 2', marginTop: '1rem' }}>
                                <Button role="submit">{L('save')}</Button>
                            </div>
                        </StyledForm>
                    }
                />
            )}
        </div>
    );
};

export default AddProjectPage;
