import React from 'react';
import { Route } from 'react-router-dom';
import { MaintenancePage } from './MaintenancePage';
import MaintenaceListPage from './page/MaintenaceListPage';

export const routes = [
    <Route key="0" exact path="/maintenance" component={MaintenaceListPage} />,
    <Route key="1" path="/maintenance/:projectId" component={MaintenancePage} />
];

export const navigation = [
    { name: 'Planerat underhåll', link: '/maintenance', icon: 'Maintenance', match: '/maintenance/:projectId' }
];
