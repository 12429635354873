import styled from '@emotion/styled';
import AddMark from '../../../../components/Icon/e-add-1.svg';

export const AddIcon = styled(AddMark)({
    alignSelf: 'center',
    gridArea: 'icon',
    height: '12px',
    width: '12px',
    fill: 'black',
    stroke: 'black'
});
