import { L } from '../../../lib/i18n';
import React from 'react';
import { TOption } from './CopyTo';
import { colors } from '@app/shared/lib/Style/colors';
import styled from '@emotion/styled';

const Col = styled.div({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

export default ({ value, label, template }: TOption) => (
    <Col>
        <div>{label}</div>
        <div style={{ marginLeft: '5px', color: colors.primary }}>{template && L('template')}</div>
    </Col>
);
