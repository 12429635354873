import React from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom-v5-compat';
import toast from 'react-hot-toast';
import {
    ContentInformationQueryKeys,
    useGetContentOrgInformationItem,
} from '../../project-templates/hooks/ContentInformation/contentInformationQueries';
import {
    useUpdateContentOrgInformation,
    useUpdateDraftContentOrgInformation,
} from '../../project-templates/hooks/ContentInformation/useUpdateContentInformation';
import ContentInformationForm from '../components/ContentInformation/ContentInformationForm';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import GroupDocumentsArea from '../../../components/document/GroupDocumentsArea/GroupDocumentsArea';
import { uploadToAws } from '../../project-templates/hooks/ContentInformation/uploadToAws';
import { trpc } from '../../../lib/trpc';
import { L } from '../../../lib/i18n';
import H1 from '../../../components/typography/H1';
import { z } from 'zod';

const Wrapper = styled.div({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    alignItems: 'start',
    paddingBottom: '2rem',

    '& > *': {
        flexBasis: '600px',
        flexGrow: 1,
    },
});

const AddContentInformationSchema = z.object({
    title: z.string().min(1, { message: 'required' }),
    description: z.string().min(1, { message: 'required' }),
    image: z.instanceof(File, { message: 'please_select_a_file' }).optional(),
    locked: z.boolean(),
});

const ContentInformationDetailPage = () => {
    const { id } = useParams<{ id: string }>();
    return id ? <ContentInformationDetail contentInfoId={+id} /> : null;
};

const ContentInformationDetail = ({ contentInfoId }: { contentInfoId: number }) => {
    const { data: contentInformation, status } = useGetContentOrgInformationItem({
        contentInfoId: +contentInfoId,
    });

    const { mutate: updateContentInformationItem } = useUpdateContentOrgInformation();
    const { mutate: updateDraftContentInformationItem } = useUpdateDraftContentOrgInformation();

    const onSubmit = (data: z.infer<typeof AddContentInformationSchema>, type: 'publish' | 'draft') => {
        if (type === 'publish') {
            updateContentInformationItem(
                {
                    information: data,
                    contentInfoId: +contentInfoId,
                    file: data.image,
                },
                {
                    onSuccess: () => {
                        toast.success(L('content_information_updated'));
                    },
                    onError: () => {
                        toast.error(L('an_error_occurred'));
                    },
                },
            );
        } else {
            updateDraftContentInformationItem(
                {
                    information: data,
                    contentInfoId: +contentInfoId,
                    file: data.image,
                },
                {
                    onSuccess: () => {
                        toast.success(L('content_information_updated'));
                    },
                    onError: () => {
                        toast.error(L('an_error_occurred'));
                    },
                },
            );
        }
    };

    return (
        <>
            <H1>{contentInformation?.title}</H1>
            <Wrapper>
                <CardWithDivider
                    topArea={<CardTitle>{L('edit_information')}</CardTitle>}
                    mainArea={
                        status !== 'loading' && (
                            <ContentInformationForm
                                imageUrl={contentInformation?.url}
                                defaultValues={{
                                    ...contentInformation,
                                    title: contentInformation?.title ?? undefined,

                                    description: contentInformation?.description ?? undefined,
                                    image: undefined,
                                }}
                                onSubmit={onSubmit}
                            />
                        )
                    }
                />
                <CardWithDivider
                    topArea={<CardTitle>{L('document')}</CardTitle>}
                    mainArea={
                        <div>
                            <GroupDocumentsArea
                                document={{
                                    get: async () => {
                                        return (
                                            await trpc.ContentInformationApi.getOrgDocuments.query({
                                                contentInformationId: contentInfoId,
                                            })
                                        ).map((doc) => ({
                                            id: doc.id,
                                            name: doc.name || '',
                                            type: doc.document_data?.original.metadata.mime_type || '',
                                            url: doc.url,
                                            groupId: doc.groupId,
                                            sort: doc.sort,
                                        }));
                                    },
                                    save: async (input) => {
                                        const response = await trpc.ContentInformationApi.addOrgDocument.mutate({
                                            file: {
                                                name: input.name,
                                                size: input.file.size,
                                                type: input.file.type,
                                            },
                                            groupId: input.documentGroupId,
                                            contentInformationId: contentInfoId,
                                        });
                                        if (response.preSingedUrl) {
                                            await uploadToAws({
                                                file: input.file,
                                                preSingedUrl: response.preSingedUrl,
                                                cleanup: async () => {
                                                    return await trpc.ContentInformationApi.removeOrgDocument.mutate({
                                                        documentId: response.documentId,
                                                        contentInformationId: contentInfoId,
                                                    });
                                                },
                                            });
                                        }
                                    },
                                    sort: async ({ sortedArray }) => {
                                        return await trpc.ContentInformationApi.sortOrgDocument.mutate({
                                            documents: sortedArray,
                                            contentInformationId: contentInfoId,
                                        });
                                    },
                                    removeDocument: async ({ id }) => {
                                        return await trpc.ContentInformationApi.removeOrgDocument.mutate({
                                            documentId: id,
                                            contentInformationId: contentInfoId,
                                        });
                                    },
                                    renameDocument: async ({ id, name }) => {
                                        return await trpc.ContentInformationApi.renameOrgDocument.mutate({
                                            documentId: id,
                                            name: name,
                                            contentInformationId: contentInfoId,
                                        });
                                    },
                                    switchGroup: async ({ groupId, docId }) => {
                                        return await trpc.ContentInformationApi.switchOrgDocumentGroup.mutate({
                                            documentId: docId,
                                            groupId,
                                            contentInformationId: contentInfoId,
                                        });
                                    },
                                    queryKey: ContentInformationQueryKeys.allDocs(contentInfoId),
                                }}
                                documentGroup={{
                                    get: async () => {
                                        return (
                                            await trpc.ContentInformationApi.getOrgDocumentGroups.query({
                                                contentInformationId: contentInfoId,
                                            })
                                        ).map((group) => ({
                                            id: group.id,
                                            name: group.name,
                                            sort: group.sort,
                                        }));
                                    },
                                    sort: async ({ sortedArray }) => {
                                        return await trpc.ContentInformationApi.sortOrgDocumentGroup.mutate({
                                            groups: sortedArray,
                                            contentInformationId: contentInfoId,
                                        });
                                    },
                                    queryKey: ContentInformationQueryKeys.allDocGroups(contentInfoId),
                                }}
                            />
                        </div>
                    }
                />
            </Wrapper>
        </>
    );
};

export default ContentInformationDetailPage;
