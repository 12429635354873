import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import CardWithDivider from '../../../components/Layout/CardWithDivider';
import CardTitle from '../../../components/typography/CardTitle';
import { L } from '../../../lib/i18n';
import { useParams } from 'react-router-dom-v5-compat';
import toast from 'react-hot-toast';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@ourliving/ourliving-ui';
import useArchiveTicket from '../hooks/useArchiveTicket';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { useGetTicket } from '../hooks/ticketQueries';
import { useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';
import { useNavigate } from 'react-router-dom-v5-compat';

const Overlay = styled(Dialog.Overlay)({
    background: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
});

const Content = styled(Dialog.Content)({
    minWidth: 300,
});

const ButtonWrapper = styled.div({
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
});

type Props = {
    readOnly?: boolean;
    children?: React.ReactNode;
};

const PrimaryTicket = ({ children, readOnly = false }: Props) => {
    const [confirmArchive, setConfirmArchive] = useState(false);
    const navigate = useNavigate();
    const { ToggleIsModalOpen } = useModal();
    const { mutate: archive } = useArchiveTicket();
    const params = useParams<{ id: string }>();
    const ticketId = Number(params.id);

    const { data: ticket } = useGetTicket(params.id);

    const ticketCardTitle = ticket?.ticket_no ? `#${ticket.project_id} - ${ticket?.ticket_no} ` : `${L('new_ticket')}`;

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries(ticketQueryKeys.listInfinite());
    }, []);

    const archiveTicket = () => {
        if (!ticketId) return;
        archive(
            {
                ticketId,
            },
            {
                onSuccess: (data) => {
                    if (data.archived_at) {
                        toast.success(`${L('ticket_archived')}`);
                    } else {
                        toast.success(`${L('ticket_unarchived')}`);
                    }
                    queryClient.invalidateQueries(ticketQueryKeys.listInfinite());
                    queryClient.invalidateQueries(ticketQueryKeys.listArchiveInfinite());
                    navigate('/tickets');
                },
            },
        );
    };

    const [openProduct, setOpenProduct] = useState<React.ReactNode | undefined>();

    const [open, setOpen] = useState<React.ReactNode | undefined>();

    const archiveButton = confirmArchive ? (
        <Button onClick={() => archiveTicket()} variant={readOnly ? 'primary' : 'danger'}>
            {readOnly ? L('unarchive_ticket') : L('archive_ticket')}
        </Button>
    ) : (
        <Button
            disabled={!ticketId}
            style={{ visibility: ticketId ? 'visible' : 'hidden' }}
            onClick={() => setConfirmArchive(true)}
        >
            {readOnly ? L('unarchive_ticket') : L('archive_ticket')}
        </Button>
    );

    const forwardMailButton = (
        <Button onClick={() => ToggleIsModalOpen()} variant={'primary'}>
            {L('forward_mail')}
        </Button>
    );

    return (
        <>
            <Dialog.Root
                open={!!open}
                onOpenChange={(e) => {
                    if (!e) setOpen(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{open}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>
            <Dialog.Root
                open={!!openProduct}
                onOpenChange={(e) => {
                    if (!e) setOpenProduct(undefined);
                }}
            >
                <Dialog.Portal>
                    <Overlay>
                        <Content>{openProduct}</Content>
                    </Overlay>
                </Dialog.Portal>
            </Dialog.Root>

            <CardWithDivider
                topArea={
                    ticket && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CardTitle>{ticketCardTitle}</CardTitle>
                            <ButtonWrapper>
                                {!readOnly && forwardMailButton}
                                {archiveButton}
                            </ButtonWrapper>
                        </div>
                    )
                }
                mainArea={children}
            />
        </>
    );
};

export default PrimaryTicket;
