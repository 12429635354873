import React from 'react';
import { margin } from '../../components/Shared/Style/margin';
import PushNotificationsSettings from './Components/PushNotificationsSettings';
import { desktop } from '../../components/MediaQuery/breakpoints';
import styled from '@emotion/styled';
import H1 from '../../components/typography/H1';
import { L } from '../../lib/i18n';
import { colors } from '@app/shared/lib/Style/colors';

const PageContainer = styled.div(({ theme }) => {
    return {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    };
});

const StyledPage = styled(PageContainer)({
    minHeight: '100vh',
    width: 'auto',
    height: 'auto',
    display: 'block'
});

const ContentWrapper = styled.div({
    [`@media screen and (min-width: ${desktop}px)`]: { padding: 0 },
    padding: '1rem',
    width: '100%',
    height: '100%'
});

const StyledH1 = styled(H1)({
    placeSelf: 'start'
});

export const Notifications = () => {
    return (
        <StyledPage>
            <ContentWrapper>
                <StyledH1>{L('notifications')}</StyledH1>
                <PushNotificationsSettings />
            </ContentWrapper>
        </StyledPage>
    );
};
