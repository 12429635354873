import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getSupplierKeys } from './supplierQueries';

const useEditSupplier = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ supplier }: { supplier: { name: string; id: number } }) => {
            return trpc.SupplierApi.updateSupplier.query({
                ...supplier
            });
        },
        {
            onSettled: (_data, _error, variables) => {
                if (!variables.supplier.id) return;
                client.invalidateQueries(getSupplierKeys.detail(variables.supplier.id));
            }
        }
    );
};

export default useEditSupplier;
