import styled from '@emotion/styled';
import React, { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import Cadd from '../../../components/Icon/c-add.svg';
import { colors } from '../../../components/Shared/Style/colors';

const ColorCircleWithPlus = styled(Cadd)(() => {
    return {
        height: '30px',
        width: '30px',
        '& circle': {
            strokeWidth: '1px',
            stroke: colors.tabActive,
            fill: colors.tabActive
        },
        '& path': {
            stroke: colors.white
        }
    };
});

const AddNewServicePartnerButton: FC = ({ ...rest }) => {
    const { projectId } = useParams<{ projectId: string }>();
    return (
        <Link {...rest} to={`/maintenance/${projectId}/service_partner/add`}>
            <ColorCircleWithPlus />
        </Link>
    );
};

export default AddNewServicePartnerButton;
