import { observable } from 'mobx';
import AddonsApi from '@app/api/public/AddonsApi';
import { computedFn } from 'mobx-utils';
import { ProductDetail, SpaceListing } from '@app/api/models/Addons';

class AddonProductStoreClass {
    @observable product: Partial<ProductDetail>;
    @observable spaces: SpaceListing[] = [];

    @observable selectedProduct: string;
    @observable selectedProductId: string;

    async load(id: string) {
        this.product = (await AddonsApi.getProduct(id)) || {};
    }

    async list(id: string) {
        this.spaces = (await AddonsApi.list(id, false)) || {};
    }

    totalSum = computedFn(function totalSum(name?: string) {
        const r = this.spaces.map(space => {
            const sum = space.product_groups
                .flatMap(pg => pg.products.filter(p => p.selected === true))
                .reduce((price, product) => price + +product.addon_selected_info?.price ?? 0, 0);

            return { space: space.name, sum };
        });

        if (name) {
            return r.find(x => x.space === name);
        } else {
            const t = r.reduce((sum, pg) => sum + pg.sum, 0);
            return { space: 'total', sum: t };
        }
    });

    selectProduct(value: string) {
        this.selectedProduct = value;
    }
}
export const AddonProductStore = new AddonProductStoreClass();
