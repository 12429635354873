import styled from '@emotion/styled';
import React from 'react';
import { style } from '@app/shared/lib/Style';
import { ReportProductsCategories } from './SupplierReportProductsCategories';
import { ProductRow } from './SupplierReportProductsData';
import { GetListData } from '../hooks/useGetList';

const AvoidBreak = styled.div({
    breakInside: 'avoid',
    pageBreakInside: 'avoid'
});

const PropertyName = styled.div({
    display: 'flex',
    width: '100%',
    fontSize: style.fontSize.epsilon,
    marginBottom: style.margin.xs,
    justifyContent: 'flex-start',
    lineHeight: '14px',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontWeight: style.fontWeight.bold,
    color: style.colors.black
});

const SpaceName = styled.div({
    display: 'flex',
    width: '100%',
    fontSize: style.fontSize.seta,
    justifyContent: 'flex-start',
    lineHeight: '14px',
    fontFamily: 'Lato',
    textAlign: 'left',
    fontWeight: style.fontWeight.bold,
    color: style.colors.black,
    marginBottom: style.margin.xs
});

const Container = styled.div({
    display: 'block',
    width: '100%',
    padding: `${style.margin.m}px ${style.margin.m}px ${style.margin.xs}px ${style.margin.m}px`,
    '& .first-product:nth-of-type(odd)': {
        backgroundColor: style.colors.background
    },
    '& .first-product:nth-of-type(even)': {
        backgroundColor: style.colors.white
    }
});

const RestSpacesContainer = styled.div({
    '& .product:nth-of-type(odd)': {
        backgroundColor: style.colors.background
    },
    '& .product:nth-of-type(even)': {
        backgroundColor: style.colors.white
    }
});

export const ReportProducts = ({ data, fields }: { data: GetListData; fields: string[] }) => {
    const project = data.reduce<{
        [propertyName: string]: {
            [spaceName: string]: GetListData;
        };
    }>((project, currentData, index, list) => {
        const allSpaces = [
            ...new Set(
                list
                    .filter(data => data.property_name === currentData.property_name)
                    .map(listData => ({ name: listData.parent_names[0], propertyId: listData.property_id }))
            )
        ];

        const spacesWithProducts = allSpaces.reduce<{
            [spaceName: string]: GetListData;
        }>((spaces, currentSpace) => {
            spaces[currentSpace.name] = list.filter(
                data => data.parent_names[0] === currentSpace.name && data.property_id === currentSpace.propertyId
            );

            return spaces;
        }, {});

        project[currentData.property_name] = spacesWithProducts;

        return project;
    }, {});

    return (
        <>
            {Object.entries(project).map(([propertyName, spaces]) => {
                return <PdfData key={propertyName} propertyName={propertyName} spaces={spaces} fields={fields} />;
            })}
        </>
    );
};

const PdfData = ({
    propertyName,
    spaces,
    fields
}: {
    propertyName: string;
    spaces: {
        [spaceName: string]: GetListData;
    };
    fields: string[];
}) => {
    const SpacesWithProducts = Object.entries(spaces).map(([spaceName, products]) => {
        return { spaceName, products };
    });

    const firstSpace = (
        <React.Fragment>
            <SpaceName>{SpacesWithProducts[0].spaceName}</SpaceName>
            <ReportProductsCategories fields={fields} />
            <ProductRow
                style={{ backgroundColor: style.colors.background }}
                product={SpacesWithProducts[0].products[0]}
                fields={fields}
            />
        </React.Fragment>
    );

    const firstSpaceRestProducts = SpacesWithProducts[0].products.slice(1).map(product => {
        return <ProductRow key={product.id} className="first-product" product={product} fields={fields} />;
    });

    const restSpaces = SpacesWithProducts.slice(1).map(space => {
        const restProducts = space.products.slice(1).map(product => {
            return (
                <AvoidBreak className={'product'} key={product.id}>
                    <ProductRow key={product.id} product={product} fields={fields} />
                </AvoidBreak>
            );
        });
        return (
            <RestSpacesContainer key={space.spaceName}>
                <AvoidBreak>
                    <SpaceName
                        style={{
                            marginTop: style.margin.l
                        }}
                    >
                        {space.spaceName}
                    </SpaceName>
                    <ReportProductsCategories fields={fields} />
                    <ProductRow
                        style={{
                            background: style.colors.background
                        }}
                        product={space.products[0]}
                        fields={fields}
                    />
                </AvoidBreak>
                {restProducts}
            </RestSpacesContainer>
        );
    });

    return (
        <Container>
            <AvoidBreak>
                <PropertyName>{propertyName}</PropertyName>
                {firstSpace}
            </AvoidBreak>
            {firstSpaceRestProducts}
            {restSpaces}
        </Container>
    );
};
