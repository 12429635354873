const work = {
    apiKey: 'AIzaSyCl04bwYc1CDGlmppWSsT17F2kti88bik8',
    authDomain: 'ourliving-staging.firebaseapp.com',
    projectId: 'ourliving-staging',
    storageBucket: 'ourliving-staging.appspot.com',
    messagingSenderId: '370705110211',
    appId: '1:370705110211:web:62e24ed3bdd66be5e9be76',
    measurementId: 'G-NKTT07084R',
    vapidKey: 'BGdhi0yYxb18kj2BJNVdSB3t9j8z4uVit2CS6fM7s4yE_K849jQoQrkVmS02EcMUcx6_KUCTEHZAmI_jgyBwDlw'
};

const se = {
    apiKey: 'AIzaSyD1CRuLyCU0EMXrtN6zMAqY6HX2Am9z_Mw',
    authDomain: 'ourliving-production.firebaseapp.com',
    projectId: 'ourliving-production',
    storageBucket: 'ourliving-production.appspot.com',
    messagingSenderId: '318438796275',
    appId: '1:318438796275:web:044676cb0e9d495b084086',
    measurementId: 'G-K0PEEZ094T',
    vapidKey: 'BMgJeO4_xCA2IYIT_0n8KlPt42zXjLT4ciDOG_dvTMjRexblX20VawHwWXt07V4MBWZIBvPnLZivahtVd5Vq-ms'
};

export { work, se };
