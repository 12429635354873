import AddonsApi from '@app/api/public/AddonsApi';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

const getListData = async (propertyId: string) => {
    const list = await AddonsApi.listv2(propertyId);
    const signedList = await Promise.all(
        list.map(async AddonProduct => {
            const signedUrl = await Promise.all(
                AddonProduct.document_ids.map(async id => {
                    return await DocumentsApi.getSignedUrl(`200x200/${id}`);
                })
            );
            if (signedUrl.length) return { ...AddonProduct, image_signed_url: signedUrl[0] };
            return { ...AddonProduct };
        })
    );
    return signedList;
};

const useGetListData = (propertyId: string) => {
    return useQuery(['headerData', propertyId], () => getListData(propertyId));
};

export default useGetListData;
