import React from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import { useGetTodoDetail } from './hooks/todoQueries';
import { useUpdateTodo } from './hooks/todoMutations';
import toast from 'react-hot-toast';
import TodoForm from './TodoForm';

const TodoDetail = ({ todoId, propertyId }: { todoId: number; propertyId: number }) => {
    const todoQuery = useGetTodoDetail({ todoId, propertyId });
    const { mutate: updateTodo } = useUpdateTodo();

    if (todoQuery.data) {
        const data = todoQuery.data;
        return (
            <CardWithDivider
                style={{ maxWidth: '800px' }}
                topArea={<CardTitle>{L('edit_todo')}</CardTitle>}
                mainArea={
                    <TodoForm
                        formValues={{
                            name: data.name,
                            description: data.description ?? undefined,
                            tips: data.tips ?? undefined,
                            recurrency: data.recurrency ?? undefined,
                            template_for_type: data.template_for_type ?? undefined,
                            template_for_subtype: data.template_for_subtype ?? undefined,
                            due_at: data.due_at || '',
                        }}
                        onSubmit={(formValues) =>
                            updateTodo(
                                {
                                    ...formValues,
                                    todoId,
                                    propertyId,
                                },
                                {
                                    onSuccess: (data) => {
                                        toast.success(`${data.name} ${L('updated')}`);
                                    },
                                },
                            )
                        }
                    />
                }
            />
        );
    }
    if (todoQuery.status === 'loading') {
        <Loading />;
    }
    return <div>{L('error')}</div>;
};

export default TodoDetail;
