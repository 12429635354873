import React from 'react';
import styled from '@emotion/styled';
import StackedGraph from '../components/StackedGraph';
import PieGraphCostYear from '../components/PieGraphCostYear';
import PieGraphTotalCost from '../components/PieGraphTotalCost';
import PieGraphCostm2 from '../components/PieGraphCostm2';

const HeadingContainer = styled.div({
    display: 'Grid',
    gridTemplateColumns: 'minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'start',
    marginTop: '2rem',
    maxWidth: '100%',
    overflowX: 'auto'
});

const AnalysPage = () => {
    return (
        <div style={{ flex: 1 }}>
            <StackedGraph />
            <HeadingContainer>
                <PieGraphCostYear />
                <PieGraphTotalCost />
                <PieGraphCostm2 />
            </HeadingContainer>
        </div>
    );
};

export default AnalysPage;
