import React, { ReactNode } from 'react';
import H1 from '../../../../components/typography/H1';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import Label from '../../../../components/Forms/Label';
import Input from '../../../../components/Forms/Input';
import { useForm } from 'react-hook-form';
import { L } from '../../../../lib/i18n';
import dayjs from 'dayjs';
import { useGetSupplierById } from '../hooks/supplierQueries';

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : ''
}));

type Props = {
    onSubmit: (supplier: { name: string }) => void;
    children?: ReactNode;
    supplier: ReturnType<typeof useGetSupplierById>['data'];
};
const SupplierForm = ({ onSubmit, children, supplier }: Props) => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<{ name: string }>({
        defaultValues: {
            name: supplier?.name
        }
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <H1>{`${L('edit')} ${L('supplier').toLocaleLowerCase()}`}</H1>
            <ErrorAndInputWrapper errorMsg={errors.name?.message ? L(errors.name?.message) : ''}>
                <Label title={L('name')}>
                    <RestyledInput
                        isError={!!errors.name}
                        placeholder={L('name')}
                        {...register('name', {
                            required: true,
                            min: { message: 'error_required', value: 1 }
                        })}
                    />
                </Label>
            </ErrorAndInputWrapper>

            <Label title={L('created_at')}>
                <Input type="datetime-local" readOnly value={dayjs(supplier?.created_at).format('YYYY-MM-DDTHH:mm')} />
            </Label>
            <Label title={L('updated_at')}>
                <Input type="datetime-local" readOnly value={dayjs(supplier?.updated_at).format('YYYY-MM-DDTHH:mm')} />
            </Label>
            {children}
        </Form>
    );
};

export default SupplierForm;
