import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../lib/trpc';
import { getProjectKeys } from '../../binders/hooks/projectHooks/projectQueries';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';

const useDeletePropertyDocuments = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (documentIds: number[]) => {
            return await trpc.BindersApi.removePropertyDocuments.mutate({ documentIds });
        },
        {
            onSettled: () => {
                queryClient.invalidateQueries(getProjectKeys.documents());
            },
            onSuccess: () => {
                toast.success(L('deleted_documents'));
            },
            onError: () => {
                toast.error(L('an_error_occurred'));
            },
        },
    );
};

export default useDeletePropertyDocuments;
