import { SaveMessage, TicketMessageThread } from '@app/api/models/Tickets';
import TicketsApi from '@app/api/public/TicketsApi';
import { useMutation, useQueryClient } from 'react-query';
import { ticketQueryKeys } from '@app/shared/queryKeys/tickets';

type Thread_type = TicketMessageThread['ticket_message_thread_type'];
const useSaveMessage = () => {
    const client = useQueryClient();
    return useMutation(
        (variables: { message: SaveMessage; files?: File[]; ticketId: number; thread_type: Thread_type }) => {
            return TicketsApi.saveMessage(
                { message: variables.message, files: variables.files },
                variables.ticketId,
                variables.thread_type
            );
        },
        {
            onSettled: (_data, _error, variables) => {
                client.invalidateQueries(
                    ticketQueryKeys.messages(variables.thread_type, variables.ticketId.toString())
                );
            }
        }
    );
};

export default useSaveMessage;
