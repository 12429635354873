import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';
import styled from '@emotion/styled';
import { Icon } from '../../../../../components/Icon/Icon';
import { DisplayField } from '../../../../../components/DisplayField/DisplayField';
import Label from '../../../../../components/Forms/Label';
import useGetUsersOnOrder from '../../hooks/useGetUsersOnOrder';
import UserOrder from './SignUserOrder';
import { Table } from '../../../../../components/Table/Table';
import dayjs from 'dayjs';
import useSetSignedManually from '../../hooks/useSetSignedManually';
import Spinner from '../../../../../components/Spinner/Spinner';
import SignUserOrder from './SignUserOrder';
import useRemoveOrder from '../../hooks/useRemoveOrder';
import toast from 'react-hot-toast';
import { Property } from '@app/api/models/Properties';

const Title = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

const LinkArea = styled.div({
    marginTop: style.margin.m,
});

const FlexContainer = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
});

type Props = {
    forwardRef: any;
    isActive: boolean;
    setIsActive: any;
    propertyId: Property['id'];
};

export function AddonMoreVertical({ forwardRef, isActive, setIsActive, propertyId }: Props) {
    const { data: usersOnOrder } = useGetUsersOnOrder(propertyId);
    const { mutate: removeOrder, status: removeStatus } = useRemoveOrder();

    const rows = usersOnOrder?.map((user) => {
        return {
            sign: <SignUserOrder {...user} propertyId={propertyId} />,
            name: <div>{user.name}</div>,
            email: <div>{user.email}</div>,
            status: <FlexContainer>{L(user.status)}</FlexContainer>,
            updated_at: <div>{dayjs(user.updated_at || user.created_at).format('YYYY-MM-DD')}</div>,
        };
    });

    const UsersList = (
        <Table
            columns={[
                { key: 'sign', header: L('manual_sign'), arrows: false, width: '0.5fr' },
                { key: 'status', header: L('status'), arrows: false, width: '0.4fr' },
                { key: 'name', header: L('name'), arrows: false, width: '1fr' },
                { key: 'email', header: L('email'), arrows: false, width: '0.5fr' },
                { key: 'updated_at', header: L('updated_at'), arrows: false, width: '0.5fr' },
            ]}
            data={rows ? rows : []}
        />
    );

    const orderId = usersOnOrder?.[0]?.order_id;
    const handleRemoveOrder = () => {
        if (!orderId) return;

        removeOrder(
            { propertyId, orderId },
            {
                onError: () => {
                    toast(`❌ ${L('remove_order_error')}`);
                },
                onSuccess: () => {
                    toast(`✅ ${L('remove_order_success')}`);
                },
            },
        );
    };

    return (
        <Modal width={'1000px'} forwardRef={forwardRef} isActive={isActive}>
            <Title>{L('users')}</Title>
            {UsersList}

            <FlexContainer>
                {orderId &&
                    (removeStatus === 'loading' ? (
                        <Spinner />
                    ) : (
                        <Button
                            onClick={() => handleRemoveOrder()}
                            variant="danger"
                            style={{
                                alignSelf: 'flex-end',
                                justifySelf: 'start-end',
                                marginTop: style.margin.l,
                                marginRight: '0px',
                            }}
                        >
                            {L('remove_order')}
                        </Button>
                    ))}
                <Button
                    onClick={() => setIsActive(false)}
                    style={{
                        alignSelf: 'flex-end',
                        justifySelf: 'flex-end',
                        marginTop: style.margin.l,
                        marginRight: '0px',
                    }}
                >
                    {L('close')}
                </Button>
            </FlexContainer>
        </Modal>
    );
}
