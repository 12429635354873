import { Contact } from '@app/api/models/Contacts';
import styled from '@emotion/styled';
import React, { ChangeEvent, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Button } from '@ourliving/ourliving-ui';
import Input from '../../../components/Forms/Input';
import Label from '../../../components/Forms/Label';
import { desktop } from '../../../components/MediaQuery/breakpoints';
import { useModal } from '../../../components/Modal/Context/ModalContext';
import { style } from '../../../components/Shared/Style';
import { colors } from '../../../components/Shared/Style/colors';
import Spinner from '../../../components/Spinner/Spinner';
import H1 from '../../../components/typography/H1';
import { L } from '../../../lib/i18n';
import useSaveContact from '../hooks/useSaveContact';
import { Avatar } from '@ourliving/ourliving-ui';

const ButtonWraper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const Form = styled.form({
    width: '400px',
    placeSelf: 'center',
    backgroundColor: colors.white,
    padding: '2rem',
    borderRadius: style.roundCornerSize.small,
});

const UploadArea = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '80px',
    [`@media screen and (min-width: ${desktop}px)`]: {
        marginTop: '2rem',
    },
});

const ClickArea = styled.label({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
});

const NewContactForm = () => {
    const { ToggleIsModalOpen } = useModal();
    const { mutate, status } = useSaveContact();
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File>();

    const [localImage, setlocalImage] = useState('');

    const [contact, setContact] = useState<Partial<Contact>>({
        name: '',
        company: '',
        title: '',
        phone_number: '',
        email: '',
        city: '',
        info: '',
    });

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        const myImg = URL.createObjectURL(file);
        setlocalImage(myImg);
        setFile(file);
        setContact({ ...contact, [e.target.name]: file });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContact({ ...contact, [e.target.name]: e.target.value });
    };
    return (
        <Form
            onClick={(e) => {
                e.stopPropagation();
            }}
            onSubmit={(e) => {
                e.preventDefault();
                if (contact.name) {
                    mutate(
                        { ...contact, file },
                        {
                            onSuccess: () => {
                                toast.success(`${L('contact')} ${contact.name} ${L('saved')}`);
                                ToggleIsModalOpen();
                            },
                        },
                    );
                }
            }}
        >
            <H1>{`${L('new')} ${L('contact')}`}</H1>
            <Label title={L('profile_image')}>
                <UploadArea>
                    <ClickArea htmlFor={'contactImage'}>
                        <Avatar src={localImage && localImage} name={contact?.name || contact?.email} />
                    </ClickArea>
                    <input
                        ref={inputFileRef}
                        style={{ display: 'none' }}
                        type="file"
                        id="contactImage"
                        name="profile_image_data"
                        onChange={(e) => changeHandler(e)}
                        accept="image/*"
                    />
                </UploadArea>
            </Label>
            <Label title={L('name')}>
                <Input placeholder={L('name')} name="name" value={contact.name} onChange={(e) => handleChange(e)} />
            </Label>
            <Label title={L('contact_company')}>
                <Input
                    placeholder={L('contact_company')}
                    name="company"
                    value={contact.company}
                    onChange={(e) => handleChange(e)}
                />
            </Label>
            <Label title={L('contact_title')}>
                <Input
                    placeholder={L('contact_title')}
                    name="title"
                    value={contact.title}
                    onChange={(e) => handleChange(e)}
                />
            </Label>
            <Label title={L('phone_number')}>
                <Input
                    placeholder={L('phone_number')}
                    name="phone_number"
                    value={contact.phone_number}
                    onChange={(e) => handleChange(e)}
                />
            </Label>

            <Label title={L('contact_mail')}>
                <Input
                    placeholder={L('contact_mail')}
                    name="email"
                    value={contact.email}
                    onChange={(e) => handleChange(e)}
                />
            </Label>

            <Label title={L('contact_city')}>
                <Input
                    placeholder={L('contact_city')}
                    name="city"
                    value={contact.city}
                    onChange={(e) => handleChange(e)}
                />
            </Label>
            <Label title={L('contact_extra_information')}>
                <Input
                    placeholder={L('contact_extra_information')}
                    name="info"
                    value={contact.info}
                    onChange={(e) => handleChange(e)}
                />
            </Label>

            <ButtonWraper>
                <Button type="button" onClick={() => ToggleIsModalOpen()} variant="primary">
                    Avbryt
                </Button>

                {status === 'idle' && (
                    <Button disabled={contact.name ? false : true} type={'submit'}>
                        {L('save')}
                    </Button>
                )}
                {status === 'loading' && (
                    <Button style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spinner />
                    </Button>
                )}
                {status === 'error' && <Button type={'submit'}>{L('error')}</Button>}
                {status === 'success' && <Button type={'submit'}>{L('saved')}</Button>}
            </ButtonWraper>
        </Form>
    );
};

export default NewContactForm;
