import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { useObserver } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import NotificationBell from '../../areas/notifications/NotificationBell';
import { AvatarMenu } from '../Menu/AvatarMenu';
import { ProfilePage } from '../../areas/profile/ProfilePage';
import useGetProfile from '../../lib/hooks/useGetProfile';
import useGetSignedUrlById from '../../lib/hooks/useGetSignedUrlById';
import { small } from '../../lib/imageSize';
import useGetAccount from '../../lib/hooks/useGetAccount';
import { Logo, colors } from '@ourliving/ourliving-ui';
import { Avatar } from '@ourliving/ourliving-ui';

const Project = styled.span({
    color: style.colors.textColor1,
    fontSize: '1rem',
    textTransform: 'uppercase',
    wordBreak: 'break-word',
    ['@media screen and (max-width: 500px)']: {
        maxWidth: '30vw',
    },
    ['@media screen and (max-width: 400px)']: {
        maxWidth: '4.5rem',
    },
});

const FlexWrapper = styled.div({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
});

export const Header = ({ ...rest }) => {
    const accountInfo = useGetAccount();
    const profileQuery = useGetProfile();
    const { data: signedUrl } = useGetSignedUrlById(profileQuery?.data?.profile_image_data?.id, small);
    const history = useHistory();

    return useObserver(() => {
        return (
            <div
                style={{
                    gridArea: 'header',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: colors.backgroundColor.white,
                    borderBottom: '1px solid rgb(204, 204, 204)',
                    padding: '0.7rem',
                }}
                {...rest}
            >
                <Logo link="/" />
                <div style={{ display: 'flex', gap: '0.7rem', alignItems: 'center' }}>
                    <FlexWrapper>
                        <Project>{accountInfo.data?.name}</Project>
                    </FlexWrapper>
                    <AvatarMenu
                        avatar={
                            <Avatar
                                size="small"
                                src={signedUrl && signedUrl}
                                readonly={true}
                                name={profileQuery.data?.name || profileQuery.data?.email}
                            />
                        }
                    >
                        <ProfilePage />
                    </AvatarMenu>
                    <NotificationBell
                        color={'primary'}
                        to={{ pathname: '/notificationsCenter', state: history.location.pathname }}
                    />
                </div>
            </div>
        );
    });
};
