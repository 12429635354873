import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { L } from '../../../../lib/i18n';
import ClearProducts from '../ClearProducts';
import { CrossIcon } from '../emotion/CrossIcon';
import TableMeta from '../../TableMetaTypes/CopyToTableMeta';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import TableImageWithFallback from '../TableImageWithFallback';
import { Product } from '@app/trpc-api/models/Products';

const columnHelper = createColumnHelper<Product>();

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const CanBtn = styled.div({
    [':hover']: {
        cursor: 'pointer',
    },
});

const columns = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
        enableResizing: false,
    }),
    columnHelper.display({
        id: 'image',
        cell: ({ row }) => (
            <div style={{ width: '100px', aspectRatio: '4/3' }}>
                <TableImageWithFallback id={row.original?.product_image_data?.original?.id} />
            </div>
        ),
        enableResizing: false,
        size: 75,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.product_type_name, {
        id: 'function',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.product_type_name ?? '')}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('function')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: () => <Flex>{L('product_group')}</Flex>,
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original.name)}</Data>
            </CellContainer>
        ),
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.attr.product, {
        id: 'product',
        header: () => <Flex>{L('product')}</Flex>,
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original.attr.product)}</Data>
            </CellContainer>
        ),
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.display({
        id: 'id',
        header: ({ table }) => <ClearProducts clearRows={(table.options.meta as TableMeta).clearRows} />,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
        cell: ({ row, table }) => (
            <CellContainer style={{ justifyContent: 'flex-end', width: '100%', paddingRight: '1rem' }}>
                <CanBtn onClick={() => (table.options.meta as TableMeta).removeRow(row.original.id)} role="button">
                    <CrossIcon />
                </CanBtn>
            </CellContainer>
        ),
    }),
];

export default columns;
