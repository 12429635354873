import * as React from 'react';
import { L } from '../../lib/i18n';
import { desktop } from '../../components/MediaQuery/breakpoints';
import { style } from '../../components/Shared/Style';
import H1 from '../../components/typography/H1';
import { useGetNotificationTypes, useNotificationList } from './hooks/notificationQueries';
import NotificationHandler from './notificationHandler/NotificationHandler';
import { Pagination } from './components/Pagination';
import { Loading } from '../../components/Loading/Loading';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import TabList from '../../components/Tabs/TabList';
import useQueryParams from '../../lib/hooks/useQueryParams';
import SmallTab from '../../components/Tabs/SmallTab';

import { colors } from '@app/shared/lib/Style/colors';
import GlobalFilter from '../../components/Table/FilterComponents/GlobalFilterTable';

const PageBackground = styled.div(() => {
    return {
        padding: '1rem',
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: colors.pageBackground,
        borderRadius: 5,
    };
});

const StyledMain = styled.main({
    padding: ` 0px ${style.margin.m}px`,
    width: '100%',
    [`@media screen and (min-width: ${desktop}px`]: {
        padding: '0px',
    },
});

const StyledTabList = styled(TabList)({
    marginBottom: '24px',
    marginTop: '12px',
});

const FlexCorner = styled('div')({
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem',
});

const NotificationsCenter: React.FC = () => {
    const { getQueryString, updateQueryString } = useQueryParams();
    const search = getQueryString('search', '');
    const page = getQueryString('page', '1');
    const { type } = useParams<{ type: string | undefined }>();
    const { data: notifications } = useNotificationList(search, Number(page), type, true);
    const { data: types } = useGetNotificationTypes();

    const updateSearch = (newSearch: string) => {
        updateQueryString('search', newSearch);
    };
    const updatePage = (newPage: number) => {
        window.scrollTo(0, 0);
        updateQueryString('page', String(newPage));
    };

    const NotificationTabs = types ? (
        types.map((data) => {
            return (
                <SmallTab key={data.type} to={`/notificationsCenter/${data.type}`}>
                    {L(data.type)}
                </SmallTab>
            );
        })
    ) : (
        <p>Loading</p>
    );
    if (!notifications) return <></>;

    return (
        <>
            <H1>{L('my_notifications')}</H1>{' '}
            <PageBackground style={{ padding: '0px 0px 75px 0px' }}>
                <StyledMain>
                    <FlexCorner>
                        {' '}
                        <GlobalFilter
                            filter={search}
                            setFilter={updateSearch}
                            placeholder={L('search')}
                            // fullWidth={true}
                            style={{ height: 48 }}
                        />
                    </FlexCorner>

                    <StyledTabList>
                        <SmallTab exact to={'/notificationsCenter'}>
                            {L('my_notifications')}
                        </SmallTab>
                        {NotificationTabs}
                    </StyledTabList>
                    {notifications?.items ? <NotificationHandler items={notifications.items} /> : <Loading />}
                    <Pagination page={notifications} onChangePage={updatePage} />
                </StyledMain>
            </PageBackground>
        </>
    );
};

export default NotificationsCenter;
