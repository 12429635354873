import { useInfiniteQuery, useQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';

export const getProfessionKeys = {
    all: ['professions'] as const,
    lists: () => [...getProfessionKeys.all, 'list'] as const,
    list: () => [...getProfessionKeys.all, 'list'],
    listInfinite: (search?: string | null, sorting?: SortingState) => [
        ...getProfessionKeys.lists(),
        'listInfinite',
        search,
        sorting
    ],
    details: () => [...getProfessionKeys.all, 'detail'] as const,
    detail: (professionId: number) => [...getProfessionKeys.details(), { professionId }] as const
};

export function useGetInfinteProfessions({
    keepPreviousData,
    search,
    sorting
}: {
    keepPreviousData: boolean;
    search: string | null;
    sorting: SortingState;
}) {
    return useInfiniteQuery(
        getProfessionKeys.listInfinite(search, sorting),
        async ({ pageParam = 1 }) =>
            await trpc.ProfessionApi.getProfessionsPaginated.query({
                page: pageParam,
                search,
                sorting
            }),
        {
            keepPreviousData,
            getNextPageParam: lastPage => {
                return lastPage.nextCursor;
            }
        }
    );
}

export function useGetProfessionById({ keepPreviousData, id }: { keepPreviousData: boolean; id: number }) {
    return useQuery(
        getProfessionKeys.detail(id),
        async () =>
            await trpc.ProfessionApi.getProfessionById.query({
                id
            }),
        {
            keepPreviousData
        }
    );
}
