import React, { useEffect, useMemo, useState } from 'react';
import { getCoreRowModel, useReactTable, SortingState, getSortedRowModel } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import TableMenu from '../../components/TableMenuV2';
import Comments from '../../components/Comments';
import FilterSearchContainer from '../../components/emotion/Filters/FilterSearchContainer';
import Row from '../../components/emotion/Row';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { Loading } from '../../../../components/Loading/Loading';
import { L } from '../../../../lib/i18n';
import { getProductsKeys, useGetSpaceProductList, useGetSpaceProductsFilters } from '../../hooks/productQueries';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import useUpdateProductPrices from '../../hooks/useUpdateProductPrices';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { margin } from '../../../../components/Shared/Style/margin';
import TopAreaContainer from '../../components/TopAreaContainer';
import { TableMeta } from '../../TableMetaTypes/PropertyProductsTableMeta';
import { useSearchParams, useNavigate, useParams } from 'react-router-dom-v5-compat';
import columns from '../../components/Spaces/SpaceProductsPageColumns';
import AddProductFromTemplate from '../../components/Products/AddProductFromTemplate';
import Dialog from '../../../../components/Dialog/Dialog';
import { RouterOutput } from '../../../../lib/trpc';
import ContentContainer from '../../components/emotion/ContentContainer';
import FilterTableData from '../../components/FilterTableDataV2';
import ChosenFilters from '../../components/ChosenFiltersV2/ChosenFiltersV2';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import { useGetSpaceName } from '../../hooks/spaceHooks/spaceQueries';
import H1 from '../../../../components/typography/H1';
import PaddedContainer from '../../components/emotion/PaddedContainer';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import { useLocalStorage } from 'react-use';
import GenericTableDnD from '../../components/GenericTableDnD';
import useUpdateSpaceProductSort from '../../hooks/productHooks/useUpdateSpaceProductSort';
import useDragEndDndGeneric from '../../../../hooks/useDragEndDndGeneric';

type NewPrice = { id: number; price: number; addon_price: string };

type SpaceProductFilters = RouterOutput['BindersApi']['getSpaceProductsFilters'];

type SpaceProductFilterInputs = Record<keyof SpaceProductFilters, { value: string; label: string }[]>;

export type MutatedInventoryData = RouterOutput['BindersApi']['getSpaceProductsList'][0] & {
    stagedDeletion: boolean;
    newPrice:
        | false
        | {
              id: number;
              price: number;
              addon_price: number;
              isProcentual: boolean;
          }
        | undefined;
};

const procentualAdd = (priceAdjustment: number, price: number) => {
    if (price) {
        return Math.round(priceAdjustment * price);
    } else {
        //NAN
        return null;
    }
};

const SpaceProductsComponent = ({ spaceId }: { spaceId: string }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const searchField = searchParams.get('searchField') ?? '';
    const [open, setOpen] = useState(false);

    const [columnVisibilityState, setColumnVisibilityState] = useLocalStorage<Record<string, boolean>>(
        'bindersSpaceProductsVisibilityState',
        { image: false },
    );

    const handleSearch = (value: string) => {
        setSearchParams((searchParams) => {
            searchParams.delete('page');
            if (value) searchParams.set('searchField', value);
            else searchParams.delete('searchField');
            return searchParams;
        });
    };

    const handleClearAll = () => {
        if (searchField) setSearchParams({ searchField });
        else setSearchParams([]);
    };

    const updateFilter = <T extends keyof SpaceProductFilters>(key: T, values: string[]) => {
        const valuesAsString = values.map((val) => String(val));
        if (values.length === 0) searchParams.delete(key);
        searchParams.delete('page');
        setSearchParams((searchParams) => {
            if (values.length > 0) {
                searchParams.delete(key);
                valuesAsString.forEach((val) => searchParams.append(key, val));
            } else searchParams.delete(key);
            return searchParams;
        });
        // Preserving searchField causes issues because of how GlobalFilter works on initial render
        const newUrlParams = new URLSearchParams(window.location.search);
        newUrlParams.delete('searchField');
    };

    const [rowSelection, setRowSelection] = React.useState({});
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<Awaited<ReturnType<typeof useGetSpaceProductList>>['data']>();
    const [previewDeletion, setPreviewDeletion] = useState<number[]>([]);
    const [previewPrices, setPreviewPrices] = useState<
        { id: number; price: number; addon_price: number; isProcentual: boolean }[]
    >([]);
    const [showModal, setShowModal] = useState(false);

    const { data: filter } = useGetSpaceProductsFilters(+spaceId);
    const productTypesFilter = filter?.productGroups;
    const packageFilters = filter?.packages;

    const navigate = useNavigate();

    //Get all filters when initial loads are done
    const filterOptions = useMemo(() => {
        const filteredFilterData: SpaceProductFilterInputs = {
            productGroups: [],
            packages: [],
        };
        if (productTypesFilter && packageFilters) {
            productTypesFilter.forEach((data) => {
                if (
                    filteredFilterData?.productGroups &&
                    !filteredFilterData.productGroups.find((filter) => filter.label === data.name)
                ) {
                    filteredFilterData.productGroups.push({ value: data.name.toString(), label: L(data.name) });
                }
            });
            packageFilters.forEach((data) => {
                if (
                    filteredFilterData?.packages &&
                    !filteredFilterData.packages.find((filter) => filter.value === data.package_name)
                ) {
                    filteredFilterData.packages.push({
                        value: data.id.toString(),
                        label: data.package_name ? data.package_name : L('name_missing'),
                    });
                }
            });
        }
        return filteredFilterData;
    }, [productTypesFilter, packageFilters]);

    const productGroupParams = searchParams.getAll('productGroups');

    const packageParams = searchParams.getAll('packages');

    const setSelectedFilter = (key: keyof SpaceProductFilters, values: { value: string; label: string }[]) => {
        const onlyValues = values.map((element) => element.value);
        updateFilter(key, onlyValues);
    };

    const selectedFiltersWithLabel = {
        selectedProductTypes: productGroupParams.flatMap((type) => {
            const option = filterOptions.productGroups.find((filter) => filter.value === type);
            return option ?? [];
        }),
        selectedPackages: packageParams.flatMap((type) => {
            const option = filterOptions.packages.find((filter) => filter.value === type);
            return option ?? [];
        }),
    };

    const toggleModal = () => {
        setShowModal((oldModal) => !oldModal);
    };

    const filteredData = useMemo(() => {
        if (!data) return [];
        return data.map((data, index) => {
            return {
                ...data,
                stagedDeletion: previewDeletion.length > 0 && previewDeletion.includes(data.id),
                newPrice: previewPrices.length > 0 && previewPrices.find((price) => price.id === index),
            };
        });
    }, [data, previewDeletion, previewPrices]);

    const clearInputParams = (key: keyof SpaceProductFilters, value: string, searchParams: URLSearchParams) => {
        const filteredParams = searchParams.getAll(key).filter((param) => param !== value);
        searchParams.delete(key);
        filteredParams.forEach((val) => searchParams.append(key, val));
        return searchParams;
    };
    const clearInput = (type: keyof SpaceProductFilters, value: string) => {
        setSearchParams((searchParams) => {
            return clearInputParams(type, value, searchParams);
        });
    };

    const setSingleCell = (id: number, price: number, addon_price: string) => {
        const previewPricesCopy = [...previewPrices];
        const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === id);

        if (foundIndex >= 0) {
            previewPricesCopy[foundIndex].price = Math.round(price);
            previewPricesCopy[foundIndex].isProcentual = false;
        } else
            previewPricesCopy.push({
                id,
                price: Math.round(price),
                addon_price: Number(addon_price),
                isProcentual: false,
            });
        setPreviewPrices(previewPricesCopy);
    };
    const tableData = useReactTable({
        data: filteredData,
        columns,
        state: { sorting, rowSelection, columnVisibility: columnVisibilityState },
        onColumnVisibilityChange: setColumnVisibilityState,
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: true,
        manualSorting: true,
        enableMultiSort: true,
        columnResizeMode: 'onChange',
        getRowId: (originalRow) => String(originalRow.id),
        // sorting breaks without sortDescFirst due to some bug
        // https://github.com/TanStack/table/issues/4289
        sortDescFirst: false,
        enableSortingRemoval: true,
        enableRowSelection: true,
        meta: {
            updateData: ({ id, price, addon_price }: NewPrice) => {
                setSingleCell(id, price, addon_price);
            },
            previewPrices,
            previewDeletion,
        } as TableMeta,
    });

    const items = filteredData.map((item) => item.id);

    const { data: binderData, isLoading } = useGetSpaceProductList({
        filter: { productGroups: productGroupParams, packages: packageParams },
        sortType: sorting,
        search: searchField,
        entityId: +spaceId,
    });

    const { mutate: updateSort } = useUpdateSpaceProductSort();

    const isFiltering =
        productGroupParams.length > 0 || packageParams.length > 0 || searchField.length > 0 || sorting.length > 0;

    const handleDragEnd = useDragEndDndGeneric({
        queryKey: getProductsKeys.spaceList({
            filter: { productGroups: productGroupParams, packages: packageParams },
            sortType: sorting,
            search: searchField,
            entityId: +spaceId,
        }),
        updateSortOrder: updateSort,
        reverseArray: true,
        isFiltering,
    });

    useEffect(() => {
        if (!binderData) return;
        setData(binderData);
    }, [binderData]);

    const previewPrice = (price: number, isProcentual: boolean) => {
        const previewPricesCopy = [...previewPrices];
        tableData.getSelectedRowModel().flatRows.forEach((item) => {
            const foundIndex = previewPricesCopy.findIndex((copiedItem) => copiedItem.id === item.original.id);
            if (foundIndex >= 0) {
                previewPricesCopy[foundIndex].price = price;
                previewPricesCopy[foundIndex].isProcentual = isProcentual;
            } else if ((item.original.addon_price || !isProcentual) && price >= 0) {
                previewPricesCopy.push({
                    id: item.original.id,
                    price,
                    addon_price: Number(item.original.addon_price),
                    isProcentual,
                });
            }
        });
        setPreviewPrices([...previewPricesCopy]);
    };

    const cancelPrice = () => {
        setPreviewPrices([]);
    };

    const { mutate: updatePrice } = useUpdateProductPrices();

    const confirmPrice = async () => {
        const stagedItems = previewPrices
            .map((item) => {
                if (item.price) {
                    if (item.isProcentual) {
                        const calculatedPrice = procentualAdd(item.price, Number(item.addon_price));
                        return {
                            id: Number(item.id),
                            price: calculatedPrice && calculatedPrice >= 0 ? calculatedPrice : Number(item.addon_price),
                        };
                    } else {
                        return {
                            id: Number(item.id),
                            price: item.price >= 0 ? item.price : Number(item.addon_price),
                        };
                    }
                }
            })
            .flatMap((item) => (item ? [item] : []));
        if (stagedItems)
            updatePrice(stagedItems, {
                onSuccess: (changedItems) => {
                    setPreviewPrices([]);
                    toast.success(
                        `${L('edited')} ${changedItems} ${L(
                            changedItems === 1 ? 'product' : 'products',
                        ).toLowerCase()}`,
                    );
                },
                onError: () => {
                    toast.error(L('something_went_wrong'));
                },
            });
    };

    const { id: projectId, propertyId } = useParams<{ id: string; propertyId: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);
    const { data: spaceNameData, status } = useGetSpaceName(spaceId ? +spaceId : null);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{spaceNameData?.name ?? L('spaces')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <Dialog content={{ style: { width: 'auto' } }} open={open} onOpenChange={setOpen}>
                <AddProductFromTemplate />
            </Dialog>
            {status !== 'loading' && <H1>{spaceNameData?.name ?? L('space')}</H1>}
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to=".">
                        {L('products')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_space')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <Comments showModal={showModal} toggleModal={toggleModal} />
                        <CardTitle>
                            {L('products')} {data ? `(${data.length})` : ''}
                        </CardTitle>
                        <div style={{ display: 'flex', columnGap: margin.s, marginLeft: 'auto' }}>
                            <Button onClick={() => navigate('new')}>{L('new_product')}</Button>
                            <Button onClick={() => setOpen(true)}>{L('new_product_from_template')}</Button>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <>
                        <FilterSearchContainer
                            style={{
                                margin: '1rem 0 1rem 0',
                            }}
                        >
                            <ContentContainer>
                                <FilterTableData
                                    filterOptions={filterOptions}
                                    options={{
                                        productGroups: 'product_group',
                                        packages: 'package',
                                    }}
                                    selected={{
                                        productGroups: selectedFiltersWithLabel.selectedProductTypes,
                                        packages: selectedFiltersWithLabel.selectedPackages,
                                    }}
                                    setSelectedFilter={setSelectedFilter}
                                    clearAll={handleClearAll}
                                />
                            </ContentContainer>
                            <ContentContainer>
                                <PaddedContainer
                                    style={{
                                        display: 'flex',
                                        columnGap: '1rem',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginLeft: 'auto',
                                            display: 'flex',
                                            columnGap: '0.5rem',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {`${L('show_with_image')}:`}
                                        <Checkbox
                                            checked={tableData.getColumn('image')?.getIsVisible()}
                                            onChange={tableData.getColumn('image')?.getToggleVisibilityHandler()}
                                        />
                                    </div>
                                    <Row>
                                        <GlobalFilter
                                            filter={searchField}
                                            setFilter={handleSearch}
                                            placeholder={L('search')}
                                        />
                                    </Row>
                                </PaddedContainer>
                            </ContentContainer>
                        </FilterSearchContainer>
                        {filterOptions && (
                            <ChosenFilters
                                inputs={{
                                    productGroups: selectedFiltersWithLabel.selectedProductTypes,
                                    packages: selectedFiltersWithLabel.selectedPackages ?? [],
                                }}
                                onRemove={clearInput}
                                labels={{
                                    productGroups: [L('producttype'), L('producttypes')],
                                    packages: [L('package'), L('package')],
                                }}
                            />
                        )}
                        <TableMenu
                            selectedCount={Object.keys(rowSelection).length}
                            previewDeletion={previewDeletion}
                            changedPrices={previewPrices.length > 0}
                            setPreviewDeletion={setPreviewDeletion}
                            onCancelPrice={cancelPrice}
                            previewPrice={previewPrice}
                            confirmPrice={confirmPrice}
                            tableData={tableData}
                            resetRowSelection={() => {
                                setRowSelection({});
                            }}
                        />
                        <GenericTableDnD
                            items={items}
                            table={tableData}
                            rowClickHandler={({ original }) => navigate(`${original.id}`)}
                            handleDragEnd={handleDragEnd}
                        />

                        {filteredData?.length === 0 &&
                            data &&
                            (!searchField ? (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_on_space_description')}</p>
                                        <p>{L('click_button_below')}</p>
                                        <Button onClick={() => navigate('new')}>{L('new_product')}</Button>
                                    </EmptyState.Description>
                                </EmptyState>
                            ) : (
                                <EmptyState>
                                    <EmptyState.Title>{L('no_products')}</EmptyState.Title>
                                    <EmptyState.Description>
                                        <p>{L('no_products_found_description')}</p>
                                        <p>{L('please_try_something_else')}</p>
                                    </EmptyState.Description>
                                </EmptyState>
                            ))}
                    </>
                }
            />
        </>
    );
};

const SpaceProductsPage = () => {
    const { spaceId } = useParams<{ spaceId: string }>();
    return spaceId ? <SpaceProductsComponent spaceId={spaceId} /> : null;
};

export default SpaceProductsPage;
