import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { DatePick } from '../../../../../components/DatePick/DatePick';

import { Modal } from '../../../../../components/Modal/Modal';
import { style } from '../../../../../components/Shared/Style';
import { L } from '../../../../../lib/i18n';

import styled from '@emotion/styled';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

const ModalItems = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
});

export function AddonEndDate({ propertyName, forwardRef, isActive, onClick }) {
    const [endDate, setEndDate] = React.useState(new Date());

    const handleClick = () => {
        onClick(endDate);
    };

    return (
        <Modal width={'370px'} height={'450px'} forwardRef={forwardRef} isActive={isActive}>
            <ModalItems>
                <div>
                    <SubTitle>{`${L('addon_end_date')} för: ${propertyName}`}</SubTitle>
                    <DatePick
                        id={'end_date'}
                        date={endDate}
                        dateChange={(date) => setEndDate(date)}
                        name={'end_date'}
                    />
                </div>
                <Button
                    onClick={handleClick}
                    style={{
                        alignSelf: 'flex-end',
                        justifySelf: 'flex-end',
                        marginTop: style.margin.m,
                        marginRight: '0px',
                    }}
                >
                    {L('set_end_date')}
                </Button>
            </ModalItems>
        </Modal>
    );
}
