import React, { useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import TopAreaContainer from '../../../binders/components/TopAreaContainer';
import CardTitle from '../../../../components/typography/CardTitle';
import { Breadcrumbs, Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';
import { margin } from '../../../../components/Shared/Style/margin';
import { ListAdminsByAccountId } from '@app/trpc-api/models/Members';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import Dialog from '../../../../components/Dialog/Dialog';
import H1 from '../../../../components/typography/H1';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import useGetProjectName from '../../useGetProjectName';
import { useParams } from 'react-router-dom-v5-compat';
import AdminListPage from './AdminListPage';
import useAddProjectAdmin from '../../hooks/adminHooks/useAddProjectAdmin';
import AdminProjectForm from './AdminProjectForm';
import useRemoveProjectAdmin from '../../hooks/adminHooks/useRemoveProjectAdmin';
import useInviteProjectAdmins from '../../hooks/adminHooks/useInviteProjectAdmin';

function Page({ projectId }: { projectId: string }) {
    const roleOptions = [
        { value: 'board_member', label: L('board_member') },
        { value: 'brf_admin', label: L('brf_admin') },
        { value: 'project_admin', label: L('project_admin') },
    ];

    const project = useGetProjectName(projectId);
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');

    const [selectedAdmins, setSelectedAdmins] = useState<ListAdminsByAccountId[]>([]);
    const { mutate: inviteAdmins } = useInviteProjectAdmins();

    const [open, setOpen] = useState(false);
    const { mutate: addAdmin } = useAddProjectAdmin();
    const { mutate: removeAdmins } = useRemoveProjectAdmin();

    const handleInviteAdmins = (admin: ListAdminsByAccountId[]) => {
        const ids = admin.map((admin) => admin.id);
        inviteAdmins(
            { membershipIds: ids, projectId: +projectId },
            {
                onSuccess: () => {
                    if (ids.length === 1) toast.success(L('invited_admin'));
                    if (ids.length > 1) toast.success(L('invited_admins'));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const handleRemoveAdmins = (admin: ListAdminsByAccountId[]) => {
        const ids = admin.map((admin) => admin.id);
        removeAdmins(
            { membershipIds: ids, projectId: +projectId },
            {
                onSuccess: () => {
                    if (ids.length === 1) toast.success(L('removed_admin'));
                    if (ids.length > 1) toast.success(L('removed_admins'));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };
    const onSubmit = (data: { email: string; role: string; name: string }) => {
        addAdmin(
            { ...data, projectId: +projectId },
            {
                onSuccess: () => {
                    toast.success(L(`${data.role}_added`));
                    setOpen(false);
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    return (
        <>
            <Dialog open={open} onOpenChange={setOpen}>
                <AdminProjectForm roleOptions={roleOptions} onSubmit={onSubmit} />
            </Dialog>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{project ?? L('project_literally')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project ?? L('project_literally')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="../properties">
                        {L('properties')}
                    </BigNavItem>
                    <BigNavItem end to="../templates">
                        {L('property_templates')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('admins')}
                    </BigNavItem>
                    <BigNavItem end to="..">
                        {L('edit_project')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            <CardWithDivider
                topArea={
                    <TopAreaContainer>
                        <CardTitle>{L('admins')}</CardTitle>
                        <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                            <ConfirmationButton
                                disabled={!Boolean(selectedAdmins.length > 0)}
                                props={{
                                    onConfirm: () => {
                                        handleRemoveAdmins(selectedAdmins);
                                    },
                                    confirmVariant: 'danger',
                                }}
                            >
                                {L('remove')}
                            </ConfirmationButton>
                            <Button
                                disabled={!Boolean(selectedAdmins.length > 0)}
                                onClick={() => handleInviteAdmins(selectedAdmins)}
                            >
                                {L('invite')}
                            </Button>
                            <Button onClick={() => setOpen(true)}>{L('new_admin')}</Button>
                        </div>
                    </TopAreaContainer>
                }
                mainArea={
                    <AdminListPage
                        handleSetSelectedAdmins={(params: ListAdminsByAccountId[]) => {
                            setSelectedAdmins(params);
                        }}
                        projectId={+projectId}
                    />
                }
            />
        </>
    );
}

export default function PropertiesPage() {
    const { id: projectId } = useParams<{ id: string }>();
    return projectId ? <Page projectId={projectId} /> : null;
}
