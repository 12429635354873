import React from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { Modal } from '../../../../components/Modal/Modal';
import { style } from '../../../../components/Shared/Style';
import { L } from '../../../../lib/i18n';
import styled from '@emotion/styled';
import { FormField } from '../../../../components/Forms/FormField';
import { SettingStore } from '../../../settings/SettingsStore';
import { TextArea } from '@ourliving/ourliving-ui';

const SubTitle = styled.div({
    display: 'flex',
    fontSize: style.fontSize.epsilon,
    fontWeight: style.fontWeight.bold,
    padding: `0px ${style.margin.m}px ${style.margin.m}px 0px`,
    alignItems: 'center',
});

const TextInput = styled(TextArea)({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    width: '100%',
    height: '100%',
    maxHeight: '105%',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    padding: style.margin.s,
    fontSize: style.fontSize.seta,
    resize: 'vertical',
});

const Buttons = styled.div({
    display: 'flex',
    alignSelf: 'flex-end',
    gap: '1rem',
});

export function AddonProjectSettingsConditions({ forwardRef, isActive, setEditConditions }) {
    const store = SettingStore;

    const onClick = () => {
        store.saveAccountAddonInfo();
        setEditConditions(false);
    };

    const onCancel = () => {
        setEditConditions(false);
    };

    const onChange = (evt: any) => {
        store.setValueTerms(evt.target.value, evt.target.name);
    };

    return (
        <Modal height={'800px'} width={'450px'} forwardRef={forwardRef} isActive={isActive}>
            <SubTitle>{L('settings')} </SubTitle>

            <FormField height={'90%'} title={L('edit_conditions')} error={''}>
                <TextInput
                    defaultValue={store.accountAddonInfo?.contract_terms}
                    name="contract_terms"
                    onChange={onChange}
                />
            </FormField>
            <Buttons>
                <Button
                    onClick={onClick}
                    style={{
                        marginTop: style.margin.m,
                        marginRight: '0px',
                    }}
                >
                    {L('save')}
                </Button>
                <Button
                    onClick={onCancel}
                    style={{
                        marginTop: style.margin.m,
                        marginRight: '0px',
                    }}
                >
                    {L('cancel')}
                </Button>
            </Buttons>
        </Modal>
    );
}
