import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@ourliving/ourliving-ui';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import { margin } from '../../../../components/Shared/Style/margin';
import { style } from '../../../../components/Shared/Style';
import { z } from 'zod';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';

export const AddAdminSchema = z.object({
    email: z.string().email('error_required'),
    name: z.string().min(1, 'error_required'),
    role: z.enum(['brf_admin', 'project_admin', 'board_member'], { required_error: 'error_required' }),
});

export type AddAdmin = z.infer<typeof AddAdminSchema>;

const InnerFormWrapper = styled.div({
    display: 'grid',
    gap: '0 1rem',
    width: '100%',
    gridTemplateColumns: '1fr',
});

const ButtonWrapper = styled.div({
    padding: '1rem 0px 0px 0px',
    display: 'flex',
    width: '100%',
    gap: '1rem',
    justifyContent: 'flex-end',
});

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '1rem',
});

type Props = {
    roleOptions: { value: string; label: string }[];
    onSubmit: (contact: AddAdmin) => void;
    handleDeleteSpace?: () => void;
};

const AdminProjectForm = ({ roleOptions, onSubmit, handleDeleteSpace }: Props) => {
    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AddAdmin>({
        resolver: zodResolver(AddAdminSchema),
    });

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <InnerFormWrapper>
                <ErrorAndInputWrapper errorMsg={L(errors.email?.message ?? '')}>
                    <Label title={L('email_account')} />
                    <InputWithError isError={!!errors.email} type="text" {...register('email')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.name?.message ?? '')}>
                    <Label title={L('name')} />
                    <InputWithError isError={!!errors.name} type="text" {...register('name')} />
                </ErrorAndInputWrapper>

                <ErrorAndInputWrapper errorMsg={L(errors.role?.message ?? '')}>
                    <Label title={L('role')} />
                    <Controller
                        control={control}
                        name="role"
                        render={({ field: { onChange, value } }) => (
                            <RadixSelect
                                isError={!!errors.role}
                                onValueChange={onChange}
                                value={value}
                                options={roleOptions}
                            />
                        )}
                    />
                </ErrorAndInputWrapper>
            </InnerFormWrapper>
            <ButtonWrapper>
                {handleDeleteSpace && (
                    <ConfirmationButton
                        style={{ marginRight: margin.s }}
                        props={{
                            onConfirm: () => {
                                handleDeleteSpace();
                            },
                        }}
                    />
                )}
                <Button type="submit">{L('save')}</Button>
            </ButtonWrapper>
        </Form>
    );
};

export default AdminProjectForm;
