import { TicketFilter } from '@app/api/models/Tickets';
import styled from '@emotion/styled';
import React, { HTMLAttributes } from 'react';
import ReactSelect from '../../../components/Dropdown/ReactSelect';
import { Filters } from '../../../components/Filters/Filters';
import { useGetProjectProperties } from '../../../lib/hooks/useGetProperties';
import { L } from '../../../lib/i18n';
import { useGetTicketFilters } from '../hooks/ticketQueries';
import Cross from '../../../components/Icon/e-remove-outline-12.svg';
import { style } from '../../../components/Shared/Style';

const StyledFilters = styled(Filters)({
    margin: '0px',
    marginBottom: '1rem',
    gap: '0.5rem',
    '& .label': {
        margin: '0px',
        padding: '0px',
    },
});

const StyledCross = styled(Cross)({
    alignSelf: 'center',
    gridArea: 'icon',
    width: '16px',
    color: '#4B506D66',
    marginRight: '5px',
});

const Label = styled.div({
    fontSize: style.fontSize.milli,
    fontWeight: style.fontWeight.bold,
    paddingRight: style.margin.m,
    textTransform: 'uppercase',
});

type Props = {
    filter: TicketFilter;
    handleFilter: <T extends keyof TicketFilter>(key: T, value: TicketFilter[T]) => void;
    handleClearAll: () => void;
} & HTMLAttributes<HTMLDivElement>;

const alphabeticalSort = (a: string, b: string) => a.localeCompare(b);

const TicketFilters = ({ handleFilter, filter, handleClearAll, ...rest }: Props) => {
    const { data: ticketFilters } = useGetTicketFilters();
    const { data: properties } = useGetProjectProperties(filter.project_id?.toString());

    const projectOptions =
        ticketFilters?.projects
            ?.map((property) => ({ label: property.name, value: property.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    const propertyOptions =
        properties
            ?.map((property) => ({ label: property.name, value: property.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    const assigneeOptions =
        ticketFilters?.assignees
            ?.map((assignee) => ({ label: assignee.name, value: assignee.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    const priorityOptions =
        ticketFilters?.priorities
            ?.map((priority) => ({ label: priority.name, value: priority.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    const statusOptions =
        ticketFilters?.statuses
            ?.map((status) => ({ label: status.name, value: status.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    const typeOptions =
        ticketFilters?.types
            ?.map((status) => ({ label: status.name, value: status.id }))
            .sort((a, b) => alphabeticalSort(a.label, b.label)) || [];

    return (
        <StyledFilters {...rest}>
            <ReactSelect
                placeholder={L('project')}
                isClearable
                value={projectOptions?.find((projectOption) => projectOption.value === filter.project_id) ?? null}
                onChange={(option) => {
                    handleFilter('property', undefined);
                    handleFilter('project_id', option?.value);
                }}
                options={projectOptions}
            />
            <ReactSelect
                placeholder={L('properties')}
                isClearable
                value={propertyOptions?.find((propertyOption) => propertyOption.value === filter?.property) ?? null}
                onChange={(option) => handleFilter('property', option?.value)}
                options={propertyOptions}
                noOptionsMessage={() => L('no_project_selected')}
            />
            <ReactSelect
                placeholder={L('assignee')}
                isClearable
                value={assigneeOptions?.find((assigneOption) => assigneOption.value === filter?.assignee) ?? null}
                options={assigneeOptions}
                onChange={(option) => handleFilter('assignee', option?.value)}
            />
            <ReactSelect
                placeholder={L('status')}
                isClearable
                value={statusOptions?.find((statusOption) => statusOption.value === filter?.status_id) ?? null}
                onChange={(option) => handleFilter('status_id', option?.value)}
                options={statusOptions}
            />
            <ReactSelect
                placeholder={L('priority')}
                isClearable
                value={priorityOptions?.find((priorityOption) => priorityOption.value === filter?.priority_id) ?? null}
                onChange={(option) => handleFilter('priority_id', option?.value)}
                options={priorityOptions}
            />
            <ReactSelect
                placeholder={L('type')}
                isClearable
                value={typeOptions?.find((typeOption) => typeOption.value === filter?.type_id) ?? null}
                onChange={(option) => handleFilter('type_id', option?.value)}
                options={typeOptions}
            />
            <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleClearAll()}>
                <StyledCross />
                <Label>{L('clear_filter')}</Label>
            </div>
        </StyledFilters>
    );
};

export default TicketFilters;
