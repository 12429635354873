import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { getPropertyContactsKeys } from './propertyContactsQueries';
import { AddContactForm } from '../../components/Contacts/AddContactForm';
import { uploadToAws } from '../../../project-templates/hooks/ContentInformation/uploadToAws';

const useEditPropertyContact = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ contact, id, file }: { contact: AddContactForm; id: number; file?: File }) => {
            const image = file
                ? {
                      name: file.name,
                      size: file.size,
                      type: file.type,
                  }
                : undefined;

            const { preSingedUrl, id: imageId } = await trpc.PropertyContactsApi.editPropertyContact.mutate({
                contact: { ...contact },
                contactId: id,
                file: image,
            });

            if (preSingedUrl && file) {
                await uploadToAws({
                    preSingedUrl,
                    file,
                    cleanup: async () => {
                        await trpc.PropertyContactsApi.editPropertyContact.mutate({
                            contact,
                            contactId: id,
                        });
                    },
                });
            }

            return { imageId };
        },
        {
            onSettled: (_data, _err, variables) => {
                client.invalidateQueries(getPropertyContactsKeys.lists());
                client.invalidateQueries(getPropertyContactsKeys.detail(variables.id));
            },
        },
    );
};

export default useEditPropertyContact;
