import React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import sv from 'dayjs/locale/sv';
import relativeTime from 'dayjs/plugin/relativeTime';
import { colors } from '../../../components/Shared/Style/colors';
import { Maintenance } from '@app/api/models/Maintenances';
import { L } from '../../../lib/i18n';

dayjs.locale(sv);
dayjs.extend(relativeTime);

const Container = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maring: 'auto'
});

const CostContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'end',
    maring: 'auto'
});

const NameContainer = styled(Container)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    margin: 0
});
const Span = styled.span(({ isBefore }: { isBefore?: boolean }) => {
    return {
        display: 'flex',
        justifyContent: 'center',
        color: isBefore ? colors.statusError : 'inherit'
    };
});

const NameSpan = styled.span({
    textAlign: 'left',
    paddingLeft: '1rem'
});

const PlanedCost = styled.span({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '10px'
});

const ColorBox = styled.div(({ boxcolor }: { boxcolor: string }) => {
    return {
        backgroundColor: boxcolor,
        height: '60px',
        width: '6px'
    };
});

const Line = styled.div({
    borderTop: `1px solid ${colors.divider}`,
    gridArea: 'line1'
});
const Row = styled.div({
    display: 'grid',
    alignItems: 'center',
    gap: '0px 1rem',
    width: '100%',
    gridTemplateAreas: `
        "htype      hpartner      hcategory      hcost      hdate      hyear"
        "line1       line1       line1       line1      line1              line1"
        "type       partner       category       cost       date             year"
        "line       line       line       line       line       line"
    `
});

type Props = {
    maintenance: Partial<Maintenance>;
};

const MaintenaceRow = ({ maintenance }: Props) => {
    return (
        <>
            <Row>
                <Span style={{ gridArea: 'htype' }}>{L('action')}</Span>
                <Span style={{ gridArea: 'hpartner' }}>{L('service_partner')}</Span>
                <Span style={{ gridArea: 'hcategory' }}>{L('category')}</Span>
                <Span style={{ gridArea: 'hcost' }}>{L('total_cost')}</Span>
                <Span style={{ gridArea: 'hdate' }}>{L('planned_date')}</Span>
                <Span style={{ gridArea: 'hyear' }}>{L('year')}</Span>
                <Line style={{ marginTop: '0.5rem' }} />
                <NameContainer style={{ gridArea: 'type' }}>
                    <ColorBox
                        boxcolor={
                            maintenance.completed_date
                                ? colors.maintenance_completed_status
                                : maintenance?.maintenance_status?.color_code || ''
                        }
                    />
                    <NameSpan>{maintenance.name}</NameSpan>
                </NameContainer>
                <Span style={{ gridArea: 'partner' }}>{maintenance.service_partner_name}</Span>
                <Span style={{ gridArea: 'category' }}>{maintenance.maintenance_category?.name}</Span>
                <CostContainer>
                    {maintenance.completed_cost ? (
                        <>
                            <Span style={{ gridArea: 'cost' }}>
                                {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                    maximumFractionDigits: 0
                                }).format(+maintenance.completed_cost)}
                            </Span>
                            {maintenance.planned_cost && (
                                <PlanedCost style={{ gridArea: 'cost' }}>
                                    {L('planned_cost')}:{' '}
                                    {new Intl.NumberFormat('sv-SE', {
                                        style: 'currency',
                                        currency: 'SEK',
                                        maximumFractionDigits: 0
                                    }).format(+maintenance.planned_cost)}
                                </PlanedCost>
                            )}
                        </>
                    ) : (
                        maintenance.planned_cost && (
                            <Span style={{ gridArea: 'cost' }}>
                                {new Intl.NumberFormat('sv-SE', {
                                    style: 'currency',
                                    currency: 'SEK',
                                    maximumFractionDigits: 0
                                }).format(+maintenance.planned_cost)}
                            </Span>
                        )
                    )}
                </CostContainer>
                <Container style={{ gridArea: 'date' }}>
                    {!maintenance.completed_date && (
                        <Span isBefore={dayjs(maintenance.planned_date).isBefore(dayjs())}>
                            {dayjs(maintenance.planned_date).isValid()
                                ? dayjs(Date.now()).to(maintenance.planned_date || Date.now())
                                : ''}
                        </Span>
                    )}
                </Container>
                <Span style={{ gridArea: 'year' }}>{dayjs(maintenance.planned_date).format('YYYY')}</Span>
                <Line style={{ gridArea: 'line' }} />
            </Row>
        </>
    );
};

export default MaintenaceRow;
