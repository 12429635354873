import React, { useEffect, useMemo } from 'react';
import { Breadcrumbs, Button, Dropzone } from '@ourliving/ourliving-ui';
import type { onUploadError, onUploadSuccess } from '@ourliving/ourliving-ui';
import Input from '../../../../components/Forms/Input';
import Label from '../../../../components/Forms/Label';
import { L } from '../../../../lib/i18n';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardTitle from '../../../../components/typography/CardTitle';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import styled from '@emotion/styled';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import RadixSelect from '../../../../components/RadixSelect/RadixSelect';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import dayjs from 'dayjs';
import { getProjectKeys, useGetProject, useGetProjectImages } from '../../hooks/projectHooks/projectQueries';
import useUpdateProject from '../../hooks/projectHooks/useUpdateProject';
import { AddProject, AddProjectSchema } from '../../schemas';
import { useGetProjectTypes } from '../../hooks/projectHooks/projectTypeQueries';
import { Separator } from '../../../../components/Separator/Separator';
import { TRPCClientError } from '@trpc/client';
import { colors } from '../../../../components/Shared/Style/colors';
import Images from '../../components/Draggables/Images';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { style } from '../../../../components/Shared/Style';
import useUpdateDocSortBulk from '../../hooks/projectHooks/useUpdateProjectDocSort';
import useDragEndDndNew from '../../../../hooks/useDragEndDndNew';
import useUploadProjectDocuments from '../../hooks/projectHooks/useUploadProjectDocuments';
import { useQueryClient } from 'react-query';
import useDeleteProjectDocument from '../../hooks/projectHooks/useDeleteProjectDocument';
import BigTabMenu from '../../../../components/BigTabItem/BigTabMenu';
import BigTabNav from '../../../../components/BigTabItem/BigTabNav';
import BigNavItem from '../../../../components/BigTabItem/BigTabListItemNav';
import H1 from '../../../../components/typography/H1';
import TopAreaContainer from '../../../binders/components/TopAreaContainer';
import { margin } from '../../../../components/Shared/Style/margin';
import ConfirmationButton from '../../../../components/Buttons/ConfirmButton';
import useSetProjectTransferred from '../../hooks/adminHooks/useSetProjectTransfered';
import { Loading } from '../../../../components/Loading/Loading';
import useDeleteProject from '../../hooks/projectHooks/useDeleteProject';

type ProjectTypeOptions = { value: number | undefined; label: string }[] | undefined;

const StyledForm = styled.form({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '0 1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr 1fr',
    },
});

const GridContainer = styled.div({
    display: 'grid',
    columnGap: '1rem',
    [`@media screen and (min-width: 1024px)`]: {
        gridTemplateColumns: '1fr auto 1fr',
    },
});

const Divider = styled(Separator)({
    backgroundColor: colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
});

const RestyledEmptyState = styled(EmptyState)({
    minHeight: 'auto',
    padding: '1rem',
});

const RestyledInput = styled(Input)({}, (props: { isError?: boolean }) => ({
    borderColor: props.isError ? '#ff4545' : '',
}));

const getDateString = (timestamp?: string | null) => {
    if (timestamp) return dayjs(timestamp).format('YYYY-MM-DD');
    return null;
};

const EditProjectComponent = ({ id }: { id: string }) => {
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { data: project, status } = useGetProject(+id);
    const { data: projectTypeData } = useGetProjectTypes();
    const { mutate: deleteDocument } = useDeleteProjectDocument();
    const { data: images } = useGetProjectImages(+id);
    const { mutate: uploadMultipleProjectDocs } = useUploadProjectDocuments();
    const { mutate: updateSortBulk, isLoading: isUpdatingSort } = useUpdateDocSortBulk();
    const handleDragEnd = useDragEndDndNew({
        queryKey: getProjectKeys.imagesByProjectId(+id),
        updateSortOrder: (doc) =>
            updateSortBulk(doc, {
                onSettled: () => {
                    queryClient.invalidateQueries(getProjectKeys.lists());
                },
            }),
        documentableId: +id,
        reverseArray: true,
    });
    const { mutate: deleteProject } = useDeleteProject();

    const onUpload = (param: onUploadError | onUploadSuccess) => {
        if (param.status === 'error') {
            toast.error(L('pg_unkown'));
            return;
        }
        uploadMultipleProjectDocs(
            { files: [param.file], projectId: +id, documentType: 0 },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries([...getProjectKeys.imagesByProjectId(+id)]);
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const projectTypeOptions = useMemo<ProjectTypeOptions>(
        () => projectTypeData?.map((item) => ({ value: item.id, label: L(item.name) })),
        [projectTypeData],
    );

    const { mutate: updateProject } = useUpdateProject();

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<AddProject>({
        resolver: zodResolver(AddProjectSchema),
        resetOptions: { keepDefaultValues: true },
    });

    const onSubmit: SubmitHandler<AddProject> = (data) => {
        const dataWithId = {
            ...data,
            id: id,
        };
        return updateProject(dataWithId, {
            onSuccess: () => {
                toast.success(L('updated_project'));
            },
            onError: () => {
                toast.error(L('the_update_failed'));
            },
        });
    };

    const handleRemoveImage = (documentId: number) => {
        deleteDocument(
            { documentId, projectId: +id },
            {
                onSuccess: () => {
                    toast.success(`${L('picture')} ${L('removed').toLowerCase()}`);
                    queryClient.invalidateQueries(getProjectKeys.imagesByProjectId(+id));
                },
                onError: (err) => {
                    if (err instanceof TRPCClientError) toast.error(L('pg_unkown'));
                },
            },
        );
    };
    const { mutate: setTransferred } = useSetProjectTransferred();

    const handleSetProjectTransferred = () => {
        setTransferred(
            { projectId: +id },
            {
                onSuccess: () => {
                    toast.success(`${project?.name}  ${L('project_transferred')}`);
                    navigate('/binders/transferred');
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const imageData: { id: number; url: string }[] = useMemo(() => {
        if (!images) return [];
        return images.map((image) => {
            return { id: image.id, url: image.signed_url };
        });
    }, [images]);

    useEffect(() => {
        reset(() => {
            return {
                name: project?.name ?? undefined,
                access_date: getDateString(project?.access_date) ?? '',
                addon_end_date: getDateString(project?.addon_end_date) ?? '',
                addon_preview_date: getDateString(project?.addon_preview_date) ?? '',
                addon_start_date: getDateString(project?.addon_start_date) ?? '',
                address: project?.address ?? undefined,
                city: project?.city ?? undefined,
                commune: project?.commune ?? undefined,
                property_count: project?.property_count ? String(project?.property_count) : '',
                project_type:
                    project?.project_type !== null && project?.project_type !== undefined
                        ? project.project_type.toString()
                        : undefined,
                zip: project?.zip ?? undefined,
            };
        });
    }, [project]);

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <div>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>{project?.name ?? L('project_literally')}</Breadcrumbs.ItemActive>
            </Breadcrumbs>
            <H1>{project?.name ?? L('project_literally')}</H1>
            <BigTabMenu>
                <BigTabNav>
                    <BigNavItem end to="properties">
                        {L('properties')}
                    </BigNavItem>
                    <BigNavItem end to="templates">
                        {L('property_templates')}
                    </BigNavItem>
                    <BigNavItem end to="admins">
                        {L('admins')}
                    </BigNavItem>
                    <BigNavItem end to=".">
                        {L('edit_project')}
                    </BigNavItem>
                </BigTabNav>
            </BigTabMenu>
            {projectTypeOptions && (
                <CardWithDivider
                    topArea={
                        <TopAreaContainer>
                            <CardTitle>{L('edit_project')}</CardTitle>
                            <div style={{ marginLeft: 'auto', display: 'flex', columnGap: margin.s }}>
                                <ConfirmationButton
                                    onClick={() => null}
                                    props={{
                                        onConfirm: () => {
                                            handleSetProjectTransferred();
                                        },
                                        confirmVariant: 'danger',
                                    }}
                                >
                                    {L('transfer')}
                                </ConfirmationButton>
                                <ConfirmationButton
                                    onClick={() => null}
                                    props={{
                                        onConfirm: () => {
                                            deleteProject(+id, {
                                                onSuccess: () => {
                                                    toast.success(L('removed_project'));
                                                    navigate('..');
                                                },
                                                onError: (err) => {
                                                    if (err instanceof TRPCClientError) {
                                                        if (
                                                            err.message === 'project_with_properties_deletion_error' ||
                                                            err.message === 'project_with_content_items_deletion_error'
                                                        ) {
                                                            toast.error(L(err.message));
                                                        } else toast.error(L('an_error_occurred'));
                                                    } else toast.error(L('an_error_occurred'));
                                                },
                                            });
                                        },
                                        confirmVariant: 'danger',
                                    }}
                                >
                                    {L('delete')}
                                </ConfirmationButton>
                            </div>
                        </TopAreaContainer>
                    }
                    mainArea={
                        <GridContainer>
                            <div>
                                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                                    <ErrorAndInputWrapper
                                        errorMsg={errors.name?.message ? L(errors.name?.message) : ''}
                                    >
                                        <Label title={L('project_name')} />
                                        <RestyledInput isError={!!errors.name} type="text" {...register('name')} />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={errors.address?.message ? L(errors.address?.message) : ''}
                                    >
                                        <Label title={L('address')} />
                                        <RestyledInput
                                            isError={!!errors.address}
                                            type="text"
                                            {...register('address')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <div style={{ display: 'flex', columnGap: '1rem' }}>
                                        <div style={{ width: '50%' }}>
                                            <ErrorAndInputWrapper
                                                errorMsg={errors.zip?.message ? L(errors.zip?.message) : ''}
                                            >
                                                <Label title={L('postalCode')} />
                                                <RestyledInput
                                                    isError={!!errors.zip}
                                                    type="text"
                                                    {...register('zip')}
                                                />
                                            </ErrorAndInputWrapper>
                                        </div>
                                        <div style={{ width: '50%' }}>
                                            <ErrorAndInputWrapper
                                                errorMsg={errors.city?.message ? L(errors.city?.message) : ''}
                                            >
                                                <Label title={L('postal_locality')} />
                                                <RestyledInput
                                                    isError={!!errors.city}
                                                    type="text"
                                                    {...register('city')}
                                                />
                                            </ErrorAndInputWrapper>
                                        </div>
                                    </div>
                                    <ErrorAndInputWrapper
                                        errorMsg={errors.commune?.message ? L(errors.commune?.message) : ''}
                                    >
                                        <Label title={L('commune')} />
                                        <RestyledInput
                                            isError={!!errors.commune}
                                            type="text"
                                            {...register('commune')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={errors.project_type?.message ? L(errors.project_type?.message) : ''}
                                    >
                                        <Label title={L('project_type_translation')} />
                                        <Controller
                                            control={control}
                                            name="project_type"
                                            render={({ field: { onChange, value } }) => (
                                                <RadixSelect
                                                    isError={!!errors.project_type}
                                                    onValueChange={onChange}
                                                    value={value}
                                                    placeholder={L('please_select')}
                                                    options={projectTypeOptions}
                                                />
                                            )}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={
                                            errors.property_count?.message ? L(errors.property_count?.message) : ''
                                        }
                                    >
                                        <Label title={L('no_properties')} />
                                        <RestyledInput
                                            isError={!!errors.property_count}
                                            type="number"
                                            {...register('property_count')}
                                        />
                                    </ErrorAndInputWrapper>

                                    <ErrorAndInputWrapper
                                        errorMsg={errors.access_date?.message ? L(errors.access_date?.message) : ''}
                                    >
                                        <Label title={L('access_date_translation')} />
                                        <RestyledInput
                                            isError={!!errors.access_date}
                                            type="date"
                                            {...register('access_date')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={
                                            errors.addon_preview_date?.message
                                                ? L(errors.addon_preview_date?.message)
                                                : ''
                                        }
                                    >
                                        <Label title={L('addon_start_date_preview')} />
                                        <RestyledInput
                                            isError={!!errors.addon_preview_date}
                                            type="date"
                                            {...register('addon_preview_date')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={
                                            errors.addon_start_date?.message ? L(errors.addon_start_date?.message) : ''
                                        }
                                    >
                                        <Label title={L('addon_start_date')} />
                                        <RestyledInput
                                            isError={!!errors.addon_start_date}
                                            type={'date'}
                                            {...register('addon_start_date')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <ErrorAndInputWrapper
                                        errorMsg={
                                            errors.addon_end_date?.message ? L(errors.addon_end_date?.message) : ''
                                        }
                                    >
                                        <Label title={L('addon_end_date_translation')} />
                                        <RestyledInput
                                            isError={!!errors.addon_end_date}
                                            type="date"
                                            {...register('addon_end_date')}
                                        />
                                    </ErrorAndInputWrapper>
                                    <div style={{ gridColumn: '1 / 2', marginTop: '1rem' }}>
                                        <Button role="submit">{L('save')}</Button>
                                    </div>
                                </StyledForm>
                            </div>
                            <DividerContainer>
                                <Divider decorative orientation="vertical" />
                            </DividerContainer>
                            <div>
                                {images &&
                                    (images.length > 0 ? (
                                        <Images
                                            outerWrapperCSS={{ marginBottom: style.margin.s }}
                                            imageData={imageData}
                                            handleRemoveImage={handleRemoveImage}
                                            handleDragEnd={handleDragEnd}
                                            isUpdatingSort={isUpdatingSort}
                                        />
                                    ) : (
                                        <RestyledEmptyState>
                                            <EmptyState.Title>{L('no_images')}</EmptyState.Title>
                                            <EmptyState.Description>
                                                <p>{L('no_images_description')}</p>
                                            </EmptyState.Description>
                                        </RestyledEmptyState>
                                    ))}
                                <Dropzone onUpload={onUpload} variant="Media" multiple={true} />
                            </div>
                        </GridContainer>
                    }
                />
            )}
        </div>
    );
};

const EditProjectPage = () => {
    const { id } = useParams<{ id: string }>();
    return id ? <EditProjectComponent id={id} /> : null;
};

export default EditProjectPage;
