import React from 'react';
import { Column } from 'react-table';
import { Button } from '@ourliving/ourliving-ui';
import { Loading } from '../../../components/Loading/Loading';
import ReactTable from '../../../components/Table/ReactTable';
import { L } from '../../../lib/i18n';
import { usePromise } from '../../../lib/react';
import { SettingStore } from '../../settings/SettingsStore';
import { AddonProjectSettingsGdpr } from '../Addons/Terms/AddonProjectSettingsGdpr';
import { AddonProjectSettingsConditions } from '../Addons/Terms/AddonProjectSettingsConditions';
import { AddonProjectSettingsOtherTerms } from '../Addons/Terms/AddonProjectSettingsOtherTerms';

const TermsAndConditionsPage = () => {
    const [editGdpr, setEditGdpr] = React.useState(false);
    const [editConditions, setEditConditions] = React.useState(false);
    const [editOtherTerms, setEditOtherTerms] = React.useState(false);

    const [ready] = usePromise(async () => {
        await SettingStore.listAccountAddonsInfo();
    }, []);

    //#region outsideClick
    const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    function useOnClickOutside(ref, handler) {
        React.useEffect(() => {
            const listener = (event) => {
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener('mousedown', listener);
            document.addEventListener('touchstart', listener);
            return () => {
                document.removeEventListener('mousedown', listener);
                document.removeEventListener('touchstart', listener);
            };
        }, [ref, handler]);
    }

    useOnClickOutside(ref, () => {
        setEditConditions(false);
        setEditGdpr(false);
        setEditOtherTerms(false);
    });
    //#endregion

    const Cols: Column[] = [
        {
            Header: () => L('gdpr'),
            accessor: 'id',
            Cell: ({ id }: { id: string }) => {
                return <Button onClick={() => setEditGdpr(true)}>Ändra {id}</Button>;
            },
            disableSortBy: true,
        },
        {
            Header: () => L('terms_and_conditions'),
            accessor: 'id2',
            Cell: ({ id }: { id: string }) => {
                return <Button onClick={() => setEditConditions(true)}>Ändra {id}</Button>;
            },
            disableSortBy: true,
        },
        {
            Header: () => L('other_terms'),
            accessor: 'id3',
            Cell: ({ id }: { id: string }) => {
                return <Button onClick={() => setEditOtherTerms(true)}>Ändra {id}</Button>;
            },
            disableSortBy: true,
        },
    ];
    if (!ready) return <Loading />;

    return (
        <>
            <ReactTable columns={Cols} data={[{ id: '1' }]} />
            <AddonProjectSettingsGdpr forwardRef={ref} isActive={editGdpr} setEditGdpr={setEditGdpr} />
            <AddonProjectSettingsConditions
                forwardRef={ref}
                isActive={editConditions}
                setEditConditions={setEditConditions}
            />
            <AddonProjectSettingsOtherTerms
                forwardRef={ref}
                isActive={editOtherTerms}
                setEditOtherTerms={setEditOtherTerms}
            />
        </>
    );
};

export default TermsAndConditionsPage;
