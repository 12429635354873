import React from 'react';
import styled from '@emotion/styled';
import { useListSettings, useSwitchNotificationSetting } from '../hooks/hooks';
import { L } from '../../../lib/i18n';
import { colors } from '@app/shared/lib/Style/colors';
import StyledSwitch from '../../../components/StyledSwitch/StyledRadixSwitch';
import { NotificationSettingsTypeId } from '@app/api/models/PushNotification';

const Nav = styled.nav({
    justifySelf: 'center',
    width: '100%',
    padding: '1.5rem',
    background: colors?.pageBackground
});

const P = styled.p(() => ({
    color: colors.textColor2,
    paddingBottom: '0.5rem',
    marginBottom: '0'
}));

const Ul = styled.ul({
    padding: 0,
    margin: 0
});

const Li = styled.li(() => {
    return {
        listStyleType: 'none',
        padding: '0',
        borderBottom: `1px solid ${colors.inputBorder}`,

        '&:first-of-type': {
            borderTop: `1px solid ${colors.inputBorder}`
        }
    };
});
const Wrapper = styled.div({
    textDecoration: 'none',
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

const StyledLabel = styled.label({
    margin: '1rem 0',
    fontSize: '1.3rem',
    cursor: 'pointer'
});

const IconDivWrapper = styled.div({
    display: 'flex',
    alignItems: 'center'
});

const WrapperDiv = styled.div({
    display: 'flex',
    justifyContent: 'flex-end'
});

const PushNotificationsSettings = () => {
    const { data: listSettings } = useListSettings();
    const { mutate, isLoading } = useSwitchNotificationSetting();
    const onSwitch = (id: NotificationSettingsTypeId, value: boolean) => {
        if (isLoading) return;
        mutate({ id, value });
    };

    return (
        <Nav>
            <P>{L('notification_for')}</P>

            <Ul>
                {listSettings &&
                    listSettings.map(res => {
                        return (
                            <Li key={res.setting_id}>
                                <Wrapper>
                                    <IconDivWrapper>
                                        <StyledLabel htmlFor={`push_notification_settings_${res.type}`}>
                                            {L(`push_notification_settings_${res.type}`)}
                                        </StyledLabel>
                                    </IconDivWrapper>

                                    <WrapperDiv>
                                        <StyledSwitch
                                            onCheckedChange={() => {
                                                onSwitch(res.setting_id, res.value);
                                            }}
                                            disabled={isLoading}
                                            checked={res.value}
                                            id={`push_notification_settings_${res.type}`}
                                        />
                                    </WrapperDiv>
                                </Wrapper>
                            </Li>
                        );
                    })}
            </Ul>
        </Nav>
    );
};

export default PushNotificationsSettings;
