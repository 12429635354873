import * as React from 'react';
import { ForgottenPasswordStore } from './ForgottenPasswordStore';
import { useObserver } from 'mobx-react';
import { NavLink } from 'react-router-dom';
import { style } from '../../components/Shared/Style';
import styled from '@emotion/styled';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../lib/i18n';
import { useState } from 'react';
import Logo from '../../components/Logo/Logo';

//#region css

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const ErrorMessage = styled.strong({
    color: 'red'
});

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const SuccessMessage = styled.strong({
    color: style.colors.primary
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TextField = styled.div({
    paddingBottom: style.margin.ml
});

const LoginForm = styled.div({
    width: '80%'
});

const StyledNavLink = styled(NavLink)({
    textDecoration: 'none',
    color: style.colors.primary,
    lineHeight: '32px',
    fontSize: style.fontSize.seta,
    fontWeight: style.fontWeight.bold
});

const LoginInput = styled.input({
    width: '100%',
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    fontSize: style.fontSize.seta,
    paddingLeft: style.margin.s
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center'
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit'
});

const Form = styled.form({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px'
});

//#endregion

export const ForgottenPasswordPage = () => {
    const [email, setEmail] = useState('');

    const sendPasswordResetInstructions = e => {
        e.preventDefault();
        ForgottenPasswordStore.sendPasswordResetInstructions(email);
    };

    return useObserver(() => {
        return (
            <Background>
                <Form onSubmit={sendPasswordResetInstructions}>
                    <StyledLogo />

                    <LoginForm>
                        {ForgottenPasswordStore.state !== 'password_reset' && ForgottenPasswordStore.state !== '' && (
                            <Error>
                                <ErrorMessage>{L('reset_' + ForgottenPasswordStore.state)}</ErrorMessage>
                            </Error>
                        )}
                        {ForgottenPasswordStore.state === 'password_reset' && (
                            <Sucess>
                                <SuccessMessage>{L('reset_' + ForgottenPasswordStore.state)}</SuccessMessage>
                            </Sucess>
                        )}
                        <Header>{L('email')}</Header>
                        <TextField>
                            <LoginInput value={email} onChange={v => setEmail(v.target.value)} />
                        </TextField>

                        <ButtonMenu>
                            <Button onClick={sendPasswordResetInstructions} style={{ marginRight: '0px' }}>
                                {L('reset_password')}
                            </Button>
                            <StyledNavLink to="/">{L('cancel')}</StyledNavLink>
                        </ButtonMenu>
                    </LoginForm>
                </Form>
            </Background>
        );
    });
};
