Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getlist"] = b.createRpcProxy("DocumentsApi", "getlist", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = b.createRpcProxy("DocumentsApi", "getbyid", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["get"] = b.createRpcProxy("DocumentsApi", "get", {
  "urlPrefix": "/rpc"
});
o["uploadToAwsStore"] = b.createRpcProxy("DocumentsApi", "uploadToAwsStore", {
  "urlPrefix": "/rpc"
});
o["uploadMultipleToAwsStore"] = b.createRpcProxy("DocumentsApi", "uploadMultipleToAwsStore", {
  "urlPrefix": "/rpc"
});
o["uploadFilesToStore"] = b.createRpcProxy("DocumentsApi", "uploadFilesToStore", {
  "urlPrefix": "/rpc"
});
o["uploadToPublic"] = b.createRpcProxy("DocumentsApi", "uploadToPublic", {
  "urlPrefix": "/rpc"
});
o["uploadToProject"] = b.createRpcProxy("DocumentsApi", "uploadToProject", {
  "urlPrefix": "/rpc"
});
o["getMjmlTemplate"] = b.createRpcProxy("DocumentsApi", "getMjmlTemplate", {
  "urlPrefix": "/rpc"
});
o["getSignedUrlStore"] = b.createRpcProxy("DocumentsApi", "getSignedUrlStore", {
  "urlPrefix": "/rpc"
});
o["getSignedUrl"] = b.createRpcProxy("DocumentsApi", "getSignedUrl", {
  "urlPrefix": "/rpc"
});
o["getListPublicFiles"] = b.createRpcProxy("DocumentsApi", "getListPublicFiles", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});