Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["getbyid"] = b.createRpcProxy("ProductsApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["listProducts"] = b.createRpcProxy("ProductsApi", "listProducts", {
  "urlPrefix": "/rpc"
});
o["listUniqueProducts"] = b.createRpcProxy("ProductsApi", "listUniqueProducts", {
  "urlPrefix": "/rpc"
});
o["listProductsByIds"] = b.createRpcProxy("ProductsApi", "listProductsByIds", {
  "urlPrefix": "/rpc"
});
o["listProductGroups"] = b.createRpcProxy("ProductsApi", "listProductGroups", {
  "urlPrefix": "/rpc"
});
o["delete"] = b.createRpcProxy("ProductsApi", "delete", {
  "urlPrefix": "/rpc"
});
o["updatePrice"] = b.createRpcProxy("ProductsApi", "updatePrice", {
  "urlPrefix": "/rpc"
});