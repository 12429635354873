import styled from '@emotion/styled';
import React, { FC } from 'react';
import { useModal } from './Context/ModalContext';

const ModalWrapper = styled.div(() => {
    return {
        display: 'grid',
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 900,
        maxHeight: '100vh',
        overflowY: 'auto',
    };
});

const Close = styled.div({
    position: 'fixed',
    top: '1rem',
    right: '1rem',
    color: '#FFFFFF',
    fontSize: '2rem',
    fontWeight: 'bold',
    cursor: 'pointer',
});

const NewModal: FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { isModalOpen, ToggleIsModalOpen, injectedChildren } = useModal();

    if (!isModalOpen) return null;
    return (
        <ModalWrapper onClick={() => ToggleIsModalOpen()}>
            <>
                <Close
                    onClick={(e) => {
                        e.stopPropagation();
                        ToggleIsModalOpen();
                    }}
                >
                    X
                </Close>
                {injectedChildren}
                {children}
            </>
        </ModalWrapper>
    );
};

export default NewModal;
