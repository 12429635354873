Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["listSpaceNames"] = b.createRpcProxy("PropertiesApi", "listSpaceNames", {
  "urlPrefix": "/rpc"
});
o["listSpaces"] = b.createRpcProxy("PropertiesApi", "listSpaces", {
  "urlPrefix": "/rpc"
});
o["listPropertiesByUser"] = b.createRpcProxy("PropertiesApi", "listPropertiesByUser", {
  "urlPrefix": "/rpc"
});
o["getTypePlate"] = b.createRpcProxy("PropertiesApi", "getTypePlate", {
  "urlPrefix": "/rpc"
});
o["listProperties"] = b.createRpcProxy("PropertiesApi", "listProperties", {
  "urlPrefix": "/rpc"
});