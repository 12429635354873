import { Property } from '@app/api/models/Addons';
import AddonsApi from '@app/api/public/AddonsApi';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { L } from '../../../../lib/i18n';

type InviteMembersToAddon = { properties: Property['id'][] };
const inviteAddonMembers = (variables: InviteMembersToAddon) => {
    return AddonsApi.invite(variables.properties);
};

const useInviteMembersToAddon = () => {
    return useMutation((variables: InviteMembersToAddon) => inviteAddonMembers(variables), {
        onMutate: () => {
            const id = toast.loading(L('inviting_addon_members'));
            return id;
        },
        onSuccess: (_data, _variables, id: string) => {
            toast.success(L('invite_addon_members_success'), { id });
        },
        onError: (error, _variables, id: string) => {
            toast.error(L('invite_addon_members_error'), { id });
        }
    });
};

export default useInviteMembersToAddon;
