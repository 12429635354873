import React, { FC } from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { p } from '../Shared/Style/typography';

const Divider = styled.div({
    width: '100%',
    height: '1px',
    backgroundColor: style.colors.divider,
});
const DividerText = styled.div({
    ...p,
    color: style.colors.divider,
});
const LastReadWrapper = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr max-content 1fr',
    width: '100%',
    alignItems: 'center',
    columnGap: '1ch',
});
const MessageDivider: FC = ({ children, ...rest }) => {
    return (
        <LastReadWrapper {...rest}>
            <Divider />
            <DividerText>{children}</DividerText>
            <Divider />
        </LastReadWrapper>
    );
};

export default MessageDivider;
