import React from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import { useParams } from 'react-router-dom-v5-compat';
import MemberForm from '../../components/Members/MemberForm';
import { SubmitHandler } from 'react-hook-form';
import { AddMember } from '../../schemas';
import toast from 'react-hot-toast';
import { useGetPropertyMember } from '../../hooks/memberHooks/propertyMemberQueries';
import styled from '@emotion/styled';
import { style } from '../../../../components/Shared/Style';
import { Separator } from '../../../../components/Separator/Separator';
import ErrorAndInputWrapper, { InputWithError } from '../../../../components/Forms/ErrorAndInputWrapper';
import dayjs from 'dayjs';
import LabelRequired from '../../../../components/Forms/LabelRequired';
import MemberPropertyList from '../../components/Members/MemberPropertyList';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { Loading } from '../../../../components/Loading/Loading';
import useUpdateMemberOnProperty from '../../hooks/memberHooks/useUpdateMemberOnProperty';

const Divider = styled(Separator)({
    backgroundColor: style.colors.divider,
});

const DividerContainer = styled.div({
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    ['@media screen and (max-width: 992px)']: {
        display: 'none',
    },
});

const StyledH3 = styled.h3({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between',
});

const Grid = styled.div({
    display: 'grid',
    columnGap: '1rem',
    gridTemplateColumns: 'minmax(0px, 1fr) auto 1fr',
    gridTemplateAreas: `
    "member                divider  extra_information"
    "member_in_properties  divider  extra_information"
    `,
});

const PropertyMemberDetail = ({ memberId, propertyId }: { propertyId: string; memberId: string }) => {
    const { mutate: updateMemberOnProperty } = useUpdateMemberOnProperty();
    const { data: member, status } = useGetPropertyMember({ id: +memberId, propertyId: +propertyId });

    const onSubmit: SubmitHandler<AddMember> = (data) => {
        updateMemberOnProperty(
            { propertyId: +propertyId, email: data.email, membershipId: +memberId },
            {
                onSuccess: () => {
                    toast.success(L('updated_member'));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const { id: projectId } = useParams<{ id: string }>();
    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <>
            <Breadcrumbs style={{ marginBottom: '1rem' }}>
                <Breadcrumbs.Item to="../../../../..">
                    {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                </Breadcrumbs.Item>
                <Breadcrumbs.Item to="../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                <Breadcrumbs.ItemActive>
                    {member?.user_name ? member.user_name : member?.email ? member.email : L('member')}
                </Breadcrumbs.ItemActive>
            </Breadcrumbs>

            {status !== 'loading' && (
                <H1>{member?.user_name ? member.user_name : member?.email ? member.email : L('member')}</H1>
            )}
            <CardWithDivider
                topArea={
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CardTitle>{L('edit_member')}</CardTitle>
                    </div>
                }
                mainArea={
                    status !== 'loading' && (
                        <Grid>
                            <div style={{ gridArea: 'member' }}>
                                <StyledH3>{L('member')}</StyledH3>
                                <MemberForm
                                    formStyling={{ padding: 0, display: 'flex', flexDirection: 'column' }}
                                    defaultValues={{ email: member?.email ?? undefined }}
                                    onSubmit={onSubmit}
                                />
                            </div>
                            <div style={{ gridArea: 'member_in_properties' }}>
                                <StyledH3>{L('member_property')}</StyledH3>
                                <div style={{ marginTop: style.margin.ml }}>
                                    {member && member.user_id && <MemberPropertyList userId={+member.user_id} />}
                                </div>
                            </div>
                            <DividerContainer style={{ gridArea: 'divider' }}>
                                <Divider decorative orientation="vertical" />
                            </DividerContainer>
                            <div style={{ gridArea: 'extra_information' }}>
                                <StyledH3>{L('extra_information')}</StyledH3>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('name')} />
                                    <InputWithError readOnly value={member?.user_name ?? undefined} />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('phone_number')} />
                                    <InputWithError readOnly value={member?.phone_number ?? undefined} />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('created_at')} />
                                    <InputWithError
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(member?.created_at).format('YYYY-MM-DDTHH:mm') ?? undefined}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('last_login')} />
                                    <InputWithError
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(member?.last_login_at).format('YYYY-MM-DDTHH:mm') ?? undefined}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('invite_sent')} />
                                    <InputWithError
                                        type="datetime-local"
                                        readOnly
                                        value={dayjs(member?.invited_at).format('YYYY-MM-DDTHH:mm') ?? undefined}
                                    />
                                </ErrorAndInputWrapper>
                                <ErrorAndInputWrapper>
                                    <LabelRequired title={L('account_confirmed')} />
                                    <InputWithError
                                        type="datetime-local"
                                        readOnly
                                        value={
                                            dayjs(member?.account_confirmed_at).format('YYYY-MM-DDTHH:mm') ?? undefined
                                        }
                                    />
                                </ErrorAndInputWrapper>
                            </div>
                        </Grid>
                    )
                }
            />
        </>
    );
};

const PropertyMemberDetailPage = () => {
    const { memberId, propertyId } = useParams<{ propertyId: string; memberId: string }>();
    return memberId && propertyId ? <PropertyMemberDetail memberId={memberId} propertyId={propertyId} /> : null;
};

export default PropertyMemberDetailPage;
