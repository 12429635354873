import React from 'react';
import { MutatedInventoryData } from '../../../copy-function/pages/ProductsPage';
import { createColumnHelper } from '@tanstack/react-table';
import styled from '@emotion/styled';
import { TextButton } from '../emotion/TextButton';
import { TableMeta } from '../../TableMetaTypes/TableMetaType';
import { L } from '../../../../lib/i18n';
import FlexRightContainer from '../emotion/FlexRightContainer';
import { RemoveIcon } from '../emotion/RemoveIcon';
import { AddIcon } from '../emotion/AddIcon';
import NumericCell from '../NumericCell';
import toast from 'react-hot-toast';
import { Checkbox } from '../../../../components/Checkbox/Checkbox';
import TableImageWithFallback from '../TableImageWithFallback';

const columnHelper = createColumnHelper<MutatedInventoryData>();

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const column = [
    columnHelper.display({
        id: 'selection',
        header: ({ table }) => (
            <Checkbox checked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
        ),
        cell: ({ row }) => (
            <div onClick={(e) => e.stopPropagation()}>
                <Checkbox
                    checked={row.getIsSelected()}
                    disabled={!row.getCanSelect()}
                    onChange={row.getToggleSelectedHandler()}
                />
            </div>
        ),
        size: 30,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
        enableResizing: false,
    }),
    columnHelper.display({
        id: 'image',
        cell: ({ row }) => (
            <div style={{ width: '100px', aspectRatio: '4/3' }}>
                <TableImageWithFallback id={row.original?.product_image_data?.original?.id} />
            </div>
        ),
        enableResizing: false,
        size: 75,
        meta: {
            styles: {
                flex: '0 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.property_name, {
        id: 'property_name',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.property_name)}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('property')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
                width: 120,
            },
        },
    }),
    columnHelper.accessor((row) => row.space_name, {
        id: 'space_name',
        cell: (info) => (
            <CellContainer>
                <Data>{info.row.original?.space_name}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('space')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.function, {
        id: 'function',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.function?.length > 0 ? info.row.original.function : '')}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('function')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.name, {
        id: 'name',
        cell: (info) => (
            <CellContainer>
                <Data>{L(info.row.original?.name)}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('product_group')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.product_name, {
        id: 'product_name',
        cell: (info) => (
            <CellContainer>
                {info.row.original?.count ? (
                    <Data>
                        {L(info.row.original?.product_name)}

                        <span
                            style={{
                                fontWeight: 'bolder',
                            }}
                        >
                            ({info.row.original?.count}C)
                        </span>
                    </Data>
                ) : (
                    <Data>{info.row.original?.product_name}</Data>
                )}
            </CellContainer>
        ),
        header: () => <Flex>{L('product')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.color, {
        id: 'color',
        cell: (info) => (
            <CellContainer>
                <Data>{info.row.original.color}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('product_color')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.addon_price, {
        id: 'addon_price',
        cell: ({ table, row }) => {
            const tableMeta = table.options.meta as TableMeta;
            return (
                <CellContainer style={{ width: '100%' }} onClick={(e) => e.stopPropagation()}>
                    <NumericCell
                        addon_price={row.original.addon_price}
                        id={row.original.id}
                        previewDeletion={tableMeta.previewDeletion}
                        previewPrices={tableMeta.previewPrices}
                        updateData={tableMeta.updateData}
                    />
                </CellContainer>
            );
        },
        header: () => <Flex>{L('product_purchased_price')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.brand, {
        id: 'brand',
        cell: (info) => (
            <CellContainer>
                <Data>{info.row.original.brand}</Data>
            </CellContainer>
        ),
        header: () => <Flex>{L('brand')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
    columnHelper.accessor((row) => row.inbasket, {
        id: 'inbasket',
        cell: (info) => (
            <FlexRightContainer>
                {info.row.original.inbasket ? (
                    <TextButton
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            const meta = info.table.options.meta as TableMeta;
                            const newIds = meta.storedIds.filter((storedId) => storedId !== info.row.original.id);
                            meta.setStoredIds(newIds);
                            toast.success(L('removed_from_basket'));
                        }}
                    >
                        <RemoveIcon />
                    </TextButton>
                ) : (
                    <TextButton
                        role="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            (info.table.options.meta as TableMeta).addIdsToBasket([info.row.original.id]);
                        }}
                    >
                        <AddIcon />
                    </TextButton>
                )}
            </FlexRightContainer>
        ),
        header: () => <Flex>{L('product_basket')}</Flex>,
        meta: {
            styles: {
                flex: '1 0 auto',
            },
        },
    }),
];

export default column;
