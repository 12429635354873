import * as React from 'react';
import { useObserver } from 'mobx-react';
import styled from '@emotion/styled';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { Header } from '../../../components/Layout/Header';
import { Card } from '../../../components/Layout/Card';
import { ContentInformationStore } from '../ContentInformationStore';
import { Dropdown } from '../../../components/Dropdown/Dropdown';
import { usePromise } from '../../../lib/react';
import { Button } from '@ourliving/ourliving-ui';
import { Dropzone } from '@ourliving/ourliving-ui';
import { TextInput } from '../../../components/Forms/Text';
import { toast } from 'react-hot-toast';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui/dist/components/DropZone/DropZone';

//#region css
const Content = styled.div({
    borderRadius: style.roundCornerSize.small,
    padding: style.margin.l,
    paddingBottom: style.margin.xs,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
});

const ContentLeft = styled.div({
    borderRadius: style.roundCornerSize.small,
    display: 'flex',
    flexDirection: 'column',
    width: '49%',
    paddingRight: style.margin.l,
});

const InfoText = styled.div({
    textAlign: 'left',
    fontWeight: style.fontWeight.bold,
    textTransform: 'uppercase',
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    marginBottom: style.margin.s,
    marginTop: style.margin.s,
});

const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
});

const ButtonContainer = styled.div({
    width: 'auto',
    paddingTop: style.margin.s,
    display: 'flex',
    justifyContent: 'flex-end',
});

//#endregion

export const InformationNewDocument = () => {
    const store = ContentInformationStore;

    const [selectedGroup, setSelectedGroup] = React.useState('');

    usePromise(async () => await store.listDocumentGroups(), []);

    const onChange = (value: string, name: string) => {
        store.setValue(value, name);
    };

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return toast(`❌ ${L('reset_error')}`);

        store.setValue(param.file, 'file');
        console.log('param status' + param.file);
        toast(`✅ ${L('document_uploaded')}`);
    };

    const save = () => {
        store.saveDocument();
    };

    const groups = store.documentGroups.map((r) => ({ label: r.name, value: r.name }));

    return useObserver(() => {
        return (
            <>
                <Header to={'../'} text={`<-` + L('back_to_info')} title={L('upload_document')} />
                <Content>
                    <ContentLeft>
                        <Card background={style.colors.white}>
                            <TextContainer>
                                <InfoText>{L('document')}</InfoText>
                                <Dropzone onUpload={onUpload} variant="Document" />
                                <InfoText>{L('document_group')}</InfoText>
                                <Dropdown
                                    placeholder="Välj grupp"
                                    name={'content_document_group_id'}
                                    options={groups}
                                    value={selectedGroup}
                                    onChange={(value, name) => {
                                        onChange(value, name);
                                        setSelectedGroup(value);
                                    }}
                                />
                                <InfoText>{L('name')}</InfoText>
                                <TextInput name="name" onChange={onChange} />
                            </TextContainer>
                            <ButtonContainer>
                                <Button onClick={save} style={{ marginRight: '0px' }}>
                                    {L('save')}
                                </Button>
                            </ButtonContainer>
                        </Card>
                    </ContentLeft>
                </Content>
            </>
        );
    });
};
