import React from 'react';
import { style } from '../../../components/Shared/Style';
import { L } from '../../../lib/i18n';
import { RouterOutput } from '../../../lib/trpc';
import Images from '../../binders/components/Draggables/Images';
import useDragEndDndNew from '../../../hooks/useDragEndDndNew';
import { getProductsKeys } from '../../binders/hooks/productQueries';
import useUploadProductDocuments from '../../binders/hooks/productHooks/useUploadProductDocuments';
import useRemoveProductDocument from '../../binders/hooks/productHooks/useRemoveProductDocument';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import ImageDropZoneNoText from '../../../components/ImageDropzone/ImageDropZoneNoText';

const ProductImages = ({
    productId,
    images,
    updateSortBulk,
    isUpdatingSort,
}: {
    productId: number;
    images: RouterOutput['ProductsApi']['listProductImages'];
    updateSortBulk: Parameters<typeof useDragEndDndNew>[0]['updateSortOrder'];
    isUpdatingSort: boolean;
}) => {
    const imagesData =
        images?.map((document) => {
            return { id: document.id, url: document.signed_url };
        }) ?? [];

    const { mutate: uploadDocs } = useUploadProductDocuments();
    const { mutate: removeDocument } = useRemoveProductDocument();
    const queryClient = useQueryClient();

    const onImageUpload = (files: File[]) => {
        uploadDocs(
            { files, productId: +productId, documentType: 0 },
            {
                onSuccess: () => {
                    toast.success(L('image_uploaded'));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('an_error_occurred'));
                },
            },
        );
    };

    const handleRemoveDocument = (documentId: number) => {
        removeDocument(
            { documentId, entityId: +productId },
            {
                onSuccess: () => {
                    toast.success(`${L('document_removed')}`);
                    queryClient.invalidateQueries(getProductsKeys.documentsByProductId(+productId));
                    queryClient.invalidateQueries(getProductsKeys.imagesByProductId(+productId));
                },
                onError: () => {
                    toast.error(L('pg_unkown'));
                },
            },
        );
    };

    const handleDragEndImages = useDragEndDndNew({
        queryKey: getProductsKeys.imagesByProductId(+productId),
        updateSortOrder: updateSortBulk,
        documentableId: +productId,
        reverseArray: true,
    });

    return (
        <Images
            outerWrapperCSS={{ marginBottom: style.margin.s }}
            handleDragEnd={handleDragEndImages}
            imageData={imagesData}
            handleRemoveImage={handleRemoveDocument}
            isUpdatingSort={isUpdatingSort}
        >
            <ImageDropZoneNoText onDrop={onImageUpload} />
        </Images>
    );
};

export default ProductImages;
