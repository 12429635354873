import React from 'react';
import { Icon } from '../Icon/Icon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from '@emotion/styled';

const IconWrapper = styled.span({
    display: 'flex',
    alignItems: 'center',
    '.st0': { fill: '#AFAFAF' },
    cursor: 'grab'
});

const Wrapper = styled.div({
    display: 'grid',
    gap: '1rem',
    alignItems: 'center',
    gridTemplateColumns: '0.02fr 0.4fr'
});

export default function SortableItem(props: { id: string | number; children: React.ReactNode }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <li ref={setNodeRef} style={style}>
            <Wrapper>
                <IconWrapper {...attributes} {...listeners}>
                    <Icon.VerticalMoreIcon
                        size="small"
                        style={{
                            justifySelf: 'left',
                            visibility: 'visible'
                        }}
                    />
                    <Icon.VerticalMoreIcon
                        size="small"
                        style={{
                            justifySelf: 'left',
                            visibility: 'visible',
                            marginLeft: '-6px'
                        }}
                    />
                </IconWrapper>
                {props.children}
            </Wrapper>
        </li>
    );
}
