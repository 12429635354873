import styled from '@emotion/styled';
import React from 'react';
import { style } from '@app/shared/lib/Style';
import { ProductListingV2 } from '@app/api/models/Addons';

const Image = styled.img({
    width: '63px',
    height: '54px',
    borderRadius: '4px',
    boxShadow: '3px 6px 6px rgba(0, 0, 0, 0.15)',
    objectFit: 'cover'
});

const TextTitle = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%',
    fontSize: style.fontSize.epsilon,
    lineHeight: '16px',
    flexDirection: 'row',
    fontWeight: style.fontWeight.thin
});

const Info = styled.div({
    display: 'flex',
    width: '80%',
    fontSize: style.fontSize.epsilon,
    justifyContent: 'flex-start',
    lineHeight: '14px',
    fontFamily: 'Lato',
    paddingTop: style.margin.s,
    fontWeight: style.fontWeight.thin,
    color: style.colors.black
});

const Row = styled.div({
    display: 'flex',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    flexDirection: 'row',
    width: '100%',
    marginTop: `${style.margin.l}px`,
    padding: `0px ${style.margin.l}px`,
    '&:last-of-type': {
        marginBottom: `${style.margin.l}px`
    }
});

const Column = styled.div({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingLeft: style.margin.m
});

interface Props {
    product: ProductListingV2;
    productGroupName: string;
}

export const ProductConfirm = (props: Props) => {
    const p = props.product;
    const fallbackImage =
        'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/product-fallback-image-white.jpg';

    return (
        <Row key={p.id}>
            <Image
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = fallbackImage;
                }}
                src={
                    props?.product?.image_signed_url ||
                    'https://s3.eu-west-1.amazonaws.com/public.ourliving.se/ourliving/product-fallback-image-white.jpg'
                }
            />

            <Column>
                <TextTitle>
                    {props.productGroupName && props.productGroupName + '  '} {p.name}
                </TextTitle>
                <Info>{p.product_name}</Info>
                <Info> {`${p.addon_selected_info.price} kr`}</Info>
                <Info> {p.addon_selected_info?.custom_request}</Info>
            </Column>
        </Row>
    );
};
