import React, { useState } from 'react';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import ReactSelect from '../../../../components/Dropdown/ReactSelect';
import { useNavigate } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import { useCreateTodo } from './hooks/todoMutations';
import toast from 'react-hot-toast';
import { Label } from '@ourliving/ourliving-ui';
import { useGetTodoDetail, useGetTodos } from '../../../todos/hooks/accountTodoQueries';
import { formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import TodoForm from './TodoForm';

const TodoNew = ({ propertyId }: { propertyId: number }) => {
    const navigate = useNavigate();
    const todosQuery = useGetTodos();
    const [templateId, setTemplateId] = useState<number>();
    const { mutate: createTodo } = useCreateTodo();
    const { data: template } = useGetTodoDetail({ todoId: templateId });

    const options = todosQuery.data?.map((todo) => {
        return { value: todo.id, label: todo.name };
    });

    return (
        <>
            <CardWithDivider
                style={{ maxWidth: '800px' }}
                topArea={<CardTitle>{L('create_new_todo')}</CardTitle>}
                mainArea={
                    <>
                        <div style={{ paddingBottom: '4rem' }}>
                            <Label htmlFor="template">{L('create_from_template')}</Label>
                            <ReactSelect
                                id="template"
                                placeholder={L('template')}
                                value={options?.find((option) => option.value === template?.id) || null}
                                isClearable
                                options={options}
                                onChange={(e) => {
                                    setTemplateId(e?.value);
                                }}
                            />
                        </div>

                        <TodoForm
                            key={template?.id}
                            formValues={{
                                name: template?.name || '',
                                description: template?.description ?? undefined,
                                tips: template?.tips ?? undefined,
                                recurrency: template?.recurrency ?? undefined,
                                template_for_type: template?.template_for_type ?? undefined,
                                template_for_subtype: template?.template_for_subtype ?? undefined,
                                due_at: template?.due_at ? formatInputDateTimeDayjs(template?.due_at) : undefined,
                            }}
                            onSubmit={(formValues) =>
                                createTodo(
                                    {
                                        ...formValues,
                                        propertyId,
                                    },
                                    {
                                        onSuccess: (data) => {
                                            toast.success(`${data.name} ${L('created')}`);
                                            navigate(`../${data.id}`);
                                        },
                                    },
                                )
                            }
                        />
                    </>
                }
            />
        </>
    );
};

export default TodoNew;
