import { useMutation } from 'react-query';
import { trpc } from '../../../../lib/trpc';

const useUpdateSpaceDocSortTemplateProperty = () => {
    return useMutation(async (params: { docs: { id: number }[]; documentable_id: number }) => {
        return trpc.TemplatesApi.updateSpaceSortPropertyTemplate.mutate({
            docs: params.docs,
            entityId: params.documentable_id,
        });
    });
};

export default useUpdateSpaceDocSortTemplateProperty;
