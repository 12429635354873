import React, { useState, useEffect } from 'react';
import reactCSS from 'reactcss';
import { TwitterPicker } from 'react-color';

type Props = {
    getValue: (value: string) => void;
    initialColor: string;
};

const BoxColorPicker = ({ getValue, initialColor }: Props) => {
    const parsedInitialColor = initialColor.match(/\d+/g);
    const parsedRgb = () => {
        if (!parsedInitialColor || parsedInitialColor.length !== 3) return null;
        return {
            r: parsedInitialColor[0],
            g: parsedInitialColor[1],
            b: parsedInitialColor[2],
        };
    };
    const rgb = parsedRgb();
    const [edited, setEdited] = useState(false);
    const [state, setState] = useState({
        displayColorPicker: false,
        color: {
            r: rgb ? rgb.r : '142',
            g: rgb ? rgb.g : '209',
            b: rgb ? rgb.b : '252',
        },
    });

    useEffect(() => {
        getValue(`rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`);
    }, [state]);

    const handleClick = () => {
        const copyState = { ...state };
        setState({ ...copyState, displayColorPicker: !copyState.displayColorPicker });
    };

    const handleClose = () => {
        const copyState = { ...state };
        setState({ ...copyState, displayColorPicker: false });
    };

    const handleChange = (color: { rgb: { r: string; g: string; b: string } }) => {
        setEdited(true);
        const copyState = { ...state };
        setState({ ...copyState, color: color.rgb });
    };

    const styles = reactCSS({
        default: {
            color: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
                width: '40px',
                height: '40px',
                cursor: 'pointer',
                borderRadius: '2px',
                background:
                    !edited && initialColor
                        ? initialColor
                        : `rgb(${state.color.r}, ${state.color.g}, ${state.color.b})`,
            },
            popover: {
                position: 'absolute',
                zIndex: '2',
            },
            cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
            },
        },
    });
    return (
        <div>
            <div style={styles.swatch} onClick={handleClick}>
                <div style={styles.color} />
            </div>
            {state.displayColorPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <TwitterPicker color={state.color} onChange={handleChange} />
                </div>
            ) : null}
        </div>
    );
};

export default BoxColorPicker;
