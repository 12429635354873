import * as React from 'react';
import { AlertFilter } from '@app/api/models/Alterts';
import { Filters } from '../../../components/Filters/Filters';
import { FilterProject } from './FilterProject';

interface Props {
    onChange: (f: AlertFilter) => void;
    filter?: AlertFilter;
}

export const AlertFilterPanel = ({ onChange, filter }: Props) => {
    return (
        <Filters>
            <FilterProject onChange={onChange} filter={filter} />
        </Filters>
    );
};
