import { observable } from 'mobx';
import UsersApi from '@app/api/public/UsersApi';
import * as EmailValidator from 'email-validator';

class ForgottenPasswordStoreClass {
    @observable state: '' | 'password_reset' | 'error' | 'not_mail' | 'expired' | 'no_valid_interval' = '';
    @observable token: string;

    async sendPasswordResetInstructions(email: string) {
        if (!EmailValidator.validate(email)) {
            this.state = 'not_mail';
            return;
        }
        // const r = await UsersApi.requestConfirmAccount(email);
        const r = await UsersApi.resetPasswordRequest(email);
        if (r && r.result) {
            this.state = 'no_valid_interval';
            return;
        } else this.state = 'password_reset';
        return r;
    }
}

export const ForgottenPasswordStore = new ForgottenPasswordStoreClass();
