import React, { useRef } from 'react';
import * as Icon from 'react-feather';
import styled from '@emotion/styled';
import { Button } from '@ourliving/ourliving-ui';

interface Props {
    children?: React.ReactNode;
    // onChange?: any;
    onClick?: () => void;
    custom?: string;
    onFilesSelected: (files: File[]) => void;
    allowOnly?: string;
    id?: string;
}

const FileLabel = (props) => <label {...props} />;

const Cursor = styled.div({
    cursor: 'pointer',
});

const FileInput = styled.input({
    position: 'absolute',
    width: '0',
    height: '0',
    visibility: 'hidden',
});

export function FileUpload(props: Props) {
    const onChange = (evt) => {
        const files = Array.from(evt.target.files) as File[];
        props.onFilesSelected(files);
    };

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <FileLabel custom={props.custom} htmlFor={props.id || 'fileUpload'} {...props}>
            <FileInput
                id={props.id || 'fileUpload'}
                type="file"
                accept={props.allowOnly}
                onChange={onChange}
                ref={inputRef}
            />
            {props.custom == 'yes' ? (
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        inputRef?.current?.click();
                    }}
                >
                    {props.children}
                </Button>
            ) : (
                <Cursor>
                    <Icon.Paperclip />
                </Cursor>
            )}
        </FileLabel>
    );
}
