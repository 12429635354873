import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../lib/trpc';
import toast from 'react-hot-toast';
import { L } from '../../../lib/i18n';
import { getProductFilterKeys, getProductsKeys } from '../../binders/hooks/productQueries';
import { getPropertyProductsTemplatesKeys } from '../../project-templates/hooks/PropertyTemplates/propertyProductsTemplateQueries';

const useSavePublicProductOnOrg = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (options: { productIds: number[] }) => {
            return trpc.BindersApi.savePublicTemplateProductOnOrg.query({
                products: options.productIds,
            });
        },
        {
            onSettled: (data) => {
                const numberOfErrors: number[] = [];
                const numberOfSuccesses: number[] = [];
                data?.forEach((item) => {
                    if (item.message.status === 'error') numberOfErrors.push(item.id);
                    if (item.message.status === 'ok') numberOfSuccesses.push(item.id);
                });
                if (numberOfErrors.length > 0) toast.error(L('copy_fail'));
                else if (numberOfSuccesses.length > 0 && numberOfErrors.length === 0)
                    numberOfSuccesses.length > 1
                        ? toast.success(`${numberOfSuccesses.length} ${L('products_were_added_to_org').toLowerCase()}`)
                        : toast.success(`${numberOfSuccesses.length} ${L('product_was_added_to_org').toLowerCase()}`);
                queryClient.invalidateQueries([...getProductsKeys.lists()]);
                queryClient.invalidateQueries([...getPropertyProductsTemplatesKeys.infiniteLists()]);
                queryClient.invalidateQueries(getProductFilterKeys.lists());
            },
            onError() {
                toast.error(L('an_error_occurred'));
            },
        },
    );
};

export default useSavePublicProductOnOrg;
