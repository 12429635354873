import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    Row as RowType,
} from '@tanstack/react-table';
import { useNavigate, Link } from 'react-router-dom-v5-compat';
import { L } from '../../../../lib/i18n';
import FilterSearchContainer from '../../../binders/components/emotion/Filters/FilterSearchContainer';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import triggerDirtyFieldsToast from '../../../../components/Forms/DirtyFieldsToast';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { Button, Card, ToggleView } from '@ourliving/ourliving-ui';
import GenericTableDnD from '../../../binders/components/GenericTableDnD';
import useDragEndDnd from '../../../../hooks/useDragEndDnd';
import { Icon } from '../../../../components/Icon/Icon';
import {
    ContentInformationQueryKeys,
    useListContentOrgInformationItems,
    useListDraftContentOrgInformationItems,
} from '../../../project-templates/hooks/ContentInformation/contentInformationQueries';
import useHandleSearch from '../../../project-templates/hooks/useHandleSearch';
import { useUpdateContentOrgInfoSort } from '../../../project-templates/hooks/ContentInformation/useUpdateContentInfoSort';
import { useLocalStorage } from 'react-use';
import { RawHtml } from '../../../../components/Layout/RawHtml';
import useGetSignedUrlById from '../../../../lib/hooks/useGetSignedUrlById';

export type FiltersSpaces = {
    space_type: { value: string; label: string }[];
};

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

type ContentInformationItem = NonNullable<Awaited<ReturnType<typeof useListContentOrgInformationItems>>['data']>[0];

const columnHelper = createColumnHelper<ContentInformationItem>();

const ContentDraftInformationList = ({
    handleRemoveContentInfo,
    handleSetNumberOfItems,
}: {
    handleRemoveContentInfo: (params: { informationId: number }) => void;
    handleSetNumberOfItems: (val: number | null) => void;
}) => {
    return (
        <ContentInformation
            handleRemoveContentInfo={handleRemoveContentInfo}
            handleSetNumberOfItems={handleSetNumberOfItems}
        />
    );
};

const NewsCard = ({
    imageId,
    id,
    title,
    description,
    onClick,
}: {
    imageId?: string;
    id: number;
    title?: string | null;
    description?: string | null;
    onClick: () => void;
}) => {
    const url = useGetSignedUrlById(imageId, '800x0');

    return (
        <Card key={id} onClick={onClick}>
            <Card.Image src={url?.data} />
            {title && (
                <Card.Title>
                    <RawHtml content={title} />
                </Card.Title>
            )}

            {description && (
                <Card.Description>
                    <RawHtml content={description} />
                </Card.Description>
            )}
        </Card>
    );
};

const ContentInformation = ({
    handleRemoveContentInfo,
    handleSetNumberOfItems,
}: {
    handleRemoveContentInfo: (params: { informationId: number }) => void;
    handleSetNumberOfItems: (val: number | null) => void;
}) => {
    const navigate = useNavigate();
    const { handleSearch, searchField } = useHandleSearch();

    const [sorting, setSorting] = useState<SortingState>([]);

    const [isCardView, setIsCardView] = useLocalStorage<'list' | 'card'>('newsView', 'list');

    const { data: contentInfoItems } = useListDraftContentOrgInformationItems({
        search: searchField,
    });

    const handleClickRow = useCallback((row: RowType<ContentInformationItem>) => {
        navigate(`${row.original.id}`);
    }, []);

    const handleClickCard = (cardId: number) => {
        navigate(`${cardId}`);
    };

    useEffect(() => {
        handleSetNumberOfItems(contentInfoItems ? contentInfoItems.length : null);
    }, [contentInfoItems]);

    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.title, {
                id: 'title',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('title')}</Flex>,
                minSize: 110,
                size: 150,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.display({
                id: 'remove_content_info',
                cell: (info) => (
                    <CellContainer style={{ width: '100%', justifyContent: 'end' }}>
                        <Data
                            onClick={(e) => {
                                e.stopPropagation();
                                return triggerDirtyFieldsToast({
                                    title: `${L('confirmation_content_info_delete')} ${info.row.original.title}`,
                                    continueButtonText: L('yes'),
                                    continueFn: () => {
                                        handleRemoveContentInfo({
                                            informationId: info.row.original.id,
                                        });
                                    },
                                    id: info.row.original.id.toString(),
                                });
                            }}
                        >
                            <Icon.TrashCan style={{ alignSelf: 'center' }} />
                        </Data>
                    </CellContainer>
                ),
                minSize: 50,
                size: 50,
                enableResizing: false,
                meta: {
                    styles: {
                        flexShrink: 0,
                    },
                },
            }),
        ],
        [],
    );

    const items = useMemo(() => contentInfoItems?.map((item) => item.id), [contentInfoItems]);

    const table = useReactTable({
        data: contentInfoItems ?? [],
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        columnResizeMode: 'onChange',
        enableColumnResizing: true,
    });

    const { mutate: updateSort } = useUpdateContentOrgInfoSort();

    const isFiltering = !!(sorting.length > 0 || searchField);

    const handleDragEnd = useDragEndDnd({
        queryKey: ContentInformationQueryKeys.drafts(),
        updateSortOrder: (sort) =>
            updateSort({
                sort: sort,
            }),
        reverseArray: true,
        isFiltering,
    });

    return (
        <>
            <FilterSearchContainer>
                <div style={{ marginLeft: 'auto', paddingBottom: '2rem', display: 'flex', gap: '1rem' }}>
                    <GlobalFilter
                        style={{ height: '2.2rem' }}
                        filter={searchField ?? ''}
                        setFilter={handleSearch}
                        placeholder={L('search')}
                    />
                    <ToggleView style={{ width: '2rem' }} view={isCardView || 'list'} setView={setIsCardView} />
                </div>
            </FilterSearchContainer>
            {isCardView === 'card' ? (
                <Card.Grid>
                    {contentInfoItems?.map((data) => {
                        const hasSpaceInFilename =
                            data?.cover_image_data?.original?.metadata?.filename?.includes(' ') ?? false;
                        return (
                            <NewsCard
                                key={data?.id}
                                id={data.id}
                                title={data.title}
                                description={data.description}
                                imageId={hasSpaceInFilename ? undefined : data?.cover_image_data?.original?.id}
                                onClick={() => handleClickCard(data?.id)}
                            />
                        );
                    })}
                </Card.Grid>
            ) : (
                <GenericTableDnD
                    table={table}
                    rowClickHandler={handleClickRow}
                    items={items}
                    handleDragEnd={handleDragEnd}
                />
            )}
            {contentInfoItems?.length === 0 &&
                (!searchField ? (
                    <EmptyState>
                        <EmptyState.Title>{L('no_content_information')}</EmptyState.Title>
                        <EmptyState.Description>
                            <p>{L('no_content_information_description')}</p>
                            <p>{L('click_button_below')}</p>
                            <Link style={{ marginLeft: 'auto' }} to={'new'}>
                                <Button>{L('new_content_information')}</Button>
                            </Link>
                        </EmptyState.Description>
                    </EmptyState>
                ) : (
                    <EmptyState>
                        <EmptyState.Title>{L('no_content_information_found')}</EmptyState.Title>
                        <EmptyState.Description>
                            <p>{L('no_content_information_found_description')}</p>
                            <p>{L('please_try_something_else')}</p>
                        </EmptyState.Description>
                    </EmptyState>
                ))}
        </>
    );
};

export default ContentDraftInformationList;
