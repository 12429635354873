import React from 'react';
import { Route } from 'react-router-dom';
import { AdministrationSettingsPage } from './Routes';

export const routes = [<Route key={0} path="/administration-settings" component={AdministrationSettingsPage} />];

export const navigation = [
    {
        name: 'admin',
        link: '/administration-settings',
        match: '/administration-settings',
        icon: 'admin',
    },
];
