import { useQueryClient, useMutation } from 'react-query';
import ServicePartnerApi from '@app/api/public/ServicePartnersApi';
import { servicePartnerDocumentsListQueryKey } from './useGetServicePartnerDocumentsById';

type Variables = { documentId: string; queryId: string };

export const deleteDocument = async (documentId: number, servicePartnerId: number) => {
    return await ServicePartnerApi.deleteDocumentFromServicePartner(documentId, servicePartnerId);
};

const useDeleteServicePartnerDocument = () => {
    const queryClient = useQueryClient();
    return useMutation((variables: Variables) => deleteDocument(+variables.documentId, +variables.queryId), {
        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries([servicePartnerDocumentsListQueryKey]);
        }
    });
};

export default useDeleteServicePartnerDocument;
