import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import TodoList from '../../../binders/pages/TodoPages/TodoList';

const TodoTemplateListPage = () => {
    const { id: propertyId } = useParams<{ id: string }>();
    return propertyId ? <TodoList propertyId={+propertyId} /> : null;
};

export default TodoTemplateListPage;
