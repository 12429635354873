import React, { useState } from 'react';
import styled from '@emotion/styled';
import Label from '../../../../components/Forms/Label';
import Input from '../../../../components/Forms/Input';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import useSaveSettingByType from '../hooks/useSaveSettingByType';
import BoxColorPicker from '../../../../components/BoxColorPicker/BoxColorPicker';
import SettingItem from './SettingItem';
import { TicketSettingType } from '@app/api/models/Tickets';
import { useGetSettingsByType } from '../../../tickets/hooks/ticketQueries';

const Container = styled.div({
    maxWidth: '50rem'
});

const Form = styled.form({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

const ItemContainer = styled.div({
    display: 'grid',
    gap: '1rem'
});

const GridWrapper = styled.div({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'min-content auto min-content',
    gap: '1rem'
});

const StyledLabel = styled(Label)({
    width: '100%'
});

export default function SettingsByType({ type }: { type: TicketSettingType }) {
    const [name, setName] = useState('');
    const [color, setColor] = useState('');
    const { data: settingsList } = useGetSettingsByType(type);
    const { mutate: save } = useSaveSettingByType();

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        save({
            setting: {
                name,
                color_code: color
            },
            type
        });
        setName('');
    };
    return (
        <Container>
            <Form onSubmit={handleSave}>
                <StyledLabel title={L(`create_new_${type}`)}>
                    <GridWrapper>
                        <BoxColorPicker getValue={value => setColor(value)} />
                        <Input
                            required={true}
                            onChange={e => setName(e.target.value)}
                            value={name}
                            placeholder={L(`label_${type}`)}
                        />
                        <Button type="submit">{L(`create`)}</Button>
                    </GridWrapper>
                </StyledLabel>
            </Form>
            <Label title={L(`all_${type}`)} />
            <ItemContainer>
                {settingsList?.map(item => {
                    return <SettingItem key={item.id} item={item} type={type} />;
                })}
            </ItemContainer>
        </Container>
    );
}
