import * as React from 'react';
import { useObserver } from 'mobx-react';
import { ConfirmAccountPage } from './Components/ConfirmAccountPage';
import { PasswordResetPage } from './Components/PasswordResetPage';
import { ConfirmResetStore } from './ConfirmResetStore';

export const ConfirmResetPage = () => {
    return useObserver(() => {
        return ConfirmResetStore.state === 'password_changed' ? <ConfirmAccountPage /> : <PasswordResetPage />;
    });
};
