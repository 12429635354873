import React from 'react';
import { useParams } from 'react-router-dom-v5-compat';
import PackageProductsComponentCopyFunction from '../components/PackageProductComponentCopyFunction';

const PackageProductsPageCopyFunction = () => {
    const { productId, id } = useParams<{ productId?: string; id?: string }>();
    if (productId) return <PackageProductsComponentCopyFunction productId={productId} />;
    if (id) return <PackageProductsComponentCopyFunction productId={id} />;
    return null;
};

export default PackageProductsPageCopyFunction;
