import React from 'react';
import { AccountMailTemplateStore } from './AccountMailTemplateStore';
import { Loading } from '../../../components/Loading/Loading';
import { Content } from '../../../components/Layout/Content';
import { useObserver } from 'mobx-react';
import { AccountMailTemplateList } from './AccountMailTemplatesList';
import { usePromise } from '../../../lib/react';

export const AccountMailTemplatePage = () => {
    const store = AccountMailTemplateStore;
    const [ready] = usePromise(async () => {
        await store.load();
    }, []);

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }

        return <AccountMailTemplateList />;
    });
};
