import React, { FC } from 'react';
import styled from '@emotion/styled';
import TicketNotice from './notices/TicketNotice';
import TicketAssigned from './notices/TicketAssigned';
import TicketStatus from './notices/TicketStatus';
import { NotificationType } from '@app/api/models/NotificationModel';

const StyledNotificationList = styled.ul({ paddingInlineStart: 0 });

type Props = {
    notifications: NotificationType[];
    isCollapsed: boolean;
};

// lägg till Rätt Notification components här
const componentMapper = {
    new_ticket: TicketNotice,
    ticket_assigned: TicketAssigned,
    ticket_status: TicketStatus
};

const NotificationList: FC<Props> = ({ notifications, isCollapsed, ...rest }) => {
    return (
        <StyledNotificationList {...rest}>
            {notifications.map(notification => {
                const NotificationComponent = componentMapper[notification.notifiable_type];
                if (!NotificationComponent) {
                    console.log('no notification component for ->', notification);
                    return null;
                }
                return (
                    <li key={notification.id}>
                        <NotificationComponent {...notification} isCollapsed={isCollapsed} />
                    </li>
                );
            })}
        </StyledNotificationList>
    );
};

export default NotificationList;
