import { createValidator, required } from '../../lib/validation';
import { observable } from 'mobx';
import { ContentCalendarItem, CalendarParticipant } from '@app/api/models/ProjectContentCalendar';
import ContentCalendarApi from '@app/api/public/ProjectContentCalendarApi';
import { redirect } from '../../lib/routing';
import DocumentsApi from '@app/api/public/DocumentsApi';

const contentCalendarValidator = createValidator({
    title: [required()],
    description: [required()],
    event_date: [required()]
});

class ContentCalendarStoreClass {
    posted = false;
    @observable errors: any = {};
    @observable model: Partial<ContentCalendarItem> = {};
    @observable participants: CalendarParticipant[] = [];

    @observable participantCount: number;
    @observable participantAmount: number;

    @observable loadTime: string;
    @observable loadDate: Date;

    @observable time: string;
    @observable date: Date;

    @observable imageUrl: string;
    @observable imagePreview: string;

    @observable searchFilter: string[] = [];

    async load(id: string | undefined) {
        this.posted = false;
        if (!id) {
            this.model = {};
            this.imageUrl = '';
        } else {
            this.model = (await ContentCalendarApi.get(id)) || {};
            await this.listParticipants(id);
        }
        if (this.model.cover_image_data) {
            this.imageUrl = await DocumentsApi.getSignedUrl(this.model.cover_image_data.id);
        }

        if (this.model.event_date) {
            const dateTime = new Date(this.model.event_date);

            const hours = dateTime.getHours() + 2;
            const minutes = dateTime.getMinutes();

            const time = `${hours}:${minutes}`;

            this.loadDate = new Date(this.model.event_date);

            this.loadTime = time;
        }
    }

    async listParticipants(calender_id: any) {
        this.participants = await ContentCalendarApi.listParticipants(calender_id);

        this.participantCount = this.participants.reduce((sum, participant) => sum + participant.count, 0);

        this.participantAmount = this.participants.length;
    }

    setValue = (value: any, name: string) => {
        this.model[name] = value;
        if (this.posted) {
            const validation = contentCalendarValidator.test(this.model);
            this.errors = validation.errors;
        }
    };

    remove = async (id: string) => {
        await ContentCalendarApi.remove(id);
        this.load(id);
        redirect(`/calendar/`);
    };

    parseDateAndTime(date: string, time?: string) {
        let t: any = time;
        t = t.split(' ');
        t = t[0].split(':');
        const milliSeconds = (parseInt(t[0]) * 60 + parseInt(t[1])) * 60000;

        const totalMilliSeconds = Date.parse(date) + milliSeconds;

        const r = new Date(totalMilliSeconds);

        console.log(r);

        return r;
    }

    async save() {
        this.posted = true;

        const validation = contentCalendarValidator.test(this.model);
        this.errors = validation.errors;
        if (!validation.ok) return;
        if (this.model.event_date)
            this.model.event_date = this.parseDateAndTime(this.model.event_date, this.model.event_time);

        const newItem = await ContentCalendarApi.save(this.model);

        if (newItem && newItem.id !== this.model.id) {
            return redirect(`/calendar/${newItem.id}`);
        }

        this.model = newItem as Partial<ContentCalendarItem>;
    }
}
export const ContentCalendarStore = new ContentCalendarStoreClass();
