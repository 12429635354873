import { useInfiniteQuery } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { SortingState } from '@tanstack/react-table';

export const getAdminKeys = {
    all: ['admins'] as const,
    lists: () => [...getAdminKeys.all, 'list'] as const,
    infiniteList: (params: { sorting: SortingState; search: string }) => [...getAdminKeys.lists(), 'infinite', params],
};

export function useGetProjectAdmins({
    sorting,
    search,
    projectId,
}: {
    sorting: SortingState;
    search: string;
    projectId: number;
}) {
    return useInfiniteQuery(
        [...getAdminKeys.infiniteList({ sorting, search })],
        async ({ pageParam = 1 }) =>
            trpc.MembersApi.listAdminsByProject.query({ sorting, search, page: pageParam, projectId }),
        {
            keepPreviousData: true,
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
        },
    );
}
