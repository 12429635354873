import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Button } from '@ourliving/ourliving-ui';
import Input from '../../../components/Forms/Input';
import { L } from '../../../lib/i18n';
import ContentContainer from './emotion/ContentContainer';
import TableMenuContainer from './emotion/TableMenuContainer';
import StyledParagraph from './emotion/StyledParagraph';
import RightCornerMenu from './emotion/RightCornerMenu';
import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { Table } from '@tanstack/react-table';
import { getProductsKeys } from '../hooks/productQueries';
import useDeleteProducts from '../hooks/useDeleteProducts';

type PropsInterface<T extends Record<string, unknown>> = {
    tableData: Table<T>;
    selectedCount: number;
    previewDeletion: number[];
    changedPrices: boolean;
    setPreviewDeletion: Dispatch<SetStateAction<number[]>>;
    onCancelPrice: () => void;
    previewPrice: (number: number, isProcentual: boolean) => void;
    addToBasket: () => void;
    confirmPrice: () => void;
    resetRowSelection: () => void;
};

const TableMenu = <T extends Record<string, unknown>>({
    tableData,
    selectedCount,
    previewDeletion,
    changedPrices,
    setPreviewDeletion,
    onCancelPrice,
    previewPrice,
    addToBasket,
    confirmPrice,
    resetRowSelection,
}: PropsInterface<T>) => {
    const [confirmedDelete, setConfirmedDelete] = React.useState(false);

    const [numberInput, setNumberInput] = React.useState('');

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberInput(event.target.value);
    };

    const { mutate: deleteProducts } = useDeleteProducts();

    const queryClient = useQueryClient();

    const deleteRows = async () => {
        const stagedItemsDeleteIds = previewDeletion;
        if (stagedItemsDeleteIds.length > 10) {
            setPreviewDeletion([]);
            toast.error(
                `${L('failed_to_delete')} ${stagedItemsDeleteIds.length} ${L('products')}. ${L('exceeds_maximum')}`,
            );
            return;
        }
        try {
            deleteProducts(stagedItemsDeleteIds, {
                onSuccess: () => {
                    queryClient.invalidateQueries(getProductsKeys.all);
                    setPreviewDeletion([]);
                    resetRowSelection();
                    if (stagedItemsDeleteIds.length === 1) {
                        toast.success(
                            `${L('successful_delete')} ${stagedItemsDeleteIds.length} ${L(
                                'product',
                            ).toLocaleLowerCase()}`,
                        );
                        return;
                    }
                    toast.success(
                        `${L('successful_delete')} ${stagedItemsDeleteIds.length} ${L('products').toLocaleLowerCase()}`,
                    );
                },
            });
        } catch (err) {
            toast.error(L('pg_unkown'));
        }

        setConfirmedDelete(false);
    };

    const previewDelete = () => {
        const ids = tableData.getSelectedRowModel().flatRows.map((row) => row.original.id) as number[];
        setPreviewDeletion(ids);
        setConfirmedDelete(true);
    };

    const cancelDelete = () => {
        setPreviewDeletion([]);
        setConfirmedDelete(false);
    };

    const cancelPrice = () => {
        onCancelPrice();
    };

    const preview = () => {
        if (numberInput) {
            //Check if input has a %
            const containsProcent = numberInput.match(/[%]+/);
            let filteredInput = Number(numberInput.replace(/[^\d.,-]/g, ''));
            console.log(containsProcent, filteredInput);
            if (!!containsProcent) {
                filteredInput = filteredInput / 100;
            }

            previewPrice(filteredInput, !!containsProcent);
        }
    };

    const updateBasket = () => {
        addToBasket();
    };

    const changePrice = () => {
        confirmPrice();
    };

    useEffect(() => {
        if (previewDeletion.length <= 0) {
            setConfirmedDelete(false);
        }
    }, [previewDeletion.length]);

    const hasAdjusted = selectedCount > 0 || previewDeletion.length > 0 || changedPrices;
    return (
        <TableMenuContainer>
            <StyledParagraph style={{ padding: '1rem 0' }}>
                {selectedCount > 0
                    ? selectedCount + 'st ' + (selectedCount > 1 ? L('chosen_products') : L('chosen_product'))
                    : L('no_chosen_products')}
            </StyledParagraph>
            {hasAdjusted && (
                <RightCornerMenu>
                    {!confirmedDelete && (
                        <ContentContainer>
                            <Input
                                type={'text'}
                                placeholder={L('enter_number_or_procent')}
                                value={String(numberInput)}
                                onChange={onChange}
                                disabled={selectedCount < 1}
                            />
                            <div>
                                <Button variant={'primary'} onClick={preview} disabled={numberInput.length < 1}>
                                    {L('set_price')}
                                </Button>
                            </div>
                            {changedPrices && (
                                <>
                                    <div>
                                        <Button variant={'primary'} onClick={changePrice}>
                                            {L('confirm')}
                                        </Button>
                                    </div>
                                    <div>
                                        <Button variant={'secondary'} onClick={cancelPrice}>
                                            {L('cancel')}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </ContentContainer>
                    )}

                    {!changedPrices && (
                        <ContentContainer>
                            {confirmedDelete ? (
                                <>
                                    <Button variant={'danger'} onClick={deleteRows}>
                                        {L('delete')} {previewDeletion.length} {L('products')}
                                    </Button>
                                    <Button variant={'secondary'} onClick={cancelDelete}>
                                        {L('cancel')}
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={() => previewDelete()}>{L('delete')}</Button>
                            )}
                        </ContentContainer>
                    )}

                    <ContentContainer>
                        <Button variant={'primary'} onClick={updateBasket}>
                            {L('add_to_basket')}
                        </Button>
                    </ContentContainer>
                </RightCornerMenu>
            )}
        </TableMenuContainer>
    );
};

export default TableMenu;
