import React, { useCallback, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PropertyContact } from '@app/trpc-api/models/PropertyContacts';
import {
    createColumnHelper,
    getCoreRowModel,
    useReactTable,
    SortingState,
    getSortedRowModel,
    Row,
} from '@tanstack/react-table';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../../lib/i18n';
import EmptyState from '../../../../components/EmptyStateCommunity/EmptyStateCommunity';
import { useGetInfinitePropertyContacts } from '../../hooks/propertyHooks/propertyContactsQueries';
import { useNavigate } from 'react-router-dom-v5-compat';
import GenericTableVirtualized from '../GenericTableVirtualizedInfinite';
import FilterSearchContainer from '../emotion/Filters/FilterSearchContainer';
import GlobalFilter from '../../../../components/Table/FilterComponents/GlobalFilterTable';
import { Loading } from '../../../../components/Loading/Loading';

const TopAreaContainer = styled.div({
    display: 'flex',
    minHeight: '35px',
    alignItems: 'center',
});

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const columnHelper = createColumnHelper<PropertyContact>();

type Props = {
    propertyId: number;
    handleToggleModal: () => void;
};

const ContactsList = ({ propertyId, handleToggleModal }: Props) => {
    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('contact_list_name')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.company, {
                id: 'company',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('contact_company')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.title, {
                id: 'title',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('contact_title')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.phone_number, {
                id: 'phone_number',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('contact_number')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.email, {
                id: 'email',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.getValue()}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('contact_mail')}</Flex>,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
        ],
        [],
    );

    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);

    const searchField = useMemo(() => searchParams.get('searchField') ?? '', [searchParams]);

    const handleSearch = (value: string) => {
        searchParams.set('searchField', value);
        navigate({
            search: searchParams.toString(),
        });
    };

    const [sorting, setSorting] = useState<SortingState>([]);

    const { fetchNextPage, hasNextPage, isFetching, data, status } = useGetInfinitePropertyContacts({
        property_id: propertyId,
        keepPreviousData: true,
        search: searchField ?? '',
        sorting,
    });

    const flatData = useMemo(() => data?.pages?.flatMap((page) => page.items) ?? [], [data]);

    const totalDBRowCount = useMemo(() => Number(data?.pages?.[0]?.totalItems ?? 0), [data]);
    const totalFetched = useMemo(() => flatData.length, [flatData]);

    const table = useReactTable({
        data: flatData,
        columns,
        manualSorting: true,
        state: {
            sorting,
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
    });

    const handleClickRow = useCallback((row: Row<PropertyContact>) => {
        navigate(`${row.original.id}`);
    }, []);

    if (status === 'loading') {
        return <Loading />;
    }

    return (
        <CardWithDivider
            topArea={
                <TopAreaContainer>
                    <CardTitle>
                        {L('contacts')} {data ? `(${data?.pages[0].totalItems})` : ''}
                    </CardTitle>
                    <Button style={{ marginLeft: 'auto' }} onClick={() => handleToggleModal()}>
                        {L('new_contact')}
                    </Button>
                </TopAreaContainer>
            }
            mainArea={
                <>
                    <FilterSearchContainer style={{ paddingBottom: '22px' }}>
                        <div style={{ marginLeft: 'auto' }}>
                            <GlobalFilter
                                filter={searchField ?? ''}
                                setFilter={handleSearch}
                                placeholder={L('search')}
                            />
                        </div>
                    </FilterSearchContainer>
                    <GenericTableVirtualized
                        table={table}
                        handleFetchNextPage={() => fetchNextPage()}
                        hasNextPage={hasNextPage}
                        isFetching={isFetching}
                        totalDBRowCount={totalDBRowCount}
                        totalFetched={totalFetched}
                        estimateSize={50}
                        rowClickHandler={handleClickRow}
                    />
                    {flatData?.length === 0 && data && (
                        <EmptyState>
                            <EmptyState.Title>{L('no_contacts')}</EmptyState.Title>
                            <EmptyState.Description>
                                <p>{L('no_contacts_property_description')}</p>
                                <p>{L('click_button_below')}</p>
                                <Button onClick={() => handleToggleModal()}> {L('new_contact')}</Button>
                            </EmptyState.Description>
                        </EmptyState>
                    )}
                </>
            }
        />
    );
};

export default ContactsList;
