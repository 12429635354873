import { useMutation, useQueryClient } from 'react-query';
import { trpc } from '../../../../lib/trpc';
import { documentGroupQueryKeys } from './documentGroupQueries';
import toast from 'react-hot-toast';
import { L } from '../../../../lib/i18n';
import { TRPCClientError } from '@trpc/client';

export default function useRemoveProjectDocumentGroup() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { id: number; projectId: number }) => {
            return trpc.ContentDocumentsApi.removeProjectDocumentGroup.mutate(options);
        },
        {
            onSuccess: (_data, params) => {
                toast.success(L('removed_document_group'), { id: params.id.toString() });
            },
            onError: (err, params) => {
                if (err instanceof TRPCClientError) {
                    if (err.message === 'CONFLICT') {
                        toast.error(L('doc_group_in_use'), { id: params.id.toString() });
                        return;
                    }

                    toast.error(L('an_error_occurred'), { id: params.id.toString() });
                }
            },
            onSettled: async (_res, _err, data) => {
                queryClient.invalidateQueries(documentGroupQueryKeys.list({ templateId: data.projectId }));
            },
        },
    );
}
