import * as React from 'react';
import styled from '@emotion/styled';
import { style } from '../Shared/Style';
import { variant } from 'styled-system';

interface NotificationCountProps {
    children?: any;
    size?: string;
    color?: string;
}
const Container = styled.span<NotificationCountProps>(
    {
        display: 'inline-block',
        textAlign: 'center',
        verticalAlign: 'middle',
        borderRadius: '100%',
        background: style.colors.notofication,
        color: style.colors.white,
        fontSize: style.fontSize.centi,
        width: '18px',

        height: '18px',
    },
    variant({
        prop: 'size',
        variants: {
            normal: {
                width: '18px',
                height: '18px',
            },
            small: {
                width: '9px',
                height: '9px',
            },
            disabled: {
                background: style.colors.black50,
            },
        },
    }),
);

export function NotificationCount(props: NotificationCountProps) {
    if (!props.children) return null;
    if (props.children === '0') return <Container size={'disabled'}>{props.children}</Container>;

    const pill =
        props.size === 'small' ? (
            <Container size={props.size} />
        ) : (
            <Container size={props.size}>{props.children}</Container>
        );

    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'end',
                paddingRight: '1rem',
            }}
        >
            {pill}
        </div>
    );
}

Container.defaultProps = {
    size: 'normal',
};
