import React from 'react';
import { style } from '../Shared/Style';
import styled from '@emotion/styled';

interface Props {
    title: any;
    children?: any;
    error?: string;
    width?: string;
    height?: string;
}

const Field = styled.label((p: { width?: string; height?: string }) => ({
    margiBottom: style.margin.ml,
    display: 'block',
    width: p.width ? p.width : '100%',
    height: p.height ? p.height : 'auto'
}));

const Title = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    marginBottom: style.margin.s,
    marginTop: style.margin.m,
    display: 'flex',
    justifyContent: 'space-between'
});

const Description = styled.div((p: { height?: string }) => ({
    height: p.height || 'auto'
}));

const Errors = styled.span({
    fontSize: style.fontSize.micro,
    color: 'red'
});

export function FormField(p: Props) {
    const errorText = p.error;
    const hasError = errorText !== undefined;

    return (
        <Field height={p.height} width={p.width}>
            <Title>
                <span>{p.title}</span>
                {hasError && <Errors>{errorText}</Errors>}
            </Title>
            <Description height={p.height}>{p.children}</Description>
        </Field>
    );
}
