import { useMutation, useQueryClient } from 'react-query';
import { getProfessionKeys } from './professionQueries';
import { trpc } from '../../../../lib/trpc';

const useDeleteProfession = () => {
    const client = useQueryClient();
    return useMutation(
        async ({ id }: { id: number }) => {
            return trpc.ProfessionApi.deleteProfession.query(id);
        },
        {
            onSettled: () => {
                client.invalidateQueries(getProfessionKeys.lists());
            }
        }
    );
};

export default useDeleteProfession;
