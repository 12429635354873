import React from 'react';
import { L } from '../../../../lib/i18n';
import { Button, Label, TextArea } from '@ourliving/ourliving-ui';
import { useGetTodoEntitySubtype, useGetTodoEntityType, useGetTodoRecurrency } from './hooks/todoQueries';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import ReactSelect from '../../../../components/Dropdown/ReactSelect';
import ErrorAndInputWrapper from '../../../../components/Forms/ErrorAndInputWrapper';
import { Link } from 'react-router-dom-v5-compat';
import styled from '@emotion/styled';
import { formatInputDateTimeDayjs } from '@app/shared/lib/formatting';
import Input from '../../../../components/Forms/Input';
import dayjs from 'dayjs';

const Form = styled.form({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '2rem 1rem',
});

const EditTodoSchema = z.object({
    name: z.string().min(1, 'error_required'),
    description: z.string().optional(),
    template_for_type: z.string().optional(),
    template_for_subtype: z.string().optional(),
    tips: z.string().optional(),
    recurrency: z.number().optional(),
    due_at: z.string().optional().or(z.literal('')),
});

type FormValues = {
    name: string;
    description?: string;
    template_for_type?: string;
    template_for_subtype?: string;
    tips?: string;
    recurrency?: number;
    due_at?: string;
};

type TodoFormProps = {
    formValues: FormValues;
    onSubmit: (params: FormValues) => void;
    id?: number;
};

const TodoForm = (props: TodoFormProps) => {
    const templateTypeValues = useGetTodoEntityType();
    const recurrencyValues = useGetTodoRecurrency();

    const { due_at, ...formValues } = props.formValues;
    const { register, handleSubmit, control, watch, setValue, formState } = useForm({
        resolver: zodResolver(EditTodoSchema),
        defaultValues: {
            ...formValues,
            due_at: formatInputDateTimeDayjs(due_at),
        },
    });

    const templateSubtypeValues = useGetTodoEntitySubtype({
        entityType: watch('template_for_type'),
    });

    return (
        <Form
            onSubmit={handleSubmit(
                (success) => {
                    props.onSubmit({
                        ...success,
                        due_at: success.due_at ? dayjs(success.due_at).toISOString() : '',
                    });
                },
                (errors) => {
                    console.log('errors', errors);
                },
            )}
        >
            <div>
                <ErrorAndInputWrapper errorMsg={L(formState.errors.name?.message)}>
                    <Label htmlFor="name">{L('name')}</Label>
                    <Input style={{ width: '100%' }} id={'name'} {...register('name')} />
                </ErrorAndInputWrapper>
            </div>
            <div
                style={{
                    gridColumn: 'span 2',
                }}
            >
                <Label htmlFor="description">{L('description')}</Label>
                <TextArea id={'description'} {...register('description')} />
            </div>
            <div>
                <Label htmlFor="template_for_type">{L('template_type')}</Label>
                <Controller
                    control={control}
                    name="template_for_type"
                    render={({ field }) => {
                        const options = templateTypeValues.data?.map((option) => ({
                            value: option.name,
                            label: L(option.name),
                        }));
                        return (
                            <ReactSelect
                                id="template_for_type"
                                placeholder={L('template_type_placeholder')}
                                value={options?.find((option) => option.value === field.value) || null}
                                options={options}
                                onChange={(e) => {
                                    setValue('template_for_subtype', '');
                                    field.onChange(e?.value);
                                }}
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Label htmlFor="template_for_subtype">{L('template_for_subtype')}</Label>
                <Controller
                    control={control}
                    name="template_for_subtype"
                    render={({ field }) => {
                        const options = templateSubtypeValues.data?.map((option) => ({
                            value: option.name,
                            label: L(option.name),
                        }));
                        return (
                            <ReactSelect
                                id="template_for_subtype"
                                placeholder={L('template_subtype_placeholder')}
                                isDisabled={!templateSubtypeValues.data?.length}
                                value={options?.find((option) => option.value === field.value) || null}
                                isClearable
                                options={options}
                                onChange={(e) => {
                                    field.onChange(e?.value);
                                }}
                            />
                        );
                    }}
                />
            </div>
            <div
                style={{
                    gridColumn: 'span 2',
                }}
            >
                <Label htmlFor="tips">{L('tips')}</Label>
                <TextArea id={'tips'} {...register('tips')} />
            </div>
            <div>
                <Label htmlFor="recurrency">{L('recurrency')}</Label>
                <Controller
                    control={control}
                    name="recurrency"
                    render={({ field }) => {
                        const options = recurrencyValues.data?.map((option) => ({
                            value: option.id,
                            label: L(option.name),
                        }));
                        return (
                            <ReactSelect
                                id="recurrency"
                                placeholder={L('recurrency')}
                                isDisabled={!recurrencyValues.data?.length}
                                value={options?.find((option) => option.value === field.value) || null}
                                isClearable
                                options={options}
                                onChange={(e) => {
                                    field.onChange(e?.value);
                                }}
                            />
                        );
                    }}
                />
            </div>
            <div>
                <Label htmlFor="due_at">{L('due_at')}</Label>
                <Input type="datetime-local" id={'due_at'} {...register('due_at')} />
            </div>
            <div
                style={{
                    gridColumn: 'span 2',
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'flex-end',
                }}
            >
                <Link to="..">
                    <Button type="button" variant="tertiary">
                        {L('cancel')}
                    </Button>
                </Link>

                <Button type="submit">{L('save')}</Button>
            </div>
        </Form>
    );
};

export default TodoForm;
