import React from 'react';
import { Column, useFlexLayout, useGlobalFilter, useRowSelect, useSortBy, useTable } from 'react-table';
import { Checkbox } from '../../../components/Checkbox/Checkbox';

export type Props<T extends Record<string, unknown>> = {
    columns: Column<T>[];
    data: T[];
};

const useTableCheckbox = <T extends Record<string, unknown>>({ columns, data }: Props<T>) => {
    return useTable<T>(
        {
            columns,
            data,
            globalFilter: ''
        },
        useGlobalFilter,
        useSortBy,
        useFlexLayout,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => {
                return [
                    {
                        id: 'selection',
                        Header: ({ getToggleAllRowsSelectedProps }) => (
                            <Checkbox {...getToggleAllRowsSelectedProps()} />
                        ),
                        Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
                        disableGlobalFilter: true
                    },
                    ...columns
                ];
            });
        }
    );
};

export default useTableCheckbox;
