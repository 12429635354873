import { useMutation, useQueryClient } from 'react-query';
import { AddProduct } from '../../binders/schemas';
import { trpc } from '../../../lib/trpc';
import { getProductsKeys } from '../../binders/hooks/productQueries';

export default function useUpdateProductOnPropertyTemplate() {
    const queryClient = useQueryClient();
    return useMutation(
        (options: { inventoryMaterial: AddProduct; productId: number }) => {
            const dataToSave = { ...options.inventoryMaterial, entity_type: 1 };
            return trpc.TemplatesApi.updateInventoryMaterial.query({ data: dataToSave, entityId: options.productId });
        },
        {
            onSettled: async () => {
                queryClient.invalidateQueries(getProductsKeys.all);
            },
        },
    );
}
