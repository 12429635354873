import React, { useEffect, useState } from 'react';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { Alert } from '@app/api/public/AlertsApi';
import useSaveAlert from '../hooks/useSaveAlert';
import AlertDetailPageStatus from './components/AlertDetailPageStatus';
import { useParams } from 'react-router';
import { useGetAlert } from '../hooks/useAlertQueries';
import Label from '../../../components/Forms/Label';
import Input from '../../../components/Forms/Input';
import { TextArea } from '@ourliving/ourliving-ui';

const AlertThread = styled.div({
    background: style.colors.white,
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    padding: style.margin.l,
});

const Header = styled.div({
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: style.margin.ml,
});

const AlertContainer = styled.div({
    paddingRight: style.margin.ml,
    borderRight: '1px solid #DFE0EB',
    flexGrow: 8,
    overflowY: 'auto',
    maxHeight: '90vh',
    '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
    },

    '&::-webkit-scrollbar-track-piece': {
        backgroundColor: style.colors.black30,
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:vertical': {
        height: '50px',
        backgroundColor: style.colors.primary,
        borderRadius: '100px',
    },
});

const Content = styled.div({
    display: 'flex',
});

const Buttons = styled.div({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: style.margin.l,
});

const AlertNumber = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.gamma,
    lineHeight: '22px',
    letterSpacing: '0.2px',
    color: style.colors.black,
});

export const AlertDetail = () => {
    const { id } = useParams<{ id: string }>();
    const { data: alert } = useGetAlert(+id);
    const { mutate: save } = useSaveAlert();
    const [statusFields, setStatusFields] = useState<
        Partial<
            Pick<Alert, 'project_id' | 'publish_from_date' | 'publish_to_date' | 'priority' | 'title' | 'description'>
        >
    >({});
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (!alert) return;
        setStatusFields({
            ...alert,
            publish_from_date: dayjs(alert.publish_from_date).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
            publish_to_date: dayjs(alert.publish_to_date).format('YYYY-MM-DD') || dayjs().format('YYYY-MM-DD'),
        });
    }, [alert]);

    const onChange = (evt: any) => {
        const { name } = evt.target;
        const { value } = evt.target;
        setStatusFields((prev) => ({ ...prev, [name]: value }));
    };

    const saveHandle = () => {
        if (
            !statusFields?.publish_from_date ||
            !statusFields?.publish_to_date ||
            !statusFields?.project_id ||
            !statusFields?.priority ||
            !statusFields.title ||
            !statusFields.description
        ) {
            return setIsError(true);
        }
        setIsError(false);
        save({
            alert: {
                ...alert,
                project_id: statusFields.project_id,
                publish_from_date: statusFields.publish_from_date,
                priority: statusFields.priority,
                publish_to_date: statusFields.publish_to_date,
                title: statusFields.title,
                description: statusFields.description,
            },
        });
    };

    return (
        <AlertThread>
            <Header>
                <AlertNumber>{statusFields?.title}</AlertNumber>
            </Header>
            <Content>
                <AlertContainer>
                    <>
                        <Label title={L('title')} error={isError && !statusFields.title ? 'Vänligen fyll i titel' : ''}>
                            <Input value={statusFields?.title || ''} name="title" type="text" onChange={onChange} />
                        </Label>
                        <Label
                            title={L('description')}
                            error={isError && !statusFields.description ? 'Vänligen fyll i titel' : ''}
                        >
                            <TextArea value={statusFields?.description || ''} name="description" onChange={onChange} />
                        </Label>
                    </>
                </AlertContainer>
                <AlertDetailPageStatus
                    isError={isError}
                    setStatusFields={setStatusFields}
                    statusFields={statusFields}
                />
            </Content>

            <Buttons>
                <Button onClick={saveHandle}>{L('save')}</Button>
            </Buttons>
        </AlertThread>
    );
};
