import React, { useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { createColumnHelper, getCoreRowModel, useReactTable, getSortedRowModel, Row } from '@tanstack/react-table';
import { L } from '../../../../lib/i18n';
import { useGetPropertiesByUser } from '../../hooks/propertyHooks/propertyQueries';
import MemberPropertyTable from '../../../../components/V8Tables/SmallerTable';
import { useNavigate } from 'react-router-dom-v5-compat';

const CellContainer = styled.div({
    display: 'grid',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '100%',
    fontSize: '0.75rem',
    gap: '0.2rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
});

const NumericCellContainer = styled(CellContainer)({
    justifyContent: 'end',
    padding: '0 5px 0 5px',
});

const Flex = styled.div({
    display: 'flex',
});

const Data = styled.p({
    margin: '0px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});

const Span = styled.span({
    textAlign: 'left',
    fontSize: '12px',
});

const StyledNameDiv = styled.div({
    textDecoration: 'none',
    color: 'inherit',
});

type Props = {
    userId: number;
};

type PropertiesList = NonNullable<Awaited<ReturnType<typeof useGetPropertiesByUser>>['data']>[0];

const columnHelper = createColumnHelper<PropertiesList>();

export default function MemberPropertyList({ userId }: Props) {
    const columns = useMemo(
        () => [
            columnHelper.accessor((row) => row.name, {
                id: 'name',
                cell: (info) => (
                    <CellContainer>
                        <StyledNameDiv>
                            <Span>{info.row.original.name}</Span>
                        </StyledNameDiv>
                    </CellContainer>
                ),
                header: () => <Flex>{L('member_property_name')}</Flex>,
                size: 50,
                meta: {
                    styles: {
                        flex: '2 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.address?.streetNumber, {
                id: 'apartment_number',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.address?.streetNumber}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('member_property_number')}</Flex>,
                size: 50,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.address?.street, {
                id: 'street',
                cell: (info) => (
                    <CellContainer>
                        <Data>{info.row.original.address?.street}</Data>
                    </CellContainer>
                ),
                header: () => <Flex>{L('member_property_street')}</Flex>,
                size: 50,
                meta: {
                    styles: {
                        flex: '2 0 auto',
                    },
                },
            }),
            columnHelper.accessor((row) => row.member_count, {
                id: 'member_count',
                cell: (info) => (
                    <NumericCellContainer>
                        <Data>{info.row.original.member_count}</Data>
                    </NumericCellContainer>
                ),
                header: () => <Flex>{L('members')}</Flex>,
                size: 50,
                meta: {
                    styles: {
                        flex: '1 0 auto',
                    },
                },
                sortingFn: 'alphanumeric',
            }),
        ],
        [],
    );

    const navigate = useNavigate();

    const { data: membersData } = useGetPropertiesByUser({ userId });

    const handleClickRow = useCallback((row: Row<PropertiesList>) => {
        navigate(`../../../${row.original.id}`);
    }, []);

    const members = useMemo(() => membersData ?? [], [membersData]);

    const table = useReactTable({
        data: members ?? [],
        columns,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableColumnResizing: false,
    });

    return <MemberPropertyTable rowClickHandler={handleClickRow} table={table} />;
}
