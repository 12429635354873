import { Maintenance, MaintenanceCategory, MaintenanceFilter, MaintenanceStatus } from '@app/api/models/Maintenances';
import styled from '@emotion/styled';
import React from 'react';
import AddItemButton from './AddItemButton';
import { COLUMNS } from './columns';
import Table from './Table';
import { useParams } from 'react-router-dom';

const Wrapper = styled.div({
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '1rem 0px'
});

type Props = {
    filteredData: Maintenance[] | undefined;
};

const TableHandler = ({ filteredData }: Props) => {
    const { projectId } = useParams<{ projectId: string }>();
    return (
        <>
            <Wrapper>
                <AddItemButton />
            </Wrapper>
            <Table Columns={COLUMNS} to={`/maintenance/${projectId}/`} Data={filteredData} />
        </>
    );
};

export default TableHandler;
