import React, { useMemo } from 'react';
import { AddMultiplePropertyDocumentSchema, AddMultiplePropertyDocument } from '../../binders/schemas';
import { Controller, FieldValues, SubmitHandler, UseFormReset, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { L } from '../../../lib/i18n';
import ErrorAndInputWrapper from '../../../components/Forms/ErrorAndInputWrapper';
import Label from '../../../components/Forms/Label';
import RadixSelect from '../../../components/RadixSelect/RadixSelect';
import { Button, Dropzone } from '@ourliving/ourliving-ui';
import AttachmentItem from '../../binders/components/AttachmentItem';
import TrashCan from '../../../components/Icon/themed/TrashCan';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import toast from 'react-hot-toast';
import type { onUploadSuccess, onUploadError } from '@ourliving/ourliving-ui';
import { SubmitMultipleParams } from '../pages/CopyDocumentsPage';

type FormType = Omit<AddMultiplePropertyDocument, 'file'> & { files: File[] | null };

const Form = styled.form({
    backgroundColor: style.colors.white,
    borderRadius: style.roundCornerSize.small,
    width: '100%',
    padding: '1rem',
});

const DocumentFormMultiple = ({
    documentTypes,
    documentSubtypes,
    handleDocSaveMutation,
}: {
    documentTypes: {
        id: number;
        name: string;
    }[];
    documentSubtypes: {
        id: number;
        name: string;
    }[];
    handleDocSaveMutation: <T extends FieldValues>({
        document_subtype,
        document_type,
        files,
        reset,
    }: SubmitMultipleParams & {
        reset: UseFormReset<T>;
    }) => void;
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
        watch,
    } = useForm<FormType>({
        resolver: zodResolver(AddMultiplePropertyDocumentSchema),
        defaultValues: {
            document_type: 11,
            files: [],
        },
    });

    const files = useWatch({ name: 'files', control });

    const onUpload = (param: onUploadSuccess | onUploadError) => {
        if (param.status === 'error') return toast.error(`${param.errorCode} ${L('an_error_occurred')}`);

        const { files } = watch();

        setValue('files', [...files, param.file]);
    };

    const selectedDocumentType = useWatch({ name: 'document_type', control });

    const documentTypeOptions = useMemo(() => {
        return documentTypes?.map((type) => ({ value: type.id, label: L(type.name) })) || [];
    }, [documentTypes]);

    const documentSubtypeOptions = useMemo(() => {
        if (selectedDocumentType === 5)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('plan'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        if (selectedDocumentType === 10)
            return (
                documentSubtypes
                    ?.filter((type) => type.name.startsWith('manual'))
                    .map((type) => ({ value: type.id, label: L(type.name) })) || []
            );
        return [];
    }, [documentSubtypes, documentTypes, selectedDocumentType]);

    const onSubmit: SubmitHandler<AddMultiplePropertyDocument> = (params) => {
        handleDocSaveMutation({ ...params, reset });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="files"
                render={() => (
                    <ErrorAndInputWrapper errorMsg={errors.files?.message ? L(errors.files?.message) : ''}>
                        <Dropzone onUpload={onUpload} variant="All" multiple />
                    </ErrorAndInputWrapper>
                )}
            />

            {files &&
                files.map((file, index) => (
                    <div style={{ padding: '1rem 0 1rem, 0' }} key={index}>
                        <AttachmentItem fileType={file.type} name={file.name}>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    reset((fieldValues) => {
                                        const oldValues = { ...fieldValues };
                                        oldValues.files.splice(index, 1);
                                        return {
                                            ...fieldValues,
                                            files: oldValues.files,
                                        };
                                    });
                                }}
                            >
                                <TrashCan />
                            </div>
                        </AttachmentItem>
                    </div>
                ))}

            <ErrorAndInputWrapper errorMsg={L(errors.document_type?.message ?? '')}>
                <Label title={L('category')} />
                <Controller
                    control={control}
                    name="document_type"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.document_type}
                            onValueChange={(val) => {
                                setValue('document_subtype', undefined);
                                onChange(+val);
                            }}
                            value={value}
                            options={documentTypeOptions}
                        />
                    )}
                />
            </ErrorAndInputWrapper>

            <ErrorAndInputWrapper errorMsg={L(errors.document_subtype?.message ?? '')}>
                <Label title={L('sub_category')} />
                <Controller
                    control={control}
                    name="document_subtype"
                    render={({ field: { onChange, value } }) => (
                        <RadixSelect
                            isError={!!errors.document_subtype}
                            onValueChange={(val) => onChange(+val)}
                            value={value}
                            options={documentSubtypeOptions}
                            disabled={selectedDocumentType !== 5 && selectedDocumentType !== 10}
                        />
                    )}
                />
            </ErrorAndInputWrapper>
            <div style={{ marginTop: style.margin.m, marginLeft: 'auto' }}>
                <Button>{L('save')}</Button>
            </div>
        </Form>
    );
};

export default DocumentFormMultiple;
