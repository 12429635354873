import { Dimension } from '@app/api/lib/resize';
import { DocumentDetail } from '@app/api/models/Documents';
import DocumentsApi from '@app/api/public/DocumentsApi';
import { useQuery } from 'react-query';

export const getSignedUrlQueryKey = 'getSignedUrls';

const getSignedUrlsStore = async (documents?: DocumentDetail[], demension?: Dimension) => {
    if (!documents?.length) return;
    const responses = await Promise.all(
        documents.map(async document => {
            if (document.document_type === 'image') {
                return await DocumentsApi.getSignedUrlStore(
                    `${demension ? demension + '/' : ''}${document?.document_data?.original?.id}`
                );
            }
        })
    );

    return responses.filter(id => !!id);
};

const useGetSignedUrls = (documents?: DocumentDetail[], demension?: Dimension) => {
    return useQuery(
        [getSignedUrlQueryKey, `${demension ? demension + '/' : ''}`, { documents }],
        () => {
            return getSignedUrlsStore(documents, demension);
        },
        {
            enabled: !!documents?.length
        }
    );
};

export { useGetSignedUrls as default };
