Object.defineProperty(exports, "__esModule", {
  value: true
});
var b = require("/home/runner/work/ourliving-propertymanagement/ourliving-propertymanagement/node_modules/koa-rpc-call/browser");
var o = exports['default'] = {};
o["list"] = b.createRpcProxy("ServicePartnersApi", "list", {
  "urlPrefix": "/rpc",
  httpMethod: 'GET'
});
o["getbyid"] = b.createRpcProxy("ServicePartnersApi", "getbyid", {
  "urlPrefix": "/rpc"
});
o["save"] = b.createRpcProxy("ServicePartnersApi", "save", {
  "urlPrefix": "/rpc"
});
o["remove"] = b.createRpcProxy("ServicePartnersApi", "remove", {
  "urlPrefix": "/rpc"
});
o["saveServicePartnerCategory"] = b.createRpcProxy("ServicePartnersApi", "saveServicePartnerCategory", {
  "urlPrefix": "/rpc"
});
o["listServicePartnerCategories"] = b.createRpcProxy("ServicePartnersApi", "listServicePartnerCategories", {
  "urlPrefix": "/rpc"
});
o["listServiceByCategoryId"] = b.createRpcProxy("ServicePartnersApi", "listServiceByCategoryId", {
  "urlPrefix": "/rpc"
});
o["deleteServicePartnerCategory"] = b.createRpcProxy("ServicePartnersApi", "deleteServicePartnerCategory", {
  "urlPrefix": "/rpc"
});
o["saveDocument"] = b.createRpcProxy("ServicePartnersApi", "saveDocument", {
  "urlPrefix": "/rpc"
});
o["updateDocumentName"] = b.createRpcProxy("ServicePartnersApi", "updateDocumentName", {
  "urlPrefix": "/rpc"
});
o["listDocuments"] = b.createRpcProxy("ServicePartnersApi", "listDocuments", {
  "urlPrefix": "/rpc"
});
o["deleteDocumentFromServicePartner"] = b.createRpcProxy("ServicePartnersApi", "deleteDocumentFromServicePartner", {
  "urlPrefix": "/rpc"
});