import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import CardWithDivider from '../../../../components/Layout/CardWithDivider';
import CardTitle from '../../../../components/typography/CardTitle';
import { L } from '../../../../lib/i18n';
import {
    useListBrands,
    useListSuppliers,
    useListProffesions,
    useListProductTypes,
    useListProperties,
    useListSpaces,
} from '../../hooks/addProductQueries';
import { SubmitHandler } from 'react-hook-form';
import ProductForm from '../../components/Products/ProductForm';
import { AddProduct } from '../../schemas';
import useAddProduct from '../../hooks/productHooks/useAddProduct';
import { useGetProjects } from '../../hooks/projectHooks/projectQueries';
import { Breadcrumbs } from '@ourliving/ourliving-ui';
import useGetProjectName from '../../useGetProjectName';
import useGetPropertyName from '../../useGetPropertyName';
import H1 from '../../../../components/typography/H1';
import { useGetSpaceName } from '../../hooks/spaceHooks/spaceQueries';

const AddProductComponent = ({
    projectId,
    propertyId,
    spaceId,
}: {
    projectId: string;
    propertyId: string;
    spaceId?: string;
}) => {
    const { data: brands } = useListBrands();
    const { data: suppliers } = useListSuppliers();
    const { data: professions } = useListProffesions();
    const { data: productTypes } = useListProductTypes();
    const { data: projects } = useGetProjects();
    const { data: properties } = useListProperties(Number(projectId));
    const [selectedPropertyId, setSelectedPropertyId] = useState(0);
    const { data: spaces } = useListSpaces(selectedPropertyId);
    const navigate = useNavigate();

    const handleSetSelectedPropertyId = (val: number) => setSelectedPropertyId(val);

    const options = {
        brands: brands ? brands.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        suppliers: suppliers ? suppliers.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        professions: professions ? professions.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        productTypes: productTypes ? productTypes.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        properties: properties ? properties.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        spaces: spaces ? spaces.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        projects: projects ? projects.map((row) => ({ value: row.id, label: L(row.name) })) : [],
        package_type: [
            { value: 1, label: L('standard') },
            { value: 2, label: L('optional') },
            { value: 3, label: L('sublist') },
            { value: 4, label: L('sublist_optional') },
        ],
    };

    const { mutate: saveInventoryMaterial } = useAddProduct();

    const onSubmit: SubmitHandler<AddProduct> = (data) =>
        saveInventoryMaterial(
            { inventoryMaterial: data },
            {
                onSuccess: () => {
                    toast.success(L('product_added'));
                    navigate('..');
                },
                onError: () => {
                    toast.error(L('product_not_added'));
                },
            },
        );

    const isUnderTransferred = location.pathname.startsWith('/binders/transferred');
    const isUnderSpaces = location.pathname.includes('/spaces/');
    const projectName = useGetProjectName(projectId);
    const propertyName = useGetPropertyName(propertyId);
    const { data: spaceNameData, status } = useGetSpaceName(spaceId ? +spaceId : null);
    return (
        <>
            {isUnderSpaces && (
                <>
                    <Breadcrumbs style={{ marginBottom: '1rem' }}>
                        <Breadcrumbs.Item to="../../../../../../..">
                            {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                        </Breadcrumbs.Item>
                        <Breadcrumbs.Item to="../../../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                        <Breadcrumbs.Item to="../../..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                        <Breadcrumbs.Item to="..">{spaceNameData?.name ?? L('space')}</Breadcrumbs.Item>
                        <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                    </Breadcrumbs>
                </>
            )}
            {!isUnderSpaces && (
                <Breadcrumbs style={{ marginBottom: '1rem' }}>
                    <Breadcrumbs.Item to="../../../../..">
                        {L(isUnderTransferred ? 'transferred_projects' : 'active_projects')}
                    </Breadcrumbs.Item>
                    <Breadcrumbs.Item to="../../..">{projectName ?? L('project_literally')}</Breadcrumbs.Item>
                    <Breadcrumbs.Item to="..">{propertyName ?? L('property')}</Breadcrumbs.Item>
                    <Breadcrumbs.ItemActive>{L('new_product')}</Breadcrumbs.ItemActive>
                </Breadcrumbs>
            )}
            {status !== 'loading' && (
                <H1>{spaceId ? spaceNameData?.name ?? L('space') : propertyName ?? L('property')}</H1>
            )}
            <CardWithDivider
                topArea={<CardTitle>{L('add_product')}</CardTitle>}
                mainArea={
                    <ProductForm
                        handleSetSelectedPropertyId={handleSetSelectedPropertyId}
                        onSubmit={onSubmit}
                        options={options}
                        defaultValues={{
                            property_id: +propertyId,
                            project_id: +projectId,
                            parent_id: spaceId ? +spaceId : undefined,
                        }}
                    />
                }
            />
        </>
    );
};

export default function AddProductPageV2() {
    const { id: projectId, propertyId, spaceId } = useParams<{ id: string; propertyId: string; spaceId?: string }>();
    return projectId && propertyId ? (
        <AddProductComponent projectId={projectId} propertyId={propertyId} spaceId={spaceId} />
    ) : null;
}
