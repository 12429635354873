import * as React from 'react';
import { ConfirmResetStore } from '../ConfirmResetStore';
import { Button } from '@ourliving/ourliving-ui';
import { L } from '../../../lib/i18n';
import { style } from '../../../components/Shared/Style';
import styled from '@emotion/styled';
import { useObserver } from 'mobx-react';
import { useQuerystring, usePromise } from '../../../lib/react';
import { Loading } from '../../../components/Loading/Loading';
import ReactPasswordStrength from 'react-password-strength';
import { ValidateToken } from './ValidateToken';
import { SetPassword } from './SetPassword';
import Logo from '../../../components/Logo/Logo';

export interface PasswordToken {
    token: string;
    invitation_token: string;
}

const Header = styled.div({
    fontWeight: style.fontWeight.bold,
    fontSize: style.fontSize.milli,
    lineHeight: '15px',
    letterSpacing: '0.3px',
    textTransform: 'uppercase',
    color: style.colors.secondary,
    paddingBottom: style.margin.xs
});

const Error = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const ErrorMessage = styled.strong({
    color: 'red'
});

const Sucess = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: style.margin.l
});
const SuccessMessage = styled.strong({
    color: style.colors.primary
});

const Background = styled.div({
    width: '100%',
    minHeight: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TextField = styled.div({
    paddingBottom: style.margin.ml
});

const LoginForm = styled.div({
    width: '80%'
});

const StyledLogo = styled(Logo)({
    maxWidth: '300px',
    width: '100%',
    height: '100%',
    maxHeight: '100px',
    placeSelf: 'center',
    backgroundColor: 'inherit'
});

const ButtonMenu = styled.div({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center'
});

const Form = styled.form({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: style.colors.white,
    padding: '70px 12px 40px 12px',
    border: '1px solid #DFE0EB',
    boxSizing: 'border-box',
    borderRadius: style.roundCornerSize.medium,
    flexDirection: 'column',
    width: '378px',
    height: '450px'
});

const StyledReactPasswordStrength = styled(ReactPasswordStrength)({
    background: style.colors.inputBackground,
    border: `1px solid ${style.colors.inputBorder}`,
    height: '42px',
    borderRadius: style.roundCornerSize.small,
    outline: 'none',
    fontSize: style.fontSize.seta,
    fontFamily: 'Lato',
    lineHeight: style.lineHeight.normal,
    '.ReactPasswordStrength-strength-desc': {
        width: '30%'
    },
    '.ReactPasswordStrength-input': {
        padding: `${style.margin.s}px`,
        fontSize: style.fontSize.seta,
        height: '39px'
    }
});

export const PasswordResetPage = () => {
    const [tokens] = useQuerystring<PasswordToken>();

    const token = Object.keys(tokens);

    const [ready] = usePromise(async () => {
        ValidateToken(location.pathname, tokens[token[0]]);
    }, []);

    const resetPassword = () => {
        SetPassword(location.pathname);
    };

    const onChangeConfirm = e => {
        ConfirmResetStore.setPassword(e.password, 'confirmPassword');
    };

    const onChangeNew = e => {
        if (!e.isValid) {
            ConfirmResetStore.isNewPasswordValid = false;
            return;
        } else {
            ConfirmResetStore.isNewPasswordValid = true;
            ConfirmResetStore.setPassword(e.password, 'newPassword');
        }
    };

    return useObserver(() => {
        if (!ready) {
            return <Loading />;
        }
        return (
            <Background>
                <Form onSubmit={resetPassword}>
                    <StyledLogo />

                    <LoginForm>
                        {ConfirmResetStore.state && ConfirmResetStore.state !== 'password_changed' && (
                            <Error>
                                <ErrorMessage>{L('reset_' + ConfirmResetStore.state)}</ErrorMessage>
                            </Error>
                        )}
                        {ConfirmResetStore.state === 'password_changed' && (
                            <Sucess>
                                <SuccessMessage>{L('reset_' + ConfirmResetStore.state)}</SuccessMessage>
                            </Sucess>
                        )}

                        <div>
                            <Header>{L('new_password')}</Header>
                            <TextField>
                                <StyledReactPasswordStrength
                                    className="customClass"
                                    minLength={5}
                                    minScore={2}
                                    tooShortWord={L('password_short')}
                                    changeCallback={e => onChangeNew(e)}
                                    scoreWords={[
                                        L('password_not_ok'),
                                        L('password_not_ok'),
                                        L('password_ok'),
                                        L('password_strong'),
                                        L('password_great')
                                    ]}
                                    inputProps={{ autoComplete: 'off', placeholder: [L('password_required_length')] }}
                                />
                            </TextField>
                        </div>
                        <div>
                            <Header>{L('confirm_password')}</Header>
                            <TextField>
                                <StyledReactPasswordStrength
                                    className="customClass"
                                    minLength={6}
                                    minScore={2}
                                    tooShortWord={L('password_short')}
                                    scoreWords={[
                                        L('password_not_ok'),
                                        L('password_not_ok'),
                                        L('password_ok'),
                                        L('password_strong'),
                                        L('password_great')
                                    ]}
                                    changeCallback={e => onChangeConfirm(e)}
                                    inputProps={{
                                        autoComplete: 'off',
                                        placeholder: [L('password_required_length')]
                                    }}
                                />
                            </TextField>
                        </div>
                        <ButtonMenu>
                            <Button onClick={resetPassword} style={{ marginRight: '0px' }}>
                                {L('save')}
                            </Button>
                        </ButtonMenu>
                    </LoginForm>
                </Form>
            </Background>
        );
    });
};
