import styled from '@emotion/styled';
import React, { FC } from 'react';
import { colors, Colors } from '../Shared/Style/colors';

const StyledH2 = styled.h2((props: { color?: Colors }) => {
    return {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: '171%',
        letterSpacing: '0.41px',
        color: props.color ? colors[props.color] : colors.textColor2
    };
});

type Props = {
    color?: Colors;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

const H2: FC<Props> = ({ children, ...rest }) => {
    return <StyledH2 {...rest}>{children}</StyledH2>;
};

export default H2;
