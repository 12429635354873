import React from 'react';
import styled from '@emotion/styled';
import useUpdateCheckedProduct from '../hooks/useUpdateCheckedProduct';
import useDeleteProductFromFilter from '../hooks/useDeleteProductFromFilter';
import { L } from '../../../lib/i18n';

const ProductWrapper = styled.label({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0.5rem',
    cursor: 'pointer'
});

const StyledInput = styled.input({
    width: '18px',
    height: '18px',
    margin: '5px 0px'
});

const FilterComponent = ({ product }) => {
    const { mutate: updateCheckedProduct } = useUpdateCheckedProduct();
    const { mutate: deleteProductFromFilter } = useDeleteProductFromFilter();

    const handleAdd = () => {
        updateCheckedProduct({ ...product });
    };

    const handleDelete = () => {
        deleteProductFromFilter({ ...product });
    };

    return (
        <ProductWrapper>
            <span>{L(product.name)}</span>
            <StyledInput
                type="checkbox"
                checked={product.checked}
                onChange={product.checked === true ? handleDelete : handleAdd}
            />
        </ProductWrapper>
    );
};

export default FilterComponent;
